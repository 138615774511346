import React, {useContext, useRef, useEffect, useState} from 'react';
import styled from 'styled-components';
import {observer} from 'mobx-react-lite';
import GraphDrawer from './graphDrawer';
import {autoCorrelate} from '../helpers/pitchHelper';
import {autoCorrelateHnr} from '../helpers/hnrHelper';
import {getShimmerValues} from '../helpers/shimmerHelper';
import {getJitterValues} from '../helpers/jitterHelper';
import CommunicationStore from '../stores/communication.store';
import {resetResults} from './containers/videoCallPage';
import GetCursorPosition from 'cursor-position'
import Icon from './icon';
import {faVolumeDown, faVolumeUp} from '@fortawesome/free-solid-svg-icons';
import AudioRecorder from "./audioRecorder.js";
var spectrogram = require('spectrogram');
var spectro;

const AnalyserContainer = styled.div`
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;    
    flex-wrap: wrap;
    z-index: 1;
    align-content: center;
    margin-right: 10px;
    margin-left: 35vw;
`;

const SliderContainer = styled.div`
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;    
    flex-wrap: wrap;
    z-index: 1;
    align-content: center;
    margin-right: 10px;
    margin-left: 35vw;
    gap: 10px;
`;

const SpectrumContainer = styled.div`
   height: 500px;
`;

const wrapper = styled.div` 
  flex-direction: column;
  display: grid;
  grid-template-columns: repeat(2, 50px);
  grid-auto-rows: 80px;
  z-index: 1;
  margin-right: 0;
  margin-left: auto;
  width: 100;
`;



const box1 = styled.div`
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
  width: 100px;
  z-index: 1;
`;

const box2 = styled.div`
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 2;
  width: 100px;
  z-index: 1;
`;

const box3 = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 3;
  z-index: 1;
  width: 200px;
`;



const Button = styled.button`
  /* Adapt the colors based on primary prop */
  background: ${props => props.primary ? "palevioletred" : "white"};
  color: black};

  font-size: 1em;
  margin: 1em;
  padding: 0.25em 1em;
  border: 2px solid palevioletred;
  border-radius: 3px;
  z-index: 100;
  &:hover {
        border-color: ${props => props.theme.colors.primary};
        cursor: pointer;
    }
`;

const AudioControlsContainer = styled.div`
    margin-bottom: 50px;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;    
    z-index: 1;
`;

const ButtonGroup = styled.div`
  display: flex;
  margin-right: 0;
  margin-left: auto;

`

const SpectrumButtons = styled.button`
position: 'absolute',
    bottom:0,
    right:0;
width: 400px;

`;

const SpectrumButtonsTwo = styled.button`
  background-color: black;
  color: white;
  font-size: 20px;
  padding: 10px 60px;
  border-radius: 5px;
  margin: 10px 0px;
  cursor: pointer;
  &:disabled {
    color: grey;
    opacity: 0.7;
    cursor: default;
  }
`;

const loudnessThresh = 50;
const jitterMax = 4; //2
const shimmerMax = 10; //8
var hnrAvg = 0;
var jitterAvg = 0;
var shimmerAvg = 0;

var fundamentalCurrent = 0;
var fundamentalSum = 0;
var fundamentalArray = [];
var fundamentalMean = 0;

var hnrCurrent = 0;
var hnrSum = 0;
var hnrArray = [];
var hnrMean = 0;

var jitterCurrent = 0;
var jitterSum = 0;
var jitterArray = [];
var jitterMean = 0;

var shimmerCurrent = 0;
var shimmerSum = 0;
var shimmerArray = [];
var shimmerMean = 0;

var intensityCurrent = 0;
var intensitySum = 0;
var intensityArray = [];
var intensityMean = 0;

var recorderIsRecording = 0;


var shimmerTotalSum = [];
var shimmerTotalAvg = 0;

var jitterTotalSum = [];
var jitterTotalAvg = 0;

var hnrTotalSum = [];
var hnrTotalAvg = 0;

var fzeroTotalSum = [];
var fzeroTotalAvg = 0;

var resetResultsTimer = setInterval(function() {
    if (resetResults == 1){
        fundamentalSum = 0;
        fundamentalArray = [];
        fundamentalMean = 0;

        hnrSum = 0;
        hnrArray = [];
        hnrMean = 0;

        jitterSum = 0;
        jitterArray = [];
        jitterMean = 0;

        shimmerSum = 0;
        shimmerArray = [];
        shimmerMean = 0;

        intensitySum = 0;
        intensityArray = [];
        intensityMean = 0;
    }
}, 100);

var interval = setInterval(canvasLoad,500);
function canvasLoad(){
    if (document.getElementById('spectrumCanvas') !=  null){
        spectro = spectrogram(document.getElementById('spectrumCanvas'),  {
            audio: {
            enable: false
            }
        });
        clearInterval(interval);

    }
    else if (document.getElementById('spectrumCanvas') ==  null){
        //catch null
    }
}

const AudioAnalyzer = observer(({stream}) => {
    const communicationStore = useContext(CommunicationStore);
    const AudioContext = window.AudioContext || window.webkitAudioContext;
    const audioContext = new AudioContext({sampleRate: communicationStore.sampleRate});
    const audioData = audioContext.createAnalyser();
    const audioDataPre = audioContext.createAnalyser();
    const gainNode = audioContext.createGain(); 
    const dest = audioContext.createMediaStreamDestination();
    const mediaRecorder = new MediaRecorder(dest.stream);
    var chunks = [];


    const initializeAudioAnalyser = () => {
        //Audio chains
        const source = audioContext.createMediaStreamSource(stream);
        const lowpass = audioContext.createBiquadFilter();

        lowpass.type = 'lowpass';
        lowpass.frequency.value = communicationStore.filterCutoff;
        audioData.fftSize = communicationStore.fftSize;
        audioDataPre.smoothingTimeConstant = 0;
        audioData.smoothingTimeConstant = 0.4;
        
        //audio chain
        source.connect(gainNode); //sourece -> gain node
        gainNode.connect(dest); // gain node -> recorder
        gainNode.connect(audioDataPre); //gain node -> intensity, RMS, peak
        gainNode.connect(lowpass); // gain node -> lowpass
        lowpass.connect(audioData); //gain node -> features

        if((document.getElementById('spectrumCanvas') !=  null) && (communicationStore.spectrogramDisplay == true)){
            spectro.connectSource(audioDataPre, audioContext);
            spectro.start();
        }

    };

    function handleSliderChange(){
        gainNode.gain.value = document.getElementById("typeinp").value;
        console.log(gainNode.gain.value);
     }  

     //peak
     const getPeak = () => {
       
        const bufferLength = audioData.frequencyBinCount;
        const amplitudeArray = new Float32Array(bufferLength);
        audioDataPre.getFloatTimeDomainData(amplitudeArray);

        var peakValue = 0;
        let sumOfSquares = 0;

        for (let i = 0; i < amplitudeArray.length; i++) {   
            sumOfSquares += amplitudeArray[i] ** 2;
            if(Math.abs(amplitudeArray[i]) > peakValue){
                peakValue = amplitudeArray[i]
            }
        }   

        const vRms = Math.sqrt(sumOfSquares / amplitudeArray.length);

        //1 pascal = 93.9794 db
        //vrms measured in pascals
        return peakValue;
    }

    const getPeakData = (peak) => {
        return peak(getPeak());
    };

    //rms
    const getRms = () => {
        const bufferLength = audioData.frequencyBinCount;
        const amplitudeArray = new Float32Array(bufferLength);
        audioDataPre.getFloatTimeDomainData(amplitudeArray);
        var peakValue = 0;
        let sumOfSquares = 0;

        for (let i = 0; i < amplitudeArray.length; i++) {   
            sumOfSquares += amplitudeArray[i] ** 2;
            if(Math.abs(amplitudeArray[i]) > peakValue){
                peakValue = amplitudeArray[i]
            }
        }   
        const vRms = Math.sqrt(sumOfSquares / amplitudeArray.length);
        return vRms;
    }

    const getRmsData = (rms) => {
        return rms(getRms());
    };
    
    //f0
    const getFrequency = () => {
        const bufferLength = audioData.frequencyBinCount;
        const amplitudeArray = new Float32Array(bufferLength);
        audioData.getFloatTimeDomainData(amplitudeArray);
        const fundamental0 = autoCorrelate(amplitudeArray, audioContext.sampleRate);

        //display vars
        fundamentalCurrent = fundamental0;
        if (fundamental0 > 0){
            fundamentalArray.push(fundamental0);
            fundamentalSum = fundamentalSum + fundamental0;
            fundamentalMean = fundamentalSum / fundamentalArray.length;
        }

        //console.log("fundamentalMean: ", fundamentalMean);

        return fundamental0;
    };

    //f0
    const getFrequencyData = (frequency) => {
        return frequency(getFrequency());
    };


    //loudness (intensity)
    var loudnessCheck = -1

    const getLoudness = () => {
            
        const bufferLength = audioData.frequencyBinCount;
        const amplitudeArray = new Float32Array(bufferLength);
        audioDataPre.getFloatTimeDomainData(amplitudeArray);

        var peakValue = 0;
        var sumOfSquares = 0;
        var amplitudeArrayOut = []; 

        for (let i = 0; i < amplitudeArray.length; i++) {   
            sumOfSquares += amplitudeArray[i] ** 2;
        }   

        const vRms = Math.sqrt(sumOfSquares / amplitudeArray.length);
        const intensityDb = 20 * Math.log10(vRms/0.00002);

        if (intensityDb > 10 ){
        intensityArray.push(intensityDb);
        intensitySum = intensitySum + intensityDb;
        intensityMean = intensitySum / intensityArray.length;
        intensityCurrent = intensityDb; 
        }

        if (intensityDb > loudnessThresh){
            loudnessCheck = 1;
        }
        else{
            loudnessCheck = 0;
        }
        return intensityDb;
    };

    //loudness
     const getLoudnessData = (loudness) =>{
        return loudness(getLoudness());
    }

    //hnr
    const getHnr = () => {
        const bufferLength = audioData.frequencyBinCount;
        const amplitudeArray = new Float32Array(bufferLength);
        audioData.getFloatTimeDomainData(amplitudeArray);
        const hnr = autoCorrelateHnr(amplitudeArray, audioContext.sampleRate);
        //hnr display
        hnrCurrent = hnr;
        if (hnr  > 0){
            hnrArray.push(hnr);
            hnrSum = hnrSum + hnr;
            hnrMean = hnrSum / hnrArray.length;
        }
        //console.log("hnrMean: ", hnrMean);
        return hnr;
    };

    //hnr
    const getHnrData = (frequency) => {
        return frequency(getHnr());
    };

    //shimmer
    const averagingShimmerArray = new Array(3).fill(0);
    var averagingLoop = 0;

    const getShimmer = () => {
        averagingLoop++;
        const bufferLength = audioData.frequencyBinCount;
        const amplitudeArray = new Float32Array(bufferLength);
        audioData.getFloatTimeDomainData(amplitudeArray);
        const shimmerVal = getShimmerValues(amplitudeArray, audioContext.sampleRate);

        averagingShimmerArray[averagingLoop%3] = shimmerVal
        const shimmerValAvg = (averagingShimmerArray[0] + averagingShimmerArray[1] + averagingShimmerArray[2])/3;

        if (averagingShimmerArray[0] < 0.1|| averagingShimmerArray[1] < 0.1 || averagingShimmerArray[2] < 0.1){
            return 0;
        }

        if (shimmerValAvg > shimmerMax){
            shimmerCurrent = 0;
            return 0;
        }
        //display shimmer
        shimmerCurrent = shimmerValAvg;
        if (shimmerValAvg > 0 && shimmerValAvg < shimmerMax){
            shimmerArray.push(shimmerValAvg);
            shimmerSum = shimmerSum + shimmerValAvg;
            shimmerMean = shimmerSum / shimmerArray.length;
        }
        //console.log("shimmerMean: ", shimmerMean);
        return shimmerValAvg;
    };

    //shimmer
    const getShimmerData = (shimmer) => {
        return shimmer(getShimmer())
    };

     //JITTER
     const getJitter = () => {
        const bufferLength = audioData.frequencyBinCount;
        const amplitudeArray = new Float32Array(bufferLength);
        audioData.getFloatTimeDomainData(amplitudeArray);
        const jitterVal = getJitterValues(amplitudeArray, audioContext.sampleRate)

        //check for value req
        if (jitterVal > jitterMax){
            jitterCurrent = 0;
            return 0;
        }
        
        //display jitter
        jitterCurrent = jitterVal;
        if (jitterVal > 0 && jitterVal < jitterMax){
            jitterArray.push(jitterVal);
            jitterSum = jitterSum + jitterVal;
            jitterMean = jitterSum / jitterArray.length;
        }
        //console.log("jitterMean: ", jitterMean);
        return jitterVal;
    };

    const getJitterData = (jitter) => {
        return jitter(getJitter())
    };

    //audioRec
    function recorderButtonPress () {
        if (mediaRecorder.state != "recording"){
            initializeAudioAnalyser();
            document.getElementById('recorder').style.backgroundColor = "violet";
            mediaRecorder.start(1000);
        }
    };

    function stopRecorderButtonPress () {
        
        if (mediaRecorder.state == "recording"){
            document.getElementById('recorder').style.backgroundColor = "white";
            mediaRecorder.requestData();
            mediaRecorder.stop();
        }
        
    }

    mediaRecorder.ondataavailable = function(evt) {
       // push each chunk (blobs) in an array
       chunks.push(evt.data);
     };

     mediaRecorder.onstop = function(evt) {
        
        // Make blob out of our blobs, and open it.
        var blob = new Blob(chunks, { 'type' : 'audio/wav; codecs=pcm' });
        //var blob = new Blob(chunks, { 'type' : 'audio/mp3; codecs=pcm' });
        //var blob = new Blob(chunks, {'type': 'audio/ogg; codecs=opus'});
        chunks = [];

        //let audioTag = document.querySelector("audioControls")
        //let audioTag = document.getElementById('audioControls');
        //audioTag.src = URL.createObjectURL(blob);
        
        var fileURL = URL.createObjectURL(blob);
        //audioTag.currentTime = 1e101;
        // create <a> tag dinamically
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;

        // it forces the name of the downloaded file
        //fileLink.download = 'F0-' + fundamentalMean.toFixed(2) + '_HNR-' + hnrMean.toFixed(2) + '_SH-' + shimmerMean.toFixed(2) + '_JT-' + jitterMean.toFixed(2);
        fileLink.download = 'ARTVA_Recording';
        fileLink.download = fileLink.download.replace(/\./g, ',');
        // triggers the click event
        fileLink.click();
     };

    function dimSpectrum(){
        //console.log("dimSpectrum reached")
        var spectrumCanvas = document.getElementById("spectrumCanvas");
        //console.log("communicationStore.spectrogramDim: ", communicationStore.spectrogramDim);
        if (communicationStore.spectrogramDim == false){
            spectrumCanvas.style.opacity = "1.0";   
        }
        else if(communicationStore.spectrogramDim == true){
            spectrumCanvas.style.opacity = "0.0";
        }
    }

    function handleSpectrumHover() {
        const {x, y} = GetCursorPosition();
    }

    function spectrumDownloadButtonPress (){
         var canvas = document.getElementById("spectrumCanvas");
        var img    = canvas.toDataURL("image/png");
       

        var spectrumLink = document.createElement('a');
        spectrumLink.href = img;

        spectrumLink.download = 'ARTVA_Spectrogram';
        spectrumLink.download = spectrumLink.download.replace(/\./g, ',');

        // triggers the click event
        spectrumLink.click();

    }

    useEffect(() => {
        //reset settings "onMount"
        defaultSettingsValues();

        //initiate dim spectrum interval
        const interval = setInterval(() => {
            if ((document.getElementById('spectrumCanvas') !=  null) && (communicationStore.spectrogramDisplay == true)){
                dimSpectrum();
            }
        }, 100);
        return () => 
            clearInterval(interval)
    }, [])

    function defaultSettingsValues() {
        gainNode.gain.value = 0.4;
        communicationStore.setSpectrogramDisplay(false);
        communicationStore.setJitterDisplay(false); 
        communicationStore.setShimmerDisplay(false);
        communicationStore.setIntensityDisplay(false);
        communicationStore.setHnrDisplay(false); 
        communicationStore.setRecorderDisplay(false);
        communicationStore.setFundamentalDisplay(false);
        communicationStore.setFilterCutoff(1000);
    }

    //states for freeze
    const [frozenState, setFreezeState] = useState(null)

    //freeze spetrogram
    function freezeButtonPress () {
        setFreezeState(prevState => !prevState)

        if(frozenState){
            spectro.pause();

        }
        else if(!frozenState){
            spectro = spectrogram(document.getElementById('spectrumCanvas'),  {
                audio: {
                    enable: false
                }
            });
            initializeAudioAnalyser();
        }
    }

    return (
        <>
        {communicationStore.calibrationDisplay == true &&
        <>
        <i class="fa fa-volume-down"></i>
        <SliderContainer>
        <p>Adjust the slider until RMS value is between 0.1 and 0.3. <br />
        The peak value to never go above 0.9. <br />
        (keep an eye on voice onset, it is usually the louest part)</p>
        </SliderContainer>
        <SliderContainer>
        <Icon fontSize={3} icon={faVolumeDown}/>
        <input 
            style={{width:410}}
            id="typeinp" 
            type="range" 
            min="0.1" 
            max="3.0" 
            defaultValue="0.3" 
            step="0.01"
            onChange={()=> handleSliderChange()}/>
        <Icon fontSize={3} icon={faVolumeUp}/>
        </SliderContainer>
        </>}
        <AnalyserContainer>
            {(communicationStore.intensityDisplay == true || communicationStore.calibrationDisplay == true) &&
            <GraphDrawer
                title={'Intensity'}
                unit={'dB'}
                yMin={0}
                yMax={100}
                hasRemoteStream={!!stream}
                initializeAudioAnalyser={initializeAudioAnalyser}
                getData={getLoudnessData}
            />}
            {communicationStore.calibrationDisplay == true &&
             <GraphDrawer
                title={'Peak'}
                unit={'Amplitude'}
                yMin={0}
                yMax={1}
                hasRemoteStream={!!stream}
                initializeAudioAnalyser={initializeAudioAnalyser}
                getData={getPeakData} 
            />}
            {communicationStore.calibrationDisplay == true &&
            <GraphDrawer
                title={'RMS'}
                unit={'Pascal'}
                yMin={0}
                yMax={1}
                hasRemoteStream={!!stream}
                initializeAudioAnalyser={initializeAudioAnalyser}
                getData={getRmsData} 
            />}
            {communicationStore.fundamentalDisplay == true &&
            <GraphDrawer
                title={'F0'}
                unit={'Hz'}
                yMin={0}
                yMax={1000}
                hasRemoteStream={!!stream}
                initializeAudioAnalyser={initializeAudioAnalyser}
                getData={getFrequencyData}
            />}
            {communicationStore.hnrDisplay == true &&
            <GraphDrawer
                title={'HNR'}
                unit={'dB'}
                yMin={0}
                yMax={40}
                hasRemoteStream={!!stream}
                initializeAudioAnalyser={initializeAudioAnalyser}
                getData={getHnrData}
            />}
            {communicationStore.jitterDisplay == true &&
            <GraphDrawer
                title={'Jitter'}
                unit={'%'}
                yMin={0}
                yMax={2}
                hasRemoteStream={!!stream}
                initializeAudioAnalyser={initializeAudioAnalyser}
                getData={getJitterData}
            />}
            {communicationStore.shimmerDisplay == true &&
            <GraphDrawer
                title={'Shimmer'}
                unit={'%'}
                yMin={0}
                yMax={10}
                hasRemoteStream={!!stream}
                initializeAudioAnalyser={initializeAudioAnalyser}
                getData={getShimmerData} 
            />} 
        {communicationStore.recorderDisplay == true &&
        <wrapper>      
            <AudioRecorder /> 
            <box1><Button hidden="true" id="recorder" onClick={() => recorderButtonPress()}>Record</Button></box1>
            <box2><Button hidden="true" id="stopRecorder" onClick={() => stopRecorderButtonPress()}>Stop Recording</Button></box2>
            <box3><audio hidden="true" id="audioControls" controls preload="auto" autobuffer></audio></box3>
        </wrapper>}
        </AnalyserContainer>

        {communicationStore.spectrogramDisplay == true &&
            <>
            <ButtonGroup>
                {communicationStore.spectrogramDim == false && 
                <>
                    {!frozenState &&
                    <Button id="freezeUnfreeze" onClick={() => freezeButtonPress()}> Start Spectrogram </Button>}
                    {frozenState &&
                    <Button id="freezeUnfreeze" onClick={() => freezeButtonPress()}> Freeze </Button>}
                    <Button id="freezeUnfreeze" onClick={() => spectrumDownloadButtonPress()}> Snapshot </Button>
                </>
                }
            </ButtonGroup>
            <canvas 
                id="spectrumCanvas" 
                width="900" 
                height="250" 
                onMouseMove={handleSpectrumHover}>
            </canvas>
            </>
        }
        </>
    );
});

export { fundamentalCurrent };
export { fundamentalMean };
export { fundamentalArray };
export { hnrCurrent };
export { hnrMean };
export { jitterCurrent };
export { jitterMean };
export { shimmerCurrent };
export { shimmerMean };
export { intensityCurrent };
export { intensityMean };
export default AudioAnalyzer;
