import styled, {css} from 'styled-components';
import {compose, flexbox, color, space, typography} from 'styled-system';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Icon = styled(FontAwesomeIcon)`
    ${compose(flexbox, space, color, typography)};
    
    ${props => props.addHover && css`
        cursor: pointer;
        
        &:hover {
            opacity: 0.8;
        }
    `};
`;

Icon.propTypes = {
    addHover: PropTypes.bool
};

export default Icon;
