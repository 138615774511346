import React, { useState, useEffect, useContext } from "react";
import { uuid } from "uuidv4";
import styled from "styled-components";
import Global from "../../components/styled/global";
import TherapistOverlay from "../../components/overlays/therapistOverlay";
import PatientCard from "../../components/patientCard";
import PageWrapper from "../../components/styled/pageWrapper";
import { PageTitle, SmallTitle } from "../../components/styled/typography";
import Card from "../../components/styled/card";
import Button from "../../components/styled/button";
import SearchBar from "../../components/search";
import { api } from "../../api";
import { FaPlus, FaTimes } from "react-icons/fa";
import LoadSpinner from "../../components/loadspinner";
import { PageAnimation } from "../../components/styled/animation";
import { observer } from "mobx-react";
import registrationStore from "../../components/registrationStore";
import patientStore from "../../components/patientStore";
import Flash from "../../components/styled/flashMessage";
import { useTranslation } from "react-i18next";
import createBertie, {
    createBertieDysfonie,
    createBertieDysfagie,
} from "../../components/helperComponents/createBertie";
import createBertieEN from "../../components/helperComponents/createBertieEN";
import Pagination from "../../components/libraryComponents/pagination";

const Flex = styled.div`
    display: flex;
    flex-direction: column-reverse;

    @media (min-width: 980px) {
        flex-direction: row;
    }
`;

const FlexBetween = styled(Flex)`
    justify-content: flex-start;
    align-items: space-between;
    margin-bottom: 25px;

    @media (min-width: 980px) {
        justify-content: space-between;
        align-items: center;
    }
`;

const Patients = styled.div`
    display: flex;
    flex-direction: column;

    @media (min-width: 980px) {
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
    }
`;

const Title = styled(SmallTitle)`
    margin-top: 10px;
    margin-right: 40px;
`;

const Plus = styled(FaPlus)`
    color: #ffffff;
    padding-right: 7px;
    transform: translateY(2px);
`;

const Times = styled(FaTimes)`
    color: #545454;
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
`;

const Overlay = styled.div`
    background-color: rgba(60, 60, 60, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const OverlayCard = styled(Card)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
`;

const Input = styled.input`
    background: #ffffff;
    border: 1px solid #51c0cf;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(197, 197, 197, 0.25);
    border-radius: 10px;
    padding: 10px 10px;
    margin-top: 5px;
    outline: none;
    width: 300px;
    margin: 20px auto;
    font-size: 16px;
`;

const OverlayTitle = styled(SmallTitle)`
    margin: 0px;
`;

const Therapie = observer(() => {
    const { t, i18n } = useTranslation();
    const store = useContext(registrationStore);
    const user = JSON.parse(store.user);
    const patientstore = useContext(patientStore);
    const [showOverlay, setShowOverlay] = useState(false);
    const [showFlash, setShowFlash] = useState(false);
    const [flashContent, setFlashContent] = useState("");
    const [isError, setIsError] = useState(false);
    const [patients, setPatients] = useState([]);
    const [filteredPatients, setFilteredPatients] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalPages, setTotalPages] = useState(0);
    const [pagination, setPagination] = useState({
        start:1,
        end:20
    });
    const [currentPage, setCurrentPage] = useState(1);

    function handlePageChange(dir) {
        setPagination({
            start: pagination.start + 20 * dir,
            end: pagination.end + 20 * dir,
        });
        setCurrentPage(currentPage + dir);
    }

    function handlePageForce(page) {
        setPagination({
            start: 20 * (page - 1) + 1,
            end: page * 20,
        });
        setCurrentPage(page);
    }

    const handleSearch = (e) => {
        if (e.target.value === "") {
            setFilteredPatients(patients);
        } else {
            var filtered = patients.filter(function (patient) {
                if (
                    patient.firstName.toLowerCase().includes(e.target.value.toLowerCase()) ||
                    patient.lastName.toLowerCase().includes(e.target.value.toLowerCase())
                ) {
                    return patient;
                }
            });
            setFilteredPatients(filtered);
        }
    };

    const HandleSubmit = () => {
        const patientEmail = document.getElementById("patient_email").value;
        api.post(
            "/addPatientRequest",
            { body: "request", sender_id: user.public_id, sender_name: user.firstName },
            { params: { email: document.getElementById("patient_email").value } }
        ).then(function (response) {
            setShowFlash(true);
            if (response.data.Succes) {
                setFlashContent(t("therapie.linkSucc"));
                api.post("/mailWithTemplate", {
                    sender: "info@telephon.digital",
                    to: patientEmail,
                    subject: "Request to become a client",
                    template_name: "request-to-become-client",
                    template_content: [{ name: "therapist-name", content: user.firstName + " " + user.lastName }],
                });
            } else if (response.data.NoUserError) {
                setFlashContent(t("therapie.noUser"));
                setIsError(true);
            } else if (response.data.NoPatientError) {
                setFlashContent(t("therapie.noPatient"));
                setIsError(true);
            } else if (response.data.AlreadyLinkedError) {
                setFlashContent(t("therapie.AlreadyLinked"));
                setIsError(true);
            }
            update();
        });
        setShowOverlay(false);
    };

    const handleClick = () => {
        setShowOverlay(true);
    };

    const handleClose = () => {
        setShowOverlay(false);
    };

    const update = () => {
        setLoading(true);
        api.get("/patients", { params: { therapist_id: user.public_id } }).then((response) => {
            setPatients(response.data.users);
            setFilteredPatients(response.data.users);
            setLoading(false);
        });
    };

    const createDummyAccount = async (therapist_id) => {
        if (i18n.language === "nl") {
            await createBertie(therapist_id);
        } else {
            await createBertieEN(therapist_id);
        }
        api.get("/patients", 
            { params: 
                { 
                    therapist_id: user.public_id,
                    start: pagination.start,
                    end: pagination.end 
                } 
            }).then((response) => {
            setPatients(response.data.users);
            setFilteredPatients(response.data.users);
            setTotalPages(Math.ceil(response.data.total / 25));
            setLoading(false);
        });
    };

    const getPatients = () => {
        api.get("/patients",
            { params: 
                { 
                    therapist_id: user.public_id,
                    start: pagination.start,
                    end: pagination.end 
                } 
            }).then((response) => {
                if (response.data.users.length != 0) {
                    setPatients(response.data.users);
                    setFilteredPatients(response.data.users);
                    setTotalPages(Math.ceil(response.data.total / 25));
                    setLoading(false);
                } else {
                    createDummyAccount(user.public_id);
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    useEffect(() => {
        getPatients()
    }, []);

    useEffect(() => {
        getPatients()
    }, [pagination])

    return (
        <Global>
            <PageWrapper>
                <TherapistOverlay />
                <PageAnimation>
                    <PageTitle black>{t("sidebarTherapist.therapy")}</PageTitle>
                    <FlexBetween>
                        <Flex>
                            <Title black>{t("therapie.patienten")}</Title>
                            <SearchBar handleSearch={handleSearch} />
                        </Flex>
                        <Button onClick={handleClick}>
                            <Plus />
                            {t("therapie.addPat")}
                        </Button>
                    </FlexBetween>
                    {showFlash && <Flash red={isError}>{flashContent}</Flash>}
                    {showOverlay && (
                        <Overlay>
                            <OverlayCard>
                                <Times onClick={handleClose} />
                                <OverlayTitle black>{t("therapie.link")}</OverlayTitle>
                                <Input placeholder={t("therapie.placeholderLink")} type="email" id="patient_email" />
                                <Button type="submit" onClick={HandleSubmit}>
                                    {t("therapier.sendReq")}
                                </Button>
                            </OverlayCard>
                        </Overlay>
                    )}
                    {loading ? (
                        <LoadSpinner />
                    ) : (
                        <>                        
                            <Patients>
                                {filteredPatients.length > 0 && (
                                    filteredPatients.map((patient) => {
                                            return <PatientCard patient={patient} update={update} />;
                                    })
                                )}
                                {filteredPatients.length <= 0 && (
                                    <div style={{ "margin-top": "20px" }}>{t("therapie.noPatients")}</div>
                                )}
                            </Patients>
                            {totalPages && (
                                <Pagination
                                    nextFunction={() => handlePageChange(1)}
                                    prevFunction={() => handlePageChange(-1)}
                                    totalPages={totalPages}
                                    currentPage={currentPage}
                                    pageForce={handlePageForce}
                                />
                        
                            )}
                        </>
                    )}
                </PageAnimation>
            </PageWrapper>
        </Global>
    );
});

export default Therapie;
