import styled from "styled-components";

const Flash = styled.div`
    background-color: ${(props) => (props.red ? "#f02929" : props.green ? "#45AE7C" : "#51C0CF")};
    padding: 10px 20px;
    border: ${(props) => (props.red ? "1px solid #f02929" : props.green ? "1px solid #45AE7C" : "1px solid #51C0CF")};
    text-align: ${(props) => (props.center ? "center" : "left")};
    border-radius: 10px;
    color: white;
    margin-bottom: 10px;
    margin-top: 10px;
`;

export default Flash;
