import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { FaEdit, FaPrint } from "react-icons/fa";
import { Formik, Form, Field } from "formik";
import TherapistOverlay from "../../components/overlays/therapistOverlay";
import Global from "../../components/styled/global";
import PageWrapper from "../../components/styled/pageWrapper";
import { PageTitle, SmallTitle, P } from "../../components/styled/typography";
import PatientNavigation from "../../components/patientNavigation";
import AreYouSure from "../../components/areYouSure";
import Card from "../../components/styled/card";
import { StyledLink } from "../../components/styled/link";
import Button from "../../components/styled/button";
import { api } from "../../api";
import PatientIcon from "../../assets/images/patient-icon.png";
import PatientIconWoman from "../../assets/images/patient-icon-woman.png";
import BertieIcon from "../../assets/images/bertie.png";
import LoadSpinner from "../../components/loadspinner";
import { PageAnimation } from "../../components/styled/animation";
import registrationStore from "../../components/registrationStore";
import { observer } from "mobx-react";
import PatientStore from "../../components/patientStore";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import qs from "qs";
import { isAppInsigthsEnabled } from "../../insights";
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { eventConstants, getInsightUserType } from "../../insights-constants";
import { trackEvent } from './../../insights-track';

const Wrapper = styled(Card)`
    padding: 20px;
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    position: relative;

    @media (min-width: 980px) {
        flex-direction: row;
        justify-content: space-between;
    }
`;

const SideBar = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-right: 30px;

    @media (min-width: 980px) {
        width: 200px;
    }
`;

const Main = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const Avatar = styled.img`
    height: 200px;
    width: 200px;
    margin-right: 5px;
    padding: 20px;
    border: 0.7px solid #d1d1d1;
    box-sizing: border-box;
    margin-bottom: 20px;

    @media (min-width: 980px) {
        height: 200px;
        width: 200px;
        margin-bottom: 10px;
    }
`;

const Box = styled.div`
    @media (min-width: 980px) {
        margin-bottom: 10px;
    }
`;

const Title = styled(SmallTitle)`
    font-weight: 600;
    margin: 10px 0px;
    @media screen and (min-width: 980px) {
        font-size: 20px;
    }
`;

const Text = styled(P)`
    margin: 0px;
`;

const Bold = styled(Text)`
    font-weight: 600;
`;

const Line = styled.hr`
    border: 0.8px solid #a5a5af;
    background-color: #a5a5af;
    margin-bottom: 10px;
    margin-top: 10px;
    width: 100%;

    @media (min-width: 980px) {
        width: 200px;
    }
`;

const EditButton = styled(Button)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    padding: 4px 10px;
    margin-top: 15px;
`;

const EditIcon = styled(FaEdit)`
    color: #ffffff;
    margin-right: 5px;
    font-size: 16px;
`;

const PrintIcon = styled(FaPrint)`
    color: #ffffff;
    margin-right: 5px;
    font-size: 16px;
`;

const EditField = styled(Field)`
    background: #ffffff;
    border: 1px solid #51c0cf;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(197, 197, 197, 0.25);
    border-radius: 10px;
    padding: 10px 10px;
    margin-top: 5px;
    margin-bottom: 10px;
    outline: none;
    width: 100%;
    height: 200px;
    white-space: pre-line;
    font-size: 16px;
`;

const RedButton = styled(Button)`
    background-color: #dc393d;
    border-color: #dc393d;
    margin-top: 10px;

    &:hover {
        background-color: #dc393d;
        border-color: #dc393d;
    }
`;

const ButtonGroup = styled.div`
  display: flex;
  margin-right: 0;
  margin-left: auto;

`

const PatientDetail = observer((props) => {
    const { t, i18n } = useTranslation();
    const store = useContext(registrationStore);
    const patientStore = useContext(PatientStore);
    const user = JSON.parse(store.user);
    const { public_id } = props.match.params;
    const [patient, setPatient] = useState({});
    const [details, setDetails] = useState();
    const [loading, setLoading] = useState(true);
    const isInEditModus = qs.parse(props.location.search, { ignoreQueryPrefix: true }).edit || false;
    const [edit, setEdit] = useState(isInEditModus);
    const [redirect, setRedirect] = useState(false);
    const [Overlay, setOverlay] = useState(false);
    const [bertieEmail, setBertieEmail] = useState();
    const [bertiePassword, setBertiePassword] = useState("!Ya7:W6CD<gs;:S");

    const isAiEnabled = isAppInsigthsEnabled();
    const appInsights = useAppInsightsContext();
    const trackProps = {
        user: {
            type: getInsightUserType(user.therapist),
            public_id: user.public_id
        }
    }

    useEffect(() => {
        api.get("/user/" + public_id).then((response) => {
            setBertieEmail(response.data.user.email);
            setPatient(response.data.user);
            patientStore.setPatient(
                response.data.user.firstName,
                response.data.user.lastName,
                response.data.user.public_id,
                response.data.user.sex
            );
            api.get("/user/details/" + public_id).then((res) => {
                setDetails(res.data.details);
                setLoading(false);
            });
        });
    }, []);

    const handleClick = () => {
        trackEvent(isAiEnabled, appInsights, eventConstants.PRESS.CLIENTS.DETAILS.UPDATE, trackProps);
        setEdit(true);
    };

    const HandleOverlay = () => {
        setOverlay(!Overlay);
    };

    const updateDiagnosis = () => {
        api.get("/user/details/" + public_id).then((response) => {
            setDetails(response.data.details);
        });
    };

    const update = () => {
        setRedirect(true);
    };

    const HandleDelete = async () => {
        api.post("/disconnectPatient/" + patientStore.patient.public_id, { therapist_id: user.public_id }).then(() => {
            api.get(`/user/${patientStore.patient.public_id}`).then((response) => {
                if (response.data.user.plan == "Patiënt" && response.data.user.plan_period == 100) {
                    api.delete(`/user/${patientStore.patient.public_id}`);
                }
            });
        });
    };

    {
        /* API call: 
            -> info zit in state (variabelen: medical & paramedical)
            -> Denk wel dat het nog niet gaat werken om de bestaande tekst in de form te steken, check je dit nog eens?
    */
    }

    return (
        <Global>
            <PageWrapper>
                <TherapistOverlay />
                <PageAnimation>
                    {loading ? (
                        <LoadSpinner />
                    ) : (
                        <PageTitle black>
                            {patientStore.patient.firstName + " " + patientStore.patient.lastName}
                        </PageTitle>
                    )}
                    <PatientNavigation public_id={public_id} />
                    {loading ? (
                        <LoadSpinner />
                    ) : (
                        <Wrapper>
                            <SideBar>
                                <Avatar
                                    src={
                                        patientStore.patient.sex === "Man"
                                            ? PatientIcon
                                            : patientStore.patient.sex === "Bertie"
                                            ? BertieIcon
                                            : PatientIconWoman
                                    }
                                />
                                <Title black>
                                    {patientStore.patient.firstName + " " + patientStore.patient.lastName}
                                </Title>
                                <Line />
                                <Title black>{t("accountNavigation.data")}</Title>
                                {patient.dateOfBirth && (
                                    <Box>
                                        <Bold>{t("gegevensForm.birthDate")}:</Bold>
                                        <Text>{patient.dateOfBirth}</Text>
                                    </Box>
                                )}
                                {patient.socialSecurityNumber && (
                                    <Box>
                                        <Bold>{t("gegevensForm.ssn")}:</Bold>
                                        <Text>{patient.socialSecurityNumber}</Text>
                                    </Box>
                                )}

                                {patient.street && patient.number && (
                                    <Box>
                                        <Bold>{t("patientDetail.living")}:</Bold>
                                        <Text>{patient.street + " " + patient.number},</Text>
                                        <Text>{patient.zipCode + " " + patient.city},</Text>
                                        <Text>{patient.country}</Text>
                                    </Box>
                                )}

                                <Box>
                                    <Bold>{t("loginForm.emailLbl")}:</Bold>
                                    <StyledLink href={!details.isBertie && "mailto:" + patient.email}>
                                        {details.isBertie ? "Bertie@gmail.com" : patient.email}
                                    </StyledLink>
                                </Box>
                                {details.isBertie && (
                                    <>
                                        <Title black>{t("patientDetail.loginAsBertie")}</Title>
                                        <Box>
                                            <Bold>{t("loginForm.emailLbl")}:</Bold>
                                            <Text>{bertieEmail}</Text>
                                            <Bold>{t("patientDetail.password")}:</Bold>
                                            <Text>{bertiePassword}</Text>
                                        </Box>
                                    </>
                                )}
                                {patient.telephone && (
                                    <Box>
                                        <Bold>{t("gegevensForm.tel")}:</Bold>
                                        <Text>{patient.telephone}</Text>
                                    </Box>
                                )}

                                <RedButton onClick={HandleOverlay}>{t("patientDetail.patientVerwijderen")}</RedButton>
                            </SideBar>
                            <AreYouSure display={Overlay} update={update} HandleDelete={HandleDelete} />
                            {redirect && <Redirect to="/t/therapy" />}
                            <Main>
                                {edit ? (
                                    <Formik
                                        initialValues={{
                                            medicalDiagnosis: details.medicalDiagnosis,
                                            paramedicalDiagnosis: details.paramedicalDiagnosis,
                                            therapyPlan: details.therapyPlan,
                                            followUp: details.followUp,
                                            endEvaluation: details.endEvaluation,
                                            helpRequest: details.helpRequest,
                                            history: details.history,
                                            personality: details.personality,
                                        }}
                                        onSubmit={(values, actions) => {
                                            api.patch("/user/details/" + patient.public_id, {
                                                medicalDiagnosis: values.medicalDiagnosis,
                                                paramedicalDiagnosis: values.paramedicalDiagnosis,
                                                therapyPlan: values.therapyPlan,
                                                followUp: values.followUp,
                                                endEvaluation: values.endEvaluation,
                                                helpRequest: values.helpRequest,
                                                history: values.history,
                                                personality: values.personality,
                                            }).then(function (response) {
                                                actions.setSubmitting(false);
                                                setEdit(false);
                                                updateDiagnosis();
                                            });
                                        }}
                                        render={({ errors, touched }, isSubmitting) => (
                                            <Form>
                                                <Box>
                                                    <SmallTitle black>{t("patientDetail.helpRequest")}</SmallTitle>
                                                    <EditField component="textarea" name="helpRequest" />
                                                </Box>
                                                <Box>
                                                    <SmallTitle black>{t("patientDetail.history")}</SmallTitle>
                                                    <EditField component="textarea" name="history" />
                                                </Box>
                                                <Box>
                                                    <SmallTitle black>{t("patientDetail.personality")}</SmallTitle>
                                                    <EditField component="textarea" name="personality" />
                                                </Box>
                                                <Box>
                                                    <SmallTitle black>{t("patientDetail.paramedical")}</SmallTitle>
                                                    <EditField component="textarea" name="paramedicalDiagnosis" />
                                                </Box>
                                                <Box>
                                                    <SmallTitle black>{t("patientDetail.medical")}</SmallTitle>
                                                    <EditField component="textarea" name="medicalDiagnosis" />
                                                </Box>
                                                {/* <Box>
                                                    <SmallTitle black>{t("patientDetail.therapyPlan")}</SmallTitle>
                                                    <EditField component="textarea" name="therapyPlan" />
                                                </Box> */}

                                                <Box>
                                                    <SmallTitle black>{t("patientDetail.followUp")}</SmallTitle>
                                                    <EditField component="textarea" name="followUp" />
                                                </Box>
                                                <Box>
                                                    <SmallTitle black>{t("patientDetail.endEvaluation")}</SmallTitle>
                                                    <EditField component="textarea" name="endEvaluation" />
                                                    <EditButton type="submit" disabled={isSubmitting}>
                                                        {t("patientDetail.saveChange")}
                                                    </EditButton>
                                                </Box>
                                            </Form>
                                        )}
                                    />
                                ) : (
                                    <div>
                                        <Box>
                                            <SmallTitle black>{t("patientDetail.helpRequest")}</SmallTitle>
                                            <Text>{details.helpRequest}</Text>
                                        </Box>
                                        <Box>
                                            <SmallTitle black>{t("patientDetail.history")}</SmallTitle>
                                            <Text>{details.history}</Text>
                                        </Box>
                                        <Box>
                                            <SmallTitle black>{t("patientDetail.personality")}</SmallTitle>
                                            <Text>{details.personality}</Text>
                                        </Box>
                                        <Box>
                                            <SmallTitle black>{t("patientDetail.paramedical")}</SmallTitle>
                                            <Text>{details.paramedicalDiagnosis}</Text>
                                        </Box>
                                        <Box>
                                            <SmallTitle black>{t("patientDetail.medical")}</SmallTitle>
                                            <Text>{details.medicalDiagnosis}</Text>
                                        </Box>

                                        {/* <Box>
                                            <SmallTitle black>{t("patientDetail.therapyPlan")}</SmallTitle>
                                            <Text>{details.therapyPlan}</Text>
                                        </Box> */}

                                        <Box>
                                            <SmallTitle black>{t("patientDetail.followUp")}</SmallTitle>
                                            <Text>{details.followUp}</Text>
                                        </Box>
                                        <Box>
                                            <SmallTitle black>{t("patientDetail.endEvaluation")}</SmallTitle>
                                            <Text>{details.endEvaluation}</Text>
                                        </Box>
                                        <ButtonGroup>
                                            <EditButton onClick={handleClick}>
                                                <EditIcon />
                                                {t("myDocument.adjust")}
                                            </EditButton>
                                            <EditButton onClick={window.print}>
                                                <PrintIcon />
                                                {t("myDocument.print")}
                                            </EditButton>
                                        </ButtonGroup>
                                    </div>
                                )}
                            </Main>
                        </Wrapper>
                    )}
                </PageAnimation>
            </PageWrapper>
        </Global>
    );
});

export default PatientDetail;
