import React, { useState, useContext, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { SmallTitle, P } from "./styled/typography";
import * as Yup from "yup";
import { api, source } from "../api";
import registrationStore from "./registrationStore";
import OpdrachtStore from "./opdrachtStore";
import LoadSpinner from "./loadspinner";
import { Formik, Form, Field } from "formik";
import Button from "./styled/button";
import SelectionOverlay from "./selectionOverlay";
import TaskInAssignment from "./taskInAssignment";
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


const TitleField = styled.input`
  background: #ffffff;
  border: 1px solid #51c0cf;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(197, 197, 197, 0.25);
  border-radius: 10px;
  padding: 10px 10px;
  margin-top: 5px;
  margin-bottom: 20px;
  outline: none;
  width: 100%;
`;

const TaskForm = styled(Form)``;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const Input = styled(Field)`
  background: #ffffff;
  border: 1px solid #51c0cf;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(197, 197, 197, 0.25);
  border-radius: 10px;
  padding: 10px 10px;
  margin-top: 5px;
  outline: none;
  font-size: 16px;
`;

const Label = styled.label`
  font-size: 16px;
  font-weight: 600;
  color: #545454;
`;

const SubmitButton = styled(Button)`
  margin-top: 0px;
  padding-right: 45px;
  padding-left: 45px;
  font-size: 18px;
  background-color: #45ae7c;
  border-color: #45ae7c;

  &:hover {
    background-color: #3a9168;
    border-color: #3a9168;
  }
`;

const Error = styled.p`
  font-size: 16px;
  color: #fff;
  font-style: italic;
  background-color: #dc393d;
  border: 1px solid #dc393d;
  border-radius: 10px;
  padding: 10px 15px;
`;

const Center = styled.div`
  text-align: center;
`;

const Flex = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 980px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
`;

const Half = styled.div`
  width: 100%;

  @media (min-width: 980px) {
    width: 49.25%;
  }
`;

const OneFourth = styled.div`
  width: 100%;

  @media (min-width: 980px) {
    width: 24.5%;
  }
`;

const TaskCards = styled.div`
  display: flex;
  flex-direction: column;
`;

const Description = styled(Field)`
  background: #ffffff;
  border: 1px solid #51c0cf;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(197, 197, 197, 0.25);
  border-radius: 10px;
  padding: 10px 10px;
  margin-top: 5px;
  margin-bottom: 10px;
  outline: none;
  width: 100%;
  height: 60px;
`;

const FlexCenter = styled.div`
  display: flex;
  align-items: flex-end;
`;

const Text = styled(P)`
  margin: 0px;
  color: #545454;
  font-size: 18px;
  padding-bottom: 3px;
`;

const InputNumber = styled(Input)`
  margin-left: 10px;
  margin-right: 10px;
  width: 60px;
  font-size: 16px;
`;

const ThreeFourth = styled.div`
  width: 100%;
`;
const TextR = styled(P)`
  font-style: italic;
  margin-top: 10px;
  margin-bottom: 10px;

  @media (min-width: 980px) {
    margin: 0px;
    margin-right: 15px;
  }
`;

const TaskButton = styled(Button)`
  margin-top: 15px;
`;

const TemplateForm = observer((props) => {
    const { t, i18n } = useTranslation();
    const [error, setError] = useState("");
    const regstore = useContext(registrationStore);
    const user = JSON.parse(regstore.user);
    const store = useContext(OpdrachtStore);
    const [showSelection, setShowSelection] = useState(false);
    const [chosenElement, setChosenElement] = useState({});
    const [exercises, setExercises] = useState();
    const [privateExercises, setPrivateExercises] = useState();
    const [documents, setDocuments] = useState([]);
    const [privateDocs, setPrivateDocs] = useState();
    const [loading, setLoading] = useState(true);
    const [assignment, setAssignment] = useState({});
    const [description, setDescription] = useState("<p></p><p></p><p></p><p></p><p></p>")

    let taskComponents = t("patientopdrachtaanmaken.taskComp");

    const HandleSubmit = () => {
        if (document.getElementById("title").value === "") {
            setError(t("patientopdrachtaanmaken.errTitel"));
        } else {
            if (props.id && (!props.copy)) {
                api.put("/assignmentTemplate/" + props.id, {
                    therapist_id: user.public_id,
                    title: document.getElementById("title").value,
                    tasks: store.taskArray,
                }).then(function (response) {
                    store.setIsFinishedCreating(true);
                    store.clearArray();
                });
            } else {
                api.post("/assignmentTemplate", {
                    therapist_id: user.public_id,
                    title: document.getElementById("title").value,
                    tasks: store.taskArray,
                }).then(function (response) {
                    store.setIsFinishedCreating(true);
                    store.clearArray();
                });
            }
        }
    };

    useEffect(() => {
        store.clearArray();

        api.get("/exercises", {
            params: {
                language: "nederlands",
                discipline: "Logopedie",
            },
        }).then((response) => {
            setExercises(response.data.output);
            api.get("/exercises/" + user.email).then((res) => {
                setPrivateExercises(res.data.output);
                api.get("/documents").then((response) => {
                    setDocuments(response.data.Documents);
                    api.get("/documents/" + user.public_id).then((res) => {
                        setPrivateDocs(res.data.Documents);
                        setLoading(false);
                    });
                });
            });
        });

        if (props.id) {
            api.get("/assignmentTemplate/" + props.id).then(function (response) {
                var task;
                for (task of response.data.assignmentsTemplate.tasks) {
                    var index = store.taskArray.length;

                    store.createObject(
                        index,
                        task.title,
                        task.type,
                        task.task_id,
                        null,
                        task.description,
                        task.total,
                        task.frequencyparam1,
                        task.frequencyparam2
                    );

                    store.addToArray(store.taskObject);
                }

                setAssignment(response.data.assignmentsTemplate);
                setLoading(false);
            });
        }
        return () => {
            source.cancel();
        }
    }, []);

    const handleSwitch = () => {
        setShowSelection(true);
    };

    const Close = () => {
        setShowSelection(false);
    };

    const SetChosenElement = (element) => {
        setChosenElement(element);
    };

    if (store.taskArray.length > 0) {
        taskComponents = store.taskArray.map((task) => {
            return <TaskInAssignment id={task.task} task={task.title} tasktype={task.tasktype} total={task.total} description={task.description} />;
        });
    }

    return (
        <>
            { loading ? (
                <LoadSpinner />
            ) : (
                <>
                    <SmallTitle black>{t("patientopdrachtaanmaken.title")}</SmallTitle>
                    <TitleField type="text" id="title" defaultValue={assignment ? assignment.title : ""}/>
                    {error}
                    <Formik
                        initialValues={{ description: "", total: "", frequency: "", frequencyparam2: "" }}
                        onSubmit={(values, actions) => {
                            const index = store.taskArray.length;
                            store.createObject(
                                index,
                                chosenElement.title,
                                chosenElement.type,
                                chosenElement.id,
                                null,
                                values.description,
                                values.total,
                                values.frequency,
                                values.frequencyparam2
                            );
                            store.addToArray(store.taskObject);
                            actions.resetForm();
                            actions.setSubmitting(false);
                        }}
                        render={({ errors, touched }, isSubmitting) => (
                            <TaskForm>
                                <SmallTitle black>{t("patientopdrachtaanmaken.tasksTitle")}</SmallTitle>
                                <Flex>
                                    {chosenElement.title === undefined ? (
                                        <FormGroup>
                                            <Label>{t("patientopdrachtaanmaken.selTask")}</Label>
                                            <Flex>
                                                <TaskButton type="button" onClick={() => handleSwitch()}>
                                                    {t("patientopdrachtaanmaken.chooseTask")}
                                                </TaskButton>
                                            </Flex>
                                        </FormGroup>
                                    ) : (
                                        <FormGroup>
                                            <Label>{t("patientopdrachtaanmaken.selTask")}</Label>
                                            <Flex>
                                                <TextR>{chosenElement.title}</TextR>
                                                <TaskButton type="button" onClick={() => handleSwitch()}>
                                                    {t("patientopdrachtaanmaken.otherTask")}
                                                </TaskButton>
                                            </Flex>
                                        </FormGroup>
                                    )}
                                </Flex>
                                {touched.total && errors.total && <Error>{errors.total}</Error>}
                                {touched.frequency && errors.frequency && <Error>{errors.frequency}</Error>}
                                {touched.frequencyparam2 && errors.frequencyparam2 && (
                                    <Error>{errors.frequencyparam2}</Error>
                                )}
                                <Flex>
                                    <OneFourth>
                                        <FormGroup>
                                            <Label>{t("checkoutStep.total")}</Label>
                                            <Input type="number" name="total" placeholder="" />
                                        </FormGroup>
                                    </OneFourth>
                                    <ThreeFourth>
                                        <FormGroup>
                                            <Label>{t("patientopdrachtaanmaken.freq")}</Label>
                                            <FlexCenter>
                                                <InputNumber type="number" name="frequencyparam2" />
                                                <Text>{t("patientopdrachtaanmaken.perDay")}</Text>
                                                <InputNumber type="number" name="frequency" placeholder="" />
                                                <Text>{t("patientopdrachtaanmaken.days")}</Text>
                                            </FlexCenter>
                                        </FormGroup>
                                    </ThreeFourth>
                                    <OneFourth></OneFourth>
                                </Flex>

                                <FormGroup>
                                    <Label>{t("opdrachtAanmaken.persInstr")}</Label>
                                    <CKEditor
                                        id="description"
                                        name="description"
                                        editor={ ClassicEditor }
                                        data={description}
                                        onChange={ ( event, editor ) => {
                                            const description = editor.getData();
                                            console.log( { event, editor });
                                            setDescription(description);
                                        }}
                                        config={{
                                            placeholder: t('fileForm.inputDescription'),
                                            toolbar: ['heading', '|', 'bold', 'italic', 'blockQuote', 'link', 'numberedList', 'bulletedList', 'insertTable', 'tableColumn', 'tableRow', 'mergeTableCells',  '|', 'undo', 'redo', '|', 'code'],
                                            cloudServices: {tokenUrl : process.env.REACT_APP_COBRAND_API + "/editor_token/" + user.public_id, uploadUrl : "https://55482.cke-cs.com/easyimage/upload/"}
                                        }}
                                        onError={ ( { willEditorRestart } ) => {
                                            // If the editor is restarted, the toolbar element will be created once again.
                                            // The `onReady` callback will be called again and the new toolbar will be added.
                                            // This is why you need to remove the older toolbar.
                                            if ( willEditorRestart ) {
                                                this.editor.ui.view.toolbar.element.remove();
                                            }
                                        } }
                                    />
                                </FormGroup>

                                <SubmitButton type="submit" disabled={isSubmitting}>
                                    {t("patientopdrachtaanmaken.add")}
                                </SubmitButton>
                            </TaskForm>
                        )}
                        validationSchema={Yup.object().shape({
                            frequency: Yup.number()
                                .required(t("patientopdrachtaanmaken.obl"))
                                .positive(t("patientopdrachtaanmaken.bigger")),
                            frequencyparam2: Yup.number()
                                .required(t("patientopdrachtaanmaken.obl"))
                                .positive("Geef een getal groter dan 0 in."),
                            total: Yup.number()
                                .required(t("patientopdrachtaanmaken.obl"))
                                .positive(t("patientopdrachtaanmaken.bigger")),
                        })}
                    />
                    {showSelection && (
                        <SelectionOverlay
                            close={Close}
                            setChosenElement={SetChosenElement}
                            exercises={exercises}
                            privateExercises={privateExercises}
                            documents={documents}
                            privateDocs={privateDocs}
                        />
                    )}
                    <SmallTitle black>{t("patientopdrachtaanmaken.tasks")}</SmallTitle>
                    <TaskCards>{taskComponents}</TaskCards>
                    <TaskButton onClick={HandleSubmit}>{t("patientopdrachtaanmaken.makeAss")}</TaskButton>
                </>
            )}
        </>
    );
})

export default TemplateForm;