import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import Filter from "../filterComponents/filter";
import LibraryCard from "./libraryCard";
import { api } from "../../api";
import registrationStore from "../registrationStore";
import { useTranslation } from "react-i18next";
import LoadSpinner from "../loadspinner";
import Pagination from "./pagination";
import LanguageStore from "../stores/languageStore";
import useLocalStorage from "../hooks/localStorage";

const Flex = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: stretch;
`;

const Exercises = styled.div`
    width: 100%;
    margin-top: 10px;
`;

const ExerciseWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

function Material(props) {
    const { t, i18n } = useTranslation();
    const store = useContext(registrationStore);
    const user = JSON.parse(store.user);
    const [loading, setLoading] = useState(true);
    const [exercisesToDisplay, setExercisesToDisplay] = useState([]);
    const [documentsToDisplay, setDocumentsToDisplay] = useState([]);
    const [pagination, setPagination] = useLocalStorage(props.tag + 'Pagination', {
        start: 1,
        end: 12,
    });
    const [totalPages, setTotalPages] = useState();
    const [currentPage, setCurrentPage] = useLocalStorage(props.tag + 'page', 1);
    const langStore = useContext(LanguageStore);
    const [filters, setFilters] = useLocalStorage(props.tag + 'FilterData', {
        title: "",
        language: langStore.getLanguage(i18n.language),
        discipline: user.speciality,
        subdiscipline: "",
        tags: []
    });

    function getFilterResults(data) {
        setFilters(data);
        resetPagination();
    }

    function resetPagination() {
        setPagination({
            start: 1,
            end: 12,
        });
        setCurrentPage(1);
    }

    function getMaterial() {
        setLoading(true);
        const params = {
            title: filters.title,
            language: filters.language,
            discipline: filters.discipline,
            subdiscipline: filters.subdiscipline,
            start: pagination.start,
            end: pagination.end,
            onlyDocs: props.onlyDocs ? props.onlyDocs : false,
            tags: filters.tags
        };
        api.get((props.public ? "/material" : "/material/" + props.public_id), {
            params: params,
        }).then((response) => {
            var material = response.data.output;
            setExercisesToDisplay(Array.from(material[0]));
            setDocumentsToDisplay(Array.from(material[1]));
            setTotalPages(Math.ceil(response.data.total / 12));
            setLoading(false);
        });
    }

    useEffect(() => {
        getMaterial();
    }, [filters, pagination]);

    useEffect(() => {
        if (props.filterData !== undefined) {
            getFilterResults(props.filterData);
        }
    }, [props.filterData]);

    //update
    function update() {
        setLoading(true);
        setTimeout(() => {
            getMaterial();
        }, 2500);
    };

    //pagination
    function handlePageChange(dir) {
        setPagination({
            start: pagination.start + 12 * dir,
            end: pagination.end + 12 * dir,
        });
        setCurrentPage(currentPage + dir);
    }

    function handlePageForce(page) {
        setPagination({
            start: 12 * (page - 1) + 1,
            end: page * 12,
        });
        setCurrentPage(page);
    }

    return (
        <>
            <div>
                {!props.taskSelect && <Filter tag={props.tag} useTags={true} filters={filters} filterCallback={(data) => getFilterResults(data)} small={props.small} />}
                {loading ? (
                    <LoadSpinner />
                ) : (
                    <ExerciseWrapper>
                        <Exercises>
                            <Flex>
                                {exercisesToDisplay &&
                                    exercisesToDisplay.map((material) => {
                                        return (<LibraryCard
                                                info={material}
                                                taskSelect={props.taskSelect}
                                                public={props.public}
                                                callBack={
                                                    props.selectCallback &&
                                                    ((id, title, type) => props.selectCallback(id, title, type))
                                                }
                                                showType={props.showType}
                                            />
                                        );
                                    })}
                            </Flex>
                        </Exercises>
                        <Exercises>
                            <Flex>
                                {documentsToDisplay &&
                                    documentsToDisplay.map((material) => {
                                        return (<LibraryCard
                                                info={material}
                                                taskSelect={props.taskSelect}
                                                public={props.public}
                                                callBack={
                                                    props.selectCallback &&
                                                    ((id, title, type) => props.selectCallback(id, title, type))
                                                }
                                                showType={props.showType}
                                            />
                                        );
                                    })}
                            </Flex>
                        </Exercises>
                        {totalPages ? (
                            <Pagination
                                nextFunction={() => handlePageChange(1)}
                                prevFunction={() => handlePageChange(-1)}
                                totalPages={totalPages}
                                currentPage={currentPage}
                                pageForce={handlePageForce}
                            />
                        ) : (
                            <div>{t("materiaalRaadplegen.noMaterial")}</div>
                        )}
                    </ExerciseWrapper>
                )}
            </div>
        </>
    );
}

export default Material;
