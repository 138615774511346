import styled, { keyframes } from 'styled-components'

const fadein = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`
const Card = styled.div`
    background: #FFFFFF;
    border: 0.5px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(197, 197, 197, 0.25);
    border-radius: 10px;
    padding: 20px;
    animation: ${fadein} 1s;
`

export const ExCard = styled.div`
    background: #F8F8F8;
    border: 0.5px solid #F8F8F8;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(197, 197, 197, 0.25);
    border-radius: 10px;
    padding: 20px;
    animation: ${fadein} 1s;
    width: 100%;
    min-width: 100%;
    margin-bottom: 15px;
    cursor: pointer;
    word-wrap:break-word;

    &.active{
        border: 1px solid #51C0CF;
        background-color: #FFFFFF;
    }

    @media (min-width: 980px){
        width: 32.5%;
        min-width: 32.5%;
        margin-right: 0.7%;
    }

`

export default Card

