import React, { useContext, useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import styled from "styled-components";
import TherapistOverlay from "../../components/overlays/therapistOverlay";
import Flash from "../../components/styled/flashMessage";
import Global from "../../components/styled/global";
import PageWrapper from "../../components/styled/pageWrapper";
import { PageTitle, SmallTitle, P } from "../../components/styled/typography";
import Card from "../../components/styled/card";
import Button from "../../components/styled/button";
import { PageAnimation } from "../../components/styled/animation";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { api } from "../../api";
import ExerciseIcon from "../../assets/images/exercise-icon.png";
import { FaCloudUploadAlt } from "react-icons/fa";
import fileUploadStore from "../../components/fileUploadStore";
import ExerciseForm from "../../components/exerciseForm/exerciseForm";

const FormGroup = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
`;
const Error = styled.p`
    font-size: 16px;
    color: #fff;
    font-style: italic;
    background-color: #dc393d;
    border: 1px solid #dc393d;
    border-radius: 10px;
    padding: 10px 15px;
`;

const FlexAround = styled.div`
    display: flex;
    flex-direction: column;

    @media (min-width: 980px) {
        flex-direction: row;
        justify-content: space-around;
        align-items: stretch;
        margin: 50px 0px;
    }
`;

const FlexBetween = styled.div`
    @media (min-width: 980px) {
        display: flex;
        justify-content: space-between;
    }
`;

const Flex = styled.div`
    @media (min-width: 980px) {
        display: flex;
        flex-wrap: wrap;
    }
`;

const Half = styled.div`
    width: 49%;
`;

const Center = styled.div`
    text-align: center;
`;

const Input = styled.input`
    background: #ffffff;
    border: 1px solid #51c0cf;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(197, 197, 197, 0.25);
    border-radius: 10px;
    padding: 10px 10px;
    outline: none;
    font-size: 16px;
    margin: 10px 0px;
    width: 100%;
`;

const ChoseFile = styled(Input)`
    display: none;
`;

const SubText = styled(P)`
    margin-top: 7px;
    font-weight: ${(props) => (props.bold ? "bold" : "normal")};
`;

const ExerciseCard = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    max-width: 300px;
    border: 1px solid lightgrey;
    border-radius: 5px;
    padding: 25px 20px;
    cursor: pointer;
    margin-bottom: 18px;

    &.clicked {
        border: 1px solid #51c0cf;
    }

    @media (min-width: 980px) {
        margin-bottom: 0px;
    }
`;

const ExerciseExpl = styled(P)`
    margin-top: 5px;
    margin-bottom: 15px;
    text-align: center;
`;

const ExIcon = styled.img`
    width: 75px;
`;

const Iframe = styled.iframe`
    width: 100%;
    border: none;
    border-shadow: none;
    height: 750px;
`;

const IframeCard = styled(Card)`
    width: 100%;
    padding: 0px;
    margin: 0px;
`;

const FileCard = styled(Card)`
    width: 33%;
    margin-right: 0.33%;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Label = styled.label`
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
`;

const UploadButton = styled(Label)`
    justify-content: flex-start;
    width: auto;
`;

const FlexColumn = styled.div`
    @media (min-width: 980px) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
`;

const UploadIcon = styled(FaCloudUploadAlt)`
    margin-right: 7px;
`;

const DeleteButton = styled(Button)`
    background-color: #dc393d;
    border-color: #dc393d;

    &:hover {
        background-color: #dc393d;
        border-color: #dc393d;
    }

    &:clicked {
        background-color: #dc393d;
        border-color: #dc393d;
    }
`;

const ButtonGroup = styled.div`
    display: flex;
    flex-direction: column;
    margin: 5px;
`

const VerticalSpace = styled.div`
    padding: 2px
`

const oefeningAanvragen = observer(() => {
    const { t, i18n } = useTranslation();
    const [redirect, setRedirect] = useState(false);
    const [kindOfExercise, setKindOfExercise] = useState("");
    const [isAllreadyUploadingError, setIsAllreadyUploadingError] = useState(false);
    const [filesAsDataTransfer, setFilesAsDataTransfer] = useState([]);
    const [filesAsArray, setFilesAsArray] = useState([]);
    const fileStore = useContext(fileUploadStore);

    const autoScrollToForm = async (exercise) => {
        if (document.getElementById("form")) {
            const element = document.getElementById("form");
            element.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    };

    const setSelectedForm = (exercise, e) => {
        autoScrollToForm(exercise);
        setKindOfExercise(exercise);

        if (e.target.tagName == "DIV") {
            const siblings = e.target.parentNode.childNodes;
            for (let i = 0; i < siblings.length; i++) {
                siblings[i].classList.remove("clicked");
            }
            e.target.classList.add("clicked");
        } else {
            const siblings = e.target.parentNode.parentNode.childNodes;
            for (let i = 0; i < siblings.length; i++) {
                siblings[i].classList.remove("clicked");
            }
            e.target.parentNode.classList.add("clicked");
        }
    };

    const submitRequestExercise = (values) => {
        if (fileStore.isUploading) {
            setIsAllreadyUploadingError(true);
        } else {
            api.post("/exercise_request", values).then((res) => {
                if (filesAsDataTransfer.length > 0) {
                    var requestId = res.data.message.match(/request (.*) created/);
                    fileStore.setId(requestId[1]);
                    fileStore.setFiles(filesAsDataTransfer);
                    fileStore.setIsUploading(false);
                    // Post request to database
                    api.post("/exercise_request", { id: fileStore.id, files: filesAsArray.map(x => x.name) });
                }
                setRedirect(true);
            });
        }
    };

    const changeSelectedFiles = (newFiles) => {
        //const newFiles = e.target.files;
        const allFiles = new DataTransfer();

        // Populate allFiles with exisiting and new files
        for (var i = 0; i < filesAsDataTransfer.length; ++i) {
            const thisItem = filesAsDataTransfer.item(i);
            allFiles.items.add(thisItem);
        }

        for (let i = 0; i < newFiles.length; i++) {
            const thisItem = newFiles[i];
            allFiles.items.add(thisItem);
        }

        // Set initial upload progress to 0
        let progressObject = {};
        for (let i = 0; i < allFiles.length; i++) {
            progressObject[allFiles[i].name] = 0;
        }

        setFilesAsDataTransfer(allFiles.files);
    };

    const handleDeleteFile = (e) => {
        const fileName = e.target.value;
        let newFiles = new DataTransfer();
        for (let i = 0; i < filesAsDataTransfer.length; ++i) {
            const thisItem = filesAsDataTransfer.item(i);
            if (thisItem.name !== fileName) {
                newFiles.items.add(thisItem);
            }
        }

        let progressObject = {};
        for (let i = 0; i < filesAsDataTransfer.length; i++) {
            progressObject[filesAsDataTransfer[i].name] = 0;
        }

        setFilesAsDataTransfer(newFiles.files);
    };

    const dataTransferToArrayConverter = (dataTransfer) => {
        let filesArray = [];
        for (let i = 0; i < dataTransfer.length; i++) {
            const file = dataTransfer.item(i);
            filesArray.push(file);
        }
        return filesArray;
    };
    
    useEffect(() => {
        // Make sure all selected files are visible
        const filesArray = dataTransferToArrayConverter(filesAsDataTransfer);
        setFilesAsArray(filesArray);
    }, [filesAsDataTransfer]);

    return (
        <Global>
            <PageWrapper>
                <TherapistOverlay />
                <PageAnimation>
                    <PageTitle black>{t("OefeningAanvragen.titel")}</PageTitle>

                    {/* SELECT KIND OF EXERCISE */}
                    <SmallTitle black>{t("OefeningAanvragen.stap1")}</SmallTitle>
                    <SubText>
                        {t("OefeningAanvragen.stap1Subtekst")}
                        <SubText bold>{t("OefeningAanvragen.messageOnlyEnglish")}</SubText>
                    </SubText>
                    <FlexAround>
                        <ExerciseCard onClick={(e) => setSelectedForm("dagboek", e)}>
                            <ExIcon src={ExerciseIcon} />
                            <h4>{t("OefeningAanvragen.dagboek")}</h4>
                            <ExerciseExpl>{t("OefeningAanvragen.dagboekUitleg")}</ExerciseExpl>
                            <ButtonGroup>
                                <Button small>{t("OefeningAanvragen.create")}</Button>
                                <VerticalSpace />
                                <Link
                                    to={{
                                        pathname: i18n.language === "nl" ? "/t/consultMaterial/taskDetail/640" : "/t/consultMaterial/taskDetail/78",
                                        search: "?preview=true",
                                    }}
                                >
                                    <Button small>{t("OefeningAanvragen.voorbeeldBekijken")}</Button>
                                </Link>
                            </ButtonGroup>
                            
                        </ExerciseCard>
                        <ExerciseCard onClick={(e) => setSelectedForm("stappenplan", e)}>
                            <ExIcon src={ExerciseIcon} />
                            <h4>{t("OefeningAanvragen.stappenplan")}</h4>
                            <ExerciseExpl>{t("OefeningAanvragen.stappenplanUitleg")}</ExerciseExpl>
                            <ButtonGroup>
                                <Button small>{t("OefeningAanvragen.create")}</Button>
                                <VerticalSpace />
                                <Link
                                     to={{
                                        pathname: i18n.language === "nl" ? "/t/consultMaterial/taskDetail/641" : "/t/consultMaterial/taskDetail/75",
                                        search: "?preview=true",
                                    }}
                                >
                                <Button small>{t("OefeningAanvragen.voorbeeldBekijken")}</Button>
                                </Link>
                            </ButtonGroup>
                        </ExerciseCard>
                        <ExerciseCard onClick={(e) => setSelectedForm("stappenplanValidatie", e)}>
                            <ExIcon src={ExerciseIcon} />
                            <h4>{t("OefeningAanvragen.stappenplanMetValidatie")}</h4>
                            <ExerciseExpl>{t("OefeningAanvragen.stappenplanMetValidatieUitleg")}</ExerciseExpl>
                            <ButtonGroup>
                                <Button small>{t("OefeningAanvragen.create")}</Button>
                                <VerticalSpace />
                                <Link
                                    to={{
                                        pathname: i18n.language === "nl" ? "/t/consultMaterial/taskDetail/637" : "/t/consultMaterial/taskDetail/74",
                                        search: "?preview=true",
                                    }}
                                >
                                    <Button small>{t("OefeningAanvragen.voorbeeldBekijken")}</Button>
                                </Link>
                            </ButtonGroup>
                        </ExerciseCard>
                    </FlexAround>

                    {/* EXERCISE FORM SECTION */}
                    <SmallTitle black id="form">
                        {t("OefeningAanvragen.stap2")}
                    </SmallTitle>
                    <SubText>{t("OefeningAanvragen.stap2Subtekst")}</SubText>
                    <ExerciseForm
                        exerciseType={kindOfExercise}
                        onSubmit={submitRequestExercise}
                        isUploading={isAllreadyUploadingError}
                        files={filesAsArray}
                        changeSelectedFiles={changeSelectedFiles}
                        handleDeleteFile={handleDeleteFile}
                    />

                    {/* CLARIFICATION SECTION */}
                    <SmallTitle black>{t("OefeningAanvragen.stap5")}</SmallTitle>
                    <SubText>{t("OefeningAanvragen.stap5Subtekst")}</SubText>

                    {redirect && (
                        <Redirect
                            to={{
                                pathname: "/t/CreateMaterial",
                                search: "?exercisecreated=true",
                            }}
                        ></Redirect>
                    )}
                </PageAnimation>
            </PageWrapper>
        </Global>
    );
});

export default oefeningAanvragen;
