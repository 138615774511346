import React, {useContext, useState} from 'react';
import {withRouter} from 'react-router-dom';
import {INITIATOR} from '../../services/userService';
import {PrimaryButton} from '@eu.jstack/react-button';
import {observer} from 'mobx-react-lite';
import {color, compose, space, flexbox} from 'styled-system';
import styled from 'styled-components';
import SelectionOverlay from "../../../components/selectionOverlay";
import libraryService from '../../services/libraryService';
import CommunicationStore from '../../stores/communication.store';
import LibraryStore from '../../stores/library.store';
import Exercise from './exercise';
import Media from './media';
import Document from './document';
import Text from '../text';
import { useTranslation } from "react-i18next";

const Container = styled.div`
    flex: 2.5;
    height: 65vh;
    ${compose(space)}
`;

const ExercisePlaceHolder = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
`;

const ScoreContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const ColoredBullet = styled.div`
    display: inline-block;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    ${compose(color, space)}
`;

const Box = styled.div`
    display: flex;
    ${compose(flexbox, space)}
`;

const Scrollable = styled.div`
    height: 75vh;
    overflow-y:auto;
`;

const ExerciseContainer = observer(({showSelectExerciseButton, location}) => {
    const isPatient = location.state?.role !== INITIATOR;
    const communicationStore = useContext(CommunicationStore);
    const libraryStore = useContext(LibraryStore);
    const [showExerciseOverlay, setShowExerciseOverlay] = useState(false);
    const [isLibraryItemChosen, setLibraryItemChosen] = useState(false);
    const { t, i18n } = useTranslation();

    const sendSelectedExerciseToPatient = (exercise) => {
        setLibraryItemChosen(true);
        libraryStore.setActiveExerciseResult(null);
        libraryStore.setActiveStep(1);
        libraryStore.setActiveMiniStep(1);
        libraryService.sendSelectedExerciseToPatient(exercise.id, communicationStore.activeCall.id);
    };

    const sendSelectedDocumentToPatient = (document) => {
        setLibraryItemChosen(true);
        libraryService.sendSelectedDocumentToPatient(document.id, communicationStore.activeCall.id);
    };

    const sendSelectedMediaToPatient = (media) => {
        setLibraryItemChosen(true);
        libraryService.sendSelectedMediaToPatient(media.id, communicationStore.activeCall.id);
    };

    const sendResultToTherapist = (result) => {
        if (!isPatient) return;
        libraryService.sendResultToTherapist(result, communicationStore.activeCall.id);
    };

    const setFromLibrary = (libraryItem) => {
        switch (libraryItem.type) {
            case 'dagboek': case 'stappenplanValidatie': case 'stappenplan':
                //This is an exercise
                sendSelectedExerciseToPatient(libraryItem);
                break;
            case 'Document':
                //This is a document
                sendSelectedDocumentToPatient(libraryItem);
                break;
            default:
                //This is a media file
                sendSelectedMediaToPatient(libraryItem);
                break;
        }
    }

    return (
        <React.Fragment>
            {showExerciseOverlay && (
                <SelectionOverlay
                    close={() => setShowExerciseOverlay(false)}
                    setChosenElement={setFromLibrary}
                />
            )}

            {!libraryStore.activeExerciseResult && (
                <Container mr={5}>
                    {showSelectExerciseButton && !isPatient && !(libraryStore.activeExerciseId || libraryStore.activeDocumentId || libraryStore.activeMediaId) && (
                        <ExercisePlaceHolder>
                            <PrimaryButton onClick={() => setShowExerciseOverlay(true)}>
                                {!isLibraryItemChosen && t('videochat.selectItem')}
                            </PrimaryButton>
                        </ExercisePlaceHolder>
                    )}
                    {(!isPatient && (libraryStore.activeExerciseId || libraryStore.activeDocumentId || libraryStore.activeMediaId)) && (
                        <Box flexDirection="column" alignItems="center" mt={6} mb={6}>
                            <Text mb={3} color="greyLight.0">
                                {t('videochat.therapistDescription')}
                            </Text>
                            <PrimaryButton onClick={() => setShowExerciseOverlay(true)}>
                                {!isLibraryItemChosen && t('videochat.selectItem')}
                                {isLibraryItemChosen && t('videochat.selectOtherItem')}
                            </PrimaryButton>
                        </Box>
                    )}
                    {libraryStore.activeExerciseId && (
                        <Scrollable>
                            <Exercise onEnd={sendResultToTherapist} id={libraryStore.activeExerciseId}/>
                        </Scrollable>
                    )}
                    {libraryStore.activeDocumentId && (
                        <Scrollable>
                            <Document id={libraryStore.activeDocumentId}/>
                        </Scrollable>
                    )}
                    {libraryStore.activeMediaId && (
                        <Scrollable>
                            <Media id={libraryStore.activeMediaId}/>
                        </Scrollable>
                    )}
                </Container>
            )}

            {libraryStore.activeExerciseResult && (
                <Container mr={5}>
                    <ExercisePlaceHolder>
                        <ScoreContainer>
                            <Text fontSize={5}>{t('videochat.score')}</Text>
                            <Text mt={3} color="greyLight.0">
                                <ColoredBullet mr={2} bg={'#F0C239'}/>
                                {t('videochat.yellow')}: {libraryStore.activeExerciseResult.scores['Gele vragen']}</Text>
                            <Text mt={1} color="greyLight.0">
                                <ColoredBullet mr={2} bg={'#24AE5F'}/>
                                {t('videochat.green')}: {libraryStore.activeExerciseResult.scores['Groene vragen']}</Text>
                            <Text mt={3} color="greyLight.0">
                                {t('videochat.totalScore')}: {libraryStore.activeExerciseResult.scores['Totaalscore']}</Text>
                        </ScoreContainer>

                        {!isPatient && (
                            <PrimaryButton mt={5} onClick={() => setShowExerciseOverlay(true)}>
                                {t('videochat.startNew')}
                            </PrimaryButton>
                        )}
                    </ExercisePlaceHolder>
                </Container>
            )}
        </React.Fragment>
    )
});

export default withRouter(ExerciseContainer);
