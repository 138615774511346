import React from "react";
import styled from "styled-components";

import Card from "../components/styled/card";
import { P } from "../components/styled/typography";
import { useTranslation } from "react-i18next";
import {FaCalendar, FaInfoCircle, FaPlus} from "react-icons/fa";

const TaskCard = styled(Card)`
    border: 0.5px solid #aeadad;
    padding: 10px 40px 10px 20px;
    margin: 10px 0px 0px 0px;
    width: 100%;

    @media (min-width: 980px) {
        width: 49.5%;
    }
`;

const Bold = styled(P)`
    font-weight: bold;
    margin: 0px;
    text-transform: capitalize;
    word-break: break-word;
`;

const Text = styled(P)`
    margin: 0px;
`;

const TaskCardFooterStats = styled(P)`
    margin: 0.5em 0px 0px 0px;
    font-size: 0.7em;
    cursor: default;
`;

const Italic = styled.span`
    font-style: italic;
    font-size: 0.9em;
`;

const InfoIcon = styled(FaInfoCircle)`
    font-size: 0.7em;
    margin: 0px 5px 0px 0px;
`;

const CalendarIcon = styled(FaCalendar)`
  font-size: 0.7em;
  margin: 0px 5px 0px 10px;
`;

function TaskCardFooter(props) {
    const { t, i18n } = useTranslation();
    let firstDate = "";
    let lastDate =  "";

    if (props.task.dates !== undefined && props.task.dates.length > 0){
        firstDate = new Date(props.task.dates[0].date).toLocaleDateString(i18n.language);
        lastDate =  new Date(props.task.dates[props.task.dates.length-1].date).toLocaleDateString(i18n.language);
    }

    return (
        <TaskCardFooterStats>
            <InfoIcon></InfoIcon><Italic>{t("checkoutStep.total")}: #{props.task?.total} </Italic>
            {firstDate !== "" ? (
                <><CalendarIcon></CalendarIcon><Italic>{firstDate} &raquo; {lastDate}</Italic></>
            ) : (
                <></>
            )}
        </TaskCardFooterStats>
    )
}

function TaskInAssignmentSmall(props) {
    const { t, i18n } = useTranslation();

    return (
        <TaskCard>
            <Bold>
                {props.task.type}: {props.task.title}
            </Bold>
            {/*<Text>{t('patientopdrachtaanmaken.freq')}: {props.task.total} {t('patientopdrachtaanmaken.perDay')} {props.task.frequencyparam1} {t('patientopdrachtaanmaken.days')}</Text>*/}

            <TaskCardFooter task={props.task} />
        </TaskCard>
    );
}

export default TaskInAssignmentSmall;
