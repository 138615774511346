import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { PageTitle, P } from "../styled/typography";
import Button from "../styled/button";
import Card from "../styled/card";
import { Redirect } from "react-router-dom";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import Flash from "../styled/flashMessage";
import Metronome from "../exerciseUtilities/metronoom/Metronoom";
import Chrono from "../exerciseUtilities/chronometer/Chronometer";
import Recorder from "../video/Recorder";
import { useTranslation } from "react-i18next";
import resultStore from "../resultStore";
import { observer } from "mobx-react";

const STEMCard = styled(Card)`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding: 40px 20px;

    @media (min-width: 980px) {
        padding-right: 80px;
        padding-left: 80px;
        padding-top: 20px;
        padding-bottom: 60px;
    }
`;

const TaskCard = styled.div`
    width: 100%;

    @media (min-width: 980px) {
        width: 100%;
    }
`;

const OptionsCard = styled(Card)`
    width: 100%;
    margin-top: 15px;

    @media (min-width: 768px) {
        width: 29.25%;
        margin-top: 0px;
        margin-left: 15px;

        &.communication-option-component {
            width: 100%;
            margin-top: 15px;
            margin-left: 0px;
        }
    }
`;

const Flex = styled.div`
    display: flex;
    flex-direction: column;

    @media (min-width: 980px) {
        flex-direction: row;
        justify-content: space-between;
    }
`;

const FlexCards = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: stretch;

    @media (min-width: 768px) {
        flex-direction: row;
        align-items: flex-start;

        &.communication-component {
            flex-direction: column;
            align-items: stretch;
            margin-bottom: 30px;
        }
    }
`;

const Title = styled(P)`
    font-weight: bold;
    font-size: 24px;
    color: #545454;
    text-align: center;
    margin-top: 0px;
    @media (min-width: 980px) {
        margin-top: 30px;
    }
`;

const Bold = styled(P)`
    font-weight: bold;
    color: #545454;
    text-align: center;
`;

const Text = styled(P)`
    text-align: center;
    padding: 0px 15px;
`;
const Audio = styled.audio`
    max-width: 80%;
    margin-top: 20px;
    margin-bottom: 20px;
`;

const NextIcon = styled(FaArrowRight)`
    color: #ffffff;
    padding-right: 10px;
    font-size: 16px;
    transform: translateY(2px);
`;

const NextIconPrimary = styled(FaArrowRight)`
    color: #51c0cf;
    cursor: pointer;
    font-size: 45px;
    position: absolute;
    top: 50%;
    right: 30px;
`;

const BackIconPrimary = styled(FaArrowLeft)`
    color: #51c0cf;
    cursor: pointer;
    font-size: 45px;
    position: absolute;
    top: 50%;
    left: 30px;
`;

const BackIcon = styled(FaArrowLeft)`
    color: #afafaf;
    font-size: 16px;
    padding-right: 10px;
    cursor: pointer;
`;

const BackText = styled(P)`
    margin: 0px;
    position: absolute;
    bottom: 20px;
    left: 30px;
    cursor: pointer;
`;

const BlueText = styled(P)`
    color: #51c0cf;
`;

const Description = styled(P)`
    margin-top: 0px;
    color: #545454;
`;

const Img = styled.img`
    width: 80%;

    @media (min-width: 980px) {
        width: 60%;
    }
`;

const NextButton = styled(Button)`
    margin-bottom: 20px;
    @media (min-width: 980px) {
        margin-bottom: auto;
    }
`;

const CenterFlex = styled.div`
    display: flex;
    justify-content: center;
`;

const Video = styled.video`
    width: 80%;
    margin-bottom: 10px;
`;

const EndButton = styled(Button)`
    margin-top: 25px;
`;

const LargeImage = styled(Img)`
    @media (min-width: 980px) {
        width: 60%;
    }
`;

const Stappenplan = observer ((props)  => {
    const { t, i18n } = useTranslation();
    const exercise = props.exercise;
    const [currentStep, setStep] = useState(props.activeStep ?? 1);
    const [miniStep, setCurrentMiniStep] = useState(props.activeMiniStep ?? 1);
    const [loading, setLoading] = useState(true);
    const [redirect, setRedirect] = useState(false);
    const [showSucces, setShowSucces] = useState(false);
    const [goalIMG, setGoalIMG] = useState();
    const [goalVideo, setGoalVideo] = useState();
    const [prepIMG, setPrepIMG] = useState();
    const [prepVideo, setPrepVideo] = useState();
    const [medIMG, setMedIMG] = useState();
    const [compAudio, setCompAudio] = useState();
    const [compVideo, setCompVideo] = useState();
    const [questionUrls, setQuestionUrls] = useState([]);
    const [questionImgUrls, setQuestionImgUrls] = useState([]);
    const [questionVideoUrls, setQuestionVideoUrls] = useState([]);
    const resultstore = useContext(resultStore);
    const [showEndRecorder, setShowEndRecorder] = useState(false);
    const [isExample, setIsExample] = useState(false);
    const [isReview, setIsReview] = useState(false);

    useEffect(() => { if(props.activeStep) setStep(props.activeStep) }, [props.activeStep])
    useEffect(() => { if(props.activeMiniStep) setCurrentMiniStep(props.activeMiniStep) }, [props.activeMiniStep])

    const setCurrentStep = (newStep) => {
        if(props.onStepChange) props.onStepChange(newStep);
        setStep(newStep);
    }

    const setMiniStep = (newMiniStep) => {
        if(props.onMiniStepChange) props.onMiniStepChange(newMiniStep);
        setCurrentMiniStep(newMiniStep);
    }

    function getUrl(type, id) {
        if (type === "question") {
            var output = questionUrls.find(url => url.id === id);
            return output ? output.value : output;
        } else if (type === "questionImage") {
            var output = questionImgUrls.find(url => url.id === id);
            return output ? output.value : output;
        } else if (type === "questionVideo") {
            var output = questionVideoUrls.find(url => url.id === id);
            return output ? output.value : output;
        }
    }

    const nextStep = () => {
        if (currentStep < 4) {
            if (
                exercise.preparation && ! Array.isArray(exercise.preparation) && exercise.preparation.text != '<p></p><p></p><p></p><p></p><p></p>' && exercise.goal
            ) {
                setCurrentStep(currentStep + 1);
            } else {
                if (currentStep === 3) {
                    setCurrentStep(4);
                } else {
                    setCurrentStep(3);
                }
            }
        }
    };
    const prevStep = () => {
        if (currentStep > 1) {
            if (
                exercise.preparation && ! Array.isArray(exercise.preparation) && exercise.preparation.text != '<p></p><p></p><p></p><p></p><p></p>' && exercise.goal
            ) {
                setCurrentStep(currentStep - 1);
            } else {
                setCurrentStep(1);
            }
        }
    };

    const nextMiniStep = () => {
        setMiniStep(miniStep + 1);
    };
    const prevMiniStep = () => {
        setMiniStep(miniStep - 1);
    };

    const toResult = () => {
        if (resultstore.isRecording) {
            setShowEndRecorder(true);
        } else {
            setShowEndRecorder(false);
            if (props.redirectEnabled) {
                setRedirect(true);
            } else if(props.isExample) {
                setIsExample(true);
            } else if(props.isReview) {
                setIsReview(true);
            } else {
                props.endExercise();
            }
        }
    };

    const ReceiveRecording = (recording) => {
        props.HandleRecording(recording);
        setShowSucces(true);
        setTimeout(function () {
            setShowSucces(false);
        }, 5000);
    };

    // Change styling for communication module
    const FlexCardsId = document.getElementById("flex-cards");
    const OptionsCardId = document.getElementById("options-card");
    if (props.isCommunicationComponent && FlexCardsId && OptionsCardId) {
        FlexCardsId.classList.add("communication-component");
        OptionsCardId.classList.add("communication-option-component");
    }

    useEffect(() => {
        if (! exercise.goal) {
            if (! exercise.preparation) {
                setCurrentStep(3);
            } else {
                setCurrentStep(2);
            }
        }

        if (exercise.media_complete_id) {
            props.getRessource(exercise.media_complete_id).then((res) => {
                setMedIMG(res);
            });
        }
        if (exercise.audio_complete_id) {
            props.getRessource(exercise.audio_complete_id).then((res) => {
                setCompAudio(res);
            });
        }
        if (exercise.video_complete_id) {
            props.getRessource(exercise.video_complete_id).then((res) => {
                setCompVideo(res);
            });
        }
        if (
            exercise.goal &&
            exercise.goal.media_id
        ) {
            props.getRessource(exercise.goal.media_id).then((res) => {
                setGoalIMG(res);
            });
        }
        if (
            exercise.goal &&
            exercise.goal.video_id
        ) {
            props.getRessource(exercise.goal.video_id).then((res) => {
                setGoalVideo(res);
            });
        }
        if (
            exercise.preparation &&
            exercise.preparation.media_id
        ) {
            props.getRessource(exercise.preparation.media_id).then((res) => {
                setPrepIMG(res);
            });
        }
        if (
            exercise.preparation &&
            exercise.preparation.video_id
        ) {
            props.getRessource(exercise.preparation.video_id).then((res) => {
                setPrepVideo(res);
            });
        }

        exercise.steps.map((step, index) => {
            if (step.media_id) {
                props.getRessource(step.media_id).then((response) => {
                    setQuestionImgUrls((questionImgUrls) => [...questionImgUrls, {id: step.media_id, value: response}]);
                });
            }
            if (step.video_id) {
                props.getRessource(step.video_id).then((response) => {
                    setQuestionVideoUrls((questionVideoUrls) => [...questionVideoUrls, {id: step.video_id, value: response}]);
                });
            }
            if (step.audio_id) {
                props.getRessource(step.audio_id).then((response) => {
                    setQuestionUrls((questionUrls) => [...questionUrls, {id: step.audio_id, value: response}]);
                    
                });
            }
            if (exercise.steps.length === index + 1) {
                setLoading(false);
            }
        });
    }, []);

    if (loading) {
        return "";
    } else {
        return (
            <>
                <PageTitle black>{exercise.title}</PageTitle>
                <Description><div dangerouslySetInnerHTML={{__html: props.taskDescription }} /></Description>
                <FlexCards id="flex-cards">
                    <TaskCard>
                        {currentStep === 1 && (
                            <STEMCard>
                                <Title>{t("stappenplan.goal")}</Title>
                                {exercise.goal.media_id && (
                                    <Img src={goalIMG}></Img>
                                )}
                                {exercise.goal.video_id && goalVideo && (
                                    <Video controls controlsList="nodownload">
                                        <source src={goalVideo} type="video/mp4; base64" />
                                    </Video>
                                )}
                                {/* {exercise.goal.text !== undefined &&<BlockContent blocks={exercise.goal.text} />} */}
                                {/*{exercise.goal.text.split('\n').map(str => <p>{str}</p>)}*/}
                                {exercise.goal.text && <Description><div dangerouslySetInnerHTML={{__html: exercise.goal.text }} /></Description>}
                                <Button onClick={nextStep}>
                                    <NextIcon />
                                    {t("stappenplan.nextStep")}
                                </Button>
                            </STEMCard>
                        )}
                        {currentStep === 2 && (
                            <STEMCard>
                                <Title>{t("stappenplan.prep")}</Title>
                                {exercise.preparation.media_id && (
                                    <Img src={prepIMG}></Img>
                                )}
                                {exercise.preparation.video_id && prepVideo && (
                                    <Video controls controlsList="nodownload">
                                        <source src={prepVideo} type="video/mp4; base64" />
                                    </Video>
                                )}
                                {/* {exercise.preparation.text && <BlockContent blocks={exercise.preparation.text} />} */}
                                {/*{exercise.preparation.text.split('\n').map(str => <p>{str}</p>)}*/}
                                {exercise.preparation.text && <Description><div dangerouslySetInnerHTML={{__html: exercise.preparation.text }} /></Description>}
                                <NextButton onClick={nextStep}>
                                    <NextIcon />
                                    {t("stappenplan.nextStep")}
                                </NextButton>
                                {exercise.goal && exercise.goal.text && (
                                    <BackText onClick={prevStep}>
                                        <BackIcon />
                                        {t("stappenplan.back")}
                                    </BackText>
                                )}
                            </STEMCard>
                        )}
                        {currentStep === 3 && (
                            <STEMCard>
                                <Title>{t("stappenplan.perf")}</Title>
                                {exercise.media_complete_id && (
                                    <Img src={medIMG}></Img>
                                )}
                                {exercise.audio_complete_id && (
                                    <Audio controls>
                                        <source src={compAudio} type="audio/wav; base64"></source>
                                    </Audio>
                                )}
                                {exercise.video_complete_id && compVideo && (
                                    <Video controls controlsList="nodownload">
                                        <source src={compVideo} type="video/mp4; base64" />
                                    </Video>
                                )}
                                {exercise.steps.map((step, index) => {
                                    if (miniStep === index + 1)
                                        return (
                                            <div>
                                                <Bold>
                                                    {t("stappenplan.step")} {index + 1}
                                                </Bold>
                                                {/* <BlockContent blocks={step.text} /> */}
                                                {/*{step.text.split('\n').map(str => <p>{str}</p>)}*/}
                                                <Description><div dangerouslySetInnerHTML={{__html: step.text }} /></Description>
                                                {/* <Description>{step.text.replace('\n', '<br />')}</Description> */}
                                                {step.media_id && (
                                                    <CenterFlex>
                                                        <Img src={getUrl("questionImage", step.media_id)}></Img>
                                                    </CenterFlex>
                                                )}
                                                {step.audio_id && (
                                                    <CenterFlex>
                                                        <Audio controls>
                                                            <source
                                                                src={getUrl("question", step.audio_id)}
                                                                type="audio/wav; base64"
                                                            />
                                                        </Audio>
                                                    </CenterFlex>
                                                )}
                                                {step.video_id && getUrl("questionVideo", step.video_id) && (
                                                    <CenterFlex>
                                                        <Video controls controlsList="nodownload">
                                                            <source
                                                                src={getUrl("questionVideo", step.video_id)}
                                                                type="video/mp4; base64"
                                                            />
                                                        </Video>
                                                    </CenterFlex>
                                                )}
                                                {miniStep < exercise.steps.length && (
                                                    <NextIconPrimary data-testid="nextIcon" onClick={nextMiniStep} />
                                                )}
                                                {miniStep > 1 && <BackIconPrimary data-testid="prevIcon" onClick={prevMiniStep} />}
                                            </div>
                                        );
                                    }
                                )}
                                {miniStep === exercise.steps.length && (
                                    <>
                                        {showEndRecorder && <Description>{t("exercise.endCamera")}</Description>}
                                        <Button onClick={toResult}>
                                            <NextIcon />
                                            {t("stappenplan.end")}
                                        </Button>
                                    </>
                                )}
                                {redirect && (
                                    <Redirect
                                        to={{
                                            pathname: "/p/Assignments/results",
                                            state: {
                                                id: props.id,
                                                startTime: props.startTime,
                                                title: exercise.title,
                                                exerciseType: "Stappenplan",
                                                type: "resultform",
                                                results: [],
                                            },
                                        }}
                                    />
                                )}
                                {isExample && (
                                    <Redirect to={{ pathname: "/t/CreateMaterial/requestExercise"}} />
                                )}
                                {isReview && (
                                    <Redirect to={{pathname: "/admin"}} />
                                )}
                                {exercise.goal &&
                                    exercise.goal.text &&
                                    exercise.preparation &&
                                    exercise.preparation.text && (
                                        <BackText onClick={prevStep}>
                                            <BackIcon />
                                            {t("checkoutStep.back")}
                                        </BackText>
                                    )
                                }
                            </STEMCard>
                        )}
                    </TaskCard>

                    {(exercise.should_be_chrono ||
                        exercise.should_be_metronome ||
                        exercise.should_be_recorded) && (
                        <OptionsCard id="options-card">
                            <Title black>{t("stappenplan.options")}</Title>
                            {props.showRecorder &&
                                exercise.should_be_recorded && (<>
                                    <Recorder onRecordingComplete={ReceiveRecording} />
                                    {showSucces && <Flash>{t("stappenplan.video")}.</Flash>}
                                </>)}
                            {exercise.should_be_chrono && <Chrono />}
                            {exercise.should_be_metronome && <Metronome />}
                        </OptionsCard>
                    )}
                </FlexCards>
            </>
        );
    }
});

export default Stappenplan;
