import React, { useState } from 'react';
import styled from "styled-components";
import Button from "../../styled/button"
import { FaPlay, FaStop, FaUndoAlt } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const Thing = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
`

const Text = styled.p`
    font-size: 20px;
    font-weight: bold;
    color: #545454;
    display: block;
    margin-bottom: 0px;
`

const PlayIcon = styled(FaPlay)`
    font-size: 14px;
    color: white;
    margin-right: 15px;
`

const StopIcon = styled(FaStop)`
    font-size: 14px;
    color: white;
    margin-right: 15px;
`

const ResetIcon = styled(FaUndoAlt)`
    font-size: 14px;
    color: white;
    margin-right: 15px;
`

const Time = styled.p`
    font-size: 30px;
    color: #545454;
    margin-top: 5px;
    margin-bottom: 10px;
`

const RoundList = styled.p`
    margin: 5px 0px  0px 0px;
    font-family: Raleway;
    font-size: 20px;
`

const ButtonStart = styled(Button)`
    background: #45AE7C;
    border-color: #45AE7C;
    width: 48%;

    &:hover{
        background: #45AE7C;
        border-color: #45AE7C;
    }
`
const ButtonStop = styled(Button)`
    background: #DC393D;
    border-color: #DC393D;
    width: 48%;

    &:hover{
        background: #DC393D;
        border-color: #DC393D;
    }
`
const ButtonReset = styled(Button)`
    background: #DC393D;
    border-color: #DC393D;
    width: 48%;

    &:hover{
        background: #DC393D;
        border-color: #DC393D;
    }

`
const ButtonRound = styled(Button)`
    background: #45AE7C;
    border-color: #45AE7C;
    width: 100%;

    &:hover{
        background: #45AE7C;
        border-color: #45AE7C;
    }
`

const Sec = styled.span`
    color: #51C0CF;
    font-size: 16px;
`

const Buttons = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 15px;
`

const FullWidth = styled.div`
    width: 100%;
`

const Rounds = styled.div`
    margin-bottom: 7px;
`

const CreateTimer = (props) => {
    const { t, i18n } = useTranslation()
    const MAXMIN = props.MAXMIN;
    const MAXSEC = props.MAXSEC;
    const MAXMILI = props.MAXMILI;
    const [showTimeUp, setTimeUp] = useState(false);
    const [rounds, setRounds] = useState([""]);
    const [timer, setTimer] = useState()
    const [showStart, setShowStart] = useState(true)
    const [showStop, setShowStop] = useState(false)
    let minutes = 0
    let seconds = 0
    let mili = 0
    let running = false
    const [state, setState] = useState("00:00:00")

    const updateState = () => {
        setState(("0" + minutes).slice(-2) + ":" + ("0" + seconds).slice(-2) + ":" + ("0" + mili).slice(-2))

        if (MAXMIN === minutes && MAXSEC === seconds && MAXMILI === mili) {
            setTimeUp(true)
        }
    }

    const addMili = () => {
        mili = mili + 1
        if (mili === 100) {
            seconds = seconds + 1
            mili = mili - 100
            if (seconds === 60) {
                if (minutes === 99) {

                } else {
                    minutes = minutes + 1
                }
            }
        }
        updateState()
    }

    const handleStart = () => {
        if (!running) {
            setRounds([""])
            running = true
            var timerID
            timerID = setInterval(() => {
                addMili()
            }, 10)
            setTimer(timerID)
        }
        setShowStop(true)
        setShowStart(false)
        setTimeUp(false)
    }
    const handleStop = () => {
        running = false
        clearInterval(timer)
        setShowStop(false)
        setShowStart(true)
    }
    const handleReset = () => {
        minutes = 0
        seconds = 0
        mili = 0
        setRounds([""])
        setState("00:00:00")
        updateState()
    }
    const handleRounds = (round) => {
        setRounds(rounds => [...rounds, state]);
    }

    return (
        <Thing>
            <Text>Chronometer</Text>
            {showTimeUp && <Text>{t('chronometer.timeUp')}</Text>}
            <Time>
                {state}<Sec>s</Sec>
            </Time>
            <Rounds>
            {
                rounds.map((item, index) => {
                    return (<RoundList key={index}>{item}</RoundList>)
                })
            }
            </Rounds>
            <Buttons>
                {showStart && <ButtonStart onClick={() => handleStart()}><PlayIcon />{t('chronometer.start')}</ButtonStart>}
                {showStop && <ButtonStop onClick={() => handleStop()}><StopIcon />{t('chronometer.stop')}</ButtonStop>}
                <ButtonReset onClick={() => handleReset()}><ResetIcon />{t('chronometer.reset')}</ButtonReset>
            </Buttons>
            <FullWidth>
            {showStop && <ButtonRound onClick={() => handleRounds()}>{t('chronometer.round')}</ButtonRound>}
            </FullWidth>
            
        </Thing>
    )
}

export default CreateTimer;