import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import registrationStore from "../../components/registrationStore";
import notificationStore from "../../components/notificationStore";
import InboxIcon from "../../assets/images/inbox-icon.png";
import HelpdeskIcon from "../../assets/images/helpdesk-icon.png";
import LogoutIcon from "../../assets/images/logout-icon.png";
import Therapist from "../../assets/images/therapist-icon-man.png";
import AdminIcon from "../../assets/images/admin.png";
import Book from "../../assets/images/book.png";
import { api } from "../../api";
import { useTranslation } from "react-i18next";
import { FaUserPlus } from "react-icons/fa";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { eventConstants, getInsightUserType } from "../../insights-constants";
import { isAppInsigthsEnabled } from "../../insights";
import { trackEvent } from './../../insights-track';

const Wrapper = styled.div`
    position: absolute;
    top: 70px;
    right: 10px;
    border: 0.5px solid #a5a5af;
    background-color: white;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding-top: 20px;
    padding-bottom: 20px;
    z-index: 99999;
`;

const Notification = styled.div`
    display: inline-block;
    height: 15px;
    width: 15px;
    line-height: 1;
    background-color: #f23539;
    border: 1px solid #f23539;
    border-radius: 100%;
    font-size: 13px;
    text-align: center;
    vertical-align: middle;
    color: #ffffff;
    margin-left: 20px;
`;
const MenuLink = styled.div`
    display: flex;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 30px;
    padding-right: 60px;
`;

const Icon = styled.img`
    height: 22px;
    width: 22px;
`;

const RouteName = styled.div`
    color: #929292;
    text-decoration: none;
    padding-left: 10px;
`;

const AccountLink = styled(Link)`
    text-decoration: none;
`;

const LinkManual = styled.a`
    text-decoration: none;
`;

const SeperatorLine = styled.hr`
    color: #929292;
    width: 100%;
    border: 0.25px solid #929292;
    height: 1px;
`;

function AccountMenuDropdown(props) {
    const { t, i18n } = useTranslation();
    const store = useContext(registrationStore);
    const user = JSON.parse(store.user);
    const notifications = useContext(notificationStore);

    const isAiEnabled = isAppInsigthsEnabled();
    const appInsights = useAppInsightsContext();
    const trackProps = {
        user: {
            type: getInsightUserType(user.therapist),
            public_id: user.public_id 
        } 
    }

    function handleClick() {
        trackEvent(isAiEnabled, appInsights, eventConstants.USER.LOGOUT, trackProps);
        store.logout();
    }

    return (
        <Wrapper>
            {user.admin && (
                <AccountLink to="/admin">
                    <MenuLink>
                        <Icon src={AdminIcon}></Icon>
                        <RouteName>Admin</RouteName>
                    </MenuLink>
                </AccountLink>
            )}
            <AccountLink to={user.therapist ? "/t/inbox" : "/p/inbox"}>
                <MenuLink>
                    <Icon src={InboxIcon}></Icon>
                    <RouteName>
                        {t("accountMenuDropdown.inbox")}{" "}
                        {notifications.notifications.length > 0 && (
                            <Notification>{notifications.notifications.length}</Notification>
                        )}{" "}
                    </RouteName>
                </MenuLink>
            </AccountLink>
            <AccountLink to={user.therapist ? "/t/helpdesk" : "/p/helpdesk"}>
                <MenuLink>
                    <Icon src={HelpdeskIcon}></Icon>
                    <RouteName>{t("accountMenuDropdown.helpdesk")}</RouteName>
                </MenuLink>
            </AccountLink>
            {user.therapist ? (
                ""
            ) : (
                <AccountLink to="/p/MyAccount/MyTherapists">
                    <MenuLink>
                        <Icon src={Therapist}></Icon>
                        <RouteName>{t("accountMenuDropdown.mytherapists")}</RouteName>
                    </MenuLink>
                </AccountLink>
            )}
            <LinkManual
                href={
                    i18n.language == "nl"
                        ? "https://uploads-ssl.webflow.com/5dc9be9d66fd1b176b91bed1/5e8309d66ceb87181e1f7d3a_gebruiksaanwijzing%20TelePHON%20(2).pdf"
                        : "https://uploads-ssl.webflow.com/5dc9be9d66fd1b176b91bed1/5e8309d56ceb873b6b1f7d38_User%20information%20TelePHON%20(2).pdf"
                }
                target="_blank"
            >
                <MenuLink>
                    <Icon src={Book}></Icon>
                    <RouteName>{t("accountMenuDropdown.userManual")}</RouteName>
                </MenuLink>
            </LinkManual>
            <SeperatorLine />
            <AccountLink to="/login" onClick={handleClick}>
                <MenuLink>
                    <Icon src={LogoutIcon}></Icon>
                    <RouteName>{t("accountMenuDropdown.logout")}</RouteName>
                </MenuLink>
            </AccountLink>
        </Wrapper>
    );
}

export default AccountMenuDropdown;
