import React from "react"
import styled from "styled-components"

import Card from '../components/styled/card'
import { H2, P } from '../components/styled/typography'

const Wrapper = styled(Card)`
    padding: 30px;
    width: 100%;
    margin-bottom: 10px;
    overflow: hidden;

    img {
        max-width: 100%;
    }

    white-space: normal;
    word-wrap: break-word;

    @media (min-width: 980px){
        width: 98%;
        overflow: initial;
    }
`

const Document = styled.div`
    image{
        width: 75%;
    }
`

function Article(props){

    return(
        <Wrapper>
            <H2>{props.title}</H2>
            <Document dangerouslySetInnerHTML={{__html: props.content}} />
        </Wrapper>
    )
}

export default Article