import {createContext} from 'react';
import {action, observable} from 'mobx';

class AuthenticationStore {
    @observable redirectToLogin = false;

    @action setRedirectToLogin(bool) {
        localStorage.removeItem('user');
        this.redirectToLogin = bool;
    }
}

export const AuthenticationStoreInstance = new AuthenticationStore();

export default createContext(AuthenticationStoreInstance);
