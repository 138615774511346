import api from '../api';

const createCall = (body) => api.getCommunicationApi().post(`/calls`, body);
const getActiveCalls = () => api.getCommunicationApi()?.get(`/calls?active=true`);
const finishCall = (id) => api.getCommunicationApi().put(`/calls/${id}`, {id, finished: true});

export default {
    createCall,
    getActiveCalls,
    finishCall
}
