import styled from 'styled-components'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { NavLink } from 'react-router-dom'

export const TelLink = styled(NavLink)`
    text-decoration: none; 
    color: #51C0CF; 
    padding: 20px;
    font-size: 18px;

    @media (min-width: 980px){
        color: #FFFFFF;
        font-size: 16px;
        font-weight: normal;
        text-transform: capitalize;
        padding: 0px 10px;
    }
`

export const MenuLink = styled.a`
    text-decoration: none; 
    color: #51C0CF; 
    padding: 20px;
    font-size: 18px;

    @media (min-width: 980px){
        color: #FFFFFF;
        font-size: 16px;
        font-weight: normal;
        text-transform: capitalize;
        padding: 0px 10px;
    }
`

export const StyledLinkAnchor = styled(AnchorLink)`
    color: ${props => props.white ? "#FFFFFF" : "#51C0CF"};
    text-decoration: ${props => props.noline ? "none" : "underline"};
`

export const StyledLink = styled.a`
    color: ${props => props.white ? "#FFFFFF" : "#51C0CF"};
    text-decoration: ${props => props.noline ? "none" : "underline"};
`