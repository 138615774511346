import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import Filter from "../filterComponents/filter";
import LibraryCard from "./libraryCard";
import { useTranslation } from "react-i18next";
import { api } from "../../api";
import LoadSpinner from "../loadspinner";
import Pagination from "./pagination";
import LanguageStore from "../stores/languageStore";
import MediaCard from "./mediaCard";
import useLocalStorage from "../hooks/localStorage";


const Flex = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: stretch;
`;

const Exercises = styled.div`
    width: 100%;
    margin-top: 10px;
`;

const ExerciseWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

function AdminList(props) {
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [exercisesToDisplay, setExercisesToDisplay] = useState([]);
    const [documentsToDisplay, setDocumentsToDisplay] = useState([]);
    const [mediaToDisplay, setMediaToDisplay] = useState([]);
    const [pagination, setPagination] = useState({
        start: 1,
        end: 12,
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const langStore = useContext(LanguageStore);
    const [filters, setFilters] = useLocalStorage(props.tag + 'FilterData', {
        title: "",
        language: langStore.getLanguage(i18n.language),
        tags: [],
        discipline: "",
        subdiscipline: ""
    });

    function getMedia() {
        const params = {
            name: filters.title,
            language: filters.language,
            tags: filters.tags,
            start: pagination.start,
            end: pagination.end,
            public: true
        }
        return api.get("/admin/files", {params: params});
    }

    function getMaterial() {
        const params = {
            title: filters.title,
            language: filters.language,
            discipline: filters.discipline,
            subdiscipline: filters.subdiscipline,
            start: pagination.start,
            end: pagination.end,
            onlyDocs: false
        };
        return props.public ? api.get("/material", {params: params}) : api.get("/admin/material", {params: params})
    }

    function getMediaAndMaterial() {
        setLoading(true);
        Promise.all([
            getMedia(),
            getMaterial()
        ]).then(([media, material]) => {
            setMediaToDisplay(media.data.Files);
            setExercisesToDisplay(Array.from(material.data.output[0]));
            setDocumentsToDisplay(Array.from(material.data.output[1]));
            let total = Math.ceil(media.data.total / 12);
            total += Math.ceil(material.data.total / 12);
            setTotalPages(total);
            setLoading(false);
        });
    }

    useEffect(() => {
        getMediaAndMaterial();
    }, [filters, pagination, props.refresh])

    useEffect(() => {
        if (props.filterData !== undefined) {
            getFilterResults(props.filterData);
        }
    }, [props.filterData]);

    function getFilterResults(data) {
        setFilters(data);
        resetPagination();
    }

    function resetPagination() {
        setPagination({
            start: 1,
            end: 12,
        });
        setCurrentPage(1);
    }

    function handlePageChange(dir) {
        setPagination({
            start: pagination.start + 12 * dir,
            end: pagination.end + 12 * dir,
        });
        setCurrentPage(currentPage + dir);
    }

    function handlePageForce(page) {
        setPagination({
            start: 12 * (page - 1) + 1,
            end: page * 12,
        });
        setCurrentPage(page);
    }

    return (
        <>
            <div>
                <Filter tag={props.tag} filters={filters} useTags={true} filterCallback={(data) => getFilterResults(data)} small={props.small} />
                {loading ? (
                    <LoadSpinner />
                ) : (
                    <ExerciseWrapper>
                        <Exercises>
                            <Flex>
                                {exercisesToDisplay &&
                                    exercisesToDisplay.map((material) => {
                                        return (<LibraryCard
                                                info={material}
                                                public={props.public}
                                                callBack={
                                                    props.selectCallback &&
                                                    ((id, title, type) => props.selectCallback(id, title, type))
                                                }
                                            />
                                        );
                                    })}
                                {documentsToDisplay &&
                                    documentsToDisplay.map((material) => {
                                        return (<LibraryCard
                                                info={material}
                                                public={props.public}
                                                callBack={
                                                    props.selectCallback &&
                                                    ((id, title, type) => props.selectCallback(id, title, type))
                                                }
                                            />
                                        );
                                    })}
                                {mediaToDisplay &&
                                    mediaToDisplay.map((media, index) => {
                                        return (
                                            <MediaCard
                                                label={"mediaCard" + index}
                                                media={media}
                                                callBack={props.selectCallback && ((id, title, type) => props.selectCallback(id, title, type))}
                                                mediaSelect={props.mediaSelect}
                                                showDescription={props.showDescription}
                                            />
                                        );
                                    })}
                            </Flex>
                        </Exercises>
                        {totalPages ? (
                            <Pagination
                                nextFunction={() => handlePageChange(1)}
                                prevFunction={() => handlePageChange(-1)}
                                totalPages={totalPages}
                                currentPage={currentPage}
                                pageForce={handlePageForce}
                            />
                        ) : (
                            <div>{t("materiaalRaadplegen.noMaterial")}</div>
                        )}
                    </ExerciseWrapper>
                )}
            </div>
        </>
    );
}

export default AdminList;