import React, { useContext, useState, useEffect } from "react";
import Card from "./styled/card";
import styled from "styled-components";
import { P } from "./styled/typography";
import ProgressBar from "./progressBar";
import { observer } from "mobx-react";
import fileUploadStore from "./fileUploadStore";
import Check from "../assets/images/check.png";
import { FaCloudUploadAlt } from "react-icons/fa";
import { uploadKeyframes } from "./styled/animation";
import { uploadOneFile } from "../azure";

const Modal = styled(Card)`
    position: fixed;
    bottom: 10px;
    right: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 40px;
    min-width: 300px;
    cursor: pointer;
    max-height: 200px;
    overflow: scroll;

    &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
        margin-right: 3px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background-color: rgba(0, 0, 0, 0.5);
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }
`;

const MinimizedModal = styled(Card)`
    position: fixed;
    bottom: 10px;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    background: linear-gradient(to top, #51c0cf 50%, white 50%);
    background-size: 100% 200%;
    background-position: right top;
    margin-left: 10px;
    transition: all 2s ease;

    animation: ${uploadKeyframes} 1.5s linear;
    animation-iteration-count: infinite;
`;

const FileName = styled(P)`
    color: #545454;
    font-weight: 600;
    font-size: 18px;
    margin: 0px;
    padding-top: 10px;
`;

const Flex = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const CheckIcon = styled.img`
    width: 25px;
    height: 25px;
`;

const UploadingIcon = styled(FaCloudUploadAlt)`
    width: 25px;
    height: 25px;
`;

const BackgroundUpload = observer((props) => {
    const fileStore = useContext(fileUploadStore);
    const files = fileStore.files;
    const [fileProgress, setFileProgress] = useState({});
    const [modalIsVisible, setModalIsVisible] = useState(false);
    const [modalIsMinimized, setModalIsMinimized] = useState(false);

    // Alert when user tries to leave before upload is done.
    window.onbeforeunload = () => {
        if (modalIsVisible) {
            return "Your files are not completely uploaded...";
        }
    };

    // Loading effect on minimized modal
    useEffect(() => {
        if (document.getElementById("upload-modal")) {
            const uploadModal = document.getElementById("upload-modal");
            uploadModal.style.backgroundPosition = "right bottom";
        }
    });

    // Make sure that files are uploaded only once (prevents infinite loop)
    useEffect(() => {
        uploadFiles();
    }, [files]);

    // Open or close modal
    const handleOpenModal = () => {
        setModalIsVisible(!modalIsVisible);
        setModalIsMinimized(!modalIsMinimized);
    };

    const uploadFiles = async () => {
        if (files && !fileStore.isUploading) {
            setModalIsVisible(true);

            // Prevents file upload to be overwritten by other request
            fileStore.setIsUploading(true);

            // Upload all files
            // TODO: Make uploads concurrent
            let fileList = [];
            for (var x = 0; x < files.length; x++) {
                await uploadOneFile(files[x]).then((filename) => fileList.push(filename));
            }

            setModalIsVisible(false);
            setModalIsMinimized(false);
            fileStore.setIsUploading(false);
        }
    };

    return (
        <div>
            {modalIsVisible && (
                <Modal onClick={handleOpenModal}>
                    {Object.keys(fileProgress).map((key) => {
                        return (
                            <Flex>
                                <FileName>{key}:</FileName>
                                {fileProgress[key] < 1 && <ProgressBar progress={fileProgress[key]} />}
                                {fileProgress[key] == 1 && <CheckIcon src={Check} />}
                            </Flex>
                        );
                    })}
                </Modal>
            )}

            {modalIsMinimized && (
                <MinimizedModal onClick={handleOpenModal} id="upload-modal">
                    <UploadingIcon />
                </MinimizedModal>
            )}
        </div>
    );
});

export default BackgroundUpload;
