import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import RegistrationStore from "../../components/registrationStore";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { H4, P } from "../../components/styled/typography";
import { FaEyeSlash, FaEye } from "react-icons/fa";

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const Question = styled(H4)`
    font-weight: 500;
    margin: 0px;
    margin-bottom: 15px;
`;

const Select = styled.select`
    background: #ffffff;
    border: 1px solid #51c0cf;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(197, 197, 197, 0.25);
    border-radius: 10px;
    padding: 10px 10px;
    margin-top: 5px;
    outline: none;
    font-size: 16px;
    margin-bottom: 15px;
    width: 100%;

    &.valid {
        border-color: #45ae7c;
    }

    &.invalid {
        border-color: #f02929;
    }
`;

const Option = styled.option``;

const InputDiv = styled.div`
    position: relative;
    width: 100%;
`;

const TextError = styled(P)`
    margin: 0px;
    margin-bottom: 15px;
    color: #f02929;
`;

const Speciality = observer(({ setRegisterStepIsValid, nextStep }) => {
    const { t, i18n } = useTranslation();
    const registrationStore = useContext(RegistrationStore);

    useEffect(() => {
        setRegisterStepIsValid();
    }, []);

    const handleSpecialityChange = (e) => {
        registrationStore.setSpeciality(e.target.value);
    };

    return (
        <Wrapper>
            <Question black>{t("specialityRegister.Question")}</Question>
            <InputDiv>
                <Select
                    id="speciality"
                    type="select"
                    value={registrationStore.specilaity}
                    onChange={(e) => handleSpecialityChange(e)}
                    onKeyPress={(e) => nextStep(e)}
                    placeholder="Your speciality"
                    autoFocus
                    required
                    data-testid="speciality"
                >
                    <option value="Logopedie" selected>
                        {t("documentEditForm.speechTherapy")}
                    </option>
                    <option value="Kinesitherapie">{t("documentEditForm.respTherapy")}</option>
                    <option value="Diëtiek">{t("documentEditForm.dietetics")}</option>
                    <option value="Ergotherapie">{t("documentEditForm.ergotherapy")}</option>
                    <option value="Huisarts">{t("documentEditForm.huisarts")}</option>
                    <option value="Verpleegkunde">{t("documentEditForm.nursing")}</option>
                    <option value="Audiologie">{t("documentEditForm.audiology")}</option>
                    <option value="Urologie">{t("documentEditForm.urology")}</option>
                    <option value="Algemeen">{t("documentEditForm.general")}</option>
                </Select>
            </InputDiv>
        </Wrapper>
    );
});

export default Speciality;
