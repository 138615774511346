import * as Sentry from '@sentry/browser';

export const isProduction = process.env.NODE_ENV === 'production';


export const captureException = (err, description) => {
    if (!isProduction) {
        console.error(err, description)
    }

    Sentry.withScope(scope => {
        scope.setExtras(description);
        Sentry.captureException(err);
    });
};
