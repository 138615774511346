import {Component} from 'react';
import {captureException} from '../helpers/errorHelper';

class ErrorBoundary extends Component {
    componentDidCatch(error, errorInfo) {
        captureException(error);
    }

    render() {
        return this.props.children;
    }
}

export default ErrorBoundary;
