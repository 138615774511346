import React from 'react';
import Icon from './icon';
import Text from './text';
import styled from 'styled-components';
import {faArrowLeft} from '@fortawesome/free-solid-svg-icons';

const Container = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
        opacity: 0.8;
    }
`;
const BackButton = ({children}) => {
    return (
        <Container>
            <Icon mr={2} color="primary" fontSize={3} icon={faArrowLeft} />
            <Text fontSize={3} color="primary">
                {children}
            </Text>
        </Container>
    );
};

export default BackButton;
