import {createContext} from 'react';
import {observable, action} from 'mobx';

class LibraryStore {
    @observable activeMediaId = null;
    @observable activeDocumentId = null;
    @observable activeExerciseId = null;
    @observable activeExerciseResult = null;
    @observable activeStep = 1;
    @observable activeMiniStep = 1;

    @action setActiveMediaId(id) {
        this.activeMediaId = id;
        this.activeDocumentId = null;
        this.activeExerciseId = null;
    }

    @action setActiveDocumentId(id) {
        this.activeDocumentId = id;
        this.activeMediaId = null;
        this.activeExerciseId = null;
    }

    @action setActiveExerciseId(id) {
        this.activeExerciseId = id;
        this.activeMediaId = null;
        this.activeDocumentId = null;
    }

    @action setActiveExerciseResult(result) {
        this.activeExerciseResult = result;
    }

    @action setActiveStep(stepNumber) {
        this.activeStep = stepNumber;
    }

    @action setActiveMiniStep(stepNumber) {
        this.activeMiniStep = stepNumber;
    }
}

export const LibraryStoreInstance = new LibraryStore();

export default createContext(LibraryStoreInstance);
