import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Line } from "react-chartjs-2";
import Card from "../../components/styled/card";
import { useTranslation } from "react-i18next";

const GraphCard = styled(Card)`
    width: 70%;
    box-sizing: border-box;
    padding-right: 40px;
`;

const Select = styled.select`
    width: 20%;
    background: #FFFFFF;
    border: 1px solid #51C0CF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(197, 197, 197, 0.25);
    border-radius: 10px;
    padding: 10px 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    outline: none;
    font-size: 16px;
`

const RightSelect = styled(Select)`
    margin-left: auto;
`

const Flex = styled.div`
    display: flex;
    flex-direction: column;
`

const LabelColors = [
    "#F23539",
    "#F0C239",
    "#24AE5F"
]
const BackgroundColor = "#51C0CF"
const AxisColor = "#545454"
const Labels = {
    date: "",
    duration: "ms",
    recording: "",
    result: "",
}

const LogboekGraph = (props) => {
    const { t, i18n } = useTranslation();
    const [results, setResults] = useState(props.myresults);
    const [xLabel, setXLabel] = useState("date");
    const [yLabel, setYLabel] = useState("result");
    const [dataSet, setDataSet] = useState([]);
    const [labelsArray, setLabelsArray] = useState([]);

    const resultProps = [
        <option value="date">{"Date"}</option>,
        <option value="duration">{"Duration"}</option>,
        <option value="result">{"Result"}</option>,
    ];

    const graphOptions = {
        title: {
            display: false
        },
        legend: {
            display: false
        },
        hover: {
            mode: "index",
            intersect: false
        },
        tooltips: {
          enabled: true,
          mode: "index",
          intersect: false,
        },
        defaultFontFamily: "Raleway, sans-serif",
        scales: {
            yAxes: [
                {
                    ticks: {
                      beginAtZero: true,
                      callback: function(value, index, values) {
                        return value + Labels[yLabel];
                      }
                    },
                    gridLines: {
                        drawOnChartArea: false
                    },
                }
            ],
            xAxes: [
                {
                    type: 'time',
                    distribution: 'series',
                    time: {
                        unit: 'day',
                        tooltipFormat: 'll'
                    },
                    gridLines: {
                        drawOnChartArea: false
                    },
                    ticks: {
                        min: labelsArray[0],
                        max: labelsArray[labelsArray.length - 1]
                    }
                }
            ],
        },
        plugins: {
            zoom: {
                zoom: {
                    enabled: true,
                    mode: 'x',
                    speed: 0.05
                },
                pan: {
                    enabled: true,
                    mode: 'x',
                }
            }
        }
    };

    const [options, setOptions] = useState(graphOptions);

    useEffect(() => {
        setResults(props.myresults);
    }, [props.myresults]);

    useEffect(() => {
        updateChart();
    }, [results, xLabel, yLabel]);

    const updateChart = () => {
        let labels= [];
        let data= [];
        let pointBackgroundColors = [];

        results.forEach((taak) => {
            data.push(taak[yLabel]);
            
            let colorIndex = (taak[yLabel] - 1 < LabelColors.length) ? taak[yLabel] - 1 : 0;
            pointBackgroundColors.push(LabelColors[colorIndex]);

            var label = taak[xLabel] + Labels[xLabel];
            if (xLabel === "date"){
                const elementDate = new Date(taak[xLabel]);
                label = elementDate.toLocaleString('en');
            }
            labels.push(label);
        });

        const dataObject = {
            label: props.title,
            data: data,
            showLine: true,
            pointRadius: 5,
            pointHoverRadius: 10,
            pointBackgroundColor: pointBackgroundColors,
            backgroundColor: AxisColor,
            borderColor: BackgroundColor,
            hidden: false,
            fill: false
        };

        setDataSet([dataObject]);
        setLabelsArray(labels);

        var tempOptions = graphOptions;
        tempOptions.scales.xAxes[0].type = xLabel === "date" ? 'time' : 'category';
        tempOptions.scales.xAxes[0].ticks.min = Math.min(...labels);
        tempOptions.scales.xAxes[0].ticks.max = Math.max(...labels);
        setOptions(tempOptions);
    }

    const data = {
        labels: labelsArray,
        datasets: dataSet,
    };

    return (
        <GraphCard>
            <Flex>
                <Select value={yLabel} name="yAxis" onChange={(e) => setYLabel(e.target.value)}>
                    {resultProps.map((x, index) => {return index !== 0 ? x: null;})}
                </Select>
                <Line data={data} options={options} />
                <RightSelect value={xLabel} name="xAxis" onChange={(e) => setXLabel(e.target.value)}>
                    {resultProps}
                </RightSelect>
            </Flex>
        </GraphCard>
    );
};

export default LogboekGraph;
