import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import LanguageStore from "../stores/languageStore";

export function Languages(props) {
    const { t, i18n } = useTranslation();
    const store = useContext(LanguageStore);
    const languages = store.languages;

    return languages.map((language) => (
        <option key={language.name} value={language.name} defaultValue={props.default === language.value}>
            {t(`languages.${language.name}`)}
        </option>
    ));
}
