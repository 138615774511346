// Example flow: https://getuserflow.com/demo
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Card from "../styled/card";
import { H2, P } from "../styled/typography";
import Button from "../styled/button";
import { Redirect } from "react-router-dom";

const OnboardingCard = styled(Card)`
    z-index: 99999;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    height: ${(props) => {
        if (props.styling.height) return `${props.styling.height}px`;
        return "auto";
    }};
    width: ${(props) => {
        if (props.styling.width) return `${props.styling.width}px`;
        return "auto";
    }};
    max-width: 40%;
    bottom: 45px;
    right: 125px;
    padding: 25px 45px;

    &:before {
        content: "";
        width: 0px;
        height: 0px;
        position: absolute;
        border-left: 10px solid #fff;
        border-right: 10px solid transparent;
        border-top: 10px solid #fff;
        border-bottom: 10px solid transparent;
        right: -20px;
        bottom: 6px;
    }
`;

const Buttons = styled.div`
    display: flex;
`;

const ButtonBordered = styled(Button)`
    background-color: transparent;
    color: #51c0cf;
    margin-right: 15px;
`;

const Title = styled(H2)`
    margin: 0px;
    text-align: center;
`;

const Text = styled(P)`
    margin: 0px;
    margin-top: 15px;
    margin-bottom: 20px;
    text-align: center;
`;

const OnboardingStep = (props) => {
    const [redirect, setRedirect] = useState(false);

    return (
        <OnboardingCard id="wrapper" styling={props}>
            <Title>{props.title}</Title>
            <Text>{props.description}</Text>
            <Buttons>
                <ButtonBordered onClick={props.backButtonDestination()}>{props.backButton}</ButtonBordered>
                <Button onClick={props.nextButtonDestination()}>{props.nextButton}</Button>
            </Buttons>
            {redirect && <Redirect to={props.redirect} />}
        </OnboardingCard>
    );
};

export default OnboardingStep;
