import React, {useContext} from "react"
import axios from "axios"
import { observer } from "mobx-react"
import registrationStore from "./components/registrationStore"

export const source = axios.CancelToken.source();

export const api = axios.create({
  baseURL: process.env.REACT_APP_COBRAND_API || "https://localhost:5001/v1",
  headers: {
    "x-access-token": window.localStorage.getItem("token") || "",
    // Here we take the session id from the application insights and send it with every request in the header (x-ai-session-id)
    // When there is no ai_session id, it will send an empty header
    "x-ai-session-id": document.cookie
      ? document.cookie.split("; ").find((row) => row.startsWith("ai_session="))
        ? document.cookie
            .split("; ")
            .find((row) => row.startsWith("ai_session="))
            .split("=")[1]
            .split("|")[0]
        : "" || ""
      : "",
  },
});

export const recordingApi = axios.create({
  baseURL: process.env.REACT_APP_SOCKET_URL || "https://localhost:5002",
  headers: {
    "x-access-token": window.localStorage.getItem("token") || "",
  }
});
