
export const INCOMING_CALL_EVENT = 'incoming-call';
export const UNAUTHORIZED_EVENT = 'unauthorized';
export const AUTHENTICATE_EVENT = 'authenticate';
export const CALL_FINISHED_EVENT = 'call-finished';
export const MEDIA_SELECTED_BY_THERAPIST = 'media-selected-by-therapist';
export const DOCUMENT_SELECTED_BY_THERAPIST = 'document-selected-by-therapist';
export const EXERCISE_SELECTED_BY_THERAPIST = 'exercise-selected-by-therapist';
export const EXERCISE_RESULT = 'exercise-result';
export const EXERCISE_CHANGE_STEP = 'exercise-change-step';
export const EXERCISE_CHANGE_MINI_STEP = 'exercise-change-mini-step';
export const INCOMING_CONVERSATION_EVENT = 'incoming-chat';
export const CONVERSATION_FINISHED_EVENT = 'chat-finished';
export const INCOMMING_MESSAGE_EVENT = 'chat-message-incomming';
export const UPDATE_SETTING_EVENT = 'update-partner-setting';
export const UPDATE_MOUSE_EVENT = 'update-mouse-event';
