import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import qs from "qs";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { api } from "../../api";
import { Redirect } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import Header from "../../components/header";
import Card from "../../components/styled/card";
import Button from "../../components/styled/button";
import Flash from "../../components/styled/flashMessage";
import { H2, P } from "../../components/styled/typography";
import RegistrationStore from "../../components/registrationStore";
import TypeOfUser from "../../components/registrationComponents/typeOfUser";
import UserName from "../../components/registrationComponents/userName";
import Email from "../../components/registrationComponents/email";
import PhoneNumber from "../../components/registrationComponents/phoneNumber";
import Password from "../../components/registrationComponents/password";
import RepeatPassword from "../../components/registrationComponents/repeatPassword";
import Speciality from "../../components/registrationComponents/speciality";
import AcceptStatements from "../../components/registrationComponents/acceptStatements";
import TwoFA from "../../components/2FAEmail";
import TwoFAPhone from "../../components/2FAPhone";

const RegisterCard = styled(Card)`
    width: 90%;
    margin: 5vh auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 50px;
    position: relative;

    @media (min-width: 768px) {
        width: 90%;
        margin: 10vh auto;
        max-width: 650px;
    }

    @media (min-width: 980px) {
        width: 70%;
        margin: 10vh auto;
    }
`;

const Title = styled(H2)`
    margin: 10px auto 30px auto;
`;

const BlueLink = styled(Link)`
    color: #51c0cf;
`;

const CenteredWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

const DefaultWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
`;

const ProgressBar = styled.div`
    width: ${(props) => {
        if (props.step == 0) return "0px";
        else if (props.step === 1) return "11.11%";
        else if (props.step === 2) return "22.22%";
        else if (props.step === 3) return "33.33%";
        else if (props.step === 4) return "44.44%";
        else if (props.step === 5) return "55.55%";
        else if (props.step === 6) return "66.66%";
        else if (props.step === 7) return "77.77%";
        else if (props.step === 8) return "88.88%";
        else if (props.step === 9) return "100%";
    }};
    border: ${(props) => (props.step == 0 ? "none" : "1px solid #51c0cf")};
    border-top-left-radius: 10px;
    border-top-right-radius: ${(props) => (props.step == 6 ? "10px" : "0px")};
    height: 15px;
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: #51c0cf;
    transition: width 1s;
`;

const BackDiv = styled.div`
    width: 100%;
`;

const Back = styled(P)`
    margin: 0px;
    text-align: left;
    padding-top: 25px;
    cursor: pointer;
    font-size: 16px;
`;

const BackArrow = styled(FaArrowLeft)`
    margin-right: 5px;
    transform: translateY(2px);
`;

function Register(props) {
    const { t, i18n } = useTranslation();
    const registrationStore = useContext(RegistrationStore);
    const language = qs.parse(props.location.search, { ignoreQueryPrefix: true }).language;
    const [registrationStep, setRegistrationStep] = useState(0);
    const [invalidError, setInvalidError] = useState(false);
    const [isValidated, setIsValidated] = useState(false);
    const [emailIsTaken, setEmailIsTaken] = useState(false);
    const [loginRedirect, setLoginRedirect] = useState(false);
    const [dashboardRedirect, setDashboardRedirect] = useState(false);
    const [submitError, setSubmitError] = useState(false);
    const [statementError, setStatemenetError] = useState(false);
    const stepIsBeforeSpeciality = registrationStep === 7

    //Check if registration is called with individual account + which type (stem/slik)
    const individualTrack = qs.parse(props.location.search, { ignoreQueryPrefix: true }).individuTrack ?? null;

    useEffect(() => {
        if (language) {
            i18n.changeLanguage(language);
        }
    }, []);

    useEffect(() => {
        if (registrationStore.newUser.verified || registrationStore.newUser.verifiedPhone) {
            setRegistrationStep(registrationStep + 1);
            setIsValidated(false);
        }
    }, [registrationStore.newUser.verified, registrationStore.newUser.verifiedPhone]);

    const triggerNextWithEnter = (e) => {
        if (e.which === 13) {
            goToNextStep();
        }
    };

    const triggerNextWithEnterEmail = (e) => {
        if (e.which === 13) {
            checkIfEmailExists();
        }
    };

    const checkIfEmailExists = () => {
        api.get("/user", { params: { email: registrationStore.email } }).then((response) => {
            if (response.data.user) {
                setEmailIsTaken(true);
            } else {
                setEmailIsTaken(false);
                goToNextStep();
            }
        });
    };

    const findCorrectNextStep = () => {
        if ((registrationStore.isTherapist === false || individualTrack !== null) && stepIsBeforeSpeciality) {
            registrationStore.setSpeciality("");
            setRegistrationStep(registrationStep + 2);
        } else if (registrationStep === 0 && individualTrack !== null) {
            setRegistrationStep(registrationStep + 2);
        } else {
            setRegistrationStep(registrationStep + 1);
        }
    };

    const goToNextStep = () => {
        if (isValidated) {
            findCorrectNextStep();
            setInvalidError(false);
        } else {
            setInvalidError(true);
        }
        setIsValidated(false);
    };

    const goToPreviousStep = () => {
        if (registrationStep > 0) {
            setRegistrationStep(registrationStep - 1);
        }
    };

    const setIsValidatedToTrue = () => {
        setIsValidated(true);
    };

    const SetIsValidatedToFalse = () => {
        setIsValidated(false);
    };

    const createAccount = () => {
        if (isValidated) {
            const newUser = {
                email: registrationStore.email,
                password: registrationStore.password,
                firstName: registrationStore.firstName,
                lastName: registrationStore.lastName,
                therapist: registrationStore.isTherapist,
                expired: false,
                plan: registrationStore.isTherapist ? "Therapeut" : "Patiënt",
                plan_period: 1,
                verified: true,
                TwoFAEnabled: false,
                wantsMarketingEmails: registrationStore.wantsMarketingEmails,
                speciality: registrationStore.speciality,
                telephone: registrationStore.phoneNumber,
                track: individualTrack
            };

            api.post("/user", newUser)
                .then(() => {
                    registrationStore.setRegistrationMessage(true);
                    if (individualTrack != null) {
                        registrationStore.setInProgress(true);
                        registrationStore.login().then(() => {
                            setDashboardRedirect(true);
                            registrationStore.setInProgress(false);
                        })
                        .catch(() => registrationStore.setInProgress(false));
                    } else {
                        setLoginRedirect(true);
                    }
                })
                .catch(() => setSubmitError(true));
        } else {
            setStatemenetError(true);
        }
    };

    return (
        <div id="register">
            <Header />
            <RegisterCard>
                <ProgressBar step={registrationStep} />

                <Title center black>
                    {t("register.reg")}
                </Title>
                {submitError && <Flash red>{t("registration.submitError")}</Flash>}
                {invalidError && <Flash red>{t("registration.invalidError")}</Flash>}
                {statementError && <Flash red>{t("registration.statementError")}</Flash>}
                {(registrationStep === 0 && individualTrack === null) && (
                    <CenteredWrapper>
                        <TypeOfUser
                            setRegisterStepIsValid={setIsValidatedToTrue}
                            setRegisterStepIsInvalid={SetIsValidatedToFalse}
                        />
                        <Button id="button" onClick={goToNextStep}>
                            {t("register.next")}
                        </Button>
                        <P>
                            {t("register.acc")} <BlueLink to="/login">{t("register.log")}</BlueLink>
                        </P>
                    </CenteredWrapper>
                )}

                {(registrationStep === 1 || (registrationStep < 2 && individualTrack !== null)) && (
                    <DefaultWrapper>
                        <UserName
                            setRegisterStepIsValid={setIsValidatedToTrue}
                            setRegisterStepIsInvalid={SetIsValidatedToFalse}
                            nextStep={(e) => triggerNextWithEnter(e)}
                        />
                        <Button id="button" onClick={goToNextStep}>
                            {t("register.next")}
                        </Button>
                    </DefaultWrapper>
                )}

                {registrationStep === 2 && (
                    <DefaultWrapper>
                        <Email
                            setRegisterStepIsValid={setIsValidatedToTrue}
                            setRegisterStepIsInvalid={SetIsValidatedToFalse}
                            nextStep={(e) => triggerNextWithEnterEmail(e)}
                            emailTaken={emailIsTaken}
                        />
                        <Button id="button" onClick={checkIfEmailExists}>
                            {t("register.next")}
                        </Button>
                    </DefaultWrapper>
                )}

                {registrationStep === 3 && (
                    <CenteredWrapper>
                        <TwoFA
                            setRegisterStepIsValid={setIsValidatedToTrue}
                            setRegisterStepIsInvalid={SetIsValidatedToFalse}
                            nextStep={(e) => triggerNextWithEnter(e)}
                            verified={goToNextStep}
                        />
                    </CenteredWrapper>
                )}

                {registrationStep === 4 && (
                    <DefaultWrapper>
                        <PhoneNumber
                            setRegisterStepIsValid={setIsValidatedToTrue}
                            setRegisterStepIsInvalid={SetIsValidatedToFalse}
                            nextStep={(e) => triggerNextWithEnter(e)}
                        />
                        <Button id="button" onClick={goToNextStep}>
                            {t("register.next")}
                        </Button>
                    </DefaultWrapper>
                )}

                {registrationStep === 5 && (
                    <CenteredWrapper>
                        <TwoFAPhone
                            setRegisterStepIsValid={setIsValidatedToTrue}
                            setRegisterStepIsInvalid={SetIsValidatedToFalse}
                            nextStep={(e) => triggerNextWithEnter(e)}
                            verified={goToNextStep}
                            isInRegistration={true}
                        />
                    </CenteredWrapper>
                )}

                {registrationStep === 6 && (
                    <DefaultWrapper>
                        <Password
                            setRegisterStepIsValid={setIsValidatedToTrue}
                            setRegisterStepIsInvalid={SetIsValidatedToFalse}
                            nextStep={(e) => triggerNextWithEnter(e)}
                        />
                        <Button id="button" onClick={goToNextStep}>
                            {t("register.next")}
                        </Button>
                    </DefaultWrapper>
                )}

                {stepIsBeforeSpeciality && (
                    <DefaultWrapper>
                        <RepeatPassword
                            setRegisterStepIsValid={setIsValidatedToTrue}
                            setRegisterStepIsInvalid={SetIsValidatedToFalse}
                            nextStep={(e) => triggerNextWithEnter(e)}
                        />
                        <Button id="button" onClick={goToNextStep}>
                            {t("register.next")}
                        </Button>
                    </DefaultWrapper>
                )}

                {registrationStep === 8 && (
                    <DefaultWrapper>
                        <Speciality
                            setRegisterStepIsValid={setIsValidatedToTrue}
                            setRegisterStepIsInvalid={SetIsValidatedToFalse}
                            nextStep={(e) => triggerNextWithEnter(e)}
                        />
                        <Button id="button" onClick={goToNextStep}>
                            {t("register.next")}
                        </Button>
                    </DefaultWrapper>
                )}

                {registrationStep === 9 && (
                    <DefaultWrapper>
                        <AcceptStatements
                            setRegisterStepIsValid={setIsValidatedToTrue}
                            setRegisterStepIsInvalid={SetIsValidatedToFalse}
                        />
                        <Button id="button" onClick={createAccount}>
                            {t("register.createAccount")}
                        </Button>
                    </DefaultWrapper>
                )}
                {registrationStep > 0 && registrationStep != 4 && (
                    <BackDiv>
                        <Back onClick={goToPreviousStep}>
                            <BackArrow /> {t("register.goBack")}
                        </Back>
                    </BackDiv>
                )}
            </RegisterCard>
            {loginRedirect && <Redirect to="/login" />}
            {dashboardRedirect && <Redirect to="/p/dashboard" />}
        </div>
    );
}

export default Register;
