import React, { useContext } from "react";
import TherapistOverlay from "../../components/overlays/therapistOverlay";
import Global from "../../components/styled/global";
import FileForm from "../../components/fileForm/fileForm";
import { observer } from "mobx-react";
import registrationStore from "../../components/registrationStore";
import { PageTitle } from "../../components/styled/typography";
import PageWrapper from "../../components/styled/pageWrapper";
import { PageAnimation } from "../../components/styled/animation";
import { useTranslation } from "react-i18next";

const FileAanmaken = observer(() => {
    const { t, i18n } = useTranslation();
    const store = useContext(registrationStore);
    const user = JSON.parse(store.user);
    return (
        <Global>
            <PageWrapper>
                <TherapistOverlay />
                <PageAnimation>
                    <PageTitle black>{t("fileForm.configure")}</PageTitle>
                    <FileForm user={user} />
                </PageAnimation>
            </PageWrapper>
        </Global>
    );
});

export default FileAanmaken;