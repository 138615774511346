import React, { useState, useEffect, useContext } from "react";
import { observer } from "mobx-react";
import Pagination from "./pagination";
import LanguageStore from "../stores/languageStore";
import registrationStore from "../registrationStore";
import { useTranslation } from "react-i18next";
import Filter from "../filterComponents/filter";
import { api } from "../../api";
import LoadSpinner from "../loadspinner";
import styled from "styled-components";
import LibraryCard from "./libraryCard";
import MyExercise from "../myExercise";

const MyExercises = styled.div`
    display: flex;
    flex-direction: column;

    @media (min-width: 980px) {
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: stretch;
    }
`;

const SelectFlex = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
`;

const OwnExercises = observer((props) => {
    const { t, i18n } = useTranslation();
    const store = useContext(registrationStore);
    const user = JSON.parse(store.user);
    const [loading, setLoading] = useState(true);
    const [exercises, setExercises] = useState([]);
    const [pagination, setPagination] = useState({
        start: 1,
        end: 12,
    });
    const [totalPages, setTotalPages] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const langStore = useContext(LanguageStore);
    const [filters, setFilters] = useState({
        title: "",
        language: langStore.getLanguage(i18n.language),
        discipline: user.speciality,
        subdiscipline: "",
    });

    function getFilterResults(data) {
        setFilters(data);
        resetPagination();
    }

    function resetPagination() {
        setPagination({
            start: 1,
            end: 12,
        });
        setCurrentPage(1);
    }

    function getExercises() {
        setLoading(true);
        const params = {
            private: false,
            title: filters.title,
            language: filters.language,
            discipline: filters.discipline,
            subdiscipline: filters.subdiscipline,
            start: pagination.start,
            end: pagination.end,
        };
        api.get("/exercises/" + props.public_id, {
            params: params,
        }).then((response) => {
            setExercises(response.data.output);
            setTotalPages(Math.ceil(response.data.total / 12));
            setLoading(false);
        });
    }


    useEffect(() => {
        getExercises();
    }, [filters, pagination]);

    useEffect(() => {
        if (props.filterData !== undefined) {
            getFilterResults(props.filterData);
        }
    }, [props.filterData]);

    function handlePageChange(dir) {
        setPagination({
            start: pagination.start + 12 * dir,
            end: pagination.end + 12 * dir,
        });
        setCurrentPage(currentPage + dir);
    }

    function handlePageForce(page) {
        setPagination({
            start: 12 * (page - 1) + 1,
            end: page * 12,
        });
        setCurrentPage(page);
    };

    return (
        <>
            <SelectFlex>
                <Filter filterCallback={(data) => getFilterResults(data)} />
            </SelectFlex>
            <MyExercises>
                {loading ? (
                    <LoadSpinner />
                ) : exercises.length > 0 ? ( exercises.map((exercise) => {
                        return (
                            <MyExercise
                                exercise={exercise}
                            />
                        );
                    })
                ) : (
                    <div style={{ "margin-top": "10px" }}>{t("materiaalAanmaken.noExercises")}</div>
                )}
                {totalPages ? (
                    <Pagination
                        nextFunction={() => handlePageChange(1)}
                        prevFunction={() => handlePageChange(-1)}
                        totalPages={totalPages}
                        currentPage={currentPage}
                        pageForce={handlePageForce}
                    />
                ) : (
                    <></>
                )}
            </MyExercises>
        </>
    )
});

export default OwnExercises;