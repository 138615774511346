import React, {useState, useRef, useEffect, useContext} from 'react';
import styled from "styled-components";
import Button from "../styled/button"
import { useTranslation } from "react-i18next"
import { observer } from "mobx-react";
import ResultStore from "../resultStore";

const Relative = styled.div`
`

const Buttons = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: stretch;
`

const StartButton = styled(Button)`
    background-color: #45AE7C;
    border-color: #45AE7C;
    margin-bottom: 10px;

    &:hover{
        background-color: #45AE7C;
        border-color: #45AE7C;
    }
`

const StopButton = styled(Button)`
    background-color: #DC393D;
    border-color: #DC393D;
    margin-bottom: 10px;

    &:hover{
        background-color: #DC393D;
        border-color: #DC393D;
    }
`

const Video = styled.video`
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
    border: 1px solid #51C0CF;
    margin-bottom: 10px;
`

const Canvas = styled.canvas`
    position: absolute;
    width: 100%   !important;
    height: auto   !important;
    object-fit: cover;
    border-radius: 5px;
    margin-bottom: 10px;
`

const VideoContainer = styled.div`
    display: inline;
    position: relative;
`

const Recorder = observer((props) => {
    const { t, i18n } = useTranslation()
    const [replayVisible, setReplayVisible] = useState(false)
    const [buttonValue, setButtonValue] = useState(t('recorder.startCamera'))
    const [gridVisible, setGridVisible] = useState(false)
    const [overlayButtonValue, setOverlayButtonValue] = useState("Show Grid")
    const canvasRef = useRef(null);
    const previewRef = useRef(null);
    const replayRef = useRef(null);

    const resultstore = useContext(ResultStore);

    const gridOptions = {
        hSections: 4,
        vSections: 3,
        color: '#51C0CF',
        width: 10
    };

    useEffect(() => {
        if (gridVisible) drawGridLines();
    }, [gridVisible]);

    useEffect(() => {
        return () => {
            stop(previewRef.current?.srcObject);
        };
    }, []);

    function log(msg) {
        console.log(msg + "\n");
    }

    function wait(delayInMS) {
        return new Promise(resolve => setTimeout(resolve, delayInMS));
    }

    function stop(stream) {
        if(stream) {
            stream.getTracks().forEach(track => track.stop());
        }
    }

    const StartRecording = (stream, lengthInMS) => {
        let recorder = new MediaRecorder(stream);
        let stopBtn = document.getElementById('stopBtn')
        let data = [];

        resultstore.setIsRecording();

        recorder.ondataavailable = event => data.push(event.data);
        recorder.start();
        log(recorder.state + " for " + (lengthInMS/1000) + " seconds...");

        let stopped = new Promise((resolve, reject) => {
            recorder.onstop = resolve;
            recorder.onerror = event => reject(event.name);
        });

        wait(lengthInMS).then(
            () => recorder.state === "recording" && recorder.stop()
        );

        stopBtn.addEventListener("click", () => {
            recorder.state === "recording" && recorder.stop()
        })
        
        return Promise.all([
            stopped
        ])
        .then(() => data);
    }

    const handleClick = () => {
        if (replayVisible === true){
            setReplayVisible(false)
            setButtonValue(t('recorder.startCamera'))
        } else {
            setButtonValue(t('recorder.tryAgain'))
        }

        let preview = previewRef.current;
        navigator.mediaDevices.getUserMedia({
            audio: true,
            video: {facingMode: "user", width: {min: 640, ideal: 1280, max:1920}, height:{min:480, ideal:720, max:1080}}
          }).then(stream => {
            preview.srcObject = stream;
            preview.captureStream = preview.captureStream || preview.mozCaptureStream;
            preview.onloadedmetadata = () => {
                preview.play()
            }
            return new Promise(resolve => preview.onplaying = resolve);
          }).then(() =>
            StartRecording(preview.captureStream(), 300000)
          ).then (recordedChunks => {
            setReplayVisible(true);
            let recordedBlob = new Blob(recordedChunks);
            replayRef.current.src = URL.createObjectURL(recordedBlob);
            props.onRecordingComplete(recordedBlob);
            resultstore.setIsRecording();
          })
          .catch(log);
    }
    
    const handleStopClick = () => {
        setButtonValue(t('recorder.tryAgain'));
        stop(previewRef.current.srcObject);
    }

    const handleOverlayClick = () => {
        setOverlayButtonValue(!gridVisible ? "Hide Grid" : "Show Grid");
        setGridVisible(!gridVisible);
    }

    const drawGridLines = () => {
        let video = null;
        if (replayVisible){
            video = replayRef.current;
        }else{
            video = previewRef.current;
        }

        let canvas = canvasRef.current;
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        var width = canvas.width;
        var height = canvas.height;
        var ctx = canvas.getContext('2d');
        var lineWidth = gridOptions.width;
        ctx.strokeStyle = gridOptions.color;
        ctx.lineWidth = lineWidth;
        ctx.beginPath();

        var i = null;

        for (i = 1; i <= gridOptions.hSections; i++) {
            var x = (i * width / gridOptions.hSections);
            ctx.moveTo(x, 0);
            ctx.lineTo(x, height);
            ctx.stroke();
        }

        for (i = 1; i <= gridOptions.vSections; i++) {
            var y = (i * height / gridOptions.vSections);
            ctx.moveTo(0, y);
            ctx.lineTo(width, y);
            ctx.stroke();
        }

        ctx.closePath();
    }

    return (
        <>
            <VideoContainer>
                {gridVisible && <Canvas id='grid' ref={canvasRef}/>}
                {replayVisible ?
                    <Video key='replay' ref={replayRef} controls controlsList></Video> :
                    <Video key='preview' ref={previewRef} autoplay muted></Video>
                }
            </VideoContainer>
            <Buttons>
                <StartButton onClick={handleClick} type="button">{buttonValue}</StartButton>
                {!replayVisible && <StopButton id="stopBtn" type="button" onClick={handleStopClick} >{t('recorder.stopBtn')}</StopButton>}
                <Button onClick={handleOverlayClick} type="button">{overlayButtonValue}</Button>
            </Buttons>
        </>
    )
})

export default Recorder