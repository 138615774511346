import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { monthDiff } from "../helperComponents/dateHelper";
import { formatDate, parseDate } from "react-day-picker/moment";

// Include the locale utils designed for moment
import MomentLocaleUtils from "react-day-picker/moment";

// Make sure moment.js has the required locale data
import "moment/locale/ca";
import "moment/locale/de";
import "moment/locale/en-gb";
import "moment/locale/es";
import "moment/locale/fr";
import "moment/locale/nl";
import styled from "styled-components";

const DateInput = styled.div`
    & .DayPickerInput {
        width: 35%;
        padding-right: 0.5rem;
    }

    & input {
        width: 100%;
        background: #ffffff;
        border: 1px solid #51c0cf;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(197, 197, 197, 0.25);
        border-radius: 10px;
        padding: 10px 10px;
        margin-top: 5px;
        outline: none;
        font-size: 16px;
    }
`;

const DateInputSpan = styled.span``;

const StyledDayPickerInput = styled(DayPickerInput)`
    boder: 1px solid #51c0cf;
`;

const DateRangePicker = (props) => {
    const { t, i18n } = useTranslation();
    const now = new Date();
    now.setHours(12,0,0,0);
    const [from, setFrom] = useState(now);
    const [to, setTo] = useState(now);
    const modifiers = { start: from, end: to };
    var toInput = null;

    useEffect(() => {
        props.callback(modifiers);
        showFromMonth();
    }, [from, to]);

    const showFromMonth = () => {
        if (toInput.getDayPicker() == null) {
            return;
        }
        if (monthDiff(to, from) < 2) {
            toInput.getDayPicker().showMonth(from);
        }
    };

    const handleFromChange = (from) => {
        // Change the from date and focus the "to" input field
        if (from < now) return;
        if (from > to) setTo(from);
        setFrom(from);
    };

    const handleToChange = (to) => {
        if (to < now) return;
        if (to < from) setFrom(to);
        setTo(to);
    };

    return (
        <DateInput aria-label="dateRange">
            <DayPickerInput
                value={from}
                placeholder={from?.toLocaleDateString(i18n.language)}
                format="LL"
                formatDate={formatDate}
                parseDate={parseDate}
                dayPickerProps={{
                    selectedDays: [from, { from, to }],
                    disabledDays: { after: to, before: now},
                    toMonth: to,
                    modifiers,
                    numberOfMonths: 2,
                    localeUtils: MomentLocaleUtils,
                    locale: i18n.language,
                    onDayClick: () => toInput.getInput().focus(),
                }}
                onDayChange={handleFromChange}
            />
            {" — "}
            <DateInputSpan>
                <StyledDayPickerInput
                    ref={(el) => (toInput = el)}
                    value={to}
                    placeholder={to?.toLocaleDateString(i18n.language)}
                    format="LL"
                    formatDate={formatDate}
                    parseDate={parseDate}
                    dayPickerProps={{
                        selectedDays: [from, { from, to }],
                        disabledDays: { before: from },
                        modifiers,
                        month: from,
                        fromMonth: from,
                        localeUtils: MomentLocaleUtils,
                        locale: i18n.language,
                        numberOfMonths: 2,
                    }}
                    onDayChange={handleToChange}
                />
            </DateInputSpan>
        </DateInput>
    );
};

export default DateRangePicker;
