import {autoCorrelate} from './pitchHelper';

// Gekopieerd uit volgende repository: https://github.com/cwilso/PitchDetect
export const getShimmerValues = (buf, sampleRate) => {
    // Implements the ACF2+ algorithm
    let SIZE = buf.length;
    let rms = 0;
  
    for (let i = 0; i < SIZE; i++) {
      const val = buf[i];
      rms += val * val;
    }
    rms = Math.sqrt(rms / SIZE);
    if (rms < 0.01){ // not enough signal
      return 0;
    }

    //buf = buf.slice(r1, r2);
    SIZE = buf.length;  

    const averagedBuffer = new Array(SIZE-3).fill(0);

    //for (let i = 0; i < averagedBuffer.length; i++){
   //     averagedBuffer[i] = (buf[i] + buf[i+1] + buf[i+2]+ buf[i+3]+ buf[i+4]+ buf[i+5] + buf[i+6] + buf[i+7]+ buf[i+8]+ buf[i+9] + buf[i+10] + buf[i+11] + buf[i+12]+ buf[i+13]+ buf[i+14]+ buf[i+15] + buf[i+16] + buf[i+17]+ buf[i+18]+ buf[i+19])/ 20;
    //}

     for (let i = 0; i < averagedBuffer.length; i++){
        averagedBuffer[i] = (buf[i] + buf[i+1] + buf[i+2])/3;
    }

    //find current pitch
    const currentPitch = autoCorrelate(buf,sampleRate);

    //samplejump betwween peaks
    const sampleJump = Math.round((sampleRate/currentPitch)/4);

    //get theoretical peak count    
    const availablePeakCount = Math.floor(averagedBuffer.length/(sampleRate/currentPitch))

    if (availablePeakCount < 3) {
        return 9
    }

    //peak sample no array
    const peakLocations = new Array(availablePeakCount).fill(-1);
    //peak sample height array
    const peakHeights = new Array(availablePeakCount).fill(-1);

    //populating peak locations and heights
    for (var i = 0; i < availablePeakCount; i++) {  
        if (i == 0) { 
            //saving buffer space by starting from 0
            for (var o = 0; o < (sampleJump*4);o++) {
                if (averagedBuffer[o] > peakHeights[i]) {
                   peakLocations[i] = o;
                   peakHeights[i] = averagedBuffer[o];
                }
            }
        }
        if (i > 0) {
            for (var p = peakLocations[i-1] + sampleJump*3; p < (peakLocations[i-1]+sampleJump*5);p++) {
                if (averagedBuffer[p] > peakHeights[i]) {
                   peakLocations[i] = p;
                   peakHeights[i] = averagedBuffer[p];
                }
            }
        }
    }

    const peakDiff = new Array(availablePeakCount-1).fill(-1);

    for (var i = 0; i < peakDiff.length; i++) {
        peakDiff[i] = Math.abs(peakHeights[i+1] - peakHeights[i]);
    }

    var peakDiffSum = 0;

    for (var i = 0; i < peakDiff.length; i++){
        peakDiffSum = peakDiffSum + peakDiff[i];
    }

    var peakDiffAvg = peakDiffSum / peakDiff.length;

    var peakSum = 0;

    for (var i = 0; i < peakHeights.length; i++){
        peakSum = peakSum + peakHeights[i];
    }

    var peakAvg = peakSum / peakHeights.length;

    let result = peakDiffAvg / peakAvg * 100

    return result;
}