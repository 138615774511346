import React, { useContext, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import registrationStore from "../components/registrationStore";
import Button from "../components/styled/button";
import { H4 } from "../components/styled/typography";
import { api } from "../api";
import { useTranslation } from "react-i18next";

const FormGroup = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
`;

const PhoneNumber = styled.div`
    display: flex;
`;

const Input = styled(Field)`
    background: #ffffff;
    border: 1px solid #51c0cf;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(197, 197, 197, 0.25);
    border-radius: 10px;
    padding: 10px 10px;
    margin-top: 5px;
    outline: none;
    font-size: 16px;
`;

const InputPhone = styled(Input)`
    margin-left: 10px;
    flex-grow: 2;
`;
const Label = styled.label`
    font-size: 16px;
    font-weight: 600;
    color: #545454;
`;

const RegisterForm = styled(Form)``;

const SmallTitle = styled(H4)`
    margin-bottom: 15px;
    margin-top: 0px;
`;

const SubmitButton = styled(Button)`
    margin-top: 20px;
    padding-right: 45px;
    padding-left: 45px;
    font-size: 18px;
`;
const Half = styled.div`
    width: 49%;
`;

const ThreeFourth = styled.div`
    width: 74%;
`;

const OneFourth = styled.div`
    width: 24%;
`;

const Flex = styled.div`
    display: flex;
    justify-content: space-between;
`;

const FlexLeft = styled.div`
    margin-top: 10px;
    display: flex;
`;

const Error = styled.p`
    font-size: 16px;
    color: #afafaf;
    font-style: italic;
`;

const Center = styled.div`
    text-align: center;
`;

const PhonePrefix = styled(Input)`
    width: 30%;

    @media (min-width: 980px) {
        width: 20%;
    }
`;

const gegevensForm = observer((props) => {
    const { t, i18n } = useTranslation();
    const telephone = props.user.telephone;
    const store = useContext(registrationStore);

    const [isMan, setIsMan] = useState(props.user.sex === "Man")

    return (
        <div>
            <Formik
                initialValues={{
                    firstName: props.user.firstName || "",
                    lastName: props.user.lastName || "",
                    SSN: props.user.socialSecurityNumber || "",
                    dateOfBirth: props.user.dateOfBirth || "",
                    TelephonePrefix: (telephone || "").substring(0, 3) || "",
                    TelephoneNumber: (telephone || "").substring(3) || "",
                    streetName: props.user.street || "",
                    houseNumber: props.user.number || "",
                    zipCode: props.user.zipCode || "",
                    city: props.user.city || "",
                    country: props.user.country || "",
                }}
                onSubmit={(values, actions) => {
                    if (
                        document.getElementById("radioMan").checked !== true &&
                        document.getElementById("radioVrouw").checked !== true
                    ) {
                        actions.setErrors({ geslacht: "Gelieve een geslacht aan te duiden." });
                        actions.setSubmitting(false);
                    } else {
                        var geslacht = "";
                        if (document.getElementById("radioMan").checked) {
                            geslacht = "Man";
                        } else {
                            geslacht = "Vrouw";
                        }
                        api.put("/user/" + props.user.public_id, {
                            firstName: values.firstName,
                            lastName: values.lastName,
                            dateOfBirth: values.dateOfBirth,
                            socialSecurityNumber: values.SSN,
                            sex: geslacht,
                            telephone: values.TelephonePrefix + values.TelephoneNumber,
                            street: values.streetName,
                            number: values.houseNumber ? values.houseNumber : null,
                            zipCode: values.zipCode,
                            city: values.city,
                            country: values.country,
                        }).then(function (response) {
                            actions.setSubmitting(false);
                            store.setUser(JSON.stringify(response.data.user));
                            props.flash();
                        });
                    }
                }}
                render={({ errors, touched, values }, isSubmitting) => (
                    <RegisterForm>
                        <SmallTitle black>{t("gegevensForm.general")}</SmallTitle>
                        <Flex>
                            <Half>
                                <FormGroup>
                                    <Label>{t("gegevensForm.firstName")}</Label>
                                    <Input
                                        type="text"
                                        name="firstName"
                                        placeholder={t("gegevensForm.firstName") + "..."}
                                    />
                                    {touched.firstName && errors.firstName && <Error>{errors.firstName}</Error>}
                                </FormGroup>
                            </Half>
                            <Half>
                                <FormGroup>
                                    <Label>{t("gegevensForm.lastName")}</Label>
                                    <Input
                                        type="text"
                                        name="lastName"
                                        placeholder={t("gegevensForm.lastName") + "..."}
                                    />
                                    {touched.lastName && errors.lastName && <Error>{errors.lastName}</Error>}
                                </FormGroup>
                            </Half>
                        </Flex>
                        <Flex>
                            <Half>
                                <FormGroup>
                                    <Label>{t("gegevensForm.birthDate")}</Label>
                                    <Input
                                        type="text"
                                        name="dateOfBirth"
                                        placeholder={t("gegevensForm.birthDate") + "..."}
                                    />
                                    {touched.dateOfBirth && errors.dateOfBirth && <Error>{errors.dateOfBirth}</Error>}
                                </FormGroup>
                            </Half>
                            <Half>
                                <FormGroup>
                                    <Label>{t("gegevensForm.ssn")}</Label>
                                    <Input type="text" name="SSN" placeholder={t("gegevensForm.ssn") + "..."} />
                                    {touched.SSN && errors.SSN && <Error>{errors.SSN}</Error>}
                                </FormGroup>
                            </Half>
                        </Flex>
                        <FormGroup>
                            <Label>{t("gegevensForm.tel")}</Label>
                            <PhoneNumber>
                                <PhonePrefix
                                    type="text"
                                    name="TelephonePrefix"
                                    value={values.TelephonePrefix}
                                    placeholder="+..."
                                />
                                <InputPhone type="text" name="TelephoneNumber" />
                            </PhoneNumber>
                            {touched.TelephonePrefix && errors.TelephonePrefix && (
                                <Error>{errors.TelephonePrefix}</Error>
                            )}
                            {touched.TelephoneNumber && errors.TelephoneNumber && (
                                <Error>{errors.TelephoneNumber}</Error>
                            )}
                        </FormGroup>
                        <FormGroup>
                            <Label>{t("gegevensForm.geslacht")}</Label>
                            <FlexLeft>
                                <Label>
                                    <Input
                                        type="radio"
                                        name="geslacht"
                                        id="radioMan"
                                        checked={isMan}
                                        onChange={(e) => isMan ? setIsMan(false) : setIsMan(true)}
                                    />
                                    {t("gegevensForm.man")}
                                </Label>
                                <Label>
                                    <Input
                                        type="radio"
                                        name="geslacht"
                                        id="radioVrouw"
                                        checked={! isMan}
                                        onChange={(e) => isMan ? setIsMan(false) : setIsMan(true)}
                                    />
                                    {t("gegevensForm.vrouw")}
                                </Label>
                            </FlexLeft>
                            {errors.geslacht && <Error>{errors.geslacht}</Error>}
                        </FormGroup>
                        <SmallTitle black>{t("gegevensForm.adress")}</SmallTitle>
                        <Flex>
                            <ThreeFourth>
                                <FormGroup>
                                    <Label>{t("gegevensForm.street")}</Label>
                                    <Input
                                        type="text"
                                        name="streetName"
                                        placeholder={t("gegevensForm.street") + "..."}
                                    />
                                    {touched.streetName && errors.streetName && <Error>{errors.streetName}</Error>}
                                </FormGroup>
                            </ThreeFourth>
                            <OneFourth>
                                <FormGroup>
                                    <Label>{t("gegevensForm.housenumber")}</Label>
                                    <Input
                                        type="text"
                                        name="houseNumber"
                                        placeholder={t("gegevensForm.housenumber") + "..."}
                                    />
                                    {touched.houseNumber && errors.houseNumber && <Error>{errors.houseNumber}</Error>}
                                </FormGroup>
                            </OneFourth>
                        </Flex>
                        <Flex>
                            <Half>
                                <FormGroup>
                                    <Label>{t("gegevensForm.zip")}</Label>
                                    <Input type="text" name="zipCode" placeholder={t("gegevensForm.zip") + "..."} />
                                    {touched.zipCode && errors.zipCode && <Error>{errors.zipCode}</Error>}
                                </FormGroup>
                            </Half>
                            <Half>
                                <FormGroup>
                                    <Label>{t("gegevensForm.city")}</Label>
                                    <Input type="text" name="city" placeholder={t("gegevensForm.city") + "..."} />
                                    {touched.city && errors.city && <Error>{errors.city}</Error>}
                                </FormGroup>
                            </Half>
                        </Flex>

                        <FormGroup>
                            <Label>{t("gegevensForm.country")}</Label>
                            <Input type="text" name="country" placeholder={t("gegevensForm.country") + "..."} />
                            {touched.country && errors.country && <Error>{errors.country}</Error>}
                        </FormGroup>
                        <SubmitButton disabled={isSubmitting}>{t("gegevensForm.configure")}</SubmitButton>
                    </RegisterForm>
                )}
                validationSchema={Yup.object().shape({
                    firstName: Yup.string().required(t("gegevensForm.fnPlace")),
                    lastName: Yup.string().required(t("gegevensForm.lnPlace")),
                    dateOfBirth: Yup.string()
                        .required(t("gegevensForm.datePlace"))
                        .matches(/^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/, "Formaat: dd/mm/yyyy"),
                    SSN: Yup.string().required(t("gegevensForm.ssnForced")),
                    TelephonePrefix: Yup.string().required(t("gegevensForm.countryNumPlace")),
                    TelephoneNumber: Yup.string().length(9, t("gegevensForm.nmrPlace")),
                    streetName: Yup.string().required(t("gegevensForm.streetPlace")),
                    houseNumber: Yup.number().required(t("gegevensForm.housePlace")),
                    zipCode: Yup.string()
                        .length(4, t("gegevensForm.correctZipPlace"))
                        .required(t("gegevensForm.zipPlace")),
                    city: Yup.string().required(t("gegevensForm.cityPlace")),
                    country: Yup.string().required(t("gegevensForm.countryPlace")),
                })}
            />
        </div>
    );
});

export default gegevensForm;
