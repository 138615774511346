import React, {useRef, useState, useEffect, useContext } from 'react';
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Formik, Form, Field, FieldArray, getIn, setIn } from 'formik';
import styled from "styled-components";
import Card from "../styled/card";
import Button from "../styled/button";
import { SmallTitle } from "../styled/typography";
import { CallbackSwitch } from "../styled/callbackSwitch";
import { Switch } from "../styled/switch";
import MediaOverlay from "./mediaSelectionOverlay";
import * as Yup from "yup";
import { Disciplines, Subdisciplines } from "../resources/disciplines";
import { Languages } from "../resources/languages";
import TagSelect from "../helperComponents/tagSelect";
import registrationStore from "../registrationStore";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CKEditor from "@ckeditor/ckeditor5-react";
import LoadSpinner from '../loadspinner';
import { api } from '../../api';
import { Redirect } from "react-router-dom";
import { DisplayFormikState } from '../helperComponents/formHelper';
import {
    FaTimes,
    FaTrash,
    FaPencilAlt,
    FaInfoCircle,
    FaShoePrints,
    FaCog,
    FaBullhorn,
    FaPhotoVideo
} from "react-icons/fa";

const ExcerciseForm = styled(Form)``;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const MultiLineGroup = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  border-left: 1px solid rgba(123, 140, 168, 0.25);
`;

const Input = styled(Field)`
  background: #ffffff;
  border: 1px solid #51c0cf;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(197, 197, 197, 0.25);
  border-radius: 10px;
  padding: 10px 10px;
  margin-top: 5px;
  outline: none;
  font-size: 16px;
  max-height: 100%;
  margin-right: 20px;

  &:focus {
    resize: both;
  }
`;

const Label = styled.label`
  font-size: 16px;
  font-weight: 600;
  color: #545454;
  margin-right: 20px;
`;

const Error = styled.p`
  font-size: 16px;
  color: #afafaf;
  font-style: italic;
`;

const Overlay = styled.div`
  background-color: rgba(60, 60, 60, 0.7);
  position: fixed;
  top: 0px;
  left: 0;
  width: 100vw;
  height: 100%;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 980px) {
    top: 0;
    height: 100vh;
    width: 100vw;
  }
`;

const OverlayCard = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-height: 75%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
  padding: 30px;
  position: absolute;
  z-index: 999999;

  @media (min-width: 980px) {
    position: relative;
    top: 0;
    z-index: 9;
  }
`;

const Times = styled(FaTimes)`
  color: #545454;
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
`;

const Trash = styled(FaTrash)`
  color: #545454;
  cursor: pointer;
`;

const Pencil = styled(FaPencilAlt)`
  color: #545454;
  margin-right: 20px;
  cursor: pointer;
`;

const SmallButton = styled(Button)`
  margin-top: 5px;
  width: fit-content;
`;

const SelectMediaButton = styled(Button)`  
  display: inline-block;
  vertical-align: middle;
  height: 25px;  
  padding: 3px 15px 10px 15px; 
  font-size: 0.9em;
  text-align: center;
  margin-left: 10px;
`;

const Title = styled(SmallTitle)`
  margin-top: 10px;
  margin-right: 40px;
`;

const FileCard = styled(Card)`
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const InfoIcon = styled(FaInfoCircle)`  
  color: #51c0cf;
  font-size: 15px;
  margin-right: 10px;
  vertical-align: middle;
  margin-bottom: 5px;
`;

const StepsIcon = styled(FaShoePrints)`  
  color: #51c0cf;
  font-size: 15px;
  margin-right: 10px;
  vertical-align: middle;
  margin-bottom: 5px;
`;

const CogIcon = styled(FaCog)`  
  color: #51c0cf;
  font-size: 15px;
  margin-right: 10px;
  vertical-align: middle;
  margin-bottom: 5px;
`;

const BullHornIcon = styled(FaBullhorn)`  
  color: #51c0cf;
  font-size: 15px;
  margin-right: 10px;
  vertical-align: middle;
  margin-bottom: 5px;
`;

const MultimediaIcon = styled(FaPhotoVideo)`  
  color: #51c0cf;
  font-size: 15px;
  margin-right: 10px;
  vertical-align: middle;
  margin-bottom: 5px;
`;

const ExerciseEditForm = observer((props) => {
    const { t, i18n } = useTranslation();
    const [showOverlay, setShowOverlay] = useState(false);
    const [showSecondOverlay, setShowSecondOverlay] = useState(false);
    const [showMediaOverlay, setShowMediaOverlay] = useState(false);
    const [questionTypeId, setQuestionTypeId] = useState(-1);
    const [questionId, setQuestionId] = useState(-1);
    const [stepId, setStepId] = useState(-1);
    const [mediaInputLabel, setMediaInputLabel] = useState("");
    const store = useContext(registrationStore);
    const user = JSON.parse(store.user);
    const [tags, setTags] = useState(props.exercise.tags);
    const [formSchema, setFormShema] = useState(Yup.object().shape({}));
    const [loading, setLoading] = useState(false);
    const [redirect, setRedirect] = useState(false);


    const formikRef = useRef();

    const validationShape = {
        title: Yup.string()
            .max(20, t("exerciseForm.titleMaxChars"))
            .required(t("exerciseForm.titleReqError")),
        discipline: Yup.string().required(t("exerciseForm.disciplineReqError")),
        subdiscipline: Yup.string().notRequired().nullable(),
        language: Yup.string().required(t("exerciseForm.languageReqError")),
        description: Yup.string().notRequired(),
        is_private: Yup.bool().notRequired()
    };

    useEffect(() => adaptValidation(), []);

    useEffect(() => {
        //Focus on overlay
        if (showOverlay) {
            autoFocus(
                13,
                props.exerciseType === "stappenplan" ? `steps[${stepId}].text` : `question_types[${questionTypeId}].name`
            );
        }
    }, [showOverlay]);

    useEffect(() => {
        //Focus on overlay
        if (showSecondOverlay) {
            autoFocus(13, `${getQuestionId()}.question`);
        }
    }, [showSecondOverlay]);

    const adaptValidation = () => {
        let newValidation = validationShape;
        let question_type = {};
        let question = {};
        switch (props.exercise.type) {
            case "dagboek":
                const field = Yup.object().shape({
                    name: Yup.string().required(t("exerciseForm.fieldNameReqError"))
                });
                question = Yup.object().shape({
                    question: Yup.string().required(t("exerciseForm.vragenlijstQuestionReqError")),
                    answer_possibility: Yup.string().required(t("exerciseForm.vragenlijstAnswerPossibilityReqError")),
                    fields: Yup.array()
                        .when("answer_possibility", {
                            is: (val) => val != "open antwoord",
                            then: Yup.array()
                                .of(Yup.array()
                                    .of(field)
                                    .min(1, t("exerciseForm.fieldNamesError"))
                                    .required(t("exerciseForm.fieldNamesError"))),
                            otherwise: Yup.array().notRequired().nullable(true)
                        })
                });
                question_type = Yup.object().shape({
                    name: Yup.string().required(t("exerciseForm.vragenlijstQuestionTypeNameReqError")),
                    questions: Yup.array()
                        .of(question)
                        .min(1, t("exerciseForm.vragenlijstQuestionsError"))
                        .required(t("exerciseForm.vragenlijstQuestionsError")),
                });
                newValidation.question_types = Yup.array()
                    .of(question_type)
                    .min(1, t("exerciseForm.vragenlijstQuestionTypeError"))
                    .required(t("exerciseForm.vragenlijstQuestionTypeError"));
                break;
            case "stappenplan":
                const step = Yup.object().shape({
                    text: Yup.string().required(t("exerciseForm.stepTextReqError")),
                    media_id: Yup.number().nullable(true),
                    video_id: Yup.number().nullable(true),
                    audio_id: Yup.number().nullable(true),
                });
                newValidation.steps = Yup.array()
                    .of(step)
                    .min(1, t("exerciseForm.stepsError"))
                    .required(t("exerciseForm.stepsError"));
                newValidation.media_complete_id = Yup.number().nullable(true);
                newValidation.audio_complete_id = Yup.number().nullable(true);
                newValidation.video_complete_id = Yup.number().nullable(true);
                newValidation.goal = Yup.object().shape({
                    media_id: Yup.number().nullable(true),
                    video_id: Yup.number().nullable(true),
                });
                newValidation.preparation = Yup.object().nullable(true).shape({
                    media_id: Yup.number().nullable(true),
                    video_id: Yup.number().nullable(true),
                });
                break;
            case "stappenplanValidatie":
                const answer_possibility = Yup.object().shape({
                    answer: Yup.string().required(t("exerciseForm.invulAnswerPossibilityReqError"))
                });
                question = Yup.object().shape({
                    question: Yup.string().required(t("exerciseForm.invulQuestionReqError")),
                    media_id: Yup.number().nullable(true),
                    video_id: Yup.number().nullable(true),
                    audio_id: Yup.number().nullable(true),
                    cue_text: Yup.string().notRequired().nullable(true),
                    cue_media_id: Yup.number().nullable(true),
                    cue_video_id: Yup.number().nullable(true),
                    cue_audio_id: Yup.number().nullable(true),
                    type_of_answer: Yup.string().required(t("exerciseForm.typeOfAnswerReqError")),
                    correct_answer: Yup.string().required(t("exerciseForm.correctAnswerReqError")),
                    answer_possibilities: Yup.array()
                        .when("type_of_answer", {
                            is: (val) => val === "Meerkeuze",
                            then: Yup.array()
                                .of(Yup.array()
                                    .of(answer_possibility)
                                    .min(1, t("exerciseForm.answerPossibilitiesError"))
                                    .required(t("exerciseForm.answerPossibilitiesError"))),
                            otherwise: Yup.array().notRequired().nullable(true)
                        })
                });
                question_type = Yup.object().shape({
                    name: Yup.string().required(t("exerciseForm.invulQuestionTypeNameReqError")),
                    questions: Yup.array()
                        .of(question)
                        .min(1, t("exerciseForm.invulQuestionsError"))
                        .required(t("exerciseForm.invulQuestionsError")),
                });
                newValidation.question_types = Yup.array()
                    .of(question_type)
                    .min(1, t("exerciseForm.invulQuestionTypeError"))
                    .required(t("exerciseForm.invulQuestionTypeError"));
                newValidation.media_complete_id = Yup.number().nullable(true);
                newValidation.audio_complete_id = Yup.number().nullable(true);
                newValidation.video_complete_id = Yup.number().nullable(true);
                newValidation.goal = Yup.object().shape({
                    media_id: Yup.number().nullable(true),
                    video_id: Yup.number().nullable(true),
                });
                newValidation.preparation = Yup.object().shape({
                    media_id: Yup.number().nullable(true),
                    video_id: Yup.number().nullable(true),
                }).nullable(true).notRequired();
                newValidation.reward_system = Yup.string().required(t("exerciseForm.rewardSystemReqError"));
                break;
            default:
                break;
        }
        setFormShema(Yup.object().shape(newValidation));
    };

    const handleSubmit = async (values) => {
        let fullValues = { ...values };

        setLoading(true);

        fullValues.tags = tags;
        fullValues.username_private = user?.email;

        if (props.exercise.type === "dagboek") {
            fullValues.question_types.forEach((questionType) => {
                questionType.questions.forEach((question) => {
                    if (question.answer_possibility === "schaal") {
                        question.scale_possibilities = question.fields[0].length;
                        var values = question.fields[0].map(field => field.value)
                        question.max_score = Math.max(...values);
                    }
                });
            });
        }

        api.put('/exercise/' + props.exercise.id, fullValues).then((res) => {
            setLoading(false);
            setRedirect(true);
        })
    };

    const qaTypes = [
        <option key="NoneQA" value=""></option>,
        <option key="open antwoord" value="open antwoord">{t("exerciseForm.openAnswer")}</option>,
        <option key="schaal" value="schaal">{t("exerciseForm.scale")}</option>,
    ];
    const answerTypes = [
        <option key="NoneA" value=""></option>,
        <option key="Meerkeuze" value="Meerkeuze">{t("exerciseForm.multipleChoice")}</option>,
        <option key="Textbox" value="Textbox">{t("exerciseForm.textbox")}</option>,
    ];
    const rewardSystems = [
        <option key="NoneR" value=""></option>,
        <option key="Geen" value="Geen">{t("exerciseForm.noneReward")}</option>,
        <option key="Score" value="Score">{t("exerciseForm.scoreReward")}</option>,
    ];

    const handleShowOverlay = (values, id = null) => {
        var newTypeId = id != null ? id : values.question_types?.length ?? 0;
        var newStepId = id != null ? id : values.steps?.length ?? 0;
        setQuestionTypeId(newTypeId);
        setStepId(newStepId);
        setShowOverlay(true);
    };

    const handleShowSecondOverlay = (values, id = null) => {
        var newQuestionId = id != null ? id : values.question_types?.[questionTypeId]?.questions?.length ?? 0;
        setQuestionId(newQuestionId);
        setShowSecondOverlay(true);
    };

    const handleHideOverlay = () => {
        setShowOverlay(false);
    };

    const handleHideSecondOverlay = () => {
        setShowSecondOverlay(false);
    };

    const getQuestionId = () => `question_types[${questionTypeId}].questions[${questionId}]`;

    const autoFocus = (key, name) => {
        if (key === 13) {
            var len = document.getElementsByName(name).length;
            if (len > 0) {
                const element = document.getElementsByName(name)[len - 1];
                element.focus();
            }
        }
    };

    const showError = (touched, errors, name, wantTouched = true) => {
        if (typeof getIn(errors, name) !== "string") return null;
        return wantTouched
            ? getIn(touched, name) && getIn(errors, name) && <Error>{getIn(errors, name)}</Error>
            : getIn(errors, name) && <Error>{getIn(errors, name)}</Error>;
    };

    const showMediaSelOverlay = (inputLabel) => {
        setMediaInputLabel(inputLabel);
        setShowMediaOverlay(true);
    };

    const SetMediaInput = (media) => {
        formikRef.current.setFieldValue(mediaInputLabel, media.id);
        formikRef.current.setFieldValue(mediaInputLabel + "Label", `${media.id} - ${media.title}`);
    };

    const MediaSwitch = (fProps, switchLabel, valuePath) => {
        const switchName = valuePath;
        const assetName = valuePath;
        return (
            <>
                <CallbackSwitch 
                    name={switchName} 
                    label={t(switchLabel)} 
                    checked={getIn(fProps.values, assetName)}
                    callback={(val) => ! val && fProps.setFieldValue(assetName, null)}
                    >
                    <br /><br />
                    <SelectMediaButton type="button" onClick={() => showMediaSelOverlay(assetName)}>
                        <FaPhotoVideo />{" "}
                        {t("exerciseForm.selectMedia")}{" "}
                    </SelectMediaButton>
                    <Input aria-label={assetName} type="hidden" name={assetName}/>
                </CallbackSwitch>
                {showError(fProps.touched, fProps.errors, assetName, false)}
                {getIn(fProps.values, assetName) && <FileCard>{getIn(fProps.values, assetName)}</FileCard>}

            </>
        );
    };

    const formLayout = ({ ...fProps }) => {
        return (
            <ExcerciseForm name="exerciseForm">
                {/*GENERAL HEADER FORM SECTION*/}
                <FormGroup>
                    <Label htmlFor="title">{t("exerciseForm.title")}</Label>
                    <Input
                        autoFocus={true}
                        onKeyDown={(e) => autoFocus(e.keyCode, "discipline")}
                        type="text"
                        id="title"
                        name="title"
                        placeholder={t("exerciseForm.titleInstructions")}
                    />
                    {showError(fProps.touched, fProps.errors, "title")}
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="discipline">{t("exerciseForm.discipline")}</Label>
                    <Input
                        component="select"
                        id="discipline"
                        name="discipline"
                        onKeyDown={(e) => autoFocus(e.keyCode, "subdiscipline")}
                    >
                        <option value="">{t("documentForm.allDis")}</option>
                        <Disciplines default={user?.speciality} />
                    </Input>
                    {showError(fProps.touched, fProps.errors, "discipline")}
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="subdiscipline">{t("exerciseForm.subdiscipline")}</Label>
                    <hr />
                    <Input
                        component="select"
                        id="subdiscipline"
                        name="subdiscipline"
                        onKeyDown={(e) => autoFocus(e.keyCode, "language")}
                    >
                        <option value="">{t("exerciseForm.allSub")}</option>
                        <Subdisciplines discipline={fProps.values.discipline} />
                    </Input>
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="language">{t("exerciseForm.language")}</Label>
                    <Input
                        component="select"
                        id="language"
                        name="language"
                        onKeyDown={(e) => autoFocus(e.keyCode, props.exercise.type === "dagboek" ? "description" : "goal.text")}
                    >
                        <Languages default={i18n.language} />
                    </Input>
                    {showError(fProps.touched, fProps.errors, "language")}
                </FormGroup>

                {/* VRAGENLIJST UNIQUE HEADER FORM SECTION */}
                {props.exercise.type === "dagboek" && (
                    <>
                        <FormGroup>
                            <Label>{t("exerciseForm.descriptionInstructions")}</Label>
                            <CKEditor
                                id="description"
                                name="description"
                                editor={ ClassicEditor }
                                data={fProps.values.description}
                                onChange={ ( event, editor ) => {
                                    fProps.values.description = editor.getData();
                                }}
                                placeholder={t("exerciseForm.descriptionInstructions")}
                                config={{
                                    placeholder: t('fileForm.inputDescription'),
                                    toolbar: ['heading', '|', 'bold', 'italic', 'blockQuote', '|', 'numberedList', 'bulletedList', 'insertTable', 'tableColumn', 'tableRow', 'mergeTableCells',  '|', 'undo', 'redo'],
                                    cloudServices: {tokenUrl : process.env.REACT_APP_COBRAND_API + "/editor_token/" + user.public_id, uploadUrl : "https://55482.cke-cs.com/easyimage/upload/"}
                                }}
                                onError={ ( { willEditorRestart } ) => {
                                    // If the editor is restarted, the toolbar element will be created once again.
                                    // The `onReady` callback will be called again and the new toolbar will be added.
                                    // This is why you need to remove the older toolbar.
                                    if ( willEditorRestart ) {
                                        this.editor.ui.view.toolbar.element.remove();
                                    }
                                } }
                            />
                            {showError(fProps.touched, fProps.errors, "description")}
                        </FormGroup>
                    </>
                )}

                {/* INVUL + UITVOER CROSSOVER HEADER FORM SECTION */}
                {(props.exercise.type === "stappenplan" || props.exercise.type === "stappenplanValidatie") && (
                    <>
                        <FormGroup>
                            <Label htmlFor="goal.text"><InfoIcon />{t("exerciseForm.goal").charAt(0).toUpperCase() + t("exerciseForm.goal").slice(1)}</Label>
                            <hr />
                            <MultiLineGroup>
                                <CKEditor
                                    id="goal.text"
                                    name="goal.text"
                                    editor={ ClassicEditor }
                                    data={fProps.values.goal.text}
                                    onChange={ ( event, editor ) => {
                                        fProps.values.goal.text = editor.getData();
                                    }}
                                    config={{
                                        placeholder: t("exerciseForm.goalInstructions"),
                                        toolbar: ['heading', '|', 'bold', 'italic', 'blockQuote', '|', 'numberedList', 'bulletedList', 'insertTable', 'tableColumn', 'tableRow', 'mergeTableCells',  '|', 'undo', 'redo'],
                                        cloudServices: {tokenUrl : process.env.REACT_APP_COBRAND_API + "/editor_token/" + user.public_id, uploadUrl : "https://55482.cke-cs.com/easyimage/upload/"}
                                    }}
                                    onError={ ( { willEditorRestart } ) => {
                                        // If the editor is restarted, the toolbar element will be created once again.
                                        // The `onReady` callback will be called again and the new toolbar will be added.
                                        // This is why you need to remove the older toolbar.
                                        if ( willEditorRestart ) {
                                            this.editor.ui.view.toolbar.element.remove();
                                        }
                                    } }
                                />
                                {MediaSwitch(fProps, "exerciseForm.mediaCheck", "goal.media_id")}
                                {MediaSwitch(fProps, "exerciseForm.videoCheck", "goal.video_id")}
                            </MultiLineGroup>
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="preparation.text"><InfoIcon />{t("exerciseForm.preparation").charAt(0).toUpperCase() + t("exerciseForm.preparation").slice(1)}</Label>
                            <hr />
                            <MultiLineGroup>
                                <CKEditor
                                    id="preparation.text"
                                    name="preparation.text"
                                    editor={ ClassicEditor }
                                    data={fProps.values.preparation.text}
                                    onChange={ ( event, editor ) => {
                                        fProps.values.preparation.text = editor.getData();
                                    }}
                                    config={{
                                        placeholder: t("exerciseForm.preparationInstructions"),
                                        toolbar: ['heading', '|', 'bold', 'italic', 'blockQuote', '|', 'numberedList', 'bulletedList', 'insertTable', 'tableColumn', 'tableRow', 'mergeTableCells',  '|', 'undo', 'redo'],
                                        cloudServices: {tokenUrl : process.env.REACT_APP_COBRAND_API + "/editor_token/" + user.public_id, uploadUrl : "https://55482.cke-cs.com/easyimage/upload/"}
                                    }}
                                    onError={ ( { willEditorRestart } ) => {
                                        // If the editor is restarted, the toolbar element will be created once again.
                                        // The `onReady` callback will be called again and the new toolbar will be added.
                                        // This is why you need to remove the older toolbar.
                                        if ( willEditorRestart ) {
                                            this.editor.ui.view.toolbar.element.remove();
                                        }
                                    } }
                                />
                                {MediaSwitch(fProps, "exerciseForm.mediaCheck", "preparation.media_id")}
                                {MediaSwitch(fProps, "exerciseForm.videoCheck", "preparation.video_id")}
                            </MultiLineGroup>
                        </FormGroup>
                        <FormGroup>
                        <Label><MultimediaIcon />{t("exerciseForm.multimedia").charAt(0).toUpperCase() + t("exerciseForm.multimedia").slice(1)}</Label>
                        <hr />
                        <MultiLineGroup>
                            <FormGroup>
                                <Label>{t("exerciseForm.mediaComplete")}</Label>
                                {MediaSwitch(fProps, "exerciseForm.mediaCompleteCheck", "media_complete_id")}
                            </FormGroup>
                            <FormGroup>
                                <Label>{t("exerciseForm.audioComplete")}</Label>
                                {MediaSwitch(fProps, "exerciseForm.audioCompleteCheck", "audio_complete_id")}
                            </FormGroup>
                            <FormGroup>
                                <Label>{t("exerciseForm.videoComplete")}</Label>
                                {MediaSwitch(fProps, "exerciseForm.videoCompleteCheck", "video_complete_id")}
                            </FormGroup>
                        </ MultiLineGroup>
                        </FormGroup>
                    </>
                )}

                {/* UITVOER UNIQUE MIDDLE FORM SECTION */}
                {props.exercise.type === "stappenplan" && (
                    <FormGroup>
                        <Label htmlFor="steps"><StepsIcon />{t("exerciseForm.steps")}</Label>
                        <hr />
                        <MultiLineGroup>
                        <FieldArray
                            id="steps"
                            name="steps"
                            render={(arrayHelpers) => (
                                <div>
                                    {fProps.values.steps?.map((step, index) => (
                                        <FileCard key={"step" + index}>
                                            <Label>{step.text}</Label>
                                            <div>
                                                <Pencil onClick={() => handleShowOverlay(fProps.values, index)} />
                                                <Trash onClick={() => arrayHelpers.remove(index)} />
                                            </div>
                                        </FileCard>
                                    ))}
                                    <SmallButton onClick={() => handleShowOverlay(fProps.values)} type="button">
                                        {t("exerciseForm.addNewStep")}
                                    </SmallButton>
                                    {showError(fProps.touched, fProps.errors, "steps", false)}
                                </div>
                            )}
                        />
                        </MultiLineGroup>
                    </FormGroup>
                )}

                {/* INVUL + VRAGENLIJST CROSSOVER MIDDLE FORM SECTION */}
                {(props.exercise.type === "stappenplanValidatie" || props.exercise.type === "dagboek") && (
                    <FormGroup>
                        <Label htmlFor="question_types">
                            <InfoIcon />{t(props.exercise.type === "stappenplanValidatie" ? "exerciseForm.steps" : "exerciseForm.exerciseTypes")}
                        </Label>
                        <hr />
                        <MultiLineGroup>
                        <FieldArray
                            id="question_types"
                            name="question_types"
                            render={(arrayHelpers) => (
                                <div>
                                    {fProps.values.question_types?.map((questionType, index) => (
                                        <FileCard key={"question_type" + index}>
                                            <p>{questionType.name}</p>
                                            <div>
                                                <Pencil onClick={() => handleShowOverlay(fProps.values, index)} />
                                                <Trash onClick={() => arrayHelpers.remove(index)} />
                                            </div>
                                        </FileCard>
                                    ))}
                                    {showError(fProps.touched, fProps.errors, "question_type", false)}
                                    <SmallButton onClick={() => handleShowOverlay(fProps.values)} type="button">
                                        {t(props.exercise.type === "stappenplanValidatie" ? "exerciseForm.addNewStep" : "exerciseForm.addNewExerciseType")}
                                    </SmallButton>
                                </div>
                            )}
                        />
                        </MultiLineGroup>
                    </FormGroup>
                )}

                {/* GENERAL MIDDLE FORM SECTION */}
                {showOverlay && (
                    <Overlay>
                        <OverlayCard>
                            <Times onClick={handleHideOverlay} />
                            <Title>
                                {props.exercise.type === "dagboek" ? t("exerciseForm.editExerciseType") : t("exerciseForm.editStep")}
                            </Title>
                            {props.exercise.type === "stappenplan" ? (
                                <>
                                    <FormGroup>
                                        <Label htmlFor={`steps[${stepId}].text`}>{t("exerciseForm.addText")}</Label>
                                        <Input
                                            component="textarea"
                                            id={`steps[${stepId}].text`}
                                            name={`steps[${stepId}].text`}
                                            placeholder={t("exerciseForm.stepTextInstructions")}
                                        />
                                        {showError(fProps.touched, fProps.errors, `steps[${stepId}].text`, false)}
                                    </FormGroup>
                                    {MediaSwitch(fProps, "exerciseForm.mediaCheck", `steps[${stepId}].media_id`)}
                                    {MediaSwitch(fProps, "exerciseForm.audioCheck", `steps[${stepId}].audio_id`)}
                                    {MediaSwitch(fProps, "exerciseForm.videoCheck", `steps[${stepId}].video_id`)}
                                    <SmallButton type="button" onClick={handleHideOverlay}>
                                        {t("exerciseForm.addStep")}
                                    </SmallButton>
                                </>
                            ) : (
                                    <>
                                        <FormGroup>
                                            <Label htmlFor={`question_types[${questionTypeId}].name`}>
                                                {t(props.exercise.type === "stappenplanValidatie" ? "exerciseForm.addText" : "exerciseForm.addExerciseTypeName")}
                                            </Label>
                                            <Input
                                                type="text"
                                                id={`question_types[${questionTypeId}].name`}
                                                name={`question_types[${questionTypeId}].name`}
                                                value={getIn(fProps.values, `question_types[${questionTypeId}].name`) || ""}
                                                placeholder={
                                                    props.exercise.type === "dagboek"
                                                        ? t("exerciseForm.exerciseTypeNameInstructions")
                                                        : t("exerciseForm.stepTextInstructions")
                                                }
                                            />
                                            {showError(fProps.touched, fProps.errors, `question_types[${questionTypeId}].name`, false)}
                                        </FormGroup>
                                        <Label htmlFor={`question_types[${questionTypeId}].questions`}>
                                            {t("exerciseForm.questions." + props.exercise.type)}
                                        </Label>
                                        <FieldArray
                                            id={`question_types[${questionTypeId}].questions`}
                                            name={`question_types[${questionTypeId}].questions`}
                                            render={(arrayHelpers) => (
                                                <div>
                                                    {fProps.values.question_types?.[questionTypeId]?.questions?.map((question, index) => (
                                                        <FileCard key={"question" + index}>
                                                            <p>{question.question}</p>
                                                            <div>
                                                                <Pencil onClick={() => handleShowSecondOverlay(fProps.values, index)} />
                                                                <Trash onClick={() => arrayHelpers.remove(index)} />
                                                            </div>
                                                        </FileCard>
                                                    ))}
                                                    {showError(fProps.touched, fProps.errors, `question_types[${questionTypeId}].questions`, false)}
                                                    <SmallButton type="button" onClick={() => handleShowSecondOverlay(fProps.values)}>
                                                        {t("exerciseForm.addExercise." + props.exercise.type)}
                                                    </SmallButton>
                                                </div>
                                            )}
                                        />
                                        <SmallButton type="button" onClick={handleHideOverlay}>
                                            {t(
                                                props.exercise.type === "stappenplanValidatie" ? "exerciseForm.addStep" : "exerciseForm.addExerciseType"
                                            )}
                                        </SmallButton>
                                    </>
                                )}
                        </OverlayCard>
                    </Overlay>
                )}

                {/*QUESTION CREATION OVERLAY SUBFORM SECTION*/}
                {showSecondOverlay && (
                    <Overlay>
                        <OverlayCard>
                            <Times onClick={handleHideSecondOverlay} />
                            {props.exercise.type === "stappenplanValidatie" ? (
                                <FormGroup>
                                    <Label htmlFor={`${getQuestionId()}.question`}>{t("exerciseForm.askQuestion")}</Label>
                                    <hr />
                                    <MultiLineGroup>
                                        <Input
                                            component="textarea"
                                            id={`${getQuestionId()}.question`}
                                            name={`${getQuestionId()}.question`}
                                            placeholder={t("exerciseForm.questionInstructions")}
                                            onKeyDown={(e) => autoFocus(e.keyCode, `${getQuestionId()}.question`)}
                                        />
                                        {showError(fProps.touched, fProps.errors, `${getQuestionId()}.question`, false)}
                                        {MediaSwitch(fProps, "exerciseForm.mediaCheck", `${getQuestionId()}.media_id`)}
                                        {MediaSwitch(fProps, "exerciseForm.audioCheck", `${getQuestionId()}.audio_id`)}
                                        {MediaSwitch(fProps, "exerciseForm.videoCheck", `${getQuestionId()}.video_id`)}
                                    </MultiLineGroup>
                                    <MultiLineGroup>
                                        <Input
                                            aria-label="askCue"
                                            component="textarea"
                                            name={`${getQuestionId()}.cue_text`}
                                            placeholder={t("exerciseForm.cueInstructions")}
                                            onKeyDown={(e) => autoFocus(e.keyCode, `${getQuestionId()}.type_of_answer`)}
                                        />
                                        {showError(fProps.touched, fProps.errors, `${getQuestionId()}.cue_text`)}
                                        {MediaSwitch(fProps, "exerciseForm.mediaCheck", `${getQuestionId()}.cue_media_id`)}
                                        {MediaSwitch(fProps, "exerciseForm.audioCheck", `${getQuestionId()}.cue_audio_id`)}
                                        {MediaSwitch(fProps, "exerciseForm.videoCheck", `${getQuestionId()}.cue_video_id`)}
                                    </MultiLineGroup>
                                    <br />
                                    <Label htmlFor={`${getQuestionId()}.type_of_answer`}>{t("exerciseForm.answerType")}</Label>
                                    <hr />
                                    <MultiLineGroup>
                                    <Input
                                        component="select"
                                        id={`${getQuestionId()}.type_of_answer`}
                                        name={`${getQuestionId()}.type_of_answer`}
                                        onKeyDown={(e) => autoFocus(e.keyCode, `${getQuestionId()}.correct_answer`)}
                                    >
                                        {answerTypes.map((answerType) => {
                                            return answerType;
                                        })}
                                    </Input>
                                    {showError(fProps.touched, fProps.errors, `${getQuestionId()}.type_of_answer`, false)}

                                    {getIn(fProps.values, `${getQuestionId()}.type_of_answer`) !== "Textbox" && (
                                        <>
                                            <Label htmlFor={`${getQuestionId()}.answer_possibilities[0]`}>
                                                {t("exerciseForm.possibleAnswers")}
                                            </Label>
                                            <FieldArray
                                                id={`${getQuestionId()}.answer_possibilities[0]`}
                                                name={`${getQuestionId()}.answer_possibilities[0]`}
                                                render={(arrayHelpers) => (
                                                    <div>
                                                        {getIn(fProps.values, `${getQuestionId()}.answer_possibilities`)?.[0]?.map((answP, index) => (
                                                            <FileCard key={"answer_possibilities[0]" + index}>
                                                                <Label htmlFor={`${getQuestionId()}.answer_possibilities[0][${index}].answer`}>
                                                                    {t("exerciseForm.answerPossibility")}
                                                                </Label>
                                                                <Input
                                                                    type="text"
                                                                    id={`${getQuestionId()}.answer_possibilities[0][${index}].answer`}
                                                                    name={`${getQuestionId()}.answer_possibilities[0][${index}].answer`}
                                                                />
                                                                <div>
                                                                    <Trash onClick={() => arrayHelpers.remove(index)} />
                                                                </div>
                                                            </FileCard>
                                                        )
                                                        )}
                                                        {showError(fProps.touched, fProps.errors, `${getQuestionId()}.answer_possibilities[0]`, false)}
                                                        <SmallButton type="button" onClick={() => arrayHelpers.push("")}>
                                                            {t("exerciseForm.addPossibleAnswer")}
                                                        </SmallButton>
                                                    </div>
                                                )}
                                            />
                                        </>
                                    )}
                                    </MultiLineGroup>
                                    <br />
                                    <Label htmlFor={`${getQuestionId()}.correct_answer`}>{t("exerciseForm.askAnswer")}</Label>
                                    <hr />
                                    <MultiLineGroup>
                                    <Input
                                        id={`${getQuestionId()}.correct_answer`}
                                        type="text"
                                        name={`${getQuestionId()}.correct_answer`}
                                        value={getIn(fProps.values, `${getQuestionId()}.correct_answer`) || ""}
                                        placeholder={t("exerciseForm.answerInstructions")}
                                    />
                                    {showError(fProps.touched, fProps.errors, `${getQuestionId()}.correct_answer`, false)}

                                    <Switch
                                        name={`${getQuestionId()}.is_capital_sensitive`} 
                                        label={t("exerciseForm.isCapitalSensitive")} />
                                    {showError(fProps.touched, fProps.errors, `${getQuestionId()}.is_capital_sensitive`)}

                                    <Switch
                                        name={`${getQuestionId()}.is_punctuation_sensitive`} 
                                        label={t("exerciseForm.isPunctuationSensitive")} />
                                    {showError(fProps.touched, fProps.errors, `${getQuestionId()}.is_punctuation_sensitive`)}
                                    </MultiLineGroup>
                                </FormGroup>
                            ) : (
                                    <>
                                        <FormGroup>
                                            <Label htmlFor={`${getQuestionId()}.question`}>{t("exerciseForm.askQuestion")}</Label>
                                            <Input
                                                type="text"
                                                id={`${getQuestionId()}.question`}
                                                name={`${getQuestionId()}.question`}
                                                value={getIn(fProps.values, `${getQuestionId()}.question`) || ""}
                                                placeholder={t("exerciseForm.questionInstructions")}
                                            />
                                            {showError(fProps.touched, fProps.errors, `${getQuestionId()}.question`, false)}
                                        </FormGroup>
                                        <FormGroup>
                                            <Label htmlFor={`${getQuestionId()}.answer_possibility`}>{t("exerciseForm.answerType")}</Label>
                                            <Input
                                                component="select"
                                                id={`${getQuestionId()}.answer_possibility`}
                                                name={`${getQuestionId()}.answer_possibility`}
                                            >
                                                {qaTypes.map((qaType) => {
                                                    return qaType;
                                                })}
                                            </Input>
                                            {showError(fProps.touched, fProps.errors, `${getQuestionId()}.answer_possibility`, false)}
                                        </FormGroup>
                                        {getIn(fProps.values, `${getQuestionId()}.answer_possibility`) !== "open antwoord" && (
                                            <>
                                                <FormGroup>
                                                    <FieldArray
                                                        name={`${getQuestionId()}.fields[0]`}
                                                        id={`${getQuestionId()}.fields[0]`}
                                                        render={(arrayHelpers) => (
                                                            <div>
                                                                {getIn(fProps.values, `${getQuestionId()}.fields`)?.[0]?.map((fieldname, index) => (
                                                                    <FileCard key={`${getQuestionId()}.fields[0][${index}].name`}>
                                                                        <Label htmlFor={`${getQuestionId()}.fields[0][${index}].name`}>
                                                                            {t("exerciseForm.fieldName")}
                                                                        </Label>
                                                                        <Input
                                                                            type="text"
                                                                            id={`${getQuestionId()}.fields[0][${index}].name`}
                                                                            name={`${getQuestionId()}.fields[0][${index}].name`}
                                                                            value={getIn(fProps.values, `${getQuestionId()}.fields[0][${index}].name`) || ""}
                                                                        />
                                                                        <div>
                                                                            <Trash onClick={() => arrayHelpers.remove(index)} />
                                                                        </div>
                                                                    </FileCard>
                                                                ))}
                                                                {showError(fProps.touched, fProps.errors, `${getQuestionId()}.answer_possibility`, false)}
                                                                <SmallButton type="button" onClick={() => arrayHelpers.push("")}>
                                                                    {t("exerciseForm.addFieldName")}
                                                                </SmallButton>
                                                            </div>
                                                        )}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label htmlFor={`${getQuestionId()}.fields`}>{t("exerciseForm.fieldvalues")}</Label>
                                                    <FieldArray
                                                        id={`${getQuestionId()}.fields`}
                                                        name={`${getQuestionId()}.fields`}
                                                        render={(arrayHelpers) => (
                                                            <div>
                                                                {getIn(fProps.values, `${getQuestionId()}.fields`)?.[0]?.map((fieldvalue, index) => (
                                                                    <FileCard key={`${getQuestionId()}.fields[0][${index}].value`}>
                                                                        <Label htmlFor={`${getQuestionId()}.fields[0][${index}].value`}>
                                                                            {t("exerciseForm.fieldValue")}
                                                                        </Label>
                                                                        <Input
                                                                            type="number"
                                                                            id={`${getQuestionId()}.fields[0][${index}].value`}
                                                                            name={`${getQuestionId()}.fields[0][${index}].value`}
                                                                            value={getIn(fProps.values, `${getQuestionId()}.fields[0][${index}].value`) || 0}
                                                                        />
                                                                        <div>
                                                                            <Trash onClick={() => arrayHelpers.remove(index)} />
                                                                        </div>
                                                                    </FileCard>
                                                                ))}
                                                                {showError(fProps.touched, fProps.errors, `${getQuestionId()}.fields`, false)}
                                                            </div>
                                                        )}
                                                    />
                                                </FormGroup>
                                            </>
                                        )}
                                    </>
                                )}
                            <SmallButton type="button" onClick={handleHideSecondOverlay}>{t("exerciseForm.addQuestion." + props.exercise.type)}</SmallButton>
                        </OverlayCard>
                    </Overlay>
                )}
                 {/* GENERAL FOOTER FORM SECTION */}
                 <FormGroup>
                    <Label><CogIcon />{t("exerciseForm.options")}</Label>
                    <hr />
                    <MultiLineGroup>
                        {(props.exercise.type === "dagboek" || props.exercise.type === "stappenplanValidatie") && (
                            <>
                                {props.exercise.type === "stappenplanValidatie" && (
                                    <>
                                        <Label htmlFor="options.reward_system">{t("exerciseForm.rewardSystem")}</Label>
                                        <Input id="reward_system" component="select" name="reward_system">
                                            {rewardSystems.map((rewardSystem) => {
                                                return rewardSystem;
                                            })}
                                        </Input>
                                        {showError(fProps.touched, fProps.errors, `reward_system`)}
                                    </>
                                )}
                                <Switch
                                    name="random_within_type" 
                                    label={t("exerciseForm.randomWithinType")} />
                                <Switch
                                    name="random_all" 
                                    label={t("exerciseForm.randomAll")} />
                            </>
                        )}
                        <Switch
                            name="should_be_recorded" 
                            label={t("exerciseForm.shouldBeRecorded")} />
                        <Switch
                            checked={fProps.values.should_be_metronome}
                            name="should_be_metronome" 
                            label={t("exerciseForm.shouldBeMetronome")} />
                        <Switch 
                            checked={fProps.values.should_be_chrono}
                            name="should_be_chrono" 
                            label={t("exerciseForm.shouldBeChrono")} />
                        <TagSelect currentTags={tags} setTags={setTags} />
                    </MultiLineGroup>
                </FormGroup>
                <FormGroup>
                    <Label><BullHornIcon />{t("exerciseForm.private")}</Label>
                    <hr />
                    <MultiLineGroup>
                        <Switch
                            name="is_private" 
                            label={t("exerciseForm.privateCheck")} />
                    </MultiLineGroup>
                </FormGroup>

                {showMediaOverlay &&
                    <MediaOverlay 
                        skipUpload={true}
                        setSelection={SetMediaInput} close={() => setShowMediaOverlay(!showMediaOverlay)} />
                }

                <Button type="submit" disabled={fProps.isValid ? null : "disabled"}>
                    {t("OefeningAanvragen.submit")}
                </Button>
                
                {redirect && <Redirect to="/t/CreateMaterial"/>}
                {/* To quickly debug the form uncomment the line underneath */}
                {/* <DisplayFormikState { ...fProps } /> */}
            </ExcerciseForm>
        );
    };

    return (
    <>
        {loading ? <LoadSpinner /> :
            <Formik
                innerRef={formikRef}
                validationSchema={formSchema}
                validateOnMount={true}
                initialValues={props.exercise}
                children={formLayout}
                onSubmit={handleSubmit}
            />
        }
    </>
    )

});

export default ExerciseEditForm