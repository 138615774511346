import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { api } from "../../../api";
import { FaTimes } from "react-icons/fa";

import LoadSpinner from "../../../components/loadspinner";
import Button from "../../../components/styled/button";
import Card from "../../../components/styled/card";
import { SmallTitle, P } from "../../../components/styled/typography";

const DiscountCard = styled(Card)`
    display: flex;
    flex-direction: column;
    align-items: space-between;
    margin: 10px 0px;
    padding-top: 10px;
    padding-bottom: 10px;

    @media (min-width: 768px) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
`;

const Flex = styled.div`
    display: flex;
    align-items: center;
    margin: 5px 0px;
`;

const CodeTag = styled.h2`
    margin: 0px;
    padding: 0px;
    color: #51c0cf;
    font-weight: 600;
    font-size: 20px;
`;

const NormalButton = styled(Button)`
    font-size: 16px;
`;

const Code = styled.div`
    display: flex;
    flex-direction: column;

    @media (min-width: 768px) {
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }
`;

const CodeInfo = styled.div`
    display: flex;
    flex-direction: column;
`;

const DiscountText = styled(P)`
    margin: 0px;
    margin-left: 5px;
`;

const PercentageText = styled(P)`
    margin: 0px;
    font-size: 20px;
    border: 1px solid #51c0cf;
    border-radius: 5px;
    padding: 5px 15px;
`;

const SmallText = styled(P)`
    font-size: 15px;
    margin: 0px;
    text-transform: italic;
`;

const RedButton = styled(NormalButton)`
    background-color: #dc393d;
    border-color: #dc393d;
    margin-left: 10px;

    &:hover {
        background-color: #dc393d;
        border-color: #dc393d;
    }

    @media (min-width: 768px) {
        margin-left: 20px;
    }
`;

const CreateButton = styled(Button)`
    margin-top: 20px;
`;

const Title = styled(SmallTitle)`
    margin-top: 10px;
    margin-right: 40px;
`;

const Label = styled.label`
    margin-top: 10px;
`;

const Input = styled.input`
    background: #ffffff;
    border: 1px solid #51c0cf;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(197, 197, 197, 0.25);
    border-radius: 10px;
    padding: 10px 10px;
    outline: none;
    font-size: 16px;
    margin: 10px 0px;
    width: 100%;
`;

const Select = styled.select`
    background: #ffffff;
    border: 1px solid #51c0cf;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(197, 197, 197, 0.25);
    border-radius: 10px;
    padding: 10px 10px;
    outline: none;
    font-size: 16px;
    margin: 10px 0px;
    width: 100%;
`;

const Overlay = styled.div`
    background-color: rgba(60, 60, 60, 0.7);
    position: fixed;
    top: 0px;
    left: 0;
    width: 100vw;
    height: 100%;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: 980px) {
        top: 0;
        height: 100vh;
        width: 100vw;
    }
`;

const CreateDiscountOverlay = styled(Card)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    box-sizing: border-box;
    padding: 30px;
    position: absolute;
    z-index: 999999;

    @media (min-width: 980px) {
        position: relative;
        top: 0;
        z-index: 9;
    }
`;

const Times = styled(FaTimes)`
    color: #545454;
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
`;

const FlexButtons = styled.div`
    display: flex;
`;

const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
`;

const Active = styled.div`
    margin: 10px 0px;
`;

const Discounts = () => {
    const [discounts, setDiscounts] = useState();
    const [allPlans, setAllPlans] = useState();
    const [loadingDiscounts, SetLoadingDiscounts] = useState(true);
    const [showOverlay, setShowOverlay] = useState(false);
    const [discount, setDiscount] = useState();
    const [code, setCode] = useState();
    const [plan, setPlan] = useState();
    const [MonthsReduction, setMonthsReduction] = useState();

    useEffect(() => {
        api.get("/discounts").then((res) => {
            setDiscounts(res.data.output);
            SetLoadingDiscounts(false);
        });
        api.get("/plans", { params: { onlyPublic: true } }).then((res) => {
            setAllPlans(res.data.plans);
        });
    }, []);

    const refresh = () => {
        SetLoadingDiscounts(true);
        api.get("/discounts").then((res) => {
            setDiscounts(res.data.output);
            SetLoadingDiscounts(false);
        });
    };

    const HandleDisable = (id, status) => {
        api.patch("/discount/" + id, { active: status ? false : true }).then((res) => {
            refresh();
        });
    };

    const HandleDelete = (id) => {
        api.delete("/discount/" + id).then((res) => {
            refresh();
        });
    };

    const CreateDiscount = () => {
        api.post("/discount", {
            code: code,
            discount: discount / 100,
            plan_id: plan,
            active: true,
            month_reduction: MonthsReduction,
        }).then((res) => {
            refresh();
        });
        setShowOverlay(false);
    };

    const ConvertDateFormat = (date) => {
        const convertedDate = new Date(date);
        return convertedDate.toLocaleDateString();
    };

    return (
        <div>
            {loadingDiscounts ? (
                <LoadSpinner />
            ) : (
                discounts.map((discount) => {
                    return (
                        <DiscountCard>
                            <CodeInfo>
                                <Code>
                                    <Flex>
                                        <CodeTag>Code: </CodeTag>
                                        <DiscountText>{discount.code}</DiscountText>
                                    </Flex>
                                </Code>
                                {discount.plan && (
                                    <Flex>
                                        <CodeTag>Plan:</CodeTag>
                                        <DiscountText>
                                            {discount.plan.naam} ({discount.plan.period} maanden)
                                        </DiscountText>
                                    </Flex>
                                )}
                                {discount.plan && (
                                    <Flex>
                                        <CodeTag>Werkelijke duur:</CodeTag>
                                        <DiscountText>
                                            {discount.plan.period - discount.month_reduction} maanden
                                        </DiscountText>
                                    </Flex>
                                )}
                                {discount.date && (
                                    <Flex>
                                        <CodeTag>Aangemaakt op:</CodeTag>
                                        <DiscountText>{ConvertDateFormat(discount.date)}</DiscountText>
                                    </Flex>
                                )}
                            </CodeInfo>
                            <FlexColumn>
                                <PercentageText>{discount.discount * 100}%</PercentageText>
                                <Active>{discount.active ? "Actief" : "Inactief"}</Active>
                                <Active>
                                    {discount.amount_used && "Aantal keer gebruikt: " + discount.amount_used}
                                </Active>
                            </FlexColumn>
                            <FlexButtons>
                                <NormalButton onClick={() => HandleDisable(discount.id, discount.active)}>
                                    {discount.active ? "Deactiveer" : "Activeer"}
                                </NormalButton>
                                <RedButton onClick={() => HandleDelete(discount.id)}>Verwijder</RedButton>
                            </FlexButtons>
                        </DiscountCard>
                    );
                })
            )}
            {showOverlay && (
                <Overlay>
                    <CreateDiscountOverlay>
                        <Times onClick={() => setShowOverlay(false)} />
                        <Title>Kortingscode aanmaken</Title>

                        <Label>Code:</Label>
                        <Input type="text" onChange={(e) => setCode(e.target.value)} />

                        <Label>Kortingspercentage (getal tussen 1 en 100):</Label>
                        <Input type="number" onChange={(e) => setDiscount(e.target.value)} />

                        <Label>Selecteer een plan (optioneel):</Label>
                        <Select component="select" name="plan" onChange={(e) => setPlan(e.target.value)}>
                            <option>Selecteer een plan</option>
                            {allPlans.map((plan) => (
                                <option value={plan.id}>
                                    {plan.naam} ({plan.period} maanden)
                                </option>
                            ))}
                        </Select>

                        <Label>Aantal maanden aftrek van plan:</Label>
                        <SmallText>Termijn plan - aftrek plan = aantal maanden dat plan actief is</SmallText>
                        <Input type="number" onChange={(e) => setMonthsReduction(e.target.value)} />

                        <Button onClick={CreateDiscount}>Maak aan</Button>
                    </CreateDiscountOverlay>
                </Overlay>
            )}

            <CreateButton onClick={() => setShowOverlay(true)}>Maak kortingscode aan</CreateButton>
        </div>
    );
};

export default Discounts;
