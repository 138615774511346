import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import RegistrationStore from "../registrationStore";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { H4, P } from "../styled/typography";

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const Question = styled(H4)`
    font-weight: 500;
    margin: 0px;
    margin-bottom: 15px;
`;

const Input = styled.input`
    background: #ffffff;
    border: 1px solid #51c0cf;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(197, 197, 197, 0.25);
    border-radius: 10px;
    padding: 10px 10px;
    margin-top: 5px;
    outline: none;
    font-size: 16px;
    margin-bottom: 15px;

    &.valid {
        border-color: #45ae7c;
    }

    &.invalid {
        border-color: #f02929;
    }
`;

const Label = styled.label`
    display: flex;
    margin-bottom: 10px;
`;

const Par = styled(P)`
    margin: 0px;
    padding-top: 2px;
    padding-left: 5px;
    display: inline;
    font-size: 16px;
    cursor: pointer;
`;

const Error = styled(P)`
    margin: 0px;
    margin-bottom: 15px;
    color: #f02929;
`;

const BlueLink = styled.a`
    color: #51c0cf;
`;

const AcceptStatements = observer(({ setRegisterStepIsValid, setRegisterStepIsInvalid }) => {
    const { t, i18n } = useTranslation();
    const [acceptedGeneralConditions, setAcceptedGeneralConditions] = useState(false);
    const [acceptedPrivacyStatement, setAcceptedPrivacyStatement] = useState(false);
    const [generalConditionsError, setGeneralConditionsError] = useState(false);
    const [privacyStatementError, setPrivacyStatementError] = useState(false);

    const managePrivacyErrorMessage = (status) => {
        if (status === true) {
            setPrivacyStatementError(false);
        } else {
            setPrivacyStatementError(true);
        }
    };

    const manageGeneralConsitionsErrorMessage = (status) => {
        if (status === true) {
            setGeneralConditionsError(false);
        } else {
            setGeneralConditionsError(true);
        }
    };

    const changeGeneralConditions = () => {
        manageGeneralConsitionsErrorMessage(!acceptedGeneralConditions);
        setAcceptedGeneralConditions(!acceptedGeneralConditions);
    };

    const changePrivacyStatement = () => {
        managePrivacyErrorMessage(!acceptedPrivacyStatement);
        setAcceptedPrivacyStatement(!acceptedPrivacyStatement);
    };

    useEffect(() => {
        console.log(acceptedGeneralConditions);
        console.log(acceptedPrivacyStatement);
        if (acceptedGeneralConditions && acceptedPrivacyStatement) {
            setRegisterStepIsValid();
        } else {
            setRegisterStepIsInvalid();
        }
    }, [acceptedGeneralConditions, acceptedPrivacyStatement]);

    return (
        <Wrapper>
            <Question black>{t("acceptStatements.title")}</Question>
            <Label>
                <Input
                    type="checkbox"
                    name="acceptedGeneralConditions"
                    value={acceptedGeneralConditions}
                    onChange={changeGeneralConditions}
                />
                <Par>
                    {t("acceptStatements.agreeGeneralConditions")}{" "}
                    <BlueLink href="https://uploads-ssl.webflow.com/5dc9be9d66fd1b176b91bed1/5e82ff28ee6a950d43d63b16_18245-V2%20gebruiksvoorwaarden%20EN.pdf">
                        {t("acceptStatements.GeneralConditions")}
                    </BlueLink>
                </Par>
            </Label>
            <Label>
                <Input
                    type="checkbox"
                    name="acceptedPrivacy"
                    value={acceptedPrivacyStatement}
                    onChange={changePrivacyStatement}
                />
                <Par>
                    {t("acceptStatements.agreePrivacyStatement")}{" "}
                    <BlueLink href="https://uploads-ssl.webflow.com/5dc9be9d66fd1b176b91bed1/5e9aca109ba599e1a6fa75be_2020%2004%2016%20Privacy%20Statement%20Eng.pdf">
                        {t("acceptStatements.PrivacyStatement")}
                    </BlueLink>
                </Par>
            </Label>
            {generalConditionsError && (
                <Error data-testid="error-terms-conditions">{t("acceptStatements.GeneralConditionsError")}</Error>
            )}
            {privacyStatementError && (
                <Error data-testid="error-privacy-statement">{t("acceptStatements.privacyStatementError")}</Error>
            )}
        </Wrapper>
    );
});

export default AcceptStatements;
