import React, { useContext } from "react";
import registrationStore from "../registrationStore";
import SidebarPatient from "../sidebarPatient";
import AccountMenu from "../accountMenu/accountMenu";

function PatientOverlay() {
    const store = useContext(registrationStore);
    const user = JSON.parse(store.user);
    return (
        <div>
            <SidebarPatient />
            <AccountMenu name={user.firstName + " " + user.lastName} role={user.therapist} />
        </div>
    );
}

export default PatientOverlay;
