import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import DisciplineStore from "../stores/disciplineStore";

export function Disciplines(props) {
    const { t, i18n } = useTranslation();
    const store = useContext(DisciplineStore);
    const disciplines = store.disciplines;

    return disciplines.map((discipline) => (
        <option key={discipline.name} value={discipline.name} defaultValue={props.default === discipline.name}>
            {t(`${discipline.value}`)}
        </option>
    ));
}

export function Subdisciplines(props) {
    const { t, i18n } = useTranslation();
    const store = useContext(DisciplineStore);
    const subdisciplines = store.getSubdisciplines(props.discipline);

    return subdisciplines?.map((subDiscipline) => (
        <option key={subDiscipline.name} value={subDiscipline.name}>
            {t(`${subDiscipline.value}`)}
        </option>
    ));
}
