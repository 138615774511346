import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import TherapistOverlay from "../../components/overlays/therapistOverlay";
import ResultsGraph from "../../components/resultsGraph/resultsGraph";
import Global from "../../components/styled/global";
import PageWrapper from "../../components/styled/pageWrapper";
import { PageTitle, SmallTitle, P } from "../../components/styled/typography";
import Card from "../../components/styled/card";
import PatientNavigation from "../../components/patientNavigation";
import registrationStore from "../../components/registrationStore";
import { observer } from "mobx-react";
import { api } from "../../api";
import ResultNavigation from "../../components/resultsGraph/resultNavigation";
import LoadSpinner from "../../components/loadspinner";
import { PageAnimation } from "../../components/styled/animation";
import Logboek from "../../components/logboek";
import PatientStore from "../../components/patientStore";
import { useTranslation } from "react-i18next";

const Title = styled(SmallTitle)`
    margin-bottom: 30px;
`;

const Flex = styled.div`
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
`;

const NoMobile = styled.div`
    display: none;

    @media (min-width: 980px) {
        display: block;
    }
`;

const UnAvailable = styled.div`
    display: block;

    @media (min-width: 980px) {
        display: none;
    }
`;

const PatientResultaat = observer((props) => {
    const { t, i18n } = useTranslation();
    const store = useContext(registrationStore);
    const patientStore = useContext(PatientStore);
    const user = JSON.parse(store.user);
    const { public_id } = props.match.params;
    const [loading, setLoading] = useState(true);
    const [patientLoading, setPatientLoading] = useState(true);
    const [GraphLoading, setGraphLoading] = useState(true);
    const [thisAssignment, setThisAssignment] = useState([]);
    const [results, setResults] = useState([]);
    const [activeGraph, setActiveGraph] = useState();

    useEffect(() => {
        if (patientStore.patient.public_id !== public_id) {
            api.get("/user/" + public_id).then((response) => {
                patientStore.setPatient(
                    response.data.user.firstName,
                    response.data.user.lastName,
                    response.data.user.public_id,
                    response.data.user.sex
                );
                setPatientLoading(false);
            });
        } else {
            setPatientLoading(false);
        }

        api.get("user/" + public_id + "/completedtasks").then((response) => {
            setThisAssignment(response.data.output);
            setLoading(false);
        });
    }, []);

    const handleClick = (e) => {
        const index = e.target.getAttribute("value");
        const assignment = thisAssignment.findIndex((p) => p.assignment_id == index);
        var elems = document.querySelectorAll(".isactive");
        [].forEach.call(elems, function (el) {
            el.classList.remove("isactive");
        });
        e.target.className += " isactive";
        setResults(thisAssignment[assignment]);
        setGraphLoading(false);
    };

    return (
        <Global>
            <PageWrapper>
                <TherapistOverlay />
                <PageAnimation>
                    {patientLoading ? (
                        <LoadSpinner />
                    ) : (
                        <PageTitle black>
                            {patientStore.patient.firstName + " " + patientStore.patient.lastName}
                        </PageTitle>
                    )}
                    <PatientNavigation public_id={public_id} />
                    <NoMobile>
                        <Title black>{t("patientResultaat.logbook")}</Title>
                        <Logboek id={public_id} />

                        <Title black>{t("patientResultaat.results")}</Title>
                        <Flex>
                            {loading ? (
                                <LoadSpinner />
                            ) : thisAssignment.length > 0 ? (
                                thisAssignment.map((result) => (
                                    <ResultNavigation result={result} onClick={handleClick} />
                                ))
                            ) : (
                                <div style={{ "margin-top": "20px" }}>{t("resultatenDetail.noAss")}</div>
                            )}

                        </Flex>
                        {loading ? (
                            ""
                        ) : GraphLoading ? (
                            ""
                        ) : (
                            <ResultsGraph myresults={results} activegraph={activeGraph} />
                        )}
                    </NoMobile>
                    <UnAvailable>
                        <div style={{ "margin-top": "20px" }}>{t("resultatenDetail.phone")}</div>
                    </UnAvailable>
                </PageAnimation>
            </PageWrapper>
        </Global>
    );
});

export default PatientResultaat;
