import { observable, action } from "mobx";
import { createContext } from "react";

class FileUploadStore {
    @observable files = [];
    @observable id = 0;
    @observable isUploading = false;

    @action setFiles(files) {
        this.files = files;
    }

    @action setId(id) {
        this.id = id;
    }

    @action setIsUploading(isUploading) {
        this.isUploading = isUploading;
    }
}

export const FileUploadStoreInstance = new FileUploadStore();

export default createContext(FileUploadStoreInstance);
