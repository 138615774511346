import React,{ useCallback, useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import { Redirect } from "react-router-dom";
import Button from '../styled/button';
import { api } from "../../api";
import { FileTarget } from "./fileTarget";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Switch } from "../styled/switch";
import FileSaver from "file-saver";
import Card from "../styled/card";
import LoadSpinner from '../loadspinner';
import TagSelect from "../helperComponents/tagSelect";
import { Languages } from '../resources/languages';
import { uploadOneFile } from '../../azure';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const FileFormStyle = styled(Form)`    
`

const FormGroup = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
`

const Input = styled(Field)`
    background: #FFFFFF;
    border: 1px solid #51C0CF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(197, 197, 197, 0.25);
    border-radius: 10px;
    padding: 10px 10px;
    margin-top: 5px;
    outline: none;
    font-size: 16px;

    &:disabled{
        //background: #EEEEEE;
        //border: 1px solid #B0C1C4;
        opacity: 0.5;
    }
`

const Label = styled.label`
    font-size: 16px;
    font-weight: 600;
    color: #545454;
`

const SubmitButton = styled(Button)`
    margin-top: 20px;
    padding-right: 45px;
    padding-left: 45px;
    font-size: 18px;
`
const FlexLeft = styled.div`
    margin-top: 10px;
    display: flex;
`

const Error = styled.p`
    font-size: 16px;
    color: #AFAFAF;
    font-style: italic;
`

const SmallButton = styled(Button)`
  margin-top: 5px;
  width: fit-content;
`;

const Center = styled.div`
    text-align: center;
`

const ChoseFile = styled(Input)`
    display: none;
`;

const FileCard = styled(Card)`
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const DeleteButton = styled(Button)`
    background-color: #dc393d;
    border-color: #dc393d;
    margin-left: 5px;

    &:hover {
        background-color: #dc393d;
        border-color: #dc393d;
    }

    &:clicked {
        background-color: #dc393d;
        border-color: #dc393d;
    }
`;

const Img = styled.img`
    width: 80%;

    @media (min-width: 980px) {
        width: 40%;
    }
`;

const Video = styled.video`
    width: 80%;
    margin-bottom: 10px;
`;

const Audio = styled.audio`
    max-width: 80%;
    margin-top: 20px;
    margin-bottom: 20px;
`;

const FileForm = observer((props) => {
    const { t, i18n } = useTranslation();
    const [tags, setTags] = useState([]);
    const [loading, setLoading] = useState(false);
    const [fileAsDataTransfer, setFileAsDataTransfer] = useState([]);
    const [fileAsArray, setFileAsArray] = useState([]);
    const [filePreview, setFilePreview] = useState('');
    const [redirect, setRedirect] = useState(false);
    const [type, setType] = useState("");
    const [previewUrl, setPreviewUrl] = useState("");
    const [fileName, setFileName] = useState("");
    const [description, setDescription] = useState((props.hasOwnProperty('document') ? props.document.description : "<p></p><p></p><p></p><p></p><p></p>"))

    useEffect(() => {
        // Make sure all selected files are visible
        const filesArray = dataTransferToArrayConverter(fileAsDataTransfer);
        if (filesArray.length > 0) {
            setFileAsArray(filesArray);
            const previewUrl = URL.createObjectURL(fileAsDataTransfer[0]);
            const fileName = filesArray[0].name;
            setPreviewUrl(previewUrl);
            setFileName(fileName);
            getFilePreview(fileName, filesArray[0].type.split('/')[0], previewUrl);
        }
    }, [fileAsDataTransfer]);

    const downloadOneFile = async (file_url, file_name) => {
        FileSaver.saveAs(file_url, file_name);
    }

    const getFilePreview = (fileName, fileType, previewUrl) =>{
        setType(fileType);
        switch (fileType) {
            case "image":
                setFilePreview(<Img src={previewUrl}/>);
                break;
            case "video":
                setFilePreview(<Video controls controlsList="nodownload"><source src={previewUrl} type="video/mp4; base64"/></Video>);
                break;
            case "audio":
                setFilePreview(<Audio controls><source src={previewUrl} type="audio/wav; base64"/></Audio>);
                break;
            default:
                setFilePreview(null);
                break;
        }
    }

    useEffect(() => {
        // Make sure all selected files are visible
        const filesArray = dataTransferToArrayConverter(fileAsDataTransfer);
        setFileAsArray(filesArray);
    }, [fileAsDataTransfer]);

    const dataTransferToArrayConverter = (dataTransfer) => {
        let filesArray = [];
        for (let i = 0; i < dataTransfer.length; i++) {
            const file = dataTransfer.item(i);
            filesArray.push(file);
        }
        return filesArray;
    };

    const handleFileDrop = useCallback((item, monitor) => {
        if (monitor) {
            const item = monitor.getItem();
            const files = item.files;
            changeSelectedFile(files[0]);
        }
    }, []);

    const changeSelectedFile = (newFile) => {
        const fileData = new DataTransfer();

        const thisItem = newFile;
        fileData.items.add(thisItem);

        // Set initial upload progress to 0
        let progressObject = {};
        progressObject[newFile.name] = 0;

        setFileAsDataTransfer(fileData.files);
    };

    const postFile = async (values, actions) => {
        setLoading(true);
        const file = fileAsDataTransfer[0]
        uploadOneFile(file).then((_) => {
            //Add type as tag
            // let tempTags = tags;
            // if (!tempTags.includes(type)) tempTags.push(type);

            api.post('/file', {
                "therapist_id": props.user.public_id,
                "therapist_name" : props.user.firstName + " " + props.user.lastName,
                "tags": tags,
                "name": values.fileName,
                "resource_name": file.name,
                "file_url": "",
                "language": values.language,
                "type": type,
                "description": description,
                "published": values.published
            }).then((res) => {
                // sendNotificationEmail(values);
                actions.setSubmitting(false);
                setLoading(false);
                props.onSubmit ? props.onSubmit() : setRedirect(true);
            })
        })
    }

    const sendNotificationEmail = (values) => {
        var message = `Dag Mieke,

Er werd zojuist nieuwe media upgeload genaamd: ${values.fileName}.
Dit is de soort media: ${type}.
De gevraagde tags zijn: ${tags}.

Groeten,
Het TD team`;
        api.post("/mail", {
            body: "request",
            sender: "info@telephon.digital",
            to: "mieke.moerman@telephon.digital",
            subject: "Upload nieuwe media",
            message: message
        });
    };

    return (<div>
        {loading ? <LoadSpinner /> :
        <Formik initialValues={{ description : "", published: true, language: ""}} 
                onSubmit={postFile}
                render={({errors,touched, values, isSubmitting, isValid}) => (
                    <FileFormStyle>
                        <FormGroup>
                            <Label htmlFor="fileName">{t('fileForm.name')}</Label>
                            <Input type="text" id="fileName" name="fileName" placeholder={t('fileForm.inputName')} />
                            { touched.fileName && errors.fileName && <Error>{errors.fileName}</Error> }
                        </FormGroup>
                        <FormGroup>
                            <Label>{t('fileForm.description')}</Label>
                            <CKEditor
                                id="description"
                                name="description"
                                editor={ ClassicEditor }
                                data={description}
                                onChange={ ( event, editor ) => {
                                    const description = editor.getData();
                                    console.log( { event, editor });
                                    setDescription(description);
                                }}
                                config={{
                                    placeholder: t('fileForm.inputDescription'),
                                    toolbar: ['heading', '|', 'bold', 'italic', 'blockQuote', 'link', 'numberedList', 'bulletedList', 'insertTable', 'tableColumn', 'tableRow', 'mergeTableCells',  '|', 'undo', 'redo', '|', 'code'],
                                    cloudServices: {tokenUrl : process.env.REACT_APP_COBRAND_API + "/editor_token/" + props.user.public_id, uploadUrl : "https://55482.cke-cs.com/easyimage/upload/"}
                                }}
                                onError={ ( { willEditorRestart } ) => {
                                    // If the editor is restarted, the toolbar element will be created once again.
                                    // The `onReady` callback will be called again and the new toolbar will be added.
                                    // This is why you need to remove the older toolbar.
                                    if ( willEditorRestart ) {
                                        this.editor.ui.view.toolbar.element.remove();
                                    }
                                } }
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="tagInput">{t('fileForm.tags')}</Label>
                            <TagSelect setTags={setTags} />
                        </FormGroup>
                        <FormGroup>
                            <Label>{t('documentEditForm.language')}</Label>
                            <Input component="select" name="language">
                                <option value="">...</option>
                                <Languages default={i18n.language} />
                            </Input>
                            { touched.language && errors.language && <Error>{errors.language}</Error> }
                        </FormGroup>
                        <FormGroup>
                            <Label>{t('fileForm.preview')}</Label>
                            {filePreview ?? <>
                                <span>{t("fileForm.noPreview")}</span>
                                <SmallButton type="button" small onClick={() => downloadOneFile(previewUrl, fileName)}>
                                    {t("mediaDetail.download")}
                                </SmallButton>
                            </>}
                        </FormGroup>
                        <FormGroup>
                            <FileTarget onDrop={handleFileDrop} target="file-upload"/>
                            <ChoseFile id="file-upload" type="file" name="file" onChange={(e) => changeSelectedFile(e.target.files[0])} />
                        </FormGroup>
                        <Switch name="published" label={t("fileForm.publish")} id="published"/>
                        { touched.published && errors.published && <Error>{errors.published}</Error> }
                        <Center>
                            <SubmitButton
                                type='submit'
                                disabled={(fileAsArray.length > 0 && isValid && !isSubmitting)  ? null : 'disabled'}
                            >
                                {t('fileForm.configure')}
                            </SubmitButton>
                        </Center>
                        {redirect && <Redirect to="/t/CreateMaterial"/>}
                    </FileFormStyle>
                )}
                validationSchema={Yup.object().shape({
                    fileName: Yup.string().required(),
                    language: Yup.string().required(),
                    description: Yup.string().notRequired(),
                    published: Yup.string().required()
                })}
        
        />}
        </div>)
})

export default FileForm