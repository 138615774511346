import styled from 'styled-components'

const Global = styled.div`
    @import url('https://fonts.googleapis.com/css?family=Raleway:300,400,600,700,800&display=swap');
    margin: 0;
    font-family: 'Raleway', sans-serif;
    background-color: #F8F8F8;
    padding-left: 0px;
    min-height: 100vh;
    margin-bottom: 15px;


    @media (min-width: 980px){
        padding-left: 175px;
        margin-bottom: auto;
    }
`

export default Global