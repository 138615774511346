import React, { useState, useContext } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Disciplines, Subdisciplines} from "../resources/disciplines";
import { Languages } from "../resources/languages";
import SearchBar from "../search";
import Button from "../styled/button";
import registrationStore from "../registrationStore";
import { FaFilter } from "react-icons/fa";
import { Form, Field, Formik } from "formik";
import TagSelect from "../helperComponents/tagSelect";
import LanguageStore from "../stores/languageStore";
import useLocalStorage from "../hooks/localStorage";

const FilterForm = styled(Form)`
    margin-left: 0px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-top: 15px;

    @media (min-width: 1200px) {
        align-items: flex-start;
    }
`;

const FilterButton = styled(Button)`
    font-size: 18px;
    margin: 0px 0px 5px 0px;
    padding: 0px 44px 0px 44px;
    height: 50px;    
`;

const FilterBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-top: 15px;
    margin-left: 0px;
  
    @media (min-width: 980px) {
        margin-left: 15px;
        padding-bottom: 10px;
        flex-direction: row;
        align-items: center;
    }
`;

const Cols = styled.span`
  display: flex;
  flex-direction: column;
  vertical-align: middle; !important;
  
  @media (min-width: 980px) {
    flex-direction: row;
    align-items: center;
  }
`;

const Input = styled(Field)`
    background: #ffffff;
    border: 1px solid #51c0cf;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(197, 197, 197, 0.25);
    border-radius: 10px;
    padding: 14px 14px;
    outline: none;
    font-size: 16px;

    @media (min-width: 980px) {
        margin-bottom: 5px;
        margin-right: 12px;
    }
`;

const Icon = styled(FaFilter)`
    color: white;
    vertical-align: middle;
    margin-right: 7px;
`;

function Filter(props) {
    const { t, i18n } = useTranslation();
    const store = useContext(registrationStore);
    const user = JSON.parse(store.user);
    const [chosenText, setChosenText] = useLocalStorage(props.tag + 'FilterDataTitle', "");
    const langStore = useContext(LanguageStore);
    const language = langStore.getLanguage(i18n.language);

    const setInitialValues = () => {
        if (props.filters) {
            return props.filters
        } else {
            return { language: language, discipline: user?.speciality, subdiscipline: "", tags: [] };
        }
    }


    return (
        <Formik 
            initialValues={setInitialValues()}
            onSubmit={(values) => {
                const data = {
                    language: values.language,
                    discipline: values.discipline,
                    subdiscipline: values.subdiscipline,
                    title: chosenText,
                    tags: values.tags
                };
                props.filterCallback(data);
            }}
            render={({values, handleSubmit}) => (
                <FilterForm>
                    <Cols>
                        <SearchBar id="search" currentSearch={chosenText} handleSearch={(e) => setChosenText(e.target.value)} />

                        <FilterBox>
                            {props.useTags && <TagSelect currentTags={values.tags} setTags={(tags) => values.tags = tags} />}
                            <Input component="select" name="language">
                                <option value="">{t("languages.all")}</option>
                                <Languages default={i18n.language} />
                            </Input>
                            {!props.hideDiscipline && (<>
                                <Input component="select" name="discipline">
                                    <option value="">{t("documentForm.allDis")}</option>
                                    <Disciplines default={user.speciality} />
                                </Input>
                                <Input component="select" name="subdiscipline">
                                    <option value="">{t("documentForm.allSub")}</option>
                                    <Subdisciplines discipline={values.discipline} />
                                </Input>
                            </>)}

                            <FilterButton onClick={handleSubmit}>
                                <Icon />
                                {t("selectionOverlay.filter")}
                            </FilterButton>
                        </FilterBox>
                    </Cols>
                </FilterForm>
            )}
        />
        
    );
}

export default Filter;
