import React, { useContext, Suspense, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import login from "./pages/login";
import login2 from "./pages/login2";
import checkout from "./pages/checkout";
import dashboard from "./pages/patient/dashboard";
import Inbox from "./pages/patient/inbox";
import materiaalDetail from "./pages/patient/materiaalDetail";
import materiaalRaadplegen from "./pages/patient/materiaalRaadplegen";
import mijnAccount from "./pages/patient/mijnAccount";
import mijnPlan from "./pages/patient/mijnPlan";
import opdrachten from "./pages/patient/opdrachten";
import resultatenDetail from "./pages/patient/resultatenDetail";
import taakDetail from "./pages/patient/taakDetail";
import ResultForm from "./pages/patient/resultForm";
import Therapeuten from "./pages/patient/therapeuten";
import DashboardT from "./pages/therapeut/dashboard";
import DocumentAanmaken from "./pages/therapeut/documentAanmaken";
import DocumentAanpassen from "./pages/therapeut/documentAanpassen";
import FileAanmaken from "./pages/therapeut/fileAanmaken";
import FileAanpassen from "./pages/therapeut/fileAanpassen";
import inboxT from "./pages/therapeut/inbox";
import LogboekDetail from "./pages/therapeut/logboekDetail";
import MateriaalAanmaken from "./pages/therapeut/materiaalAanmaken";
import MateriaalDetailT from "./pages/therapeut/materiaalDetail";
import MateriaalRaadplegenT from "./pages/therapeut/materiaalRaadplegen";
import MijnAccount from "./pages/therapeut/mijnAccount";
import MijnPlan from "./pages/therapeut/mijnPlan";
import OpdrachtAanmaken from "./pages/therapeut/opdrachtAanmaken";
import opdrachtAanpassen from "./pages/therapeut/opdrachtAanpassen";
import patientDetail from "./pages/therapeut/patientDetail";
import patientResultaat from "./pages/therapeut/patientResultaat";
import patientTaak from "./pages/therapeut/patientTaak";
import Therapie from "./pages/therapeut/therapie";
import HelpdeskT from "./pages/therapeut/helpdesk";
import HelpdeskP from "./pages/patient/helpdesk";
import taakDetailT from "./pages/therapeut/taakDetail";
import { observer } from "mobx-react-lite";
import RegistrationStore from "./components/registrationStore";
import VideoCallPage from "./communication-module/components/containers/videoCallPage";
import ChatPage from "./communication-module/components/containers/chatPage";
import ActiveCallNotification from "./communication-module/components/containers/activeCallNotification";
import Initialize from "./communication-module/components/containers/initialize";
import resetPassword from "./pages/resetPassword";
import { H1, P } from "./components/styled/typography";
import ErrorHandler from "./components/errorHandler";
import oefeningAanvragen from "./pages/therapeut/oefeningAanvragen";
import Admin from "./pages/admin/admin";
import "./i18n";
import BackgroundUpload from "./components/backgroundUpload";
import Register from "./pages/registration/register";
import OnboardingFlow from "./components/onboarding/onboardingFlow";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import MediaDetail from "./pages/therapeut/mediaDetail";
import LanguageStore from "./components/stores/languageStore";
import DisciplineStore from "./components/stores/disciplineStore";
import { api } from "./api";
import ChatNotification from "./communication-module/components/containers/chatNotification";
import MediaDetailP from "./pages/patient/mediaDetail";
import EditExercise from "./pages/therapeut/editExercise";
import V2Frame from "./pages/v2";
import { reactPlugin, isRootAppInsightsEnabled } from "./insights";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";

const fadein = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

const Wrapper = styled.div`
  animation: ${fadein} 1s;
`;

const Title = styled(H1)`
  text-align: center;
  margin: 0 20px;
  font-size: 25px;
`;

const SmallText = styled(P)`
  text-align: center;
  margin: 20px 20px;
`;

const Overlay = styled.div`
  display: none;

  /*@media (max-width: 980px){
        z-index: 999;
        background-color: white;
        position: fixed;
        top: 0;
        bottom: 0px;
        height: 100vh;
        width: 100vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center; 
    }*/
`;

const TherapistRoute = ({ component: Component, ...rest }) => {
    const store = useContext(RegistrationStore);
    store.checkAccountIsExpired();
    var user;
    store.user === null ? (user = {}) : (user = JSON.parse(store.user));
    return (
        <Route
            {...rest}
            render={(props) =>
                user.therapist ? (
                    <Component {...props} />
                ) : user.therapist === false ? (
                    <Redirect to="/p/dashboard" />
                ) : (
                            <Redirect to="/login" />
                        )
            }
        />
    );
};

const PatientRoute = ({ component: Component, ...rest }) => {
    const store = useContext(RegistrationStore);
    store.checkAccountIsExpired();
    var user;
    store.user === null ? (user = {}) : (user = JSON.parse(store.user));
    return (
        <Route
            {...rest}
            render={(props) =>
                user.therapist === false ? (
                    <Component {...props} />
                ) : user.therapist === true ? (
                    <Redirect to="/t/dashboard" />
                ) : (
                            <Redirect to="/login" />
                        )
            }
        />
    );
};

const AdminRoute = ({ component: Component, ...rest }) => {
    const store = useContext(RegistrationStore);
    var user;
    store.user === null ? (user = {}) : (user = JSON.parse(store.user));
    return (
        <Route
            {...rest}
            render={(props) => user.admin ? (
                        <Component {...props} />
                    ) : (
                        <Redirect to="/login" />
                    )
            }
        />
    );
};

const getLanguages = (store) => {
    // Session caching: will trigger reload when refreshed
    // TODO: Make this use local cache?
    if(!store.initialized) {
        api.get('/languages').then((response) => {
            store.setLanguages(response.data);
            store.setInitialized(true);
        })
    }
}

const getDisciplines = (store) => {
    // Session caching: will trigger reload when refreshed
    // TODO: Make this use local cache?
    if(!store.initialized) {
        api.get('/disciplines').then((response) => {
            store.setDisciplines(response.data);
            store.setInitialized(true);
        })
    }
}

const App = observer(() => {
  const registrationStore = useContext(RegistrationStore);
  const languageStore = useContext(LanguageStore);
  const disciplineStore = useContext(DisciplineStore);

  const isAppInsightsEnabeld = isRootAppInsightsEnabled(true);

  //Initialize the language and discipline stores
  useEffect(() => {
    getLanguages(languageStore);
    getDisciplines(disciplineStore);
  }, [registrationStore.token]);

  const root = (childeren) => (
    <DndProvider backend={HTML5Backend}>
      <Wrapper>
        <Suspense fallback={null}>
          <ErrorHandler>{childeren}</ErrorHandler>
        </Suspense>
      </Wrapper>
    </DndProvider>
  );

  const appInsightsContextProvidor = (reactPlugin, childeren) => (
    <AppInsightsContext.Provider value={reactPlugin}>{childeren}</AppInsightsContext.Provider>
  );

  const childeren = (
    <>
      <Router>
        {registrationStore.user && <Initialize userId={JSON.parse(registrationStore.user).public_id} />}
        {registrationStore.user && <ActiveCallNotification />}
        {registrationStore.user && <ChatNotification />}
        <Switch>
          <Route path="/" exact render={() => <Redirect to="/login" />} />
          <Route path="/login" exact component={login} />
          <Route path="/login2" exact component={login2} />
          <Route path="/checkout" exact component={checkout} />
          <Route path="/reset_password/:token" exact component={resetPassword} />
          <Route path="/video-chat" component={VideoCallPage} />
          <Route path="/chat" component={ChatPage} />

          <Route path="/v2" component={V2Frame} />

          {/* NEW REGISTRATION ROUTES */}
          <Route path="/register" exact component={Register} />

          <AdminRoute path="/admin" component={Admin} />
          <AdminRoute path="/consultMaterial/Detail/:id" component={taakDetailT} />

          <PatientRoute path="/p/dashboard" exact component={dashboard} />
          <PatientRoute path="/p/inbox" exact component={Inbox} />
          <PatientRoute path="/p/consultMaterial/Detail/:id" component={materiaalDetail} />
          <PatientRoute path="/p/consultMaterial" exact component={materiaalRaadplegen} />
          <PatientRoute path="/p/MyAccount/Details" component={mijnAccount} />
          <PatientRoute path="/p/MyAccount/MyPlan" component={mijnPlan} />
          <PatientRoute path="/p/MyAccount/MyTherapists" exact component={Therapeuten} />
          <PatientRoute path="/p/Assignments" exact component={opdrachten} />
          <PatientRoute path="/p/ResultsDetail" exact component={resultatenDetail} />
          <PatientRoute path="/p/Assignments/Detail/:id" component={taakDetail} />
          <PatientRoute path="/p/Assignments/results" exact component={ResultForm} />
          <PatientRoute path="/p/helpdesk" exact component={HelpdeskP} />
          <PatientRoute path="/p/consultMaterial/mediaDetail/:id" exact component={MediaDetailP} />

          <TherapistRoute path="/t/Dashboard" exact component={DashboardT} />
          <TherapistRoute path="/t/CreateMaterial/CreateDocument" component={DocumentAanmaken} />
          <TherapistRoute path="/t/CreateMaterial/EditDocument/:id" component={DocumentAanpassen} />
          <TherapistRoute path="/t/CreateMaterial/CreateFile" component={FileAanmaken} />
          <TherapistRoute path="/t/CreateMaterial/EditFile/:id" component={FileAanpassen} />
          <TherapistRoute path="/t/CreateMaterial/EditExercise/:id" component={EditExercise} />
          <TherapistRoute path="/t/inbox" exact component={inboxT} />
          <TherapistRoute path="/t/consultMaterial/Detail/:id" component={MateriaalDetailT} />
          <TherapistRoute path="/t/consultMaterial/taskDetail/:id" component={taakDetailT} />
          <TherapistRoute path="/t/consultMaterial" exact component={MateriaalRaadplegenT} />
          <TherapistRoute path="/t/consultMaterial/mediaDetail/:id" component={MediaDetail} />
          <TherapistRoute path="/t/MyAccount/Details" component={MijnAccount} />
          <TherapistRoute path="/t/MyAccount/MyPlan" component={MijnPlan} />
          <TherapistRoute path="/t/CreateMaterial" exact component={MateriaalAanmaken} />
          <TherapistRoute path="/t/CreateMaterial/CreateTemplate" component={OpdrachtAanmaken} />
          <TherapistRoute path="/t/CreateMaterial/EditTemplate/:id" component={opdrachtAanpassen} />
          <TherapistRoute path="/t/CreateMaterial/requestExercise" component={oefeningAanvragen} />
          <TherapistRoute path="/t/therapy/PatientDetail/:public_id" component={patientDetail} />
          <TherapistRoute path="/t/therapy/PatientResults/:public_id" exact component={patientResultaat} />
          <TherapistRoute path="/t/therapy/PatientResults/LogDetail/:id" component={LogboekDetail} />
          <TherapistRoute path="/t/therapy/PatientTask/:public_id" component={patientTaak} />
          <TherapistRoute path="/t/therapy" exact component={Therapie} />
          <TherapistRoute path="/t/helpdesk" exact component={HelpdeskT} />
        </Switch>
        {registrationStore.user && JSON.parse(registrationStore.user).therapist && <OnboardingFlow />}
      </Router>
      <BackgroundUpload />
      <Overlay>
        <Title>Deze applicatie is momenteel nog niet beschikbaar op kleine schermen.</Title>
      </Overlay>
    </>
  );

  if (isAppInsightsEnabeld && isAppInsightsEnabeld === true) {
    return root(appInsightsContextProvidor(reactPlugin, childeren));
  } else {
    return root(childeren);
  }
});

export default App;
