import React, {useEffect, useContext, useState} from "react"
import styled from "styled-components"
import {api} from "../api"
import registrationStore from "./registrationStore"
import AreYouSure from '../components/areYouSure'
import Card from '../components/styled/card'
import Button from '../components/styled/button'
import { H4 } from '../components/styled/typography'
import TherapistIcon from '../assets/images/therapist-icon.png'
import TherapistIconMan from '../assets/images/therapist-icon-man.png'
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom";

const Icon = styled.img`
    height: 30px;
    width: 30px;
    margin-right: 15px;
`

const TherapistCard = styled(Card)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 50%;
    margin: 10px 0px;
`

const RedButton = styled(Button)`
    background-color: #DC393D;
    border-color: #DC393D;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    margin: 3px 0px;

    &:hover {
        background-color: #b02a2e;
        border-color: #b02a2e;
    }
`

const BlueButton = styled(Button)`
    background-color: #4d8b94;
    border-color: #4d8b94;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    font-size: 16px;
    width: 100%;

    &:hover {
        background-color: #41757d;
        border-color: #41757d;
    }
`;

const NameDiv = styled.div`
    display: flex;
    align-items: center;
`

const Name = styled(H4)`
    margin: 0px;
    font-size: 20px !important;
    font-weight: semi-bold;
`

const Flex = styled.div `
`

const CardLink = styled(Link)`
    text-decoration: none;
`;

const MyTherapists = () => {
    const { t, i18n } = useTranslation()
    const store = useContext(registrationStore)
    const user = JSON.parse(store.user)
    const [therapists, setTherapists] = useState([])
    const [Overlay, setOverlay] = useState()

    useEffect(() => {
        api.get('/therapists/' + user.public_id).then(res => {
            setTherapists(res.data.output)
        })
    }, [])

    const update = () => {
        api.get('/therapists/' + user.public_id).then(res => {
            setTherapists(res.data.output)
        })
        setOverlay()
    }

    const HandleOverlay = (public_id) => {
        setOverlay(public_id)
    }

    const HandleDelete = (therapist_id) => {
        api.post('/disconnectPatient/' + user.public_id, {therapist_id: therapist_id})
    }

    return(
        <Flex>
            {therapists.map(therapist => {
                return <div>
                    <AreYouSure display={Overlay === therapist.public_id} update={update} HandleDelete={() => HandleDelete(therapist.public_id)}/>
                    <TherapistCard>
                        <NameDiv>
                            {therapist.sex == 'Man' ? <Icon src={TherapistIconMan} /> : <Icon src={TherapistIcon} />}
                            <Name>{therapist.firstName + " " + therapist.lastName}</Name>
                        </NameDiv>
                        <CardLink
                            to={{
                                pathname: `/chat`,
                                state: {
                                    messages: [],
                                    receiverId: therapist.public_id,
                                    initiatorId: user.public_id,
                                    role: "initiator",
                                },
                            }}
                        >
                            <BlueButton small>
                                {/*<MessageIcon />*/}
                                {t("therapists.chat")}
                            </BlueButton>
                        </CardLink>
                        <RedButton onClick={() => HandleOverlay(therapist.public_id)}>{t('mytherapists.delete')}</RedButton>
                    </TherapistCard>
                </div>
            })}
            
        </Flex>
    )
}

export default MyTherapists