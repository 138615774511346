import React, { useContext, useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { api } from "../../api";
import registrationStore from "../registrationStore";
import trajectStore from "./trajectStore";
import Button from "../styled/button";

const TitleField = styled.input`
    background: #ffffff;
    border: 1px solid #51c0cf;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(197, 197, 197, 0.25);
    border-radius: 10px;
    padding: 10px 10px;
    margin-top: 5px;
    outline: none;
    width: 100%;
`;

const CreateButton = styled(Button)`
    margin-top: 15px;
`;

const CreateTask= observer((props) => {
    const { t, i18n } = useTranslation();
    const regstore = useContext(registrationStore);
    const user = JSON.parse(regstore.user);
    const store = useContext(trajectStore);
    const [error, setError] = useState("");

    const HandleSubmit = () => {

        if (document.getElementById("title").value === "") {
            setError(t("patientopdrachtaanmaken.errTitel"));
        } else {
            var task;
            api.post("/traject", {
                therapist_id: user.public_id,
                user_id: props.public_id,
                title: document.getElementById("title").value,
                })
                .then((response) => {
                    props.close();
                    props.update();
                    store.clearArray();
                    // POST new traject message via api
                    // then
                    // SEND mail to user using api
                });
        }
    };

    const handleTitleInputChange = (e) => {
        store.setTitleInput(e.target.value);
    };

    return (
        <div>
            <TitleField type="text" id="title" onChange={handleTitleInputChange} value={store.titleInput} placeholder="Titel van het traject..." />
            <p>{error}</p>
            <CreateButton onClick={HandleSubmit}>{t('createTraject.createTraject')}</CreateButton>
        </div>
    )
})

export default CreateTask;