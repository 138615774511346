import React, { useState, useEffect, useContext } from "react";
import PatientOverlay from "../../components/overlays/patientOverlay";
import Global from "../../components/styled/global";
import { PageTitle, SmallTitle } from "../../components/styled/typography";
import PageWrapper from "../../components/styled/pageWrapper";
import { api } from "../../api";
import AssignmentCard from "../../components/assignmentCard";
import TodayTask from "../../components/todayTask";
import LoadSpinner from "../../components/loadspinner";
import { PageAnimation } from "../../components/styled/animation";
import registrationStore from "../../components/registrationStore";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import Flash from "../../components/styled/flashMessage";

const Opdrachten = observer((props) => {
    const { t, i18n } = useTranslation();
    const store = useContext(registrationStore);
    const user = JSON.parse(store.user);
    const [Loading, setLoading] = useState(true);
    const [assigns, setAssigns] = useState([]);
    const [assignments, setAssignments] = useState([]);
    const [individuFinished, setIndividuFinished] = useState(false);

    useEffect(() => {
        api.get("/tasksOfToday/" + user.public_id).then((response) => {
            if (response.data.individu_finished) {
                setIndividuFinished(true);
            }
            setAssigns(response.data.tasks);
            setLoading(false);
        });
        api.get("/assignments/" + user.public_id).then(function (response) {
            setAssignments(response.data.assignments.reverse());
            setLoading(false);
        });
    }, []);
    
    return (
        <Global>
            <PageWrapper>
                <PatientOverlay />
                <PageAnimation>
                    <PageTitle black>{t("patientCard.assignments")}</PageTitle>
                    {individuFinished && <Flash green>{t("dashboard.individuFinished")}</Flash>}
                    <SmallTitle black>{t("dashboard.tasksToday")}</SmallTitle>
                    {Loading ? (
                        <LoadSpinner />
                    ) : assigns.length > 0 ? (
                        assigns.map((assignment) => {
                            return assignment.taken.map((task, index) => (
                                <TodayTask key={index} title={assignment.title} data={task} patient={user}/>
                            ));
                        })
                    ) : (
                        <div style={{ "margin-top": "20px" }}>{t("dashboard.noTasks")}</div>
                    )}
                    <SmallTitle black>{t("opdrachten.running")}</SmallTitle>
                    {Loading ? (
                        <LoadSpinner />
                    ) : assignments.length > 0 ? (
                        assignments.map((assignment) => {
                            return <AssignmentCard key={assignment.id} assignment={assignment} />;
                        })
                    ) : (
                        <div style={{ "margin-top": "20px" }}>{t("opdrachten.noRunning")}</div>
                    )}
                </PageAnimation>
            </PageWrapper>
        </Global>
    );
});

export default Opdrachten;
