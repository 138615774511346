import React, {useContext, useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import styled from 'styled-components';
import api from '../../api';
import Article from "../../../components/article";

const Document = observer(({id}) => {
    const [document, setDocument] = useState({});

    useEffect(() => {
        api.getTelephonApi().get("/document/" + id).then((response) => {
            setDocument(response.data.document);
        });
    }, [id]);

    return <Article title={document.title} content={document.content} />;
});

export default Document;