import { observable, action } from "mobx"
import { createContext } from "react";

class ResultStore{
    @observable result = "";
    @observable date ="";
    @observable recording;
    @observable isRecording = false;

    @action setResult(newResult){
        this.result = newResult
    }

    @action setRecording(newRecording){
        this.recording = newRecording
    }

    @action clearRecording(){
        this.recording = undefined
    }
    
    @action clearResult(){
        this.result = "";
    }

    @action setDate(newDate){
        this.date = newDate
    }

    @action setIsRecording() {
        this.isRecording = ! this.isRecording;
    }
}


export const ResultStoreInstance = new ResultStore()

export default createContext(ResultStoreInstance)