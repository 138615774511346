import React, {useState, useEffect} from 'react';
import styled, {css} from 'styled-components';
import moment from 'moment';
import PropTypes from 'prop-types';
import Icon from './icon';
import {faClock} from '@fortawesome/free-solid-svg-icons';
import {compose, position} from 'styled-system';
import Text from './text';
import useInterval from '../hooks/useInterval';

const StopWatchContainer = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    transition: all ease-out 0.3s;
    opacity: 0;
    transform: translateY(150%);
    z-index: 2;
    ${compose(position)};
    width: 100px;
    border-radius: 50px;
    background: ${props => props.theme.colors.primary};
    padding: 10px;
  
    ${props => props.show && css`
        opacity: 1;
        transform: translateY(0%);
    `};
`;

const StopWatch = ({show}) => {
    const [seconds, setSeconds] = useState(0);

    const formatTime = () => moment().hour(0).minute(0).second(seconds).format('HH:mm:ss');

    useInterval(() => {
        setSeconds(seconds + 1);
    }, 1000);

    return (
        <StopWatchContainer show={show}>
            <Icon mr={2} fontSize={3} color='white' icon={faClock}/>
            <Text fontSize={3} color='white'>{formatTime()}</Text>
        </StopWatchContainer>
    )
};

StopWatch.propTypes = {
    show: PropTypes.bool
};

export default StopWatch;
