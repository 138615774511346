import styled from 'styled-components'
import icon from '../../assets/images/check.png'
import iconWhite from '../../assets/images/check-white.png'
import iconSmall from '../../assets/images/check-small.png'
import iconWhiteSmall from '../../assets/images/check-white-small.png'


export const Li = styled.li`
    list-style: none;
    background-image: url(${props => props.white && props.small ? iconWhiteSmall : props.white ? iconWhite : props.small ? iconSmall : icon});
    background-repeat: no-repeat;
    line-height: ${props => props.oneline ? "40px" : "auto"};
    padding-left: ${props => props.small ? "30px" : "60px" };
    padding-bottom: 20px;
    font-weight: 600;
    color: ${props => props.white ? "#FFFFFF" : "#929292"};
`

export const Ul = styled.ul`
    margin-left: -30px;
`