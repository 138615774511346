import React, {useContext} from "react"
import styled from "styled-components"

import { P } from '../components/styled/typography'
import Button from '../components/styled/button'
import { FaTimes } from "react-icons/fa"

import OpdrachtStore from "../components/opdrachtStore"
import { useTranslation } from "react-i18next"



const Wrapper = styled.div`
    width: 100%;
    border: 0.5px solid #AEADAD;
    background-color: #FFFFFF;
    box-sizing: border-box;
    padding: 15px;
    box-shadow: 0px 4px 4px rgba(197, 197, 197, 0.25);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 5px 0px;

    @media (min-width: 980px){
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
`

const Bold = styled(P)`
    font-weight: 600;
    margin: 0px;
    margin-bottom: 2px;
    margin-top: 2px;
    margin-right: 5px;
`

const Text = styled(P)`
    margin: 0px;
    margin-bottom: 2px;
    margin-top: 2px;

    white-space: -moz-pre-wrap !important;  /* Mozilla, since 1999 */
    white-space: -webkit-pre-wrap;          /* Chrome & Safari */ 
    white-space: -pre-wrap;                 /* Opera 4-6 */
    white-space: -o-pre-wrap;               /* Opera 7 */
    white-space: pre-wrap;                  /* CSS3 */
    word-wrap: break-word;                  /* Internet Explorer 5.5+ */
    word-break: break-all;
    white-space: normal;
`

const Flex = styled.div`
    display: flex;
    flex-direction: column;

    @media(min-width: 980px){
        flex-direction: row;
    }
`

const DeleteButton = styled(Button)`
    background-color: #DC393D;
    border-color: #DC393D;
    display: flex;
    align-items: center;
    margin-top: 5px;

    &:hover {
        background-color: #b02a2e;
        border-color: #b02a2e;
    }

    @media (min-width: 980px){
        margin-top: 0px
    }
`

const Icon = styled(FaTimes)`
    color: #FFFFFF;
    margin-right: 5px;
    font-size: 14px;
`

function TaskInAssignment(props){
    const { t, i18n } = useTranslation()
    const store = useContext(OpdrachtStore)
    function removeItem(event){
        store.taskArray.forEach((element) => {
            if (event.target.value == element.task){
                const index = store.taskArray.indexOf(element)
                store.removeFromArray(index, 1)
            }
        })

    }

    return(
        <Wrapper>
            <div>
                <Flex>
                    <Bold>{props.tasktype}:</Bold><Text>{props.task}</Text>
                </Flex>
                <Text>{t('checkoutStep.total')}: {props.total}</Text>
            </div>
            <DeleteButton onClick={removeItem} small value={props.id}><Icon />{t('myDocument.delete')}</DeleteButton>
        </Wrapper>
        
    )
}

export default TaskInAssignment