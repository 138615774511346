import React from 'react';
import styled from "styled-components"
import {H1} from "../components/styled/typography"
import Button from "../components/styled/button"
import { useTranslation } from "react-i18next"


const Flex = styled.div`
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const BackButton = styled(Button)`
    margin-top: 20px;
`


const Error = () => {
  const { t, i18n } = useTranslation()

  const handleClick = () => {
      window.history.back()
  }
  return (
    <Flex>
        <H1>{t('error.err1')}</H1>
        <BackButton type="button" onClick={handleClick} >{t('error.err2')}</BackButton>
    </Flex>
  )
}

export default Error;