import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import registrationStore from "../../components/registrationStore";
import TherapistOverlay from "../../components/overlays/therapistOverlay";
import Global from "../../components/styled/global";
import { PageAnimation } from "../../components/styled/animation";
import PageWrapper from "../../components/styled/pageWrapper";
import { PageTitle, P, SmallTitle } from "../../components/styled/typography";
import OpdrachtStore from "../../components/opdrachtStore";
import { api } from "../../api";
import { Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import TemplateForm from "../../components/templateForm";
import LoadSpinner from "../../components/loadspinner";
import { ExCard } from "../../components/styled/card";
import Button from "../../components/styled/button";


const A = styled(NavLink)`
    color: #51c0cf;
    text-decoration: none;
    padding: 0px 10px 0px 0px;
    margin-bottom: 10px;
    &.active {
        text-decoration: underline;
        color: #4e7693;
    }
`;

const FlexLinks = styled.div`
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    @media (min-width: 980px) {
        flex-direction: row;
    }
`;

const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
`;

const Bold = styled(P)`
    margin: 0px;
    font-weight: 600;
    color: #a5a5af;
`;

const Card = styled(ExCard)`
    width: 100%;
`;



const OpdrachtAanmaken = observer((props) => {
    const regstore = useContext(registrationStore);
    const user = JSON.parse(regstore.user);
    const store = useContext(OpdrachtStore);
    const [redirect, setRedirect] = useState(false);
    const { t, i18n } = useTranslation();
    const [newTemplate, setNewTemplate] = useState(true);
    const [templates, setTemplates] = useState([]);
    const [loading, setLoading] = useState(false);
    const [chosenOne, setChosenOne] = useState("");
    const [editExisting, setEditExisting] = useState(false);


    useEffect(() => {
        if (loading) {
            api.get("/assignmentTemplates/" + user.public_id).then(function (response) {
                setTemplates(response.data.assignmentTemplates);
                setLoading(false);
            });
        }
    }, [loading])

    useEffect(() => {
        if (store.isFinishedCreating) {
            setRedirect(true);
            store.setIsFinishedCreating(false);
        }

    }, [store.isFinishedCreating])


    const handleNav = (page) => {
        setNewTemplate(page);
        if (! page) {
            setLoading(true);
        }
    }

    const handleChange = (event, id) => {
        setChosenOne(id);

        var elems = document.querySelectorAll("#card");
        elems.forEach.call(elems, function (el) {
            el.classList.remove("active");
        });

        if (event.target.id === "text") {
            event.target.closest("div").className += " active";
        } else {
            event.target.className += " active";
        }
    };

    const handleSubmit = () => {
        setEditExisting(true);
    }

    const goBack = () => {
        setEditExisting(false);
    }

    return (
        <Global>
            <PageWrapper>
                <TherapistOverlay />
                <PageAnimation>
                    <PageTitle black>{t("materiaalAanmaken.assignment")}</PageTitle>
                    <P>{t("materiaalAanmaken.subtitle")}</P>
                    <div>
                        <FlexLinks>
                            <A className={newTemplate ? "active" : ""} onClick={() => handleNav(true)}>
                                {t("patientopdrachtaanmaken.newTemplate")}
                            </A>
                            <A className={newTemplate ? "" : "active"} onClick={() => handleNav(false)}>
                                {t("patientopdrachtaanmaken.existingTemplate")}
                            </A>
                        </FlexLinks>
                        { newTemplate ? (
                            <TemplateForm></TemplateForm> 
                        ) : (
                            <>
                                {loading ? (
                                    <LoadSpinner/>
                                ) : (
                                    <>
                                        {! editExisting && templates && (
                                            templates.length > 0 ? (
                                                <>
                                                    <FlexColumn>
                                                        {templates.map((template) => {
                                                            return (
                                                                <Card id="card" onClick={(event) => handleChange(event, template.id)}>
                                                                    <Bold id="text" onClick={(event) => handleChange(event, template.id)}>
                                                                        {template.title}
                                                                    </Bold>
                                                                </Card>
                                                            )
                                                        })}
                                                    </FlexColumn>
                                                    <Button onClick={handleSubmit}>{t("patientExistingAssignment.createCopy")}</Button>
                                                </>
                                            ) : (
                                                <div>{t("patientExistingAssignment.noTemp")}</div>
                                            )
                                        )}
                                        {editExisting && (
                                            <>
                                                <Button onClick={goBack}>{t("patientExistingAssignment.selectOtherTemplate")}</Button>
                                                <TemplateForm id={chosenOne} copy={true}></TemplateForm>
                                            </>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </div>
                    {redirect && <Redirect to="/t/CreateMaterial" />}
                </PageAnimation>
            </PageWrapper>
        </Global>
    );
});

export default OpdrachtAanmaken;
