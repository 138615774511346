import React, { useContext } from "react";
import styled from "styled-components";
import TherapistOverlay from "../../components/overlays/therapistOverlay";
import Global from "../../components/styled/global";
import { PageTitle, SmallTitle, P } from "../../components/styled/typography";
import PageWrapper from "../../components/styled/pageWrapper";
import { observer } from "mobx-react";
import { PageAnimation } from "../../components/styled/animation";
import registrationStore from "../../components/registrationStore";
import { useTranslation } from "react-i18next";
import Material from "../../components/libraryComponents/material";
import Media from "../../components/libraryComponents/media";
import AdminList from "../../components/libraryComponents/adminList";

const SelectFlex = styled.div`
    display: flex;
    align-items: center;
`;

const MateriaalRaadplegen = observer(() => {
    const { t, i18n } = useTranslation();
    const store = useContext(registrationStore);
    const user = JSON.parse(store.user);

    return (
        <Global>
            <PageWrapper>
                <TherapistOverlay />
                <PageAnimation>
                    <PageTitle black>{t("materiaalRaadplegen.Title")}</PageTitle>
                    <SelectFlex>
                        <SmallTitle black>{t("materiaalRaadplegen.publ")}</SmallTitle>
                    </SelectFlex>
                    <Material tag={"matPublic"} public={true} onlyDocs={false} showType={true}/>
                    <SelectFlex>
                        <SmallTitle black>{t("materiaalRaadplegen.priv")}</SmallTitle>
                    </SelectFlex>
                    <Material tag={"matPrivate"} public={false} public_id={user.public_id} onlyDocs={false} showType={true}/>
                    <SelectFlex>
                        <SmallTitle black>{t("materiaalRaadplegen.media")}</SmallTitle>
                    </SelectFlex>
                    <Media tag={"med"} public_id={user.public_id} showType={true}/>
                    { user.admin && (
                        <>
                            <SelectFlex>
                                <SmallTitle black>{t("materiaalRaadplegen.admin.private")}</SmallTitle>
                            </SelectFlex>
                            <AdminList tag={"admin"} public={false}/>
                        </>
                    )}
                </PageAnimation>
            </PageWrapper>
        </Global>
    );
});

export default MateriaalRaadplegen;
