import React, {useContext, useEffect, useState, useRef} from 'react';
import styled from 'styled-components';
import {compose, space, flexbox} from 'styled-system';
import Text from '../text';
import {withRouter} from 'react-router-dom';
import BackButton from '../backButton';
import registrationStore from "../../../components/registrationStore";
import ChatStore from '../../stores/chat.store';
import chatService from '../../services/chatService';
import { observer } from "mobx-react";
import Button from '../../../components/styled/button';
import Card from '../../../components/styled/card';
import { useTranslation } from "react-i18next";
import Global from "../../../components/styled/global";
import PageWrapper from "../../../components/styled/pageWrapper";
import PatientOverlay from "../../../components/overlays/patientOverlay";
import TherapistOverlay from "../../../components/overlays/therapistOverlay";

const ChatPageContainer = styled.div`
    display: flex;
    flex-direction: column;
    ${compose(space)}
`;

const ChatBlock = styled.div`
    display: flex;
    overflow: hidden;
    ${compose(flexbox)}
`;

const BackButtonContainer = styled.div`
    ${compose(space)}
`;

const HeaderContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

const MessagesContainer = styled(Card)`
    width: 45%;
    height: 50vh;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    justify-content: left;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */

    ::-webkit-scrollbar { 
        display: none;  /* Safari and Chrome */
    }
`

const MessageForm = styled.form`
    width: 100%;
    display: flex;
    flex-Direction: row;
`

const Input = styled.input`
    background: #FFFFFF;
    border: 1px solid #51C0CF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(197, 197, 197, 0.25);
    border-radius: 10px;
    padding: 10px 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    outline: none;
    margin-right: 0px;
    min-width: 35%;
`

const SubmitButton = styled(Button)`
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    min-width: 10%;
`

const CardStyled = styled(Card)`
    width: 100%;
    margin-top: auto;
    background: #e4e6eb;
    border: 0.5px solid #e4e6eb;
`

const MessageWrapper = styled.div`
    width: 45%;
    margin-left: ${props => props.myMessage ? "auto" : "0"};
`

const ChatPage = observer(({location, history}) => {
    const { t, i18n } = useTranslation();
    const [message, SetMessage] = useState("");
    const [chat, SetChat] = useState(null);
    const [attendees, SetAttendees] = useState([]);
    const chatStore = useContext(ChatStore);
    const store = useContext(registrationStore);
    const user = JSON.parse(store.user);

    useEffect(() => {
        SetChat(chatStore.activeChat);
    }, [chatStore.activeChat]);

    // create chat if not exists
    useEffect(() => {
        var chatPromise = location.state.id ? chatService.getChat(location.state.id) : null;

        if (chatPromise !== null) {
            chatPromise.then(async({data}) => {
                chatStore.setActiveChat(data);
                SetAttendees(data.attendees);
            });
        } else {
            const body = {
                messages: [],
                finished: false,
                initiator: location.state.initiatorId,
                attendees: [location.state.initiatorId, location.state.receiverId],
                counts: 0
            };
            chatService.createChat(body)
                .then(async({data}) => {
                    chatStore.setActiveChat(data);
                    SetAttendees(data.attendees);
                })
                .catch(() => null);
        }
    }, []);

    // fetch user info of receiver and initiator
    const GetChatPartenerFullName = () => {
        if(attendees === undefined) return '';
        var fullName;
        attendees.forEach(attendee => {
            if (attendee.public_id !== user.public_id) fullName = `${attendee.firstName} ${attendee.lastName}`;
        });
        return fullName;
    }

    //send a new message
    const SendMessage = async (e) => {
        await chatService.sendMessage(chat?.id, message, user.public_id);
    }

    const AlwaysScrollToBottom = () => {
        const elementRef = useRef();
        useEffect(() => elementRef.current.scrollIntoView({behavior: "smooth"}));
        return <div ref={elementRef} />;
    };

    const HandleInputSubmit = (e) => {
        e.preventDefault();
        SendMessage();
        SetMessage("");
    };

    const ShowAttendeeFullName = (public_id) => {
        return attendees.filter(x => x.public_id === public_id).map(x => `${x.firstName} ${x.lastName}`);
    }

    const SetViewed = async () => {
        if (chat && chat.counts > 0) {
            const last = chat.messages[chat.messages.length - 1];
            if (last && last.instigatorId != user.public_id) {
                await chatService.setViewed(chat.id);
            }
        }
    }

    return (
        <Global>
        <PageWrapper>
            {user.therapist ? <TherapistOverlay /> : <PatientOverlay />}
            <ChatPageContainer p={5}>
                <HeaderContainer>
                    <BackButtonContainer onClick={() => history.goBack()} mb={3}>
                        <BackButton>{t("chat.back")} </BackButton>
                    </BackButtonContainer>
                </HeaderContainer>
                <Text fontSize={4}>{t("chat.conversationWith")} {GetChatPartenerFullName()}</Text>
                <ChatBlock flexDirection={'column'}>
                    <MessagesContainer>
                        {chat?.messages.map((message) => {
                            return (<MessageWrapper myMessage={message.instigatorId === user.public_id} >
                            {ShowAttendeeFullName(message.instigatorId)}
                            <CardStyled myMessage={message.instigatorId === user.public_id}>
                                {message.message}
                            </CardStyled>
                            {new Date(message.message_date).toLocaleString(i18n.language)}
                            </MessageWrapper>)
                        })}
                        <AlwaysScrollToBottom />
                    </MessagesContainer>
                    <MessageForm onSubmit={HandleInputSubmit}>
                        <Input
                            id="messageInput"
                            placeholder={t("chat.inputPlaceholder")}
                            value={message}
                            onChange={(e) => SetMessage(e.target.value)}
                            onFocus={() => SetViewed()}
                        />
                        <SubmitButton small type="button" onClick={SendMessage}>{t("chat.sendMessage")}</SubmitButton>
                    </MessageForm>
                </ChatBlock>
            </ChatPageContainer>
        </PageWrapper>
    </Global>
    );
});

export default withRouter(ChatPage);

