import React, { useContext, useState } from "react";
import styled from "styled-components";
import Card from "../components/styled/card";
import { P } from "../components/styled/typography";
import { Link } from "react-router-dom";
import Button from "../components/styled/button";
import ProgressBar from "../components/progressBar";
import AreYouSure from "../components/areYouSure";
import { api } from "../api";
import { FaTasks, FaBook, FaPhone } from "react-icons/fa";
import PatientIcon from "../assets/images/patient-icon.png";
import PatientIconWoman from "../assets/images/patient-icon-woman.png";
import BertieIcon from "../assets/images/bertie.png";
import {} from "../components/styled/animation";
import { observer } from "mobx-react";
import RegistrationStore from "./registrationStore";
import { useTranslation } from "react-i18next";
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { isAppInsigthsEnabled } from "../insights";
import { eventConstants, getInsightUserType } from "../insights-constants";
import { trackEvent } from "../insights-track";

const Wrapper = styled(Card)`
    padding: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 5px;

    @media (min-width: 980px) {
        width: 49.5%;
        margin-top: 15px;
    }
`;

const CardLink = styled(Link)`
    text-decoration: none;
`;

const Info = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    @media (min-width: 980px) {
        flex-direction: row;
        justify-content: space-between;
    }
`;

const CenterBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Icon = styled.img`
    height: 75px;
    width: 75px;
    margin-right: 5px;
    padding-left: 5px;

    @media (min-width: 980px) {
        height: 100px;
        width: 100px;
    }
`;

const Name = styled(P)`
    color: #545454;
    font-weight: 700;
    font-size: 18px;
    margin: 0px;
    padding-top: 10px;
`;

const Diagnose = styled(P)`
    margin: 0px;
`;

const A = styled(Link)`
    color: #51c0cf;
    text-decoration: none;
`;

const GreenButton = styled(Button)`
    background-color: #45ae7c;
    border-color: #45ae7c;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    width: 100%;

    &:hover {
        background-color: #3a9168;
        border-color: #3a9168;
    }
`;

const BlueButton = styled(Button)`
    background-color: #4d8b94;
    border-color: #4d8b94;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    font-size: 16px;
    width: 100%;

    &:hover {
        background-color: #41757d;
        border-color: #41757d;
    }
`;

const TaskIcon = styled(FaTasks)`
    color: #ffffff;
    margin-right: 10px;
    font-size: 16px;
`;

const ResultIcon = styled(FaBook)`
    color: #ffffff;
    margin-right: 10px;
    font-size: 16px;
`;

const PhoneIcon = styled(FaPhone)`
    color: #ffffff;
    margin-right: 10px;
    font-size: 16px;
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    @media (min-width: 980px) {
        margin: 0px;
    }
`;

const Flex = styled.div`
    display: flex;
`;

const ColumnTopBottom = styled(Column)`
    justify-content: space-between;
    align-items: flex-start;
    margin-left: 20px;
`;

const Assignments = styled.div`
    margin-top: 20px;
`;
const TaskTitle = styled(P)`
    color: #545454;
    margin: 0px;
    margin-bottom: 5px;
`;

const DeleteLink = styled.a`
    text-decoration: none;
    color: #dc393d;
    font-weight: bold;
    cursor: pointer;
    width: 15px;
`;

const PatientCard = observer((props) => {
    const { t, i18n } = useTranslation();
    const registrationStore = useContext(RegistrationStore);
    const user = JSON.parse(registrationStore.user);
    const [Overlay, setOverlay] = useState(false);

    const isAiEnabled = isAppInsigthsEnabled();
    const appInsights = useAppInsightsContext();
    const trackProps = {
        user: {
            type: getInsightUserType(user.therapist),
            public_id: user.public_id 
        } 
    }

    const HandleOverlay = () => {
        setOverlay(!Overlay);
    };

    const HandleDelete = async () => {
        api.post("/disconnectPatient/" + props.patient.public_id, { therapist_id: user.public_id });
    };

    return (
        <Wrapper>
            <DeleteLink onClick={HandleOverlay}>X</DeleteLink>
            <CardLink
                to={{
                    pathname: "therapy/PatientDetail/" + props.patient.public_id,
                }}
            >
                <Info>
                    <Flex>
                        <CenterBox>
                            <Icon
                                src={
                                    props.patient.sex === "Man"
                                        ? PatientIcon
                                        : props.patient.sex === "Bertie"
                                        ? BertieIcon
                                        : PatientIconWoman
                                }
                            />
                        </CenterBox>
                        <ColumnTopBottom>
                            <div>
                                <Name>{props.patient.firstName + " " + props.patient.lastName}</Name>
                                <Diagnose></Diagnose>
                            </div>
                            <div>
                                <A
                                    to={{
                                        pathname: "therapy/PatientDetail/" + props.patient.public_id,
                                    }}
                                    onClick={() => trackEvent(isAiEnabled, appInsights, eventConstants.PRESS.CLIENTS.DETAILS.PAGE, trackProps)}
                                >
                                    {t("patientCard.details")}
                                </A>
                            </div>
                        </ColumnTopBottom>
                    </Flex>
                    <Column>
                        <CardLink
                            to={{
                                pathname: "therapy/PatientTask/" + props.patient.public_id,
                            }}
                            onClick={() => trackEvent(isAiEnabled, appInsights, eventConstants.PRESS.CLIENTS.CARE_PATHS.PAGE, trackProps)}
                        >
                            <GreenButton small>
                                <TaskIcon />
                                {t("patientCard.trajects")}
                            </GreenButton>
                        </CardLink>
                        <CardLink
                            to={{
                                pathname: "therapy/patientResults/" + props.patient.public_id,
                            }}
                            onClick={() => trackEvent(isAiEnabled, appInsights, eventConstants.PRESS.CLIENTS.RESULTS.PAGE, trackProps)}
                        >
                            <BlueButton small>
                                <ResultIcon />
                                {t("patientCard.results")}
                            </BlueButton>
                        </CardLink>
                        <CardLink
                            to={{
                                pathname: `/video-chat`,
                                state: {
                                    receiverId: props.patient.public_id,
                                    initiatorId: JSON.parse(registrationStore.user).public_id,
                                    role: "initiator",
                                },
                            }}
                            onClick={() => trackEvent(isAiEnabled, appInsights, eventConstants.PRESS.CLIENTS.START_VIDEO_CALL, trackProps)}
                        >
                            <BlueButton small>
                                <PhoneIcon />
                                {t("patientCard.convo")}
                            </BlueButton>
                        </CardLink>
                        <CardLink
                            to={{
                                pathname: `/chat`,
                                state: {
                                    messages: [],
                                    receiverId: props.patient.public_id,
                                    initiatorId: JSON.parse(registrationStore.user).public_id,
                                    role: "initiator",
                                },
                            }}
                        >
                            <BlueButton small>
                                {/*<MessageIcon />*/}
                                {t("patientcard.chat")}
                            </BlueButton>
                        </CardLink>
                    </Column>
                </Info>
                <Assignments>
                    {props.patient.progress.map((progress, index) => (
                        <div>
                            <TaskTitle>{progress.title}</TaskTitle>
                            <ProgressBar key={index} number={index + 1} progress={progress.percentage} />
                        </div>
                    ))}
                </Assignments>
            </CardLink>
            <AreYouSure display={Overlay} update={props.update} HandleDelete={HandleDelete} />
        </Wrapper>
    );
});

export default PatientCard;
