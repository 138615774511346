import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import MyMedia from "../myMedia";
import registrationStore from "../registrationStore";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import Filter from "../filterComponents/filter";
import { api } from "../../api";
import { SmallTitle, P } from "../styled/typography";
import LoadSpinner from "../loadspinner";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import Pagination from "./pagination";
import LanguageStore from "../stores/languageStore";

const MyMediaWrapper = styled.div`
    display: flex;
    flex-direction: column;

    @media (min-width: 980px) {
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: stretch;
    }
`;

const PageNumber = styled(P)`
    margin: 0px 7px;
    display: inlne;
    cursor: pointer;
    outline: none;

    &.active {
        color: white;
        background-color: #51c0cf;
        border: 1px solid #51c0cf;
        padding: 2px 5px 4px 5px;
        border-radius: 30px;
    }

    @media (min-width: 980%) {
        margin: 0px 3px;
    }
`;

const SelectFlex = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
`;

const OwnMedia = observer((props) => {
    const { t, i18n } = useTranslation();
    const store = useContext(registrationStore);
    const user = JSON.parse(store.user);
    const [loading, setLoading] = useState(true);
    const [media, setMedia] = useState([]);
    const [pagination, setPagination] = useState({
        start: 1,
        end: 12,
    });
    const [totalPages, setTotalPages] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const langStore = useContext(LanguageStore);
    const [filters, setFilters] = useState({
        title: "",
        language: langStore.getLanguage(i18n.language),
        tags: [],
    });

    function getFilterResults(data) {
        setFilters(data);
        resetPagination();
    }

    function resetPagination() {
        setPagination({
            start: 1,
            end: 12,
        });
        setCurrentPage(1);
    }

    function getDocuments() {
        setLoading(true);
        const params = {
            name: filters.title,
            language: filters.language,
            tags: filters.tags,
            start: pagination.start,
            end: pagination.end,
        };
        api.get(user.admin ? "admin/files" : "/files/" + props.public_id, {
            params: params,
        }).then((response) => {
            setMedia(response.data.Files);
            setTotalPages(Math.ceil(response.data.total / 12));
            setLoading(false);
        });
    }

    useEffect(() => {
        getDocuments();
    }, [filters, pagination]);

    useEffect(() => {
        if (props.filterData !== undefined) {
            getFilterResults(props.filterData);
        }
    }, [props.filterData]);

    //update
    function update() {
        setLoading(true);
        setTimeout(() => {
            getDocuments();
        }, 2500);
    };

    //pagination
    function handlePageChange(dir) {
        setPagination({
            start: pagination.start + 12 * dir,
            end: pagination.end + 12 * dir,
        });
        setCurrentPage(currentPage + dir);
    }

    function handlePageForce(page) {
        setPagination({
            start: 12 * (page - 1) + 1,
            end: page * 12,
        });
        setCurrentPage(page);
    };

    return (<>
        <SelectFlex>
            <Filter useTags={true} hideDiscipline={true} filters={filters} filterCallback={(data) => getFilterResults(data)} />
        </SelectFlex>
        <MyMediaWrapper>
            {loading ? (
                <LoadSpinner />
            ) : media.length > 0 ? (
                media.map((mediaFile) => {
                    return (
                        <MyMedia
                            media={mediaFile}
                            store={registrationStore}
                            update={update}
                            flash={props.flash}
                        />
                    );
                })
            ) : (
                <div style={{ "margin-top": "10px" }}>{t("materiaalAanmaken.noMedia")}</div>
            )}

            {totalPages ? (
                <Pagination
                    nextFunction={() => handlePageChange(1)}
                    prevFunction={() => handlePageChange(-1)}
                    totalPages={totalPages}
                    currentPage={currentPage}
                    pageForce={handlePageForce}
                />
            ) : (
                <></>
            )}
        </MyMediaWrapper>
    </>)
});

export default OwnMedia;