import {createContext} from 'react';
import {action, observable} from 'mobx';
import api from '../api';
import userService from '../services/userService';
import chatService from '../services/chatService';

class ChatStore {
    @observable user = null;
    @observable initialized = false;
    @observable activeChat = null;
    @observable numberOfChats = 0;
    @observable showNotification = false;

    @action setActiveChat(chat, showNotification = false) {
        this.activeChat = chat;
        this.showNotification = showNotification;
    }

    @action setNumberOfChats(newNumber) {
        this.numberOfChats = newNumber;
    }

    @action initialize(userId) {
        api.createAPI();
        Promise.all([this.fetchAndSetUser(userId)]).then(() => this.initialized = true);
    }

    @action finish(chatId) {
        chatService.finishChat(chatId);
    }

    fetchAndSetUser(userId) {
        return userService.getUser(userId).then(({data}) => this.user = data);
    }
}

export const ChatStoreInstance = new ChatStore();

export default createContext(ChatStoreInstance);