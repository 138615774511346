import React, {useEffect, useState, useContext} from 'react';
import LoginForm from "../components/loginForm"
import { observer } from "mobx-react"
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import Header from '../components/header'
import { H2, P } from '../components/styled/typography'
import Card from '../components/styled/card'
import Global from '../components/styled/global'
import {api} from "../api"
import { useTranslation } from "react-i18next"
import qs from "qs"
import RegistrationStore from "../components/registrationStore"
import {Redirect} from 'react-router-dom'

const LoginCard = styled(Card)`
  width: 90%;
  margin: 5vh auto;
  padding: 40px 30px;

  @media (min-width: 768px) {
    width: 70%;
    margin: 10vh auto;
    padding: 40px 40px;
  }
`

const Title = styled(H2)`
  margin: 20px auto 0px auto;
  padding-bottom: 20px;
  @media (min-width: 768px){
    padding-bottom: 40px;
  }
`

const NoAccountCreated = styled(P)`

  @media (min-width: 768px) {
    padding: 0px 0px 0px 60px;
  }
`

const CreateAccount = styled(Link)`
  color: #51C0CF;
`

const Login = observer((props) => {
        const { t, i18n } = useTranslation()
        const Rstore = useContext(RegistrationStore)
        const user = (Rstore.user && JSON.parse(Rstore.user))
        const [redirect, setRedirect] = useState(false)
        const language = qs.parse(props.location.search, {ignoreQueryPrefix: true}).language
        const isMaintenance = process.env.REACT_APP_MAINTENANCE;

        useEffect(()=>{
            if (language){
                i18n.changeLanguage(language)
            }
            if(Rstore.user){
                setRedirect(true)
            }
        },[])

        return (
            <div>
                { redirect && (user.therapist ? <Redirect to='/t/dashboard' /> : <Redirect to='/p/dashboard' />) }
                <Header />
                <LoginCard>
                    { (isMaintenance === 'True') ?
                        (<>
                            <Title center black>We are in maintenance</Title>
                            Sorry we are in maintenance
                        </>)
                        :
                        (<>
                            <Title center black>{t('login.title')}</Title>
                            <LoginForm/>
                            <NoAccountCreated>{t('login.noAccountCreated')} <CreateAccount
                                to="/register">{t('login.register')}</CreateAccount></NoAccountCreated>
                        </>)
                    }
                </LoginCard>
            </div>
        )
    }
)

export default Login;
