import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";

import AccountMenuDropdown from "../../components/accountMenu/accountMenuDropdown";
import { api } from "../../api";
import { FaChevronDown } from "react-icons/fa";
import { P } from "../../components/styled/typography";
import { observer } from "mobx-react";
import TherapistIcon from "../../assets/images/therapist-icon.png";
import TherapistIconMan from "../../assets/images/therapist-icon-man.png";
import PatientIcon from "../../assets/images/patient-icon.png";
import PatientIconWoman from "../../assets/images/patient-icon-woman.png";
import registrationStore from "../registrationStore";
import notificationStore from "../notificationStore";
import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
    display: none;

    @media (min-width: 980px) {
        display: block;
        position: relative;
    }
`;

const ActiveUser = styled.div`
    position: absolute;
    top: 20px;
    right: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
`;

const UserIcon = styled.div`
    position: relative;
    margin-right: 15px;
`;

const Icon = styled.img`
    height: 30px;
    width: 30px;
`;

const Chevron = styled(FaChevronDown)`
    color: #51c0cf;
    font-size: 24px;
    padding-left: 10px;
`;

const Notification = styled.div`
    display: inline-block;
    height: 10px;
    width: 10px;
    line-height: 1;
    background-color: #f23539;
    border: 1px solid #f23539;
    border-radius: 100%;
    font-size: 10px;
    text-align: center;
    vertical-align: middle;
    color: #ffffff;
    position: absolute;
    top: 20px;
    left: 20px;
`;

const User = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`;

const Name = styled(P)`
    color: #51c0cf;
    font-weight: 600;
    padding-left: 10px;
    margin-top: 0px;
    margin-bottom: 0px;
`;

const Role = styled(P)`
    margin-top: 0px !important;
    margin-bottom: 0px !important;
`;

const AccountMenu = observer((props) => {
    const { t, i18n } = useTranslation();

    const [open, setOpen] = useState(false);
    const store = useContext(registrationStore);
    const user = JSON.parse(store.user);
    const notificationstore = useContext(notificationStore);
    const [notifications, setNotifications] = useState(0);
    const handleClick = () => {
        setOpen(!open);
    };

    useEffect(() => {
        notificationstore.setNotifications(user.public_id);
    }, []);

    return (
        <Wrapper>
            <ActiveUser onClick={handleClick}>
                <UserIcon>
                    {notificationstore.notifications.length > 0 && (
                        <Notification>{notificationstore.notifications.length}</Notification>
                    )}
                    <Icon
                        src={
                            props.role
                                ? user.sex == "Man"
                                    ? TherapistIconMan
                                    : TherapistIcon
                                : user.sex == "Man"
                                ? PatientIcon
                                : PatientIconWoman
                        }
                    ></Icon>
                </UserIcon>
                <User>
                    <Name>{props.name}</Name>
                    <Role>
                        {props.role ? (
                            <span>{t("accountMenu.therapist")}</span>
                        ) : (
                            <span>{t("accountMenu.patient")}</span>
                        )}
                    </Role>
                </User>
                <Chevron />
            </ActiveUser>
            {open ? <AccountMenuDropdown /> : ""}
        </Wrapper>
    );
});

export default AccountMenu;
