import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { api } from "../../../api";
import LoadSpinner from "../../../components/loadspinner";
import { useTranslation } from "react-i18next";
import registrationStore from "../../../components/registrationStore";
import FileSaver from "file-saver";
import Card from "../../../components/styled/card";
import Button from "../../../components/styled/button";
import TherapistIcon from "../../../assets/images/therapist-icon-man.png";
import { Redirect } from "react-router-dom";
import {Field} from 'formik';

const ExCard = styled(Card)`
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: 10px;
`;

const FlexCard = styled.div`
    display: flex;
    flex-direction: column;

    @media (min-width: 768px) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
`;

const Flex = styled.div`
    display: flex;
    align-items: center;
`;

const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
`;

const Bold = styled.p`
    margin: 0px;
    font-weight: bold;
`;

const Text = styled.p`
    margin: 0px;
    margin-bottom: 5px;
    margin-top: 5px;
`;

const UserIcon = styled.img`
    width: 25px;
    height: 25px;
    margin-right: 5px;

    @media (min-width: 980px) {
        width: 40px;
        height: 40px;
        margin-right: 15px;
    }
`;

const FlexButtons = styled.div`
    display: flex;
    margin-top: 15px;

    @media (min-width: 768px) {
        margin-top: 0px;
    }
`;

const RedButton = styled(Button)`
    background-color: #dc393d;
    border-color: #dc393d;
    margin-left: 10px;

    &:hover {
        background-color: #dc393d;
        border-color: #dc393d;
    }

    @media (min-width: 768px) {
        margin-left: 20px;
    }
`;

const Input = styled(Field)`
  background: #ffffff;
  border: 1px solid #51c0cf;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(197, 197, 197, 0.25);
  border-radius: 10px;
  padding: 10px 10px;
  margin-top: 5px;
  outline: none;
  font-size: 16px;
  max-height: 100%;
  margin-right: 20px;

  &:focus {
    resize: both;
  }
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const ExerciseRequests = () => {
    const { t, i18n } = useTranslation();
    const store = useContext(registrationStore);
    const user = JSON.parse(store.user);
    const [requests, setRequests] = useState();
    const [loading, setLoading] = useState(true);
    const [redirect, setRedirect] = useState(false);
    const [redirectToId, setRedirectToId] = useState("");
    const [users, setUsers] = useState()
    const [mapping, setMapping] = useState([])

    useEffect(() => {
        let users = api.get("/users?therapist=true");
        let requests = api.get("/exercise_requests");
        Promise.all([users, requests]).then(res => {
            setUsers(res[0].data.users)
            setRequests(res[1].data.output);
            setLoading(false);
        });
    }, []);

    const SendStatusEmailToTherapist = (status = "accepted", user_id, title) => {
        api.get("/user/" + user_id).then((res) => {
            const therapistEmail = res.data.user.email;

            api.post("/mail", {
                body: "request",
                sender: "info@telephon.digital",
                to: therapistEmail,
                subject: `Requested exercise ${status}`,
                message: `Dear TelePHON.digital Community Member,

You are receiving this message because your requested exercise (${title}) got ${status}.
Go to telephon.digital to assign your new exercise to a client.

With warm regards,
The TelePHON.digital team`,
            }).then(() => {
                console.log(`mail succesfully send to ${therapistEmail}`);
            });
        });
    };

    const acceptRequest = (request) => {

        let user_id = request.user_id;
        let title = request.exercises[0].title;
        const id = request.id;

        // const mappedExercise = mapping.find(mapper => mapper.request.id == request.id)

        // if (mappedExercise && mappedExercise.user) {
        //     user_id = mappedExercise.user.public_id
        //     api.put("/exercise_request/" + id, { status: "Accepted", email: mappedExercise.user.email}).then((res) => {
        //         update();
        //     });
        // } else {
        api.put("/exercise_request/" + id, { status: "Accepted" }).then((res) => {
            update();
        });
        // }

        api.post("/send_message", {
            user_id: user_id,
            body: t("AdminOefeningAanvragen.accepteerBericht") + " " + title,
            sender_id: user.public_id,
            sender_name: "Telephon admin",
            type: "regular",
        });
       
        SendStatusEmailToTherapist("accepted", user_id, title);
    };
    const rejectRequest = (user_id, title, id) => {
        api.post("/send_message", {
            user_id: user_id,
            body: t("AdminOefeningAanvragen.weigerBericht") + " " + title,
            sender_id: user.public_id,
            sender_name: "Telephon admin",
            type: "regular",
        });
        api.put("/exercise_request/" + id, { status: "Rejected" }).then((res) => {
            update();
        });
        SendStatusEmailToTherapist("declined", user_id, title);
    };
    const update = () => {
        api.get("/exercise_requests").then((res) => {
            setRequests(res.data.output);
        });
    };

    async function showMaterial(id) {
        // requests.map(r => { console.log(r); console.log(r._type)});
        // for (let file of files) {
        //     await downloadOneFile(file);
        // }
        setRedirectToId(id);
        setRedirect(true);

    }

    const handleTherapistChange = (email, request) => {
        // let user = users.find(user => user.email === email);
        // let filteredMapping = mapping.filter(mapper => mapper.request.id != request.id)
        // setMapping([...filteredMapping, {user, request}])
    }

    return (
        <div>
            {loading ? (
                <LoadSpinner />
            ) : (
                <>
                    {requests.map((request) => {
                        if (! request.exercises) {
                            return (<></>);
                        }
                        return (
                            <ExCard>
                                <FlexCard>
                                    <Flex>
                                        <UserIcon src={TherapistIcon} />
                                        <div>
                                            <Bold>{t("AdminOefeningAanvragen.aangevraagddoor")}</Bold>
                                            <Text>{request.therapist_name}</Text>
                                        </div>
                                    </Flex>
                                    {/* <FormGroup>
                                        <Text>{t("admin.isprivate")}:{request.exercises[0].is_private.toString()}</Text>
                                        <select onChange={(e) => handleTherapistChange(e.target.value, request)}>
                                            <option value="">{t("users.none")}</option>
                                            {users.map(user => 
                                                <option key={user.public_id} value={user.email}>{user.email}</option>
                                            )}
                                        </select>
                                    </FormGroup> */}
                                    <FlexColumn>
                                        <Text>{request.exercises[0].title}</Text>
                                        <Button small onClick={() => showMaterial(request.id)}>
                                            {t("AdminOefeningAanvragen.download")}
                                        </Button>
                                    </FlexColumn>
                                    <FlexButtons>
                                        <Button
                                            small
                                            onClick={() => acceptRequest(request)}
                                        >
                                            {t("AdminOefeningAanvragen.accepteer")}
                                        </Button>
                                        <RedButton
                                            small
                                            onClick={() => rejectRequest(request.user_id, request.exercises[0].title, request.id)}
                                        >
                                            {t("AdminOefeningAanvragen.weiger")}
                                        </RedButton>
                                    </FlexButtons>
                                </FlexCard>
                            </ExCard>
                        );
                    })}
                    {redirect && (
                        <Redirect
                            push
                            to={{ 
                                pathname: "consultMaterial/Detail/" + redirectToId,
                                review: "?preview=true"
                            }}
                        />
                    )}
                </>
            )}
        </div>
    );
};

export default ExerciseRequests;
