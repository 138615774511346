import React, { useEffect, useState } from "react";
import { FaCaretDown, FaCaretRight } from "react-icons/fa";
import styled from "styled-components";

const TitleContainer = styled.div`
    display: flex;
    flexdirection: "row";
    align-items: center;
    font-weight: ${(props) => (props.bold ? "bold" : "normal")};
    color: ${(props) => (props.color != null ? props.color : "#000000")};
    cursor: pointer;
    margin-bottom: 15px;
    margin-top: 5px;
`;

const Container = styled.div`
    position: relative;
    backgroundcolor: "#fff";
    margin-left: 10px;
    overflow: "hidden";
    font-size: 18px;

    @media (min-width: 980px) {
        font-size: 22px;
        margin-left: 30px;
    }
`;

const Content = styled.div`
    padding-left: 10px;
    font-size: 18px;

    @media (min-width: 980px) {
        font-size: 22px;
    }
`;

const CaretDown = styled(FaCaretDown)`
    text-align: left;
    color: ${(props) => (props.color != null ? props.color : "#000000")};
    font-size: 25px;
    align-self: center;
    font-weight: ${(props) => (props.bold ? "bold" : "normal")};
`;

const CaretRight = styled(FaCaretRight)`
    text-align: left;
    color: ${(props) => (props.color != null ? props.color : "#000000")};
    font-size: 25px;
    align-self: center;
    font-weight: ${(props) => (props.bold ? "bold" : "normal")};
`;

const Collapsible = (props) => {
    const [open, setOpen] = useState(props.open);

    const toggle = () => {
        setOpen(!open);
    };

    return (
        <Container>
            <TitleContainer onClick={toggle} color={props.color} bold={props.bold} icon={props.icon}>
                {open ? (
                    <CaretDown color={props.color} bold={props.bold} />
                ) : (
                    <CaretRight color={props.color} bold={props.bold} />
                )}
                {props.icon}{props.title}
            </TitleContainer>
            {open ? <Content>{props.children}</Content> : null}
        </Container>
    );
};

export default Collapsible;
