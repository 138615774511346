import api from '../api';

const sendSelectedMediaToPatient = (mediaId, callId) => api.getCommunicationApi().post('/media/select', {
    mediaId,
    callId
});

const sendSelectedDocumentToPatient = (documentId, callId) => api.getCommunicationApi().post('/document/select', {
    documentId,
    callId
});

const sendSelectedExerciseToPatient = (exerciseId, callId) => api.getCommunicationApi().post('/exercises/select', {
    exerciseId,
    callId
});

const sendResultToTherapist = (result, callId) => api.getCommunicationApi().post('/exercises/result', {
    result,
    callId
});

const sendStepChange = (stepNumber, callId) => api.getCommunicationApi().post('/exercises/step/change', {
    stepNumber,
    callId
});

const sendMiniStepChange = (stepNumber, callId) => api.getCommunicationApi().post('/exercises/mini-step/change', {
    stepNumber,
    callId
});

export default {
    sendSelectedMediaToPatient,
    sendSelectedDocumentToPatient,
    sendSelectedExerciseToPatient,
    sendResultToTherapist,
    sendStepChange,
    sendMiniStepChange
}
