import React from "react"
import styled from "styled-components"

const Spinner = styled.div`
    @keyframes loadingspin {
        100% {
                transform: rotate(360deg)
        }
    }
    pointer-events: none;
	width: 20px;
	height: 20px;
	border: 0.2em solid transparent;
	border-color: #eee;
	border-top-color: #51C0CF;
	border-radius: 50%;
    animation: loadingspin 1s linear infinite;
`

function LoadSpinner(){
    return(
        <Spinner />
    )
}

export default LoadSpinner