import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Li, Ul } from "../styled/list";
import Button from "../styled/button";
import { H3, H4 } from "../styled/typography";
import RegistrationStore from "../registrationStore";
import TherapistIconFemale from "../../assets/images/therapist-icon.png";
import TherapistIconMale from "../../assets/images/therapist-icon-man.png";
import LoadingSpinner from "../../components/loadspinner";
import { api } from "../../api";
import MijnPlan from "../../pages/therapeut/mijnPlan";
import { PropTypes } from "mobx-react";

const Plan = styled.div`
    border: 1px solid #51c0cf;
    border-radius: 10px;
    width: 100%;
    margin-bottom: 15px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fff;
    padding-bottom: 25px;
    margin-right: 25px;

    @media (min-width: 980px) {
        width: 48%;
        max-width: 375px;
        margin-bottom: 0px;
    }
`;

const PlanImage = styled.img`
    width: 100px;
    margin-top: 40px;
`;

const Flex = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const Price = styled(H3)`
    font-weight: 400;
    margin-top: 20px;
    margin-bottom: 0px;
`;

const Duration = styled(H4)`
    margin-bottom: 20px;
    font-weight: 300;
    margin-top: 0px;
`;

const Input = styled.select`
    background: #ffffff;
    border: 1px solid #51c0cf;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(197, 197, 197, 0.25);
    border-radius: 10px;
    padding: 10px 10px;
    margin-top: 5px;
    outline: none;
    width: 70%;
    font-size: 16px;
`;

const SubmitButton = styled(Button)`
    margin-top: 20px;
    margin-bottom: 40px;
    padding-right: 30px;
    padding-left: 30px;
    font-size: 16px;
    width: 70%;
`;

const A = styled.a`
    text-decoration: none;
`;

const SubText = styled(H4)`
    margin-bottom: 25px;
    margin-top: 25px;
    color: #929292;
    text-align: center;
    font-size: 18px;
    font-weight: 300;
`;

const TherapistPlans = ({ setValue }) => {
    const { t, i18n } = useTranslation();
    const store = useContext(RegistrationStore);
    const user = JSON.parse(store.user);
    const [loading, setLoading] = useState(false);
    const [plans, setPlans] = useState([]);

    useEffect(() => {
        setLoading(true);
        api.get("/plans", { params: { onlyPublic: true, typeOfUser: "Therapeut" } }).then((res) => {
            setPlans(res.data.plans);
            setLoading(false);
        });
    }, []);

    const selectPlan = (id) => {
        setValue(id);
    };

    return loading ? (
        <LoadingSpinner />
    ) : (
        <Flex>
            {plans.map((plan) => {
                return (
                    <Plan>
                        <PlanImage src={user.sex === "Man" ? TherapistIconMale : TherapistIconFemale} />
                        <Price id="priceTherapist">{(plan.price / plan.period).toFixed(2) + "€ " + t("mijnPlan.perMonth")}</Price>
                        <Ul>
                            <Li small>{t("registerForm3.therapy")}</Li>
                            <Li small>
                                {t("registerForm3.ex")} &amp; <br /> {t("registerForm3.res")}
                            </Li>
                            <Li small>{t("registerForm3.doc")}</Li>
                            <Li small>{t("registerForm3.com")}</Li>
                        </Ul>
                        <Duration>{plan.period + " " + t("mijnPlan.months")}</Duration>
                        <Button onClick={() => selectPlan(plan.id)}>{t("mijnPlan.SelectThisPlan")}</Button>
                    </Plan>
                );
            })}
            <Plan>
                <PlanImage src={user.sex === "Man" ? TherapistIconMale : TherapistIconFemale} />
                <Price id="priceTherapist">{t("mijnPlan.CustomPlan")}</Price>
                <SubText>{t("mijnPlan.CustomPlanLenght")}</SubText>
                <A href="mailto:info@telephon.digital">
                    <Button>{t("mijnPlan.SelectThisPlan")}</Button>
                </A>
            </Plan>
        </Flex>
    );
};

export default TherapistPlans;
