import React, { useContext, useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { Formik, Form, Field } from "formik";
import registrationStore from "../components/registrationStore";
import TaskInAssignment from "../components/taskInAssignment";
import { P, SmallTitle } from "../components/styled/typography";
import Button from "../components/styled/button";
import OpdrachtStore from "../components/opdrachtStore";
import { api } from "../api";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import TrajectStore from "../components/trajectComponents/trajectStore"
//Changes: JEN
import Sheduler from "../components/calendar/sheduler"

const TaskForm = styled(Form)`
    margin-top: 10px;

    @media (min-width: 980px) {
        margin-top: 0px;
    }
`;

const FormGroup = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
`;

const Input = styled(Field)`
    background: #ffffff;
    border: 1px solid #51c0cf;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(197, 197, 197, 0.25);
    border-radius: 10px;
    padding: 10px 10px;
    margin-top: 5px;
    outline: none;
    font-size: 16px;
`;

const TitleField = styled.input`
    background: #ffffff;
    border: 1px solid #51c0cf;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(197, 197, 197, 0.25);
    border-radius: 10px;
    padding: 10px 10px;
    margin-top: 5px;
    outline: none;
    width: 100%;
`;
const Label = styled.label`
    font-size: 16px;
    font-weight: 600;
    color: #545454;
    margin-bottom: 10px;
`;

const SubmitButton = styled(Button)`
    margin-top: 0px;
    padding-right: 45px;
    padding-left: 45px;
    font-size: 18px;
    background-color: #45ae7c;
    border-color: #45ae7c;

    &:hover {
        background-color: #3a9168;
        border-color: #3a9168;
    }
`;

const Error = styled.p`
    font-size: 16px;
    color: #fff;
    font-style: italic;
    background-color: #dc393d;
    border: 1px solid #dc393d;
    border-radius: 10px;
    padding: 10px 15px;
`;

const Center = styled.div`
    text-align: center;
`;

const Flex = styled.div`
    display: flex;
    flex-direction: column;

    @media (min-width: 980px) {
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
    }
`;

const Half = styled.div`
    width: 49.5%;
`;

const OneFourth = styled.div`
    width: 100%;
    @media (min-width: 980px) {
        width: 17.5%;
    }
`;

const SchedulerContainer = styled.div`
    width: 100%;
`;

const ThreeFourth = styled.div`
    width: 100%;
`;

const TaskCards = styled.div`
    display: flex;
    flex-direction: column;
`;
const Description = styled(Field)`
    background: #ffffff;
    border: 1px solid #51c0cf;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(197, 197, 197, 0.25);
    border-radius: 10px;
    padding: 10px 10px;
    margin-top: 5px;
    margin-bottom: 10px;
    outline: none;
    width: 100%;
    height: 60px;
`;

const DatumField = styled.div`
    margin-bottom: 10px;
`;

const FlexCenter = styled.div`
    display: flex;
    flex-direction: column;

    @media (min-width: 980px) {
        flex-direction: row;
        align-items: flex-end;
    }
`;

const Text = styled(P)`
    margin: 0px;
    color: #545454;
    font-size: 18px;
    padding-bottom: 3px;
`;

const TextR = styled(P)`
    font-style: italic;
    margin-top: 10px;
    margin-bottom: 10px;

    @media (min-width: 980px) {
        margin: 0px;
        margin-right: 15px;
    }
`;

const InputNumber = styled(Input)`
    margin-left: 10px;
    margin-right: 10px;
    width: 60px;
`;

const InputDate = styled.input`
    background: #ffffff;
    border: 1px solid #51c0cf;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(197, 197, 197, 0.25);
    border-radius: 10px;
    padding: 10px 10px;
    margin-top: 5px;
    outline: none;
    width: 100%;
`;

const MobileFlex = styled.div`
    display: flex;
    align-items: center;

    @media (min-width: 980px) {
        display: flex;
        align-items: flex-end;
    }
`;

const SubTitle = styled(SmallTitle)`
    @media (min-width: 980px) {
        font-size: 18px;
        margin-top: 10px;
    }
`;

const CreateButton = styled(Button)`
    margin-top: 15px;
`;

const OpdrachtAanmaken = observer((props) => {
    const { t, i18n } = useTranslation();
    const regstore = useContext(registrationStore);
    const user = JSON.parse(regstore.user);
    const store = useContext(OpdrachtStore);
    const trajStore = useContext(TrajectStore)
    const [error, setError] = useState("");
    //Changes: JEN
    const [resetShedule, setResetShedule] = useState(false);
    const [shedule, setShedule] = useState({});
    const [btnToggleAdd, setBtnToggleAdd] = useState(false);
    const [btnToggleAddTask, setBtnToggleAddTask] = useState(false);

    const [viewScheduler, setViewScheduler] = useState(false);

    let taskComponents = t("patientopdrachtaanmaken.taskComp");

    if (store.taskArray.length > 0) {
        taskComponents = store.taskArray.map((task) => {
            return (
                <TaskInAssignment
                    id={task.task}
                    task={task.title}
                    tasktype={task.tasktype}

                    //Changes: JEN
                    total={task.total}
                />
            );
        });
    }
     
    const HandleSubmit = () => {
        if (document.getElementById("title").value === "") {
            setError(t("patientopdrachtaanmaken.errTitel"));
        } else {
            api.post("/assignment", {
                therapist_id: user.public_id,
                title: document.getElementById("title").value,
                user_id: props.public_id,
                tasks: store.taskArray,
                category: trajStore.chosenCategory,
                traject_id: trajStore.chosenTrajectId
            })
                .then((response) => {
                    props.close();
                    props.update();
                    store.clearArray();
                    store.clearTitle()
                    api.post("/send_message", {
                        body: "New assignment",
                        sender_id: user.public_id,
                        sender_name: user.firstName,
                        user_id: props.public_id,
                        type: "new-assignment",
                    });
                })
                .then(() => {
                    api.get("/user/" + props.public_id).then((res) => {
                        const patientEmail = res.data.user.email;
                        api.post("/mailWithTemplate", {
                            sender: "info@telephon.digital",
                            to: patientEmail,
                            subject: "Assignment added",
                            template_name: "new-assignment-mail",
                            template_content: [{"name": "therapist-name", "content": user.firstName + " " + user.lastName}]
                        });
                    });
                });
        }
    };

    const handleSwitch = () => {
        props.switch();
    };

    const handleTitleInputChange = (e) => {
        store.setTitleInput(e.target.value);
    };

    const handleDateInputChange = (e) => {
        store.setDateInput(e.target.value);
    };

    useEffect(() => {
        if(props.chosenElement.title !== undefined)
            setViewScheduler(true);
    }, [props.chosenElement.title]);

    useEffect(() => {        
        setBtnToggleAddTask(store.titleInput.length > 0 ? false : true);
    }, [store.titleInput]);

    const handlePermitAddTask = (e) => {
        setBtnToggleAdd(e > 0 ? false : true);
    }

    return (
        <div>
            <hr />
            <SubTitle black>{t("patientopdrachtaanmaken.title")}</SubTitle>            
            <TitleField type="text" id="title" onChange={handleTitleInputChange} value={store.titleInput} />            
            <Flex><p c>{error}</p></Flex>
            <Formik
                initialValues={{ tasktype: "", task: "", description: "" }}
                onSubmit={(values, actions) => {
                    const index = store.taskArray.length;
                    store.createObject(
                        index,
                        props.chosenElement.title,
                        props.chosenElement.type,
                        props.chosenElement.id,
                        shedule,
                        values.description
                    );
                    store.addToArray(store.taskObject);
                    setResetShedule(!resetShedule);
                    actions.resetForm();
                    actions.setSubmitting(false);
                }} 
                  render={({ errors, touched }, isSubmitting) => (                    
                    <TaskForm>
                        <hr />
                        <Flex>
                            <FormGroup>                                    
                                <SubTitle black>{t("patientopdrachtaanmaken.selTask")}</SubTitle>
                                <Flex>
                                    {props.chosenElement.title !== undefined ? (
                                        <h2>&#9658; {props.chosenElement.title}</h2>
                                    ) : ('')}
                                </Flex>
                                <Flex>
                                    <Button type="button" disabled={btnToggleAddTask} onClick={() => handleSwitch()}>
                                        {t("patientopdrachtaanmaken.chooseTask")}
                                    </Button>
                                </Flex>
                            </FormGroup>
                        </Flex>

                        {/*Changes: JEN*/}
                        <Flex>
                        <div style={(viewScheduler === true ? {"display": "inline"} : {"display": "none"} )}>                                                    
                            <Sheduler name="shedule" callback = {(e) => setShedule(e)} handlePermitAddTask={(e) => handlePermitAddTask(e)} resetShedule={resetShedule} />                            
                            <FormGroup>
                                <Label>{t("patientopdrachtaanmaken.info")}</Label>
                                <Description component="textarea" name="description" />
                            </FormGroup>
                            <SubmitButton type="submit" disabled={btnToggleAdd} onClick={(e) => setViewScheduler(false)}>
                                {t("patientopdrachtaanmaken.add")}
                            </SubmitButton>
                        </div>
                        </Flex>
                    </TaskForm>
                )}
            />
            {/* STEP 3 */}
            <Flex>
                <div style={(store.taskArray.length > 0 ? {"display": "inline"} : {"display": "none"} )}>
                    <hr />
                    <SubTitle black>{t("patientopdrachtaanmaken.tasks")}</SubTitle>
                    <TaskCards>{taskComponents}</TaskCards>               
                </div>
                <br />
            </Flex>
            <hr />            
            <CreateButton disabled={store.taskArray.length > 0 ? false : true } onClick={HandleSubmit}>{t("patientopdrachtaanmaken.makeAss")}</CreateButton>
        </div>
    );
});

export default OpdrachtAanmaken;
