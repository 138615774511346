import React, { useContext, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import Button from "../components/styled/button";
import RegistrationStore from "../components/registrationStore";
import { observer } from "mobx-react-lite";
import { api } from "../api";
import Flash from "../components/styled/flashMessage";
import Card from "../components/styled/card";
import { FaTimes } from "react-icons/fa";
import { SmallTitle } from "../components/styled/typography";
import LoadSpinner from "../components/loadspinner";
import { useTranslation } from "react-i18next";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { eventConstants, getInsightUserType } from "../insights-constants";
import { isAppInsigthsEnabled } from "../insights";
import { trackEvent } from "../insights-track";

const LoginForm = styled(Form)`
    padding: 0px 0px 20px 0px;

    @media (min-width: 768px) {
        padding: 0px 60px 40px 60px;
    }
`;

const CenterSpinner = styled.div`
    display: flex;
    justify-content: center;
    padding-bottom: 15px;
`;

const Container = styled.div`
    padding: 0px;

    @media (min-width: 768px) {
        padding: 0px 60px;
    }
`;

const FormGroup = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
`;

const Input = styled(Field)`
    background: #ffffff;
    border: 1px solid #51c0cf;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(197, 197, 197, 0.25);
    border-radius: 10px;
    padding: 10px 10px;
    margin-top: 5px;
    outline: none;
    font-size: 16px;
`;

const Label = styled.label`
    font-size: 16px;
    font-weight: 600;
    color: #545454;
`;

const SubmitButton = styled(Button)`
    margin-top: 20px;
    padding-right: 45px;
    padding-left: 45px;
    font-size: 18px;
`;

const Error = styled.p`
    font-size: 16px;
    color: #fff;
    font-style: italic;
    background-color: #dc393d;
    border: 1px solid #dc393d;
    border-radius: 10px;
    padding: 10px 15px;
`;

const Center = styled.div`
    text-align: center;
`;

const ForgotPassword = styled.a`
    color: #51c0cf;
    cursor: pointer;
`;

const Overlay = styled.div`
    background-color: rgba(60, 60, 60, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const OverlayCard = styled(Card)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
`;

const Times = styled(FaTimes)`
    color: #545454;
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
`;

const OverlayTitle = styled(SmallTitle)`
    margin: 0px;
`;

const ResetInput = styled.input`
    background: #ffffff;
    border: 1px solid #51c0cf;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(197, 197, 197, 0.25);
    border-radius: 10px;
    padding: 10px 10px;
    margin-top: 5px;
    outline: none;
    width: 300px;
    margin: 20px auto;
    font-size: 16px;
`;

function getDateMonthsBefore(date, nofMonths) {
    var thisMonth = date.getMonth();
    // set the month index of the date by subtracting nofMonths from the current month index
    date.setMonth(thisMonth - nofMonths);
    // When trying to add or subtract months from a Javascript Date() Object which is any end date of a month,
    // JS automatically advances your Date object to next month's first date if the resulting date does not exist in its month.
    // For example when you add 1 month to October 31, 2008 , it gives Dec 1, 2008 since November 31, 2008 does not exist.
    // if the result of subtraction is negative and add 6 to the index and check if JS has auto advanced the date,
    // then set the date again to last day of previous month
    // Else check if the result of subtraction is non negative, subtract nofMonths to the index and check the same.
    if (thisMonth - nofMonths < 0 && date.getMonth() != thisMonth + nofMonths) {
        date.setDate(0);
    } else if (thisMonth - nofMonths >= 0 && date.getMonth() != thisMonth - nofMonths) {
        date.setDate(0);
    }
    return date;
}

const LoginForm1 = observer(() => {
    const { t, i18n } = useTranslation();
    const store = useContext(RegistrationStore);
    const [user, setUser] = useState({});
    const [redirect, setRedirect] = useState(false);
    const [showReset, setShowReset] = useState(false);
    const [goTo2FA, setGoTo2FA] = useState(false);
    const [userDoesNotExist, setUserDoesNotExist] = useState(false);

    const isAiEnabled = isAppInsigthsEnabled();
    const appInsights = useAppInsightsContext();

    const handleClose = () => {
        setShowReset(false);
    };

    const HandleSubmit = () => {
        api.get("/user?email=" + document.getElementById("EmailInput").value).then((response) => {
            if (response.data.user) {
                api.get("/reset_password?email=" + document.getElementById("EmailInput").value).then(function () {
                    setShowReset(false);
                });
            } else {
                setUserDoesNotExist(true);
            }
        });
    };

    return (
        <div>
            <Container>
                {store.error && <Error>{store.error}</Error>}
                {store.inProgress && (
                    <CenterSpinner>
                        <LoadSpinner />
                    </CenterSpinner>
                )}
            </Container>
            <Formik
                initialValues={{ email: "", password: "" }}
                onSubmit={(values, actions) => {
                    store.setEmail(values.email);
                    store.setPassword(values.password);
                    store.setInProgress(true);
                    store
                        .login()
                        .then(() => {
                            setUser(JSON.parse(store.user));
                            var Loggeduser = JSON.parse(store.user);
                            trackEvent(isAiEnabled, appInsights, eventConstants.USER.LOGIN, {
                                user: {
                                    public_id: Loggeduser.public_id, 
                                    type: getInsightUserType(Loggeduser.therapist)
                                }
                            });
                            var shouldGoTo2FA = false;
                            var aMonthAgo = getDateMonthsBefore(new Date(), 1);
                            if (new Date(Loggeduser.lastTwoFAPhone) < aMonthAgo) {
                                shouldGoTo2FA = true;
                            }
                            if (!Loggeduser.TwoFAEnabled) {
                                shouldGoTo2FA = false;
                            }
                            setGoTo2FA(shouldGoTo2FA);
                            setRedirect(true);
                            store.setInProgress(false);
                            actions.setSubmitting(false);
                        })
                        .catch(() => store.setInProgress(false));
                }}
                render={({ errors, touched }, isSubmitting) => (
                    <LoginForm>
                        {store.registrationMessage && <Flash green>{t("loginForm.succesRegister")}</Flash>}
                        <FormGroup>
                            <Label>{t("loginForm.emailLbl")}</Label>
                            <Input
                                type="email"
                                name="email"
                                placeholder={t("loginForm.emailLbl")}
                                data-testid="email-input"
                            />
                            {touched.email && errors.email && <Error data-testid="email-error">{errors.email}</Error>}
                        </FormGroup>
                        <FormGroup>
                            <Label>{t("loginForm.passLbl")}</Label>
                            <Input
                                type="password"
                                name="password"
                                placeholder={t("loginForm.passLbl")}
                                data-testid="pwd-input"
                            />
                            {touched.password && errors.password && <Error>{errors.password}</Error>}
                        </FormGroup>
                        <div>
                            <ForgotPassword onClick={() => setShowReset(true)}>{t("loginForm.forgot")}</ForgotPassword>
                        </div>
                        <Center>
                            <SubmitButton disabled={isSubmitting} data-testid="login-submit-button">
                                {t("loginForm.login")}
                            </SubmitButton>
                        </Center>
                        {redirect &&
                            (goTo2FA === true ? (
                                <Redirect to="/login2" />
                            ) : user.therapist === true ? (
                                <Redirect to="/t/dashboard" />
                            ) : (
                                <Redirect to="/p/dashboard" />
                            ))}
                    </LoginForm>
                )}
                validationSchema={Yup.object().shape({
                    email: Yup.string().email(t("loginForm.corrEmail")).required(t("loginForm.giveEmail")),
                    password: Yup.string().required(t("loginForm.givePass")),
                })}
            />
            {showReset && (
                <Overlay>
                    <OverlayCard>
                        <Times onClick={handleClose} />
                        <OverlayTitle black>{t("loginForm.forgotPass")}</OverlayTitle>
                        {userDoesNotExist && <Flash red>{t("loginForm.EmailDoesNotExist")}</Flash>}
                        <ResetInput placeholder={t("loginForm.emailPlace")} type="email" id="EmailInput" />
                        <Button type="submit" onClick={HandleSubmit}>
                            {t("loginForm.sendReq")}
                        </Button>
                    </OverlayCard>
                </Overlay>
            )}
        </div>
    );
});

export default LoginForm1;
