// @ts-nocheck
import React, {useState, useEffect, useContext} from "react";
import styled from "styled-components";
import { PageTitle, SmallTitle, P } from "../styled/typography";
import Card from "../styled/card";
import Button from "../styled/button";
import { Redirect } from "react-router-dom";
import { Formik, Field } from "formik";
import { useTranslation } from "react-i18next";
import Recorder from "../video/Recorder";
import Metronome from "../exerciseUtilities/metronoom/Metronoom";
import Chrono from "../exerciseUtilities/chronometer/Chronometer";
import Flash from "../styled/flashMessage";
import resultStore from "../resultStore";

const DagboekCard = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 20px 50px 60px 50px;

  @media (min-width: 980px) {
    padding-top: 20px;
    padding-bottom: 60px;
  }
`;

const Flex = styled.div`
  display: flex;
`;

const FlexColumn = styled(Flex)`
  flex-direction: column;
  align-items: center;
  margin: 5px 20px;

  @media (min-width: 980px) {
    margin: 0px 20px;
  }
`;

const Title = styled(SmallTitle)`
  margin-top: 0px;
`;

const Description = styled(P)`
  margin: 0px;
`;

const Desc = styled(P)`
  margin-top: 0px;
  color: #545454;
`;

const Bold = styled(P)`
  font-weight: bold;
  color: #545454;
  margin: 0px;
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 20px;
`;

const TextBox = styled(Field)`
  background: #ffffff;
  border: 1px solid #51c0cf;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(197, 197, 197, 0.25);
  border-radius: 10px;
  padding: 10px 10px;
  margin-top: 5px;
  margin-bottom: 10px;
  outline: none;
  width: 100%;
  height: 200px;
`;

const ButtonBox = styled.div`
  margin-top: 30px;
  width: 100%;
`;

const SubmitButton = styled(Button)`
  width: 100%;

  @media (min-width: 768px) {
    width: auto;
  }
`;

const FlexLabel = styled.label`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;

  @media (min-width: 980px) {
    flex-direction: column;
  }
`;

const Fieldset = styled.div`
  border: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (min-width: 980px) {
    flex-direction: row;
    align-items: center;
  }
`;

const FlexCards = styled(Flex)`
  flex-direction: column;
  align-items: stretch;

  &.communication-component {
    flex-direction: column;
    align-items: stretch;
  }

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: flex-start;

    &.communication-component {
      flex-direction: column;
      align-items: stretch;
      margin-bottom: 30px;
    }
  }
`;

const OptionsCard = styled(Card)`
  width: 100%;
  margin-top: 15px;

  @media (min-width: 768px) {
    width: 29.25%;
    margin-top: 0px;
    margin-left: 15px;

    &.communication-option-component {
      width: 100%;
      margin-top: 15px;
      margin-left: 0px;
    }
  }
`;

const TaskCard = styled.div`
  width: 100%;

  @media (min-width: 980px) {
    width: 100%;
  }
`;

const QuestionElement = styled.div`
    width: 70vw;
`

function Dagboek(props) {
    const { t, i18n } = useTranslation();

    const exercise = props.exercise;
    const [redirect, setRedirect] = useState(false);
    const [scores, setScores] = useState({});
    const [openAnswers, setOpenAnswers] = useState({});
    const [scaleAnswers, setScaleAnswers] = useState({});
    const [allQuestions, setAllQuestions] = useState([]);
    const [showSucces, setShowSucces] = useState(false);
    const [isExample, setIsExample] = useState(false);
    const [isReview, setIsReview] = useState(false);

    const ReceiveRecording = (recording) => {
        props.HandleRecording(recording);
        setShowSucces(true);
        setTimeout(function () {
            setShowSucces(false);
        }, 5000);
    };

    function shuffle(array) {
        var currentIndex = array.length,
            temporaryValue,
            randomIndex;

        // While there remain elements to shuffle...
        while (0 !== currentIndex) {
            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;

            // And swap it with the current element.
            temporaryValue = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = temporaryValue;
        }

        return array;
    }

    function createScale(question) {
        let scale = [];
        var i;
        for (i = 0; i < question.scale_possibilities; i++) {
            scale.push(
                <FlexColumn>
                    <FlexLabel>
                        <div>{question.fields[0][i].name}</div>
                        <Field type="radio" name={question.id} value={question.fields[0][i].value} required></Field>
                    </FlexLabel>
                </FlexColumn>
            );
        }

        // else {
        //     var i
        //     for (i = 0; i < options.scalePossibilities; i++){
        //         scale.push(<FlexColumn><label>{i}<Field type="radio" name={question._key} value={i} required></Field></label></FlexColumn>)
        //     }
        // }
        return scale;
    }

    useEffect(() => {
        if (exercise.random_all) {
            var questions = [];
            exercise.question_types.map((type) => {
                questions = questions.concat(type.questions);
            });
            var randQuestions = shuffle(questions);
            setAllQuestions(randQuestions);
        } else if (exercise.random_within_type) {
            var questions = [];
            exercise.question_types.map((type) => {
                var randQuestions = shuffle(type.questions);
                questions = questions.concat(randQuestions);
            });
            setAllQuestions(questions);
        } else {
            var questions = [];
            exercise.question_types.map((type) => {
                questions = questions.concat(type.questions);
            });
            setAllQuestions(questions);
        }
        // exercise.description.split('\n').map(str => <p>{str}</p>);
    }, []);

    if (exercise) {
        return (
            <div>
                <PageTitle black>{exercise.title}</PageTitle>
                <Desc>{props.taskDescription}</Desc>
                <FlexCards id="flex-cards">
                    <TaskCard>
                        <DagboekCard>
                            <Description><div dangerouslySetInnerHTML={{__html: exercise.description.replace(/\n/g,"<br /><br />") }} /></Description>
                            <Formik
                                initialValues={() => {
                                    var dict = {};
                                    exercise.question_types.map((type) => {
                                        return type.questions.map((question) => {
                                            return (dict[question.id] = 0);
                                        });
                                    });
                                    return dict;
                                }}
                                onSubmit={(values, actions) => {
                                    var scores = {};
                                    var Totaalscore = 0;
                                    var maxTotaalScore = 0;
                                    var type;
                                    var question;
                                    var aantalvragen = 0;
                                    var openAnswers = {};
                                    var scaleAnswers = {};
                                    for (type of exercise.question_types) {
                                        var subscore = 0;
                                        var subvragen = 0;
                                        var subtotaalscore = 0;
                                        for (question of type.questions) {
                                            if (question.answer_possibility === "schaal") {
                                                Totaalscore += parseInt(values[question.id], 10);
                                                subscore += parseInt(values[question.id], 10);
                                                aantalvragen += 1;
                                                subvragen += 1;
                                                subtotaalscore += parseInt(
                                                    question.max_score, 10
                                                );
                                                maxTotaalScore += parseInt(
                                                    question.max_score, 10
                                                );

                                                var index = question.fields.indexOf(values[question.id]);
                                                scaleAnswers[question.question] = question.fields[index];
                                            } else {
                                                openAnswers[question.question] = values[question.id];
                                            }
                                        }
                                        scores[type.name] = subscore + "/" + subtotaalscore;
                                    }
                                    var totalscoreName = t("dagboek.totaalScore");
                                    scores[totalscoreName] = Totaalscore + "/" + maxTotaalScore;
                                    setScores(scores);
                                    setOpenAnswers(openAnswers);
                                    setScaleAnswers(scaleAnswers);
                                    if (props.redirectEnabled) {
                                        setRedirect(true);
                                    } else if(props.isExample) {
                                        setIsExample(true);
                                    } else if(props.isReview) {
                                        setIsReview(true);
                                    } else {
                                        props.endExercise({
                                            id: exercise.id,
                                            scores: scores,
                                            exercise: exercise,
                                            startTime: props.startTime,
                                            title: exercise.title,
                                            type: "resultformdagboek",
                                        });
                                    }
                                }}
                                render={({ handleSubmit, values, setFieldValue }) => (
                                    <form onSubmit={handleSubmit} autocomplete="off">
                                        {allQuestions.map((question) => {
                                            return (
                                                <QuestionElement>
                                                    <Bold>{question.question.split('\n').map(str => <p>{str}</p>)}</Bold>
                                                    {question.answer_possibility == "open antwoord" ? (
                                                        <TextBox
                                                            component="textarea"
                                                            name={question.id}
                                                            value={values[question.id]}
                                                        ></TextBox>
                                                    ) : (
                                                        <Fieldset
                                                            id={question.id}
                                                        >{createScale(question)}</Fieldset>
                                                    )}
                                                </QuestionElement>
                                            );
                                        })}
                                        <ButtonBox>
                                            <SubmitButton type="submit">{t("dagboek.submit")}</SubmitButton>
                                            {redirect && (
                                                <Redirect
                                                    to={{
                                                        pathname: "/p/Assignments/results",
                                                        state: {
                                                            id: props.id,
                                                            scores: scores,
                                                            openAnswers: openAnswers,
                                                            scaleAnswers: scaleAnswers,
                                                            exercise: exercise,
                                                            startTime: props.startTime,
                                                            title: exercise.title,
                                                            type: "resultformdagboek",
                                                            exerciseType: "Dagboek"
                                                        },
                                                    }}
                                                />
                                            )}
                                            {isExample && (
                                                <Redirect to={{ pathname: "/t/CreateMaterial/requestExercise"}} />
                                            )}
                                            {isReview && (
                                                <Redirect to={{pathname: "/admin"}} />
                                            )}
                                        </ButtonBox>
                                    </form>
                                )}
                            ></Formik>
                        </DagboekCard>
                    </TaskCard>
                    {(exercise.should_be_chrono ||
                        exercise.should_be_metronome ||
                        exercise.should_be_recorded) && (
                        <OptionsCard id="options-card">
                            <Title black>{t("stappenplan.options")}</Title>
                            {props.showRecorder &&
                            exercise.should_be_recorded && (<>
                                <Recorder onRecordingComplete={ReceiveRecording} />
                                {showSucces && <Flash>{t("stappenplan.video")}.</Flash>}
                            </>)}
                            {exercise.should_be_chrono && <Chrono />}
                            {exercise.should_be_metronome && <Metronome />}
                        </OptionsCard>
                    )}
                </FlexCards>
            </div>
        );
    }
}

export default Dagboek;
