import React from "react"
import styled from "styled-components"
import { NavLink } from 'react-router-dom'
import { useTranslation } from "react-i18next"


const Flex = styled.div`
    display: flex;
`

const A = styled(NavLink)`
    color: #51C0CF;
    text-decoration: none;
    padding: 0px 5px;

    &.active{
        text-decoration: underline;
        color: #4E7693;
    }
`


function AccountNavigation(props){
    const { t, i18n } = useTranslation()
    return(
        <Flex>
            <A to={props.therapist ? {pathname: "/t/MyAccount/Details"} : {pathname: "/p/MyAccount/Details"}}>{t('accountNavigation.data')}</A>
            <A to={props.therapist ? {pathname: "/t/MyAccount/MyPlan"} : {pathname: "/p/MyAccount/MyPlan"}}>{t('accountNavigation.plan')}</A>
            {props.therapist ? "" : <A to={props.therapist ? "" : {pathname: "/p/MyAccount/MyTherapists"}}>{t('accountNavigation.mytherapists')}</A>}
        </Flex>
    )
}

export default AccountNavigation