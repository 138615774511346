import { observable, action, reaction, get } from "mobx";
import { createContext } from "react";
import { api } from "../api";

class RegistrationStore {
    @observable publicID = "";
    @observable user = window.localStorage.getItem("user");
    @observable newUser = {};
    @observable email = "";
    @observable password = "";
    @observable planLength = "";
    @observable plan = "";
    @observable plan_id = 0;
    @observable token = window.localStorage.getItem("token");
    @observable api = "";
    @observable error = "";
    @observable registrationMessage = false;
    @observable inProgress = false;
    @observable isTherapist = false;
    @observable firstName = "";
    @observable lastName = "";
    @observable phoneNumber = "";
    @observable wantsMarketingEmails = false;
    @observable speciality = "Logopedie";
    @observable individuFinished = false;

    constructor() {
        const reaction1 = reaction(
            () => this.token,
            (token) => {
                if (token) {
                    window.localStorage.setItem("token", token);
                    api.defaults.headers["x-access-token"] = token;
                } else {
                    window.localStorage.removeItem("token");
                }
            }
        );
        const reaction2 = reaction(
            () => this.user,
            (user) => {
                if (user) {
                    window.localStorage.setItem("user", user);
                } else {
                    window.localStorage.removeItem("user");
                }
            }
        );
    }
    @action addUserAttribute(name, value) {
        this.newUser[name] = value;
    }
    @action setToken(newToken) {
        this.token = newToken;
    }
    @action setUser(newUser) {
        this.user = newUser;
    }
    @action setError(newError) {
        this.error = newError;
    }
    @action setEmail(newEmail) {
        this.email = newEmail;
    }
    @action setPassword(newPassword) {
        this.password = newPassword;
    }
    @action setInProgress(newValue) {
        this.inProgress = newValue;
    }
    @action setPlan(newPlan) {
        this.plan = newPlan;
    }
    @action setPlanId(newId) {
        this.plan_id = newId;
    }
    @action setPlanLength(newPlanLength) {
        this.planLength = newPlanLength;
    }

    @action setIsTherapist(isTherapist) {
        this.isTherapist = isTherapist;
    }

    @action setFirstName(firstName) {
        this.firstName = firstName;
    }

    @action setLastName(lastName) {
        this.lastName = lastName;
    }

    @action setWantsMarketingEmails(wantsMarketingEmails) {
        this.wantsMarketingEmails = wantsMarketingEmails;
    }

    @action setSpeciality(speciality) {
        this.speciality = speciality;
    }

    @action setPhoneNumber(newPhoneNumber) {
        this.phoneNumber = newPhoneNumber;
    }

    @action setIndividuFinished(newValue) {
        this.individuFinished = newValue;
    }

    @action login() {
        return api
            .get("/login", { auth: { username: this.email, password: this.password } })
            .then((response) => {
                this.setToken(response.data.token);
                this.setUser(JSON.stringify(response.data.user));
            })
            .catch(() => {
                this.setError("Incorrect email or password.");
                throw this.error;
            });
    }

    @action checkAccountIsExpired() {
        const localStorageData = window.localStorage.getItem("lastAccountCheck");
        const lastCheck = new Date(localStorageData);
        const now = new Date();
        const public_id = JSON.parse(this.user)?.public_id;
        if (
            localStorageData === null ||
            lastCheck.getDate() !== now.getDate() ||
            lastCheck.getMonth() !== now.getMonth()
        ) {
            // api call
            api.get(`user/${public_id}/isExpired`).then((response) => {
                api.get(`user/${public_id}`).then((response) => {
                    this.setUser(JSON.stringify(response.data.user));
                });
            });

            // set lastCheck
            window.localStorage.setItem("lastAccountCheck", new Date());
        }
    }

    @action logout() {
        this.setToken(undefined);
        this.setUser(undefined);
        this.setError("");
    }
    @action setRegistrationMessage(newValue) {
        this.registrationMessage = newValue;
    }
}

export const RegistrationStoreInstance = new RegistrationStore();

export default createContext(RegistrationStoreInstance);
