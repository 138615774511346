import React, {useState, useContext} from "react"
import styled from 'styled-components'
import { TelLink } from "../components/styled/link" 
import TelephonLogo from '../assets/images/telephon-logo.png'
import registrationStore from "../components/registrationStore"
import DashboardIcon from '../assets/images/patient-overzicht-icon.png'
import OpdrachtenIcon from '../assets/images/patient-opdrachten-icon.png'
import MateriaalIcon from '../assets/images/patient-materiaalraadplegen-icon.png'
import ResultaatIcon from '../assets/images/patient-resultaten-icon.png'
import AccountIcon from '../assets/images/patient-account-icon.png'
import HelpdeskIcon from '../assets/images/helpdesk-icon.png'
import LogoutIcon from '../assets/images/logout-icon.png'
import InboxIcon from '../assets/images/inbox-icon.png'
import LanguageSelector from "../components/languageSelector"
import { useTranslation } from "react-i18next"
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { eventConstants, getInsightUserType } from "../insights-constants";
import { isAppInsigthsEnabled } from "../insights"
import { trackEvent } from './../insights-track';

/*
const Sidebar = styled.div`
  background-color: #51C0CF;
  height: 100%;
  width: 175px;
  position: fixed;
  z-index:9;
  top: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  padding-top: 20px;
`

const LogoImage = styled.img`
    height: 55px;
`

const Logo = styled.div`
  width: 120px;
  padding-bottom: 10px;
  z-index: 999;
  padding-left: 15px;
  @media (min-width: 980px){
    width: 10%;
  }
`

const Nav = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 20px;
`

const NavIcon = styled.img`
    width: 25px;
    height: 25px;
    padding-right: 10px;
`
const MenuLink = styled(TelLink)`
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-left: 20px;
    &.active{
        background-color: #469CA8;
        border-left: 5px solid #4E7693;
        box-shadow: inset 0px 2px 4px rgba(138, 138, 138, 0.3), inset 0px -2px 4px rgba(138, 138, 138, 0.3);
        padding-top: 20px;
        padding-bottom: 20px;
        margin-top: 0px;
        margin-bottom: 0px;
    }
`
*/

const Sidebar = styled.div`
    width: 100vw;
    height: 75px;
    background-color: #51C0CF;
    display: flex;
    align-items: center;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 9999;
    


    @media (min-width: 980px){
        display: block;
        background-color: #51C0CF;
        height: 100%;
        width: 175px;
        position: fixed;
        z-index:9;
        top: 0;
        left: 0;
        overflow-x: hidden;
        overflow-y: hidden;
        padding-top: 20px;
    }

    &.show{
        background-color: white;

        position: fixed;
        top: 0;
        left:0;
        height: 100vh;
        width: 100vw;
    }

`

const LogoImage = styled.img`
    height: 45px;
`

const Logo = styled.div`
  width: 120px;
  z-index: 999;
  padding-left: 10px;
  @media (min-width: 980px){
    width: 10%;
    padding-left: 20px;
    padding-bottom: 10px;
  }
`

const Nav = styled.div`
    display: none;
    
    @media (min-width: 980px){
        display: flex;
        flex-direction: column;
        padding-top: 20px;
        margin-bottom: 60px;
    }

    &.show{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

`

const NavIcon = styled.img`
    width: 30px;
    height: 30px;
    padding-right: 10px;
    @media(min-width: 980px){
        width: 25px;
        height: 25px;
    }
`

const MenuLink = styled(TelLink)`
    display: flex;
    align-items: center;
    font-size: 20px;
    @media (min-width: 980px){
        font-size: 18px;
        font-weight: normal;
        width: 150px;
        margin-top: 20px;
        margin-bottom: 20px;
        padding-left: 20px;
        &.active{
            background-color: #469CA8;
            border-left: 5px solid #4E7693;
            box-shadow: inset 0px 2px 4px rgba(138, 138, 138, 0.3), inset 0px -2px 4px rgba(138, 138, 138, 0.3);
            padding-top: 20px;
            padding-bottom: 20px;
            margin-top: 0px;
            margin-bottom: 0px;
        }
    }
`

const HomeLink = styled.a`
    text-decoration: none;
`

const NavButton = styled.div`
  z-index: 999;
  cursor: pointer;
  position: absolute;
  right: 30px;
  top: 27px;

  &.close {
    transform: rotate(180deg);
    position: fixed;
    top: 20px;
    left: 30px;
  }

  @media (min-width: 980px){
    display: none;
  }
`

const ButtonLine = styled.div`
  width: 25px;
  height: 3px;
  margin: 0px 0px 5px 0px;
  background: #FFFFFF;

  &.show:nth-child(1){
      background: #51C0CF;
      transform: rotate(45deg) translate(5px, 5px);
  }
  //line 2
  &.show:nth-child(2){
      opacity: 0;
  }
  //line 3
  &.show:nth-child(3){
      background: #51C0CF;
      transform: rotate(-45deg) translate(6px, -6px);
  }
`

const Language = styled.div`
    position: initial;

    @media(min-width: 980px){
        position: absolute;
        bottom: 60px;
        left: 40px;
    }
`

function SidebarPatient(){
    const { t, i18n } = useTranslation()
    const store = useContext(registrationStore)
    const user = JSON.parse(store.user)
    const [mobileMenu, setMobileMenu] = useState(false);

    const isAiEnabled = isAppInsigthsEnabled();
    const appInsights = useAppInsightsContext();
    const trackProps = {
        user: {
            type: getInsightUserType(user.therapist),
            public_id: user.public_id 
        } 
    }

    const toggleClass = () => {
        setMobileMenu(!mobileMenu);
    };

    function handleLogout(){
        trackEvent(isAiEnabled, appInsights, eventConstants.USER.LOGOUT, trackProps);
        store.logout();
    }

    return (
        <Sidebar className={mobileMenu ? 'show': null}>
            <Logo>
              <TelLink to='/p/dashboard'>
                <LogoImage src={TelephonLogo} />
              </TelLink>
            </Logo>
            <NavButton 
              className={mobileMenu ? 'show': null} 
              onClick={toggleClass}
            >
              <ButtonLine className={mobileMenu ? 'show': null}></ButtonLine>
              <ButtonLine className={mobileMenu ? 'show': null}></ButtonLine>
              <ButtonLine className={mobileMenu ? 'show': null}></ButtonLine>
            </NavButton>
            <Nav className={mobileMenu ? 'show': null}>
                <MenuLink to="/p/dashboard">
                    <NavIcon src={DashboardIcon}></NavIcon>
                    {t('sidebarPatient.over')}
                </MenuLink>
                {!user.expired && 
                    <MenuLink to="/p/Assignments">
                        <NavIcon src={OpdrachtenIcon}></NavIcon>
                        {t('patientCard.assignments')}
                    </MenuLink>
                }
                {!user.expired && 
                    <MenuLink to="/p/consultMaterial" onClick={() => trackEvent(isAiEnabled, appInsights, eventConstants.NAVIGATION.LIBRARY, trackProps)}>
                        <NavIcon src={MateriaalIcon}></NavIcon>
                        {t('sidebarPatient.material')}
                    </MenuLink>
                }
                {!user.expired && 
                    <MenuLink to="/p/ResultsDetail" onClick={() => trackEvent(isAiEnabled, appInsights, eventConstants.NAVIGATION.RESULTS, trackProps)}>
                        <NavIcon src={ResultaatIcon}></NavIcon>
                        {t('patientCard.results')}
                    </MenuLink>
                }         
                <MenuLink to="/p/MyAccount/Details">
                    <NavIcon src={AccountIcon}></NavIcon>
                    {t('sidebarPatient.acc')}
                </MenuLink>
                <MenuLink activeClassName='active' to="/p/helpdesk">
                    <NavIcon src={HelpdeskIcon}></NavIcon>
                    {t('accountMenuDropdown.helpdesk')}
                </MenuLink>
                {mobileMenu ? 
                    <MenuLink activeClassName='active' to={user.therapist ? "/t/inbox" : "/p/inbox"} >
                        <NavIcon src={InboxIcon}></NavIcon>
                        {t('accountMenuDropdown.inbox')}
                    </MenuLink> 
                    : null
                }
                {mobileMenu ? 
                    <MenuLink activeClassName='active' to="/login" onClick={handleLogout}>
                        <NavIcon src={LogoutIcon}></NavIcon>
                        {t('accountMenuDropdown.logout')}
                    </MenuLink> 
                    : null
                }                        
                <Language>
                  <LanguageSelector sidebar />
                </Language>
            </Nav>
        </Sidebar>
    )
}

export default SidebarPatient
