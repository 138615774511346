import { api } from "../../api";
import { uuid } from "uuidv4";
import dateConverter from "./dateConverter";

export default async function createBertieEN(therapist_id, name = "Bertie") {
    const dummyUser = {
        email: `${uuid()}@gmail.com`,
        password: "!Ya7:W6CD<gs;:S",
        firstName: name,
        lastName: " ",
        sex: "Bertie",
        therapist: false,
        paid: false,
        plan: "Patiënt",
        plan_period: 100,
        verified: true,
        TwoFAEnabled: false,
    };

    const dummyDetails = {
        medicalDiagnosis: "Noduli vocales",
        therapyPlan: `Thoracal breathing patterns, too long utterances on one breath; Tempo is high, moderate hypertonicity; Perceptual rating: G2R1B2 (A0 S2)`,
        paramedicalDiagnosis: "Functional dysphonia due to structural-organic pathology",
        helpRequest:
            "For several months she developed progressive hoarseness. Recently, speaking takes more effort and she is very tired after a full day of teaching. She can no longer make herself intelligible in noisy surroundings. Can't sing anymore. She gets throat pain when performing.",
        history: "Vocal nodules as a child. Speech therapy, no surgery.",
        personality:
            "Profession: kindergarten teacher, Hobby’s: Scene/musical; Very dynamic personality, full of energy; woman 32 y",
    };

    let patient = await api.post("/user", dummyUser);
    let patientLink = await api.post(`/linkPatient/${patient.data.id}`, { therapist_id: therapist_id });
    let traject = await api.post("/traject", {
        user_id: patient.data.id,
        therapist_id: therapist_id,
        title: "Care path",
    });
    api.patch(`/user/details/${patient.data.id}`, dummyDetails);
    // Vragenlijsten
    api.post("/assignment", {
        user_id: patient.data.id,
        therapist_id: therapist_id,
        title: "QOL and self-assessment",
        tasks: [
            {
                id: 0,
                title: "VHI adults",
                tasktype: "oefening",
                task: "npM1AmgJjgPkJCWgRjtbos",
                total: 1,
                dates: [{date: new Date(), frequency: 1}],
                description: "",
            },
        ],
        category: "research_questions",
        traject_id: traject.data.traject_id,
    });
    // Testen
    api.post("/assignment", {
        user_id: patient.data.id,
        therapist_id: therapist_id,
        title: "Measurements",
        tasks: [
            {
                id: 0,
                title: "measuring MPT1",
                tasktype: "oefening",
                task: "d2feaf8a-3a7d-4c94-a36d-2512273f08e4",
                total: 1,
                dates: [{date: new Date(), frequency: 1}],
                description: "",
            },
            {
                id: 1,
                title: "measuring MPT2",
                tasktype: "oefening",
                task: "8cd87633-7749-46ac-b775-f15c23e7d8e1",
                total: 1,
                dates: [{date: new Date(), frequency: 1}],
                description: "",
            },
            {
                id: 2,
                title: "measuring MPT3",
                tasktype: "oefening",
                task: "28e3a94b-7381-458e-9dd5-5c08626106ff",
                total: 1,
                dates: [{date: new Date(), frequency: 1}],
                description: "",
            },
            {
                id: 3,
                title: "aa/AA",
                tasktype: "oefening",
                task: "b73dcd96-422e-4921-a75a-2aeb6c37370e",
                total: 1,
                dates: [{date: new Date(), frequency: 1}],
                description: "",
            },
            {
                id: 4,
                title: "The rainbow passage",
                tasktype: "oefening",
                task: "rYzRBNi73HK0SjhCrTDaP9",
                total: 1,
                dates: [{date: new Date(), frequency: 1}],
                description: "",
            },
        ],
        category: "research_tests",
        traject_id: traject.data.traject_id,
    });
    // Behandeltraject - Informatie/educatie
    api.post("/assignment", {
        user_id: patient.data.id,
        therapist_id: therapist_id,
        title: "Things you should know about your voice",
        tasks: [
            {
                id: 0,
                title: "Voice hygiene in everyday life",
                tasktype: "document",
                task: 103,
                total: 1,
                dates: [{date: new Date(), frequency: 1}],
                description: "",
            },
        ],
        category: "treatment_info_education",
        traject_id: traject.data.traject_id,
    });
    // Behandeltraject - Bewustwording
    api.post("/assignment", {
        user_id: patient.data.id,
        therapist_id: therapist_id,
        title: "Breathing and posture",
        tasks: [
            {
                id: 0,
                title: "breathing/basics",
                tasktype: "oefening",
                task: "oO31wLM82M9PDTZDz0H0OY",
                total: 1,
                dates: [{date: new Date(), frequency: 1}],
                description: "",
            },
        ],
        category: "treatment_awareness",
        traject_id: traject.data.traject_id,
    });
    // Behandeltraject - Training
    api.post("/assignment", {
        user_id: patient.data.id,
        therapist_id: therapist_id,
        title: "Diminishing vocal effort",
        tasks: [
            {
                id: 0,
                title: "LaxVox basis",
                tasktype: "oefening",
                task: "23aa5da0-dc46-43a9-87a8-b9a8c0cc2d22",
                total: 1,
                dates: [{date: new Date(), frequency: 1}],
                description: "",
            },
            {
                id: 1,
                title: "LaxVox sound & words",
                tasktype: "oefening",
                task: "0a394987-7b1e-4c67-b702-038bf2b517a9",
                total: 1,
                dates: [{date: new Date(), frequency: 1}],
                description: "",
            },
            {
                id: 2,
                title: "VocTract opening",
                tasktype: "oefening",
                task: "4409fee6-5c8f-4168-9c48-92dffcb7636d",
                total: 1,
                dates: [{date: new Date(), frequency: 1}],
                description: "",
            },
        ],
        category: "treatment_training",
        traject_id: traject.data.traject_id,
    });
    // Behandeltraject - Generalisatie
    api.post("/assignment", {
        user_id: patient.data.id,
        therapist_id: therapist_id,
        title: "What to take into account",
        tasks: [
            {
                id: 0,
                title: "VHI adults random",
                tasktype: "oefening",
                task: "cOrcAAHov0716gz2u0fEGL",
                total: 1,
                dates: [{date: new Date(), frequency: 1}],
                description: "",
            },
            {
                id: 1,
                title: "Voice hygiene in everyday life",
                tasktype: "document",
                task: 103,
                total: 1,
                dates: [{date: new Date(), frequency: 1}],
                description: "",
            },
        ],
        category: "treatment_generalisation",
        traject_id: traject.data.traject_id,
    });
    // Behandeltraject - Automatisatie
    api.post("/assignment", {
        user_id: patient.data.id,
        therapist_id: therapist_id,
        title: "A reminder",
        tasks: [
            {
                id: 0,
                title: "VHI adults random",
                tasktype: "oefening",
                task: "cOrcAAHov0716gz2u0fEGL",
                total: 1,
                dates: [{date: new Date(), frequency: 1}],
                description: "",
            },
            {
                id: 1,
                title: "easy onset",
                tasktype: "oefening",
                task: "o6nAe64K8m9m3xNMaDZGvo",
                total: 1,
                dates: [{date: new Date(), frequency: 1}],
                description: "",
            },
            {
                id: 2,
                title: "Voice hygiene in everyday life",
                tasktype: "document",
                task: 103,
                total: 1,
                dates: [{date: new Date(), frequency: 1}],
                description: "",
            },
        ],
        category: "treatment_automatisation",
        traject_id: traject.data.traject_id,
    });
}
