import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Flex = styled.div`
    display: flex;
`;

const A = styled(NavLink)`
    color: #51c0cf;
    text-decoration: none;
    padding: 0px 5px;

    &.active {
        text-decoration: underline;
        color: #4e7693;
    }
`;

function PatientNavigation(props) {
    const { t, i18n } = useTranslation();
    return (
        <Flex>
            <A to={{ pathname: "/t/therapy/PatientDetail/" + props.public_id }}>{t("patientNavigation.det")}</A>
            <A to={{ pathname: "/t/therapy/PatientTask/" + props.public_id }}>{t("patientNavigation.ass")}</A>
            <A to={{ pathname: "/t/therapy/PatientResults/" + props.public_id }}>{t("patientNavigation.res")}</A>
        </Flex>
    );
}

export default PatientNavigation;
