import React, {useState} from 'react'
import styled from 'styled-components'
import Card from './styled/card'
import { P } from './styled/typography'
import { Link } from 'react-router-dom'
import { FaEdit, FaTrashAlt } from "react-icons/fa"
import Button from './styled/button'
import TaskInAssignmentSmall from './taskInAssignmentSmall'
import {api} from "../api"
import { useTranslation } from "react-i18next"

const TemplateCard = styled(Card)`
    width: 100%;
    margin-top: 20px;

    @media (min-width: 980px){
        width: 49.5%;
    }
`

const StyledLink = styled(Link)`
    text-decoration: none;
`

const Bold = styled(P)`
    margin: 0px;
    font-weight: bold;
    font-size: 20px;
`

const Text = styled(P)`
    margin: 0px;
    margin-top: 10px;
`

const GreenButton = styled(Button)`
    background-color: #45AE7C;
    border-color: #45AE7C;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    margin: 3px 0px;
    width: 100%;
    
    &:hover{
        background-color: #3a9168;
        border-color: #3a9168;
    }
`

const EditIcon = styled(FaEdit)`
    color: #FFFFFF;
    margin-right: 5px;
    font-size: 16px;
`

const TrashIcon = styled(FaTrashAlt)`
    color: #FFFFFF;
    margin-right: 5px;
    font-size: 16px;
`

const RedButton = styled(Button)`
    background-color: #DC393D;
    border-color: #DC393D;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    margin: 3px 0px;
    width: 100%;

    &:hover {
        background-color: #b02a2e;
        border-color: #b02a2e;
    }
`

const Flex = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
`

const FlexBetween = styled(Flex)`
    justify-content: space-between;
    align-items: center;
`

const FlexColumn = styled(Flex)`
    flex-direction: column;
`

function MyTemplate(props){
    const { t, i18n } = useTranslation()
    const [assignment, setAssignment] = useState(props.assignment)
    const deleteTemplate = () => {
        api.delete('/assignmentTemplate/' + assignment.id).then(
            props.update
        )
    }
    return(
        <TemplateCard>
            <FlexBetween>
            <FlexColumn>
            <Bold>{t('myTemplate.assignment')}: {props.assignment.title}</Bold>
            <Text>{props.assignment.description}</Text>
            </FlexColumn>
                <FlexColumn>
                    <StyledLink>
                        <StyledLink to= {{ pathname: "CreateMaterial/EditTemplate/" + props.assignment.id}}><GreenButton><EditIcon />{t('myDocument.adjust')}</GreenButton></StyledLink>
                    </StyledLink>
                    <RedButton onClick={deleteTemplate}><TrashIcon />{t('myDocument.delete')}</RedButton>
                </FlexColumn>
            </FlexBetween>
            <Flex>
                {props.assignment.tasks.map(task => <TaskInAssignmentSmall task={task} />)}
            </Flex>
        </TemplateCard>
    )
}

export default MyTemplate