import React, {useState} from "react"
import styled, { keyframes } from "styled-components"
import { Link } from 'react-router-dom'
import Button from '../components/styled/button'
import { observer } from "mobx-react"
import { P, H4 } from '../components/styled/typography'
import { FaTelegramPlane, FaEdit, FaTrashAlt } from "react-icons/fa"
import {api} from "../api"
import { withRouter } from "react-router-dom"
import { useTranslation } from "react-i18next"

const fadein = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`

const A = styled(Link)`
    text-decoration: none;
    width: 100%;
    margin: 5px 0px;

    @media (min-width: 980px){
        width: 49.5%;
    }
`

const StyledLink = styled(Link)`
    text-decoration: none;
`

const Wrapper = styled.div`
    background-color: #FFFFFF;
    box-sizing: border-box;
    width: 100%;
    padding: 20px;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 4px 4px rgba(197, 197, 197, 0.25);
    border-radius: 10px;
    height: auto;
    animation: ${fadein} 1s;

    @media(min-width: 980px){
        height: 250px;
    }
`

const Flex = styled.div`
    display: flex;
    flex-direction: column;

    @media(min-width: 980px){
        flex-direction: row;
        justify-content: space-between;
    }
`

const Title = styled(H4)`
    margin-top: 0px;
    margin-bottom: 0px;
    word-break: break-word;
`

const Author = styled(P)`
    font-style: italic;
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 16px;
`

const Text = styled(P)`
    margin-top: 10px;
    margin-bottom: 0px;
    word-break: break-word;
`

const Buttons = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-top: 15px;

    @media(min-width: 980px){
        margin-top: 0px;
        margin-left: 10px;
        justify-content: flex-start;
    }
`

const GreenButton = styled(Button)`
    background-color: #45AE7C;
    border-color: #45AE7C;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    margin: 3px 0px;
    width: 100%;

    &:hover{
        background-color: #3a9168;
        border-color: #3a9168;
    }
`

const PublishIcon = styled(FaTelegramPlane)`
    color: #FFFFFF;
    margin-right: 5px;
    font-size: 16px;
`

const EditIcon = styled(FaEdit)`
    color: #FFFFFF;
    margin-right: 5px;
    font-size: 16px;
`

const TrashIcon = styled(FaTrashAlt)`
    color: #FFFFFF;
    margin-right: 5px;
    font-size: 16px;
`

const PrimaryButton = styled(Button)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    margin: 3px 0px;
    width: 100%;
`

const RedButton = styled(Button)`
    background-color: #DC393D;
    border-color: #DC393D;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    margin: 3px 0px;
    width: 100%;

    &:hover {
        background-color: #b02a2e;
        border-color: #b02a2e;
    }
`

const Empty = styled.div`
    width: 1px;
    height: 1px;
`

const MyDocument = observer((props) => {
    const { t, i18n } = useTranslation()
    const [document, setDocument] = useState(props.document)
    const [showComponent, setShowComponent] = useState(true)

    const publish = () => {
        api.put('/document/' + document.id, {published : true}).then((response) => {
            setDocument(response.data.document)
            props.flash()
        })
    }

    const deleteDocument = () => {
        api.delete('/document/' + document.id).then(
            props.update()
        )
    }

    return(
        <A>
        <Wrapper>
            <Flex>
                <div>
                <Title primary>{document.title}</Title>
                <Author>{document.therapist_name} - {document.date_created.split(' ').slice(0, 4).join(' ')}</Author>
                <Text>{document.description}</Text>
                </div>
                <Buttons>
                    {!document.published &&
                        <PrimaryButton onClick={publish}>
                            <PublishIcon />{t('myDocument.publish')}<Empty />
                        </PrimaryButton>
                    }
                    <StyledLink to={{pathname: 'CreateMaterial/EditDocument/' + document.id}}>
                        <GreenButton>
                            <EditIcon />{t('myDocument.adjust')}<Empty />
                        </GreenButton>
                    </StyledLink>
                    <RedButton onClick={deleteDocument}>
                        <TrashIcon />{t('myDocument.delete')}<Empty />
                    </RedButton>
                </Buttons>
            </Flex>
        </Wrapper>
        </A>
    )
})

export default withRouter(MyDocument)