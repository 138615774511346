import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Li, Ul } from "../styled/list";
import Button from "../styled/button";
import { H3 } from "../styled/typography";
import RegistrationStore from "../registrationStore";
import PatientIconFemale from "../../assets/images/patient-icon.png";
import PatientIconMale from "../../assets/images/patient-icon-woman.png";

const Plan = styled.div`
    border: 1px solid #51c0cf;
    border-radius: 10px;
    width: 100%;
    margin-bottom: 15px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fff;
    padding-bottom: 25px;

    @media (min-width: 980px) {
        width: 48%;
        max-width: 375px;
        margin-bottom: 0px;
    }
`;

const PlanImage = styled.img`
    width: 100px;
    margin-top: 40px;
`;

const Price = styled(H3)`
    font-weight: 400;
    margin-top: 20px;
    margin-bottom: 0px;
`;

const Input = styled.select`
    background: #ffffff;
    border: 1px solid #51c0cf;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(197, 197, 197, 0.25);
    border-radius: 10px;
    padding: 10px 10px;
    margin-top: 5px;
    outline: none;
    width: 70%;
    font-size: 16px;
`;

const SubmitButton = styled(Button)`
    margin-top: 20px;
    margin-bottom: 40px;
    padding-right: 30px;
    padding-left: 30px;
    font-size: 16px;
    width: 70%;
`;

const PatientPlans = ({ setValue }) => {
    const { t, i18n } = useTranslation();
    const store = useContext(RegistrationStore);
    const user = JSON.parse(store.user);
    const [plan, setPlan] = useState("3");

    useEffect(() => {
        setValue(plan);
    }, [plan]);

    function CostPlanPatient(value) {
        setPlan(value);
        if (value === "3") {
            document.getElementById("pricePatient").innerHTML = "€ 39";
        } else if (value === "6") {
            document.getElementById("pricePatient").innerHTML = "€ 72";
        }
    }
    return (
        <>
            <Plan>
                <PlanImage src={user.sex === "Man" ? PatientIconMale : PatientIconFemale} />
                <Price id="pricePatient">€ 39</Price>
                <Ul>
                    <Li small>{t("registerForm3.therapy")}</Li>
                    <Li small>
                        {t("registerForm3.ex")} &amp; <br /> {t("registerForm3.res")}
                    </Li>
                    <Li small>{t("registerForm3.doc")}</Li>
                    <Li small>{t("registerForm3.com")}</Li>
                </Ul>
                <Input
                    name="patientPlan"
                    id="PlanSelectorPatient"
                    onClick={() => CostPlanPatient(document.getElementById("PlanSelectorPatient").value)}
                >
                    <option value="3">3 {t("registerForm3.months")}</option>
                    <option value="6">6 {t("registerForm3.months")}</option>
                </Input>
            </Plan>
        </>
    );
};

export default PatientPlans;
