import React from "react"
import Error from "../pages/error"
import * as Sentry from '@sentry/browser';

export const isProduction = process.env.NODE_ENV === 'production';

class ErrorHandler extends React.Component {
    constructor(props) {
      super(props)
      this.state = { errorOccurred: false }
    }
  
    componentDidCatch(err, description) {
      this.setState({ errorOccurred: true })
      if (!isProduction) {
        console.error(err, description)
      }
      Sentry.withScope(scope => {
        scope.setExtras(description);
        Sentry.captureException(err);
      })
    }
    render() {
      return this.state.errorOccurred ? <Error /> : this.props.children
    }
  }
 
export default ErrorHandler;