import React from "react";
import PageWrapper from "../components/styled/pageWrapper";
import Global from "../components/styled/global";
import TherapistOverlay from "../components/overlays/therapistOverlay";
import styled from "styled-components";

const Container = styled.div`
  position: relative;
  overflow: hidden;
  margin-top: 75px;
  height: calc(100vh - 100px);
  @media (min-width: 980px) {
    width: calc(100vw - 250px);
  }
`;

const V2Frame = () => {
  let language = window.localStorage.getItem("language") === null ? "nl" : window.localStorage.getItem("language");
  let userId = JSON.parse(window.localStorage.getItem("user")).public_id;
  let token = window.localStorage.getItem("token").toString();

  const hideLoading = () => (document.getElementById("loading").style.display = "none");

  return (
    <Global>
      <PageWrapper>
        <TherapistOverlay />
        <Container>
          {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
          <div id="loading">Loading...</div>
          {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
          <iframe
            width="100%"
            height="100%"
            frameBorder="0"
            src={`${process.env.REACT_APP_V2_URL}?token=${token}&user=${userId}&lang=${language}`}
            onLoad={hideLoading}
          />
        </Container>
      </PageWrapper>
    </Global>
  );
};

export default V2Frame;
