import React,{ useCallback, useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import { Redirect } from "react-router-dom";
import Button from '../styled/button';
import { api } from "../../api";
import { Switch } from "../styled/switch";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import FileSaver from "file-saver";
import LoadSpinner from '../loadspinner';
import TagSelect from "../helperComponents/tagSelect";
import { Languages } from '../resources/languages';
import { getResourceUrl } from '../../azure';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const FileEditFormStyle = styled(Form)`
`

const FormGroup = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
`

const Input = styled(Field)`
    background: #FFFFFF;
    border: 1px solid #51C0CF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(197, 197, 197, 0.25);
    border-radius: 10px;
    padding: 10px 10px;
    margin-top: 5px;
    outline: none;
    font-size: 16px;

    &:disabled{
        //background: #EEEEEE;
        //border: 1px solid #B0C1C4;
        opacity: 0.5;
    }
`

const Label = styled.label`
    font-size: 16px;
    font-weight: 600;
    color: #545454;
`

const SubmitButton = styled(Button)`
    margin-top: 20px;
    padding-right: 45px;
    padding-left: 45px;
    font-size: 18px;
`

const SmallButton = styled(Button)`
  margin-top: 5px;
  width: fit-content;
`;

const Center = styled.div`
    text-align: center;
`

const Img = styled.img`
    width: 80%;

    @media (min-width: 980px) {
        width: 40%;
    }
`;

const Error = styled.p`
    font-size: 16px;
    color: #AFAFAF;
    font-style: italic;
`

const Video = styled.video`
    width: 80%;
    margin-bottom: 10px;
`;

const Audio = styled.audio`
    max-width: 80%;
    margin-top: 20px;
    margin-bottom: 20px;
`;

const FileEditForm = observer((props) => {
    const { t, i18n } = useTranslation();
    const [tags, setTags] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filePreview, setFilePreview] = useState('');
    const [redirect, setRedirect] = useState(false);
    const [fileUrl, setFileUrl] = useState("");

    useEffect(() => {
        setLoading(true);
        getResourceUrl(props.file).then((url) => {
            setFileUrl(url);
            getFilePreview(url);
            setLoading(false);
        });
    }, []);

    const downloadOneFile = async (file_url) => {
        FileSaver.saveAs(file_url, props.file.resource_name);
    }

    const getFilePreview = (file_url) =>{
        switch (props.file.type) {
            case "image":
                setFilePreview(<Img src={file_url}/>);
                break;
            case "audio":
                setFilePreview(<Audio controls><source src={file_url} type="audio/wav; base64"/></Audio>);
                break;
            case "video":
                setFilePreview(<Video controls controlsList="nodownload"><source src={file_url} type="video/mp4; base64"/></Video>);
                break;
            default:
                setFilePreview(null);
                break;
        }
    }

    const postFile = async (values, actions) => {
        setLoading(true);
        //Add type as tag
        //let tempTags = tags;
        //if (!tempTags.includes(type)) tempTags.push(type);
        //if (!tempTags.includes('aangepast')) tempTags.push('aangepast');

        api.put('/file/' + props.file.id, {
            "tags": tags,
            "name": values.fileName,
            "description": values.description,
            "published": values.published,
            "language": values.language
        }).then((res) => {
            actions.setSubmitting(false);
            setLoading(false);
            setRedirect(true);
        })
    }

    return (<div>
        {loading ? <LoadSpinner /> :
        <Formik initialValues={{
                    tags: props.file.tags,
                    fileName: props.file.name,
                    description: props.file.description,
                    published: props.file.published,
                    language: props.file.langauge
                }} 
                onSubmit={postFile}
                render={({errors,touched, values, isSubmitting, isValid}) => (
                    <FileEditFormStyle>
                        <FormGroup>
                            <Label htmlFor="fileName">{t('fileForm.name')}</Label>
                            <Input type="text" id="fileName" value={values.fileName} placeholder={t('fileForm.inputName')} />
                            { touched.fileName && errors.fileName && <Error>{errors.fileName}</Error> }
                        </FormGroup>
                        <FormGroup>
                            <Label>{t('fileForm.description')}</Label>
                            <CKEditor
                                id="description"
                                name="description"
                                editor={ ClassicEditor }
                                data={props.file.description}
                                onChange={ ( event, editor ) => {
                                    values.description = editor.getData();
                                }}
                                config={{
                                    placeholder: t('fileForm.inputDescription'),
                                    toolbar: ['heading', '|', 'bold', 'italic', 'blockQuote', 'link', 'numberedList', 'bulletedList', 'insertTable', 'tableColumn', 'tableRow', 'mergeTableCells',  '|', 'undo', 'redo'],
                                    cloudServices: {tokenUrl : process.env.REACT_APP_COBRAND_API + "/editor_token/", uploadUrl : "https://55482.cke-cs.com/easyimage/upload/"}
                                }}
                                onError={ ( { willEditorRestart } ) => {
                                    // If the editor is restarted, the toolbar element will be created once again.
                                    // The `onReady` callback will be called again and the new toolbar will be added.
                                    // This is why you need to remove the older toolbar.
                                    if ( willEditorRestart ) {
                                        this.editor.ui.view.toolbar.element.remove();
                                    }
                                } }
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="tagInput">{t('fileForm.tags')}</Label>
                            <TagSelect setTags={setTags} tags={props.file.tags} />
                        </FormGroup>
                        <FormGroup>
                            <Label>{t('documentEditForm.language')}</Label>
                            <Input component="select" name="language">
                                <option value="">...</option>
                                <Languages default={i18n.language} />
                            </Input>
                            { touched.language && errors.language && <Error>{errors.language}</Error> }
                        </FormGroup>
                        <FormGroup>
                            <Label>{t('fileForm.preview')}</Label>
                            {filePreview ?? <>
                                <span>{t("fileForm.noPreview")}</span>
                                <SmallButton type="button" small onClick={() => downloadOneFile(fileUrl)}>
                                    {t("mediaDetail.download")}
                                </SmallButton>
                            </>}
                        </FormGroup>
                        <Switch name="published" label={t("fileForm.publish")} id="published"/>
                        <Center>
                            <SubmitButton
                                type='submit'
                                disabled={isValid && !isSubmitting ? null : 'disabled'}
                            >
                                {t('fileAanpassen.config')}
                            </SubmitButton>
                        </Center>
                        {redirect && <Redirect to="/t/CreateMaterial"/>}
                    </FileEditFormStyle>
                )}
                validationSchema={Yup.object().shape({
                    fileName: Yup.string().required(),
                    language: Yup.string().required(),
                    description: Yup.string().notRequired(),
                    published: Yup.string().required()
                })}
        
        />}
        </div>)
})

export default FileEditForm