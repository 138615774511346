
const calculateCorrelation = require("calculate-correlation");

export const autoCorrelateHnr = (buf, sampleRate) => {
    // Implements the ACF2+ algorithm
    let SIZE = buf.length;
    let rms = 0;


    for (let i = 0; i < SIZE; i++) {
      const val = buf[i];
      rms += val * val;
    }
    rms = Math.sqrt(rms / SIZE);
    if (rms < 0.01){ // not enough signal
      return 0;
    }

    //TRIM
    let r1 = 0, r2 = SIZE - 1, thres = 0.2;
    for (let i = 0; i < SIZE / 2; i++){
      if (Math.abs(buf[i]) < thres) { 
        r1 = i; 
        break; 
      }
    }
    for (let i = 1; i < SIZE / 2; i++){
      if (Math.abs(buf[SIZE - i]) < thres) { 
        r2 = SIZE - i; 
        break; 
      }
    }

  
    buf = buf.slice(r1, r2);
    SIZE = buf.length;

    //AUTO-CORRELATION
    //i is lag
    //j loops through sample
   const c = new Array(SIZE).fill(0);
    for (let i = 0; i < SIZE; i++){
      for (let j = 0; j < SIZE - i; j++){

        c[i] = c[i] + buf[j] * buf[j + i];
      }
    }
  
    //FIND FIRST DIP
    let d = 0; 
    while (c[d] > c[d + 1]) {
      d++;
    }
    
    //FIND MAX
    let maxval = -1, maxpos = -1;
    for (let i = d; i < SIZE; i++) {
      if (c[i] > maxval) {
        maxval = c[i];
        maxpos = i;
      }
    }
    let T0 = maxpos;

    const newbuf = new Array(SIZE-T0).fill(0);
    for (let i = 0; i < SIZE-T0; i++){
        newbuf[i] = buf[i]
     }

    const newbufT = new Array(SIZE-T0).fill(0);
      for (let i = 0; i < SIZE-T0; i++){
        newbufT[i] = buf[T0 + i]
     }

    const config = {
      string: true,
      decimals: 100,
    };
     const correlation = calculateCorrelation(newbuf, newbufT,config);

     let hnr = 10 * Math.log10( correlation / (1 - correlation))

    return hnr
}
