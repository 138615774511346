import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import TherapistOverlay from "../../components/overlays/therapistOverlay";
import Global from "../../components/styled/global";
import { PageTitle } from "../../components/styled/typography";
import PageWrapper from "../../components/styled/pageWrapper";
import Article from "../../components/article";
import Author from "../../components/author";
import { observer } from "mobx-react";
import registrationStore from "../../components/registrationStore";
import { api } from "../../api";
import LoadSpinner from "../../components/loadspinner";
import { PageAnimation } from "../../components/styled/animation";
import { useTranslation } from "react-i18next";

const Flex = styled.div`
    display: flex;
    flex-direction: column;

    @media (min-width: 980px) {
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
    }
`;

const MateriaalDetail = observer((props) => {
    const { t, i18n } = useTranslation();
    const [document, setDocument] = useState({});
    const { id } = props.match.params;
    const store = useContext(registrationStore);
    const user = JSON.parse(store.user);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        api.get("/document/" + id).then((response) => {
            setDocument(response.data.document);
            setLoading(false);
        });
    }, []);
    return (
        <Global>
            <PageWrapper>
                <TherapistOverlay />
                <PageAnimation>
                    <PageTitle black>{t("sidebarPatient.material")}</PageTitle>
                    {loading ? (
                        <LoadSpinner />
                    ) : (
                        <Flex>
                            <Article title={document.title} content={document.content} />
                            <Author public_id={document.therapist_id} />
                        </Flex>
                    )}
                </PageAnimation>
            </PageWrapper>
        </Global>
    );
});

export default MateriaalDetail;
