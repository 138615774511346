import React, {useState} from "react";
import styled from "styled-components";
import { Field } from "formik";

const Wrapper = styled.div`
    display: flex;
    align-items: flex-end;
    margin: 5px 0px;
`;

const SwitchLabel = styled.label`
    position: relative;
    display: inline-block;
    width: 47px;
    height: 20px;
    margin-top: 5px;
`;

const Label = styled.label`
    margin: 0px;
    display: inline-block;
    margin-left: 10px;
`;

const Slider = styled.div`
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;

    &:before {
        position: absolute;
        content: "";
        height: 13px;
        width: 13px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }
`;

const RoundSlider = styled(Slider)`
    border-radius: 34px;
    &:before {
        border-radius: 50%;
    }
`;

const SwitchInput = styled(Field)`
    display: none;

    &:checked + ${Slider} {
        background-color: #51c0cf;
    }

    &:focus + ${Slider} {
        box-shadow: 0 0 1px #51c0cf;
    }

    &:checked + ${Slider}:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }
`;

export const Switch = (props) => {
    return (<>
        <Wrapper>
            <SwitchLabel>
                <SwitchInput 
                    aria-label={props.name} 
                    id={props.name} 
                    type="checkbox" 
                    name={props.name}
                />
                <RoundSlider></RoundSlider>
            </SwitchLabel>
            {props.children ? document.getElementById(props.name)?.checked && props.children : null}
            <Label htmlFor={props.name} >{props.label}</Label>
        </Wrapper>
    </>);
};