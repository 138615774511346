import { api } from "../../api";
import { uuid } from "uuidv4";
import dateConverter from "./dateConverter";

export default async function createBertie(therapist_id, name = "Bertie") {
    const dummyUser = {
        email: `${uuid()}@gmail.com`,
        password: "!Ya7:W6CD<gs;:S",
        firstName: name,
        lastName: " ",
        sex: "Bertie",
        therapist: false,
        paid: false,
        plan: "Patiënt",
        plan_period: 100,
        verified: true,
        TwoFAEnabled: false,
    };

    const dummyDetails = {
        medicalDiagnosis: "Noduli vocales",
        therapyPlan: `hoge AH, spreekt op reservelucht; \nSpreektempo is snel, spreekt hypertoon; \nperceptie: G2R1B2 (A0 S2)`,
        paramedicalDiagnosis: "functionele dysfonie door organisch lijden",
        helpRequest:
            "Sinds enkele maanden ontwikkelde ze progressief heesheid. Recent kost het spreken meer moeite en is ze ook algemeen vermoeidheid na een dag lesgeven. Ze kan zich niet meer verstaanbaar maken in luidruchtige omgeving. Kan niet meer zingen. Ze krijgt pijn aan de keel bij performance.",
        history: "stembandknobbeltjes als kind waarvoor logopedie, geen heelkunde",
        personality:
            "Beroep: kleuterleidster/ Hobby’s: toneel, musical; ze is altijd al een-spring-in-het-veld geweest, zeer dynamisch persoon; Vrouw 32 jaar",
    };

    let patient = await api.post("/user", dummyUser);
    let patientLink = await api.post(`/linkPatient/${patient.data.id}`, { therapist_id: therapist_id });
    let traject = await api.post("/traject", {
        user_id: patient.data.id,
        therapist_id: therapist_id,
        title: "Traject",
    });
    api.patch(`/user/details/${patient.data.id}`, dummyDetails);
    // Vragenlijsten
    api.post("/assignment", {
        user_id: patient.data.id,
        therapist_id: therapist_id,
        title: "QOL en zelfbeoordeling",
        tasks: [
            {
                id: 0,
                title: "VHI volw ",
                tasktype: "oefening",
                task: "40hntiTmjoQFohqSz2xoTU",
                total: 1,
                dates: [{ date: new Date(), frequency: 1 }],
                description: "",
            },
        ],
        category: "research_questions",
        traject_id: traject.data.traject_id,
    });
    // Testen
    api.post("/assignment", {
        user_id: patient.data.id,
        therapist_id: therapist_id,
        title: "Het stemonderzoek",
        tasks: [
            {
                id: 0,
                title: "MFT A1",
                tasktype: "oefening",
                task: "7NXlmx58UMJLLylFtmVtWF",
                total: 1,
                dates: [{ date: new Date(), frequency: 1 }],
                description: "",
            },
            {
                id: 1,
                title: " MFT A2",
                tasktype: "oefening",
                task: "0Y00gfj9k509KTSi0cq1i0",
                total: 1,
                dates: [{ date: new Date(), frequency: 1 }],
                description: "",
            },
            {
                id: 2,
                title: " MFT A3",
                tasktype: "oefening",
                task: "0Y00gfj9k509KTSi0cq3mK",
                total: 1,
                dates: [{ date: new Date(), frequency: 1 }],
                description: "",
            },
            {
                id: 3,
                title: " S/Z met webcam",
                tasktype: "oefening",
                task: "twCbctRZNezQBHVr93YnBk",
                total: 1,
                dates: [{ date: new Date(), frequency: 1 }],
                description: "",
            },
            {
                id: 4,
                title: "aa/AA/zin",
                tasktype: "oefening",
                task: "45ebd739-b8ac-4b99-84f8-ceb4656849a5",
                total: 1,
                dates: [{ date: new Date(), frequency: 1 }],
                description: "",
            },
            {
                id: 5,
                title: "Papa en Marloes",
                tasktype: "oefening",
                task: "twCbctRZNezQBHVr93aum5",
                total: 1,
                dates: [{ date: new Date(), frequency: 1 }],
                description: "",
            },
        ],
        category: "research_tests",
        traject_id: traject.data.traject_id,
    });
    // Behandeltraject - Informatie/educatie
    api.post("/assignment", {
        user_id: patient.data.id,
        therapist_id: therapist_id,
        title: "Wat je moet weten over de stem",
        tasks: [
            {
                id: 0,
                title: "Stemhygiëne",
                tasktype: "document",
                task: 133,
                total: 1,
                dates: [{ date: new Date(), frequency: 1 }],
                description: "",
            },
        ],
        category: "treatment_info_education",
        traject_id: traject.data.traject_id,
    });
    // Behandeltraject - Bewustwording
    api.post("/assignment", {
        user_id: patient.data.id,
        therapist_id: therapist_id,
        title: "Ademhaling en houding",
        tasks: [
            {
                id: 0,
                title: "hoe adem ik",
                tasktype: "oefening",
                task: "OKW8zNDllfrWZZDk9l2foo",
                total: 1,
                dates: [{ date: new Date(), frequency: 1 }],
                description: "",
            },
        ],
        category: "treatment_awareness",
        traject_id: traject.data.traject_id,
    });
    // Behandeltraject - Training
    api.post("/assignment", {
        user_id: patient.data.id,
        therapist_id: therapist_id,
        title: "Ontspannen stem geven",
        tasks: [
            {
                id: 0,
                title: "LaxVox basis",
                tasktype: "oefening",
                task: "5eccd917-0afc-46b0-a35c-4b1b4c6d327f",
                total: 1,
                dates: [{ date: new Date(), frequency: 1 }],
                description: "",
            },
            {
                id: 1,
                title: "LaxVox klank&woord",
                tasktype: "oefening",
                task: "U6fNb9drJDpo4tiO3ATIq7",
                total: 1,
                dates: [{ date: new Date(), frequency: 1 }],
                description: "",
            },
            {
                id: 2,
                title: "VocTract openen",
                tasktype: "oefening",
                task: "rYzRBNi73HK0SjhCrSJ25m",
                total: 1,
                dates: [{ date: new Date(), frequency: 1 }],
                description: "",
            },
        ],
        category: "treatment_training",
        traject_id: traject.data.traject_id,
    });
    // Behandeltraject - Generalisatie
    api.post("/assignment", {
        user_id: patient.data.id,
        therapist_id: therapist_id,
        title: "Waarop ik moet letten",
        tasks: [
            {
                id: 0,
                title: "VHI volw random",
                tasktype: "oefening",
                task: "twCbctRZNezQBHVr8mSlOv",
                total: 1,
                dates: [{ date: new Date(), frequency: 1 }],
                description: "",
            },
            {
                id: 1,
                title: "de Do's en Don'ts voor de stem",
                tasktype: "document",
                task: 126,
                total: 1,
                dates: [{ date: new Date(), frequency: 1 }],
                description: "",
            },
            {
                id: 2,
                title: "Vocal Fatigue Index",
                tasktype: "oefening",
                task: "70faec99-4d7d-4587-b093-e91bd94b3af9",
                total: 1,
                dates: [{ date: new Date(), frequency: 1 }],
                description: "",
            },
            {
                id: 3,
                title: "Vocal Function ex",
                tasktype: "oefening",
                task: "ecf121a3-95e7-4d39-916c-ceab7f65d1eb",
                total: 1,
                dates: [{ date: new Date(), frequency: 1 }],
                description: "",
            },
        ],
        category: "treatment_generalisation",
        traject_id: traject.data.traject_id,
    });
    // Behandeltraject - Automatisatie
    api.post("/assignment", {
        user_id: patient.data.id,
        therapist_id: therapist_id,
        title: "Af en toe een reminder",
        tasks: [
            {
                id: 0,
                title: "VHI volw random",
                tasktype: "oefening",
                task: "twCbctRZNezQBHVr8mSlOv",
                total: 1,
                dates: [{ date: new Date(), frequency: 1 }],
                description: "",
            },
            {
                id: 1,
                title: "zachte inzet",
                tasktype: "oefening",
                task: "pNTE3777l60h2m6aoY4luo",
                total: 1,
                dates: [{ date: new Date(), frequency: 1 }],
                description: "",
            },
            {
                id: 2,
                title: "de Do's en Don'ts voor de stem",
                tasktype: "document",
                task: 126,
                total: 1,
                dates: [{ date: new Date(), frequency: 1 }],
                description: "",
            },
        ],
        category: "treatment_automatisation",
        traject_id: traject.data.traject_id,
    });
}

// Nog info aankrijgen van Mieke
export async function createBertieAfasie(therapist_id, name) {
    const dummyUser = {
        email: `${uuid()}@gmail.com`,
        password: "!Ya7:W6CD<gs;:S",
        firstName: name,
        lastName: "Afasie",
        sex: "Bertie",
        therapist: false,
        paid: false,
        plan: "Patiënt",
        plan_period: 100,
        verified: true,
        TwoFAEnabled: false,
    };

    const dummyDetails = {
        medicalDiagnosis: "hersenbloeding (li -insula) -> rechter hemiparese + afasie van Broca.",
        therapyPlan: `
    1)	Voorstel Aanpak: Behandelen op (functie)niveau is gericht op herstel van de taalfuncties: visueel en auditief semantische therapie.-> training woordenschat, woordvormen, ritmisch lezen
    2)	De behandeling op activiteiten- en participatieniveau gericht op het omgaan met de beperkingen en het verminderen van de gevolgen van de afasie op het persoonlijke leven van de cliënt en diens omgeving: ->  taalzakboek, itemspecifieke en situatiespecifieke training`,
        paramedicalDiagnosis: `vloeiendheidsstoornis, woordvindingsstoornis, deleties, substituties zowel in spreken als schrijven (+ re hemiparese!). Mr spreekt in telegramstijl + begripsstoornis bij toenemende complexiteit
    Bewust van het probleem -> maakt zich vaak boos. 
    Re hemiplegie
        `,
        helpRequest: `Dd 15 januari: verwijzing via neuroloog 
    Vorig weekend opname op spoed omwille van verlamming rechter arm en been en spraakstoornissen. -> NMR: infarct li insula. Nu op de kamer. Opstart kine & logo
    Patiënt Wil zelfstandig zijn 
    -> 1) herstel: communicatie & spraak herstel / optimaliseren tav directe omgeving en in 2e instantie derden 
    -> 2) ondersteuning`,
        history: "Nooit echt ziek geweest, wel altijd stress, roken 30 py, Bourgondisch leven zeker op zakenreis",
        personality: `Man, 74jaar
    Beroep: gepensioneerd Burgerlijk Ingenieur, internationaal bedrijf voor klimatisatie
    Hobby’s: architectuur, tuinieren 
    Persoonlijkheid: rustig en berekend, vaak op zichzelf, controlefreak. Stressgevoelig. Maakt zich boos (frustratie) door huidig communicatieprobleem`,
    };

    let patient = await api.post("/user", dummyUser);
    let patientLink = await api.post(`/linkPatient/${patient.data.id}`, { therapist_id: therapist_id });
    let traject = await api.post("/traject", {
        user_id: patient.data.id,
        therapist_id: therapist_id,
        title: "Traject",
    });
    api.patch(`/user/details/${patient.data.id}`, dummyDetails);
}

export async function createBertieDysfonie(therapist_id, name) {
    const dummyUser = {
        email: `${uuid()}@gmail.com`,
        password: "!Ya7:W6CD<gs;:S",
        firstName: name,
        lastName: "Dysfonie",
        sex: "Bertie",
        therapist: false,
        paid: false,
        plan: "Patiënt",
        plan_period: 100,
        verified: true,
        TwoFAEnabled: false,
    };

    const dummyDetails = {
        medicalDiagnosis: `25/01: Dysfonie van organische oorzaak (paralyse e causa ignota)
    2/02: KNO onderzoek visualisatie laryx: linker stemplooiparalyse in abductiestand, e causa ignota`,
        therapyPlan: `1) functieherstel -> ademhalingstraining, adductie- en stootoefening, anterieure klankprojectie. Indien onvolledig resultaat: opnieuw doorverwijzen voor fonochirurgie (medialisatie)
    2) Participatie & activiteit -> geen les in grote ruimtes gedurende 1maand (evtl vervangen door admin)
    10/2 eerste sessie: start AH/Houding/Relaxatie`,
        paramedicalDiagnosis: `25/01:
    Hoog thoracaal ademhalingspatroon 
    Verkorte zinslengte
    Spreektempo is snel, 
    spreekt licht hypertoon
    perceptie: G2R0B2 (A0 S1)`,
        helpRequest: `25/01: spontane aanmelding omwille van plots stemverlies, zonder aanwijzing. Wil meer draagkracht in de stem en heeft last van kortademigheid. Wil kunnen werken (leerkracht Lich Opv)`,
        history: "Stemplooi knobbeltjes als kind, waarvoor logo. Geen heelkunde",
        personality: `vrouw, 25j, gezond
    Beroep: leerkracht Lichamelijke Opvoeding
    Hobby: sport 
    Persoonlijkheid: evenwichtig, extrovert.`,
    };

    let patient = await api.post("/user", dummyUser);
    let patientLink = await api.post(`/linkPatient/${patient.data.id}`, { therapist_id: therapist_id });
    let traject = await api.post("/traject", {
        user_id: patient.data.id,
        therapist_id: therapist_id,
        title: "Traject",
    });
    api.patch(`/user/details/${patient.data.id}`, dummyDetails);
    // Vragenlijsten
    api.post("/assignment", {
        user_id: patient.data.id,
        therapist_id: therapist_id,
        title: "Vragenlijst",
        tasks: [
            {
                id: 0,
                title: "Vocal Fatigue Index",
                tasktype: "dagboek",
                task: "70faec99-4d7d-4587-b093-e91bd94b3af9",
                total: 1,
                dates: [{ date: new Date(), frequency: 1 }],
                description: "",
            },
            {
                id: 1,
                title: "VHI volw random",
                tasktype: "dagboek",
                task: "twCbctRZNezQBHVr8mSlOv",
                total: 1,
                dates: [{ date: new Date(), frequency: 1 }],
                description: "",
            },
        ],
        category: "research_questions",
        traject_id: traject.data.traject_id,
    });
    // Testen
    api.post("/assignment", {
        user_id: patient.data.id,
        therapist_id: therapist_id,
        title: "Testen",
        tasks: [
            {
                id: 0,
                title: "MFT A1",
                tasktype: "oefening",
                task: "7NXlmx58UMJLLylFtmVtWF",
                total: 1,
                dates: [{ date: new Date(), frequency: 1 }],
                description: "",
            },
            {
                id: 1,
                title: " MFT A2",
                tasktype: "oefening",
                task: "0Y00gfj9k509KTSi0cq1i0",
                total: 1,
                dates: [{ date: new Date(), frequency: 1 }],
                description: "",
            },
            {
                id: 2,
                title: " MFT A3",
                tasktype: "oefening",
                task: "0Y00gfj9k509KTSi0cq3mK",
                total: 1,
                dates: [{ date: new Date(), frequency: 1 }],
                description: "",
            },
            {
                id: 3,
                title: " S/Z met webcam",
                tasktype: "oefening",
                task: "twCbctRZNezQBHVr93YnBk",
                total: 1,
                dates: [{ date: new Date(), frequency: 1 }],
                description: "",
            },
            {
                id: 4,
                title: "aa/AA/zin",
                tasktype: "oefening",
                task: "45ebd739-b8ac-4b99-84f8-ceb4656849a5",
                total: 1,
                dates: [{ date: new Date(), frequency: 1 }],
                description: "",
            },
            {
                id: 5,
                title: "Papa en Marloes",
                tasktype: "oefening",
                task: "twCbctRZNezQBHVr93aum5",
                total: 1,
                dates: [{ date: new Date(), frequency: 1 }],
                description: "",
            },
        ],
        category: "research_tests",
        traject_id: traject.data.traject_id,
    });
    // Behandeltraject - Bewustwording
    api.post("/assignment", {
        user_id: patient.data.id,
        therapist_id: therapist_id,
        title: "Bewustwording",
        tasks: [
            {
                id: 0,
                title: "hoe adem ik",
                tasktype: "oefening",
                task: "OKW8zNDllfrWZZDk9l2foo",
                total: 1,
                dates: [{ date: new Date(), frequency: 1 }],
                description: "",
            },
        ],
        category: "treatment_awareness",
        traject_id: traject.data.traject_id,
    });
    // Behandeltraject - Training
    api.post("/assignment", {
        user_id: patient.data.id,
        therapist_id: therapist_id,
        title: "Training",
        tasks: [
            {
                id: 0,
                title: "ademsteun",
                tasktype: "stappenplan",
                task: "WykEogMRnpxd6DHVia5vsx",
                total: 1,
                dates: [{ date: new Date(), frequency: 1 }],
                description: "",
            },
            {
                id: 1,
                title: "stootoefening1",
                tasktype: "stappenplan",
                task: "LgFk3sbs3o1mYFx7b7aBYe",
                total: 1,
                dates: [{ date: new Date(), frequency: 1 }],
                description: "",
            },
        ],
        category: "treatment_training",
        traject_id: traject.data.traject_id,
    });
}

export async function createBertieDysfagie(therapist_id, name) {
    const dummyUser = {
        email: `${uuid()}@gmail.com`,
        password: "!Ya7:W6CD<gs;:S",
        firstName: name,
        lastName: "Dysfagie",
        sex: "Bertie",
        therapist: false,
        paid: false,
        plan: "Patiënt",
        plan_period: 100,
        verified: true,
        TwoFAEnabled: false,
    };

    const dummyDetails = {
        medicalDiagnosis: `Dysfagie van organische oorzaak tgv heelkunde & radiochemo. 
    Gestoorde orale fase met bemoeilijkte boluscontrole, vertraagde onset faryngeale fase
    Articulatiestoornis
    Dysfonie van organische oorzaak`,
        therapyPlan: `1)	Aanpak: functieherstel -> tong mobiliteit, orale controle, sliktrigger, versterken farynxspieren, larynxheffing, pittigheidsarticulatie, stemplooitrilling versoepelen, klankprojectie
    2)	Participatie & activiteit -> aanpassen voedingsconsistentie, hulpaccessoires (vb micro) indien nodig`,
        paramedicalDiagnosis: `Mond: Het uitsteken van de tong gebeurt niet volledig. Het lijkt alsof de tong links achteraan wat vast zit. De flap neemt teveel volume in. Verminderd bolustransport en vertraagde faryngale onset (minder gevoelige trigger?) Gebrek aan pittigheid in de articulatie. Monddroogte: +++
    Hals: litteken halsuitruiming links. Hoofdbeweeglijkheid: beperkte rotatie naar rechts (links trekt tegen). Flexie OK. Bij slikken is er een beperkte larynxheffing
    Stem: G2R1B2A1S1`,
        helpRequest: `Dd 11 jan 2021: Op doorverwijzing van de medisch oncoloog 
    Kan sinds de behandeling moeilijk slikken. Monddroogte. Kan ook moeilijk koekjes eten. Moet overal veel vocht bij doen. Durft zich wel eens te verslikken bij (snel) drinken. Alles heeft een andere smaak. Wil volwaardige voeding kunnen eten & op een tempo zoals de anderen
    Ook moeilijk spreken: beperkte articulatie, De stem klinkt ook anders.`,
        history: `8 november 2019 resectie li tongbasis en reconstructie met FRFF (voorarmflap) + halsevidement voor T2N2bM0 tongbasiscarcinoma links. + 9 dec start postop radio-chemotherapie. Daarbij is vorig jaar een stuk van de linker tong weggenomen, zijn de klieren in de linker hals weggehaald en werd hij na de operatie bestraald.
    Behalve maagzweer geen specifieke antecedenten. Gebruikt regelmatig alcohol, 2E/dag en in weekend duidelijk meer. De laatste tijd stress omwille van scheiding van zijn dochter en kan dan wel meer drinken. Roken: anderhalf pakje /dag sinds 30 jaar, sinds vorig jaar gestopt. `,
        personality: `man 63 jaar
    Beroep: hogere functie in een internationale bank instelling
    Hobby’s: culinaire geneugten, vroeger nog veel gefietst
    Persoonlijkheid: altijd al een dynamisch persoon geweest, een beetje hyper, opvliegend, nerveus`,
    };

    let patient = await api.post("/user", dummyUser);
    let patientLink = await api.post(`/linkPatient/${patient.data.id}`, { therapist_id: therapist_id });
    let traject = await api.post("/traject", {
        user_id: patient.data.id,
        therapist_id: therapist_id,
        title: "Traject",
    });
    api.patch(`/user/details/${patient.data.id}`, dummyDetails);
    // Vragenlijsten
    api.post("/assignment", {
        user_id: patient.data.id,
        therapist_id: therapist_id,
        title: "Vragenlijst",
        tasks: [
            {
                id: 0,
                title: "DHI random",
                tasktype: "dagboek",
                task: "faf85eca-0575-4679-988d-6a84bcb43f0a",
                total: 1,
                dates: [{ date: new Date(), frequency: 1 }],
                description: "",
            },
            {
                id: 1,
                title: "EAT-10",
                tasktype: "dagboek",
                task: "40hntiTmjoQFohqSz2xLEw",
                total: 1,
                dates: [{ date: new Date(), frequency: 1 }],
                description: "",
            },
        ],
        category: "research_questions",
        traject_id: traject.data.traject_id,
    });
    // Behandeltraject - Training
    api.post("/assignment", {
        user_id: patient.data.id,
        therapist_id: therapist_id,
        title: "Training",
        tasks: [
            {
                id: 0,
                title: "ORALMOT/lippen",
                tasktype: "stappenplan",
                task: "KGX4urdh7UF2Vlz88ybSaQ",
                total: 1,
                dates: [{ date: new Date(), frequency: 1 }],
                description: "",
            },
            {
                id: 1,
                title: "Shaker-isometrisch",
                tasktype: "stappenplan",
                task: "To8GgA5EoQcWHALXjQaYxk",
                total: 1,
                dates: [{ date: new Date(), frequency: 1 }],
                description: "",
            },
            {
                id: 2,
                title: "Shaker-isokinetisch",
                tasktype: "stappenplan",
                task: "To8GgA5EoQcWHALXjQcDYi",
                total: 1,
                dates: [{ date: new Date(), frequency: 1 }],
                description: "",
            },
            {
                id: 3,
                title: "Masako",
                tasktype: "stappenplan",
                task: "To8GgA5EoQcWHALXjQRgwS",
                total: 1,
                dates: [{ date: new Date(), frequency: 1 }],
                description: "",
            },
        ],
        category: "treatment_training",
        traject_id: traject.data.traject_id,
    });
}

export async function createBertieEmpty(therapist_id, name) {
    const dummyUser = {
        email: `${uuid()}@gmail.com`,
        password: "!Ya7:W6CD<gs;:S",
        firstName: name,
        lastName: "",
        sex: "Bertie",
        therapist: false,
        paid: false,
        plan: "Patiënt",
        plan_period: 100,
        verified: true,
        TwoFAEnabled: false,
    };

    const dummyDetails = {
        medicalDiagnosis: ``,
        therapyPlan: ``,
        paramedicalDiagnosis: ``,
        helpRequest: ``,
        history: ``,
        personality: ``,
    };

    let patient = await api.post("/user", dummyUser);
    await api.post(`/linkPatient/${patient.data.id}`, { therapist_id: therapist_id });
    await api.post("/traject", {
        user_id: patient.data.id,
        therapist_id: therapist_id,
        title: "Traject",
    });
    api.patch(`/user/details/${patient.data.id}`, dummyDetails);
}
