import React, { useState } from "react";

import Container from "../components/styled/container";
import { TelLink, MenuLink } from "../components/styled/link";
import Button from "../components/styled/button";
import { Link } from "react-router-dom";

import TelephonLogo from "../assets/images/telephon-logo.png";
import LanguageSelector from "../components/languageSelector";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

const Navbar = styled.div`
    background-color: #51c0cf;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 20px;
`;

const RegisterLink = styled(Link)`
    text-decoration: none;
    color: #51c0cf;
    padding: 20px;
    font-size: 20px;

    @media (min-width: 980px) {
        color: #ffffff;
        font-size: 16px;
        font-weight: normal;
        text-transform: capitalize;
        padding: 0px 10px;
    }
`;

const Menu = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    visibility: hidden;
    z-index: 99 !important;

    &.show {
        visibility: visible;
    }

    @media (min-width: 980px) {
        display: block;
        margin-left: auto;
        position: static;
        visibility: visible;
    }
`;

const MenuNav = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    overflow: hidden;
    background: #ffffff;

    @media (min-width: 980px) {
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        padding-left: 0px;
        height: auto;
        background: rgba(0, 0, 0, 0);
    }
`;

const NavButton = styled.div`
    z-index: 999;
    cursor: pointer;

    &.close {
        transform: rotate(180deg);
        position: fixed;
        top: 20px;
        left: 30px;
    }

    @media (min-width: 980px) {
        display: none;
    }
`;

const ButtonLine = styled.div`
    width: 25px;
    height: 3px;
    margin: 0px 0px 5px 0px;
    background: #ffffff;

    &.show:nth-child(1) {
        background: #51c0cf;
        transform: rotate(45deg) translate(5px, 5px);
    }
    //line 2
    &.show:nth-child(2) {
        opacity: 0;
    }
    //line 3
    &.show:nth-child(3) {
        background: #51c0cf;
        transform: rotate(-45deg) translate(6px, -6px);
    }
`;

const Auth = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: 980px) {
        display: inline;
        padding-left: 20px;
    }
`;

const Logo = styled.div`
    width: 120px;
    padding-bottom: 10px;
    z-index: 999;
    order: -1;
`;

const LogoImage = styled.img`
    height: 55px;
`;

const ContainerNav = styled(Container)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 0px;
    margin-left: 0px;
    width: 100%;
    padding: 0px;

    @media (min-width: 980px) {
        margin-left: 20px;
        padding: 0px 25px;
    }
`;

const LoginButton = styled(Button)`
    font-size: 20px;
    border: 1px solid #51c0cf;
    color: #51c0cf;
    background-color: rgba(0, 0, 0, 0);
    font-weight: normal;
    1px 1px 3px 0 #30a0af;

    @media (min-width: 980px) {
        font-size: 16px;
        color: #51c0cf;
        border: 2px solid #ffffff;
        background-color: #fff;
        text-transform: capitalize;

        &:hover{
          color: #51c0cf;
          border: 2px solid #ffffff;
          background-color: #fff;
        }
    }
`;

const ChooseLanguage = styled(LanguageSelector)`
    @media (min-width: 980px) {
        margin-right: 20px;
    }
`;

const Language = styled.div`
    position: absolute;
    right: 60px;

    @media (min-width: 980px) {
        position: initial;
    }
`;

const Header = () => {
    const [menuActive, setMenuActive] = useState(false);
    const { t, i18n } = useTranslation();

    function toggleClass() {
        setMenuActive(!menuActive);
    }

    return (
        <header>
            <Navbar>
                <ContainerNav>
                    <Logo>
                        <MenuLink href={"https://www.telephon.digital/" + i18n.language + "/home"}>
                            <LogoImage src={TelephonLogo} />
                        </MenuLink>
                    </Logo>
                    <NavButton className={menuActive ? "show" : null} onClick={toggleClass}>
                        <ButtonLine className={menuActive ? "show" : null}></ButtonLine>
                        <ButtonLine className={menuActive ? "show" : null}></ButtonLine>
                        <ButtonLine className={menuActive ? "show" : null}></ButtonLine>
                    </NavButton>
                    <Menu className={menuActive ? "show" : null}>
                        <MenuNav>
                            <MenuLink href={"https://www.telephon.digital/" + i18n.language + "/home"}>
                                {t("header.home")}
                            </MenuLink>
                            <MenuLink href={"https://www.telephon.digital/" + i18n.language + "/about-us"}>
                                {t("header.about")}
                            </MenuLink>
                            <MenuLink href={"https://www.telephon.digital/" + i18n.language + "/contact"}>
                                {t("header.contact")}
                            </MenuLink>

                            <Auth>
                                <Link to="/register">
                                    <LoginButton>{t("header.start")}</LoginButton>
                                </Link>
                            </Auth>
                        </MenuNav>
                    </Menu>
                </ContainerNav>
                <Language>
                    <ChooseLanguage />
                </Language>
            </Navbar>
        </header>
    );
};

export default Header;
