import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { PageTitle, P } from "../styled/typography";
import Button from "../styled/button";
import Card from "../styled/card";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import { Redirect } from "react-router-dom";
import LoadingSpinner from "../loadspinner";
import { shake } from "../styled/animation";
import Metronome from "../exerciseUtilities/metronoom/Metronoom";
import Chrono from "../exerciseUtilities/chronometer/Chronometer";
import Flash from "../styled/flashMessage";
import Recorder from "../video/Recorder";
import { useTranslation } from "react-i18next";
import resultStore from "../resultStore";


const STEMCard = styled(Card)`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding: 20px 50px 60px 50px;

    @media (min-width: 980px) {
        padding-top: 20px;
        padding-bottom: 60px;
    }
`;

const Description = styled(P)`
    margin-top: 0px;
    color: #545454;
`;

const TaskCard = styled.div`
    width: 100%;

    @media (min-width: 980px) {
        width: 100%;
    }
`;

const OptionsCard = styled(Card)`
    width: 100%;
    margin-top: 15px;

    @media (min-width: 768px) {
        width: 29.25%;
        margin-top: 0px;
        margin-left: 15px;

        &.communication-option-component {
            width: 100%;
            margin-top: 15px;
            margin-left: 0px;
        }
    }
`;

const Flex = styled.div`
    display: flex;
    justify-content: space-between;
`;

const FlexCards = styled(Flex)`
    flex-direction: column;
    align-items: stretch;

    &.communication-component {
        flex-direction: column;
        align-items: stretch;
    }

    @media (min-width: 768px) {
        flex-direction: row;
        align-items: flex-start;

        &.communication-component {
            flex-direction: column;
            align-items: stretch;
            margin-bottom: 30px;
        }
    }
`;

const Title = styled(P)`
    font-weight: bold;
    font-size: 24px;
    color: #545454;
    text-align: center;
`;

const Bold = styled(P)`
    font-weight: bold;
    color: #545454;
    text-align: center;
    font-size: 28px;
    margin-bottom: 15px;
`;

const Text = styled(P)`
    text-align: center;
    margin-top: 0px;
`;

const NextIcon = styled(FaArrowRight)`
    color: #ffffff;
    padding-right: 10px;
    font-size: 16px;
    transform: translateY(2px);
`;

const NextIconWhite = styled(FaArrowRight)`
    color: #fff;
    font-size: 16px;
`;

const NextIconPrimary = styled(FaArrowRight)`
    color: ${props => props.color};
    cursor: pointer;
    font-size: 45px;
    position: absolute;
    top: 50%;
    right: 30px;
`;

const BackIconPrimary = styled(FaArrowLeft)`
    color: #51c0cf;
    cursor: pointer;
    font-size: 22px;
    position: absolute;
    top: 50%;
    left: 30px;
`;

const BackIcon = styled(FaArrowLeft)`
    color: #afafaf;
    font-size: 16px;
    padding-right: 10px;
    cursor: pointer;
`;

const BackText = styled(P)`
    margin: 0px;
    position: absolute;
    bottom: 20px;
    left: 30px;
    cursor: pointer;
`;

const ListItem = styled.li`
    margin-top: 10px;
    cursor: pointer;
    border: 1px solid lightgrey;
    border-radius: 5px;
    padding: 10px 10px;
    text-align: center;
    color: #929292;

    &.clicked {
        border: 2px solid #51c0cf;
        border-radius: 5px;
        padding: 10px;
    }
`;
const UnorderedList = styled.ul`
    list-style-type: none;
    padding: 0;
    margin-bottom: 40px;
    width: 100%;
`;

const ScoreDiv = styled.div`
    position: absolute;
    left: 40px;
    bottom: 20px;
    display: flex;
    flex-direction: column;
`;

const Score = styled.p`
    margin: 0px;
    margin-bottom: 5px;
`;

const Audio = styled.audio`
    max-width: 80%;
    margin-top: 20px;
    margin-bottom: 20px;
`;

const Img = styled.img`
    width: 80%;

    @media (min-width: 980px) {
        width: 60%;
    }
`;

const NextButton = styled(Button)``;

const Cue = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0px;
    margin-bottom: 25px;
    border: 1px solid #545454;
    border-radius: 5px;
    padding: 20px auto;
    padding-bottom: 20px;
    min-width: 50vw;
`;

const Input = styled.input`
    background: #ffffff;
    border: 1px solid #51c0cf;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(197, 197, 197, 0.25);
    padding: 10px 10px;
    margin-top: 5px;
    outline: none;
    font-size: 16px;
`;

const FoutMessage = styled(Text)`
    display: block;
    padding: 10px 20px;
    background-color: #f23539;
    border: 1px solid #f23539;
    border-radius: 10px;
    color: white;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 0px;

    &.shake {
        animation: ${shake} 0.5s;
    }
`;

const JuistMessage = styled(FoutMessage)`
    background-color: #24ae5f;
    border: 1px solid #24ae5f;
`;

const BlueText = styled(P)`
    color: #51c0cf;
`;

const CenterFlex = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Video = styled.video`
    width: 80%;
    margin-bottom: 10px;
`;

const InputTextBox = styled(Input)`
    margin-right: 10px;
    width: 50vw;
`;

const CenterDiv = styled.div`
    text-align: center;
`;

function StappenplanMetValidatie(props) {
    const { t, i18n } = useTranslation();
    const exercise = props.exercise;
    const [currentStep, setStep] = useState(1);
    const [miniStep, setCurrentMiniStep] = useState(1);
    const [steps, setSteps] = useState();
    const [urls, setUrls] = useState([]);
    const [questionImgUrls, setQuestionImgUrls] = useState([]);
    const [questionVideoUrls, setQuestionVideoUrls] = useState([]);
    const [cueImgUrls, setCueImgUrls] = useState([]);
    const [cueVideoUrls, setCueVideoUrls] = useState([]);
    const [questionUrls, setQuestionUrls] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showCue, setShowCue] = useState(false);
    const [showCorrect, setShowCorrect] = useState(false);
    const [showFalse, setShowFalse] = useState(false);
    const [showFail, setShowFail] = useState(false);
    const [clickable, setClickable] = useState(true);
    const [score, setScore] = useState(0);
    const [amountClicked, setAmountClicked] = useState(0);
    const [redirect, setRedirect] = useState(false);
    const [showSucces, setShowSucces] = useState(false);

    const [results, setResults] = useState([]);

    const [goalIMG, setGoalIMG] = useState();
    const [goalVideo, setGoalVideo] = useState();
    const [prepIMG, setPrepIMG] = useState();
    const [prepVideo, setPrepVideo] = useState();
    const [medIMG, setMedIMG] = useState();
    const [compAudio, setCompAudio] = useState();
    const [compVideo, setCompVideo] = useState();

    const [answeredMiniStep, setAnsweredMiniStep] = useState(false);
    const [showAnswerBeforeNext, setShowAnswerBeforeNext] = useState(false);

    const resultstore = useContext(resultStore);
    const [showEndRecorder, setShowEndRecorder] = useState(false);
    const [isExample, setIsExample] = useState(false);
    const [isReview, setIsReview] = useState(false);

    useEffect(() => { if(props.activeStep) setStep(props.activeStep) }, [props.activeStep])
    useEffect(() => { if(props.activeMiniStep) setCurrentMiniStep(props.activeMiniStep) }, [props.activeMiniStep])


    const setCurrentStep = (newStep) => {
        if(props.onStepChange) props.onStepChange(newStep);
        setStep(newStep);
    }

    const setMiniStep = (newMiniStep) => {
        if(props.onMiniStepChange) props.onMiniStepChange(newMiniStep);
        setCurrentMiniStep(newMiniStep);
    }

    function getUrl(type, id) {
        if (type === "cue") {
            var output = urls.find(url => url.id === id);
            return output ? output.value : output;
        } else if (type === "question") {
            var output = questionUrls.find(url => url.id === id);
            return output ? output.value : output;
        } else if (type === "questionImage") {
            var output = questionImgUrls.find(url => url.id === id)
            return output ? output.value : output;
        } else if (type === "cueImage") {
            var output = cueImgUrls.find(url => url.id === id)
            return output ? output.value : output;
        } else if (type === "cueVideo") {
            var output = cueVideoUrls.find(url => url.id === id)
            return output ? output.value : output;
        } else if (type === "questionVideo") {
            var output = questionVideoUrls.find(url => url.id === id)
            return output ? output.value : output;
        }
    }

    // Change styling for communication module
    const FlexCardsId = document.getElementById("flex-cards");
    const OptionsCardId = document.getElementById("options-card");
    if (props.isCommunicationComponent && FlexCardsId && OptionsCardId) {
        FlexCardsId.classList.add("communication-component");
        OptionsCardId.classList.add("communication-option-component");
    }

    function shuffle(array) {
        var currentIndex = array.length,
            temporaryValue,
            randomIndex;

        // While there remain elements to shuffle...
        while (0 !== currentIndex) {
            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;

            // And swap it with the current element.
            temporaryValue = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = temporaryValue;
        }

        return array;
    }

    function initQuestionsToSteps(types) {
        var scoreObject = {};
        for (var type of types) {
            scoreObject[type.name] = 0;
            for (var question of type.questions) {
                question.type = type.name;
            }
        }
        setScore(scoreObject);

        var newlist = [];
        if (exercise.random_all) {
            var questions = [];
            types.map((type) => {
                questions = questions.concat(type.questions);
            });
            var randQuestions = shuffle(questions);
            newlist = randQuestions;
        } else if (exercise.random_within_type) {
            var questions = [];
            types.map((type) => {
                var randQuestions = shuffle(type.questions);
                questions = questions.concat(randQuestions);
            });
            newlist = questions;
        } else {
            var questions = [];
            types.map((type) => {
                questions = questions.concat(type.questions);
            });
            newlist = questions;
        }

        return newlist;
    }
    useEffect(() => {
        setSteps(initQuestionsToSteps(exercise.question_types));
        //.reduce((result, qt) => result + qt.questions.reduce((res, _) => res + 1, 0),0))

        if (! exercise.goal) {
            if (! exercise.preparation) {
                setCurrentStep(3);
            } else {
                setCurrentStep(2);
            }
        }

        if (exercise.media_complete_id) {
            props.getRessource(exercise.media_complete_id).then((res) => {
                setMedIMG(res);
            });
        }
        if (exercise.audio_complete_id) {
            props.getRessource(exercise.audio_complete_id).then((res) => {
                setCompAudio(res);
            });
        }
        if (exercise.video_complete_id) {
            props.getRessource(exercise.video_complete_id).then((res) => {
                setCompVideo(res);
            });
        }
        if (
            exercise.goal &&
            exercise.goal.media_id
        ) {
            props.getRessource(exercise.goal.media_id).then((res) => {
                setGoalIMG(res);
            });
        }
        if (
            exercise.goal &&
            exercise.goal.video_id
        ) {
            props.getRessource(exercise.goal.video_id).then((res) => {
                setGoalVideo(res);
            });
        }
        if (
            exercise.preparation &&
            exercise.preparation.media_id
        ) {
            props.getRessource(exercise.preparation.media_id).then((res) => {
                setPrepIMG(res);
            });
        }
        if (
            exercise.preparation &&
            exercise.preparation.video_id 
        ) {
            props.getRessource(exercise.preparation.video_id).then((res) => {
                setPrepVideo(res);
            });
        }

        exercise.question_types.map(qt => qt.questions.map((step) => {
            if (step.media_id) {
                props.getRessource(step.media_id).then((response) => {
                    setQuestionImgUrls((questionImgUrls) => [...questionImgUrls, {id: step.media_id, value: response}]);
                });
            }
            if (step.video_id) {
                props.getRessource(step.video_id).then((response) => {
                    setQuestionVideoUrls((questionVideoUrls) => [...questionVideoUrls, {id: step.video_id, value: response}]);
                });
            }
            if (step.cue_media_id) {
                props.getRessource(step.cue_media_id).then((response) => {
                    setCueImgUrls((cueImgUrls) => [...cueImgUrls, {id: step.cue_media_id, value: response}]);
                });
            }
            if (step.cue_audio_id) {
                props.getRessource(step.cue_audio_id).then((response) => {
                    setUrls((urls) => [...urls, {id: step.cue_audio_id, value: response}]);
                });
            }
            if (step.cue_video_id) {
                props.getRessource(step.cue_video_id).then((response) => {
                    setCueVideoUrls((cueVideoUrls) => [...cueVideoUrls, {id: step.cue_video_id, value: response}]);
                });
            }
            if (step.audio_id) {
                props.getRessource(step.audio_id).then((response) => {
                    setQuestionUrls((questionUrls) => [...questionUrls, {id: step.audio_id, value: response}]);
                });
            }
        }));
        setLoading(false);
    }, []);

    const nextMiniStep = () => {
        setMiniStep(miniStep + 1);
        setAnsweredMiniStep(false);
        ResetValidation();
    };

    const toResult = () => {
        if (miniStep === steps.length) {
            setMiniStep(miniStep + 1);
        }
        if (resultstore.isRecording) {
            setShowEndRecorder(true);
        } else {
            setShowEndRecorder(false);
            if (props.redirectEnabled) {
                setRedirect(true);
            } else if(props.isExample) {
                setIsExample(true);
            } else if(props.isReview) {
                setIsReview(true);
            } else {
                props.endExercise();
            }
        }
    }

    const nextStep = () => {
        if (currentStep < 4) {
            if (
                exercise.preparation && ! Array.isArray(exercise.preparation)  && exercise.preparation.text != '<p></p><p></p><p></p><p></p><p></p>' && exercise.goal
            ) {
                setCurrentStep(currentStep + 1);
            } else {
                if (currentStep === 3) {
                    setCurrentStep(4);
                } else {
                    setCurrentStep(3);
                }
            }
        }
    };
    const prevStep = () => {
        if (currentStep > 1) {
            if (
                exercise.preparation && ! Array.isArray(exercise.preparation) && exercise.preparation.text != '<p></p><p></p><p></p><p></p><p></p>' && exercise.goal
            ) {
                setCurrentStep(currentStep - 1);
            } else {
                setCurrentStep(1);
            }
        }
    };
    
    function validateAnswer(e, answer, correctAnswer, step) {
        if (document.getElementById("listitem")) {
            e.target.classList.add("clicked");
        }
        setAnsweredMiniStep(true);
        answer = answer.trim();
        correctAnswer = correctAnswer.trim();
        if (!step.is_capital_sensitive) {
            answer = answer.toLowerCase();
            correctAnswer = correctAnswer.toLowerCase();
        }
        if (!step.is_punctuation_sensitive) {
            answer = answer.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, "").replace(/\s{2,}/g, " ");
            correctAnswer = correctAnswer.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, "");
        }
        if (answer === correctAnswer) {
            setShowFalse(false);
            setShowCorrect(true);
            setShowAnswerBeforeNext(false);
            setShowCue(false);
            setClickable(false);
            if (amountClicked < 4) {
                setScore({ ...score, [step.type]: score[step.type] + 5 - amountClicked });
            }
            setAmountClicked(amountClicked + 1);

            var found = false;
            for (var i = 0; i < results.length; i++) {
                if (results[i].key == step.id) {
                    found = true;
                    let newArr = [...results];
                    newArr[i].attempts += 1;
                    newArr[i].answers.push(answer);
                    setResults(newArr);
                }
            }
            if (!found) {
                var resultObject = {
                    key: step.id,
                    question: step.question,
                    answers: [answer],
                    attempts: 1,
                };
                setResults((results) => [...results, resultObject]);
            }
        } else {
            if (amountClicked >= 3) {
                setShowFail(true);
                setShowFalse(false);
                setShowCue(false);
                setClickable(false);
                setShowAnswerBeforeNext(false);
                
            } else {
                setShowFalse(true);
                setShowCue(true);
                setShowAnswerBeforeNext(false);
            }
            setAmountClicked(amountClicked + 1);

            var found = false;
            for (var i = 0; i < results.length; i++) {
                if (results[i].key == step.id) {
                    found = true;
                    let newArr = [...results];
                    newArr[i].attempts += 1;
                    newArr[i].answers.push(answer);
                    setResults(newArr);
                }
            }
            if (!found) {
                var resultObject = {
                    key: step.id,
                    question: step.question,
                        // step.question instanceof String
                        //     ? step.question
                        //     // : step.question.map((x) => x.children[0].text).join("\n"),
                    answers: [answer],
                    attempts: 1,
                };
                setResults((results) => [...results, resultObject]);
            }
        }

        if (showFalse == true) {
            document.getElementById("foutmessage").classList.add("shake");
            setTimeout(() => {
                if (document.getElementById("foutmessage")) {
                    document.getElementById("foutmessage").classList.remove("shake");
                }
            }, 500);
        }
    }

    function ResetValidation() {
        setShowFalse(false);
        setShowFail(false);
        setShowCorrect(false);
        setClickable(true);
        setShowCue(false);
        setAmountClicked(0);
        setShowAnswerBeforeNext(false);
    }

    const ReceiveRecording = (recording) => {
        props.HandleRecording(recording);
        setShowSucces(true);
        setTimeout(function () {
            setShowSucces(false);
        }, 5000);
    };
    
    const toggleWarning = () => {
        setShowAnswerBeforeNext(true);
    }

    if (loading) {
        return <LoadingSpinner />;
    } else {
        return (
            <>
                <PageTitle black>{exercise.title}</PageTitle>
                <Description>{props.taskDescription}</Description>
                <FlexCards id="flex-cards">
                    <TaskCard>
                        {currentStep === 1 && (
                            <STEMCard>
                                <Title>{t("stappenplan.goal")}</Title>
                                {exercise.goal.media_id && (
                                    <Img src={goalIMG}></Img>
                                )}
                                {exercise.goal.video_id && (
                                    <Video controls controlsList="nodownload">
                                        <source src={goalVideo} type="video/mp4" />
                                    </Video>
                                )}
                                {exercise.goal.text && <Description><div dangerouslySetInnerHTML={{__html: exercise.goal.text }} /></Description>}
                                <NextButton onClick={nextStep}>
                                    <NextIcon />
                                    {t("stappenplan.nextStep")}
                                </NextButton>
                            </STEMCard>
                        )}
                        {currentStep === 2 && (
                            <STEMCard>
                                <Title>{t("stappenplan.prep")}</Title>
                                {exercise.preparation.media_id && (
                                    <Img src={prepIMG}></Img>
                                )}
                                {exercise.preparation.video_id && (
                                    <Video controls controlsList="nodownload">
                                        <source src={prepVideo} type="video/mp4" />
                                    </Video>
                                )}
                                {exercise.preparation.text && <Description><div dangerouslySetInnerHTML={{__html: exercise.preparation.text }} /></Description>}
                                <NextButton onClick={nextStep}>
                                    <NextIcon />
                                    {t("stappenplan.nextStep")}
                                </NextButton>
                                {exercise.goal && (
                                    <BackText onClick={prevStep}>
                                        <BackIcon />
                                        {t("stappenplan.back")}
                                    </BackText>
                                )}
                            </STEMCard>
                        )}
                        {currentStep === 3 && (
                            <div>
                                {steps.map((step, index) => {
                                    if (miniStep === index + 1) {
                                        return (
                                            <div key={index}>
                                                <STEMCard>
                                                    <Title>{t("stappenplan.perf")}</Title>
                                                    {exercise.media_complete_id && (
                                                        <Img src={medIMG}></Img>
                                                    )}
                                                    {exercise.audio_complete_id && (
                                                        <Audio controls>
                                                            <source src={compAudio} type="audio/wav; base64"></source>
                                                        </Audio>
                                                    )}
                                                    {exercise.video_complete_id && (
                                                        <Video controls controlsList="nodownload">
                                                            <source src={compVideo} type="video/mp4; base64" />
                                                        </Video>
                                                    )}
                                                    <Bold>
                                                        {t("stappenplanMetValidatie.question")} {index + 1}
                                                    </Bold>
                                                    <Description id="questionContent">{step.question.split('\n').map(str => <p>{str}</p>)}</Description>
                                                    {/* <BlockContent blocks={step.question} id="questionContent" /> */}
                                                    <CenterFlex>
                                                        {step.media_id && (
                                                            <Img
                                                                src={getUrl("questionImage", step.media_id)}
                                                            ></Img>
                                                        )}
                                                        {step.audio_id && (
                                                            <Audio controls>
                                                                <source
                                                                    src={getUrl(
                                                                        "question",
                                                                        step.audio_id
                                                                    )}
                                                                    type="audio/wav; base64"
                                                                />
                                                            </Audio>
                                                        )}
                                                        {step.video_id && (
                                                            <Video controls controlsList="nodownload">
                                                                <source
                                                                    src={getUrl("questionVideo", step.video_id)}
                                                                    type="video/mp4; base64"
                                                                />
                                                            </Video>
                                                        )}
                                                        {showCorrect && (
                                                            <JuistMessage>
                                                                {t("stappenplanMetValidatie.right")}{" "}
                                                                {step.correct_answer}
                                                            </JuistMessage>
                                                        )}
                                                        {showFalse && (
                                                            <FoutMessage id="foutmessage">
                                                                {t("stappenplanMetValidatie.wrong")}
                                                            </FoutMessage>
                                                        )}
                                                        {showFail && (
                                                            <FoutMessage>
                                                                {t("stappenplanMetValidatie.tries")}{" "}
                                                                {step.correct_answer}
                                                            </FoutMessage>
                                                        )}
                                                        {showAnswerBeforeNext && (
                                                            <FoutMessage>
                                                                {t("stappenplanMetValidatie.answerBeforeNext")}
                                                            </FoutMessage>
                                                        )}
                                                        <Bold>{t("stappenplanMetValidatie.answers")}</Bold>
                                                        <UnorderedList>
                                                            {step.type_of_answer === "Meerkeuze" &&
                                                                step.answer_possibilities[0].map((answer) => {
                                                                    return clickable ? (
                                                                        <ListItem
                                                                            id="listitem"
                                                                            key={answer.id}
                                                                            onClick={(e) => {
                                                                                validateAnswer(
                                                                                    e,
                                                                                    answer.answer,
                                                                                    step.correct_answer,
                                                                                    step
                                                                                )
                                                                            }}
                                                                        >
                                                                            {answer.answer}
                                                                        </ListItem>
                                                                    ) : (
                                                                        <ListItem key={answer.id}>{answer.answer}</ListItem>
                                                                    );
                                                                })}
                                                            {step.type_of_answer === "Textbox" && clickable && (
                                                                <CenterDiv>
                                                                    <InputTextBox type="text" id="inputExercise" autocomplete="off"/>
                                                                    <Button
                                                                        onClick={(e) => 
                                                                            validateAnswer(
                                                                                e,
                                                                                document.getElementById("inputExercise")
                                                                                    .value,
                                                                                step.correct_answer,
                                                                                step
                                                                            )
                                                                        }
                                                                    >
                                                                        <NextIconWhite />
                                                                    </Button>
                                                                </CenterDiv>
                                                            )}
                                                        </UnorderedList>

                                                        {showCue && step.cue_text && (
                                                            <Cue>
                                                                <h5>{t("stappenplanMetValidatie.hint")}</h5>
                                                                <p>{step.cue_text}</p>
                                                                {step.cue_audio_id && (
                                                                    <Audio controls>
                                                                        <source
                                                                            src={getUrl(
                                                                                "cue",
                                                                                step.cue_audio_id
                                                                            )}
                                                                            type="audio/wav; base64"
                                                                        />
                                                                    </Audio>
                                                                )}
                                                                {step.cue_video_id && (
                                                                    <Video controls controlsList="nodownload">
                                                                        <source
                                                                            src={getUrl(
                                                                                "cueVideo",
                                                                                step.cue_video_id
                                                                            )}
                                                                            type="video/mp4; base64"
                                                                        />
                                                                    </Video>
                                                                )}
                                                                {step.cue_media_id && (
                                                                    <Img
                                                                        src={getUrl(
                                                                            "cueImage",
                                                                            step.cue_media_id
                                                                        )}
                                                                    ></Img>
                                                                )}
                                                            </Cue>
                                                        )}
                                                        {exercise.reward_system === "Score" && (
                                                            <ScoreDiv>
                                                                {Object.keys(score).map((key) => {
                                                                    return <Score>{key + ": " + score[key]}</Score>;
                                                                })}
                                                            </ScoreDiv>
                                                        )}
                                                        {miniStep < steps.length && answeredMiniStep && (
                                                            <NextIconPrimary color="#51c0cf" data-testid="nextIcon" onClick={nextMiniStep} />
                                                        )}
                                                        {miniStep < steps.length && ! answeredMiniStep && (
                                                            <NextIconPrimary color="grey" data-testid="nextIcon" onClick={toggleWarning} />
                                                        )}
                                                        {miniStep === steps.length && (
                                                            <NextButton onClick={toResult}>
                                                                <NextIcon />
                                                                {t("registerForm1.next")}
                                                            </NextButton>
                                                        )}
                                                    </CenterFlex> 
                                                </STEMCard>
                                            </div>
                                        );
                                    }
                                })}
                            </div>
                        )}
                        {miniStep === steps.length + 1 && (
                            <STEMCard>
                                <Bold>{t("stappenplanMetValidatie.endscore")} </Bold>
                                {Object.keys(score).map((key) => {
                                    return (
                                        <Text>
                                            {key + ": " + score[key]}/
                                            {exercise.question_types.find((type) => {
                                                return type.name == key;
                                            }).questions.length * 5}
                                        </Text>
                                    );
                                })}
                                <NextIconPrimary data-testid="nextResultIcon" onClick={toResult} />
                                {showEndRecorder && <Description>{t("exercise.endCamera")}</Description>}
                            </STEMCard>
                        )}
                        {redirect && (
                            <Redirect
                                to={{
                                    pathname: "/p/Assignments/results",
                                    state: {
                                        id: props.id,
                                        startTime: props.startTime,
                                        title: exercise.title,
                                        type: "resultform",
                                        exerciseType: "SMV",
                                        results: results,
                                        scores: score,
                                    },
                                }}
                            />
                        )}
                    </TaskCard>
                    {(exercise.should_be_chrono ||
                        exercise.should_be_metronome ||
                            exercise.should_be_recorded) && (
                            <OptionsCard id="options-card">
                                <Title black>{t("stappenplan.options")}</Title>
                                {props.showRecorder &&
                                    exercise.should_be_recorded && (<>
                                        <Recorder onRecordingComplete={ReceiveRecording} />
                                        {showSucces && <Flash>{t("stappenplan.video")}.</Flash>}
                                    </>)}
                                {exercise.should_be_chrono && <Chrono />}
                                {exercise.should_be_metronome && <Metronome />}
                            </OptionsCard>
                        )}
                </FlexCards>
            </>
        );
    }
}

export default StappenplanMetValidatie;
