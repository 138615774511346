import React, {useContext, useState, useEffect} from 'react'
import styled from "styled-components"
import { observer } from "mobx-react-lite"
import {Redirect} from "react-router-dom"
import ResultStore from "../components/resultStore"
import Card from "../components/styled/card"
import Button from '../components/styled/button'
import { SmallTitle, P } from '../components/styled/typography'
import {api, recordingApi} from '../api'
import registrationStore from "../components/registrationStore"
import { useTranslation } from "react-i18next"
import LoadSpinner from './loadspinner'
import Flash from "../components/styled/flashMessage";


const Wrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
  padding: 100px 60px;
`

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
`

const Title = styled(SmallTitle)`
  text-align: center;
`

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px 20px;
`

const BulletGreen = styled.div`
  width: 40px;
  height: 40px;
  background-color: #24AE5F;
  border: 4px solid #24AE5F;
  border-radius: 100%;
  margin-right: 10px;
  cursor: pointer;
  box-sizing: content-box;

  &.active{
    border: 4px solid #51C0CF;
  }
`

const BulletOrange = styled(BulletGreen)`
  background-color: #F0C239;
  border-color: #F0C239;
`

const BulletRed = styled(BulletGreen)`
  background-color: #F23539;
  border-color: #F23539;
`

const Bold = styled(P)`
  margin: 0px;
  font-weight: bold;
  margin-top: 10px;
`

const ResultForm = observer((props) => {
    const { t, i18n } = useTranslation()
    const store = useContext(ResultStore)
    const id = props.id
    const regstore = useContext(registrationStore)
    const user = JSON.parse(regstore.user)
    const [redirect, setRedirect] = useState(false)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false);

    useEffect(() => {
        store.clearResult()
    }, []);

    function handleClick(value){
        let date = new Date();
        date = date.toLocaleDateString('en-US', {day: '2-digit', month: '2-digit', year: 'numeric'});
        store.setDate(date);

        if (value==3){
            store.setResult(3)
        } else if (value==2){
            store.setResult(2)
        } else{
            store.setResult(1)
        }
    }

    function HandleSubmit() {
        if (store.result == 0 || store.result == "") {
            setError(true);
        } else {
            setLoading(true)
            if (store.recording !== undefined) {
                recordingApi.post("/recording", store.recording, {
                    headers: {"Content-Type": `multipart/form-data; boundary=${store.recording._boundary}`},
                }).then((response) => {
                    let recordingUrl = response.data.key;
                    var endTime = Date.now()
                    const duration = endTime - props.startTime
                    api.post('/task', {
                        "task_id": id,
                        "recording": recordingUrl,
                        "result": store.result,
                        "duration": duration,
                        "scores": props.scores,
                        "openAnswers": props.openAnswers,
                        "scaleAnswers": props.scaleAnswers,
                        "user_id": user.public_id,
                        exerciseType: props.exerciseType
                    }).then(function (response) {
                        store.clearRecording();
                        setLoading(false)
                        setRedirect(true)
                    })
                })
            } else {
                setLoading(true)
                var endTime = Date.now()
                const duration = endTime - props.startTime
                api.post('/task', {
                    "task_id": id,
                    "result": store.result,
                    "duration": duration,
                    "scores": props.scores,
                    "openAnswers": props.openAnswers,
                    "scaleAnswers": props.scaleAnswers,
                    "user_id": user.public_id,
                    exerciseType: props.exerciseType
                }).then(function(response){
                    setLoading(false)
                    setRedirect(true)
                })
            }
        }
    }
    const showScores = () => {
        var score
        var output = []
        for (score in props.scores){
            output.push(<Bold>{score}: {props.scores[score]}</Bold>)
        }
        return output
    }

    return(
        <Wrapper>
            {! loading && 
                <>
                    <Title black>{t('resultForm.difficulty')}</Title>
                    {error && <Flash red>{t("resultForm.NothingSelectedError")}</Flash>}
                    <Flex>
                        <FlexColumn>
                            <BulletGreen className={store.result == 3 ? "active" : ""} onClick={() => handleClick(3)} /><Bold>{t('legend.easy')}</Bold>
                        </FlexColumn>
                        <FlexColumn>
                            <BulletOrange className={store.result == 2 ? "active" : ""} onClick={() => handleClick(2)} /><Bold>{t('legend.average')}</Bold>
                        </FlexColumn>
                        <FlexColumn>
                            <BulletRed className={store.result == 1 ? "active" : ""} onClick={() => handleClick(1)} /><Bold>{t('legend.hard')}</Bold>
                        </FlexColumn>
                    </Flex>
                    {showScores()}
                    <br />
                    <Button onClick={HandleSubmit}>{t('resultForm.endExer')}</Button>
                </>
            }
            {loading && 
                <>
                    <Title black>{t("resultForm.uploadingTitle")}</Title>
                    <p>{t("resultForm.uploadingMessage")}</p>
                    <LoadSpinner />
                </>
            }
            {redirect && <Redirect to="/p/Assignments" />}
        </Wrapper>
    )

})

export default ResultForm