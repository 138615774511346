import { observable, action } from "mobx";
import { createContext } from "react";
import { api } from "../api";

class NotificationStore {
    @observable notifications = [];

    @action updateNotifications(newNotification) {
        this.notifications = newNotification;
    }

    @action setNotifications(public_id) {
        api.get("/user/" + public_id + "/inbox").then((response) => {
            this.updateNotifications(response.data.inbox);
        });
    }
}

export const NotificationStoreInstance = new NotificationStore();

export default createContext(NotificationStoreInstance);
