import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import TherapistOverlay from "../../components/overlays/therapistOverlay";
import Global from "../../components/styled/global";
import PageWrapper from "../../components/styled/pageWrapper";
import { PageTitle, SmallTitle, P } from "../../components/styled/typography";
import {
    FaAccessibleIcon, FaBookOpen,
    FaBriefcaseMedical, FaBuffer, FaEdit,
    FaFileArchive, FaFileImage,
    FaFileSignature,
    FaFolderOpen, FaPaintBrush, FaPaste, FaPhotoVideo,
    FaPlus
} from "react-icons/fa";
import registrationStore from "../../components/registrationStore";
import { api } from "../../api";
import MyTemplate from "../../components/myTemplate";
import LoadSpinner from "../../components/loadspinner";
import { PageAnimation } from "../../components/styled/animation";
import Flash from "../../components/styled/flashMessage";
import { useTranslation } from "react-i18next";
import qs from "qs";
import Button from "../../components/styled/button";
import OwnDocuments from "../../components/libraryComponents/ownDocuments";
import OwnMedia from "../../components/libraryComponents/ownMedia";
import OwnExercises from "../../components/libraryComponents/ownExercises";

const Flex = styled.div`
    display: flex;
    flex-direction: column;

    @media (min-width: 980px) {
        flex-direction: row;
    }
`;

const SelectFlex = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
`;

const MyDocuments = styled.div`
    display: flex;
    flex-direction: column;

    @media (min-width: 980px) {
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: stretch;
    }
`;

const A = styled(Link)`
    text-decoration: none;
`;

const CardButton = styled.div`
    background: #ffffff;
    border: 1px solid #51c0cf;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(197, 197, 197, 0.25);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 30px;
    margin-top: 20px;
    margin-right: 20px;
    cursor: pointer;

    @media (min-width: 980px) {
        width: 225px;
        height: 170px;
    }
`;

const Empty = styled.div`
    height: 20px;
    width: 20px;
`;

const CardButtonText = styled(P)`
    margin: 0px;
    color: #51c0cf;
    text-align: center;
    padding: 0px 10px;
`;

const Plus = styled(FaPlus)`
    font-size: 10px;
    color: #51c0cf;
`;

const BufferIcon = styled(FaBuffer)`  
  color: #51c0cf;
  font-size: 25px;
  vertical-align: middle;  
`;

const FileSignatureIcon = styled(FaFileSignature)`  
  color: #51c0cf;
  font-size: 25px;
  vertical-align: middle;  
`;

const PhotoVideoIcon = styled(FaPhotoVideo)`  
  color: #51c0cf;
  font-size: 25px;
  vertical-align: middle;
  padding-right: 10px;
`;

const EditIcon = styled(FaEdit)`  
  color: #51c0cf;
  font-size: 25px;
  vertical-align: middle;  
`;

const CopyPasteIcon = styled(FaPaste)`
  color: #51c0cf;
  font-size: 25px;
  vertical-align: middle;
  padding-right: 10px;
`;

const PaintBrushIcon = styled(FaPaintBrush)`
  color: #51c0cf;
  font-size: 25px;
  vertical-align: middle;
  padding-right: 10px;
`;
const MyTemplates = styled(MyDocuments)``;

const MateriaalAanmaken = observer((props) => {
    const { t, i18n } = useTranslation();
    const [assignments, setAssignments] = useState([]);
    const store = useContext(registrationStore);
    const user = JSON.parse(store.user);
    const [tempLoading, setTempLoading] = useState(true);
    const [showFlash, setShowFlash] = useState(false);
    const isRequestExSubmit = qs.parse(props.location.search, { ignoreQueryPrefix: true }).exercisecreated;

    useEffect(() => {
        api.get("/assignmentTemplates/" + user.public_id).then((response) => {
            setAssignments(response.data.assignmentTemplates);
            setTempLoading(false);
        });
    }, []);

    const update = () => {
        setTempLoading(true);
        setTimeout(() => {
            api.get("/assignmentTemplates/" + user.public_id).then((response) => {
                setAssignments(response.data.assignmentTemplates);
                setTempLoading(false);
            });
        }, 2500);
    };

    const flash = () => {
        setShowFlash(true);
    };

    return (
        <Global>
            <PageWrapper>
                <TherapistOverlay />
                <PageAnimation>
                    <PageTitle black>{t("sidebarTherapist.material")}</PageTitle>
                    {showFlash && <Flash green>{t("materiaalAanmaken.succ")}</Flash>}
                    {isRequestExSubmit == "true" && <Flash green>{t("OefeningAanvragen.succ")}</Flash>}
                    <SelectFlex>
                        <SmallTitle black><PaintBrushIcon />&nbsp;{t("sidebarTherapist.smallSubText")}</SmallTitle>
                    </SelectFlex>
                    <Flex>
                        <A to="CreateMaterial/CreateTemplate">
                            <CardButton>
                                <Plus />
                                <BufferIcon />
                                <CardButtonText>{t("materiaalAanmaken.assignment")}</CardButtonText>
                            </CardButton>
                        </A>
                        <A to="CreateMaterial/CreateDocument">
                            <CardButton>
                                <Plus />
                                <FileSignatureIcon />
                                <CardButtonText>{t("documentForm.configure")}</CardButtonText>
                            </CardButton>
                        </A>
                        <A to="CreateMaterial/CreateFile">
                            <CardButton>
                                <Plus />
                                <PhotoVideoIcon />
                                <CardButtonText>{t("fileForm.configure")}</CardButtonText>
                            </CardButton>
                        </A>
                        <A to="CreateMaterial/requestExercise">
                            <CardButton>
                                <Plus />
                                <EditIcon />
                                <CardButtonText>{t("materiaalAanmaken.makeExercise")}</CardButtonText>
                            </CardButton>
                        </A>
                    </Flex>

                    <br /><br />

                    <SmallTitle black><FileSignatureIcon />&nbsp;{t("materiaalAanmaken.ownDocs")}</SmallTitle>
                    <OwnDocuments public={false} public_id={user.public_id} flash={flash}/>
                    <br /><br />
                    <SmallTitle black><CopyPasteIcon />&nbsp;{t("materiaalAanmaken.temp")}</SmallTitle>
                    <MyTemplates>
                        {tempLoading ? (
                            <LoadSpinner />
                        ) : assignments.length > 0 ? (
                            assignments.map((assignment) => {
                                return <MyTemplate assignment={assignment} update={update} />;
                            })
                        ) : (
                            <div style={{ "margin-top": "10px" }}>{t("materiaalAanmaken.noTemp")}</div>
                        )}
                    </MyTemplates>
                    <br /><br />

                    <SmallTitle black><PhotoVideoIcon />&nbsp;{t("materiaalAanmaken.media")}</SmallTitle>
                    <OwnMedia public_id={user.public_id} flash={flash}/>

                    <SmallTitle black><FileSignatureIcon />&nbsp;{t("materiaalAanmaken.ownExercises")}</SmallTitle>
                    <OwnExercises public={false} public_id={user.public_id} flash={flash}/>

                </PageAnimation>
            </PageWrapper>
        </Global>
    );
});

export default MateriaalAanmaken;
