import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import RegistrationStore from "../registrationStore";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { H4, P } from "../styled/typography";
import Flash from "../styled/flashMessage";

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const Question = styled(H4)`
    font-weight: 500;
    margin: 0px;
    margin-bottom: 15px;
`;

const Input = styled.input`
    background: #ffffff;
    border: 1px solid #51c0cf;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(197, 197, 197, 0.25);
    border-radius: 10px;
    padding: 10px 10px;
    margin-top: 5px;
    outline: none;
    font-size: 16px;
    margin-bottom: 15px;

    &.valid {
        border-color: #45ae7c;
    }

    &.invalid {
        border-color: #f02929;
    }
`;

const Label = styled.label`
    display: flex;
    margin-bottom: 15px;
`;

const Par = styled(P)`
    margin: 0px;
    padding-top: 2px;
    padding-left: 5px;
    display: inline;
    font-size: 16px;
    cursor: pointer;
`;

const Error = styled(P)`
    margin: 0px;
    margin-bottom: 15px;
    color: #f02929;
`;

const Email = observer(({ setRegisterStepIsValid, setRegisterStepIsInvalid, nextStep, emailTaken }) => {
    const { t, i18n } = useTranslation();
    const registrationStore = useContext(RegistrationStore);
    const [emailError, setEmailError] = useState(false);

    useEffect(() => {
        const emailField = document.getElementById("email");
        const checkBox = document.getElementById("checkbox");
        if (validateEmail(registrationStore.email)) {
            checkBox.checked = registrationStore.wantsMarketingEmails;
            setRegisterStepIsValid();
            emailField.classList.remove("invalid");
            emailField.classList.add("valid");
        }
    }, []);

    function validateEmail(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const handleEmailChange = (e) => {
        const emailField = document.getElementById("email");
        registrationStore.setEmail(e.target.value);
        if (validateEmail(registrationStore.email)) {
            emailField.classList.remove("invalid");
            emailField.classList.add("valid");
            setRegisterStepIsValid();
            setEmailError(false);
        } else {
            emailField.classList.remove("valid");
            emailField.classList.add("invalid");
            setRegisterStepIsInvalid();
            setEmailError(true);
        }
    };

    const handleWantsMarketingEmails = (e) => {
        registrationStore.setWantsMarketingEmails(!registrationStore.wantsMarketingEmails);
    };

    return (
        <Wrapper>
            <Question black>{t("emailRegister.title")}</Question>
            {emailTaken && <Flash red>{t("emailRegister.emailExists")}</Flash>}
            <Input
                id="email"
                type="email"
                value={registrationStore.email}
                onChange={(e) => handleEmailChange(e)}
                onKeyPress={(e) => nextStep(e)}
                placeholder={t("emailRegister.placeholder")}
                autoFocus
                required
                data-testid="email"
            />
            {emailError && <Error data-testid="email-error">{t("emailRegister.emailError")}</Error>}
            <Label>
                <Input
                    id="checkbox"
                    type="checkbox"
                    name="acceptedPrivacy"
                    value={registrationStore.wantsMarketingEmails}
                    onKeyPress={(e) => nextStep(e)}
                    onChange={(e) => {
                        handleWantsMarketingEmails(e);
                    }}
                />
                <Par>{t("emailRegister.subscribeToNewsLetter")}</Par>
            </Label>
        </Wrapper>
    );
});

export default Email;
