import React from 'react';
import { NativeTypes } from 'react-dnd-html5-backend';
import { useDrop } from 'react-dnd';
import { FaCloudUploadAlt } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const Label = styled.label`
    font-size: 16px;
    font-weight: 600;
    color: #545454;
    margin-right: 20px;
`;

const UploadButton = styled(Label)`
    justify-content: flex-start;
    width: auto;
    border: 1px solid #929292;
    padding: 5px 10px;
    margin-bottom: 15px;
`;

const UploadIcon = styled(FaCloudUploadAlt)`
    margin-right: 7px;
`;

export const FileTarget = (props) => {
    const { t, i18n } = useTranslation();
    const { onDrop } = props;
    const [{ canDrop, isOver }, drop] = useDrop({
        accept: [NativeTypes.FILE],
        drop(item, monitor) {
            if (onDrop) {
                onDrop(props, monitor);
            }
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    });
    const isActive = canDrop && isOver;
    return (<UploadButton ref={drop} htmlFor={props.target} type="button">
            <UploadIcon /> {isActive ? t("fileTarget.releaseFile") : t("fileTarget.uploadFile")}
        </UploadButton>);
};