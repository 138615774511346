import React, {useContext, useState} from 'react'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import {Redirect} from "react-router"
import Button from '../components/styled/button'
import RegistrationStore from "../components/registrationStore"
import { observer } from "mobx-react-lite"
import {api} from "../api"
import { H4 } from '../components/styled/typography'
import { useTranslation } from "react-i18next"

const LoginForm = styled(Form)`
`

const FormGroup = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
`

const Input = styled(Field)`
    background: #FFFFFF;
    border: 1px solid #51C0CF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(197, 197, 197, 0.25);
    border-radius: 10px;
    padding: 10px 10px;
    margin-top: 5px;
    outline: none;
    font-size: 16px;
`

const Label = styled.label`
    font-size: 16px;
    font-weight: 600;
    color: #545454;
`

const SubmitButton = styled(Button)`
    margin-top: 20px;
    padding-right: 45px;
    padding-left: 45px;
    font-size: 18px;
`
const Error = styled.p`
    font-size: 16px;
    color: #FFF;
    font-style: italic;
    background-color: #DC393D;
    border: 1px solid #DC393D;
    border-radius: 10px;
    padding: 10px 15px;
`

const Center = styled.div`
    text-align: center;
`

const ForgotPassword = styled(Link)`
    color: #51C0CF;
`
const SmallTitle = styled(H4)`
    margin-bottom: 15px;
    margin-top: 0px;
`



const LoginForm1 = observer((props) => {
    const { t, i18n } = useTranslation()
    return (<div>
        <Formik initialValues={{ oldpassword : "", newpassword : "", repeatnewpassword: ""}}
                onSubmit={(values, actions) => {
                    api.get('/login',{auth:{username: props.user.email, password: values.oldpassword}}).then((response) =>{
                        if (response.data.user){
                            api.put('/user/' + props.user.public_id, {password: values.newpassword}).then(function(response){
                                actions.setSubmitting(false)
                                window.location.reload()
                            })
                        } else {
                            
                            actions.setSubmitting(false)
                            }
                        }).catch((error) => {
                            actions.setErrors({oldpassword : t('passwordResetForm.incorrectPassword')})
                        })
                }}
                render={({errors, touched}, isSubmitting) => (
                    <LoginForm>
                        <SmallTitle black>{t('passwordResetForm.changeTitle')}</SmallTitle>
                        <FormGroup>
                            <Label>{t('passwordResetForm.oldPass')}</Label>
                            <Input type="password" name="oldpassword"/>
                            { touched.oldpassword && errors.oldpassword && <Error>{errors.oldpassword}</Error> }
                        </FormGroup>
                        <FormGroup>
                            <Label>{t('passwordResetForm.newPass')}</Label>
                            <Input type="password" name="newpassword"/>
                            { touched.newpassword && errors.newpassword && <Error>{errors.newpassword}</Error>}
                        </FormGroup>
                        <FormGroup>
                            <Label>{t('passwordResetForm.newPass')} {t('passwordResetForm.second')}</Label>
                            <Input type="password" name="repeatnewpassword" />
                            { touched.repeatnewpassword && errors.repeatnewpassword && <Error>{errors.repeatnewpassword}</Error>}
                        </FormGroup>
                        <Center>
                            <SubmitButton disabled={isSubmitting}>{t('passwordResetForm.changeTitle')}</SubmitButton>
                        </Center>
                    </LoginForm>
                    
                )}
                validationSchema={Yup.object().shape({
                    oldpassword: Yup.string().required(t('passwordResetForm.givePass')),
                    newpassword: Yup.string().matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/, t('passwordResetForm.passRes')).required(t('passwordResetForm.giveNew')),
                    repeatnewpassword: Yup.string().test(t('passwordResetForm.match'), t('passwordResetForm.corrPass'), function(value) {
                        return this.parent.newpassword === value;}),
                })}
        />  
    </div>)
})

export default LoginForm1