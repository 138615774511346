import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { withRouter } from 'react-router';
import ChatStore from '../../stores/chat.store';
import extendedTheme from '../../theme';
import styled, { ThemeProvider } from 'styled-components';
import {faEnvelope} from '@fortawesome/free-solid-svg-icons';
import Icon from '../icon';
import {color, compose, space} from 'styled-system';
import Card from '@eu.jstack/react-card';
import { PrimaryButton, TertiaryButton } from '@eu.jstack/react-button/src';
import Text from '../text';
import registrationStore from "../../../components/registrationStore";
import api from '../../api';
import chatService from '../../services/chatService';

const Highlight = styled.div`
    height: 100px;
    width: 5px;
    ${compose(color)};
`;

const CardContainer = styled.div`
    display: flex;
    align-items: center;
    ${compose(space)}
`;

const MessageIconContainer = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    ${compose(space)}
`;

const MessageIconCircle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    ${compose(color)}
`;

const DescriptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    ${compose(space)}
`;

const ActionContainer = styled.div`
    display: flex;
    flex-direction: row;
    ${compose(space)}
`;

const ChatNotification = observer(({history}) => {
    const chatstore = useContext(ChatStore);
    const [chat, setChat] = useState(null);
    const store = useContext(registrationStore);
    const user = JSON.parse(store.user);

    useEffect(() => {

        if(chatstore.showNotification) {
            chatService.getChat(chatstore.activeChat.id).then((data) => setChat(data.data));
            setTimeout(() => chatstore.showNotification = false, 8000);
        }
    }, [chatstore.showNotification]);

    const getMessageSender = () => {
        if (chat) {
            var fullName;
            chat.attendees.forEach(attendee => {
                if (attendee.public_id !== user.public_id) fullName = `${attendee.firstName} ${attendee.lastName}`;
            });
            return fullName;
        } else {
            return "";
        }
    }

    const showNotification = () => {

        if (chatstore.activeChat) {
            //const target = JSON.parse(JSON.stringify(chatstore.activeChat));
            const last = chatstore.activeChat.messages[chatstore.activeChat.messages.length - 1];
            return ! (last && last.instigatorId != user.public_id);
        }
    }

    const removeNotification = () => {
        chatstore.showNotification = false;
    }

    const openChat = () => {
        removeNotification();
        history.push('/chat', { id: chat.id});
    }

    if(! chatstore.showNotification || showNotification()) {
        return null;
    }

    return(
        <ThemeProvider theme={extendedTheme}>
            <Card position='fixed' bottom='30px' right='30px' height='100px' p={0}>
                <Highlight bg='primary' />

                <CardContainer p={5}>
                    <MessageIconContainer>
                        <MessageIconCircle bg='primaryLight.3'>
                            <Icon fontSize={3} color='primary' icon={faEnvelope}/>
                        </MessageIconCircle>
                    </MessageIconContainer>

                    <DescriptionContainer ml={5}>
                        <Text fontWeight='600'>Inkomend bericht</Text>
                        <Text color='greyLight.0'>Van {getMessageSender()}</Text>
                    </DescriptionContainer>

                    <ActionContainer ml={8}>
                        <PrimaryButton onClick={() => openChat()} px={3}>Openen</PrimaryButton>
                        <TertiaryButton onClick={() => removeNotification()}ml={3}>Negeer</TertiaryButton>
                    </ActionContainer>
                </CardContainer>
            </Card>
        </ThemeProvider>
    );
});


export default withRouter(ChatNotification);