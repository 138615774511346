import React, { useState, useEffect, useContext, useLayoutEffect } from "react";
import { FaTimes } from "react-icons/fa";
import styled from "styled-components";
import { Formik, Form, Field } from "formik";
import Card from "../components/styled/card";
import { PageTitle, SmallTitle, P } from "../components/styled/typography";
import Flash from "../components/styled/flashMessage";
import registrationStore from "../components/registrationStore";
import LoadSpinner from "../components/loadspinner";
import { ExCard } from "../components/styled/card";
import Button from "../components/styled/button";
import { FaFilter } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import SearchBar from "../components/search";
import Material from "../components/libraryComponents/material";
import Media from "../components/libraryComponents/media";
import Filter from "../components/filterComponents/filter";
import LanguageStore from "../components/stores/languageStore";

const Overlay = styled.div`
    background-color: rgba(60, 60, 60, 0.7);
    position: fixed;
    top: 0px;
    left: 0;
    width: 100vw;
    height: 100%;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: 980px) {
        top: 0;
        height: 100vh;
        width: 100vw;
    }
`;

const OverlayCard = styled(Card)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    box-sizing: border-box;
    padding: 30px;
    height: 98%;
    width: 98%;
    position: absolute;
    z-index: 999999;

    @media (min-width: 980px) {
        height: 90%;
        position: relative;
        top: 0;
        z-index: 9;
    }
`;
const Times = styled(FaTimes)`
    color: #545454;
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
`;
const CreateAssignment = styled(SmallTitle)`
    margin: 30px 0px 10px 0px;
`;

const ExFlex = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
`;

const Content = styled.div`
    width: 100%;
`;

const Title = styled.div`
    display: inline-block;
    position: relative;
    color: #545454;
    margin-bottom: 0px;
`;

const Flex = styled.div`
    display: flex;
    flex-direction: column;

    @media (min-width: 980px) {
        flex-direction: row;
        justify-content: space-between;
    }
`;

const Third = styled.div`
    width: 100%;

    @media (min-width: 980px) {
        width: 33%;
    }
`;

const TwoThird = styled.div`
    width: 100%;

    @media (min-width: 980px) {
        width: 66%;
    }
`;

function SelectionOverlay(props) {
    const { t, i18n } = useTranslation();
    const store = useContext(registrationStore);
    const user = JSON.parse(store.user);
    const [tempChosen, setTempChosen] = useState({});
    const langStore = useContext(LanguageStore);
    const [filterData, setFilterData] = useState({
        title: "",
        language: langStore.getLanguage(i18n.language),
        discipline: user.speciality,
        subdiscipline: "",
    });

    const handleClick = (id, title, type) => {
        setTempChosen({ id: id, title: title, type: type });
    };

    const HandleSubmit = () => {
        props.setChosenElement(tempChosen);
        props.close();
    };

    const getFilterResults = (data) => {
        setFilterData(data);
    };

    return (
        <Overlay>
            <OverlayCard>
                <Times onClick={() => props.close()} />
                <CreateAssignment black>{t("selectionOverlay.selectTask")}</CreateAssignment>
                <Content>
                    <Filter filterCallback={(data) => getFilterResults(data)} />
                    <Flex>
                        <Third>
                            <Title>
                                <h2>{t("materiaalRaadplegen.publ")}</h2>
                            </Title>
                            <Material
                                public={true}
                                filterData={filterData}
                                taskSelect={true}
                                selectCallback={(event, id, title, type) => handleClick(event, id, title, type)}
                                showType={false}
                            />
                        </Third>
                        <Third>
                            <Title>
                                <h2>{t("materiaalRaadplegen.priv")}</h2>
                            </Title>
                            <Material
                                public={false}
                                small={true}
                                filterData={filterData}
                                public_id={user.public_id}
                                taskSelect={true}
                                selectCallback={(event, id, title, type) => handleClick(event, id, title, type)}
                                showType={false}
                            />
                        </Third>
                        <Third>
                            <Title>
                                <h2>{t("materiaalRaadplegen.media")}</h2>
                            </Title>
                            <Media
                                small={true}
                                filterData={filterData}
                                public_id={user.public_id}
                                mediaSelect={true}
                                selectCallback={(event, id, title, type) => handleClick(event, id, title, type)}
                                showType={false}
                            />
                        </Third>
                    </Flex>

                    <Button onClick={HandleSubmit}>{t("selectionOverlay.selEx")}</Button>
                </Content>
            </OverlayCard>
        </Overlay>
    );
}

export default SelectionOverlay;
