import React, {useContext, useState, useEffect} from "react"
import styled from "styled-components"
import { NavLink } from 'react-router-dom'
import {api} from "../api"
import { useTranslation } from "react-i18next"
import LoadSpinner from '../components/loadspinner'

import Button from './styled/button'
import Card from './styled/card'


const SureOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(60, 60, 60, 0.7);
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
`

const Modal = styled(Card)`
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const CloseLink = styled.a`
    text-decoration: none;
    color: #545454;
    font-weight: bold;
    cursor: pointer;
`

const CloseLinkDiv = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
`

const RedButton = styled(Button)`
    background-color: #DC393D;
    border-color: #DC393D;
    margin-left: 20px;

    &:hover{
        background-color: #DC393D;
        border-color: #DC393D;
    }
`

const LoadSpinnerDiv = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px 0px;
`


function AreYouSure(props){
    const { t, i18n } = useTranslation()
    const [Overlay, setOverlay] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (props.display === true){
            setOverlay(true)
        } else {
            setOverlay(false)
        }
    }, [props.display])

    const HandleDelete = () => {
        props.HandleDelete()
        setLoading(true)
            setTimeout(() => {
                setLoading(false)
                props.update()
            }, 5000);
    }

    const HandleOverlay = () => {
        setOverlay(!Overlay)
    }
    return(
        <div>
            {Overlay &&
                <SureOverlay>
                <Modal>
                <CloseLinkDiv><CloseLink onClick={HandleOverlay}>X</CloseLink></CloseLinkDiv>
                    <h2>{t('areYouSure.areYouSure')}</h2>
                <div><Button onClick={HandleOverlay} >{t("areYouSure.dontDelete")}</Button><RedButton onClick={HandleDelete}>{t('areYouSure.delete')}</RedButton>{loading &&
                <LoadSpinnerDiv><LoadSpinner /></LoadSpinnerDiv>
                }</div>
                </Modal>
                </SureOverlay>
            }
        </div>

    )
}

export default AreYouSure