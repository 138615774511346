import styled from 'styled-components'

const pageWrapper = styled.div`
    padding: 100px 30px 0px 30px;
    @media (min-width: 980px){
        padding: 10px 30px;
        margin-bottom: 40px;
    }

`

export default pageWrapper
