import { observable, action } from "mobx";
import { createContext } from "react";
import api from '../../api';

class DisciplineStore{
    @observable disciplines = [];
    @observable initialized = false;

    @action setDisciplines(newDisciplines){
        this.disciplines = newDisciplines;
    }

    @action getSubdisciplines(discipline_name){
        var discipline = this.disciplines.find(x => x.name === discipline_name);
        return discipline ? discipline.subdisciplines : [];
    }

    @action setInitialized(newInitialized){
        this.initialized = newInitialized;
    }
}


export const DisciplineStoreInstance = new DisciplineStore()

export default createContext(DisciplineStoreInstance)