import api from '../api';

export const INITIATOR = 'initiator';
export const RECEIVER = 'receiver';

const getUser = (id) => api.getCommunicationApi().get(`/users/${id}`);

export default {
    getUser
}
