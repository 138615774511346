import React, { useState, useEffect, useContext } from "react";
import { SmallTitle } from "../components/styled/typography";
import { api } from "../api";
import registrationStore from "../components/registrationStore";
import LoadSpinner from "../components/loadspinner";
import styled from "styled-components";
import Button from "../components/styled/button";
import { ExCard } from "../components/styled/card";
import { P } from "../components/styled/typography";
import { useTranslation } from "react-i18next";
import opdrachtStore from "../components/opdrachtStore";
import TrajectStore from "../components/trajectComponents/trajectStore";
import DayPickerInput from "react-day-picker/DayPickerInput";
// Include the locale utils designed for moment
import MomentLocaleUtils from "react-day-picker/moment";
import { formatDate, parseDate } from "react-day-picker/moment";
// Make sure moment.js has the required locale data
import "moment/locale/ca";
import "moment/locale/de";
import "moment/locale/en-gb";
import "moment/locale/es";
import "moment/locale/fr";
import "moment/locale/nl";

const DatumField = styled.div`
    margin-bottom: 10px;

    & .DayPickerInput {
        width: 35%;
        padding-right: 0.5rem;
    }

    & input {
        width: 100%;
        background: #ffffff;
        border: 1px solid #51c0cf;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(197, 197, 197, 0.25);
        border-radius: 10px;
        padding: 10px 10px;
        margin-top: 5px;
        outline: none;
        font-size: 16px;
    }
`;

const Card = styled(ExCard)`
    width: 100%;
`;

const Content = styled.div`
    width: 100%;
`;

const Bold = styled(P)`
    margin: 0px;
    font-weight: 600;
    color: #a5a5af;
`;

const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
`;

const Error = styled(P)`
    color: #dc393d;
`;

function PatientExistingAssignment(props) {
    const { t, i18n } = useTranslation();
    const [templates, setTemplates] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const regstore = useContext(registrationStore);
    const user = JSON.parse(regstore.user);
    const store = useContext(opdrachtStore);
    const trajStore = useContext(TrajectStore);
    const [chosenOne, setChosenOne] = useState("");
    const now = new Date();
    now.setHours(12,0,0,0);
    const [start, setStart] = useState(now);
    const modifiers = { start: start };

    useEffect(() => {
        api.get("/assignmentTemplates/" + user.public_id).then(function (response) {
            setTemplates(response.data.assignmentTemplates);
            setLoading(false);
        });
    }, []);

    const handleStartChange = (start) => {
        // Change the from date and focus the "to" input field
        if (start < now) return;
        setStart(start);
    };

    const handleSubmit = () => {
        api.post("/templateToAssignment/" + chosenOne, {
            start: start,
            user_id: props.public_id,
            category: trajStore.chosenCategory,
            traject_id: trajStore.chosenTrajectId,
        })
            .then(function (response) {
                props.close();
                props.update();
                store.clearTitle();
            })
            .then(() => {
                api.get("/user/" + props.public_id).then((res) => {
                    const patientEmail = res.data.user.email;
                    api.post("/mailWithTemplate", {
                        sender: "info@telephon.digital",
                        to: patientEmail,
                        subject: "Assignment added",
                        template_name: "new-assignment-mail",
                        template_content: [{"name": "therapist-name", "content": user.firstName + " " + user.lastName}]
                });
            });
        });
    };

    const handleChange = (event, id) => {
        setChosenOne(id);

        var elems = document.querySelectorAll("#card");
        elems.forEach.call(elems, function (el) {
            el.classList.remove("active");
        });

        if (event.target.id === "text") {
            event.target.closest("div").className += " active";
        } else {
            event.target.className += " active";
        }
    };

    if (loading) {
        return <LoadSpinner />;
    } else {
        return (
            <Content>
                <SmallTitle>{t("patientExistingAssignment.selTemp")}:</SmallTitle>
                {templates ? (
                    templates.length > 0 ? (
                        <FlexColumn>
                            {templates.map((template) => {
                                return (
                                    <Card id="card" onClick={(event) => handleChange(event, template.id)}>
                                        <Bold id="text" onClick={(event) => handleChange(event, template.id)}>
                                            {template.title}
                                        </Bold>
                                    </Card>
                                );
                            })}
                        </FlexColumn>
                    ) : (
                        <div>{t("patientExistingAssignment.noTemp")}</div>
                    )
                ) : (
                    <div>{t("patientExistingAssignment.noTemp")}</div>
                )}

                <SmallTitle>{t("patientExistingAssignment.start")}:</SmallTitle>
                <DatumField>
                    <DayPickerInput
                        value={start}
                        placeholder={start?.toLocaleDateString(i18n.language)}
                        format="LL"
                        formatDate={formatDate}
                        parseDate={parseDate}
                        dayPickerProps={{
                            selectedDays: [start],
                            disabledDays: { before: now},
                            modifiers,
                            numberOfMonths: 2,
                            localeUtils: MomentLocaleUtils,
                            locale: i18n.language
                        }}
                        onDayChange={handleStartChange}
                    />
                </DatumField>
                <Button onClick={handleSubmit}>{t("patientExistingAssignment.add")}</Button>
                <Error>{error}</Error>
                {/*error styling nodig*/}
            </Content>
        );
    }
}

export default PatientExistingAssignment;
