import React, {useContext, useEffect, useState} from 'react';
import styled, {ThemeProvider} from 'styled-components';
import ErrorBoundary from '../errorBoundary';
import extendedTheme from '../../theme';
import {color, compose, position, space, flexbox} from 'styled-system';
import placeholderImageSrc from '../../images/video-call-placeholder.svg';
import Text from '../text';
import callService from '../../services/callService';
import userService, {INITIATOR, RECEIVER} from '../../services/userService';
import {withRouter} from 'react-router-dom';
import BackButton from '../backButton';
import CommunicationStore from '../../stores/communication.store';
import ExerciseStore from '../../stores/library.store';
import communicationService from '../../services/communicationService';
import api from '../../api';
import {captureException} from '../../helpers/errorHelper';
import HangupCallButton from '../hangupCallButton';
import StopWatch from '../stopWatch';
import AudioAnalyzer from '../audioAnalyzer';
import {BaseModal} from '@eu.jstack/react-modal';
import {PrimaryButton, TertiaryButton} from '@eu.jstack/react-button/src';
import LibraryContainer from './libraryContainer';
import SettingsModal from './settingsModal';
import Icon from '../icon';
import {faCog, faMicrophoneSlash, faMousePointer, faVideo, faVideoSlash} from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from "react-i18next";

import {observer} from 'mobx-react-lite';
import {faSlideshare} from "@fortawesome/free-brands-svg-icons";
import * as artvaModule from '../audioAnalyzer';

const VideoCallPageContainer = styled.div`
    display: flex;
    flex-direction: column;
    ${compose(space)}
`;

const ExerciseAndCallBlock = styled.div`
    display: flex;
    ${compose(flexbox)}
`;

const ArtvaResultBlock = styled.div`
    display: flex;
    ${compose(flexbox)}
`;

const CallContainer = styled.div`
    position: relative;
    flex: 3;
    height: 65vh;
    ${compose(color)}
`;

const Button = styled.button`
  /* Adapt the colors based on primary prop */
  background: ${props => props.primary ? "palevioletred" : "white"};
  color: ${props => props.primary ? "white" : "palevioletred"};

  font-size: 1em;
  margin: 1em;
  padding: 0.25em 1em;
  border: 2px solid palevioletred;
  border-radius: 3px;
`;

const MainVideoContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
    overflow-y: hidden;
    z-index: 0;
`;

const MainVideo = styled.video`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

const PlaceholderContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    ${compose(space)}
`;

const PlaceholderImage = styled.img`
    opacity: 0.9;
    max-width: 300px;
`;

const SmallIconsOwn = styled.div`
    cursor: pointer;
    position: absolute;
    z-index: 5;
    top: 15px;    
    object-fit: cover;
    border: 0px solid;
    color: white;      
    right: 10px;
    font-size: 2em;
    text-align: right;    
    margin-right: 10px;
`;

const SmallIconOwn = styled(Icon)`
  margin-right: 5px;
  border-radius: 25px;
  background-color: ${props => props.theme.colors.primary};
  padding: 2px;
  width: 20px;
  height: 15px;
`;

const SmallIconsPartner = styled.div`
    cursor: pointer;
    position: absolute;
    text-align: left;
    z-index: 5;
    font-size: 0.8em;    
    top: 10px;
    left: 10px;
    object-fit: cover;
    border: 0px solid;    
    color: white;
    padding-top: 10px;
`;

const SmallIconPartner = styled(Icon)`
  margin-left: 10px;
  border-radius: 50px;
  background: ${props => props.theme.colors.primary};
  flex: 1 50px;  
  padding: 15px;
`;

const BigIconPartner = styled(Icon)`
    position: absolute;
    margin-left: 10px;
    border-radius: 50px;
    background: ${props => props.theme.colors.primary};
    flex: 1 50px;  
    padding: 15px;
    top: ${props => props.top};
    left: ${props => props.left};
`;



const ColorFade = styled.span`
    animation: colorfade 1.2s infinite;
    animation-direction: alternate;
    @keyframes colorfade {
        25% {
          color: white;      
        }
        100% {
          color: red;      
        }
    }
`;

const SmallVideo = styled.video`
    cursor: pointer;
    height: 20%;
    position: absolute;
    object-fit: cover;
    border: 2px solid ${props => props.theme.colors.primary};
    background-color: ${props => props.theme.colors.primary};
    transition: border 200ms ease-out;
    border-radius: 10px;
    ${compose(position)};
 
    &:hover {        
      border: 2px dotted #FFFFFF;
    }
`;

const BackButtonContainer = styled.div`
    ${compose(space)}
`;

const CloseModalFooterContainer = styled.div`
    display: flex;
    flex-direction: row;
    ${compose(space)}
`;

const HeaderContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

const SettingsButton = styled.button`
    background-color: transparent;
    border: none;
`

var labelUpdate = 1;
var resetResults = 0;

const VideoCallPage = observer (({location, history}) => {
    const [initiatorInfo, setInitiatorInfo] = useState(null);
    const [receiverInfo, setReceiverInfo] = useState(null);
    const [callStarted, setCallStarted] = useState(false);
    const [showCallButtons, setShowCallButtons] = useState(false);
    const [isCloseModalOpen, setIsCloseModalOpen] = useState(false);
    const communicationStore = useContext(CommunicationStore);
    const exerciseStore = useContext(ExerciseStore);
    const [stream, setStream] = useState();
    const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);

    const [isVideoSwitched, setVideoSwitched] = useState(false);
    const [isAudioMuted, setIsAudioMuted] = useState (false);
    const [isVideoMuted, setIsVideoMuted] = useState (false);
    const [isScreenShared, setIsScreenShared] = useState (false);

    const [isPartnerAudioMuted, setIsPartnerAudioMuted] = useState (false);
    const [isPartnerVideoMuted, setIsPartnerVideoMuted] = useState (false);
    const [isPartnerMouseShared, setIsPartnerMouseShared] = useState (false);
    const [isPartnerScreenShared, setIsPartnerScreenShared] = useState (false);

    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const { t, i18n } = useTranslation();
    const [peerConnection, setPeerConnection] = useState(null);
    const [mousePosition, setMousePosition] = useState(null);

    // initialize and cleanup stream when leaving
    useEffect(() => {
        communicationStore.setIsInCall(true);
        exerciseStore.setActiveExerciseId(null);
         return () => {
            communicationService.stopLocalStream();
            communicationStore.setIsInCall(false);
        }
    }, []);

    // create call if not exists
    useEffect(() => {
        if (location.state) {
            const body = {
                finished: false,
                initiator: location.state.initiatorId,
                attendees: [location.state.initiatorId, location.state.receiverId],
            };
            callService.createCall(body)
                .then(({data}) => communicationStore.setActiveCall(data))
                .catch(() => null);
        }
    }, []);

    const calculateLeft = (mouseX, screenWidth) => {
        const curWidth = document.documentElement.clientWidth;
        return mouseX / screenWidth * curWidth;
    };

    const calculateTop = (mouseY, screenHeight) => {
        const curHeight = document.documentElement.clientHeight;
        return mouseY / screenHeight * curHeight;
    };

    useEffect(() => {
        if (communicationStore.mouseChanged) {
            const pos = {...communicationStore.mousePosition};
            console.log(pos);
            const newPos = {};
            newPos.top = calculateTop(pos.y, pos.height) + 'px';
            newPos.left = calculateLeft(pos.x, pos.width) + 'px';
            setMousePosition(newPos);
            console.log(newPos);
            communicationStore.setMouseChanged(false);
        }
    }, [communicationStore.mouseChanged])

    useEffect(() => {
        setIsPartnerAudioMuted({...communicationStore.settings}.muteAudio);
        setIsPartnerVideoMuted({...communicationStore.settings}.muteVideo);
        setIsPartnerMouseShared({...communicationStore.settings}.shareMouse);
        setIsPartnerScreenShared({...communicationStore.settings}.shareScreen);

        communicationStore.setSettingsChanged(false);
    }, [communicationStore.settingsChanged]);

    // fetch active call
    useEffect(() => {
        var activeCallsPromise = callService.getActiveCalls();
        if(activeCallsPromise !== null) {
            activeCallsPromise.then(({data}) => {
                if (data.length > 0) {
                    communicationStore.setActiveCall(data[0])
                }
            });
        }
    }, []);

    // finish call when leaving
    useEffect(() => {
        return () => {
            if (communicationStore.activeCall) {
                communicationStore.finishActiveCall();
                communicationService.stopLocalStream();
            }
        }
    }, []);

    // fetch user info of receiver and initiator
    useEffect(() => {
        const fetchReceiverInfo = async () => {
            const {data: receiverUserInfo} = await userService.getUser(location.state.receiverId);
            setReceiverInfo(receiverUserInfo);
        };

        const fetchInitiatorInfo = async () => {
            const {data: initiatorUserInfo} = await userService.getUser(location.state.initiatorId);
            setInitiatorInfo(initiatorUserInfo);
        };

        if (location.state) {
            fetchReceiverInfo();
            fetchInitiatorInfo();
        }
    }, []);

    // set local stream and call other person
    useEffect(() => {
        if (!receiverInfo || !initiatorInfo) {
            return;
        }

        setupOwnMediaStream()
            .then((result) => {
                if (!result) return setIsErrorModalOpen(true);
                if (location.state?.role === INITIATOR) {
                    callOther(receiverInfo.videoCallConnectionId)
                }
                if (location.state?.role === RECEIVER) {
                    callOther(initiatorInfo.videoCallConnectionId);
                }
            })
    }, [receiverInfo, initiatorInfo]);

    const setupOwnMediaStream = async () => {
        const stream = await communicationService.initializeLocalVideoStream();
        if (stream === undefined) return false;
        displayLocalVideoStream(stream);

        communicationService.getVideoCallConnection().on('call', (call) => {
            setCallStarted(true);
            call.answer(stream);
            call.on('stream', displayRemoteVideoStream);
            // HIER KAN DE TRACK VAN DE VIDEOSTREAM AANGEPAST WORDEN
            setPeerConnection(call.peerConnection.getSenders()[1])
        });

        // communicationService.getDataStreamConnection().on("connection", (connection) => {
        //     connection.on('data', (data) => {
        //         console.log("in connection.data", new Date().toString(), data);
        //     });
        // });

        communicationService.getVideoCallConnection().on('error', (err) => captureException('Failed to open "call" event', err));

        // communicationService.getDataStreamConnection()
        //     .on('error', (err) => captureException('Failed to open "connection" event', err))
        //     .on("disconnected", () => console.log('disconnected from data channel'));

        return true;
    };

    const displayRemoteVideoStream = (remoteStream) => {
        const element = document.getElementById('main-video');
        if (element) {
            element.srcObject = remoteStream;
            setStream(remoteStream);
            element.onloadedmetadata = (e) => element.play();
        }
    };

    const displayLocalVideoStream = (localStream) => {
        const element = document.getElementById('small-video');
        element.srcObject = localStream;
        element.muted = true;
    };

    const callOther = (connectionId) => {
        const call = communicationService.getVideoCallConnection().call(connectionId, communicationService.getLocalStream());
        if (call == undefined) {
            return;
        }
        call.on('stream', (remoteStream) => {
            setCallStarted(true);
            displayRemoteVideoStream(remoteStream);
            setPeerConnection(call.peerConnection.getSenders()[1])
        });
        call.on('error', (err) => captureException('Calling other person failed', err));
    };

    const switchVideoView = () => {
        const smallVideoElement = document.getElementById('small-video');
        const mainVideoElement = document.getElementById('main-video');
        const smallVideoSrc = smallVideoElement.srcObject;
        const mainVideoSrc = mainVideoElement.srcObject;

        mainVideoElement.srcObject = smallVideoSrc;
        smallVideoElement.srcObject = mainVideoSrc;

        const newIsVideoSwitchedState = !isVideoSwitched;

        if (newIsVideoSwitchedState) {
            mainVideoElement.muted = true;
            smallVideoElement.muted = false;
        } else {
            smallVideoElement.muted = true;
            mainVideoElement.muted = false;
        }

        setVideoSwitched(newIsVideoSwitchedState);
    };


    const getCalleeName = () => {
        if (!receiverInfo || !initiatorInfo) {
            return;
        }

        return getFullName(location.state?.role === INITIATOR ? RECEIVER : INITIATOR);
    };

    const getFullName = (role) => {
        if (role === INITIATOR) {
            return `${initiatorInfo.firstName} ${initiatorInfo.lastName}`;
        } else if (role === RECEIVER) {
            return `${receiverInfo.firstName} ${receiverInfo.lastName}`;
        }
    };

    const startShare = async () => {
        if (peerConnection) {
            if (isScreenShared) {
                communicationService.getLocalStream().getVideoTracks().forEach(track => track.stop())
                const stream = await communicationService.initializeLocalVideoStream(false);
                if (stream) {
                    setIsScreenShared(false)
                    const userId = location.state.role === INITIATOR ? receiverInfo.public_id : initiatorInfo.public_id;
                    api.getCommunicationApi().put(`/calls/settings/${userId}`, {muteAudio: isAudioMuted, muteVideo: isVideoMuted, shareScreen: false});
                    peerConnection.replaceTrack(stream.getTracks()[0]);
                    displayLocalVideoStream(stream);
                }
            } else {
                const stream = await communicationService.initializeScreenShareStream();
                if(stream) {
                    setIsScreenShared(true)
                    const userId = location.state.role === INITIATOR ? receiverInfo.public_id : initiatorInfo.public_id;
                    api.getCommunicationApi().put(`/calls/settings/${userId}`, {muteAudio: isAudioMuted, muteVideo: isVideoMuted, shareScreen: true});
                    peerConnection.replaceTrack(stream.getTracks()[0]);
                    displayLocalVideoStream(stream);
                }
            }
        }
    }


    const Button = styled.button`
    /* Adapt the colors based on primary prop */
    background: ${props => props.primary ? "palevioletred" : "white"};
    color: ${props => props.primary ? "white" : "palevioletblue"};

     font-size: 1em;
        margin: 1em;
     padding: 0.25em 1em;
     border: 2px solid palevioletred;
     border-radius: 3px;
     &:hover {
        border-color: ${props => props.theme.colors.primary};
        cursor: pointer;
    }
    `;

    //f0
    const getIntensityCurrent = () => {
        var intensityCurrentIntetrval = setInterval(function() { 
        if (document.getElementById('intensityCurrent') == null){
            clearInterval(intensityCurrentIntetrval);
        }
        else {
            document.getElementById('intensityCurrent').innerHTML = artvaModule.intensityCurrent.toFixed(0);
        }
        }, 100)   
    }


    const getIntensityMean = () => {
        var intensityMeanIntetrval = setInterval(function() {  
        if (labelUpdate = 0 || document.getElementById('intensityMean') == null){
         //document.getElementById('fundamentalMean').innerHTML = "0.00";
            clearInterval(intensityMeanIntetrval);
        }
        else {
            document.getElementById('intensityMean').innerHTML = artvaModule.intensityMean.toFixed(0);
        }
        }, 100)   
    }


    //f0
    const getFundamentalCurrent = () => {
        var fundamentalCurrentIntetrval = setInterval(function() { 
        if (document.getElementById('fundamentalCurrent') == null){
            clearInterval(fundamentalCurrentIntetrval);
        }
        else {
            if (artvaModule.fundamentalCurrent > 70 && artvaModule.fundamentalCurrent < 1000){
                document.getElementById('fundamentalCurrent').innerHTML = artvaModule.fundamentalCurrent.toFixed(2);
            }
            else {
                document.getElementById('fundamentalCurrent').innerHTML = "0.00";
            }
        }
        }, 100)   
    }


    const getFundamentalMean = () => {
        var fundamentalMeanIntetrval = setInterval(function() {  
        if (labelUpdate = 0 || document.getElementById('fundamentalMean') == null){
            clearInterval(fundamentalMeanIntetrval);
        }
        else {
            if (artvaModule.fundamentalMean > 0){
                 document.getElementById('fundamentalMean').innerHTML = artvaModule.fundamentalMean.toFixed(2);
            }
            else {
                document.getElementById('fundamentalMean').innerHTML = "0.00";
            }
        }
        }, 100)   
    }


    //HNR
      const getHnrCurrent = () => {
        var hnrCurrentIntetrval = setInterval(function() {  
        if (labelUpdate = 0 || document.getElementById('hnrCurrent') == null){
            clearInterval(hnrCurrentIntetrval);
        }
        else {
            if (artvaModule.hnrCurrent > 0.00){
                 document.getElementById('hnrCurrent').innerHTML = artvaModule.hnrCurrent.toFixed(2);
            }
            else{
                document.getElementById('hnrCurrent').innerHTML = "0.00";
            }
        }
        }, 100)   
    }


    const getHnrMean = () => {
        var hnrMeanIntetrval = setInterval(function() {  
        if (labelUpdate = 0 || document.getElementById('hnrMean') == null){
            clearInterval(hnrMeanIntetrval);
        }
        else {
            if (artvaModule.hnrMean > 0){
                 document.getElementById('hnrMean').innerHTML = artvaModule.hnrMean.toFixed(2);
            }
            else{
                document.getElementById('hnrMean').innerHTML = "0.00";
            }
        }
        }, 100)   
    }

    //Jitter
      const getJitterCurrent = () => {
        var jitterCurrentIntetrval = setInterval(function() {  
        if (labelUpdate = 0) {
            document.getElementById('jitterCurrent').innerHTML = "0.00";
            clearInterval(jitterCurrentIntetrval);
        }
        if (document.getElementById('jitterCurrent') == null){
            clearInterval(jitterCurrentIntetrval);
        }
        else {
            if (artvaModule.jitterCurrent > 0){
                 document.getElementById('jitterCurrent').innerHTML = artvaModule.jitterCurrent.toFixed(2);
            }
            else{
                document.getElementById('jitterCurrent').innerHTML = "0.00";
            }
        }
        }, 100)   
    }

    const getJitterMean = () => {
        var jitterMeanIntetrval = setInterval(function() {  
        if (labelUpdate = 0 || document.getElementById('jitterMean') == null){
            clearInterval(jitterMeanIntetrval);
        }
        else {
            if (artvaModule.jitterMean > 0){

                 document.getElementById('jitterMean').innerHTML = artvaModule.jitterMean.toFixed(2);
            }
            else{
                document.getElementById('jitterMean').innerHTML = "0.00";
            }
        }
        }, 100)   
    }

    //Shimmer
      const getShimmerCurrent = () => {
        var shimmerCurrentIntetrval = setInterval(function() {  
        if (labelUpdate = 0) {
            document.getElementById('shimmerCurrent').innerHTML = "0.00";
            clearInterval(shimmerCurrentIntetrval);
        }
        if (document.getElementById('shimmerCurrent') == null){
            clearInterval(shimmerCurrentIntetrval);
        }
        else {
            if (artvaModule.shimmerCurrent > 0){
                 document.getElementById('shimmerCurrent').innerHTML = artvaModule.shimmerCurrent.toFixed(2);
            }
            else{
                document.getElementById('shimmerCurrent').innerHTML = "0.00";
            }
        }
        }, 100)   
    }

    const getShimmerMean = () => {
        var shimmerMeanIntetrval = setInterval(function() {  
        if (labelUpdate = 0 || document.getElementById('shimmerMean') == null){
            clearInterval(shimmerMeanIntetrval);
        }
        else {
            if (artvaModule.shimmerMean > 0){
                 document.getElementById('shimmerMean').innerHTML = artvaModule.shimmerMean.toFixed(2);
            }
            else{
                document.getElementById('shimmerMean').innerHTML = "0.00";
            }
        }
        }, 100)   
    }

    function resetResultsBtn () {
        if(communicationStore.hnrDisplay || communicationStore.shimmerDisplay || communicationStore.fundamentalDisplay || communicationStore.jitterDisplay){
        resetResults = 1;
        if(communicationStore.jitterDisplay){
        document.getElementById('jitterMean').innerHTML = "0.00";
        } 
        if(communicationStore.shimmerDisplay){
        document.getElementById('shimmerMean').innerHTML = "0.00";
        } 
        if(communicationStore.hnrDisplay){
        document.getElementById('hnrMean').innerHTML = "0.00";
        } 
        if(communicationStore.fundamentalDisplay){
        document.getElementById('fundamentalMean').innerHTML = "0.00";
        }
        if(communicationStore.intensityDisplay){
        document.getElementById('intensityMean').innerHTML = "0.00";
        }
        setTimeout(() => {  resetResults = 0; }, 400);
    }
    }

    function handlerFunction(stream){
        const rec = new MediaRecorder(stream);
    //    rec.
    }

    function myFunction(){
        console.log("my function");
    }

    const openCloseModal = () => setIsCloseModalOpen(!isCloseModalOpen);
    const openSettingsModal = () => setIsSettingsModalOpen(!isSettingsModalOpen);

    const backModalFooter =
        (<CloseModalFooterContainer ml={8}>
            <PrimaryButton onClick={() => history.goBack()} px={3}>Ja, beëindigen</PrimaryButton>
            <TertiaryButton onClick={() => setIsCloseModalOpen(false)} ml={3}>Nee, in het gesprek
                blijven</TertiaryButton>
        </CloseModalFooterContainer>);

    const NoCameraModal = () => (
        <BaseModal
                isOpen={isErrorModalOpen}
                footer={errorModalFooter}
                width={"500px"}
            >
                De camera toestemming voor de browser staat geblokkeerd.
        </BaseModal>);

    const errorModalFooter = (<CloseModalFooterContainer ml={8}>
            <TertiaryButton onClick={() => history.goBack()} px={3}>Ga terug</TertiaryButton>
        </CloseModalFooterContainer>);

    const toggleMuteSound = () => {
        communicationService.getLocalStream().getAudioTracks().forEach(t => t.enabled = !t.enabled);
        setIsAudioMuted(!isAudioMuted);
        const userId = location.state.role === INITIATOR ? receiverInfo.public_id : initiatorInfo.public_id;
        api.getCommunicationApi().put(`/calls/settings/${userId}`, {muteAudio: !isAudioMuted, muteVideo: isVideoMuted, shareScreen: isScreenShared});
    }

    const toggleMuteVideo = () => {
        communicationService.getLocalStream().getTracks().forEach(t => t.enabled = !t.enabled);
        setIsVideoMuted(!isVideoMuted);
        const userId = location.state.role === INITIATOR ? receiverInfo.public_id : initiatorInfo.public_id;
        api.getCommunicationApi().put(`/calls/settings/${userId}`, {muteAudio: isAudioMuted, muteVideo: !isVideoMuted, shareScreen: isScreenShared});
        
    }

    // const toggleMouseShare = async () => {
    //     const userId = location.state.role === INITIATOR ? receiverInfo.public_id : initiatorInfo.public_id;
    //     setIsMouseShared(!isMouseShared);
    //     api.getCommunicationApi().put(`/calls/settings/${userId}`, {muteAudio: isAudioMuted, muteVideo: isVideoMuted, shareScreen: isScreenShared});

    //     // if (isMouseShared) {
    //     //     let data = null;
    //     //     if (location.state.role === INITIATOR) {
    //     //         //await communicationService.initializeDataStreamConnection(receiverInfo.public_id)
    //     //         data = await communicationService.getDataStreamConnection().connect(receiverInfo.dataStreamConnectionId);
    //     //     } else {
    //     //         //await communicationService.initializeDataStreamConnection(initiatorInfo.public_id)
    //     //         data = await communicationService.getDataStreamConnection().connect(initiatorInfo.dataStreamConnectionId);
    //     //     }
    //     //     //setMousePosition(data);
    //     //     data.on('open', (dataConnection) => setMousePosition(data));
    //     //     data.on('error', (err) => console.log(err));
    //     //     data.on("disconnected", () => console.log('disconnected from data channel in transmit mouse'));
    //     // }
    // }

    // const captureMouseMovement = (event) => {
    //     // console.log("captureMouse")
    //     // if (mousePosition) {
    //     //     mousePosition.send({x: event.offsetX, y: event.clientY});
    //     // }
    //     if (isMouseShared) {
    //         const rect = event.target.getBoundingClientRect();
    //         const posX = event.clientX - rect.left;
    //         const posY = event.clientY - rect.top;

    //         console.log(posX, posY);

    //         const userId = location.state.role === INITIATOR ? receiverInfo.public_id : initiatorInfo.public_id;
    //         api.getCommunicationApi().post(`/calls/mouse/${userId}`, {x: posX, y:posY, height:rect.bottom - rect.top, width:rect.right - rect.left});
    //     }
    // };

    return (
        <ThemeProvider theme={extendedTheme}>
            
            <NoCameraModal/>
            <BaseModal
                isOpen={isCloseModalOpen}
                footer={backModalFooter}
                width={"500px"}
                onClose={() => setIsCloseModalOpen(false)}
            >
                {t('videochat.warnEnd')}
            </BaseModal>
            <SettingsModal
                isModalOpen={isSettingsModalOpen}
                close={setIsSettingsModalOpen}
            />
            <ErrorBoundary>
                <VideoCallPageContainer p={5}>
                    <HeaderContainer>
                        <BackButtonContainer onClick={openCloseModal} mb={3}>
                            <BackButton>{t('videochat.back')}</BackButton>
                        </BackButtonContainer>
                        {location.state?.role === INITIATOR && !!stream &&
                            <SettingsButton onClick={openSettingsModal}>
                                
                            <Text fontSize={2} fontWeight="bold">ARTVA <Icon fontSize={4} icon={faCog}/></Text>
                            </SettingsButton>
                        }
                    </HeaderContainer>
                    <Text fontSize={4}>{t('videochat.conversationWith')} {getCalleeName()}</Text>

                    {callStarted && isPartnerMouseShared && mousePosition && <BigIconPartner icon={faMousePointer} left={mousePosition.left} top={mousePosition.top}/>}

                    <ExerciseAndCallBlock flexDirection={['column', 'column', 'row']}>
                        <LibraryContainer showSelectExerciseButton={ callStarted }/>

                        <CallContainer bg="greyLight.2">
                            <MainVideoContainer onMouseEnter={() => setShowCallButtons(true)}
                                                onMouseLeave={() => setShowCallButtons(false)}>

                                {callStarted && <HangupCallButton clickCallbacks={[startShare, openCloseModal, toggleMuteSound, toggleMuteVideo]} muteAudio={isAudioMuted} muteVideo={isVideoMuted} activeScreenShare={isScreenShared} show={showCallButtons}/>}
                                {callStarted && <StopWatch show={showCallButtons} />}
                                {callStarted && <MainVideo key="main-video" id="main-video" autoPlay={true} muted={isVideoSwitched}/>}
                                {!callStarted && (
                                    <PlaceholderContainer mb={8}>
                                        <PlaceholderImage src={placeholderImageSrc}/>
                                        <Text mt={3} color="greyLight.0">{t('videochat.waitingFor')} {getCalleeName()} {t('videochat.toStart')}</Text>
                                    </PlaceholderContainer>
                                )}
                            </MainVideoContainer>
                            <SmallVideo onClick={switchVideoView} right={3} top={3} key="small-video" id="small-video"
                                        muted={!isVideoSwitched}
                                        autoPlay={true}/>
                                        <SmallIconsOwn>
                                            <ColorFade>
                                                {callStarted && isAudioMuted ? <SmallIconOwn icon={faMicrophoneSlash} right={3} top={3} /> : <></> }
                                                {callStarted && isVideoMuted ? <SmallIconOwn icon={faVideoSlash} right={3} top={3}/> : <></> }
                                                {callStarted && isScreenShared ? <SmallIconOwn icon={faSlideshare} right={3} top={3}/> : <></> }
                                            </ColorFade>
                                        </SmallIconsOwn>
                            <SmallIconsPartner>
                                {callStarted && isPartnerAudioMuted ? <SmallIconPartner icon={faMicrophoneSlash} left={3} top={3} /> : <></> }
                                {callStarted && isPartnerVideoMuted ? <SmallIconPartner icon={faVideoSlash} left={3} top={3}/> : <></> }
                                {callStarted && isPartnerMouseShared ? <SmallIconPartner icon={faMousePointer} left={3} top={3}/> : <></> }
                                {callStarted && isPartnerScreenShared ? <SmallIconPartner icon={faSlideshare} left={3} top={3}/> : <></> }
                            </SmallIconsPartner>
                        </CallContainer>
                        
                        <ArtvaResultBlock>
                        {(communicationStore.hnrDisplay || communicationStore.shimmerDisplay || communicationStore.fundamentalDisplay || communicationStore.jitterDisplay || communicationStore.intensityDisplay) && !!stream && 
                        <Text fontSize={2}>&nbsp;&nbsp;&nbsp;&nbsp;ARTVA feature details <br />
                        <br />
                        {communicationStore.intensityDisplay == true && <>
                        &nbsp;&nbsp;Intensity (dB)<br />
                        &emsp;Current: &emsp;&emsp; <label id = "intensityCurrent"> {getIntensityCurrent()}</label> <br />
                        &emsp;Mean: &emsp;&emsp;&emsp; <label id = "intensityMean"> {getIntensityMean()}</label>  <br />  <br />
                        </>}
                        {communicationStore.fundamentalDisplay == true && <>
                        &nbsp;&nbsp;F0 (Hz)<br />
                        &emsp;Current: &emsp;&emsp; <label id = "fundamentalCurrent"> {getFundamentalCurrent()}</label> <br />
                        &emsp;Mean: &emsp;&emsp;&emsp; <label id = "fundamentalMean"> {getFundamentalMean()}</label>  <br />  <br />
                        </>}
                        {communicationStore.hnrDisplay == true && <>
                        &nbsp;&nbsp;HNR (dB)<br />
                        &emsp;Current: &emsp;&emsp; <label id = "hnrCurrent"> {getHnrCurrent()}</label>  <br />
                        &emsp;Mean: &emsp;&emsp;&emsp; <label id = "hnrMean"> {getHnrMean()}</label>  <br />   <br /> 
                        </>}
                        {communicationStore.shimmerDisplay == true && <>
                        &nbsp;&nbsp;Shimmer (%)<br />
                        &emsp;Current: &emsp;&emsp; <label id = "shimmerCurrent"> {getShimmerCurrent()}</label>  <br />
                        &emsp;Mean: &emsp;&emsp;&emsp; <label id = "shimmerMean"> {getShimmerMean()}</label>  <br /> <br /> 
                        </>}
                        {communicationStore.jitterDisplay == true && <>
                        &nbsp;&nbsp;Jitter (%)<br />
                        &emsp;Current: &emsp;&emsp; <label id = "jitterCurrent"> {getJitterCurrent()}</label>  <br />
                        &emsp;Mean: &emsp;&emsp;&emsp; <label id = "jitterMean"> {getJitterMean()}</label>  <br /> <br /> 
                        </>}
                        <Button onClick={() => resetResultsBtn()}> Reset results </Button>
                        </Text>}
                        </ArtvaResultBlock>
                    </ExerciseAndCallBlock>
                    {location.state?.role === INITIATOR && !!stream &&  
                        <AudioAnalyzer stream={stream}/>}
                </VideoCallPageContainer>
            </ErrorBoundary>
        </ThemeProvider>
    );
});

export default withRouter(VideoCallPage);
export { resetResults };
