import React, { useEffect, useState } from 'react';
import { SelectStyle } from '../styled/select';
import { useTranslation } from "react-i18next";
import { api } from "../../api";
import Select from "react-select";



const TagSelect = (props) => {
    const { t, i18n } = useTranslation()
    const [keywords, setKeywords] = useState([]);
    const [selectedTags, setSelectedTags] = useState();
    const [loading, setLoading] = useState(true);

    const TagSelectStyle = {
        control: (styles) => ({
            ...styles,
            marginRight: "10px",
            marginBottom: "8px",
            minWidth: "250px",
            border: "1px solid #51C0CF",
            backgroundColor: "#FFFFFF",
            color: "#000000",
            boxSizing: "border-box",
            boxShadow: "0px 4px 4px rgba(197, 197, 197, 0.25)",
            borderRadius: "10px",
            outline: "none",
            fontSize: "18px",
            minHeight: "50px",
            verticalAlign: "middle",
            ":hover": {
                ...styles[":hover"],
                border: "1px solid #51C0CF",
            },
            ":active": {
                ...styles[":active"],
                border: "1px solid #51C0CF",
            },
        })
    }

    useEffect(() => {
        api.get('/tags').then((response) => {
            setKeywords(response.data.map((x) => {
                var data = {};
                data["value"] = x.name;
                data["label"] = t(x.name);
                return data;
            }))
        })
    }, []);

    useEffect(() => {
        if(selectedTags){
            props.setTags(selectedTags.map(x => x?.value))
        }
    }, [selectedTags]);

   useEffect(() => {
       if (keywords.length > 0) {
            const lst = props.currentTags?.map((x) => {
                if (x) {
                    if (typeof x === "string") {
                        var data = {};
                        const key = keywords.find(y => y.value == x);
                        return key;
                    } else {
                        var data = {};
                        data["value"] = x.name;
                        data["label"] = t(x.name);
                        return data;
                    }
                } else {
                    return [];
                }
            });
            setSelectedTags(lst);
            setLoading(false);
       }
        
        
    }, [keywords]);

    return (
        <>
            {! loading && <Select
                styles={TagSelectStyle}
                isMulti
                name="tags"
                options={keywords}
                onChange={setSelectedTags}
                defaultValue={selectedTags}
                placeholder={t("tagSelect.placeholder")}
            />}
        </>
    )
}

export default TagSelect;