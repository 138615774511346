import React from 'react'
import styled from 'styled-components'
import { useTranslation } from "react-i18next"

const Button = styled.button`
  background: ${props => props.backgroundColor};
  color: ${props => props.color};
  border-radius: 50%;
  width: 40.5px;
  height: 40.5px;
  background: rgba(227, 73, 28, 0.8);
  outline: none;
  border: none;
  cursor: pointer;
  :hover {
    background: #fb6d42;
  }
`

const RecWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ButtonBorder = styled.div`
  border: 5px solid rgba(255, 255, 255, 0.4);
  height: 50px;
  width: 50px;
  border-radius: 50%;
`
const Instructions = styled.p`
  font-size: 14px;
  color: #ffffff;
  letter-spacing: 1.75px;
  display: flex;
  margin-bottom: 20px;
`

const InstuctionsHighlight = styled.span`
  font-weight: 700;
  color: #dc6547;
  padding: 0 5px;
`

const TextCenter = styled.p`
    text-align: center;
`

Button.defaultProps = {
  color: 'black',
  backgroundColor: 'white'
}

export default props => {
  const { t, i18n } = useTranslation()
  return(
  <RecWrapper>
      <TextCenter>{t('recordButton.pressButton')}</TextCenter>

    <ButtonBorder>
      <Button {...props} />
    </ButtonBorder>
  </RecWrapper>
  )
}