import React, {useContext, useState, useEffect} from 'react'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import styled from 'styled-components'
import Button from '../components/styled/button'
import {api} from "../api"
import { H2 } from '../components/styled/typography'
import Header from '../components/header'
import Card from '../components/styled/card'
import Global from '../components/styled/global'
import LoadSpinner from '../components/loadspinner'
import Flash from "../components/styled/flashMessage"
import { useTranslation } from "react-i18next"

const LoginForm = styled(Form)`
`

const FormGroup = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
`

const Input = styled(Field)`
    background: #FFFFFF;
    border: 1px solid #51C0CF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(197, 197, 197, 0.25);
    border-radius: 10px;
    padding: 10px 10px;
    margin-top: 5px;
    outline: none;
    font-size: 16px;
`

const Label = styled.label`
    font-size: 16px;
    font-weight: 600;
    color: #545454;
`

const SubmitButton = styled(Button)`
    margin-top: 20px;
    padding-right: 45px;
    padding-left: 45px;
    font-size: 18px;
`

const Error = styled.p`
    font-size: 16px;
    color: #FFF;
    font-style: italic;
    background-color: #DC393D;
    border: 1px solid #DC393D;
    border-radius: 10px;
    padding: 10px 15px;
`

const Center = styled.div`
    text-align: center;
`

const SmallTitle = styled(H2)`
    margin-bottom: 40px;
    margin-top: 20px;
`

const PasswordCard = styled(Card)`
    width: 90%;
    margin: 5vh auto;

    @media (min-width: 768px) {
      width: 70%;
      margin: 10vh auto;
      padding: 40px 50px;
    }
`

function ResetPassword(props){
    const { t, i18n } = useTranslation()
    const token = props.match.params.token
    const [showFail, setShowFail] = useState(false)
    const [showSucces, setShowSucces] = useState(false)

    return(
        <div>
            <Header />
            <PasswordCard>
            {showSucces && <Flash green>{t('resetPassword.passSucc')}</Flash>}
            {showFail && <Flash blue>{t('resetPassword.tokenInvalid')}</Flash>}
            <Formik initialValues={{ newpassword : "", repeatnewpassword: ""}}
                onSubmit={(values, actions) => {
                    api.put('/verify_token', {password: values.newpassword, token: token}).then(function(response){
                        if (response.data.verified){
                            setShowSucces(true)
                        }else{
                            setShowFail(true)
                        }
                        actions.setSubmitting(false)
                    })
                }}
                render={({errors, touched}, isSubmitting) => (
                    <LoginForm>
                        <SmallTitle black center>{t('passwordResetForm.changeTitle')}</SmallTitle>
                        <FormGroup>
                            <Label>{t('passwordResetForm.newPass')}</Label>
                            <Input type="password" name="newpassword"/>
                            { touched.newpassword && errors.newpassword && <Error>{errors.newpassword}</Error>}
                        </FormGroup>
                        <FormGroup>
                            <Label>{t('passwordResetForm.newPass')} {t('passwordResetForm.second')}</Label>
                            <Input type="password" name="repeatnewpassword" />
                            { touched.repeatnewpassword && errors.repeatnewpassword && <Error>{errors.repeatnewpassword}</Error>}
                        </FormGroup>
                        <Center>
                            <SubmitButton disabled={isSubmitting}>{t('passwordResetForm.changeTitle')}</SubmitButton>
                        </Center>
                    </LoginForm>
                    
                )}
                validationSchema={Yup.object().shape({
                    newpassword: Yup.string().matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/,t('passwordResetForm.passRes')).required(t('passwordResetForm.giveNew')),
                    repeatnewpassword: Yup.string().test(t('passwordResetForm.match'), t('passwordResetForm.corrPass'), function(value) {
                        return this.parent.newpassword === value;}),
                })}
        />
            </PasswordCard>
        </div>
    )
}

export default ResetPassword;