const eventConstants = {
  USER: {
    LOGIN: "USER_LOGIN",
    LOGOUT: "USER_LOGOUT",
  },
  NAVIGATION: {
    GET_STARTED: "NAVIGATION_GET-STARTED",
    LIBRARY: "NAVIGATION_LIBRARY",
    CLIENTS: "NAVIGATION_CLIENTS",
    RESULTS: "NAVIGATION_RESULTS",
  },
  PRESS: {
    LIBRARY: {
      PUBLIC: "PRESS_LIBRARY_PUBLIC",
      PRIVATE: "PRESS_LIBRARY_PRIVATE",
      MEDIA: "PRESS_LIBRARY_MEDIA",
    },
    CLIENTS: {
      DETAILS: {
        PAGE: "PRESS_CLIENTS_DETAILS",
        UPDATE: "PRESS_CLIENTS_DETAILS_UPDATE",
      },
      CARE_PATHS: {
        PAGE: "PRESS_CLIENTS_CARE-PATHS",
        ADD_TASK: "PRESS_CLIENTS_CARE-PATHS_ADD-TASK",
      },
      RESULTS: {
        PAGE: "PRESS_CLIENTS_RESULTS",
      },
      START_VIDEO_CALL: "PRESS_CLIENTS_START-VIDEO-CALL",
    },
    ASSIGNMENTS: {
      START_ASSIGNMENT: "PRESS_ASSIGNMENTS_START-ASSIGNMENT",
    },
  },
};

const pageViewConstants = {
  GET_STARTED: "GET-STARTED",
  LIBRARY: "LIBRARY",
  CLIENTS: "CLIENTS",
  RESULTS: "RESULTS",
};

const typeUserConstants = {
  UNKNOWN: "UNKNOWN",
  PATIENT: "PATIENT",
  THERAPIST: "THERAPIST",
};

/**
 * Get right user type for Applicatoin Insights
 * @param {boolean} isTherapist
 * @returns {string} user type constant
 */
const getInsightUserType = (isTherapist) => {
  switch (isTherapist) {
    case undefined:
    case null:
      return typeUserConstants.UNKNOWN;
    case true:
      return typeUserConstants.THERAPIST;
    case false:
      return typeUserConstants.PATIENT;
    default:
      return typeUserConstants.UNKNOWN;
  }
};

export { eventConstants, pageViewConstants, typeUserConstants, getInsightUserType };
