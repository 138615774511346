import axios from 'axios';

const socketUrl = process.env.REACT_APP_SOCKET_URL || 'http://localhost:3001';
const communicationApiUrl = process.env.REACT_APP_API_URL || 'https://localhost:5001/communication';
const telephonApiUrl = process.env.REACT_APP_COBRAND_API || "https://localhost:5001/v1";

let communicationApi = null;
let telephonApi = null;
let socketApi = null;

const getSocketApi = () => socketApi;
const getSocketUrl = () => socketUrl;
const getCommunicationApi = () => communicationApi;
const getTelephonApi = () => telephonApi;
const getCommunicationApiUrl = () => communicationApiUrl;
const getTelephonApiUrl = () => telephonApiUrl;

const createAPI = () => {
    socketApi = axios.create({
        baseURL: getSocketUrl(),
        headers: {'x-access-token': window.localStorage.getItem('token') || ''}
    });

    communicationApi = axios.create({
        baseURL: communicationApiUrl,
        headers: {'x-access-token': window.localStorage.getItem('token') || ''}
    });

    telephonApi = axios.create({
        baseURL: telephonApiUrl,
        headers: {'x-access-token': window.localStorage.getItem('token') || ''}
    })
};

export default {
    getCommunicationApi,
    getTelephonApi,
    getCommunicationApiUrl,
    getTelephonApiUrl,
    createAPI,
    getSocketApi,
    getSocketUrl
}
