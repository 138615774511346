import React, { useState, useEffect, useContext } from "react";
import TherapistOverlay from "../../components/overlays/therapistOverlay";
import Global from "../../components/styled/global";
import FileEditForm from "../../components/fileForm/fileEditForm";
import { observer } from "mobx-react";
import { api } from "../../api";
import { PageTitle } from "../../components/styled/typography";
import PageWrapper from "../../components/styled/pageWrapper";
import LoadSpinner from "../../components/loadspinner";
import { PageAnimation } from "../../components/styled/animation";
import registrationStore from "../../components/registrationStore";
import { useTranslation } from "react-i18next";

const FileAanpassen = observer((props) => {
    const { t, i18n } = useTranslation();
    const { id } = props.match.params;
    const [file, setFile] = useState({});
    const [loading, setLoading] = useState(true);
    const store = useContext(registrationStore);
    const user = JSON.parse(store.user);

    useEffect(() => {
        api.get("/file/" + id).then((response) => {
            setFile(response.data.file);
            setLoading(false);
        });
    }, []);

    if (loading) {
        return <LoadSpinner />;
    } else {
        return (
            <Global>
                <PageWrapper>
                    <TherapistOverlay />
                    <PageAnimation>
                        <PageTitle black>{t("fileAanpassen.config")}</PageTitle>
                        <FileEditForm id={id} file={file} />
                    </PageAnimation>
                </PageWrapper>
            </Global>
        );
    }
});
export default FileAanpassen;
