import React, { useState, useEffect, useContext } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { api } from "../../api";
import LoadSpinner from "../../components/loadspinner";
import registrationStore from "../../components/registrationStore";
import TherapistOverlay from "../../components/overlays/therapistOverlay";
import { PageAnimation } from "../../components/styled/animation";
import { PageTitle } from "../../components/styled/typography";
import PageWrapper from "../../components/styled/pageWrapper";
import Global from "../../components/styled/global";
import ExerciseEditForm from "../../components/exerciseForm/exerciseEditForm";


const EditExercise = observer((props) => {
    const { t, i18n } = useTranslation();
    const { id } = props.match.params;
    const [loading, setLoading] = useState(true);
    const [exercise, setExercise] = useState({});
    const store = useContext(registrationStore);
    const user = JSON.parse(store.user);

    useEffect(() => {
        api.get("/exercise/" + id).then((response) => {
            setExercise(response.data.output);
            setLoading(false);
        });
    }, []);

    if (loading) {
        return <LoadSpinner/>;
    } else {
        return (
            <Global>
                <PageWrapper>
                    <TherapistOverlay />
                    <PageAnimation>
                        <PageTitle black>{t("editExercise.config")}</PageTitle>
                        <ExerciseEditForm exercise={exercise}></ExerciseEditForm>
                    </PageAnimation>
                </PageWrapper>
            </Global>
        )
    }
});

export default EditExercise;