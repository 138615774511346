import React, { useEffect, useState, useContext  } from "react";
import styled from "styled-components";
import MediaCard from "./mediaCard";
import { api } from "../../api";
import { useTranslation } from "react-i18next";
import registrationStore from "../registrationStore";
import Pagination from "./pagination";
import LoadSpinner from "../loadspinner";
import Filter from "../filterComponents/filter";
import LanguageStore from "../stores/languageStore";
import useLocalStorage from "../hooks/localStorage";

const Flex = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: stretch;
`;

const Exercises = styled.div`
    width: 100%;
    margin-top: 10px;
`;

const ExerciseWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

function Media(props) {
    const { t, i18n } = useTranslation();
    const store = useContext(registrationStore);
    const user = JSON.parse(store.user);
    const [loading, setLoading] = useState(true);
    const [mediaToDisplay, setMediaToDisplay] = useState([]);
    const [pagination, setPagination] = useState({
        start: 1,
        end: 12,
    });
    const [totalPages, setTotalPages] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const langStore = useContext(LanguageStore);
    const [filters, setFilters] = useLocalStorage(props.tag + 'FilterData', {
        title: "",
        language: langStore.getLanguage(i18n.language),
        discipline: user.speciality,
        subdiscipline: "",
        tags: []
    });

    function getFilterResults(data) {
        setFilters(data);
        resetPagination();
    }

    function resetPagination() {
        setPagination({
            start: 1,
            end: 12,
        });
        setCurrentPage(1);
    }

    function getMedia() {
        setLoading(true);
        const params = {
            name: filters.title,
            language: filters.language,
            tags: filters.tags,
            start: pagination.start,
            end: pagination.end,
            public: true
        };
        api.get(user.admin ? "/admin/files" : "/files/" + props.public_id, {
            params: params,
        }).then((response) => {
            setMediaToDisplay(response.data.Files);
            setTotalPages(Math.ceil(response.data.total / 12));
            setLoading(false);
        });
    }

    useEffect(() => {
        getMedia();
    }, [filters, pagination, props.refresh]);

    useEffect(() => {
        if (props.filterData !== undefined) {
            getFilterResults(props.filterData);
        }
    }, [props.filterData]);

    //pagination
    function nextPage() {
        setPagination({
            start: pagination.start + 12,
            end: pagination.end + 12,
        });
        setCurrentPage(currentPage + 1);
    }

    function prevPage() {
        setPagination({
            start: pagination.start - 12,
            end: pagination.end - 12,
        });
        setCurrentPage(currentPage - 1);
    }

    function handlePageForce(page) {
        setPagination({
            start: 12 * (page - 1) + 1,
            end: page * 12,
        });
        setCurrentPage(page);
    }

    return (
        <>
            <div>
                {!props.mediaSelect && <Filter tag={props.tag} filters={filters} useTags={true} hideDiscipline={true} filterCallback={(data) => getFilterResults(data)} small={props.small} />}
                {loading ? (
                    <LoadSpinner />
                ) : (
                        <ExerciseWrapper>
                            <Exercises>
                                <Flex>
                                    {mediaToDisplay &&
                                        mediaToDisplay.map((media, index) => {
                                            return (
                                                <MediaCard
                                                    label={"mediaCard" + index}
                                                    media={media}
                                                    callBack={props.selectCallback && ((id, title, type) => props.selectCallback(id, title, type))}
                                                    mediaSelect={props.mediaSelect}
                                                    showDescription={props.showDescription}
                                                />
                                            );
                                        })}
                                </Flex>
                            </Exercises>
                            {totalPages ? (
                                <Pagination
                                    nextFunction={nextPage}
                                    prevFunction={prevPage}
                                    totalPages={totalPages}
                                    currentPage={currentPage}
                                    pageForce={(page) => handlePageForce(page)}
                                />
                            ) : (
                                    <div>{t("materiaalRaadplegen.noMedia")}</div>
                                )}
                        </ExerciseWrapper>
                    )}
            </div>
        </>
    );
}

export default Media;
