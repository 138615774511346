import { observable, action } from "mobx"
import { createContext } from "react";

class TrajectStore {
    trajectObject = {
        id: "",
        title: ""
    }

    @observable trajectArray = []
    @observable titleInput = ""
    @observable chosenCategory = ""
    @observable chosenTrajectId = 1

    @action createObject(id, title){
        this.trajectObject.id = id
        this.trajectObject.title = title
    }

    @action setChosenCategory(category){
        this.chosenCategory = category
    }
    @action setChosenTrajectId(id){
        this.chosenTrajectId = id
    }

    @action addToArray(object){
        this.trajectArray.push(object)
    }

    @action removeFromArray(index, items){
        this.trajectArray.splice(index, items)
    }

    @action setOefeningen(newOefeningen){
        this.oefeningen = newOefeningen
    }

    @action clearArray(){
        this.trajectArray = []
    }

    @action setTitleInput(newTitle){
        this.titleInput = newTitle
    }

    @action clearInputs(){
        this.titleInput = ""
    }
    
}


export const TrajectStoreInstance = new TrajectStore()

export default createContext(TrajectStoreInstance)