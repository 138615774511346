import React, { useContext, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import { Redirect } from "react-router-dom";
import Button from "../components/styled/button";
import { api } from "../api";
import { observer } from "mobx-react-lite";
import registrationStore from "../components/registrationStore";
import Flash from "../components/styled/flashMessage";
import { useTranslation } from "react-i18next";

const DocumentForm = styled(Form)``;

const FormGroup = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
`;

const Input = styled(Field)`
    background: #ffffff;
    border: 1px solid #51c0cf;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(197, 197, 197, 0.25);
    border-radius: 10px;
    padding: 10px 10px;
    margin-top: 5px;
    outline: none;
    font-size: 16px;
`;

const Label = styled.label`
    font-size: 16px;
    font-weight: 600;
    color: #545454;
`;

const SubmitButton = styled(Button)`
    margin-top: 0px;
    padding-right: 45px;
    padding-left: 45px;
    font-size: 18px;
`;
const FlexLeft = styled.div`
    margin-top: 10px;
    display: flex;
`;

const Error = styled.p`
    font-size: 16px;
    color: #fff;
    font-style: italic;
    background-color: #dc393d;
    border: 1px solid #dc393d;
    border-radius: 10px;
    padding: 10px 15px;
`;

const Message = styled(Field)`
    background: #ffffff;
    border: 1px solid #51c0cf;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(197, 197, 197, 0.25);
    border-radius: 10px;
    padding: 10px 10px;
    margin-top: 5px;
    margin-bottom: 10px;
    outline: none;
    width: 100%;
    height: 150px;
`;

const HelpdeskForm = observer((props) => {
    const { t, i18n } = useTranslation();
    const [showFlash, setShowFlash] = useState(false);
    const store = useContext(registrationStore);
    const user = JSON.parse(store.user);
    return (
        <div>
            <Formik
                initialValues={{ email: user.email, bericht: "" }}
                onSubmit={(values, actions) => {
                    api.post("/mail", {
                        body: "request",
                        sender: "info@telephon.digital",
                        message: `
                        Afzender: ${values.email}
                        
Bericht:
${values.bericht}`,
                        subject: "Helpdesk aanvraag",
                        to: "helpdesk@telephon.digital",
                    })
                        .then(function (response) {
                            setShowFlash(true);
                            actions.resetForm();
                        })
                        .catch((e) => {
                            setShowFlash(true);
                            actions.resetForm();
                        });
                }}
                render={({ errors, touched, values }, isSubmitting) => (
                    <DocumentForm>
                        {showFlash && <Flash green>{t("helpdeskForm.sentSucc")}</Flash>}
                        <FormGroup>
                            <Label>{t("helpdeskForm.emailLbl")}</Label>
                            <p>{user.email}</p>
                            {touched.email && errors.email && <Error>{errors.email}</Error>}
                        </FormGroup>
                        <FormGroup>
                            <Label>{t("helpdeskForm.msg")}</Label>
                            <Message
                                component="textarea"
                                type="text"
                                name="bericht"
                                placeholder={t("helpdeskForm.msgPlace")}
                            />
                            {touched.bericht && errors.bericht && <Error>{errors.bericht}</Error>}
                        </FormGroup>
                        <SubmitButton disabled={isSubmitting}>{t("helpdeskForm.sent")}</SubmitButton>
                    </DocumentForm>
                )}
                validationSchema={Yup.object().shape({
                    email: Yup.string().required(t("helpdeskForm.emailPlace")),
                    email: Yup.string().email(t("helpdeskForm.corrEmailPlace")),
                    bericht: Yup.string().required(t("helpdeskForm.messagePlace")),
                })}
            />
        </div>
    );
});

export default HelpdeskForm;