import React, { useState } from "react";
import styled from "styled-components";

const CodeFlex = styled.div`
    display: flex;
    align-items: center;
`;

const Input = styled.input`
    padding: 5px;
    font-size: 16px;
    width: 22px;
    height: 22px;
    margin: 5px;
    border: 1px solid #51c0cf;
    border-radius: 5px;
    text-align: center;
    margin-bottom: 20px;

    &:focus {
        box-shadow: 0 0 10px #51c0cf;
        outline: none;
    }

    @media (min-width: 768px) {
        padding: 7px;
        font-size: 18px;
        width: 40px;
        height: 40px;
        margin: 7px;
        margin-bottom: 20px;
    }
    @media (min-width: 980px) {
        padding: 10px;
        font-size: 20px;
        width: 50px;
        height: 50px;
        margin: 10px;
    }
`;

const VerifyCode = (props) => {
    const [part1, setPart1] = useState("");
    const [part2, setPart2] = useState("");
    const [part3, setPart3] = useState("");
    const [part4, setPart4] = useState("");
    const [part5, setPart5] = useState("");
    const [part6, setPart6] = useState("");

    const HandleChangePart1 = (e) => {
        setPart1(e.target.value);
        props.setCode(e.target.value + part2 + part3 + part4 + part5 + part6);
        ChangeInputField(e);
    };
    const HandleChangePart2 = (e) => {
        setPart2(e.target.value);
        props.setCode(part1 + e.target.value + part3 + part4 + part5 + part6);
        ChangeInputField(e);
    };
    const HandleChangePart3 = (e) => {
        setPart3(e.target.value);
        props.setCode(part1 + part2 + e.target.value + part4 + part5 + part6);
        ChangeInputField(e);
    };
    const HandleChangePart4 = (e) => {
        setPart4(e.target.value);
        props.setCode(part1 + part2 + part3 + e.target.value + part5 + part6);
        ChangeInputField(e);
    };
    const HandleChangePart5 = (e) => {
        setPart5(e.target.value);
        props.setCode(part1 + part2 + part3 + part4 + e.target.value + part6);
        ChangeInputField(e);
    };
    const HandleChangePart6 = (e) => {
        setPart6(e.target.value);
        props.setCode(part1 + part2 + part3 + part4 + part5 + e.target.value);
        ChangeInputField(e);
    };

    const ChangeInputField = (e) => {
        if (e.target.value.length === 1) {
            if (e.target.nextSibling) {
                e.target.nextSibling.focus();
            }
        }
    };

    return (
        <CodeFlex>
            <Input type="text" onChange={HandleChangePart1} maxLength="1" tabIndex="2" autoComplete="off" />
            <Input type="text" onChange={HandleChangePart2} maxLength="1" tabIndex="2" autoComplete="off" />
            <Input type="text" onChange={HandleChangePart3} maxLength="1" tabIndex="2" autoComplete="off" />
            <Input type="text" onChange={HandleChangePart4} maxLength="1" tabIndex="2" autoComplete="off" />
            <Input type="text" onChange={HandleChangePart5} maxLength="1" tabIndex="2" autoComplete="off" />
            <Input type="text" onChange={HandleChangePart6} maxLength="1" tabIndex="2" autoComplete="off" />
        </CodeFlex>
    );
};

export default VerifyCode;
