import React, { useContext, useState } from "react";
import styled from "styled-components";
import { observer } from "mobx-react-lite";
import { Redirect } from "react-router-dom";
import ResultStore from "../components/resultStore";
import registrationStore from "../components/registrationStore";
import Card from "../components/styled/card";
import Button from "../components/styled/button";
import { SmallTitle, P } from "../components/styled/typography";
import { api, recordingApi } from "../api";
import { useTranslation } from "react-i18next";
import LoadSpinner from "../components/loadspinner";
import Flash from "../components/styled/flashMessage";

const Wrapper = styled(Card)`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 20px 40px 40px 40px;

    @media (min-width: 980px) {
        padding: 100px 60px;
        width: 70%;
    }
`;

const Flex = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: 980px) {
        flex-direction: row;
        justify-content: space-between;
    }
`;

const Title = styled(SmallTitle)`
    text-align: center;
    margin-bottom: 15px;

    @media (min-width: 980px) {
        margin-bottom: auto;
    }
`;

const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px auto;

    @media (min-width: 980px) {
        margin: 40px 20px;
    }
`;

const BulletGreen = styled.div`
    width: 30px;
    height: 30px;
    background-color: #24ae5f;
    border: 4px solid #24ae5f;
    border-radius: 100%;
    margin-right: 10px;
    cursor: pointer;
    box-sizing: content-box;

    &.active {
        border: 4px solid #51c0cf;
    }

    @media (min-width: 980px) {
        width: 40px;
        height: 40px;
    }
`;

const BulletOrange = styled(BulletGreen)`
    background-color: #f0c239;
    border-color: #f0c239;
`;

const BulletRed = styled(BulletGreen)`
    background-color: #f23539;
    border-color: #f23539;
`;

const Bold = styled(P)`
    margin: 0px;
    font-weight: bold;
    margin-top: 10px;
    text-align: center;
`;

const SubmitButton = styled(Button)`
    margin-top: 20px;
    @media (min-width: 980px) {
        margin-top: auto;
    }
`;

const ResultForm = observer((props) => {
    const { t, i18n } = useTranslation();
    const store = useContext(ResultStore);
    const id = props.id;
    const regstore = useContext(registrationStore);
    const user = JSON.parse(regstore.user);
    const [redirect, setRedirect] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [uploadFailed, setUploadFailed] = useState(false);

    function handleClick(value) {
        let date = new Date();
        date = date.toLocaleDateString('en-US', {day: '2-digit', month: '2-digit', year: 'numeric'});
        store.setDate(date);

        if (value == 3) {
            store.setResult(3);
        } else if (value == 2) {
            store.setResult(2);
        } else {
            store.setResult(1);
        }
    }

    function HandleSubmit() {
        if (store.result == 0 || store.result == "") {
            setError(true);
        } else {
            setLoading(true);
            setError(false);
            var endTime = Date.now();
            const duration = endTime - props.startTime;
            if (store.recording !== undefined) {
                let recordingUrl;
                recordingApi.post("/recording", store.recording, {
                    headers: { "Content-Type": `multipart/form-data; boundary=${store.recording._boundary}` },
                }).then((response) => {
                    recordingUrl = response.data.key;
                    if (recordingUrl) {
                        if (!props.exerciseType === "Stappenplan"){
                            api.post("/task", {
                                task_id: id,
                                result: store.result,
                                duration: duration,
                                recording: recordingUrl,
                                results: props.results,
                                scores: props.scores,
                                exerciseType: props.exerciseType,
                                user_id: user.public_id,
                            }).then(function (response) {
                                store.clearRecording();
                                setLoading(false);
                                setRedirect(true);
                            });
                        } else {
                            api.post("/task", {
                                task_id: id,
                                result: store.result,
                                recording: recordingUrl,
                                duration: duration,
                                exerciseType: props.exerciseType,
                                user_id: user.public_id,
                            }).then(function (response) {
                                store.clearRecording();
                                setLoading(false);
                                setRedirect(true);
                            });
                        }
                    } else {
                        setUploadFailed(true)
                        setLoading(false);
                    }
                    
                });
            } else if (props.exerciseType !== "Stappenplan") {
                api.post("/task", {
                    task_id: id,
                    result: store.result,
                    duration: duration,
                    results: props.results,
                    exerciseType: props.exerciseType,
                    scores: props.scores,
                    user_id: user.public_id,
                }).then(function (response) {
                    setLoading(false);
                    setRedirect(true);
                });
            } else {
                api.post("/task", {
                    task_id: id,
                    result: store.result,
                    exerciseType: props.exerciseType,
                    duration: duration,
                    user_id: user.public_id,
                }).then(function (response) {
                    setLoading(false);
                    setRedirect(true);
                });
            }
        }
    }

    return (
        <Wrapper>
            {! loading && 
                <>
                    <Title black>{t("resultForm.difficulty")}</Title>
                    {error && <Flash red>{t("resultForm.NothingSelectedError")}</Flash>}
                    <Flex>
                        <FlexColumn>
                            <BulletGreen className={store.result == 3 ? "active" : ""} onClick={() => handleClick(3)} />
                            <Bold>{t("legend.easy")}</Bold>
                        </FlexColumn>
                        <FlexColumn>
                            <BulletOrange className={store.result == 2 ? "active" : ""} onClick={() => handleClick(2)} />
                            <Bold>{t("legend.average")}</Bold>
                        </FlexColumn>
                        <FlexColumn>
                            <BulletRed className={store.result == 1 ? "active" : ""} onClick={() => handleClick(1)} />
                            <Bold>{t("legend.hard")}</Bold>
                        </FlexColumn>
                    </Flex>
                    {uploadFailed && <Flash red>{t("resultForm.UploadFailedRetry")}</Flash>}
                    <SubmitButton onClick={HandleSubmit}>{t("resultForm.endExer")}</SubmitButton>
                </>
            }
            {loading && 
                <>
                    <Title black>{t("resultForm.uploadingTitle")}</Title>
                    <p>{t("resultForm.uploadingMessage")}</p>
                    <LoadSpinner />
                </>
            }
            {redirect && <Redirect to="/p/Assignments" />}
        </Wrapper>
    );
});

export default ResultForm;
