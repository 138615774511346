import React, { useEffect, useState, useContext } from "react";
import PatientOverlay from "../../components/overlays/patientOverlay";
import Global from "../../components/styled/global";
import PageWrapper from "../../components/styled/pageWrapper";
import { api } from "../../api";
import Dagboek from "../../components/exercises/dagboek";
import Stappenplan from "../../components/exercises/stappenplan";
import StappenplanMetValidatie from "../../components/exercises/stappenplanMetValidatie";
import LoadSpinner from "../../components/loadspinner";
import { observer } from "mobx-react";
import Actions from "../../components/exerciseUtilities/videoRecorder/videoRenderActions";
import ResultStore from "../../components/resultStore";
import { useTranslation } from "react-i18next";
import { getResourceUrl } from "../../azure";

const TaakDetail = observer((props) => {
    const { t, i18n } = useTranslation();
    const Rstore = useContext(ResultStore);
    const { id } = props.match.params;
    const [exercise, setExercise] = useState({});
    const [taskDescription, setTaskDescriptions] = useState("");
    const [loading, setLoading] = useState(true);
    let exerciseComponent;
    let startTime = Date.now();

    useEffect(() => {
        api.get("/task/" + id).then((response) => {
            setTaskDescriptions(response.data.task.description);
            api.get("/exercise/" + response.data.task.task_id).then((response) => {
                setExercise(response.data.output);
                setLoading(false);
            });
        });
    }, []);

    async function GetRessource(id) {
        return api.get("/file/" + id).then((response) => {
            return getResourceUrl(response.data.file) 
        })
    }

    const HandleRecording = (blob) => {
        let formData = new FormData();
        formData.append("recording", blob);
        Rstore.setRecording(formData);
    };

    if (! exercise) {
        console.log('oops something went wrong');
    } else {
        switch (exercise.type) {
            case "dagboek":
                exerciseComponent = <Dagboek
                    exercise={exercise}
                    id={id}
                    taskDescription={taskDescription}
                    startTime={startTime}
                    getRessource={GetRessource}
                    redirectEnabled={true}
                    showRecorder={true}
                    HandleRecording={HandleRecording}
                />
                break;
            case "stappenplan":
                exerciseComponent = <Stappenplan
                    exercise={exercise}
                    id={id}
                    taskDescription={taskDescription}
                    startTime={startTime}
                    showRecorder={true}
                    getRessource={GetRessource}
                    redirectEnabled={true}
                    Actions={Actions}
                    HandleRecording={HandleRecording}
                />
                break;
            case "stappenplanValidatie":
                exerciseComponent = <StappenplanMetValidatie
                    exercise={exercise}
                    id={id}
                    taskDescription={taskDescription}
                    startTime={startTime}
                    getRessource={GetRessource}
                    showRecorder={true}
                    redirectEnabled={true}
                    HandleRecording={HandleRecording}
                />
                break;
            default:
                exerciseComponent = <div>{t("taakDetail.wrong")}</div>
                break;
        };
    }

    return (loading ? <LoadSpinner /> :
        <Global>
            <PageWrapper>
                <PatientOverlay />
                {exerciseComponent}
            </PageWrapper>
        </Global>
    );
});

export default TaakDetail;
