import api from '../api';

const createChat = (body) => api.getCommunicationApi().post(`/chats`, body);
const getChats = () => api.getCommunicationApi()?.get(`/chats`);
const getChat = (id) => api.getCommunicationApi()?.get(`/chats/${id}`);
const finishChat = (id) => api.getCommunicationApi().put(`/chats/${id}`, {id, finished: true});
const setViewed = (id) => api.getCommunicationApi().put(`/chats/${id}`, {id, counts: 0});
const sendMessage = (id, message, user_id) => api.getCommunicationApi().put(`/chats/${id}/message`, {id, new_message: message, instigator_id: user_id});

export default {
    createChat,
    getChats,
    getChat,
    finishChat,
    setViewed,
    sendMessage
}
