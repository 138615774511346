import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { FaAngleLeft, FaAngleRight, FaAngleDoubleLeft, FaAngleDoubleRight } from "react-icons/fa";
import { P } from "../styled/typography";

const Wrapper = styled.div`
    display: flex;
    align-items: center;
`;

const IconArrowRight = styled(FaAngleRight)`
    color: #51c0cf;
    margin: 0px 5px;
    cursor: pointer;
    font-size: 25px;
`;

const IconArrowLeft = styled(FaAngleLeft)`
    color: #51c0cf;
    margin: 0px 5px;
    cursor: pointer;
    font-size: 25px;
`;

const IconDoubleArrowLeft = styled(FaAngleDoubleLeft)`
    color: #51c0cf;
    margin: 0px 5px;
    cursor: pointer;
    font-size: 25px;
`;

const IconDoubleArrowRight = styled(FaAngleDoubleRight)`
    color: #51c0cf;
    margin: 0px 5px;
    cursor: pointer;
    font-size: 25px;
`;

const PageNumber = styled(P)`
    margin: 0px 7px;
    display: inlne;
    cursor: pointer;
    outline: none;
    font-size: 20px;

    &.active {
        color: white;
        background-color: #51c0cf;
        border: 1px solid #51c0cf;
        padding: 2px 5px 4px 5px;
        border-radius: 30px;
    }

    @media (min-width: 980%) {
        margin: 0px 3px;
    }
`;

const PaginationWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px 5px;
`;

function Pagination(props) {
    const [pages, setPages] = useState([]);

    function generateIndexList() {
        let index_list = [];
        for (let i = 1; i < props.totalPages + 1; i++) {
            index_list.push(i);
        }
        return index_list;
    }

    useEffect(() => {
        let index_list = generateIndexList();
        if (index_list.length <= 4) {
            setPages(index_list);
        } else {
            setPages(index_list.slice(props.currentPage - 1, props.currentPage + 3));
        }
    }, []);

    useEffect(() => {
        let index_list = generateIndexList();
        if (index_list.length <= 4) {
            setPages(index_list);
        } else {
            setPages(index_list.slice(props.currentPage - 1, props.currentPage + 3));
        }
    }, [props.totalPages]);

    function pageSlicer() {
        let index_list = generateIndexList();
        if (props.currentPage >= 2) {
            setPages(index_list.slice(props.currentPage - 2, props.currentPage + 2));
        } else {
            setPages(index_list.slice(props.currentPage - 1, props.currentPage + 3));
        }
    }

    function handlePageForce(page) {
        props.pageForce(page);
    }

    function nextPage() {
        props.nextFunction();
        pageSlicer();
    }

    function prevPage() {
        props.prevFunction();
        pageSlicer();
    }

    function lastPage() {
        props.pageForce(props.totalPages);
    }

    function firstPage() {
        props.pageForce(1);
    }

    useEffect(() => {
        pageSlicer();
    }, [props.currentPage]);

    return (
        <Wrapper>
            {props.currentPage !== 1 && (
                <>
                    <IconDoubleArrowLeft onClick={firstPage} />
                    <IconArrowLeft onClick={prevPage} />
                </>
            )}

            <PaginationWrapper>
                {pages &&
                    props.totalPages >= 2 &&
                    pages.map((page) => {
                        return (
                            <PageNumber
                                className={props.currentPage == page ? "active" : ""}
                                onClick={() => handlePageForce(page)}
                            >
                                {page}
                            </PageNumber>
                        );
                    })}
            </PaginationWrapper>
            {props.currentPage !== props.totalPages && (
                <>
                    <IconArrowRight onClick={nextPage} />
                    <IconDoubleArrowRight onClick={lastPage} />
                </>
            )}
        </Wrapper>
    );
}

export default Pagination;
