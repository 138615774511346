import React, { useContext, useState } from "react";
import styled from "styled-components";
import TherapistOverlay from "../../components/overlays/therapistOverlay";
import Global from "../../components/styled/global";
import GegevensForm from "../../components/gegevensForm";
import PasswordResetForm from "../../components/passwordResetForm";
import AccountNavigation from "../../components/accountNavigation";
import PageWrapper from "../../components/styled/pageWrapper";
import { PageTitle, SmallTitle } from "../../components/styled/typography";
import { PageAnimation } from "../../components/styled/animation";
import Button from "../../components/styled/button";
import registrationStore from "../../components/registrationStore";
import { observer } from "mobx-react";
import Flash from "../../components/styled/flashMessage";
import { useTranslation } from "react-i18next";
import { api } from "../../api";
import createBertie, {
    createBertieDysfonie,
    createBertieDysfagie,
    createBertieAfasie,
    createBertieEmpty
} from "../../components/helperComponents/createBertie";
import createBertieEN from "../../components/helperComponents/createBertieEN";
import LoadSpinner from "../../communication-module/components/external/loadspinner";

const Flex = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 20px;

    @media (min-width: 980px) {
        flex-direction: row;
        justify-content: space-between;
    }
`;

const OneThird = styled.div`
    width: 100%;
    margin-bottom: 25px;
    @media (min-width: 980px) {
        width: 62%;
        margin-bottom: 0px;
    }
`;

const TwoThird = styled.div`
    width: 100%;
    margin-bottom: 25px;
    @media (min-width: 980px) {
        width: 33%;
        margin-bottom: 0px;
    }
`;

const Select = styled.select`
    background: #ffffff;
    border: 1px solid #51c0cf;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(197, 197, 197, 0.25);
    border-radius: 10px;
    padding: 10px 10px;
    outline: none;
    font-size: 16px;
    margin: 10px 0px;
    width: 100%;
`;

const Input = styled.input`
    background: #FFFFFF;
    border: 1px solid #51C0CF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(197, 197, 197, 0.25);
    border-radius: 10px;
    padding: 10px 10px;
    margin-top: 5px;
    outline: none;
    font-size: 16px;

    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        opacity: 1; /* Firefox */
        font-style: italic;
    }
    
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
        font-style: italic;
    }
    
    ::-ms-input-placeholder { /* Microsoft Edge */
        font-style: italic;
    }
`

const MijnAccount = observer(() => {
    const { t, i18n } = useTranslation();
    const store = useContext(registrationStore);
    const user = JSON.parse(store.user);
    const [showFlash, setShowFlash] = useState(false);
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [bertieType, setBertieType] = useState("Empty");
    const [bertieName, setBertieName] = useState("Bertie");

    const flash = () => {
        setShowFlash(true);
    };

    const createBerties = async (therapist_id) => {
        setLoading(true);
        if (i18n.language === "nl") {
            if (bertieType === "Empty") {
                await createBertieEmpty(therapist_id, bertieName);
            }else if (bertieType === "Afasie") {
                await createBertieAfasie(therapist_id, bertieName);
            } else if (bertieType === "Dysfonie") {
                await createBertieDysfonie(therapist_id, bertieName);
            } else if (bertieType === "Dysfagie") {
                await createBertieDysfagie(therapist_id, bertieName);
            }
        } else {
            await createBertieEmpty(therapist_id, bertieName);
        }
        setSuccess(true);
        setLoading(false);
    };

    const redoOnboarding = () => {
        api.put(`/user/${user.public_id}`, {
            isOnboarded: false,
        }).then((res) => {
            store.setUser(JSON.stringify(res.data.user));
        });
    };
    return (
        <Global>
            <PageWrapper>
                <TherapistOverlay />
                <PageAnimation>
                    <PageTitle black>{t("sidebarPatient.acc")}</PageTitle>
                    <AccountNavigation therapist={user.therapist} />
                    {showFlash && <Flash>{t("mijnAccount.dataConf")}</Flash>}
                    {success && <Flash green>Bertie werd succesvol aangemaakt</Flash>}
                    <Flex>
                        <OneThird>
                            <GegevensForm user={user} flash={flash} />
                        </OneThird>
                        <TwoThird>
                            <PasswordResetForm user={user} flash={flash} />
                            <SmallTitle black>{t("mijnAccount.Rondleiding")}</SmallTitle>
                            <Button small onClick={redoOnboarding}>
                                {t("mijnAccount.Rondleidingherbekijken")}
                            </Button>
                            <SmallTitle black>Berties</SmallTitle>
                            {loading ? (
                                <LoadSpinner/>
                            ) : (
                                <>
                                    <label htmlFor="bertieName">{t("bertieCreate.name")} </label>
                                    <Input name="bertieName" type='text' onChange={(e) => setBertieName(e.target?.value)} placeholder={`${bertieName}...`} />
                                    <Select component="select" onChange={(e) => setBertieType(e.target?.value)}>
                                        <option value="Empty">{t("bertieCreate.empty")}</option>
                                        {i18n.language === "nl" && (
                                            <>
                                                <option value="Afasie">{t("bertieCreate.afasie")}</option>
                                                <option value="Dysfonie">{t("bertieCreate.dysfonie")}</option>
                                                <option value="Dysfagie">{t("bertieCreate.dysfagie")}</option>
                                            </>
                                        )}
                                    </Select>
                                    <Button small onClick={() => createBerties(user.public_id)}>
                                        Aanmaken
                                    </Button>
                                </>
                            )}
                        </TwoThird>
                    </Flex>
                </PageAnimation>
            </PageWrapper>
        </Global>
    );
});

export default MijnAccount;
