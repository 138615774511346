import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {Line} from 'react-chartjs-2';
import extendedTheme from '../theme';

const GraphContainer = styled.div`
    width: 250px;
    height: 200px;
`;

const GraphDrawer = (props) => {
    const requestRef = useRef();
    const fps = 15;
    const secondsToShow = 30;
    const labels = Array.from({length: fps * secondsToShow}, () => 0);
    const [data, setData] = useState({
        labels: labels,
        datasets: [
            {
                label: props.title,
                fill: false,
                lineTension: 1,
                backgroundColor: extendedTheme.colors.primary,
                borderColor: extendedTheme.colors.primary,
                pointDot: true,
                data: labels,
            }
        ]
    });

    useEffect(() => {
        if (props.hasRemoteStream) {
            props.initializeAudioAnalyser();
            requestRef.current = requestAnimationFrame(runSpectrum);
            return () => cancelAnimationFrame(requestRef.current);
        }
    }, [props.hasRemoteStream]);

    const runSpectrum = () => {
        props.getData(plotData);
        requestRef.current = requestAnimationFrame(runSpectrum);
    };

    const plotData = (freq) => {
        const dataset = data.datasets[0];
        const newState = {
            ...data,
            datasets: [{
                ...dataset,
                data: [...dataset.data, ...[freq]].slice(-(fps * secondsToShow))
            }]
        };
        setData(newState);
    };

    return (
        <GraphContainer>
            <Line
                data={data}
                options={{
                    elements: {point: {radius: 0}},
                    maintainAspectRatio: false,
                    tooltips: {enabled: false},
                    hover: {mode: null},
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                                min: props.yMin,
                                max: props.yMax
                            },
                            scaleLabel: {
                                display: true,
                                labelString: props.unit
                            }
                        }],
                        xAxes: [{
                            ticks: {
                                autoSkip: false,
                                maxTicksLimit: 60,
                            },
                            display: false,
                        }]
                    }
                }}
            />
        </GraphContainer>
    );
};

export default GraphDrawer;
