import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import {FaCalendar, FaEdit, FaTimes} from "react-icons/fa";
import Card from "../components/styled/card";
import ProgressBar from "../components/progressBar";
import TaskInAssignmentSmall from "../components/taskInAssignmentSmall";
import { P } from "../components/styled/typography";
import Button from "../components/styled/button";
import { api } from "../api";
import registrationStore from "../components/registrationStore";
import { useTranslation } from "react-i18next";

const FlexBetween = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    @media (min-width: 980px) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
`;

const AssignmentLargeCard = styled(Card)`
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0.5px solid #aeadad;
    width: 100%;

    @media (min-width: 980px) {
        margin-top: 10px;
        margin-bottom: 10px;
        width: 100%;
        height: 95%;
    }
`;

const Bold = styled(P)`
    font-weight: bold;
    margin: 0px;
`;

const Text = styled(P)`
    margin: 0px;
`;

const RedButton = styled(Button)`
    background-color: #dc393d;
    border-color: #dc393d;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    margin: 3px 0px;
    width: 100%;

    &:hover {
        background-color: #b02a2e;
        border-color: #b02a2e;
    }
`;

const GreenButton = styled(Button)`
    background-color: #45ae7c;
    border-color: #45ae7c;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    margin: 3px 0px;
    width: 100%;

    &:hover {
        background-color: #3a9168;
        border-color: #3a9168;
    }
`;

const ProgBar = styled.div`
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;

    @media (min-width: 980px) {
        width: 400px;
        margin-top: auto;
        margin-bottom: auto;
    }
`;

const Flex = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;

    @media (min-width: 980px) {
        margin-top: auto;
        justify-content: space-between;
    }
`;

const EditIcon = styled(FaEdit)`
    color: #ffffff;
    margin-right: 5px;
    font-size: 16px;
`;

const DeleteIcon = styled(FaTimes)`
    color: #ffffff;
    margin-right: 5px;
    font-size: 16px;
`;

const FlexStart = styled.div`
    display: flex;
    align-items: flex-start;
    margin-top: 15px;
`;

const DatesText = styled(P)`
    margin: 0px 0px 0.4em 0.5em;
    font-size: 0.7em;

    letter-spacing: 0.02em;
    cursor: default;
    border-bottom: grey 1px dotted;
`;
const CalendarIcon = styled(FaCalendar)`
  font-size: 0.7em;
  margin: 0px 5px 0px 0px;
`;

const TitleBold = styled.span`
    font-weight: bold;
`;
function AssignmentCard(props) {
    const { t, i18n } = useTranslation();
    const store = useContext(registrationStore);
    const user = JSON.parse(store.user);
    const [sortedDates, setSortedDates] = useState([]);

    useEffect(() => {
        getSortedDates();
    }, []);

    const handleDelete = () => {
        api.delete("/assignment/" + props.assignment.id).then(function (response) {
            props.update();
        });
    };

    const getSortedDates = () => {
        var sortedDates = [];
        for (let index = 0; index < props.assignment.tasks.length; index++) {
            const task = props.assignment.tasks[index];
            if (task.dates != null) sortedDates.push(...task.dates);
        }
        sortedDates.sort((a,b) => Date.parse(a.date) - Date.parse(b.date));
        setSortedDates(sortedDates);
    }

    return (
        <AssignmentLargeCard>
            <FlexBetween>
                <div>
                    <DatesText style={{textTransform: 'capitalize'}}>
                        <TitleBold>{props.assignment.title}</TitleBold> - <CalendarIcon></CalendarIcon>{new Date(sortedDates[0]?.date).toLocaleDateString(i18n.language)} &raquo; {new Date(sortedDates[sortedDates.length - 1]?.date).toLocaleDateString(i18n.language)}
                    </DatesText>
                </div>

                {!user.therapist && (
                    <ProgBar>
                        <ProgressBar title={props.assignment.title} progress={props.assignment.progress} />
                    </ProgBar>
                )}
            </FlexBetween>
            <Flex>
                {props.assignment.tasks.map((task) => (
                    <TaskInAssignmentSmall key={task.id} task={task} />
                ))}
            </Flex>
            <FlexStart>
                <div style={{margin: '5px'}}>
                    {user.therapist ? (
                        <RedButton onClick={handleDelete}>
                            <DeleteIcon />
                            {t("assignmentCard.delete")}
                        </RedButton>
                    ) : (
                        ""
                    )}
                </div>
            </FlexStart>
        </AssignmentLargeCard>
    );
}

export default AssignmentCard;
