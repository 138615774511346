import styled from 'styled-components'

const Button = styled.button`
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    border: ${props => props.secondary ? "2px solid #fff" : "2px solid #51C0CF"};
    border-radius: 5px;
    background-color: ${props => props.secondary ? "rgba(0,0,0,0)" : "#51C0CF" };
    color: #fff;
    padding: ${props => props.small ? "5px 10px" : props.large ? "12px 15px" : "7px 15px"};
    font-size: ${props => props.small ? "16px" : props.large ? "22px" : "18px"};
    outline: none;
    transition: all .5s ease-out;

    &:disabled{
        opacity: 0.5;
    }

    &:hover{
        background-color: ${props => props.secondary ? "#51C0CF" : "#469CA8" };
        border: ${props => props.secondary ? "2px solid #51C0CF" : "2px solid #469CA8"};
        color: ${props => props.secondary ? "#FFF" : "#FFF"};
        transition: all .5s ease-out;
    }

`

export default Button