import React, {useEffect, useContext} from 'react';
import {observer} from 'mobx-react-lite';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import CommunicationStore from '../../stores/communication.store';
import AuthenticationStore from '../../stores/authentication.store';

const Initialize = observer(({history, location, userId}) => {
    const communicationStore = useContext(CommunicationStore);
    const authenticationStore = useContext(AuthenticationStore);

    useEffect(() => {
        communicationStore.initialize(userId);
    }, [userId]);

    // useEffect(() => {
    //     communicationStore.initializeData(userId);
    // }, []);

    useEffect(() => {
        if (authenticationStore.redirectToLogin) {
            history.replace('/login');
            authenticationStore.setRedirectToLogin(false);
        }
    }, [authenticationStore.redirectToLogin]);

    // on call finished navigate back to the overview
    useEffect(() => {
        if (communicationStore.isCallFinished && location.pathname === '/video-chat') {
            history.goBack();
        }
        communicationStore.setIsCallFinished(false);
    }, [communicationStore.isCallFinished]);

    return null;
});

Initialize.propTypes = {
    userId: PropTypes.string.isRequired
};

export default withRouter(Initialize);
