import styled from 'styled-components'

export const H1 = styled.h1`
    color: ${props => props.white ? "#FFFFFF" : props.black ? "#545454" : "#51C0CF"};
    font-size: 32px;
    text-align: ${props => props.center ? "center" : "left"};
    margin-top: 0px;
    margin-bottom: 0px;

    @media (min-width: 980px) {
        font-size: 40px
    }
`;

export const H2 = styled.h2`
    color: ${props => props.white? "#FFFFFF" : props.black ? "#545454" : "#51C0CF"};
    font-size: 28px;
    text-align: ${props => props.center ? "center" : "left"};
    margin-top: 0px;
    margin-bottom: 0px;
    @media (min-width: 980px) {
        font-size: 32px
    }
`

export const H3 = styled.h3`
    color: ${props => props.white? "#FFFFFF" : props.black ? "#545454" : "#51C0CF"};
    font-size: 22px;
    text-align: ${props => props.center ? "center" : "left"};
    font-weight: ${props => props.light ? "500" : "auto"};
    margin-top: 0px;
    margin-bottom: 0px;
    @media (min-width: 980px) {
        font-size: 28px
    }
`

export const H4 = styled.h4`
    color: ${props => props.white? "#FFFFFF" : props.black ? "#545454" : "#51C0CF"};
    font-size: 18px;
    text-align: ${props => props.center ? "center" : "left"};
    font-weight: ${props => props.light ? "500" : "auto"};
    margin-top: 30px;
    margin-bottom: 30px;
    @media (min-width: 980px) {
        font-size: 24px
    }
`

export const P = styled.p`
    color: ${props => props.white? "#FFFFFF" : props.black ? "#545454" : "#929292"};
    text-align: ${props => props.center ? "center" : "left"};
    font-size: 18px;
    margin-top: 30px;
    margin-bottom: 30px;
`

export const Bold = styled.span`
    font-weight: bold;
`

export const PageTitle = styled.h2`
    color: ${props => props.white? "#FFFFFF" : props.black ? "#545454" : "#51C0CF"};
    font-size: 28px;
    text-align: ${props => props.center ? "center" : "left"};
    @media (min-width: 980px) {
        font-size: 32px
    }
`

export const SmallTitle = styled(H4)`
    margin-bottom: 10px;
`
