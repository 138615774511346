import React, { useContext } from "react";
import registrationStore from "./registrationStore";
import notificationStore from "./notificationStore";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Button from "../components/styled/button";
import { P } from "../components/styled/typography";
import { IoIosClose } from "react-icons/io";
import TherapistIcon from "../assets/images/therapist-icon-man.png";
import { api } from "../api";
import { useTranslation } from "react-i18next";


const Wrapper = styled.div`
    background-color: #ffffff;
    box-sizing: border-box;
    padding: 15px;
    border: 1px solid #ffffff;
    box-shadow: 0px 4px 4px rgba(197, 197, 197, 0.25);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 15px auto;

    @media (min-width: 980px) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
`;

const User = styled(P)`
    font-weight: 700;
    color: #51c0cf;
    margin-top: 0px;
    margin-bottom: 0px;
`;

const Flex = styled.div`
    display: flex;

    flex-direction: column;

    @media (min-width: 980px) {
        flex-direction: row;
        align-items: center;
        width: 95%;
    }
`;

const Message = styled.div`
    padding-left: 0px;
    padding-top: 5px;
    @media (min-width: 980px) {
        padding-left: 45px;
        padding-top: 0px;
    }
`;

const Text = styled(P)`
    padding-right: 2px;
    margin-top: 0px;
    margin-bottom: 0px;
`;

const UserIcon = styled.img`
    width: 25px;
    height: 25px;
    margin-right: 5px;

    @media (min-width: 980px) {
        width: 25px;
        height: 25px;
        margin-right: 15px;
    }
`;

const Icon = styled(IoIosClose)`
    color: #51c0cf;
    font-size: 40px;
    cursor: pointer;
    position: absolute;
    top: 5px;
    right: 5px;

    @media (min-width: 980px) {
        position: static;
    }
`;

const MobileFlex = styled.div`
    display: flex;
    flex-direction: row;
    padding-bottom: 5px;
    align-items: flex-end;

    @media (min-width: 980px) {
        flex-direction: row;
        padding-bottom: 0px;
        align-items: center;
    }
`;

const TextFlex = styled.div`
    display: flex;

    @media (min-width: 980px) {
        display: block;
    }
`;

const GreenButton = styled(Button)`
    background-color: #45ae7c;
    border-color: #45ae7c;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    margin: 3px 3px;
    width: 100%;

    &:hover {
        background-color: #3a9168;
        border-color: #3a9168;
    }
`;

const A = styled(Link)`
    text-decoration: none;
`
const RedButton = styled(Button)`
    background-color: #dc393d;
    border-color: #dc393d;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    margin: 3px 3px;
    width: 100%;

    &:hover {
        background-color: #b02a2e;
        border-color: #b02a2e;
    }
`;

const FlexRow = styled.div`
    display: flex;
`;

function Notification(props) {
    const { t, i18n } = useTranslation();
    const store = useContext(registrationStore);
    const notifications = useContext(notificationStore);
    const user = JSON.parse(store.user);

    const deleteMessageFromInbox = () => {
        notifications.setNotifications(user.public_id);
        return api.delete("/message/" + props.message.id).then(function (response) {
            props.update();
        });
    };

    const sendEmailNotification = (status = "accepted") => {
        api.get("/user/" + props.message.sender_id).then((res) => {
            const therapistEmail = res.data.user.email;

            api.post("/mailWithTemplate", {
                sender: "info@telephon.digital",
                to: therapistEmail,
                subject: `${user.firstName} ${user.lastName} ${status} your request`,
                template_name: "accepted-request-to-become-client",
                template_content: [{"name": "client-name", "content": user.firstName}]
                });
        });
    };

    const declineInvite = () => {
        api.get("/user/" + props.message.sender_id).then((res) => {
            const therapistEmail = res.data.user.email;
            deleteMessageFromInbox().then(() => {
                sendEmailNotification("declined");
            });
        });
    };

    const acceptInvite = () => {
        api.post("/linkPatient/" + user.public_id, { therapist_id: props.message.sender_id })
            .then((res) => {
                deleteMessageFromInbox();
                api.get("/user/" + user.public_id).then(res => {
                    store.setUser(JSON.stringify(res.data.user));
                })
            })
            .then(() => {
                sendEmailNotification("accepted");
            });
    };
    const isAddedAsPatientMessage = props.message.message_type === "Patient-Therapist-Link";
    const isEndedLinkMessage = props.message.message_type === "end-link";
    const isAcceptedMessage = props.message.message_type === "link-accept";
    const isNewAssignmentMessage = props.message.message_type === "new-assignment";
    const isPlanNotification = props.message.message_type === "plan-notification";

    const displayCorrectMessage = () => {
        if (isAddedAsPatientMessage) return props.message.sender_name + " " + t("notification.addPatient");
        else if (isEndedLinkMessage)
            return (
                t("notification.linkDeletedPart1") +
                " " +
                props.message.sender_name +
                " " +
                t("notification.linkDeletedPart2")
            );
        else if (isAcceptedMessage) return props.message.sender_name + " " + t("notification.acceptedMessage");
        else if (isNewAssignmentMessage)
            return props.message.sender_name + " " + t("notification.newAssignmentMessage");
        else if (isPlanNotification) return t('inbox.planNotification')
        return props.message.body;
    };

    const displayedMessage = displayCorrectMessage();

    return (
        <Wrapper>
            {isPlanNotification ? 
                <A to={{pathname: user.therapist ? '/t/MyAccount/MyPlan' : 'p/MyAccount/MyPlan'}}>
                    <Flex>
                        <MobileFlex>
                            <UserIcon src={TherapistIcon} />
                            <TextFlex>
                                <Text>{t("notification.noteOf")}:</Text>
                                <User primary>{props.message.sender_name}</User>
                            </TextFlex>
                        </MobileFlex>
                        <Message>
                            <Text>{displayedMessage}</Text>
                        </Message>
                    </Flex>
                </A>
                :
                <Flex>
                    <MobileFlex>
                        <UserIcon src={TherapistIcon} />
                        <TextFlex>
                            <Text>{t("notification.noteOf")}:</Text>
                            <User primary>{props.message.sender_name}</User>
                        </TextFlex>
                    </MobileFlex>
                    <Message>
                        <Text>{displayedMessage}</Text>
                    </Message>
                </Flex>
            }
            
            {isAddedAsPatientMessage ? (
                <FlexRow>
                    <GreenButton onClick={acceptInvite}>{t("notification.accept")}</GreenButton>
                    <RedButton onClick={declineInvite}>{t("notification.decline")}</RedButton>
                </FlexRow>
            ) : (
                <Icon onClick={deleteMessageFromInbox} />
            )}
        </Wrapper>
    );
}

export default Notification;
