import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import VerifyCode from "./verifyCode";
import RegistrationStore from "./registrationStore";
import { api } from "../api";
import { Redirect } from "react-router-dom";
import Flash from "./styled/flashMessage";
import { useTranslation } from "react-i18next";
import Button from "./styled/button";
import { P } from "./styled/typography";

const Flex = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 25px 0px 50px 0px;
`;

const Code = styled.div`
    margin: 10px 0px;
`;

const Resend = styled.span`
    color: #51c0cf;
    text-decoration: underline;
    cursor: pointer;
`;

const ResendText = styled(P)`
    margin-top: 35px;
`;

const Text = styled(P)`
    margin-top: 5px;
`;

const Error = styled.p`
    font-size: 16px;
    color: #fff;
    font-style: italic;
    background-color: #dc393d;
    border: 1px solid #dc393d;
    border-radius: 10px;
    padding: 10px 15px;
`;
const TwoFA = ({ isInRegistration, setRegisterStepIsValid, setRegisterStepIsInvalid }) => {
    const { t, i18n } = useTranslation();
    const store = useContext(RegistrationStore);
    const [code, setCode] = useState();
    const [showResendMessage, setShowResendMessage] = useState(false);
    const [showFailMessage, setShowFailMessage] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState();
    const [redirect, setRedirect] = useState(false);

    useEffect(() => {
        setPhoneNumber(store.phoneNumber);
    });

    useEffect(() => {
        api.get("/2FA/phone", { params: { phone: phoneNumber } }).then(() => {
            console.log("message sent to: " + phoneNumber);
        });
    }, [phoneNumber]);

    const ResendEmail = () => {
        api.get("/2FA/phone", { params: { phone: phoneNumber } }).then((res) => {
            setShowResendMessage(true);
            setTimeout(function () {
                setShowResendMessage(false);
            }, 5000);
        });
    };

    const VerifyCodeInput = () => {
        if (isInRegistration) {
            console.log("yes");
            api.put("/2FA/code/" + code, { type: "phone-initial" }).then((res) => {
                if (res.data.success) {
                    store.addUserAttribute("verifiedPhone", true);
                    setRegisterStepIsValid();
                } else {
                    setRegisterStepIsInvalid();
                    setShowFailMessage(true);
                }
            });
        } else {
            api.put("/2FA/code/" + code, { type: "phone" }).then((res) => {
                if (res.data.success) {
                    setRedirect(true);
                } else {
                    setShowFailMessage(true);
                }
            });
        }
    };

    return (
        <Flex>
            {phoneNumber && <Text>{t("2FAPhone.codeSent") + "********" + phoneNumber.substring(9)}</Text>}

            {showResendMessage && <Flash>{t("2FAPhone.codeResent")}</Flash>}
            {showFailMessage && <Error>{t("2FAPhone.invalidCode")}</Error>}
            <Text>{t("2FAPhone.inputCode")}</Text>
            <Code>
                <VerifyCode code={code} setCode={setCode} verify={VerifyCodeInput} />
            </Code>
            <Button onClick={VerifyCodeInput}>{t("2FAPhone.verify")}</Button>
            <ResendText>
                {t("2FAEmail.noCodeP1")}
                <Resend onClick={ResendEmail}>{t("2FAEmail.noCodeP2")}</Resend>
                {t("2FAEmail.noCodeP3")}
            </ResendText>
        </Flex>
    );
};

export default TwoFA;
