import { observable, action } from "mobx"
import { createContext } from "react";

class PatientStore{
    @observable patient = {
        firstName: "",
        lastName:  "",
        public_id: "",
        sex: ""
    };
    
    @action setPatient(firstName, lastName, public_id, sex){
        this.patient.firstName = firstName
        this.patient.lastName = lastName
        this.patient.public_id = public_id
        this.patient.sex = sex
    }

}


export const PatientStoreInstance = new PatientStore()

export default createContext(PatientStoreInstance)