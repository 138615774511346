import Text from './text';
import styled from 'styled-components';
import {fontSize} from 'styled-system';
import extendedTheme from '../theme';

const ErrorMessage = styled(Text)`
`
ErrorMessage.defaultProps = {
    color: extendedTheme.colors.error,
    fontSize: 0
}

export default ErrorMessage;
