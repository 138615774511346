import React, { useContext } from "react";
import PatientOverlay from "../../components/overlays/patientOverlay";
import Global from "../../components/styled/global";
import { PageTitle, SmallTitle, P } from "../../components/styled/typography";
import { PageAnimation } from "../../components/styled/animation";
import { observer } from "mobx-react";
import registrationStore from "../../components/registrationStore";
import AccountNavigation from "../../components/accountNavigation";
import PageWrapper from "../../components/styled/pageWrapper";
import { useTranslation } from "react-i18next";
import MyTherapists from "../../components/myTherapists";

const Therapeuten = observer(() => {
    const { t, i18n } = useTranslation();
    const store = useContext(registrationStore);
    const user = JSON.parse(store.user);
    return (
        <Global>
            <PageWrapper>
                <PatientOverlay />
                <PageAnimation>
                    <PageTitle black>{t("therapists.mytherapists")}</PageTitle>
                    <AccountNavigation therapist={user.therapist} />
                    <SmallTitle black>{t("therapists.mytherapistssmall")}</SmallTitle>
                    <MyTherapists />
                </PageAnimation>
            </PageWrapper>
        </Global>
    );
});

export default Therapeuten;
