import { observable, action } from "mobx";
import { createContext } from "react";
import api from '../../api';

class LanguageStore{
    @observable languages = [];
    @observable initialized = false;

    @action setLanguages(newLanguages){
        this.languages = newLanguages;
    }

    @action setInitialized(newInitialized){
        this.initialized = newInitialized;
    }

    @action getLanguage(language_code){
        return this.languages.filter(x => x.value === language_code).map(x => x.name).pop();
    }
}


export const LanguageStoreInstance = new LanguageStore()

export default createContext(LanguageStoreInstance)