import { observable, action } from "mobx"
import { createContext } from "react";

let today = new Date()
today = String(today.getDate()).padStart(2, '0') + '/' + String(today.getMonth() + 1).padStart(2, '0') + '/' + today.getFullYear()

class OpdrachtStore{
    taskObject = {
        id: "",
        title: "",
        tasktype: "",
        task: "",
        description: "",
        total: "",
        frequencyparam1: null,
        frequencyparam2: null,
        //Changes: JEN
        dates: []
    }

    @observable taskArray= []
    @observable oefeningen = []
    @observable selectedExercise = 0
    @observable selectedExerciseTitle = ""
    @observable selectedType = ""
    @observable titleInput = ""
    @observable isFinishedCreating = false;

    @action createObject(id, title, taskType, task, shedule, description, total = null, frequency = null, frequencyparam2 = null){
        this.taskObject.id = id
        this.taskObject.title = title
        this.taskObject.tasktype= taskType
        this.taskObject.task = task
        if (shedule !== null){
            this.taskObject.total = shedule.total
            this.taskObject.dates = shedule.dates
        } else {
            this.taskObject.total = total
            this.taskObject.frequencyparam1 = frequency
            this.taskObject.frequencyparam2 = frequencyparam2
        }
        this.taskObject.description = description
    }

    @action addToArray(object){
        this.taskArray.push(object)
    }

    @action removeFromArray(index, items){
        this.taskArray.splice(index, items)
    }

    @action setOefeningen(newOefeningen){
        this.oefeningen = newOefeningen
    }
    @action clearArray(){
        this.taskArray = []
    }
    @action clearTitle(){
        this.titleInput = ""
    }
    @action setSelectedExercise(newExercise){
        this.selectedExercise = newExercise
    }

    @action setSelectedExerciseTitle(newExercise){
        this.selectedExerciseTitle = newExercise
    }

    @action setSelectedType(newType){
        this.selectedType = newType
    }

    @action setTitleInput(newTitle){
        this.titleInput = newTitle
    }

    @action clearInputs(){
        this.titleInput = ""
        this.selectedExercise = 0
        this.selectedExerciseTitle = ""
        this.selectedType = ""
    }

    @action setIsFinishedCreating(value) {
        this.isFinishedCreating = value;
    }
    
}


export const OpdrachtStoreInstance = new OpdrachtStore()

export default createContext(OpdrachtStoreInstance)