import React, { useContext } from "react";
import PatientOverlay from "../../components/overlays/patientOverlay";
import { PageTitle, P } from "../../components/styled/typography";
import Global from "../../components/styled/global";
import ResultFormDagboek from "../../components/resultFormDagboek";
import ResultFrom from "../../components/resultForm";
import PageWrapper from "../../components/styled/pageWrapper";
import registrationStore from "../../components/registrationStore";
import { useTranslation } from "react-i18next";

import { PageAnimation } from "../../components/styled/animation";

function ResultForm(props) {
    const { t, i18n } = useTranslation();
    const store = useContext(registrationStore);
    const user = JSON.parse(store.user);
    return (
        <Global>
            <PageWrapper>
                <PatientOverlay />
                <PageAnimation>
                    <PageTitle black>{t("patientCard.results")}</PageTitle>
                    {props.location.state.type == "resultform" ? (
                        <ResultFrom
                            id={props.location.state.id}
                            startTime={props.location.state.startTime}
                            exerciseType={props.location.state.exerciseType}
                            scores={props.location.state.scores}

                            results={props.location.state.results}
                        />
                    ) : (
                        <ResultFormDagboek
                            id={props.location.state.id}
                            startTime={props.location.state.startTime}
                            exerciseType={props.location.state.exerciseType}
                            scores={props.location.state.scores}

                            scaleAnswers={props.location.state.scaleAnswers}
                            openAnswers={props.location.state.openAnswers}
                            exercise={props.location.state.exercise}
                        />
                    )}
                </PageAnimation>
            </PageWrapper>
        </Global>
    );
}

export default ResultForm;
