import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { FaGlobe } from "react-icons/fa";
import { FaAngleDown } from "react-icons/fa";
import Belgium from "../assets/images/belgium.png";
import Frans from "../assets/images/frans.png";
import Spaans from "../assets/images/spaans.png";
import Engels from "../assets/images/uk.png";
import Duits from "../assets/images/duits.png";
import Catalan from "../assets/images/catalan.png";
import Netherlands from "../assets/images/holland.png";
import Italiaans from "../assets/images/italy.png";

const Earth = styled(FaGlobe)`
    color: #fff;
    &#platform {
        @media (max-width: 980px) {
            color: #51c0cf;
        }
    }
`;
const Arrow = styled(FaAngleDown)`
    color: #fff;
    &#platform {
        @media (max-width: 980px) {
            color: #51c0cf;
        }
    }
`;

const Dropdown = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
`;
const Language = styled.div`
    margin: 0px 10px 0px 10px;
    color: #fff;

    &.platform {
        @media (max-width: 980px) {
            color: #51c0cf;
        }
    }
`;

const LanguageDropdown = styled.div`
    background-color: white;
    position: absolute;
    top: 35px;
    right: 0px;
    border: 2px solid #51c0cf;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    z-index: 9;

    &.languagedropdown {
        display: none;
    }

    &#topside {
        position: absolute !important;
        top: auto !important;
        bottom: 35px !important;
        right: auto !important;
        left: -30px;
    }
`;

const Relative = styled.div`
    position: relative;
`;
const OtherLanguage = styled.div`
    color: #51c0cf;
    min-width: 110px;
    padding: 10px 25px 10px 25px;
    cursor: pointer;
    display: flex;
    align-items: center;

    &:hover {
        color: #fff;
        background-color: #51c0cf;
    }
`;
const Image = styled.img`
    width: 20px;
    height: 20px;
    margin-right: 5px;
`;

const LanguageSelector = (props) => {
    const { t, i18n } = useTranslation();
    const [DropdownOpen, setDropdownOpen] = useState(false);

    const changeLanguage = (language, fullLanguage) => {
        i18n.changeLanguage(language);
        window.localStorage.setItem("language", language);
        setDropdownOpen(!DropdownOpen);
        if (document.getElementsByTagName('iframe')[0]) {
            let token = window.localStorage.getItem("token").toString();
            let userId = JSON.parse(window.localStorage.getItem("user")).public_id;
            document.getElementsByTagName('iframe')[0].src=`${process.env.REACT_APP_V2_URL}?token=${token}&user=${userId}&lang=${language}`
        }
    };

    const changeDropdown = () => {
        setDropdownOpen(!DropdownOpen);
    };

    return (
        <Relative>
            <Dropdown onClick={changeDropdown}>
                <Earth id={props.sidebar ? "platform" : ""} />
                <Language className={props.sidebar ? "platform" : ""} id="mainlanguage">
                    {i18n.language}
                </Language>
                <Arrow id={props.sidebar ? "platform" : ""} />
            </Dropdown>

            {DropdownOpen && (
                <LanguageDropdown classList="languagedropdown" id={props.sidebar ? "topside" : ""}>
                    <OtherLanguage onClick={() => changeLanguage("ca", "Català")}>
                        <Image src={Catalan} />
                        Català
                    </OtherLanguage>
                    <OtherLanguage onClick={() => changeLanguage("de", "Deutsch")}>
                        <Image src={Duits} />
                        Deutsch
                    </OtherLanguage>
                    <OtherLanguage onClick={() => changeLanguage("en", "English")}>
                        <Image src={Engels} />
                        English
                    </OtherLanguage>
                    <OtherLanguage onClick={() => changeLanguage("es", "Español")}>
                        <Image src={Spaans} />
                        Español
                    </OtherLanguage>
                    <OtherLanguage onClick={() => changeLanguage("fr", "Français")}>
                        <Image src={Frans} />
                        Français
                    </OtherLanguage>
                    <OtherLanguage onClick={() => changeLanguage("it", "Italiano")}>
                        <Image src={Italiaans} />
                        Italiano
                    </OtherLanguage>
                    <OtherLanguage onClick={() => changeLanguage("nl", "Nederlands")}>
                        <Image src={Netherlands} />
                        Nederlands
                    </OtherLanguage>
                </LanguageDropdown>
            )}
        </Relative>
    );
};

export default LanguageSelector;
