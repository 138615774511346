import React, {useContext} from "react"
import Discounts from "./modules/discounts"
import styled from 'styled-components'
import SidebarTherapist from '../../components/sidebarTherapist'
import AccountMenu from '../../components/accountMenu/accountMenu'
import Global from "../../components/styled/global"
import { PageTitle } from '../../components/styled/typography'
import { PageAnimation } from '../../components/styled/animation'
import { SmallTitle } from '../../components/styled/typography'
import registrationStore from "../../components/registrationStore"
import PageWrapper from '../../components/styled/pageWrapper'
import ExerciseRequests from './modules/exerciseRequests'
import MarketingDownload from "./modules/marketing-download"

const Title = styled(SmallTitle)`
    margin-top: 20px;
    margin-bottom: 10px;
    margin-right: 40px;
`


const Admin = () => {
    const store = useContext(registrationStore)
    const user = JSON.parse(store.user)
    return (
        <Global>
        <PageWrapper>
            <SidebarTherapist />
            <AccountMenu name={user.firstName + " " + user.lastName} role={user.therapist}/>
            <PageAnimation>
            <PageTitle black>Admin</PageTitle>
            <Title black>Kortingscodes</Title>
            <Discounts />
            <Title black>Aangevraagde oefeningen</Title>
            <ExerciseRequests />
            <Title black>Download users</Title>
            <MarketingDownload />
            </PageAnimation>
        </PageWrapper>
        </Global>
    )
}

export default Admin