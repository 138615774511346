import React, {useContext, useEffect,useState} from 'react';
import useForm from 'react-hook-form';
import styled from 'styled-components';
import {compose, space} from 'styled-system';
import {BaseModal} from '@eu.jstack/react-modal';
import ModalStyles from '@eu.jstack/react-modal/src/modalStyles';
import Input from '@eu.jstack/react-input/src';
import {PrimaryButton, TertiaryButton} from '@eu.jstack/react-button/src';
import CommunicationStore from '../../stores/communication.store';
import RegistrationStore from "../../../components/registrationStore";
import ErrorMessage from '../errorMessage';
import Text from '../text';
import theme from '../../theme';
import Icon from '../icon';
import {faQuestionCircle} from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import ReactDOM from 'react-dom';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import {Typography} from '@mui/material';



const ModalFooterContainer = styled.div`
    display: flex; 
    flex-Direction: row;
    ${compose(space)}
`;

const SettingsForm = styled.form`
    display: flex;
    flex-Direction: column;
`

const TopModalContainer = styled.form`
    display: flex;
    flex-Direction: row;
    justify-content: space-evenly;
    margin-left: 0px;
    margin-right: 0px;
`

const HelpButton = styled.button`
    margin-left: auto; 
    margin-right: 0;
    background-color: transparent;
    border: none;
    justify-content: end;
`


//space-evenly space-around space-between
const AllSettingsContainer = styled.div`
    display: flex;
    flex-Direction: row;
    justify-content: sspace-evenly; 
    flex-wrap: wrap;  
    z-index: 1;
    align-content: space-evenly;
    margin-right: 35px;
    margin-left: 1px;
    width: 100%;
    max-width: 60vw;
    gap: 10px;
`


const SingleSettingContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-Direction: column;
    justify-content: space-evenly;   
    z-index: 1;
    align-content: space-evenly;
    
`

var spectrogramUsed;

const isFirefox = typeof InstallTrigger !== 'undefined';

const SettingsModal = (props) => {
    const communicationStore = useContext(CommunicationStore);
    //state setup for feature params
    //[current  state, function to update the state  value] = useState(defultvalue)
    const [filterCutoff,setStateFilterCutoff] = useState(communicationStore.filterCutoff)
    const [sampleRate,setStateSampleRate] = useState(communicationStore.sampleRate)
    const [fftSize,setStateFftSize] = useState(communicationStore.fftSize)
    const [value, setValue] = useState()
    const [customSettings] = useState(true);
    const [customSettingsDisplay,setCustomSettingsDisplay] = useState(false);
    //const [intensityDisplay,setIntensity] = useState(true);
    const [shimmerDisplay,setShimmerDisplay] = useState(false); //+
    const [jitterDisplay,setJitterDisplay] = useState(false);   //+
    const [spectrogramDisplay,setSpectrogramDisplay] = useState(false); //-
    const [intensityDisplay,setIntensityDisplay] = useState(false); //-
    const [recorderDisplay,setRecorderDisplay] = useState(false); //-  ???
    const [hnrDisplay,setHnrDisplay] = useState(false); //-
    const [fundamentalDisplay,setFundamentalDisplay] = useState(false); //-
    const [calibrationDisplay,setCalibrationDisplay] = useState(false);

    /*
    class App extends React.Component {
    constructor() {
        super(props);
        this.state = {
            selectedValue: {},
            selectOptions: []
        };
      }
    }*/

    const {register, handleSubmit, errors} = useForm({
        defaultValues: {
            fftSize: communicationStore.fftSize,
            filterCutoff: communicationStore.filterCutoff,
            sampleRate: communicationStore.sampleRate,
            jitterDisplay: communicationStore.jitterDisplay,
            shimmerDisplay: communicationStore.shimmerDisplay,
            spectrogramDisplay: communicationStore.spectrogramDisplay,
            calibrationDisplay: communicationStore.calibrationDisplay,
            recorderDisplay: communicationStore.recorderDisplay,
            hnrDisplay: communicationStore.hnrDisplay,
            fundamentalDisplay: communicationStore.fundamentalDisplay,
            intensityDisplay: communicationStore.intensityDisplay
        }
    });

    const onSubmit = (data) => {
        //console.log("communicationStore.sampleRate before:", communicationStore.sampleRate);
    console.log("break1")
    if (isFirefox) {

    }
    else {  
        if(document.getElementById('shimmerDisplay').checked 
            && !document.getElementById('jitterDisplay').checked
            && !document.getElementById('intensityDisplay').checked
            && !document.getElementById('fundamentalDisplay').checked
            && !document.getElementById('hnrDisplay').checked
            && !document.getElementById('spectrogramDisplay').checked  ){
          //  data.fftSize = 512
           // communicationStore.setFftSize(Math.pow(2, Math.round(Math.log(data.fftSize)/Math.log(2))));
           
            console.log("reached if")

            data.filterCutoff = 300;
            communicationStore.setFilterCutoff(data.filterCutoff);

        }

        //fftSize must be a power of 2. This formular rounds to the closest power of 2.
        communicationStore.setFftSize(Math.pow(2, Math.round(Math.log(data.fftSize)/Math.log(2))));
       
        communicationStore.setSampleRate(data.sampleRate);

        communicationStore.setFilterCutoff(data.filterCutoff);
        //calibration
        if(document.getElementById('calibrationDisplay').checked){
            communicationStore.setCalibrationDisplay(true); 
        }
        else if(!document.getElementById('calibrationDisplay').checked){
            communicationStore.setCalibrationDisplay(false); 
        }
        //jitter
        if(document.getElementById('jitterDisplay').checked){
            communicationStore.setJitterDisplay(true); 
        }
        else if(!document.getElementById('jitterDisplay').checked){
            communicationStore.setJitterDisplay(false);
        }
        //shimmer
        if(document.getElementById('shimmerDisplay').checked){
            communicationStore.setShimmerDisplay(true); 
        }
        else if(!document.getElementById('shimmerDisplay').checked){
            communicationStore.setShimmerDisplay(false)
        }
        //intensity
        if(document.getElementById('intensityDisplay').checked){
            communicationStore.setIntensityDisplay(true); 
        }
        else if(!document.getElementById('intensityDisplay').checked){
            communicationStore.setIntensityDisplay(false)
        }
        //f0
        if(document.getElementById('fundamentalDisplay').checked){
            communicationStore.setFundamentalDisplay(true); 
        }
        else if(!document.getElementById('fundamentalDisplay').checked){
            communicationStore.setFundamentalDisplay(false)
        }
        //hnr
        if(document.getElementById('hnrDisplay').checked){
            communicationStore.setHnrDisplay(true); 
        }

        else if(!document.getElementById('hnrDisplay').checked){
            communicationStore.setHnrDisplay(false)
        }
        //recorder
        if(document.getElementById('recorderDisplay').checked){
            communicationStore.setRecorderDisplay(true);
        }
        else if(!document.getElementById('recorderDisplay').checked){
            communicationStore.setRecorderDisplay(false)
        }
        //spectrogram
        if(document.getElementById('spectrogramDisplay').checked){
            communicationStore.setSpectrogramDisplay(true);
            communicationStore.setSpectrogramDim(false);
        }

        else if(!document.getElementById('spectrogramDisplay').checked){
            //communicationStore.setSpectrogramDisplay(false);
            communicationStore.setSpectrogramDim(true);
            //console.log("communicationStore.setSpectrogramDim", communicationStore.setSpectrogramDim);
        }
        //console.log("communicationStore.sampleRate after:", communicationStore.sampleRate);
        props.close(false);
    }  
    };

    const store = useContext(RegistrationStore);

    const handleShimmerCheck = (value)=>{
        console.log('handleShimmerCheck ',value) 
        //set the display if the value changed?
        setShimmerDisplay(prev=>!prev);
        //set prev to be equal to not the shimmerDisplaay
        let prev = !shimmerDisplay;
        //depending on prev set parameter values
        if(prev){
            setStateFilterCutoff(600)
            setStateSampleRate(22025)
            setStateFftSize(2048)
            //communicationStore.setFilterCutoff(300);
        }
        else{
            //setStateFilterCutoff(communicationStore.filterCutoff)
            //setStateSampleRate(communicationStore.sampleRate)
            //setStateFftSize(communicationStore.fftSize)
        }
    }

    const handleJitterCheck = (value)=>{
        console.log('handleJitterCheck ',value) 
        //set the display if the value changed?
        setJitterDisplay(prev=>!prev);
        //set prev to be equal to not the shimmerDisplaay
        let prev = !jitterDisplay;
        //depending on prev set parameter values
        if(prev){
            setStateFilterCutoff(600)
            setStateSampleRate(22025)
            setStateFftSize(2048)
        }
        else{
        }
    }

    const handleIntensityCheck = (value)=>{
        console.log('handleIntensityCheck ',value) 
        //set the display if the value changed?
        setIntensityDisplay(prev=>!prev);
        //set prev to be equal to not the shimmerDisplaay
        let prev = !intensityDisplay;
        //depending on prev set parameter values
        if(prev){
            setStateFilterCutoff(20000)
            setStateSampleRate(22025)
            setStateFftSize(2048)
        }
        else{
        }
    }

    const handleSpectrogramCheck = (value)=>{
        console.log('handleSpectrogramCheck ',value) 
        //set the display if the value changed?
        setSpectrogramDisplay(prev=>!prev);
        //set prev to be equal to not the shimmerDisplaay
        let prev = !spectrogramDisplay;
        //depending on prev set parameter values
        if(prev){
            setStateFilterCutoff(20000)
            setStateSampleRate(22050)
            setStateFftSize(2048)
        }
        else{
        }
    }

    const handleFundamentalCheck = (value)=>{
        console.log('handleFundamentalCheck ',value) 
        //set the display if the value changed?
        setFundamentalDisplay(prev=>!prev);
        //set prev to be equal to not the shimmerDisplaay
        let prev = !fundamentalDisplay;
        //depending on prev set parameter values
        if(prev){
            setStateFilterCutoff(1000)
            setStateSampleRate(22025)
            setStateFftSize(2048)
        }
        else{
        }
    }

    const handleHnrCheck = (value)=>{
        console.log('handleHnrCheck ',value) 
        //set the display if the value changed?
        setHnrDisplay(prev=>!prev);
        //set prev to be equal to not the shimmerDisplaay
        let prev = !hnrDisplay;
        //depending on prev set parameter values
        if(prev){
            setStateFilterCutoff(20000)
            setStateSampleRate(44100)
            setStateFftSize(2048)
        }
        else{
        }
    }

    const handleRecorderCheck = (value)=>{
        console.log('handleRecorderCheck ',value) 
        //set the display if the value changed?
        setRecorderDisplay(prev=>!prev);
        //set prev to be equal to not the shimmerDisplaay
        let prev = !recorderDisplay;
        //depending on prev set parameter values
        if(prev){
            setStateFilterCutoff(20000)
            setStateSampleRate(44100)
            setStateFftSize(2048)
        }
        else{
        }
    }

    const handleCalibrationCheck = (value)=>{
        console.log('handleCalibrationCheck ',value) 
        //set the display if the value changed?
        setCalibrationDisplay(prev=>!prev);
        //set prev to be equal to not the shimmerDisplaay
        let prev = !calibrationDisplay;
        //depending on prev set parameter values
        if(prev){
            setStateFilterCutoff(20000)
            setStateSampleRate(44100)
            setStateFftSize(2048)
            communicationStore.setShimmerDisplay(false)
        }
        else{
        }
    }


    const handleCustomSettingsCheck = (value)=>{
        console.log('handleCustomSettingsCheck ',value)
        setCustomSettingsDisplay(prev=>!prev);
        let prev = !customSettingsDisplay;
        if(prev){
            setShimmerDisplay(false)
        }
    }

    const handleFilterValueChange = (e)=>{
        setStateFilterCutoff(e.target.value)
    }

    const handleSampleRateValueChange = (e)=>{
        setStateSampleRate(e.target.value)
    }

    const handleFftSizeValueChange = (e)=>{
        setStateFftSize(e.target.value)
    }

    const CustomWidthTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
      ))({
        [`& .${tooltipClasses.tooltip}`]: {
          maxWidth: 323,
        },
      });
   
    const modalTitle = (
        <>
        {!isFirefox &&
        <ModalStyles.ModalTitle fontSize={3} fontWeight="bold">
                ARTVA Options
        </ModalStyles.ModalTitle>
        }
        {isFirefox &&
        <ModalStyles.ModalTitle fontSize={3} fontWeight="bold" color="red">
                Please use Google Chrome to enable ARTVA
        </ModalStyles.ModalTitle>
        }
        {
        <CustomWidthTooltip
        title={
          <>
            <Typography variant="caption" color="inherit">For best results select only the features you need.     </Typography>
            <Typography variant="caption" color="inherit">Parameters are determined by the last feature selected.    </Typography>
            <Typography variant="caption" color="inherit">To see parameter changes turn on custom settings.</Typography>
          </>
        }
        >
            <HelpButton>
                <Text fontSize={2} fontWeight="bold"> Help <Icon fontSize={4} icon={faQuestionCircle}/></Text>
            </HelpButton>
        </CustomWidthTooltip>
        }
         </>)
    const footer = ( 
        <ModalFooterContainer style={{marginTop: '10px'}}>
            <PrimaryButton onClick={handleSubmit(onSubmit)} px={3}>Opslaan</PrimaryButton>
            <TertiaryButton onClick={() => props.close(false)} ml={3}>Cancel</TertiaryButton>
        </ModalFooterContainer>)

    const label = (text) => (
        <Text fontSize={theme.fontSizes[1]} mt={2} mb={1}>{text}</Text>
    );

    const optionsFftSize = [
        { value: '1024', label: '1024' },
        { value: '2048', label: '2048' },
        { value: '4096', label: '4096' }
    ]

    const optionsSampleRate = [
        { value: '48000', label: '48000' },
        { value: '44100', label: '44100' },
        { value: '22050', label: '22050' }
    ]

    const optionsFilterCutoff = [
        { value: '600', label: '600' },
        { value: '1000', label: '1000' },
        { value: '22000', label: '22000' }
    ]

    return (
        <BaseModal
            isOpen={props.isModalOpen}
            width={theme.breakpoints[0]}
            onClose={() => props.close(false)}
            title={modalTitle}
            footer={footer}
            maxContentHeight={800}
        >
            <SettingsForm>
                <AllSettingsContainer>
                {!store.checkAccountIsExpired() &&
                <>
                <Tooltip title="Calibration disables selection of other features">
                <SingleSettingContainer>
                {label('Calibration')}
                <Input 
                    type='checkbox'
                    id='calibrationDisplay'
                    unchecked
                    value={calibrationDisplay}
                    onChange={e=>handleCalibrationCheck(calibrationDisplay?true:false)}
                />
                </SingleSettingContainer>
                </Tooltip>
                </>}


                {!store.checkAccountIsExpired() &&
                <>
                <SingleSettingContainer>
                {label('Intensity (dB)')}
                <Input 
                    type='checkbox'
                    id='intensityDisplay'
                    unchecked
                    ref={register()}
                    value={intensityDisplay}
                    onChange={e=>handleIntensityCheck(intensityDisplay?true:false)}
                    disabled={calibrationDisplay}
                />
                </SingleSettingContainer>
                </>}
               
                {!store.checkAccountIsExpired() &&
                <>
                <SingleSettingContainer>
                {label('Fundamental/F0 (Hz)')}
                <Input 
                    type='checkbox'
                    id='fundamentalDisplay'
                    unchecked
                    value={fundamentalDisplay}
                    onChange={e=>handleFundamentalCheck(fundamentalDisplay?true:false)}
                    disabled={calibrationDisplay}
                />
                </SingleSettingContainer>
                </>}

                {!store.checkAccountIsExpired() &&
                <>
                <SingleSettingContainer>
                {label('Jitter (%)')}
                <Input 
                    type='checkbox'
                    id='jitterDisplay'
                    value={jitterDisplay}
                    onChange={e=>handleJitterCheck(jitterDisplay?true:false)}
                    disabled={calibrationDisplay}
                />
                </SingleSettingContainer>
                </>}

                {!store.checkAccountIsExpired() &&
                <>
                <SingleSettingContainer>
                {label('Shimmer (%)')}
                <Input 
                    type='checkbox'
                    id='shimmerDisplay'
                    value={shimmerDisplay}
                    onChange={e=>handleShimmerCheck(shimmerDisplay?true:false)}
                    disabled={calibrationDisplay}
                />
                </SingleSettingContainer>
                </>}

                {!store.checkAccountIsExpired() &&
                <>
                <SingleSettingContainer>
                {label('Harmonicity/HNR (dB)')}
                <Input 
                    type='checkbox'
                    id='hnrDisplay'
                    unchecked
                    value={hnrDisplay}
                    onChange={e=>handleHnrCheck(hnrDisplay?true:false)}
                    disabled={calibrationDisplay}
                />
                </SingleSettingContainer>
                </>}

                <SingleSettingContainer>
                {label('Spectrogram')}
                <Input 
                    type='checkbox'
                    id='spectrogramDisplay'
                    unchecked
                    value={spectrogramDisplay}
                    onChange={e=>handleSpectrogramCheck(spectrogramDisplay?true:false)}
                    disabled={calibrationDisplay}
                />
                </SingleSettingContainer>

                {!store.checkAccountIsExpired() &&
                <>
                <SingleSettingContainer>
                {label('Recorder')}
                <Input 
                    type='checkbox'
                    id='recorderDisplay'
                    unchecked
                    value={recorderDisplay}
                    onChange={e=>handleRecorderCheck(recorderDisplay?true:false)}
                    disabled={calibrationDisplay}
                />  
                </SingleSettingContainer>
                </>}

                {!store.checkAccountIsExpired() &&
                <>
                <SingleSettingContainer>
                {label('Show custom settings')}
                <Input 
                    type='checkbox'
                    id='customSettings'
                    unchecked
                    onChange={e=>handleCustomSettingsCheck(customSettings?true:false)}
                    disabled={calibrationDisplay}
                />  
                </SingleSettingContainer>
                </>}    

                
                </AllSettingsContainer>

                {!store.checkAccountIsExpired() &&
                <>
                {label(<label hidden={!customSettingsDisplay || calibrationDisplay}>Filter cutoff (Low-Pass Filter)</label>)}
                <Input 
                    type='number'
                    name='filterCutoff'
                    id='filterCutoff'
                    ref={(register({min: 100, max: 20000}))}
                    value={filterCutoff}
                    onChange={e=>handleFilterValueChange(e)}
                    hidden={!customSettingsDisplay || calibrationDisplay}
                    
                />
                </>}

                {!store.checkAccountIsExpired() &&
                <>
                {label(<label hidden={!customSettingsDisplay || calibrationDisplay}>FFT size</label>)}
                <Input 
                    type='number'
                    name='fftSize' 
                    ref={(register({min: 32, max: 32768}))}
                    value={fftSize}
                    onChange={e=>handleFftSizeValueChange(e)}
                    hidden={!customSettingsDisplay || calibrationDisplay}

                />
                {errors.fftSize && <ErrorMessage>Fft size moet een getal zijn tussen 32 en 32768.</ErrorMessage>}
                </>}

                {!store.checkAccountIsExpired() &&
                <>               
                {label(<label hidden={!customSettingsDisplay || calibrationDisplay}>Sample rate</label>)}
                <Input 
                    type='number'
                    name='sampleRate'
                    ref={(register({min: 8000, max: 96000}))}
                    disabled={false}
                    value={sampleRate}
                    onChange={e=>handleSampleRateValueChange(e)}
                    hidden={!customSettingsDisplay || calibrationDisplay}
                />
                {errors.sampleRate && <ErrorMessage>Sample rate moet een getal zijn tussen 8000 en 96000</ErrorMessage>}
                </>}
            </SettingsForm>
        </BaseModal>
    );
};

export default SettingsModal;
