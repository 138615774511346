import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import TherapistOverlay from "../../components/overlays/therapistOverlay";
import Global from "../../components/styled/global";
import PageWrapper from "../../components/styled/pageWrapper";
import { PageTitle, P } from "../../components/styled/typography";
import { Li, Ul } from "../../components/styled/list";
import { H3 } from "../../components/styled/typography";
import Button from "../../components/styled/button";
import Flash from "../../components/styled/flashMessage";
import AccountNavigation from "../../components/accountNavigation";
import TherapistIconFemale from "../../assets/images/therapist-icon.png";
import TherapistIconMale from "../../assets/images/therapist-icon-man.png";
import { PageAnimation } from "../../components/styled/animation";
import registrationStore from "../../components/registrationStore";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import TherapistPlans from "../../components/checkoutComponents/therapistPlans";
import CheckoutStep from "../../components/checkoutStep";
import Card from "../../components/styled/card";
import { api } from "../../api";
import qs from "qs";
import LoadingSpinner from "../../components/loadspinner";

const Plan = styled.div`
    border: 1px solid #51c0cf;
    border-radius: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    margin-top: 10px;
    margin-right: 20px;

    @media (min-width: 980px) {
        width: 375px;
    }
`;

const PlanImage = styled.img`
    width: 100px;
    margin-top: 40px;
`;

const Price = styled(H3)`
    font-weight: 400;
    margin-top: 20px;
    margin-bottom: 0px;
    @media (min-width: 980px) {
        font-size: 40px;
    }
`;

const Title = styled(H3)`
    margin-top: 25px;
`;

const TwoFACard = styled(Card)`
    margin-top: 20px;
    width: 100%;

    @media (min-width: 768px) {
        width: 50%;
    }
`;

const SmallYear = styled(P)`
    margin: 0px;
    margin-top: 15px;
    color: #51c0cf;
`;

const A = styled.a`
    color: #51c0cf;
`;

const SubmitButton = styled(Button)`
    margin-top: 20px;
`;

const CustomPlan = styled.div`
    background-color: #ffffff;
    border-radius: 10px;
    display: inline-block;
    border: 1px solid #51c0cf;
`;

const PlanText = styled(P)`
    color: #545454;
    font-weight: 700;
    font-size: 18px;
    margin: 5px;
`;

const PText = styled(P)`
    color: #545454;
    margin: 5px;
    padding: 8px;
`;

const Flex = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
`;

const FlexRow = styled.div`
    display: flex;
`;

const NewPlan = styled.div`
    margin-top: 25px;
`;

const MijnPlan = observer((props) => {
    const { t, i18n } = useTranslation();
    const store = useContext(registrationStore);
    const user = JSON.parse(store.user);
    const [checkoutStep, setCheckoutStep] = useState();
    const [selectedPlan, setSelectedPlan] = useState();
    const [successMessage, setSuccessMessage] = useState(false);
    const [userPlans, setUserPlans] = useState([]);

    const [loading, setLoading] = useState(false);

    const afterPayment = qs.parse(props.location.search, { ignoreQueryPrefix: true }).afterPayment;

    useEffect(() => {
        store.setPlan("Therapeut");
        store.setPlanId(selectedPlan);
        setCheckoutStep(checkoutStep + 1);
    }, [selectedPlan]);

    useEffect(() => {
        if (afterPayment) {
            setSuccessMessage(true);
            setCheckoutStep(-1);
        }
        setLoading(true);
        api.get("/user_plans/" + user.public_id).then((res) => {
            if (res.data.user_plans.length <= 1) {
                setCheckoutStep(1);
            }
            let plans = [];
            for (var plan of res.data.user_plans) {
                const planObj = plan;
                const planStartDate = new Date(plan.dateActive);
                const expireDate = new Date(
                    planStartDate.setMonth(
                        planStartDate.getMonth() +
                            (plan.month_reduction ? plan.plan_period - plan.month_reduction : plan.plan_period)
                    )
                );
                planObj["expireDate"] = expireDate;
                plans.push(planObj);
            }
            setUserPlans(res.data.user_plans);
            setLoading(false);
        });
    }, []);

    return (
        <Global>
            <PageWrapper>
                <TherapistOverlay />
                <PageAnimation>
                    <PageTitle black>{t("sidebarPatient.acc")}</PageTitle>
                    <AccountNavigation therapist={user.therapist} />
                    {loading ? (
                        <LoadingSpinner />
                    ) : !user.expired ? (
                        <>
                            {successMessage && <Flash green>{t("mijnPlan.successMessage")}</Flash>}
                            <Flex>{<PlanText>{t("mijnPlan.currentPlanText")}</PlanText>}</Flex>
                            <FlexRow>
                                {userPlans.map((plan) => {
                                    return (
                                        <Plan>
                                            <PlanImage
                                                src={user.sex === "Man" ? TherapistIconMale : TherapistIconFemale}
                                            />
                                            <SmallYear>
                                                {plan.plan_period === 1 && plan.price === 0 ? 
                                                    t("mijnPlan.freeTrial") : 
                                                    plan.month_reduction ? 
                                                        plan.plan_period - plan.month_reduction + " " + t("mijnPlan.months") : 
                                                        plan.plan_period + " " + t("mijnPlan.months")}
                                            </SmallYear>
                                            <Ul>
                                                <Li small>{t("mijnPlan.manage")}</Li>
                                                <Li small>{t("mijnPlan.exercise")}</Li>
                                                <Li small>{t("mijnPlan.lookResult")}</Li>
                                                <Li small>{t("mijnPlan.docs")}</Li>
                                                <Li small>{t("mijnPlan.videochat")}</Li>
                                            </Ul>
                                            <p>
                                                {t("mijnPlan.expiresOnText") +
                                                    " " +
                                                    plan.expireDate.getDate() +
                                                    "/" +
                                                    parseInt(plan.expireDate.getMonth() + 1) +
                                                    "/" +
                                                    plan.expireDate.getFullYear()}
                                            </p>
                                        </Plan>
                                    );
                                })}
                            </FlexRow>
                        </>
                    ) : (
                        <PlanText>{t("mijnPlan.previousPlanExpiredText")}</PlanText>
                    )}
                    {checkoutStep === 1 && (
                        <>
                            <NewPlan>
                                <PlanText>{t("mijnPlan.newPlan")}</PlanText>
                                <TherapistPlans setValue={setSelectedPlan} />
                            </NewPlan>
                        </>
                    )}
                    {checkoutStep === 2 && (
                        <NewPlan>
                            <PlanText>{t("mijnPlan.newPlan")}</PlanText>
                            <CheckoutStep />
                        </NewPlan>
                    )}
                </PageAnimation>
            </PageWrapper>
        </Global>
    );
});

export default MijnPlan;
