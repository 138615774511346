import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import TherapistOverlay from "../../components/overlays/therapistOverlay";
import Global from "../../components/styled/global";
import { FaEdit, FaTimes } from "react-icons/fa";
import { api } from "../../api";
import PatientNavigation from "../../components/patientNavigation";
import PageWrapper from "../../components/styled/pageWrapper";
import AssignmentCard from "../../components/assignmentCard";
import { PageTitle, SmallTitle, P } from "../../components/styled/typography";
import Button from "../../components/styled/button";
import Card from "../../components/styled/card";
import registrationStore from "../../components/registrationStore";
import PatientStore from "../../components/patientStore";
import { observer } from "mobx-react";
import TaskInAssignmentSmall from "../../components/taskInAssignmentSmall";
import Patientopdrachtaanmaken from "../../components/patientopdrachtaanmaken";
import PatientIcon from "../../assets/images/patient-icon.png";
import PatientIconWoman from "../../assets/images/patient-icon-woman.png";
import BertieIcon from "../../assets/images/bertie.png";
import { FaPlus } from "react-icons/fa";
import LoadSpinner from "../../components/loadspinner";
import { PageAnimation } from "../../components/styled/animation";
import { NavLink } from "react-router-dom";
import PatientExistingAssignment from "../../components/patientExistingAssignment";
import SelectionOverlay from "../../components/selectionOverlay";
import OpdrachtStore from "../../components/opdrachtStore";
import { useTranslation } from "react-i18next";
import CreateTraject from "../../components/trajectComponents/createTraject";
import TrajectDetails from "../../components/trajectComponents/trajectDetails";

const Avatar = styled.img`
    height: 175px;
    width: 175px;
    margin-right: 5px;
    padding: 5px;
    border: 0.7px solid #d1d1d1;
    box-sizing: border-box;
`;

const FlexBetween = styled.div`
    display: flex;
    flex-direction: column-reverse;
    margin-top: 20px;

    @media (min-width: 980px) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: auto;
    }
`;

const Plus = styled(FaPlus)`
    color: #ffffff;
    transform: translateY(3px);
    margin-right: 5px;
`;

const Times = styled(FaTimes)`
    color: #545454;
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
`;

const Title = styled(SmallTitle)`
    margin-bottom: 30px;
`;
const OverlayTraject = styled.div`
    position: fixed;
    display: flex;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(60, 60, 60, 0.7);
    z-index: 999;
    justify-content: center;
    align-items: center;
`;

const OverlayCardTraject = styled(Card)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
`;

const OverlayAssignment = styled.div`
    background-color: rgba(60, 60, 60, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const OverlayCardAssignment = styled(Card)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    box-sizing: border-box;
    padding: 30px;
    height: 98%;
    width: 98%;
    position: absolute;
    z-index: 999999;
    padding-bottom: 15%;
    @media (min-width: 980px) {
        height: 90%;
        position: relative;
        top: 0;
        z-index: 9;
        padding-bottom: 30px;
    }
`;

const A = styled(NavLink)`
    color: #51c0cf;
    text-decoration: none;
    padding: 0px 10px 0px 0px;
    margin-bottom: 10px;
    &.active {
        text-decoration: underline;
        color: #4e7693;
    }
`;

const Flex = styled.div`
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    @media (min-width: 980px) {
        flex-direction: row;
    }
`;

const CreateAssignment = styled(SmallTitle)`
    margin: 30px 0px 10px 0px;
`;

const PatientTaak = observer((props) => {
    const { t, i18n } = useTranslation();
    const store = useContext(registrationStore);
    const patientStore = useContext(PatientStore);
    const opdrachtStore = useContext(OpdrachtStore);
    const user = JSON.parse(store.user);
    const { public_id } = props.match.params;
    const [showOverlayTraject, setShowOverlayTraject] = useState(false);
    const [showAssignmentOverlay, setShowAssignmentOverlay] = useState(false);
    const [loading, setLoading] = useState(true);
    const [patientLoading, setPatientLoading] = useState(true);
    const [trajects, setTrajects] = useState([]);
    const [newAssignment, setNewAssignment] = useState(false);
    const [showSelection, setShowSelection] = useState(false);
    const [chosenElement, setChosenElement] = useState({});
    const [exercises, setExercises] = useState();
    const [privateExercises, setPrivateExercises] = useState();
    const [privateDocs, setPrivateDocs] = useState();
    const [exerciseLoading, setExerciseLoading] = useState(true);
    const [documents, setDocuments] = useState();

    useEffect(() => {
        api.get("/trajects/" + public_id).then(function (response) {
            setTrajects(response.data.trajects.reverse());
            setLoading(false);
        });

        if (patientStore.patient.public_id !== public_id) {
            api.get("/user/" + public_id).then((response) => {
                patientStore.setPatient(
                    response.data.user.firstName,
                    response.data.user.lastName,
                    response.data.user.public_id,
                    response.data.user.sex
                );
                setPatientLoading(false);
            });
        } else {
            setPatientLoading(false);
        }

        api.get("/exercises", {
            params: {
                language: "nederlands",
                discipline: "Logopedie",
            },
        }).then((response) => {
            setExercises(response.data.output);
        });
        api.get("/exercises/" + user.email).then((res) => {
            setPrivateExercises(res.data.output);
        });
        api.get("/documents", {
            params: {
                language: "nederlands",
                discipline: "Logopedie",
            },
        }).then((response) => {
            setDocuments(response.data.Documents);
        });
        api.get("/privateDocuments/" + user.public_id).then((res) => {
            setPrivateDocs(res.data.Documents);
        });

        opdrachtStore.clearInputs();
        opdrachtStore.clearArray();
    }, []);

    const displayOverlayTraject = () => {
        setShowOverlayTraject(true);
    };

    const hideOverlayTraject = () => {
        setShowOverlayTraject(false);
    };

    const handleCloseAssignment = () => {
        setShowAssignmentOverlay(false);
    };

    const DisplayAssignmentOverlay = () => {
        setShowAssignmentOverlay(true);
    };

    const handleNav = (choice) => {
        if (choice === "zelf") {
            setNewAssignment(false);
        } else {
            setNewAssignment(true);
        }
    };

    const update = () => {
        api.get("/trajects/" + public_id).then(function (response) {
            setTrajects(response.data.trajects.reverse());
        });
    };

    const SwitchToSelectionFromAssignment = () => {
        handleCloseAssignment();
        setShowSelection(true);
    };

    const Close = () => {
        setShowSelection(false);
        setShowAssignmentOverlay(true);
    };

    const deleteTraject = (id) => {
        api.delete("/traject/" + id).then(() => {
            setTrajects(trajects.filter((traject) => traject.id !== id));
        });
    };

    async function getExercisePage(page) {
        let start = 12 * (page - 5);
        let end = 12 * page - 5;
        const response = await api.get("/exercises", { params: { start: start, end: end } });
        return response.output;
    }

    return (
        <Global>
            <PageWrapper>
                <TherapistOverlay />
                <PageAnimation>
                    {patientLoading ? (
                        <LoadSpinner />
                    ) : (
                        <PageTitle black>
                            {patientStore.patient.firstName + " " + patientStore.patient.lastName}
                        </PageTitle>
                    )}
                    <PatientNavigation public_id={public_id} />
                    {loading ? (
                        <LoadSpinner />
                    ) : (
                        trajects.map((traject, index) => {
                            return (
                                <TrajectDetails
                                    key={traject.id}
                                    traject={traject}
                                    viewTraject={true}
                                    update={update}
                                    openAssignmentOverlay={DisplayAssignmentOverlay}
                                    patient_id={patientStore.patient.public_id}
                                    deleteTraject={deleteTraject}
                                />
                            );
                        })
                    )}
                    <Button onClick={displayOverlayTraject}>
                        <Plus />
                        {t("patientTaak.addNewTraject")}
                    </Button>
                    {showOverlayTraject && (
                        <OverlayTraject>
                            <OverlayCardTraject>
                                <Times onClick={hideOverlayTraject} />
                                <CreateAssignment black>{t("patientTaak.createTraject")}</CreateAssignment>
                                <CreateTraject close={hideOverlayTraject} update={update} public_id={public_id} />
                            </OverlayCardTraject>
                        </OverlayTraject>
                    )}
                    {showSelection && (
                        <SelectionOverlay
                            close={Close}
                            setChosenElement={setChosenElement}
                            exercises={exercises}
                            privateExercises={privateExercises}
                            documents={documents}
                            privateDocs={privateDocs}
                        />
                    )}
                    {showAssignmentOverlay && (
                        <OverlayAssignment>
                            <OverlayCardAssignment>
                                <Times onClick={handleCloseAssignment} />
                                <CreateAssignment black>{t("patientopdrachtaanmaken.makeAss")}</CreateAssignment>
                                <Flex>
                                    <A className={newAssignment ? "" : "active"} onClick={() => handleNav("zelf")}>
                                        {t("patientTaak.newAss")}
                                    </A>
                                    <A className={newAssignment ? "active" : ""} onClick={() => handleNav("template")}>
                                        {t("patientTaak.existingAss")}
                                    </A>
                                </Flex>
                                {newAssignment ? (
                                    <PatientExistingAssignment
                                        public_id={public_id}
                                        close={handleCloseAssignment}
                                        update={update}
                                    />
                                ) : (
                                    <Patientopdrachtaanmaken
                                        public_id={public_id}
                                        close={handleCloseAssignment}
                                        update={update}
                                        switch={SwitchToSelectionFromAssignment}
                                        chosenElement={chosenElement}
                                    />
                                )}
                            </OverlayCardAssignment>
                        </OverlayAssignment>
                    )}
                </PageAnimation>
            </PageWrapper>
        </Global>
    );
});

export default PatientTaak;
