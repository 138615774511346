import React, {useState, useEffect} from "react"
import styled from 'styled-components'
import {api} from "../api"
import { NavLink } from 'react-router-dom'
import {P} from '../components/styled/typography'
import { useTranslation } from "react-i18next"

import Card from '../components/styled/card'


const LogboekCard = styled(Card)`
    display: flex;
    flex-wrap: wrap;
`

const BulletGreen = styled.div`
    width: 10px;
    height: 10px;
    background-color: #24AE5F;
    border: 4px solid #24AE5F;
    border-radius: 100%;
    margin-right: 10px;
    box-sizing: content-box;
    vertical-align: middle;
    display: inline-block;
`

const BulletOrange = styled(BulletGreen)`
    background-color: #F0C239;
    border-color: #F0C239;
`

const BulletRed = styled(BulletGreen)`
    background-color: #F23539;
    border-color: #F23539;
`

const A = styled(NavLink)`
    text-decoration: none;
    color: #51C0CF;
    font-weight: 600;
`

const Duration = styled.span`
    color: #A5A5A5;
    font-size: 16px;
    font-style: italic;
    font-weight: lighter;
`

const Day = styled.div`
    margin-right: 20px;
    margin-left: 20px;
`

const DateTitle = styled(P)`
    font-weight: bold;
    margin-bottom: 0px;
    margin-top: 15px;
    color: #545454;
`

const Logboek = (props) => {
    const { t, i18n } = useTranslation()
    const [logs, setLogs] = useState([])
    useEffect(() => {
        api.get('/user/' + props.id + '/logs').then((response) =>{
            setLogs(response.data.output)
        })
        
    }, []);

    return (
        <div>
        { logs.length != 0 ? 
        <LogboekCard>
            {logs.map(log => {
                return (
                    <Day>
                    <DateTitle>
                        {new Date(log[0].date).toLocaleDateString(i18n.language)}
                    </DateTitle>
                    {
                        log.map(task => {
                        const durationTask= task.duration * 1.666666667 * (10**-5);
                        let duration;
                        if (durationTask < 1){
                            duration = ((durationTask)*100).toFixed(0) + " sec"
                        } else {
                            duration = (durationTask).toFixed(2) + " min"
                        }
                        return  <div>
                                    <A to={{pathname: "/t/therapy/PatientResults/LogDetail/" + task.id}}>
                                        <span>
                                            {task.result == 1 ? <BulletRed /> : task.result == 2 ? <BulletOrange /> : <BulletGreen />}
                                            {t('logboek.task')}: {task.task_title}
                                        </span> 
                                        <Duration>({duration})</Duration>
                                    </A>
                                </div>
                        })
                    }
                    </Day>
                )
            })}
        </LogboekCard> :
        <div>{t('logboek.taskAvailable')}</div>
        }
        </div>
    )
}

export default Logboek