import React from "react";
import FileSaver from "file-saver";
import {BlobServiceClient} from "@azure/storage-blob";

function getContainer(container_name){
    const sasToken = process.env.REACT_APP_AZURE_STORAGE_SAS_TOKEN;
    const storageAccount = process.env.REACT_APP_AZURE_STORAGE_ACCOUNT;

    const blobServiceClient = new BlobServiceClient(`https://${storageAccount}.blob.core.windows.net${sasToken}`);
    const container = blobServiceClient.getContainerClient(container_name);

    return container;
}

async function blobToString(blob) {
    const fileReader = new FileReader();

    return new Promise((resolve, reject) => {
        fileReader.onloadend = (ev) => {
            resolve(ev.target.result);
        };
        fileReader.onerror = reject;
        fileReader.readAsDataURL(blob);
    });
}

export async function getResourceUrl(file) {
    // const container = getContainer(process.env.REACT_APP_AZURE_CONTAINER_MEDIA);
    // const blob = container.getBlobClient(file.resource_name);
    // const blobResponse = await blob.generateSasUrl();

    // // return await blobToString(await blobResponse.blobBody);
    // return blobResponse;
    const sasToken = process.env.REACT_APP_AZURE_STORAGE_SAS_TOKEN;
    const storageAccount = process.env.REACT_APP_AZURE_STORAGE_ACCOUNT;
    const container = process.env.REACT_APP_AZURE_CONTAINER_MEDIA;
    return `https://${storageAccount}.blob.core.windows.net/${container}/${file.resource_name}${sasToken}`
}

export async function downloadOneFile(file) {
    const container = getContainer(process.env.REACT_APP_AZURE_CONTAINER_MEDIA);
    const blob = container.getBlobClient(file);
    const blobResponse = await blob.download();

    FileSaver.saveAs( await blobToString(await blobResponse.blobBody), file);
}

async function createBlobInContainer(container, file) {
    const client = container.getBlockBlobClient(file.name);

    const options = { blobHTTPHeaders: { blobContentType: file.type }};

    await client.uploadBrowserData(file, options);
}

export async function uploadOneFile(file) {
    const container = getContainer(process.env.REACT_APP_AZURE_CONTAINER_MEDIA);
    await createBlobInContainer(container, file);
}