import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import TherapistOverlay from "../../components/overlays/therapistOverlay";
import Global from "../../components/styled/global";
import PageWrapper from "../../components/styled/pageWrapper";
import { api } from "../../api";
import Dagboek from "../../components/exercises/dagboek";
import Stappenplan from "../../components/exercises/stappenplan";
import StappenplanMetValidatie from "../../components/exercises/stappenplanMetValidatie";
import LoadSpinner from "../../components/loadspinner";
import { observer } from "mobx-react";
import registrationStore from "../../components/registrationStore";
import Actions from "../../components/exerciseUtilities/videoRecorder/videoRenderActions";
import ResultStore from "../../components/resultStore";
import { Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import qs from "qs";
import { getResourceUrl } from "../../azure";

const GoBack = styled(Link)`
    padding: 10px 20px;
    border: 1px solid #51c0cf;
    background-color: #51c0cf;
    cursor: pointer;
    color: white;
    border-radius: 5px;
    text-decoration: none;
`;

const BackButton = styled.div`
    margin-top: 30px;
`;

const TaakDetail = observer((props) => {
    const { t, i18n } = useTranslation();
    const store = useContext(registrationStore);
    const Rstore = useContext(ResultStore);
    const user = JSON.parse(store.user);
    const { id } = props.match.params;
    const [exercise, setExercise] = useState({});
    const [taskDescription, setTaskDescriptions] = useState("");
    const [loading, setLoading] = useState(true);
    const [redirect, setRedirect] = useState(false);
    const isExampleEx = qs.parse(props.location.search, { ignoreQueryPrefix: true }).preview;
    const isReview = qs.parse(props.location.review, { ignoreQueryPrefix: true }).preview;

    let exerciseComponent;
    let startTime = Date.now();
    useEffect(() => {
        api.get("/exercise/" + id).then((response) => {
            setExercise(response.data.output);
            setLoading(false);
        });
        Rstore.clearRecording();
    }, []);

    const HandleRecording = (blob) => {
        let formData = new FormData();
        formData.append("recording", blob);
        Rstore.setRecording(formData);
    };

    const EndExercise = () => {
        setRedirect(true);
    };

    const getResource = (id) => {
        return api.get("/file/" + id).then((response) => {
            return getResourceUrl(response.data.file) 
        })
    }

    if (exercise.type === "dagboek") {
        exerciseComponent = (
            <Dagboek
                exercise={exercise}
                id={id}
                taskDescription={taskDescription}
                startTime={startTime}
                getRessource={getResource}
                redirectEnabled={false}
                endExercise={EndExercise}
                isExample={isExampleEx == "true"}
                isReview={isReview == "true"}
            />
        );
    } else if (exercise.type === "stappenplan") {
        exerciseComponent = (
            <Stappenplan
                exercise={exercise}
                id={id}
                taskDescription={taskDescription}
                startTime={startTime}
                showRecorder={true}
                getRessource={getResource}
                redirectEnabled={false}
                Actions={Actions}
                HandleRecording={HandleRecording}
                endExercise={EndExercise}
                isExample={isExampleEx == "true"}
                isReview={isReview == "true"}
            />
        );
    } else if (exercise.type === "stappenplanValidatie") {
        exerciseComponent = (
            <StappenplanMetValidatie
                exercise={exercise}
                id={id}
                taskDescription={taskDescription}
                startTime={startTime}
                getRessource={getResource}
                showRecorder={true}
                redirectEnabled={false}
                HandleRecording={HandleRecording}
                endExercise={EndExercise}
            />
        );
    } else {
        exerciseComponent = <div>{t("taakDetail.wrong")}</div>;
    }

    if (loading) {
        return <LoadSpinner />;
    } else {
        return (
            <Global>
                <PageWrapper>
                    <TherapistOverlay />
                    {isExampleEx == "true" && (
                        <BackButton>
                            <GoBack to="/t/CreateMaterial/requestExercise">{t("taakDetail.terugnaaraanvragen")}</GoBack>
                        </BackButton>
                    )}
                    {isReview == "true" && (
                        <BackButton>
                            <GoBack to="/admin">{t("taakDetail.terugNaarAdmin")}</GoBack>
                        </BackButton>
                    )}
                    {exerciseComponent}
                    {redirect &&
                        (isExampleEx == "true" ? (
                            <Redirect to="/t/CreateMaterial/requestExercise" />
                        ) : (isReview == "true" ? (
                            <Redirect to="/admin" />
                        ) : (
                            <Redirect to="/t/consultMaterial" />
                        )))}
                </PageWrapper>
            </Global>
        );
    }
});

export default TaakDetail;
