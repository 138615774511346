import React, { useState, useEffect } from 'react';
import audioFile from "./metronome80.wav";
import styled from "styled-components";
import Button from "../../styled/button";
import { FaPlay, FaStop } from "react-icons/fa";
import { useTranslation } from "react-i18next";


const Text = styled.p`
    font-size: 20px;
    font-weight: bold;
    color: #545454;
    display: block;
    margin-bottom: 0px;
`

const Styled = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
`
const PlayIcon = styled(FaPlay)`
    font-size: 14px;
    color: white;
    margin-right: 15px;
`

const StopIcon = styled(FaStop)`
    font-size: 14px;
    color: white;
    margin-right: 15px;
`

const BPMText = styled.p`
    font-size: 20px;
    display: block;
    margin-top: 5px;
`

const ButtonStart = styled(Button)`
    background: #45AE7C;
    border-color: #45AE7C;
    width: 100%;

    &:hover{
        background: #45AE7C;
        border-color: #45AE7C;
    }
`
const ButtonStop = styled(Button)`
    background: #DC393D;
    border-color: #DC393D;
    width: 100%;

    &:hover{
        background: #DC393D;
        border-color: #DC393D;
    }
`

const BPM = styled.span`
    color: #51C0CF;
    font-size: 16px;
`
const RangeDiv = styled.div`
    width: 100%;
`

const Range = styled.input`
    width: 100%;
`

const Buttons = styled.div`
    width: 100%;
    margin: 15px auto;
`

const Metronoom = () => {
    const { t, i18n } = useTranslation()
    const audio = new Audio(audioFile)
    const [intervalID, setIntervalID] = useState()
    const [rangeValue, setRangeValue] = useState(80)
    const [showPlay, setShowPlay] = useState(true)
    const [showStop, setShowStop] = useState(false)
    const [changeable, setChangeable] = useState(true)

    useEffect(() => {
        return function cleanup(){
            clearInterval(intervalID)
        }
    })

    const playaudio = () => {
        var intervalId = setInterval(function () {
            audio.play()
        }, 60 / rangeValue * 1000)
        setIntervalID(intervalId)
        setShowPlay(false)
        setShowStop(true)
    }
    const stopaudio = () => {
        clearInterval(intervalID)
        setShowPlay(true)
        setShowStop(false)
    }
    const HandleChange = (e) => {
        setRangeValue(e.target.value)
        stopaudio()

    }
    return (

        <Styled>
            <Text>{t('metronoom.metronome')}</Text>
            <BPMText>
                {rangeValue} <BPM>{t('metronoom.bpm')}</BPM>
            </BPMText>
            <RangeDiv>
                <Range type="range" value={rangeValue} min="20" max="200" id="MetronoomRange" onChange={HandleChange} disabled={!changeable} />
            </RangeDiv>
            <Buttons>
                {showPlay && <ButtonStart onClick={() => playaudio()}><PlayIcon />{t('metronoom.start')}</ButtonStart>}
                {showStop && <ButtonStop onClick={() => stopaudio()}><StopIcon />{t('metronoom.stop')}</ButtonStop>}
                {
                    //<input width="200px" type="checkbox" checked={changeable} onChange={() => setChangeable(!changeable)} />
                }
            </Buttons>
        </Styled>
    );
}

export default Metronoom;