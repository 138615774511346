import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import RegistrationStore from "../../components/registrationStore";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { H4, P } from "../../components/styled/typography";
import { FaEyeSlash, FaEye } from "react-icons/fa";

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const Question = styled(H4)`
    font-weight: 500;
    margin: 0px;
    margin-bottom: 15px;
`;

const Input = styled.input`
    background: #ffffff;
    border: 1px solid #51c0cf;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(197, 197, 197, 0.25);
    border-radius: 10px;
    padding: 10px 10px;
    margin-top: 5px;
    outline: none;
    font-size: 16px;
    margin-bottom: 15px;
    width: 100%;

    &.valid {
        border-color: #45ae7c;
    }

    &.invalid {
        border-color: #f02929;
    }
`;

const InputDiv = styled.div`
    position: relative;
    width: 100%;
`;

const EyeSlashIcon = styled(FaEyeSlash)`
    font-size: 20px;
    position: absolute;
    right: 15px;
    top: 16px;
    cursor: pointer;
    color: #545454;
`;

const EyeIcon = styled(FaEye)`
    font-size: 20px;
    position: absolute;
    right: 15px;
    top: 16px;
    cursor: pointer;
    color: #545454;
`;

const TextError = styled(P)`
    margin: 0px;
    margin-bottom: 15px;
    color: #f02929;
`;

const RepeatPassword = observer(({ setRegisterStepIsValid, setRegisterStepIsInvalid, nextStep }) => {
    const { t, i18n } = useTranslation();
    const registrationStore = useContext(RegistrationStore);
    const [passwordIsVisible, setPasswordIsVisible] = useState(false);
    const [repeatedPasswordValue, setRepeatedPasswordValue] = useState("");
    const [repeatedPasswordError, setRepeatedPasswordError] = useState(false);

    function validatePassword(password) {
        return password == registrationStore.password;
    }

    const handlePasswordChange = (e) => {
        const passwordField = document.getElementById("password");
        setRepeatedPasswordValue(e.target.value);
        if (validatePassword(e.target.value)) {
            passwordField.classList.remove("invalid");
            passwordField.classList.add("valid");
            setRegisterStepIsValid();
            setRepeatedPasswordError(false);
        } else {
            passwordField.classList.remove("valid");
            passwordField.classList.add("invalid");
            setRegisterStepIsInvalid();
            setRepeatedPasswordError(true);
        }
    };

    const handleEyeClick = () => {
        setPasswordIsVisible(!passwordIsVisible);
    };

    return (
        <Wrapper>
            <Question black>{t("repeatPasswordRegister.title")}</Question>
            <InputDiv>
                <Input
                    id="password"
                    type={passwordIsVisible ? "text" : "password"}
                    value={repeatedPasswordValue}
                    onChange={(e) => handlePasswordChange(e)}
                    onKeyPress={(e) => nextStep(e)}
                    placeholder={t("repeatPasswordRegister.placeholder")}
                    autoFocus
                    required
                    data-testid="password"
                />
                {!passwordIsVisible && <EyeSlashIcon onClick={handleEyeClick} data-testid="eye-slash-icon" />}
                {passwordIsVisible && <EyeIcon onClick={handleEyeClick} data-testid="eye-icon" />}
            </InputDiv>
            {repeatedPasswordError && (
                <TextError data-testid="password-error">{t("repeatPasswordRegister.error")}</TextError>
            )}
        </Wrapper>
    );
});

export default RepeatPassword;
