import React, { useState, useContext, useEffect } from "react";
import Button from "../components/styled/button";
import styled from "styled-components";
import { api } from "../api";
import RegistrationStore from "../components/registrationStore";
import Header from "../components/header";
import Card from "../components/styled/card";
import { H2, P, H4 } from "../components/styled/typography";
import Flash from "../components/styled/flashMessage";
import { Link } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import Paypal from "../assets/images/paypal.png";
import Bancontact from "../assets/images/bancontact.png";
import { Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DiscountBox from "./discountBox";

const SubmitButton = styled(Button)`
    margin-top: 40px;
    padding-right: 45px;
    padding-left: 45px;
    font-size: 18px;
`;

const A = styled.a`
    color: #51c0cf;
`;

const CheckoutCard = styled(Card)`
    width: 100%;
    margin-top: 20px;

    @media (min-width: 1200px) {
        width: 50%;
        margin-top: 20px;
        padding: 40px 20px;
    }
`;
const Icon = styled(FaArrowLeft)`
    vertical-align: middle;
    margin-right: 5px;
`;

const Title = styled(H2)`
    margin: 20px auto 0px auto;
`;

const Back = styled(Link)`
    padding: 40px 0px 20px 60px;
    text-decoration: none;
    color: #afafaf;
`;

const Container = styled.div`
    padding: 0px 30px;

    @media (min-width: 768px) {
        padding: 0px 60px;
    }
`;

const Column = styled.div`
    padding: 20px 0px;
`;

const ColumnBorder = styled(Column)`
    border: 1px solid #51c0cf;
    border-radius: 10px;
    padding: 30px 20px;
    min-width: 100%;
    margin-bottom: 15px;

    @media (min-width: 768px) {
        min-width: 55%;
    }
`;

const Step = styled(P)`
    font-weight: bold;
    text-align: right;
    padding: 0px 60px 20px 0px;
`;

const Flex = styled.div`
    display: flex;
    justify-content: space-between;
`;

const CheckoutFlex = styled(Flex)`
    margin-top: 50px;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`;

const TitleSmall = styled(H4)`
    font-size: 18px !important;
    margin: 0px;
    margin-bottom: 20px;
`;

const PaymentImage = styled.img`
    height: 40px;
    cursor: pointer;
`;

const PaymentImageBancontact = styled.img`
    height: 75px;
    cursor: pointer;
`;

const NormalFlex = styled.div`
    display: flex;
    align-items: stretch;
`;

const PaymentBox = styled.div`
    padding: 2px 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    margin: 0px 5px;

    &.active {
        border: 1px solid #51c0cf;
    }
`;

const Price = styled.span`
    color: #51c0cf;
`;

const Par = styled.span`
    color: #525252;
    padding-top: 10px;
`;

const Item = styled(Flex)`
    padding-bottom: 10px;
    border-bottom: 1px solid #51c0cf;
    align-items: center;
`;

const ColumnBorderFlex = styled(ColumnBorder)`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Checkout = () => {
    const { t, i18n } = useTranslation();
    const store = useContext(RegistrationStore);
    const user = JSON.parse(store.user);
    const [paymentMethod, setPaymentMethod] = useState("");
    const [plan, setPlan] = useState({});
    const [succesMessage, setSuccesMessage] = useState(false);
    const [failureMessage, setFailureMessage] = useState(false);
    const [discount, setDiscount] = useState(0.0);
    const [redirect, setRedirect] = useState(false);
    const [monthReduction, setMonthReduction] = useState(0);

    function handleClick(e) {
        if (e.target.id == "Paypal") {
            // document.getElementById("Paypal").classList.add("active")
            document.getElementById("bancontact").classList.remove("active");
        } else if (e.target.id == "bancontact") {
            document.getElementById("bancontact").classList.add("active");
            // document.getElementById("Paypal").classList.remove("active")
        }
        setPaymentMethod(e.target.id);
    }

    function handleClickImage(e) {
        if (e.target.id == "Paypal") {
            document.getElementById("bancontact").classList.remove("active");
            // document.getElementById("Paypal").classList.add("active")
        } else if (e.target.id == "bancontact") {
            // document.getElementById("Paypal").classList.remove("active")
            document.getElementById("bancontact").classList.add("active");
        }
        setPaymentMethod(e.target.id);
    }

    function GoCheckout(plan_id, user_id) {
        api.post("/payments", {
            redirect_url: window.location.href,
            plan_id: plan_id,
            month_reduction: monthReduction,
            user_id: user_id,
            discount: discount,
            method: paymentMethod === "" ? "bancontact" : paymentMethod,
        }).then(function (response) {
            if (discount === 1) {
                window.location.reload()
                store.setUser(JSON.stringify(response.data.user));
            } else {
                window.location.href = response.data.checkout;
            }
        });
    }
    
    useEffect(() => {
        api.get("/plans").then((response) => {
            response.data.plans.forEach((plan) => {
                if (store.plan_id === plan.id) {
                    setPlan(plan);
                }
            });
        });
    }, []);

    const succesDiscount = (discount, monthreduction = 0) => {
        setSuccesMessage(true);
        setDiscount(discount);
        setMonthReduction(monthreduction);
        setFailureMessage(false);
    };

    const failedDiscount = () => {
        setFailureMessage(true);
        setSuccesMessage(false);
    };

    return (
        <div>
            <CheckoutCard>
                <Title center black>
                    {t("checkoutStep.payment")}
                </Title>
                <Container>
                    <CheckoutFlex>
                        <ColumnBorder>
                            <TitleSmall black>{t("checkoutStep.toPayout")}</TitleSmall>
                            <Item>
                                <Par>
                                    {t("checkoutStep.subscription")}{" "}
                                    {plan.naam + " " + (plan.period - monthReduction) + t("checkoutStep.months")}:
                                </Par>
                                <Price>€{(Math.round(plan.price * 100) / 100).toFixed(2)}</Price>
                            </Item>
                            <Item>
                                <Par>{t("checkoutStep.discountLabel")}</Par>
                                <Price>€{(discount * plan.price).toFixed(2)}</Price>
                            </Item>
                            <Flex>
                                <Par>{t("checkoutStep.stepout")}:</Par>
                                <Par>€{(Math.round((plan.price - discount * plan.price) * 100) / 100).toFixed(2)}</Par>
                            </Flex>
                            <Item>
                                <Par>{t("checkoutStep.vat")}:</Par>
                                <Par>
                                    €{(Math.round((plan.price - discount * plan.price) * 0.21 * 100) / 100).toFixed(2)}
                                </Par>
                            </Item>
                            <Flex>
                                <Par>{t("checkoutStep.total")}:</Par>
                                <Par>
                                    €{(
                                        Math.round(
                                            (plan.price -
                                                discount * plan.price +
                                                (plan.price - discount * plan.price) * 0.21) *
                                                100
                                        ) / 100
                                    ).toFixed(2)}
                                    
                                </Par>
                            </Flex>
                        </ColumnBorder>

                        <ColumnBorderFlex>
                            {failureMessage && <Flash red>{t("checkoutstep.wrongcode")}</Flash>}
                            {succesMessage && <Flash green>{t("checkoutstep.succescode")}</Flash>}
                            <TitleSmall black>{t("checkoutStep.discountCode")}</TitleSmall>
                            <DiscountBox succes={succesDiscount} failure={failedDiscount} plan_id={plan.id} />
                        </ColumnBorderFlex>
                        <SubmitButton onClick={() => GoCheckout(store.plan_id, user.public_id)}>
                            {t("checkoutStep.toPayout")}
                        </SubmitButton>
                    </CheckoutFlex>
                    <P>
                        <i>
                            {t("checkoutStep.mailInvoice1")}
                            <A href="mailto:info@telephon.digital">info@telephon.digital</A>
                            {t("checkoutStep.invoiceData")}
                        </i>
                    </P>
                </Container>
            </CheckoutCard>
        </div>
    );
};

export default Checkout;
