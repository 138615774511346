import styled, { keyframes } from "styled-components";

const fadein = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

export const shake = keyframes`
    0% { transform: translate(1px, 0px); }
    10% { transform: translate(-1px, 0px); }
    20% { transform: translate(-3px, 0px); }
    30% { transform: translate(3px, 0px); }
    40% { transform: translate(1px, 0px); }
    50% { transform: translate(-1px, 0px); }
    60% { transform: translate(-3px, 0px); }
    70% { transform: translate(3px, 0px) ; }
    80% { transform: translate(-1px, 0px); }
    90% { transform: translate(1px, 0px); }
    100% { transform: translate(1px, 0px); }
`;

export const PageAnimation = styled.div`
    animation: ${fadein} 0.5s;
`;

export const uploadKeyframes = keyframes`
    from {
        background-position: right top;
    }
    to {
        background-potition: right bottom;
    }
`;
