import React, {useState, useEffect} from "react"
import styled from "styled-components"
import {api} from "../api"
import Card from '../components/styled/card'
import { H4 } from '../components/styled/typography'
import TherapistIconMan from "../assets/images/therapist-icon-man.png"
import TherapistIcon from '../assets/images/therapist-icon.png'
import LoadSpinner from '../components/loadspinner'

const Wrapper = styled(Card)`
    padding: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: 980px){
        width: 24%;
    }
`

const Icon = styled.img`
    height: 50px;
    margin-right: 15px;
`

const Name = styled(H4)`
    margin: 0px;
`

function Article(props){
    const [therapist, setTherapist] = useState({})
    const [loading, setLoading] = useState(true)
    const public_id = props.public_id

    useEffect(() => {
        api.get('/user/' + public_id).then((response) => {
            setTherapist(response.data.user)
            setLoading(false)
        })
    }, []);
    if (loading){
        return(
            <Wrapper>
                <LoadSpinner />
            </Wrapper>
        )
    } else {
        return(
            <Wrapper>
                <Icon src={therapist.sex == 'Man' ? TherapistIconMan : TherapistIcon} />
                <Name>{therapist.firstName + " " + therapist.lastName}</Name>
            </Wrapper>
        )
    }
}

export default Article