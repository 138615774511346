import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import TherapistOverlay from "../../components/overlays/therapistOverlay";
import Global from "../../components/styled/global";
import Card from "../../components/styled/card";
import { api, recordingApi } from "../../api";
import { observer } from "mobx-react";
import registrationStore from "../../components/registrationStore";
import PageWrapper from "../../components/styled/pageWrapper";
import { PageTitle, SmallTitle, P } from "../../components/styled/typography";
import { PageAnimation } from "../../components/styled/animation";
import PatientStore from "../../components/patientStore";
import LogboekGraph from "../../components/resultsGraph/logboekGraph";
import LoadSpinner from "../../components/loadspinner";
import FileSaver from "file-saver";
import { useTranslation } from "react-i18next";
import ResultsGraph from "../../components/resultsGraph/resultsGraph";

const ScoresTitle = styled(SmallTitle)`
    color: #545454;
    margin-top: 15px;
    font-size: 22px;
`;

const ScoreCard = styled(Card)`
    width: 28%;
`;

const Flex = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`;

const OpenAnswCard = styled(Card)`
    margin-top: 20px;
    max-width: 70%;
`;
const Question = styled.div`
    font-weight: bold;
    margin-bottom: 10px;
    color: #545454;
`;
const Answer = styled.div`
    margin-bottom: 10px;
    max-width: 800px;
    color: #545454;
`;
const Openanswertitle = styled.div`
    color: #545454;
`;

const VideoDiv = styled.div`
    video {
        max-width: 70%;
    }
`;

const QABlock = styled.div`
    border-bottom: 1px solid #51c0cf;
    margin-bottom: 10px;
    margin-top: 20px;
`;

const Logboek = observer((props) => {
    const { t, i18n } = useTranslation();
    const store = useContext(registrationStore);
    const patientStore = useContext(PatientStore);
    const user = JSON.parse(store.user);
    const { id } = props.match.params;
    const [task, setTask] = useState({});
    const [thisAssignment, setThisAssignment] = useState([]);
    const [loading, setLoading] = useState(true);
    const [recording, setRecording] = useState();
    const [recordingLoad, setRecordingLoad] = useState(true);
    const [videoLoading, setVideoLoading] = useState(false);

    useEffect(() => {
        api.get("/completedTask/" + id).then((response) => {
            setTask(response.data.completedTask);
            const task_id = response.data.completedTask.task_id;

            if (response.data.completedTask.recording) {
                setVideoLoading(true)
                getRecording(response.data.completedTask.recording);
            }
            api.get("user/" + response.data.completedTask.user_id + "/completedtasks").then((response) => {
                for (var i = 0; i < response.data.output.length; i++) {
                    for (var j = 0; j < response.data.output[i].taken.length; j++) {
                        if (response.data.output[i].taken[j].task_id === task_id) {
                            setThisAssignment(response.data.output[i].taken[j].results);
                            setLoading(false);
                        }
                    }
                }
            });
        });
    }, []);

    const getRecording = (recording) => {
        recordingApi.get("/recording/" + recording, { responseType: "blob" }).then(function (response) {
            setRecording(response.data);
            setRecordingLoad(false);
            setVideoLoading(false);
        });
    };

    return (
        <Global>
            <PageWrapper>
                <TherapistOverlay />
                <PageAnimation>
                    <PageTitle black>
                        {t("logboekDetail.log")} {task.task_title}
                    </PageTitle>
                    {loading ? (
                        <LoadSpinner />
                    ) : (
                        <div>
                            <Flex>
                                <LogboekGraph myresults={thisAssignment} title={task.task_title} />
                                {task.scores && (
                                    <ScoreCard>
                                        <ScoresTitle>{t("logboekDetail.scores")}</ScoresTitle>
                                        {Object.keys(task.scores).map((key) => {
                                            return (
                                                <p>
                                                    {key} : {task.scores[key]}
                                                </p>
                                            );
                                        })}
                                    </ScoreCard>
                                )}
                            </Flex>
                            {videoLoading && (
                                <>
                                    <h2>{t("logboekDetail.video")}</h2>
                                    <LoadSpinner />
                                </>
                            )}
                            {task.recording !== null && !recordingLoad && (
                                <VideoDiv>
                                    <h2>{t("logboekDetail.video")}</h2>
                                    <video controls controlsList="nodownload">
                                        <source src={URL.createObjectURL(recording)} type="video/mp4" />
                                    </video>
                                </VideoDiv>
                            )}
                            {task.scaledAnswers &&
                                <OpenAnswCard>
                                    <h2>
                                        <Openanswertitle>{t("logboekDetail.scaleAnswers")}:</Openanswertitle>
                                    </h2>
                                    <ol>
                                        {Object.keys(task.scaledAnswers).map((question) => {
                                            return (
                                                <li>
                                                    <Question>{question} :</Question>
                                                    <Answer>{task.scaledAnswers[question]}</Answer>
                                                </li>
                                            );
                                        })}
                                    </ol>
                                </OpenAnswCard>
                            }
                            {task.openAnswers &&
                                <OpenAnswCard>
                                    <h2>
                                        <Openanswertitle>{t("logboekDetail.openAnswers")}:</Openanswertitle>
                                    </h2>
                                    <ol>
                                        {Object.keys(task.openAnswers).map((question) => {
                                            return (
                                                <li>
                                                    <Question>{question} :</Question>
                                                    <Answer>{task.openAnswers[question]}</Answer>
                                                </li>
                                            );
                                        })}
                                    </ol>
                                </OpenAnswCard>
                            }
                        </div>
                    )}
                    {task.questions && (
                        <OpenAnswCard>
                            <ScoresTitle>Oefening details</ScoresTitle>
                            {task.questions.map((question) => {
                                return (
                                    <QABlock>
                                        <Question>Vraag:</Question>
                                        <Answer>{question.question}</Answer>
                                        <Question>Antwoorden:</Question>
                                        {question.answers.map((answer, index) => {
                                            return (
                                                <Answer>
                                                    <u>poging {index + 1}:</u> {answer}
                                                </Answer>
                                            );
                                        })}
                                    </QABlock>
                                );
                            })}
                        </OpenAnswCard>
                    )}
                </PageAnimation>
            </PageWrapper>
        </Global>
    );
});

export default Logboek;
