import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Card from "../styled/card";
import { H4, P } from "../styled/typography";
import { Redirect, Link } from "react-router-dom";
import dateConverter from "../helperComponents/dateConverter";

// Icons
import Image from "../../assets/images/image.svg";
import Audio from "../../assets/images/audio.svg";
import Video from "../../assets/images/video.svg";
import Unknown from "../../assets/images/unknown.svg";

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 15px;

  @media (min-width: 768px) {
    width: ${(props) => (props.mediaSelect ? "100%" : "49.5%")};
  }
`;

const CardStyled = styled(Card)`
  height: 100%;
  cursor: pointer;

  &.active {
    border: 2px solid #51c0cf;
  }
`;

const Title = styled(H4)`
  margin: 0px;
  font-size: 18px;
  margin-top: 5px;

  @media (min-width: 980px) {
    font-size: 20px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const Details = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 33%;
`;

const SmallText = styled(P)`
  font-size: 16px;
  font-style: italic;
  margin: 0px;
`;

const Icon = styled.img`
  width: 25px;
  height: 25px;
  margin-left: 15px;

  @media (min-width: 1200px) {
    width: 35px;
    height: 35px;
  }
`;

const TagWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 75%;
`;

const Tag = styled.div`
  background-color: #51c0cf;
  border: 1px solid #51c0cf;
  border-radius: 5px;
  margin-right: 5px;
  margin-top: 5px;
  padding: 3px 10px;

  @media (min-width: 1400) {
    padding: 5px 15px;
    margin-right: 10px;
  }
`;

const TagText = styled(P)`
  color: white;
  margin: 0px;
  font-size: 15px;

  @media (min-width: 1400px) {
    font-size: 18px;
  }
`;

const AuthorInfo = styled.div`
  display: none;
`;

function MediaCard(props) {
    const { t, i18n } = useTranslation();
    const [redirect, setRedirect] = useState(false);
    const type = props.media.type;
    let ActiveIcon;
    let hoverText;
    switch (type) {
        case "image":
            ActiveIcon = Image;
            hoverText = t("mediacard.image");
            break;
        case "audio":
            ActiveIcon = Audio;
            hoverText = t("mediacard.audio");
            break;
        case "video":
            ActiveIcon = Video;
            hoverText = t("mediacard.video");
            break;
        default:
            ActiveIcon = Unknown;
            hoverText = t("mediacard.unkown");
            break;
    }

    function handleCardClick(event) {
        if (props.mediaSelect) {
            var elems = document.querySelectorAll("#card");
            elems.forEach.call(elems, function (el) {
                el.classList.remove("active");
            });

            let card = event.target;
            if (event.target.type !== "div") {
                card = event.target.closest("#card");
            }
            card.className += " active";
            props.callBack(props.media.id, props.media.name, type);
        } else {
            setRedirect(true);
        }
    }

    return (
        <Wrapper title={hoverText} mediaSelect={props.mediaSelect}>
            <CardStyled aria-label={props.label || "card"} id="card" onClick={(e) => handleCardClick(e)}>
                <Header id="">
                    <Title black>{props.media && props.media.name}</Title>
                    <Details>
                        <AuthorInfo>
                            <SmallText>{dateConverter(new Date(props.media.date_created))}</SmallText>
                            <SmallText>{props.media.therapist_name}</SmallText>
                        </AuthorInfo>
                        <Icon src={ActiveIcon} />
                    </Details>
                </Header>
                {props.showDescription && <SmallText>{props.media.description}</SmallText>}
                <TagWrapper>
                    {props.media.tags &&
                        props.media.tags.map((tag) => {
                            return (
                                <>
                                    {tag.name !== "" && (
                                        <Tag>
                                            <TagText>{t(tag.name)}</TagText>
                                        </Tag>
                                    )}
                                </>
                            );
                        })}
                </TagWrapper>
            </CardStyled>
            {redirect && <Redirect push to={{ pathname: "consultMaterial/mediaDetail/" + props.media.id }} />}
        </Wrapper>
    );
}

export default MediaCard;
