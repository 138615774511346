import React, { useState, useEffect, useRef, useContext } from "react";
import * as Yup from "yup";
import styled from "styled-components";
import { Formik, Form, Field, FieldArray, getIn, useFormikContext } from "formik";
import Button from "../styled/button";
import registrationStore from "../registrationStore";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import {
    FaTimes,
    FaTrash,
    FaPencilAlt,
    FaInfoCircle,
    FaShoePrints,
    FaCog,
    FaBullhorn,
    FaPhotoVideo
} from "react-icons/fa";
import Card from "../styled/card";
import { SmallTitle, P } from "../styled/typography";
import { Switch } from "../styled/switch";
import MediaOverlay from "./mediaSelectionOverlay";
import { Disciplines, Subdisciplines } from "../resources/disciplines";
import { Languages } from "../resources/languages";
import TagSelect from "../helperComponents/tagSelect";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {DisplayFormikState} from "../helperComponents/formHelper";

const ExcerciseForm = styled(Form)``;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const MultiLineGroup = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  border-left: 1px solid rgba(123, 140, 168, 0.25);
`;

const Input = styled(Field)`
  background: #ffffff;
  border: 1px solid #51c0cf;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(197, 197, 197, 0.25);
  border-radius: 10px;
  padding: 10px 10px;
  margin-top: 5px;
  outline: none;
  font-size: 16px;
  max-height: 100%;
  margin-right: 20px;

  &:focus {
    resize: both;
  }
`;

const Label = styled.label`
  font-size: 16px;
  font-weight: 600;
  color: #545454;
  margin-right: 20px;
`;

const Error = styled.p`
  font-size: 16px;
  color: #afafaf;
  font-style: italic;
`;

const Overlay = styled.div`
  background-color: rgba(60, 60, 60, 0.7);
  position: fixed;
  top: 0px;
  left: 0;
  width: 100vw;
  height: 100%;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 980px) {
    top: 0;
    height: 100vh;
    width: 100vw;
  }
`;

const OverlayCard = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-height: 75%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
  padding: 30px;
  position: absolute;
  z-index: 999999;

  @media (min-width: 980px) {
    position: relative;
    top: 0;
    z-index: 9;
  }
`;

const Times = styled(FaTimes)`
  color: #545454;
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
`;

const Trash = styled(FaTrash)`
  color: #545454;
  cursor: pointer;
`;

const Pencil = styled(FaPencilAlt)`
  color: #545454;
  margin-right: 20px;
  cursor: pointer;
`;

const SmallButton = styled(Button)`
  margin-top: 5px;
  width: fit-content;
`;

const SelectMediaButton = styled(Button)`  
  display: inline-block;
  vertical-align: middle;
  height: 25px;  
  padding: 3px 15px 10px 15px; 
  font-size: 0.9em;
  text-align: center;
  margin-left: 10px;
`;

const Title = styled(SmallTitle)`
  margin-top: 10px;
  margin-right: 40px;
`;

const FileCard = styled(Card)`
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const InfoIcon = styled(FaInfoCircle)`  
  color: #51c0cf;
  font-size: 15px;
  margin-right: 10px;
  vertical-align: middle;
  margin-bottom: 5px;
`;

const StepsIcon = styled(FaShoePrints)`  
  color: #51c0cf;
  font-size: 15px;
  margin-right: 10px;
  vertical-align: middle;
  margin-bottom: 5px;
`;

const CogIcon = styled(FaCog)`  
  color: #51c0cf;
  font-size: 15px;
  margin-right: 10px;
  vertical-align: middle;
  margin-bottom: 5px;
`;

const BullHornIcon = styled(FaBullhorn)`  
  color: #51c0cf;
  font-size: 15px;
  margin-right: 10px;
  vertical-align: middle;
  margin-bottom: 5px;
`;

const MultimediaIcon = styled(FaPhotoVideo)`  
  color: #51c0cf;
  font-size: 15px;
  margin-right: 10px;
  vertical-align: middle;
  margin-bottom: 5px;
`;

const ExerciseForm = observer(({ onSubmit, ...props }) => {
    const { t, i18n } = useTranslation();
    const [formSchema, setFormShema] = useState(Yup.object().shape({}));
    const [showOverlay, setShowOverlay] = useState(false);
    const [showSecondOverlay, setShowSecondOverlay] = useState(false);
    const [showMediaOverlay, setShowMediaOverlay] = useState(false);
    const [questionTypeId, setQuestionTypeId] = useState(-1);
    const [questionId, setQuestionId] = useState(-1);
    const [stepId, setStepId] = useState(-1);
    const [mediaInputLabel, setMediaInputLabel] = useState("");
    const store = useContext(registrationStore);
    const user = JSON.parse(store.user);
    const [tags, setTags] = useState([]);

    const handleSubmit = async (values) => {
        let fullValues = { ...values };

        //Remap and add default tags
        fullValues.tags = tags;
        fullValues.type = props.exerciseType;

        //Add user email to private check
        if (fullValues.private?.shouldBePrivate) {
            fullValues.private = {
                shouldBePrivate: fullValues.private.shouldBePrivate,
                usernamePrivate: user?.email,
            };
        } else {
            fullValues.private = {
                shouldBePrivate: false,
                usernamePrivate: user?.email,
            };
        }

        //Add needed values to schaal type questions in vragenlijst
        if (props.exerciseType === "dagboek") {

            fullValues.questionTypes.forEach((questionType) => {
                questionType.questions.forEach((question) => {
                    if (question.answerpossibility === "schaal") {
                        question.scalePossibilities = question.fieldnames.length;
                        question.maxScore = Math.max(...question.fieldvalues);
                    }
                });
            });
        }

        //Clean unwanted step checker values
        fullValues.goal?.media?.enabled ? delete fullValues.goal?.media?.enabled : delete fullValues.goal?.media;
        fullValues.goal?.video?.enabled ? delete fullValues.goal?.video?.enabled : delete fullValues.goal?.video;
        fullValues.preparation?.media?.enabled
            ? delete fullValues.preparation?.media?.enabled
            : delete fullValues.preparation?.media;
        fullValues.preparation?.video?.enabled
            ? delete fullValues.preparation?.video?.enabled
            : delete fullValues.preparation?.video;
        fullValues.mediaComplete?.enabled ? delete fullValues.mediaComplete?.enabled : delete fullValues.mediaComplete;
        fullValues.audioComplete?.enabled ? delete fullValues.audioComplete?.enabled : delete fullValues.audioComplete;
        fullValues.videoComplete?.enabled ? delete fullValues.videoComplete?.enabled : delete fullValues.videoComplete;
        if (fullValues.steps) {
            fullValues.steps.forEach((step) => {
                step.media?.enabled ? delete step.media?.enabled : delete step.media;
                step.audio?.enabled ? delete step.audio?.enabled : delete step.audio;
                step.video?.enabled ? delete step.video?.enabled : delete step.video;
            });
        }
        if (fullValues.questionTypes) {
            fullValues.questionTypes.forEach((questionType) => {
                questionType.questions.forEach((question) => {
                    question.media?.enabled ? delete question.media?.enabled : delete question.media;
                    question.audio?.enabled ? delete question.audio?.enabled : delete question.audio;
                    question.video?.enabled ? delete question.video?.enabled : delete question.video;
                    question.cue?.media?.enabled ? delete question.cue?.media?.enabled : delete question.cue?.media;
                    question.cue?.audio?.enabled ? delete question.cue?.audio?.enabled : delete question.cue?.audio;
                    question.cue?.video?.enabled ? delete question.cue?.video?.enabled : delete question.cue?.video;
                });
            });
        }

        onSubmit(fullValues);
    };

    const qaTypes = [
        <option key="NoneQA" value=""></option>,
        <option key="open antwoord" value="open antwoord">{t("exerciseForm.openAnswer")}</option>,
        <option key="schaal" value="schaal">{t("exerciseForm.scale")}</option>,
    ];
    const answerTypes = [
        <option key="NoneA" value=""></option>,
        <option key="Meerkeuze" value="Meerkeuze">{t("exerciseForm.multipleChoice")}</option>,
        <option key="Textbox" value="Textbox">{t("exerciseForm.textbox")}</option>,
    ];
    const rewardSystems = [
        <option key="NoneR" value=""></option>,
        <option key="Geen" value="Geen">{t("exerciseForm.noneReward")}</option>,
        <option key="Score" value="Score">{t("exerciseForm.scoreReward")}</option>,
    ];

    // FORM LOGIC

    const initialValues = {
        title: "",
        discipline: "",
        language: "nederlands",
        description: ""
    };

    const validationShape = {
        title: Yup.string()
            .max(20, t("exerciseForm.titleMaxChars"))
            .required(t("exerciseForm.titleReqError")),
        discipline: Yup.string().required(t("exerciseForm.disciplineReqError")),
        subdiscipline: Yup.string().notRequired(),
        language: Yup.string().required(t("exerciseForm.languageReqError")),
        description: Yup.string().notRequired(),
        private: Yup.object().shape({
            shouldBePrivate: Yup.bool().notRequired(),
        })
    };

    const mediaValidationShape = {
        enabled: Yup.boolean().notRequired(),
        asset: Yup.string().when("enabled", {
            is: (val) => val,
            then: Yup.string().required(t("exerciseForm.assetReqError")),
            otherwise: Yup.string().notRequired(),
        }),
    };

    const formikRef = useRef();
    useEffect(() => {
        //restForm
        if (formikRef.current != null) {
            formikRef.current.resetForm();
        }
        //adapt validation
        adaptValidation();
    }, [props.exerciseType]);

    useEffect(() => {
        //Focus on overlay
        if (showOverlay) {
            autoFocus(
                13,
                props.exerciseType === "stappenplan" ? `steps[${stepId}].text` : `questionTypes[${questionTypeId}].name`
            );
        }
        //adapt validation
        adaptValidation();
    }, [showOverlay]);

    useEffect(() => {
        //Focus on overlay
        if (showSecondOverlay) {
            autoFocus(13, `${getQuestionId()}.question`);
        }
        //adapt validation
        adaptValidation();
    }, [showSecondOverlay]);

    const adaptValidation = () => {
        let newValidation = validationShape;
        let question = {};
        let questionType = {};
        switch (props.exerciseType) {
            case "dagboek":
                question = Yup.object().shape({
                    question: Yup.string().required(t("exerciseForm.vragenlijstQuestionReqError")),
                    answerpossibility: Yup.string().required(t("exerciseForm.vragenlijstAnswerPossibilityReqError")),
                    fieldnames: Yup.array()
                        .of(Yup.string())
                        .when("answerpossibility", {
                            is: (val) => val !== "open antwoord",
                            then: Yup.array()
                                .of(Yup.string().required(t("exerciseForm.fieldNameReqError")))
                                .min(1, t("exerciseForm.fieldNamesError"))
                                .required(t("exerciseForm.fieldNamesError")),
                            otherwise: Yup.array().of(Yup.string()).notRequired(),
                        }),
                    fieldvalues: Yup.array()
                        .of(Yup.number())
                        .when("answerpossibility", {
                            is: (val) => val !== "open antwoord",
                            then: Yup.array()
                                .of(
                                    Yup.number()
                                        .min(0, t("exerciseForm.fieldValueInvError"))
                                        .required(t("exerciseForm.fieldValueReqError"))
                                )
                                .test({
                                    name: "min",
                                    exclusive: false,
                                    params: {},
                                    message: t("exerciseForm.fieldValuesError"),
                                    test: function (value) {
                                        return this.parent.fieldnames?.length > 0
                                            ? value?.length === this.parent.fieldnames?.length
                                            : value?.length > 0;
                                    },
                                })
                                .required(t("exerciseForm.fieldValuesError")),
                            otherwise: Yup.array().of(Yup.number()).notRequired(),
                        })
                });
                questionType = Yup.object().shape({
                    name: Yup.string().required(t("exerciseForm.vragenlijstQuestionTypeNameReqError")),
                    questions: Yup.array()
                        .of(question)
                        .min(1, t("exerciseForm.vragenlijstQuestionsError"))
                        .required(t("exerciseForm.vragenlijstQuestionsError")),
                });
                newValidation.questionTypes = Yup.array()
                    .of(questionType)
                    .min(1, t("exerciseForm.vragenlijstQuestionTypeError"))
                    .required(t("exerciseForm.vragenlijstQuestionTypeError"));
                break;
            case "stappenplan":
                const step = Yup.object().shape({
                    text: Yup.string().required(t("exerciseForm.stepTextReqError")),
                    media: Yup.object().shape(mediaValidationShape),
                    video: Yup.object().shape(mediaValidationShape),
                    audio: Yup.object().shape(mediaValidationShape),
                });
                newValidation.steps = Yup.array()
                    .of(step)
                    .min(1, t("exerciseForm.stepsError"))
                    .required(t("exerciseForm.stepsError"));
                newValidation.mediaComplete = Yup.object().shape(mediaValidationShape);
                newValidation.audioComplete = Yup.object().shape(mediaValidationShape);
                newValidation.videoComplete = Yup.object().shape(mediaValidationShape);
                newValidation.goal = Yup.object().shape({
                    media: Yup.object().shape(mediaValidationShape),
                    video: Yup.object().shape(mediaValidationShape),
                });
                newValidation.preparation = Yup.object().shape({
                    media: Yup.object().shape(mediaValidationShape),
                    video: Yup.object().shape(mediaValidationShape),
                });
                break;
            case "stappenplanValidatie":
                question = Yup.object().shape({
                    question: Yup.string().required(t("exerciseForm.invulQuestionReqError")),
                    media: Yup.object().shape(mediaValidationShape),
                    video: Yup.object().shape(mediaValidationShape),
                    audio: Yup.object().shape(mediaValidationShape),
                    cue: Yup.object()
                        .shape({
                            text: Yup.string().notRequired(),
                            media: Yup.object().shape(mediaValidationShape),
                            video: Yup.object().shape(mediaValidationShape),
                            audio: Yup.object().shape(mediaValidationShape),
                        })
                        .notRequired(),
                    typeOfAnswer: Yup.string().required(t("exerciseForm.typeOfAnswerReqError")),
                    correctAnswer: Yup.string().required(t("exerciseForm.correctAnswerReqError")),
                    answerPossibilities: Yup.array()
                        .of(Yup.string())
                        .when("typeOfAnswer", {
                            is: (val) => val === "Meerkeuze",
                            then: Yup.array()
                                .of(Yup.string().required(t("exerciseForm.invulAnswerPossibilityReqError")))
                                .min(1, t("exerciseForm.answerPossibilitiesError"))
                                .required(t("exerciseForm.answerPossibilitiesError")),
                            otherwise: Yup.array().of(Yup.string()).notRequired(),
                        }),
                });
                questionType = Yup.object().shape({
                    name: Yup.string().required(t("exerciseForm.invulQuestionTypeNameReqError")),
                    questions: Yup.array()
                        .of(question)
                        .min(1, t("exerciseForm.invulQuestionsError"))
                        .required(t("exerciseForm.invulQuestionsError")),
                });
                newValidation.questionTypes = Yup.array()
                    .of(questionType)
                    .min(1, t("exerciseForm.invulQuestionTypeError"))
                    .required(t("exerciseForm.invulQuestionTypeError"));
                newValidation.mediaComplete = Yup.object().shape(mediaValidationShape);
                newValidation.audioComplete = Yup.object().shape(mediaValidationShape);
                newValidation.videoComplete = Yup.object().shape(mediaValidationShape);
                newValidation.goal = Yup.object().shape({
                    media: Yup.object().shape(mediaValidationShape),
                    video: Yup.object().shape(mediaValidationShape),
                });
                newValidation.preparation = Yup.object().shape({
                    media: Yup.object().shape(mediaValidationShape),
                    video: Yup.object().shape(mediaValidationShape),
                });
                newValidation.options = Yup.object().shape({
                    rewardSystem: Yup.string().required(t("exerciseForm.rewardSystemReqError")),
                });
                break;
            default:
                break;
        }
        setFormShema(Yup.object().shape(newValidation));
    };

    const handleShowOverlay = (values, id = null) => {
        var newTypeId = id != null ? id : values.questionTypes?.length ?? 0;
        var newStepId = id != null ? id : values.steps?.length ?? 0;
        setQuestionTypeId(newTypeId);
        setStepId(newStepId);
        setShowOverlay(true);
    };

    const handleShowSecondOverlay = (values, id = null) => {
        var newQuestionId = id != null ? id : values.questionTypes?.[questionTypeId]?.questions?.length ?? 0;
        setQuestionId(newQuestionId);
        setShowSecondOverlay(true);
    };

    const handleHideOverlay = () => {
        setShowOverlay(false);
    };

    const handleHideSecondOverlay = () => {
        setShowSecondOverlay(false);
    };

    const getQuestionId = () => `questionTypes[${questionTypeId}].questions[${questionId}]`;

    const autoFocus = (key, name) => {
        if (key === 13) {
            var len = document.getElementsByName(name).length;
            if (len > 0) {
                const element = document.getElementsByName(name)[len - 1];
                element.focus();
            }
        }
    };

    const showError = (touched, errors, name, wantTouched = true) => {
        if (typeof getIn(errors, name) !== "string") return null;
        return wantTouched
            ? getIn(touched, name) && getIn(errors, name) && <Error>{getIn(errors, name)}</Error>
            : getIn(errors, name) && <Error>{getIn(errors, name)}</Error>;
    };

    const showMediaSelOverlay = (inputLabel) => {
        setMediaInputLabel(inputLabel);
        setShowMediaOverlay(true);
    };

    const SetMediaInput = (media) => {
        formikRef.current.setFieldValue(mediaInputLabel, media.id);
        formikRef.current.setFieldValue(mediaInputLabel + "Label", `${media.id} - ${media.title}`);
    };

    const MediaSwitch = (fProps, switchLabel, valuePath) => {
        const switchName = valuePath + ".enabled";
        const assetName = valuePath + ".asset";
        return (
            <>
                <Switch name={switchName} label={t(switchLabel)} >
                    <br /><br />
                    <SelectMediaButton type="button" onClick={() => showMediaSelOverlay(assetName)}>
                        <FaPhotoVideo />{" "}
                        {t("exerciseForm.selectMedia")}{" "}
                    </SelectMediaButton>
                    <Input aria-label={assetName} type="hidden" name={assetName} />
                </Switch>
                {showError(fProps.touched, fProps.errors, assetName, false)}
                {getIn(fProps.values, assetName) && <FileCard>{getIn(fProps.values, assetName + "Label")}</FileCard>}

            </>
        );
    };

    if (!["stappenplan", "stappenplanValidatie", "dagboek"].includes(props.exerciseType)) {
        return null;
    }

    const formLayout = ({ ...fProps }) => {
        return (
            <ExcerciseForm name="exerciseForm">
                {/*GENERAL HEADER FORM SECTION*/}
                <FormGroup>
                    <Label htmlFor="title">{t("exerciseForm.title")}</Label>
                    <Input
                        autoFocus={true}
                        onKeyDown={(e) => autoFocus(e.keyCode, "discipline")}
                        type="text"
                        id="title"
                        name="title"
                        placeholder={t("exerciseForm.titleInstructions")}
                    />
                    {showError(fProps.touched, fProps.errors, "title")}
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="discipline">{t("exerciseForm.discipline")}</Label>
                    <Input
                        component="select"
                        id="discipline"
                        name="discipline"
                        onKeyDown={(e) => autoFocus(e.keyCode, "subdiscipline")}
                    >
                        <option value="">{t("documentForm.allDis")}</option>
                        <Disciplines default={user?.speciality} />
                    </Input>
                    {showError(fProps.touched, fProps.errors, "discipline")}
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="subdiscipline">{t("exerciseForm.subdiscipline")}</Label>
                    <hr />
                    <Input
                        component="select"
                        id="subdiscipline"
                        name="subdiscipline"
                        onKeyDown={(e) => autoFocus(e.keyCode, "language")}
                    >
                        <option value="">{t("exerciseForm.allSub")}</option>
                        <Subdisciplines discipline={fProps.values.discipline} />
                    </Input>
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="language">{t("exerciseForm.language")}</Label>
                    <Input
                        component="select"
                        id="language"
                        name="language"
                        onKeyDown={(e) => autoFocus(e.keyCode, props.exerciseType === "dagboek" ? "description" : "goal.text")}
                    >
                        <Languages default={i18n.language} />
                    </Input>
                    {showError(fProps.touched, fProps.errors, "language")}
                </FormGroup>

                {/* VRAGENLIJST UNIQUE HEADER FORM SECTION */}
                {props.exerciseType === "dagboek" && (
                    <>
                        <FormGroup>
                            <Label>{t("exerciseForm.descriptionInstructions")}</Label>
                            <CKEditor
                                id="description"
                                name="description"
                                editor={ ClassicEditor }
                                data={fProps.values.description}
                                onChange={ ( event, editor ) => {
                                    fProps.values.description = editor.getData();
                                }}
                                placeholder={t("exerciseForm.descriptionInstructions")}
                                config={{
                                    placeholder: t('fileForm.inputDescription'),
                                    toolbar: ['heading', '|', 'bold', 'italic', 'blockQuote', '|', 'numberedList', 'bulletedList', 'insertTable', 'tableColumn', 'tableRow', 'mergeTableCells',  '|', 'undo', 'redo'],
                                    cloudServices: {tokenUrl : process.env.REACT_APP_COBRAND_API + "/editor_token/" + user.public_id, uploadUrl : "https://55482.cke-cs.com/easyimage/upload/"}
                                }}
                                onError={ ( { willEditorRestart } ) => {
                                    // If the editor is restarted, the toolbar element will be created once again.
                                    // The `onReady` callback will be called again and the new toolbar will be added.
                                    // This is why you need to remove the older toolbar.
                                    if ( willEditorRestart ) {
                                        this.editor.ui.view.toolbar.element.remove();
                                    }
                                } }
                            />
                            {showError(fProps.touched, fProps.errors, "description")}
                        </FormGroup>
                    </>
                )}

                {/* INVUL + UITVOER CROSSOVER HEADER FORM SECTION */}
                {(props.exerciseType === "stappenplan" || props.exerciseType === "stappenplanValidatie") && (
                    <>
                        <FormGroup>
                            <Label htmlFor="goal.text"><InfoIcon />{t("exerciseForm.goal").charAt(0).toUpperCase() + t("exerciseForm.goal").slice(1)}</Label>
                            <hr />
                            <MultiLineGroup>
                                <CKEditor
                                    id="goal.text"
                                    name="goal.text"
                                    editor={ ClassicEditor }
                                    data={fProps.values.goal?.text ? fProps.values.goal.text : null }
                                    onChange={ ( event, editor ) => {
                                        if (! fProps.values.goal) {
                                            fProps.values.goal = {}
                                        }
                                        fProps.values.goal.text = editor.getData();
                                    }}
                                    config={{
                                        placeholder: t("exerciseForm.goalInstructions"),
                                        toolbar: ['heading', '|', 'bold', 'italic', 'blockQuote', '|', 'numberedList', 'bulletedList', 'insertTable', 'tableColumn', 'tableRow', 'mergeTableCells',  '|', 'undo', 'redo'],
                                        cloudServices: {tokenUrl : process.env.REACT_APP_COBRAND_API + "/editor_token/" + user.public_id, uploadUrl : "https://55482.cke-cs.com/easyimage/upload/"}
                                    }}
                                    onError={ ( { willEditorRestart } ) => {
                                        // If the editor is restarted, the toolbar element will be created once again.
                                        // The `onReady` callback will be called again and the new toolbar will be added.
                                        // This is why you need to remove the older toolbar.
                                        if ( willEditorRestart ) {
                                            this.editor.ui.view.toolbar.element.remove();
                                        }
                                    } }
                                />
                                {MediaSwitch(fProps, "exerciseForm.mediaCheck", "goal.media")}
                                {MediaSwitch(fProps, "exerciseForm.videoCheck", "goal.video")}
                            </MultiLineGroup>
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="preparation.text"><InfoIcon />{t("exerciseForm.preparation").charAt(0).toUpperCase() + t("exerciseForm.preparation").slice(1)}</Label>
                            <hr />
                            <MultiLineGroup>
                                <CKEditor
                                    id="preparation.text"
                                    name="preparation.text"
                                    editor={ ClassicEditor }
                                    data={fProps.values.preparation?.text ? fProps.values.preparation.text : null}
                                    onChange={ ( event, editor ) => {
                                        if (! fProps.values.preparation) {
                                            fProps.values.preparation = {}
                                        }
                                        fProps.values.preparation.text = editor.getData();
                                    }}
                                    config={{
                                        placeholder: t("exerciseForm.preparationInstructions"),
                                        toolbar: ['heading', '|', 'bold', 'italic', 'blockQuote', '|', 'numberedList', 'bulletedList', 'insertTable', 'tableColumn', 'tableRow', 'mergeTableCells',  '|', 'undo', 'redo'],
                                        cloudServices: {tokenUrl : process.env.REACT_APP_COBRAND_API + "/editor_token/" + user.public_id, uploadUrl : "https://55482.cke-cs.com/easyimage/upload/"}
                                    }}
                                    onError={ ( { willEditorRestart } ) => {
                                        // If the editor is restarted, the toolbar element will be created once again.
                                        // The `onReady` callback will be called again and the new toolbar will be added.
                                        // This is why you need to remove the older toolbar.
                                        if ( willEditorRestart ) {
                                            this.editor.ui.view.toolbar.element.remove();
                                        }
                                    } }
                                />
                                {MediaSwitch(fProps, "exerciseForm.mediaCheck", "preparation.media")}
                                {MediaSwitch(fProps, "exerciseForm.videoCheck", "preparation.video")}
                            </MultiLineGroup>
                        </FormGroup>
                        <FormGroup>
                        <Label><MultimediaIcon />{t("exerciseForm.multimedia").charAt(0).toUpperCase() + t("exerciseForm.multimedia").slice(1)}</Label>
                        <hr />
                        <MultiLineGroup>
                            <FormGroup>
                                <Label>{t("exerciseForm.mediaComplete")}</Label>
                                {MediaSwitch(fProps, "exerciseForm.mediaCompleteCheck", "mediaComplete")}
                            </FormGroup>
                            <FormGroup>
                                <Label>{t("exerciseForm.audioComplete")}</Label>
                                {MediaSwitch(fProps, "exerciseForm.audioCompleteCheck", "audioComplete")}
                            </FormGroup>
                            <FormGroup>
                                <Label>{t("exerciseForm.videoComplete")}</Label>
                                {MediaSwitch(fProps, "exerciseForm.videoCompleteCheck", "videoComplete")}
                            </FormGroup>
                        </ MultiLineGroup>
                        </FormGroup>
                    </>
                )}

                {/* UITVOER UNIQUE MIDDLE FORM SECTION */}
                {props.exerciseType === "stappenplan" && (
                    <FormGroup>
                        <Label htmlFor="steps"><StepsIcon />{t("exerciseForm.steps")}</Label>
                        <hr />
                        <MultiLineGroup>
                        <FieldArray
                            id="steps"
                            name="steps"
                            render={(arrayHelpers) => (
                                <div>
                                    {fProps.values.steps?.map((step, index) => (
                                        <FileCard key={"step" + index}>
                                            <Label>{step.text}</Label>
                                            <div>
                                                <Pencil onClick={() => handleShowOverlay(fProps.values, index)} />
                                                <Trash onClick={() => arrayHelpers.remove(index)} />
                                            </div>
                                        </FileCard>
                                    ))}
                                    <SmallButton onClick={() => handleShowOverlay(fProps.values)} type="button">
                                        {t("exerciseForm.addNewStep")}
                                    </SmallButton>
                                    {showError(fProps.touched, fProps.errors, "steps", false)}
                                </div>
                            )}
                        />
                        </MultiLineGroup>
                    </FormGroup>
                )}

                {/* INVUL + VRAGENLIJST CROSSOVER MIDDLE FORM SECTION */}
                {(props.exerciseType === "stappenplanValidatie" || props.exerciseType === "dagboek") && (
                    <FormGroup>
                        <Label htmlFor="questionTypes">
                            <InfoIcon />{t(props.exerciseType === "stappenplanValidatie" ? "exerciseForm.steps" : "exerciseForm.exerciseTypes")}
                        </Label>
                        <hr />
                        <MultiLineGroup>
                        <FieldArray
                            id="questionTypes"
                            name="questionTypes"
                            render={(arrayHelpers) => (
                                <div>
                                    {fProps.values.questionTypes?.map((questionType, index) => (
                                        <FileCard key={"questionType" + index}>
                                            <p>{questionType.name}</p>
                                            <div>
                                                <Pencil onClick={() => handleShowOverlay(fProps.values, index)} />
                                                <Trash onClick={() => arrayHelpers.remove(index)} />
                                            </div>
                                        </FileCard>
                                    ))}
                                    {showError(fProps.touched, fProps.errors, "questionTypes", false)}
                                    <SmallButton onClick={() => handleShowOverlay(fProps.values)} type="button">
                                        {t(props.exerciseType === "stappenplanValidatie" ? "exerciseForm.addNewStep" : "exerciseForm.addNewExerciseType")}
                                    </SmallButton>
                                </div>
                            )}
                        />
                        </MultiLineGroup>
                    </FormGroup>
                )}

                {/* GENERAL MIDDLE FORM SECTION */}
                {showOverlay && (
                    <Overlay>
                        <OverlayCard>
                            <Times onClick={handleHideOverlay} />
                            <Title>
                                {props.exerciseType === "dagboek" ? t("exerciseForm.editExerciseType") : t("exerciseForm.editStep")}
                            </Title>
                            {props.exerciseType === "stappenplan" ? (
                                <>
                                    <FormGroup>
                                        <Label htmlFor={`steps[${stepId}].text`}>{t("exerciseForm.addText")}</Label>
                                        <Input
                                            component="textarea"
                                            id={`steps[${stepId}].text`}
                                            name={`steps[${stepId}].text`}
                                            placeholder={t("exerciseForm.stepTextInstructions")}
                                        />
                                        {showError(fProps.touched, fProps.errors, `steps[${stepId}].text`, false)}
                                    </FormGroup>
                                    {MediaSwitch(fProps, "exerciseForm.mediaCheck", `steps[${stepId}].media`)}
                                    {MediaSwitch(fProps, "exerciseForm.audioCheck", `steps[${stepId}].audio`)}
                                    {MediaSwitch(fProps, "exerciseForm.videoCheck", `steps[${stepId}].video`)}
                                    <SmallButton type="button" onClick={handleHideOverlay}>
                                        {t("exerciseForm.addStep")}
                                    </SmallButton>
                                </>
                            ) : (
                                    <>
                                        <FormGroup>
                                            <Label htmlFor={`questionTypes[${questionTypeId}].name`}>
                                                {t(props.exerciseType === "stappenplanValidatie" ? "exerciseForm.addText" : "exerciseForm.addExerciseTypeName")}
                                            </Label>
                                            <Input
                                                type="text"
                                                id={`questionTypes[${questionTypeId}].name`}
                                                name={`questionTypes[${questionTypeId}].name`}
                                                value={getIn(fProps.values, `questionTypes[${questionTypeId}].name`) || ""}
                                                placeholder={
                                                    props.exerciseType === "dagboek"
                                                        ? t("exerciseForm.exerciseTypeNameInstructions")
                                                        : t("exerciseForm.stepTextInstructions")
                                                }
                                            />
                                            {showError(fProps.touched, fProps.errors, `questionTypes[${questionTypeId}].name`, false)}
                                        </FormGroup>
                                        <Label htmlFor={`questionTypes[${questionTypeId}].questions`}>
                                            {t("exerciseForm.questions." + props.exerciseType)}
                                        </Label>
                                        <FieldArray
                                            id={`questionTypes[${questionTypeId}].questions`}
                                            name={`questionTypes[${questionTypeId}].questions`}
                                            render={(arrayHelpers) => (
                                                <div>
                                                    {fProps.values.questionTypes?.[questionTypeId]?.questions?.map((question, index) => (
                                                        <FileCard key={"question" + index}>
                                                            <p>{question.question}</p>
                                                            <div>
                                                                <Pencil onClick={() => handleShowSecondOverlay(fProps.values, index)} />
                                                                <Trash onClick={() => arrayHelpers.remove(index)} />
                                                            </div>
                                                        </FileCard>
                                                    ))}
                                                    {showError(fProps.touched, fProps.errors, `questionTypes[${questionTypeId}].questions`, false)}
                                                    <SmallButton type="button" onClick={() => handleShowSecondOverlay(fProps.values)}>
                                                        {t("exerciseForm.addExercise." + props.exerciseType)}
                                                    </SmallButton>
                                                </div>
                                            )}
                                        />
                                        <SmallButton type="button" onClick={handleHideOverlay}>
                                            {t(
                                                props.exerciseType === "stappenplanValidatie" ? "exerciseForm.addStep" : "exerciseForm.addExerciseType"
                                            )}
                                        </SmallButton>
                                    </>
                                )}
                        </OverlayCard>
                    </Overlay>
                )}

                {/*QUESTION CREATION OVERLAY SUBFORM SECTION*/}
                {showSecondOverlay && (
                    <Overlay>
                        <OverlayCard>
                            <Times onClick={handleHideSecondOverlay} />
                            {props.exerciseType === "stappenplanValidatie" ? (
                                <FormGroup>
                                    <Label htmlFor={`${getQuestionId()}.question`}>{t("exerciseForm.askQuestion")}</Label>
                                    <hr />
                                    <MultiLineGroup>
                                        <Input
                                            component="textarea"
                                            id={`${getQuestionId()}.question`}
                                            name={`${getQuestionId()}.question`}
                                            placeholder={t("exerciseForm.questionInstructions")}
                                            onKeyDown={(e) => autoFocus(e.keyCode, `${getQuestionId()}.cue.text`)}
                                        />
                                        {showError(fProps.touched, fProps.errors, `${getQuestionId()}.question`, false)}
                                        {MediaSwitch(fProps, "exerciseForm.mediaCheck", `${getQuestionId()}.media`)}
                                        {MediaSwitch(fProps, "exerciseForm.audioCheck", `${getQuestionId()}.audio`)}
                                        {MediaSwitch(fProps, "exerciseForm.videoCheck", `${getQuestionId()}.video`)}
                                    </MultiLineGroup>
                                    <MultiLineGroup>
                                        <Input
                                            aria-label="askCue"
                                            component="textarea"
                                            name={`${getQuestionId()}.cue.text`}
                                            placeholder={t("exerciseForm.cueInstructions")}
                                            onKeyDown={(e) => autoFocus(e.keyCode, `${getQuestionId()}.typeOfAnswer`)}
                                        />
                                        {showError(fProps.touched, fProps.errors, `${getQuestionId()}.cue.text`)}
                                        {MediaSwitch(fProps, "exerciseForm.mediaCheck", `${getQuestionId()}.cue.media`)}
                                        {MediaSwitch(fProps, "exerciseForm.audioCheck", `${getQuestionId()}.cue.audio`)}
                                        {MediaSwitch(fProps, "exerciseForm.videoCheck", `${getQuestionId()}.cue.video`)}
                                    </MultiLineGroup>
                                    <br />
                                    <Label htmlFor={`${getQuestionId()}.typeOfAnswer`}>{t("exerciseForm.answerType")}</Label>
                                    <hr />
                                    <MultiLineGroup>
                                    <Input
                                        component="select"
                                        id={`${getQuestionId()}.typeOfAnswer`}
                                        name={`${getQuestionId()}.typeOfAnswer`}
                                        onKeyDown={(e) => autoFocus(e.keyCode, `${getQuestionId()}.correctAnswer`)}
                                    >
                                        {answerTypes.map((answerType) => {
                                            return answerType;
                                        })}
                                    </Input>
                                    {showError(fProps.touched, fProps.errors, `${getQuestionId()}.typeOfAnswer`, false)}

                                    {getIn(fProps.values, `${getQuestionId()}.typeOfAnswer`) !== "Textbox" && (
                                        <>
                                            <Label htmlFor={`${getQuestionId()}.answerPossibilities`}>
                                                {t("exerciseForm.possibleAnswers")}
                                            </Label>
                                            <FieldArray
                                                id={`${getQuestionId()}.answerPossibilities`}
                                                name={`${getQuestionId()}.answerPossibilities`}
                                                render={(arrayHelpers) => (
                                                    <div>
                                                        {getIn(fProps.values, `${getQuestionId()}.answerPossibilities`)?.map((answP, index) => (
                                                            <FileCard key={"answerPossibility" + index}>
                                                                <Label htmlFor={`${getQuestionId()}.answerPossibilities[${index}]`}>
                                                                    {t("exerciseForm.answerPossibility")}
                                                                </Label>
                                                                <Input
                                                                    type="text"
                                                                    id={`${getQuestionId()}.answerPossibilities[${index}]`}
                                                                    name={`${getQuestionId()}.answerPossibilities[${index}]`}
                                                                    placeholder={answP}
                                                                />
                                                                <div>
                                                                    <Trash onClick={() => arrayHelpers.remove(index)} />
                                                                </div>
                                                            </FileCard>
                                                        )
                                                        )}
                                                        {showError(fProps.touched, fProps.errors, `${getQuestionId()}.answerPossibilities`, false)}
                                                        <SmallButton type="button" onClick={() => arrayHelpers.push("")}>
                                                            {t("exerciseForm.addPossibleAnswer")}
                                                        </SmallButton>
                                                    </div>
                                                )}
                                            />
                                        </>
                                    )}
                                    </MultiLineGroup>
                                    <br />
                                    <Label htmlFor={`${getQuestionId()}.correctAnswer`}>{t("exerciseForm.askAnswer")}</Label>
                                    <hr />
                                    <MultiLineGroup>
                                    <Input
                                        id={`${getQuestionId()}.correctAnswer`}
                                        type="text"
                                        name={`${getQuestionId()}.correctAnswer`}
                                        value={getIn(fProps.values, `${getQuestionId()}.correctAnswer`) || ""}
                                        placeholder={t("exerciseForm.answerInstructions")}
                                    />
                                    {showError(fProps.touched, fProps.errors, `${getQuestionId()}.correctAnswer`, false)}

                                    <Switch name={`${getQuestionId()}.isCapitalSensitive`} label={t("exerciseForm.isCapitalSensitive")} />
                                    {showError(fProps.touched, fProps.errors, `${getQuestionId()}.isCapitalSensitive`)}

                                    <Switch name={`${getQuestionId()}.isPunctuationSensitive`} label={t("exerciseForm.isPunctuationSensitive")} />
                                    {showError(fProps.touched, fProps.errors, `${getQuestionId()}.isPunctuationSensitive`)}
                                    </MultiLineGroup>
                                </FormGroup>
                            ) : (
                                    <>
                                        <FormGroup>
                                            <Label htmlFor={`${getQuestionId()}.question`}>{t("exerciseForm.askQuestion")}</Label>
                                            <Input
                                                type="text"
                                                id={`${getQuestionId()}.question`}
                                                name={`${getQuestionId()}.question`}
                                                value={getIn(fProps.values, `${getQuestionId()}.question`) || ""}
                                                placeholder={t("exerciseForm.questionInstructions")}
                                            />
                                            {showError(fProps.touched, fProps.errors, `${getQuestionId()}.question`, false)}
                                        </FormGroup>
                                        <FormGroup>
                                            <Label htmlFor={`${getQuestionId()}.answerpossibility`}>{t("exerciseForm.answerType")}</Label>
                                            <Input
                                                component="select"
                                                id={`${getQuestionId()}.answerpossibility`}
                                                name={`${getQuestionId()}.answerpossibility`}
                                            >
                                                {qaTypes.map((qaType) => {
                                                    return qaType;
                                                })}
                                            </Input>
                                            {showError(fProps.touched, fProps.errors, `${getQuestionId()}.answerPossibility`, false)}
                                        </FormGroup>
                                        {getIn(fProps.values, `${getQuestionId()}.answerpossibility`) !== "open antwoord" && (
                                            <>
                                                <FormGroup>
                                                    <FieldArray
                                                        name={`${getQuestionId()}.fieldnames`}
                                                        render={(arrayHelpers) => (
                                                            <div>
                                                                {getIn(fProps.values, `${getQuestionId()}.fieldnames`)?.map((fieldname, index) => (
                                                                    <FileCard key={"fieldName" + index}>
                                                                        <Label htmlFor={`${getQuestionId()}.fieldnames[${index}]`}>
                                                                            {t("exerciseForm.fieldName")}
                                                                        </Label>
                                                                        <Input
                                                                            type="text"
                                                                            id={`${getQuestionId()}.fieldnames[${index}]`}
                                                                            name={`${getQuestionId()}.fieldnames[${index}]`}
                                                                            value={getIn(fProps.values, `${getQuestionId()}.fieldnames[${index}]`) || ""}
                                                                            placeholder={fieldname}
                                                                        />
                                                                        <div>
                                                                            <Trash onClick={() => arrayHelpers.remove(index)} />
                                                                        </div>
                                                                    </FileCard>
                                                                ))}
                                                                {showError(fProps.touched, fProps.errors, `${getQuestionId()}.fieldnames`, false)}
                                                                <SmallButton type="button" onClick={() => arrayHelpers.push("")}>
                                                                    {t("exerciseForm.addFieldName")}
                                                                </SmallButton>
                                                            </div>
                                                        )}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label htmlFor={`${getQuestionId()}.fieldvalues`}>{t("exerciseForm.fieldvalues")}</Label>
                                                    <FieldArray
                                                        id={`${getQuestionId()}.fieldvalues`}
                                                        name={`${getQuestionId()}.fieldvalues`}
                                                        render={(arrayHelpers) => (
                                                            <div>
                                                                {getIn(fProps.values, `${getQuestionId()}.fieldvalues`)?.map((fieldvalue, index) => (
                                                                    <FileCard key={"fieldValue" + index}>
                                                                        <Label htmlFor={`${getQuestionId()}.fieldvalues[${index}]`}>
                                                                            {t("exerciseForm.fieldValue")}
                                                                        </Label>
                                                                        <Input
                                                                            type="number"
                                                                            id={`${getQuestionId()}.fieldvalues[${index}]`}
                                                                            name={`${getQuestionId()}.fieldvalues[${index}]`}
                                                                            placeholder={fieldvalue}
                                                                        />
                                                                        <div>
                                                                            <Trash onClick={() => arrayHelpers.remove(index)} />
                                                                        </div>
                                                                    </FileCard>
                                                                ))}
                                                                {showError(fProps.touched, fProps.errors, `${getQuestionId()}.fieldvalues`, false)}
                                                                <SmallButton type="button" onClick={() => arrayHelpers.push("")}>
                                                                    {t("exerciseForm.addFieldValue")}
                                                                </SmallButton>
                                                            </div>
                                                        )}
                                                    />
                                                </FormGroup>
                                            </>
                                        )}
                                    </>
                                )}
                            <SmallButton type="button" onClick={handleHideSecondOverlay}>{t("exerciseForm.addQuestion." + props.exerciseType)}</SmallButton>
                        </OverlayCard>
                    </Overlay>
                )}

                {/* GENERAL FOOTER FORM SECTION */}
                <FormGroup>
                    <Label><CogIcon />{t("exerciseForm.options")}</Label>
                    <hr />
                    <MultiLineGroup>
                        {(props.exerciseType === "dagboek" || props.exerciseType === "stappenplanValidatie") && (
                            <>
                                {props.exerciseType === "stappenplanValidatie" && (
                                    <>
                                        <Label htmlFor="options.rewardSystem">{t("exerciseForm.rewardSystem")}</Label>
                                        <Input id="options.rewardSystem" component="select" name="options.rewardSystem">
                                            {rewardSystems.map((rewardSystem) => {
                                                return rewardSystem;
                                            })}
                                        </Input>
                                        {showError(fProps.touched, fProps.errors, `options.rewardSystem`)}
                                    </>
                                )}
                                <Switch name="options.randomWithinType" label={t("exerciseForm.randomWithinType")} />
                                <Switch name="options.randomAll" label={t("exerciseForm.randomAll")} />
                            </>
                        )}
                        <Switch name="options.shouldBeRecorded" label={t("exerciseForm.shouldBeRecorded")} />
                        <Switch name="options.shouldBeMetronome" label={t("exerciseForm.shouldBeMetronome")} />
                        <Switch name="options.shouldBeChrono" label={t("exerciseForm.shouldBeChrono")} />
                        <TagSelect setTags={setTags} />
                    </MultiLineGroup>
                </FormGroup>
                <FormGroup>
                    <Label><BullHornIcon />{t("exerciseForm.private")}</Label>
                    <hr />
                    <MultiLineGroup>
                        <Switch name="private.shouldBePrivate" label={t("exerciseForm.privateCheck")} />
                    </MultiLineGroup>
                </FormGroup>

                {showMediaOverlay &&
                    <MediaOverlay setSelection={SetMediaInput} close={() => setShowMediaOverlay(!showMediaOverlay)} />
                }

                <Button type="submit" disabled={fProps.isValid ? null : "disabled"}>
                    {t("OefeningAanvragen.submit")}
                </Button>

                {/* To quickly debug the form uncomment the line underneath */}
                {/* <DisplayFormikState { ...fProps } /> */}
            </ExcerciseForm>
        );
    };

    return (
        <Formik
            innerRef={formikRef}
            initialValues={initialValues}
            validationSchema={formSchema}
            validateOnMount={true}
            onSubmit={handleSubmit}
            children={formLayout}
        />
    );
});

export default ExerciseForm;
