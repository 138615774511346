import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/react';

const isProduction = process.env.NODE_ENV === 'production';

if (isProduction){
    Sentry.init({dsn: "https://19e310354a754945b7db7984ef39c042@o382844.ingest.sentry.io/5320361"});
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
