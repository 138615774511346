import styled from 'styled-components';
import {color, compose, space, typography, flexbox} from 'styled-system';

const Text = styled.div`
    ${compose(
        space,
        color,
        typography,
        flexbox
    )};
`;

Text.defaultProps = {
    color: 'textColor',
};

export default Text;
