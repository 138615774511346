import React, { useContext, useEffect, useState } from "react";
import Notification from "./notification";
import { PageTitle } from "./styled/typography";
import registrationStore from "./registrationStore";
import notificationStore from "./notificationStore";
import { observer } from "mobx-react-lite";
import { api } from "../api";
import { useTranslation } from "react-i18next";

const Inbox = observer(() => {
    const { t, i18n } = useTranslation();
    const [messages, setMessages] = useState([]);
    const store = useContext(registrationStore);
    const user = JSON.parse(store.user);
    const notifications = useContext(notificationStore);

    useEffect(() => {
        api.get("/user/" + user.public_id + "/inbox").then((response) => {
            setMessages(response.data.inbox);
        });
    }, []);

    const update = () => {
        api.get("/user/" + user.public_id + "/inbox").then((response) => {
            setMessages(response.data.inbox);
            notifications.setNotifications(user.public_id);
        });
    };
    return (
        <div>
            <PageTitle black>{t("accountMenuDropdown.inbox")}</PageTitle>
            {messages.length > 0 ? (
                messages.map((message) => {
                    return <Notification message={message} update={update} />;
                })
            ) : (
                <p>{t("inbox.leeg")}</p>
            )}
        </div>
    );
});

export default Inbox;
