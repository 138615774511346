import React, { Component } from "react";
import styled from 'styled-components';
import AudioAnalyser from "react-audio-analyser";

const Button = styled.button`
  /* Adapt the colors based on primary prop */
  background: ${props => props.primary ? "palevioletred" : "white"};
  color: black};

  font-size: 1em;
  margin: 1em;
  padding: 0.25em 1em;
  border: 2px solid palevioletred;
  border-radius: 3px;
  z-index: 100;
  &:hover {
        border-color: ${props => props.theme.colors.primary};
        cursor: pointer;
    }
`;

export default class AudioRecorder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: ""
    };
  }

  controlAudio(status) {
    this.setState({
      status
    });
  }

  recordButtonStart(){
    document.getElementById('recorderStart').style.backgroundColor = "violet";
  }

  recordButtonPause(){
    document.getElementById('recorderStart').style.backgroundColor = "white";
  }

  recordButtonStop(){
    document.getElementById('recorderStart').style.backgroundColor = "white";
  }

  changeScheme(e) {
    this.setState({
      audioType: e.target.value
    });
  }

  componentDidMount() {
    this.setState({
      audioType: "audio/wav"
    });
  }

  render() {
    const { status, audioSrc, audioType } = this.state;
    const audioProps = {
      audioType,
      width: 0,
      height: 0,
      // audioOptions: {sampleRate: 30000}, // 设置输出音频采样率
      status,
      audioSrc,
      timeslice: 1000, // timeslice（https://developer.mozilla.org/en-US/docs/Web/API/MediaRecorder/start#Parameters）
      startCallback: e => {
        console.log("succ start", e);
      },
      pauseCallback: e => {
        console.log("succ pause", e);
      },
      stopCallback: e => {
        this.setState({
          audioSrc: window.URL.createObjectURL(e)
        });
        console.log("succ stop", e);
      },
      onRecordCallback: e => {
        console.log("recording", e);
      },
      errorCallback: err => {
        console.log("error", err);
      }
    };
    return (
      <div>
        <AudioAnalyser {...audioProps}>
          <div className="btn-box">
            <Button
                className="btn"
                id="recorderStart"
                onClick={() => {this.controlAudio("recording"); this.recordButtonStart()}}
            >
              Record
            </Button>
            <Button 
                className="btn"
                id="recorderPause"
                onClick={() => {this.controlAudio("paused"); this.recordButtonPause()}}
            >
              Pause
            </Button>
            <Button
                className="btn"
                id="recorderStop"
                onClick={() => {this.controlAudio("inactive"); this.recordButtonPause()}}
            >
              Stop
            </Button>
            <Button hidden="true" className="btn" onClick={() => console.log(AudioAnalyser)}>
              Log
            </Button>
          </div>
        </AudioAnalyser>
        <p>Choose audio output format</p>
        <select
          name=""
          id=""
          onChange={e => this.changeScheme(e)}
          value={audioType}
        >
          <option value="audio/webm">audio/webm（default）</option>
          <option value="audio/wav">audio/wav</option>
          <option value="audio/mp3">audio/mp3</option>
        </select>
      </div>
    );
  }
}
