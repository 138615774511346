import React from "react";
import styled from "styled-components";

import { P } from "../components/styled/typography";

const Progress = styled.div`
    margin-bottom: 10px;
`;

const TaskTitle = styled(P)`
    color: #545454;
    margin: 0px;
    margin-bottom: 5px;
`;

const Box = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const ProgressBarBox = styled.div`
    border: 0.5px solid #cfcfcf;
    border-radius: 30px;
    width: 90%;
    height: 20px;
`;

const Bar = styled.div`
    width: ${(props) => props.width}%;
    height: 20px;
    background-color: #51c0cf;
    border-radius: 30px;
`;

const Percentage = styled(P)`
    font-weight: 700;
    color: #51c0cf;
    margin: 0px;
    margin-left: 5px;
`;

function ProgressBar(props) {
    const progress = Math.round(props.progress * 100);
    return (
        <Progress>
            <TaskTitle>{props.title}</TaskTitle>
            <Box>
                <ProgressBarBox>
                    <Bar width={progress} />
                </ProgressBarBox>
                <Percentage>{progress}%</Percentage>
            </Box>
        </Progress>
    );
}

export default ProgressBar;
