import React, {useContext} from "react"
import {api} from "../../../api"
import { saveAs } from 'file-saver';

const UsersDownload = () => {

    const getExcelSheet = () => {
        api.get('/users/info', {responseType: 'blob'}).then(res => {
            saveAs(res.data, 'marketing_info_users.xlsx')
        })
    }
    return (
        <div>
            <button onClick={() => getExcelSheet()}>Download</button>
        </div>
    )
}

export default UsersDownload