import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { H4, P } from "../../components/styled/typography";
import RegistrationStore from "../../components/registrationStore";

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const Question = styled(H4)`
    font-weight: 500;
    margin: 0px;
    margin-bottom: 15px;
`;

const FullName = styled.div`
    display: flex;
    flex-direction: column;

    @media (min-width: 768px) {
        flex-direction: row;
        justify-content: space-between;
    }
`;

const Input = styled.input`
    background: #ffffff;
    border: 1px solid #51c0cf;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(197, 197, 197, 0.25);
    border-radius: 10px;
    padding: 10px 10px;
    margin-top: 5px;
    outline: none;
    font-size: 16px;
    margin-bottom: 10px;
    width: 100%;

    &.valid {
        border-color: #45ae7c;
    }

    &.invalid {
        border-color: #f02929;
    }

    @media (min-width: 768px) {
        width: 49.5%;
        margin-bottom: 15px;
    }
`;

const TextError = styled(P)`
    margin: 0px;
    margin-bottom: 15px;
    color: #f02929;
`;

const UserName = observer(({ setRegisterStepIsValid, setRegisterStepIsInvalid, nextStep }) => {
    const { t, i18n } = useTranslation();
    const registrationStore = useContext(RegistrationStore);
    const [firstNameIsValid, setFirstNameIsValid] = useState(false);
    const [lastNameIsValid, setLastNameIsValid] = useState(false);
    const [firstNameError, setFirstNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);

    const validateForm = () => {
        if (firstNameIsValid && lastNameIsValid) {
            setRegisterStepIsValid();
        } else {
            setRegisterStepIsInvalid();
        }
    };

    useEffect(() => {
        validateForm();
    }, [firstNameIsValid, lastNameIsValid]);

    useEffect(() => {
        const firstNameField = document.getElementById("first-name");
        const lastNameField = document.getElementById("last-name");
        if (registrationStore.firstName != "" && registrationStore.lastName != "") {
            setFirstNameIsValid(true);
            setLastNameIsValid(true);
            firstNameField.classList.add("valid");
            lastNameField.classList.add("valid");
        }
        validateForm();
    });

    const handleFirstNameChange = (e) => {
        const firstNameField = document.getElementById("first-name");
        registrationStore.setFirstName(e.target.value);
        if (e.target.value.length !== 0) {
            firstNameField.classList.remove("invalid");
            firstNameField.classList.add("valid");
            setFirstNameIsValid(true);
            setFirstNameError(false);
        } else {
            firstNameField.classList.remove("valid");
            firstNameField.classList.add("invalid");
            setFirstNameIsValid(false);
            setFirstNameError(true);
        }
    };

    const handleLastNameChange = (e) => {
        const lastNameField = document.getElementById("last-name");
        registrationStore.setLastName(e.target.value);
        if (e.target.value.length !== 0) {
            lastNameField.classList.remove("invalid");
            lastNameField.classList.add("valid");
            setLastNameIsValid(true);
            setLastNameError(false);
        } else {
            lastNameField.classList.remove("valid");
            lastNameField.classList.add("invalid");
            setLastNameIsValid(false);
            setLastNameError(true);
        }
    };

    return (
        <Wrapper>
            <Question black>{t("userName.title")}</Question>
            <FullName>
                <Input
                    id="first-name"
                    type="text"
                    value={registrationStore.firstName}
                    onChange={(e) => handleFirstNameChange(e)}
                    onKeyPress={(e) => nextStep(e)}
                    placeholder={t("userName.firstNamePlaceholder")}
                    autoFocus
                    required
                    data-testid="first-name"
                />
                <Input
                    id="last-name"
                    type="text"
                    value={registrationStore.lastName}
                    onChange={(e) => handleLastNameChange(e)}
                    onKeyPress={(e) => nextStep(e)}
                    placeholder={t("userName.lastNamePlaceholder")}
                    required
                    data-testid="last-name"
                />
            </FullName>
            {firstNameError && <TextError data-testid="first-name-error">{t("userName.firstNameError")}</TextError>}
            {lastNameError && <TextError data-testid="last-name-error">{t("userName.lastNameError")}</TextError>}
        </Wrapper>
    );
});

export default UserName;
