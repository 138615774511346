import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import PatientOverlay from "../../components/overlays/patientOverlay";
import Global from "../../components/styled/global";
import { PageTitle, H4, SmallTitle, P } from "../../components/styled/typography";
import PageWrapper from "../../components/styled/pageWrapper";
import { observer } from "mobx-react";
import { api } from "../../api";
import LoadSpinner from "../../components/loadspinner";
import { PageAnimation } from "../../components/styled/animation";
import registrationStore from "../../components/registrationStore";
import { useTranslation } from "react-i18next";
import { Formik, Form, Field } from "formik";
import Button from "../../components/styled/button";
import { FaFilter } from "react-icons/fa";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import Material from "../../components/libraryComponents/material";

const Flex = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @media (min-width: 980px) {
        flex-direction: row;
    }
`;
const SubmitButton = styled(Button)`
    margin-top: 20px;
    padding-right: 45px;
    padding-left: 45px;
    font-size: 18px;
    margin-left: 10px;
`;
const Icon = styled(FaFilter)`
    color: white;
    vertical-align: middle;
    margin-right: 7px;
`;

const Input = styled(Field)`
    background: #ffffff;
    border: 1px solid #51c0cf;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(197, 197, 197, 0.25);
    border-radius: 10px;
    padding: 10px 10px;
    margin-top: 5px;
    outline: none;
    font-size: 16px;
`;
const DocFlex = styled(Flex)`
    margin-bottom: 20px;
    justify-content: flex-start;
`;

const IconArrowRight = styled(FaArrowRight)`
    color: #51c0cf;
    margin: 0px 5px;
    cursor: pointer;
`;

const IconArrowLeft = styled(FaArrowLeft)`
    color: #51c0cf;
    margin: 0px 5px;
    cursor: pointer;
`;

const Pagination = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px 5px;
`;

const PageNumber = styled(P)`
    margin: 0px 7px;
    display: inlne;
    cursor: pointer;
    outline: none;

    &.active {
        color: white;
        background-color: #51c0cf;
        border: 1px solid #51c0cf;
        padding: 2px 5px 4px 5px;
        border-radius: 30px;
    }

    @media (min-width: 980%) {
        margin: 0px 3px;
    }
`;
const FilterButton = styled(SubmitButton)`
    margin-top: 0px;
`;

const PaginationCenter = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;

const MateriaalRaadplegen = observer(() => {
    const { t, i18n } = useTranslation();

    return (
        <Global>
            <PageWrapper>
                <PatientOverlay />
                <PageAnimation>
                    <SmallTitle black>{t("materiaalRaadplegen.allDocs")}</SmallTitle>
                    <Material public={true} onlyDocs={true} />
                </PageAnimation>
            </PageWrapper>
        </Global>
    );
});

export default MateriaalRaadplegen;
