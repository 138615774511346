import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import VerifyCode from "./verifyCode";
import RegistrationStore from "./registrationStore";
import { api } from "../api";
import { Redirect } from "react-router-dom";
import Flash from "./styled/flashMessage";
import { useTranslation } from "react-i18next";
import Button from "./styled/button";
import { P } from "./styled/typography";

const Flex = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 25px 0px 50px 0px;
`;

const Code = styled.div`
    margin: 10px 0px;
`;

const Resend = styled.span`
    color: #51c0cf;
    text-decoration: underline;
    cursor: pointer;
`;

const ResendText = styled(P)`
    margin-top: 35px;
`;

const Text = styled(P)`
    margin-top: 5px;
    margin-bottom: 0px;
`;

const Error = styled.p`
    font-size: 16px;
    color: #fff;
    font-style: italic;
    background-color: #dc393d;
    border: 1px solid #dc393d;
    border-radius: 10px;
    padding: 10px 15px;
`;

const TwoFA = ({ setRegisterStepIsValid, setRegisterStepIsInvalid, verified }) => {
    const { t, i18n } = useTranslation();
    const store = useContext(RegistrationStore);
    const [code, setCode] = useState();
    const [showResendMessage, setShowResendMessage] = useState(false);
    const [showFailMessage, setShowFailMessage] = useState(false);

    useEffect(() => {
        api.get("/2FA/email", { params: { email: store.email } });
    }, []);

    const ResendEmail = () => {
        api.get("/2FA/email", { params: { email: store.email } }).then((res) => {
            setShowResendMessage(true);
            setTimeout(function () {
                setShowResendMessage(false);
            }, 5000);
        });
    };

    const VerifyCodeInput = () => {
        api.put("/2FA/code/" + code, { type: "email" }).then((res) => {
            if (res.data.success) {
                store.addUserAttribute("verified", true);
                setRegisterStepIsValid();
            } else {
                setRegisterStepIsInvalid();
                setShowFailMessage(true);
            }
        });
    };

    return (
        <Flex>
            <Text>{t("2FAEmail.emailSent")}</Text>
            {showResendMessage && <Flash>{t("2FAEmail.emailResend")}</Flash>}
            {showFailMessage && <Error>{t("2FAEmail.invalidCode")}</Error>}
            <Flash center green>
                {t("2FAEmail.checkSpam")}
            </Flash>
            <Text>{t("2FAEmail.codeInput")}</Text>
            <Code>
                <VerifyCode code={code} setCode={setCode} verify={VerifyCodeInput} />
            </Code>
            <Button onClick={VerifyCodeInput}>{t("2FAEmail.verify")}</Button>
            <ResendText>
                {t("2FAEmail.noCodeP1")}
                <Resend onClick={ResendEmail}>{t("2FAEmail.noCodeP2")}</Resend>
                {t("2FAEmail.noCodeP3")}
            </ResendText>
        </Flex>
    );
};

export default TwoFA;
