import React, {useContext, useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import api from '../../api';
import Dagboek from '../../../components/exercises/dagboek';
import Stappenplan from '../../../components/exercises/stappenplan';
import StappenplanMetValidatie from '../../../components/exercises/stappenplanMetValidatie';
import libraryService from '../../services/libraryService';
import CommunicationStore from '../../stores/communication.store';
import LibraryStore from '../../stores/library.store';
import { getResourceUrl } from "../../../azure";

const Exercise = observer(({id, onEnd}) => {
    const [exercise, setExercise] = useState({});
    const [taskDescription, setTaskDescriptions] = useState("");
    const communicationStore = useContext(CommunicationStore);
    const libraryStore = useContext(LibraryStore);
    let startTime = Date.now();

    useEffect(() => {
        api.getTelephonApi().get('/exercise/' + id).then(response => {
            setExercise(response.data.output);
        })
    }, [id]);

    const sendStepChange = (number) => {
        libraryService.sendStepChange(number, communicationStore.activeCall.id);
    };

    const sendMiniStepChange = (number) => {
        libraryService.sendMiniStepChange(number, communicationStore.activeCall.id);
    };

    async function getResource(id) {
        return api.getTelephonApi().get("/file/" + id).then((response) => {
            return getResourceUrl(response.data.file) 
        })
    }

    if (exercise.type === "dagboek") {
        return <Dagboek exercise={exercise} id={exercise.id} taskDescription={taskDescription} startTime={startTime}
                        endExercise={onEnd}
                        showRecorder={false}
                        getRessource={getResource}/>
    } else if (exercise.type === "stappenplan") {
        return <Stappenplan exercise={exercise} id={exercise.id} taskDescription={taskDescription} startTime={startTime}
                            onStepChange={sendStepChange}
                            onMiniStepChange={sendMiniStepChange}
                            activeStep={libraryStore.activeStep}
                            activeMiniStep={libraryStore.activeMiniStep}
                            endExercise={onEnd}
                            showRecorder={false} 
                            getRessource={getResource} 
                            isCommunicationComponent={true}/>
    } else if (exercise.type === "stappenplanValidatie") {
        return <StappenplanMetValidatie exercise={exercise} id={exercise.id} taskDescription={taskDescription}
                                        endExercise={onEnd}
                                        showRecorder={false}
                                        onStepChange={sendStepChange}
                                        onMiniStepChange={sendMiniStepChange}
                                        activeStep={libraryStore.activeStep}
                                        activeMiniStep={libraryStore.activeMiniStep}
                                        startTime={startTime} getRessource={getResource} isCommunicationComponent={true}/>
    }

    return null;
});

export default Exercise;
