import React, {useContext, useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import styled from 'styled-components';
import api from '../../api';
import Card from '../../../components/styled/card';
import { useTranslation } from "react-i18next";
import FileSaver from "file-saver";
import { H2 } from '../../../components/styled/typography';
import Button from "../../../components/styled/button";
import { downloadOneFile, getResourceUrl } from "../../../azure";

const Img = styled.img`
    width: 80%;

    @media (min-width: 980px) {
        width: 40%;
    }
`;

const Video = styled.video`
    width: 80%;
    margin-bottom: 10px;
`;

const Audio = styled.audio`
    max-width: 80%;
    margin-top: 20px;
    margin-bottom: 20px;
`;

const Wrapper = styled(Card)`
    padding: 30px;
    width: 100%;
    margin-bottom: 10px;
    overflow: hidden;

    img {
        max-width: 100%;
    }

    white-space: normal;
    word-wrap: break-word;

    @media (min-width: 980px){
        width: 98%;
        overflow: initial;
    }
`

const Media = observer(({id}) => {
    const { t, i18n } = useTranslation();
    const [media, setMedia] = useState({});
    const [fileUrl, setFileUrl] = useState("");

    useEffect(() => {
        api.getTelephonApi().get('/file/' + id).then(response => {
            setMedia(response.data.file)
        })
    }, [id]);

    useEffect(() => {
        if (media.resource_name) {
            getResourceUrl(media.resource_name).then(url => setFileUrl(url))
        }
    }, [media]);

    if (!fileUrl) return null;

    if (media.type === "image") {
        return <Img src={fileUrl}/>
    } else if (media.type === "video") {
        return <Video controls controlsList="nodownload"><source src={fileUrl} type="video/mp4; base64"/></Video>
    } else if (media.type === "audio") {
        return <Audio controls><source src={fileUrl} type="audio/wav; base64"/></Audio>
    } else {
        return (
            <>
                <Wrapper>
                   <H2>{media.name}</H2>
                   <span>{t("fileForm.noPreview")}</span>
               </Wrapper>
                <Button onClick={() => downloadOneFile(media.resource_name)}>
                   {t("mediaDetail.download")}
               </Button>
            </>)
    }
});

export default Media;