import React from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"

import { FaSearch } from 'react-icons/fa'

const SearchWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: stretch;
    box-sizing: border-box;
    margin: 10px 0px;
    @media (min-width: 980px){
        margin: 0px;
    }
`

const SearchBox = styled.input`
    border: 1px solid #51C0CF;
    font-size: 18px;
    letter-spacing: 0.05em;
    border-top-radius: 7px;
    border-bottom-radius: 7px;
    padding: 10px;
    padding-left: 10px;
    outline: none;
    height: 25px;
    width: calc(100% - 50px);
    
    @media (min-width: 980px){
        width: auto;
    }

`

const Button = styled.button`
    border: 1px solid #51C0CF;
    background-color: #FFFFFF;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
    cursor: pointer;
    outline: none;
    
`

const Icon = styled(FaSearch)`
    color: #51C0CF;
    font-size: 16px;
    padding: 10px;
    padding-bottom: 11px;
    vertical-align: middle;
    
`

function SearchBar(props){
    const { t, i18n } = useTranslation()

    return(
        <SearchWrapper>
            <SearchBox name="title" aria-label={props.id} id={props.id} value={props.currentSearch} onChange={props.handleSearch} onKeyDown={props.onKeyDown} placeholder={t('search')} />
        </SearchWrapper>
    )
}

export default SearchBar