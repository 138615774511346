import React, { useContext } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { observer } from "mobx-react-lite";
import RegistrationStore from "../../components/registrationStore";
import { Li, Ul } from "../../components/styled/list";
import { H3, P } from "../../components/styled/typography";
import Button from "../../components/styled/button";
import PatientIcon from "../../assets/images/patient-icon.png";
import TherapistIcon from "../../assets/images/therapist-icon.png";
import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const PlansWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    @media (min-width: 980px) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 40px 0px;
    }
`;

const Plan = styled.div`
    border: 1px solid lightgrey;
    border-radius: 10px;
    width: 100%;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 1px;

    @media (min-width: 980px) {
        width: 48%;
        margin-bottom: 0px;
    }

    ${({ isSelected }) =>
        isSelected &&
        `
        border: 2px solid #51C0CF;
        padding: 0px;
    `}

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
`;

const PlanImage = styled.img`
    width: 100px;
    margin-top: 40px;
`;

const UserType = styled(H3)`
    font-weight: 400;
    margin-top: 20px;
    margin-bottom: 0px;
`;

const SmallListIcon = styled(Ul)`
    margin: 30px 0px 30px -30px;
`;

const SubText = styled(P)`
    margin: 0px auto;
    margin-bottom: 20px;
    @media (min-width: 980px) {
        margin: 0px auto;
    }
`;

const typeOfUser = observer(({ setRegisterStepIsValid }) => {
    const { t, i18n } = useTranslation();
    const registrationStore = useContext(RegistrationStore);

    const handeChangeUserType = (type) => {
        if (type === "Patient"){
            registrationStore.setIsTherapist(true);
        } else if (type === "Therapist"){
            registrationStore.setIsTherapist(false);
        }
        
    };

    setRegisterStepIsValid();

    return (
        <Wrapper>
            <SubText>{t("typeOfUser.subText")}</SubText>
            <PlansWrapper>
                <Plan
                    isSelected={registrationStore.isTherapist}
                    onClick={() => handeChangeUserType("Patient")}
                    data-testid="therapist-plan"
                >
                    <PlanImage src={TherapistIcon} alt="therapist-icon" />
                    <UserType>{t("typeOfUser.Therapist")}</UserType>
                    <Ul>
                        <Li small>{t("registerForm3.therapy")}</Li>
                        <Li small>
                            {t("registerForm3.ex")} &amp; <br /> {t("registerForm3.res")}
                        </Li>
                        <Li small>{t("registerForm3.doc")}</Li>
                        <Li small>{t("registerForm3.com")}</Li>
                    </Ul>
                </Plan>
                <Plan
                    isSelected={!registrationStore.isTherapist}
                    onClick={() => handeChangeUserType("Therapist")}
                    data-testid="patient-plan"
                >
                    <PlanImage src={PatientIcon} alt="patient-icon" />
                    <UserType>{t("typeOfUser.client")}</UserType>
                    <SmallListIcon>
                        <Li small>{t("registerForm3.ex")}</Li>
                        <Li small>{t("registerForm3.res")}</Li>
                        <Li small>{t("registerForm3.doc")}</Li>
                        <Li small>{t("registerForm3.com")}</Li>
                    </SmallListIcon>
                </Plan>
            </PlansWrapper>
        </Wrapper>
    );
});

export default typeOfUser;
