import React, { useContext, useState } from "react";
import styled from "styled-components";
import PatientOverlay from "../../components/overlays/patientOverlay";
import Global from "../../components/styled/global";
import GegevensForm from "../../components/gegevensForm";
import PasswordResetForm from "../../components/passwordResetForm";
import PageWrapper from "../../components/styled/pageWrapper";
import { PageTitle } from "../../components/styled/typography";
import { PageAnimation } from "../../components/styled/animation";
import registrationStore from "../../components/registrationStore";
import AccountNavigation from "../../components/accountNavigation";
import Flash from "../../components/styled/flashMessage";
import { useTranslation } from "react-i18next";

const Flex = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;

    @media (min-width: 980px) {
        flex-direction: row;
        justify-content: space-between;
    }
`;

const OneThird = styled.div`
    width: 100%;
    margin: 15px 0px;

    @media (min-width: 980px) {
        width: 62%;
    }
`;

const TwoThird = styled.div`
    width: 100%;
    margin: 15px auto;

    @media (min-width: 980px) {
        width: 33%;
    }
`;

function MijnAccount() {
    const { t, i18n } = useTranslation();
    const store = useContext(registrationStore);
    const user = JSON.parse(store.user);
    const [showFlash, setShowFlash] = useState(false);

    const flash = () => {
        setShowFlash(true);
    };
    return (
        <Global>
            <PageWrapper>
                <PatientOverlay />
                <PageAnimation>
                    <PageTitle black>{t("sidebarPatient.acc")}</PageTitle>
                    <AccountNavigation therapist={user.therapist} />
                    {showFlash && <Flash>{t("mijnAccount.dataConf")}</Flash>}
                    <Flex>
                        <OneThird>
                            <GegevensForm user={user} flash={flash} />
                        </OneThird>
                        <TwoThird>
                            <PasswordResetForm user={user} flash={flash} />
                        </TwoThird>
                    </Flex>
                </PageAnimation>
            </PageWrapper>
        </Global>
    );
}

export default MijnAccount;
