import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Media from "../libraryComponents/media";
import Card from "../styled/card";
import Button from "../styled/button";
import { useTranslation } from "react-i18next";
import { SmallTitle, P } from "../styled/typography";
import { FaTimes } from "react-icons/fa";
import FileForm from "../fileForm/fileForm";
import registrationStore from "../registrationStore";
import Filter from "../filterComponents/filter";
import LanguageStore from "../stores/languageStore";

const Overlay = styled.div`
  background-color: rgba(60, 60, 60, 0.7);
  position: fixed;
  top: 0px;
  left: 0;
  width: 100vw;
  height: 100%;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 980px) {
    top: 0;
    height: 100vh;
    width: 100vw;
  }
`;

const OverlayCard = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-height: 75%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
  padding: 30px;
  position: absolute;
  z-index: 999999;

  @media (min-width: 980px) {
    position: relative;
    top: 0;
    z-index: 9;
  }
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  display: inline-block;
  position: relative;
  color: #545454;
  margin-bottom: 0px;
`;

const SelectMedia = styled(SmallTitle)`
  margin: 30px 0px 10px 0px;
`;

const Times = styled(FaTimes)`
  color: #545454;
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
`;

const Flex = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 980px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const FullWidth = styled.div`
    width: 100%;
`;

const LinkButton = styled(Button)`
    margin-bottom: 10px;
`;

function MediaSelectionOverlay(props) {
    const { t, i18n } = useTranslation();
    const [refresh, setRefresh] = useState(false);
    const [selection, setSelection] = useState([]);
    const [showFileForm, setShowFileForm] = useState(false);
    const store = useContext(registrationStore);
    const user = JSON.parse(store.user);
    const langStore = useContext(LanguageStore);
    const [filterData, setFilterData] = useState({
        title: "",
        language: langStore.getLanguage(i18n.language),
        tags: [],
    });

    const handleFileFormClose = () => {
        setRefresh(!refresh);
        setShowFileForm();
    };

    const handleClick = (id, title, type) => {
        setSelection({ id, title, type });
    };

    const HandleSubmit = () => {
        props.setSelection(selection);
        props.close();
    };

    const getFilterResults = (data) => {
      setFilterData(data);
    };

    return (<>
        <Overlay>
            <OverlayCard>
                <Times onClick={props.close} />
                <SelectMedia black>{t("mediaOverlay.selectMedia")}</SelectMedia>
                <Content>
                    <Filter useTags={true} hideDiscipline={true} filterCallback={(data) => getFilterResults(data)} />
                    <Flex>
                      <FullWidth>
                          <Title>
                              <h2>{t("materiaalRaadplegen.media")}</h2>
                          </Title>
                          <Media
                              small={true}
                              filterData={filterData}
                              public_id={user.public_id}
                              mediaSelect={true}
                              selectCallback={(event, id, title, type) => handleClick(event, id, title, type)}
                              refresh={refresh}
                              showDescription={true}
                          />
                      </FullWidth>
                    </Flex>
                    {/* {! props.skipUpload && 
                      <LinkButton onClick={() => setShowFileForm(true)}>{t("mediaOverlay.createNew")}</LinkButton> 
                    } */}
                    <Button onClick={HandleSubmit}>{t("mediaOverlay.addMedia")}</Button>
                </Content>
            </OverlayCard>
        </Overlay>

        { showFileForm &&
            <Overlay>
                <OverlayCard>
                    <Times onClick={handleFileFormClose} />
                    <FileForm onSubmit={handleFileFormClose} user={user} />
                </OverlayCard>
            </Overlay>
        }
    </>);
}

export default MediaSelectionOverlay;
