import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import Global from "../../components/styled/global";
import { PageTitle } from "../../components/styled/typography";
import PageWrapper from "../../components/styled/pageWrapper";
import Author from "../../components/author";
import Button from "../../components/styled/button";
import { observer } from "mobx-react";
import { api } from "../../api";
import LoadSpinner from "../../components/loadspinner";
import { PageAnimation } from "../../components/styled/animation";
import { H2} from '../../components/styled/typography';
import Card from '../../components/styled/card';
import { useTranslation } from "react-i18next";
import resultStore from "../../components/resultStore";
import qs from "qs";
import { P } from "../../components/styled/typography";
import PatientOverlay from "../../components/overlays/patientOverlay";
import { Redirect } from "react-router-dom";
import { getResourceUrl, downloadOneFile } from "../../azure";

const Wrapper = styled(Card)`
    padding: 30px;
    width: 100%;
    margin-bottom: 10px;
    overflow: hidden;

    img {
        max-width: 100%;
    }

    white-space: normal;
    word-wrap: break-word;

    @media (min-width: 980px){
        width: 98%;
        overflow: initial;
    }
`

const Flex = styled.div`
    display: flex;
    flex-direction: column;

    @media (min-width: 980px) {
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
    }
`;

const Img = styled.img`
    width: 80%;

    @media (min-width: 980px) {
        width: 40%;
    }
`;

const Video = styled.video`
    width: 80%;
    margin-bottom: 10px;
`;

const Audio = styled.audio`
    max-width: 80%;
    margin-top: 20px;
    margin-bottom: 20px;
`;

const Description = styled(P)`
    margin-top: 0px;
    color: #545454;
`;
const DoneButton = styled(Button)`
    margin-top: 20px;
`;
const ButtonDiv = styled.div``;

const MediaDetail = observer((props) => {
    const { t, i18n } = useTranslation();
    const [media, setMedia] = useState({});
    const { id } = props.match.params;
    const [loading, setLoading] = useState(true);
    const [mediaToShow, setMediaToShow] = useState('');
    const [isExercise, setIsExercise] = useState();
    const RStore = useContext(resultStore);
    const task_id = qs.parse(props.location.search, { ignoreQueryPrefix: true }).task_id;
    const [exercise, setExercise] = useState();
    const [redirect, setRedirect] = useState(false);
    const startTime = Date.now();

    useEffect(() => {
        api.get("/file/" + id).then(async (response) => {
            setMedia(response.data.file);
            getResourceUrl(response.data.file).then((file_url) => {
                setFile(file_url, response.data.file.type);
                if (task_id) {
                    api.get("/task/" + task_id).then((res) => {
                        setExercise(res.data.task);
                        setLoading(false);
                    });
                } else {
                    setLoading(false);
                }
            })
        });
        RStore.clearRecording();
    }, []);

    useEffect(() => {
        var d = qs.parse(props.location.search, { ignoreQueryPrefix: true }).exercise;
        setIsExercise(d);
    }, [props.location.search]);

    const setFile= (file_url, type) => {
        switch (type) {
            case "image":
                setMediaToShow(<Img src={file_url}/>);
                break;
            case "video":
                setMediaToShow(<Video controls controlsList="nodownload"><source src={file_url} type="video/mp4; base64"/></Video>);
                break;
            case "audio":
                setMediaToShow(<Audio controls><source src={file_url} type="audio/wav; base64"/></Audio>);
                break;
            default:
                setMediaToShow(null);
                break;
        }
    }

    const HandleSubmit = () => {
        setRedirect(true);
    };

    return (
        <Global>
            <PageWrapper>
                <PatientOverlay />
                <PageAnimation>
                    <PageTitle black>{t("sidebarPatient.material")}</PageTitle>
                    {loading ? (
                        <LoadSpinner />
                    ) : (<>
                        <Flex>
                            {isExercise && <Description>{exercise.description}</Description>}
                            <Wrapper>
                                <H2>{media.name}</H2>
                                {mediaToShow ?? <span>{t("fileForm.noPreview")}</span>}
                            </Wrapper>
                            <Author public_id={media.therapist_id} />
                        </Flex>
                        <ButtonDiv>
                            {isExercise && (
                                <Button onClick={HandleSubmit}>{t("document.readingready")}</Button>
                            )}
                            <Button onClick={() => downloadOneFile(media.resource_name)}>
                                {t("mediaDetail.download")}
                            </Button>
                        </ButtonDiv>
                        
                    </>)}
                    {redirect && (
                        <Redirect
                            to={{
                                pathname: "/p/Assignments/results",
                                state: {
                                    id: task_id,
                                    startTime: startTime,
                                    type: "resultform",
                                },
                            }}
                        />
                    )}
                </PageAnimation>
            </PageWrapper>
        </Global>
    );
});

export default MediaDetail;
