import React, { forwardRef, useState, useEffect, useImperativeHandle } from "react";
import DayPicker, { DateUtils } from 'react-day-picker';
import { useTranslation } from "react-i18next";
import 'react-day-picker/lib/style.css';

// Include the locale utils designed for moment
import MomentLocaleUtils from 'react-day-picker/moment';

// Make sure moment.js has the required locale data
import 'moment/locale/ca';
import 'moment/locale/de';
import 'moment/locale/en-gb';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/nl';

const Calendar = forwardRef((props, ref) => {
    const { t, i18n } = useTranslation();
    const [dates, setDates] = useState([]);
    const callback = props.callback;

    // Day clear option
    useImperativeHandle(ref, () => ({
      clearDays() {
        setDates([]);
      }
    }));

    useEffect(() => {
        callback(dates);
    }, [dates]);

    const handleDayClick = (day, modifiers) => {
        if (modifiers.disabled) {
            return;
        }
        var prevDates = [...dates];
        if (modifiers.selected) {
            const selectedIndex = prevDates.findIndex(selectedDay =>
              DateUtils.isSameDay(selectedDay, day)
            );
            prevDates.splice(selectedIndex, 1);
        } else {
            prevDates.push(day);
        }
        setDates(prevDates);
    };

    return (
        <DayPicker
            month={props.month}
            canChangeMonth={props.canChangeMonth}
            disabledDays={props.disabledDays}
            selectedDays={dates}
            localeUtils={MomentLocaleUtils}
            locale={i18n.language}
            onDayClick={handleDayClick}
        />
    );
});

export default Calendar;
