import Peer from 'peerjs';
import io from "socket.io-client";
import api from '../api';
import {captureException} from '../helpers/errorHelper';
import {CommunicationStoreInstance} from '../stores/communication.store';
import {AuthenticationStoreInstance} from '../stores/authentication.store';
import {ChatStoreInstance} from '../stores/chat.store';
import {
    AUTHENTICATE_EVENT,
    CALL_FINISHED_EVENT,
    EXERCISE_CHANGE_MINI_STEP,
    EXERCISE_CHANGE_STEP,
    EXERCISE_RESULT,
    MEDIA_SELECTED_BY_THERAPIST,
    DOCUMENT_SELECTED_BY_THERAPIST,
    EXERCISE_SELECTED_BY_THERAPIST,
    INCOMING_CALL_EVENT,
    UNAUTHORIZED_EVENT,
    INCOMING_CONVERSATION_EVENT,
    CONVERSATION_FINISHED_EVENT,
    INCOMMING_MESSAGE_EVENT,
    UPDATE_SETTING_EVENT,
    UPDATE_MOUSE_EVENT
} from '../events';
import {LibraryStoreInstance} from '../stores/library.store';

// let dataStreamConnection = null;
let videoCallConnection = null;
let localStream = null;

// const getDataStreamConnection = () => dataStreamConnection;
const getVideoCallConnection = () => videoCallConnection;
const getLocalStream = () => localStream;

const initializeWebsocketConnection = () => {
    const socket = io.connect(api.getSocketUrl(), {
        path: '/socket/socket/call',
        transports: ['polling', 'websocket'],
        upgrade: false
    });

    socket.emit(AUTHENTICATE_EVENT, {token: localStorage.getItem('token')})
        .on(UNAUTHORIZED_EVENT, () => AuthenticationStoreInstance.setRedirectToLogin(true))
        .on(INCOMING_CALL_EVENT, () => CommunicationStoreInstance.showActiveCallBanner())
        .on(CALL_FINISHED_EVENT, () => CommunicationStoreInstance.setIsCallFinished(true))
        .on(MEDIA_SELECTED_BY_THERAPIST, (id) => {
            LibraryStoreInstance.setActiveMediaId(id);
        })
        .on(DOCUMENT_SELECTED_BY_THERAPIST, (id) => {
            LibraryStoreInstance.setActiveDocumentId(id);
        })
        .on(EXERCISE_SELECTED_BY_THERAPIST, (id) => {
            LibraryStoreInstance.setActiveMiniStep(1);
            LibraryStoreInstance.setActiveStep(1);
            LibraryStoreInstance.setActiveExerciseResult(null);
            LibraryStoreInstance.setActiveExerciseId(id);
        })
        .on(EXERCISE_RESULT, (result) => LibraryStoreInstance.setActiveExerciseResult(result))
        .on(EXERCISE_CHANGE_STEP, (stepNumber) => LibraryStoreInstance.setActiveStep(stepNumber))
        .on(EXERCISE_CHANGE_MINI_STEP, (stepNumber) => LibraryStoreInstance.setActiveMiniStep(stepNumber))
        .on(INCOMING_CONVERSATION_EVENT, () => ChatStoreInstance.setNumberOfChats(ChatStoreInstance.numberOfChats + 1))
        .on(CONVERSATION_FINISHED_EVENT, () =>  ChatStoreInstance.setNumberOfChats(ChatStoreInstance.numberOfChats - 1))
        .on(INCOMMING_MESSAGE_EVENT, (chat) => ChatStoreInstance.setActiveChat(chat, true))
        .on(UPDATE_SETTING_EVENT, (settings) => CommunicationStoreInstance.setSettings(settings))
        .on(UPDATE_MOUSE_EVENT, (mousePos) => CommunicationStoreInstance.setMousePosition(mousePos));

    socket.on('connect_error', (error) => {
        console.log(error)
    });
};

const initializeVideoCallConnection = async (userId) => {
    
    videoCallConnection = new Peer({
        host: 'peerjs.telephon.digital',
        path: 'myapp',
        secure: true,
        debug: 3,
        config: {
            'iceServers': [
                {url: 'stun:stun.l.google.com:19302'},
                {url: 'stun:stun1.l.google.com:19302'}
            ]
        },  
    });
    videoCallConnection.on('open', (id) => {
        return api.getCommunicationApi().patch(`/users/${userId}`, {videoCallConnectionId: id})
    });
};

const initializeLocalVideoStream = (hasAudio = true) => {
    return navigator.mediaDevices.getUserMedia({video: true, audio: hasAudio})
        .then(stream => {
            localStream = stream;
            return stream;
        })
        .catch(err => captureException('Failed to open media stream ', err));
};

const initializeScreenShareStream = () => {
    return navigator.mediaDevices.getDisplayMedia().then( stream => {
        localStream.addTrack(stream.getTracks()[0]);
        console.log("intitiaalize", localStream);
        return stream;
    })
    .catch(err => captureException('Failed to open media stream ', err));
};

const stopLocalStream = () => {
    if (localStream) {
        localStream.getTracks().forEach(track => track.stop());
        localStream.getVideoTracks().forEach(track => track.stop())
        localStream = null;
    }
};

const destroyVideoConnection = (userId) => {
    videoCallConnection.destroy();
    // if (dataStreamConnection) dataStreamConnection.destroy();
    return initializeVideoCallConnection(userId);
};

export default {
    initializeVideoCallConnection,
    // initializeDataStreamConnection,
    initializeLocalVideoStream,
    initializeScreenShareStream,
    initializeWebsocketConnection,
    getVideoCallConnection,
    // getDataStreamConnection,
    getLocalStream,
    stopLocalStream,
    destroyVideoConnection
}
