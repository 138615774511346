import React, { useState, useEffect, useContext } from "react";
import { Redirect } from "react-router-dom";
import styled from "styled-components";
import OnboardingStep from "./onboardingStep";
import Bertie from "../../assets/images/bertie.png";
import { api } from "../../api";
import registrationStore from "../../components/registrationStore";
import patientStore from "../../components/patientStore";
import { useTranslation } from "react-i18next";

const BertieModal = styled.img`
    position: fixed;
    right: 10px;
    bottom: 10px;
    border-radius: 50%;
    width: 75px;
    border: 2px solid #51c0cf;
    padding: 5px;
    cursor: pointer;
    z-index: 99999;
`;

const OnboardingWrapper = styled.div`
    display: none;

    @media (min-width: 768px) {
        display: block;
    }
`;

const OnboardingFlow = () => {
    const { t, i18n } = useTranslation();
    const [BertieIsVisible, setBertieIsVisibile] = useState(false);
    const store = useContext(registrationStore);
    const user = JSON.parse(store.user);
    const patientstore = useContext(patientStore);
    const [step, setStep] = useState(0);
    const [stepsAreVisible, setStepsAreVisible] = useState(true);
    const [redirect, setRedirect] = useState(false);
    const [bertieAccount, setBertieAccount] = useState();

    useEffect(() => {
        if (step === 1)
            api.get(`/patients?therapist_id=${user.public_id}`).then((res) => {
                if (res.data.users[0]) {
                    const bertie = res.data.users[0];
                    patientstore.setPatient(bertie.firstName, bertie.lastName, bertie.public_id, bertie.sex);
                }
            });
    }, [step]);

    const closeBertie = () => {
        setBertieIsVisibile(false);
        api.put(`/user/${user.public_id}`, {
            isOnboarded: true,
        }).then((res) => {
            setBertieIsVisibile(false);
            store.setUser(JSON.stringify(res.data.user));
        });
    };

    const nextStep = () => {
        setStep(step + 1);
    };

    const previousStep = () => {
        setStep(step - 1);
    };

    useEffect(() => {
        if (user.isOnboarded === true) {
            setBertieIsVisibile(false);
        } else {
            setBertieIsVisibile(true);
        }
    });

    const onboardingSteps = {
        0: {
            title: `${t("onboardingSteps.stepOneTitleHello")} ${user.firstName}! ${t("onboardingSteps.stepOneTitle")}`,
            description: `${t("onboardingSteps.stepOneDescription")}`,
            backButton: `${t("onboardingSteps.notnow")}`,
            nextButton: `${t("onboardingSteps.nextbutton")}`,
            backButtonDestination: closeBertie,
            nextButtonDestination: nextStep,
            redirect: "/t/therapy",
        },
        1: {
            title: `${t("onboardingSteps.stepTwoTitle")}`,
            description: `${t("onboardingSteps.stepTwoDescription")}`,
            backButton: `${t("onboardingSteps.previous")}`,
            nextButton: `${t("onboardingSteps.next")}`,
            backButtonDestination: previousStep,
            nextButtonDestination: nextStep,
            redirect: `/t/therapy/PatientDetail/${patientstore.patient.public_id}`,
        },
        2: {
            title: `${t("onboardingSteps.stepThreeTitle")}`,
            description: `${t("onboardingSteps.stepThreeDescription")}`,
            backButton: `${t("onboardingSteps.previous")}`,
            nextButton: `${t("onboardingSteps.next")}`,
            backButtonDestination: previousStep,
            nextButtonDestination: nextStep,
            redirect: `/t/therapy/PatientTask/${patientstore.patient.public_id}`,
        },
        3: {
            title: `${t("onboardingSteps.stepFourTitle")}`,
            description: `${t("onboardingSteps.stepFourDescription")}`,
            backButton: `${t("onboardingSteps.previous")}`,
            nextButton: `${t("onboardingSteps.next")}`,
            backButtonDestination: previousStep,
            nextButtonDestination: nextStep,
            redirect: `/t/therapy/PatientTask/${patientstore.patient.public_id}`,
        },
        4: {
            title: `${t("onboardingSteps.stepICFFourFiveTitle")}`,
            description: `${t("onboardingSteps.stepICFFourFiveDescription")}`,
            backButton: `${t("onboardingSteps.previous")}`,
            nextButton: `${t("onboardingSteps.next")}`,
            backButtonDestination: previousStep,
            nextButtonDestination: nextStep,
            redirect: `/t/therapy/PatientTask/${patientstore.patient.public_id}`,
        },
        5: {
            title: `${t("onboardingSteps.stepFiveTitle")}`,
            description: `${t("onboardingSteps.stepFiveDescription")}`,
            backButton: `${t("onboardingSteps.previous")}`,
            nextButton: `${t("onboardingSteps.next")}`,
            backButtonDestination: previousStep,
            nextButtonDestination: nextStep,
            redirect: `/t/therapy/PatientTask/${patientstore.patient.public_id}`,
        },
        6: {
            title: `${t("onboardingSteps.stepSixTitle")}`,
            description: `${t("onboardingSteps.stepSixDescription")}`,
            backButton: `${t("onboardingSteps.previous")}`,
            nextButton: `${t("onboardingSteps.next")}`,
            backButtonDestination: previousStep,
            nextButtonDestination: nextStep,
            redirect: `/t/therapy/PatientTask/${patientstore.patient.public_id}`,
        },
        7: {
            title: `${t("onboardingSteps.stepSevenTitle")}`,
            description: `${t("onboardingSteps.stepSevenDescription")}`,
            backButton: `${t("onboardingSteps.previous")}`,
            nextButton: `${t("onboardingSteps.next")}`,
            backButtonDestination: previousStep,
            nextButtonDestination: nextStep,
            redirect: `/t/therapy/PatientTask/${patientstore.patient.public_id}`,
        },
        8: {
            title: `${t("onboardingSteps.stepEightTitle")}`,
            description: `${t("onboardingSteps.stepEightDescription")}`,
            backButton: `${t("onboardingSteps.previous")}`,
            nextButton: `${t("onboardingSteps.next")}`,
            backButtonDestination: previousStep,
            nextButtonDestination: nextStep,
            redirect: `/t/therapy/PatientTask/${patientstore.patient.public_id}`,
        },
        9: {
            title: `${t("onboardingSteps.stepNineTitle")}`,
            description: `${t("onboardingSteps.stepNineDescription")}`,
            backButton: `${t("onboardingSteps.previous")}`,
            nextButton: `${t("onboardingSteps.next")}`,
            backButtonDestination: previousStep,
            nextButtonDestination: nextStep,
            redirect: `/t/therapy/PatientTask/${patientstore.patient.public_id}`,
        },
        10: {
            title: `${t("onboardingSteps.stepTenTitle")}`,
            description: `${t("onboardingSteps.stepTenDescription")}`,
            backButton: `${t("onboardingSteps.previous")}`,
            nextButton: `${t("onboardingSteps.next")}`,
            backButtonDestination: previousStep,
            nextButtonDestination: nextStep,
            redirect: `/t/therapy/PatientResults/${patientstore.patient.public_id}`,
        },
        11: {
            title: `${t("onboardingSteps.stepElevenTitle")}`,
            description: `${t("onboardingSteps.stepElevenDescription")}`,
            backButton: `${t("onboardingSteps.previous")}`,
            nextButton: `${t("onboardingSteps.next")}`,
            backButtonDestination: previousStep,
            nextButtonDestination: nextStep,
            redirect: `/t/consultMaterial`,
        },
        12: {
            title: `${t("onboardingSteps.stepTwelveTitle")}`,
            description: `${t("onboardingSteps.stepTwelveDescription")}`,
            backButton: `${t("onboardingSteps.previous")}`,
            nextButton: `${t("onboardingSteps.next")}`,
            backButtonDestination: previousStep,
            nextButtonDestination: nextStep,
            redirect: `/t/CreateMaterial`,
        },
        13: {
            title: `${t("onboardingSteps.stepThirteenTitle")}`,
            description: `${t("onboardingSteps.stepThirteenDescription")}`,
            backButton: `${t("onboardingSteps.previous")}`,
            nextButton: `${t("onboardingSteps.next")}`,
            backButtonDestination: previousStep,
            nextButtonDestination: nextStep,
            redirect: `/t/therapy`,
        },
        14: {
            title: `${t("onboardingSteps.stepFourteenTitle")}`,
            description: `${t("onboardingSteps.stepFourteenDescription")}`,
            backButton: `${t("onboardingSteps.previous")}`,
            nextButton: `${t("onboardingSteps.endOnboarding")}`,
            backButtonDestination: previousStep,
            nextButtonDestination: closeBertie,
            redirect: `/t/therapy`,
        },
    };

    const handleBertieClose = () => {
        setStepsAreVisible(!stepsAreVisible);
    };

    return (
        <OnboardingWrapper>
            {BertieIsVisible ? (
                <>
                    {stepsAreVisible && (
                        <OnboardingStep
                            absolute
                            title={onboardingSteps[step]["title"]}
                            description={onboardingSteps[step]["description"]}
                            backButton={onboardingSteps[step]["backButton"]}
                            nextButton={onboardingSteps[step]["nextButton"]}
                            backButtonDestination={() => onboardingSteps[step]["backButtonDestination"]}
                            nextButtonDestination={() => onboardingSteps[step]["nextButtonDestination"]}
                            redirect={onboardingSteps[step]["redirect"]}
                        />
                    )}
                    <BertieModal onClick={handleBertieClose} src={Bertie} />
                    {step > 0 ? <Redirect to={onboardingSteps[step - 1]["redirect"]} /> : ""}
                </>
            ) : (
                <></>
            )}
        </OnboardingWrapper>
    );
};

export default OnboardingFlow;
