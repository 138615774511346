import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Card from "../styled/card";
import { H4, P } from "../styled/typography";
import { Redirect, Link } from "react-router-dom";
import dateConverter from "../helperComponents/dateConverter";

// Icons
import Skilltraining from "../../assets/images/vaardigheidstraining.svg";
import FillEx from "../../assets/images/invuloefening.svg";
import Questionnaire from "../../assets/images/vragenlijst.svg";
import Document from "../../assets/images/document.svg";
import Test from "../../assets/images/test.svg";

const Wrapper = styled.div`
    width: 100%;
    margin-bottom: 15px;

    @media (min-width: 768px) {
        width: ${(props) => (props.taskSelect ? "100%" : "49.5%")};
    }
`;

const CardStyled = styled(Card)`
    height: 100%;
    cursor: pointer;

    &.active {
        border: 2px solid #51c0cf;
    }
`;

const CardLink = styled(Link)`
    color: #545454;
    text-decoration: none;
    &:hover {
        color: white;
    }
`;

const Title = styled(H4)`
    margin: 0px;
    font-size: 18px;
    margin-top: 5px;

    @media (min-width: 980px) {
        font-size: 20px;
    }
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`;

const Details = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 33%;
`;

const SmallText = styled(P)`
    font-size: 16px;
    font-style: italic;
    margin: 0px;
`;

const IconText = styled(P)`
    vertical-align: middle;
    font-size: 0.9em;
    font-style: italic;    
    margin: 0px;
`;

const Icon = styled.img`
    vertical-align: middle;
    width: 20px;
    height: 20px;    

    @media (min-width: 1200px) {
        width: 30px;
        height: 30px;
    }
`;

const TagWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 75%;
`;

const Tag = styled.div`
    background-color: #51c0cf;
    border: 1px solid #51c0cf;
    border-radius: 5px;
    margin-right: 5px;
    margin-top: 5px;
    padding: 3px 10px;

    @media (min-width: 1400) {
        padding: 5px 15px;
        margin-right: 10px;
    }
`;

const TagText = styled(P)`
    color: white;
    margin: 0px;
    font-size: 15px;

    @media (min-width: 1400px) {
        font-size: 18px;
    }
`;

const AuthorInfo = styled.div`
    display: none;
`;

function LibraryCard(props) {
    const { t, i18n } = useTranslation();
    const [redirect, setRedirect] = useState(false);
    let ActiveIcon;
    let hoverText;
    let isExercise = true;

    if (props.info.type) {
        switch (props.info.type) {
            case "stappenplan":
                ActiveIcon = Skilltraining;
                hoverText = t("librarycard.vaardigheidstraining");
                break;
            case "stappenplanValidatie":
                hoverText = t("librarycard.invulOefening");
                ActiveIcon = FillEx;
                break;
            case "dagboek":
                ActiveIcon = Questionnaire;
                hoverText = t("librarycard.vragenlijst");
                break;
        }
    } else {
        isExercise = false;
        hoverText = t("librarycard.document");
        ActiveIcon = Document;
    }

    function handleCardClick(event) {
        if (props.taskSelect) {
            if (props.info.type) {
                props.callBack(props.info.id, props.info.title, props.info.type);
            } else {
                props.callBack(props.info.id, props.info.title, "Document");
            }

            var elems = document.querySelectorAll("#card");
            elems.forEach.call(elems, function (el) {
                el.classList.remove("active");
            });

            if (event.target.type !== "div") {
                event.target.closest("#card").className += " active";
            } else {
                event.target.className += " active";
            }
        } else {
            setRedirect(true);
        }
    }

    return (
        <Wrapper title={hoverText} taskSelect={props.taskSelect && !props.public}>
            <CardStyled id="card" onClick={(e) => handleCardClick(e)}>
                <Header id="">
                    <Title black>{props.info && props.info.title}</Title>
                    <Details>
                        <AuthorInfo>
                            {!isExercise && (
                                <>
                                    <SmallText>{dateConverter(new Date(props.info.date_created))}</SmallText>
                                    <SmallText>{props.info.therapist_name}</SmallText>
                                </>
                            )}
                        </AuthorInfo>
                        {props.showType && <IconText>{hoverText}</IconText>}
                        <Icon src={ActiveIcon} />                       
                    </Details>
                </Header>
                <TagWrapper>
                    {isExercise &&
                        props.info.tags &&
                        props.info.tags.map((tag) => {
                            return (
                                <>
                                    {tag && (
                                        <Tag>
                                            <TagText>{t(tag.name)}</TagText>
                                        </Tag>
                                    )}
                                </>
                            );
                        })}
                </TagWrapper>
            </CardStyled>
            {redirect && (
                <Redirect
                    push
                    to={
                        isExercise
                            ? { pathname: "consultMaterial/taskDetail/" + props.info.id }
                            : { pathname: "consultMaterial/Detail/" + props.info.id }
                    }
                />
            )}
        </Wrapper>
    );
}

export default LibraryCard;
