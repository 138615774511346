import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import TherapistOverlay from "../../components/overlays/therapistOverlay";
import Global from "../../components/styled/global";
import registrationStore from "../../components/registrationStore";
import PageWrapper from "../../components/styled/pageWrapper";
import { PageTitle } from "../../components/styled/typography";
import { useTranslation } from "react-i18next";
import LoadSpinner from "../../components/loadspinner";
import OpdrachtStore from "../../components/opdrachtStore";
import { api } from "../../api";
import { Redirect } from "react-router-dom";
import { PageAnimation } from "../../components/styled/animation";
import TemplateForm from "../../components/templateForm";

const OpdrachtAanpassen = observer((props) => {
    const { t, i18n } = useTranslation();
    const regstore = useContext(registrationStore);
    const user = JSON.parse(regstore.user);
    const store = useContext(OpdrachtStore);
    const [redirect, setRedirect] = useState(false);
    const { id } = props.match.params;

    useEffect(() => {
        if (store.isFinishedCreating) {
            setRedirect(true);
            store.setIsFinishedCreating(false);
        }

    }, [store.isFinishedCreating])

    return (
        <Global>
            <PageWrapper>
                <TherapistOverlay />
                <PageAnimation>
                    <PageTitle black>{t("opdrachtAanpassen.adj")}</PageTitle>
                    <TemplateForm id={id}></TemplateForm>
                    {redirect && <Redirect to="/t/CreateMaterial" />}
                </PageAnimation>
            </PageWrapper>
        </Global>
    );
});

export default OpdrachtAanpassen;
