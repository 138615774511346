import React, { useEffect, useState, useContext } from "react";
import PatientOverlay from "../../components/overlays/patientOverlay";
import { observer } from "mobx-react";
import registrationStore from "../../components/registrationStore";
import Global from "../../components/styled/global";
import { PageTitle, SmallTitle } from "../../components/styled/typography";
import PageWrapper from "../../components/styled/pageWrapper";
import TodayTask from "../../components/todayTask";
import { api } from "../../api";
import LoadSpinner from "../../components/loadspinner";
import { PageAnimation } from "../../components/styled/animation";
import Flash from "../../components/styled/flashMessage";
import { Redirect, Link } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { useTranslation } from "react-i18next";
import chatService from '../../communication-module/services/chatService';
import ChatStore from "../../communication-module/stores/chat.store";
import { P } from "../../components/styled/typography";
import { FaTrashAlt,  FaEdit } from "react-icons/fa";
import Button from "../../components/styled/button";

const FlashMessage = styled(Flash)`
    :hover {
        cursor: pointer;
    }
`;

const EditIcon = styled(FaEdit)`
    color: #FFFFFF;
    margin-right: 5px;
    font-size: 16px;
`

const Title = styled(SmallTitle)`
    margin-top: 10px;
    margin-right: 40px;
`;

const Flex = styled.div`
    display: flex;
    flex-direction: column;

    @media(min-width: 980px){
        flex-direction: row;
        justify-content: space-between;
    }
`

const Buttons = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-top: 15px;

    @media(min-width: 980px){
        margin-top: 0px;
        margin-left: 10px;
        justify-content: flex-start;
    }
`

const Overlay = styled.div`
    display: none;

    /*@media (max-width: 980px){
        z-index: 999;
        background-color: white;
        position: fixed;
        top: 0;
        bottom: 0px;
        height: 100vh;
        width: 100vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center; 
    }*/
`;

const TrashIcon = styled(FaTrashAlt)`
    color: #FFFFFF;
    margin-right: 5px;
    font-size: 16px;
`

const PrimaryButton = styled(Button)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    margin: 3px 0px;
    width: 100%;
`

const RedButton = styled(Button)`
    background-color: #DC393D;
    border-color: #DC393D;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    margin: 3px 0px;
    width: 100%;

    &:hover {
        background-color: #b02a2e;
        border-color: #b02a2e;
    }
`

const Empty = styled.div`
    width: 1px;
    height: 1px;
`

const StyledLink = styled(Link)`
    text-decoration: none;
`

const fadein = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`

const Wrapper = styled.div`
    background-color: #FFFFFF;
    box-sizing: border-box;
    width: 100%;
    padding: 20px;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 4px 4px rgba(197, 197, 197, 0.25);
    border-radius: 10px;
    height: auto;
    animation: ${fadein} 1s;

`

const A = styled(Link)`
    text-decoration: none;
    width: 100%;
    margin: 5px 0px;

    @media (min-width: 980px){
        width: 49.5%;
    }
`

const SubTitle = styled(P)`
    font-style: italic;
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 16px;
`

const Dashboard = observer(() => {
    const { t, i18n } = useTranslation();
    const store = useContext(registrationStore);
    const user = JSON.parse(store.user);
    const [assignments, setAssignments] = useState([]);
    const [chats, setChats] = useState([]);
    const [Loading, setLoading] = useState(true);
    const [redirect, setRedirect] = useState(false);
    const [individuFinished, setIndividuFinished] = useState(false);
    const chatstore = useContext(ChatStore);

    useEffect(() => {
        api.get("/tasksOfToday/" + user.public_id).then((response) => {
            if (response.data.individu_finished && !store.individuFinished) {
                store.setIndividuFinished(true);
                ShowIndividuFinished();
            }
            setAssignments(response.data.tasks);
            setLoading(false);
        });

        chatService.getChats().then(({data}) => setChats(data));
    }, []);

    useEffect(() => {
        chatService.getChats().then(({data}) => setChats(data));
    }, [chatstore.numberOfChats, chatstore.activeChat]);

    const HandleClick = () => {
        store.setPlan(user.plan);
        store.setPlanLength(user.plan_period);
        store.addUserAttribute("ID", user.public_id);
        setRedirect(true);
    };

    const HandleRegistrationClick = () => {
        store.setRegistrationMessage(false);
    }

    const ShowIndividuFinished = () => {
        setIndividuFinished(true);
        setTimeout(function(){
            setIndividuFinished(false)
        }, 5000)
    }

    const GetChatPartenerFullName = (attendees) => {
        var fullName;
        attendees.forEach(attendee => {
            if (attendee && attendee.public_id !== user.public_id) fullName = `${attendee.firstName} ${attendee.lastName}`;
        });
        return fullName;
    }

    const removeChat = (chatId) => {
        chatstore.finish(chatId);
    };

    const GetCountNewMessages = (chat) => {
        const last = chat.messages[chat.messages.length - 1];
        return last && last.instigatorId != user.public_id ? chat.counts : 0;
    }

    return (
        <Global>
            <PageWrapper>
                <PatientOverlay />
                <PageAnimation>
                    <PageTitle black>{t("sidebarPatient.over")}</PageTitle>
                    {store.registrationMessage && <FlashMessage green onClick={HandleRegistrationClick}>{t("dashboard.succesRegister")}</FlashMessage>}
                    {individuFinished && <Flash green>{t("dashboard.individuFinished")}</Flash>}
                    {user.expired && <FlashMessage onClick={HandleClick}>{t("dashboard.expired")}</FlashMessage>}
                    {redirect && <Redirect to="/p/MyAccount/MyPlan" />}
                    {!user.expired && <SmallTitle black>{t("dashboard.tasksToday")}</SmallTitle>}
                    {!user.expired &&
                        (Loading ? (
                            <LoadSpinner />
                        ) : assignments.length > 0 ? (
                            assignments.map((assignment) => {
                                return assignment.taken.map((task, index) => (
                                    <TodayTask key={index} title={assignment.title} data={task} />
                                ));
                            })
                        ) : (
                            <div style={{ marginTop: "20px" }}>{t("dashboard.noTasks")}</div>
                        ))}
                    {!user.expired && <SmallTitle black>{t("dashboard.chats")}</SmallTitle>}
                    {!user.expired &&
                        (chats.length > 0 ? (
                            chats.map((chat) => (
                                <A>
                                    <Wrapper>
                                        <Flex>
                                            <div>
                                                <Title>{t("dashboard.chatTitle")} {GetChatPartenerFullName(chat.attendees)}</Title>
                                                <SubTitle>{GetCountNewMessages(chat)} {t("dashboard.chatCount")}</SubTitle>
                                            </div>
                                            <Buttons>
                                                <StyledLink to={{
                                                    pathname: `/chat`,
                                                    state: { id: chat.id}
                                                }}>
                                                    <PrimaryButton>
                                                    <EditIcon />{t("dashboard.chatOpen")}<Empty />
                                                    </PrimaryButton>
                                                </StyledLink>
                                                <RedButton onClick={() => removeChat(chat.id)}>
                                                    <TrashIcon />{t("dashboard.chatDelete")}<Empty />
                                                </RedButton>     
                                            </Buttons>
                                        </Flex>
                                    </Wrapper>
                                </A>
                        ))) : (
                            <p>{t("chats.leeg")}</p>
                        ))
                    }
                </PageAnimation>
                <Overlay></Overlay>
            </PageWrapper>
        </Global>
    );
});

export default Dashboard;
