import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import {
    FaBriefcaseMedical,
    FaCalendar,
    FaChevronDown,
    FaChevronLeft,
    FaStethoscope,
    FaTasks,
    FaTimes
} from "react-icons/fa";
import { H1, H2 } from "../styled/typography";
import styled from "styled-components";
import Card from "../styled/card";
import Collapsible from "../helperComponents/collapsible";
import registrationStore from "../registrationStore";
import { api } from "../../api";
import Button from "../styled/button";
import AssignmentCard from "../assignmentCard";
import trajectStore from "../trajectComponents/trajectStore";
import { Redirect } from "react-router-dom";
import { isAppInsigthsEnabled } from "../../insights";
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { eventConstants, getInsightUserType } from "../../insights-constants";
import { trackEvent } from './../../insights-track';

const AssignmentLargeCard = styled(Card)`
    margin-top: 20px;
    margin-bottom: 20px;

    @media (min-width: 980px) {
        margin-top: 40px;
        margin-bottom: 20px;
    }
`;

const CollapsibleTitle = styled(H2)`
    display: flex;
    text-align: left;
    margin: 10px 25px;
    font-size: 18px;
    cursor: pointer;

    @media (min-width: 980px) {
        margin: 10px 25px;
        font-size: 25px;
    }
`;

const ChevronDown = styled(FaChevronDown)`
    text-align: right;
    color: #51c0cf;
    font-size: 18px;
    align-self: center;
    margin-left: auto;

    @media (min-width: 980px) {
        font-size: 25px;
    }
`;

const ChevronLeft = styled(FaChevronLeft)`
    text-align: right;
    color: #51c0cf;
    font-size: 18px;
    align-self: center;
    margin-left: auto;

    @media (min-width: 980px) {
        font-size: 25px;
    }
`;

const TaskContainer = styled.div`
    position: relative;
    margin-left: 0px;
    margin-bottom: 20px;

    @media (min-width: 980px) {
        margin-left: 30px;
    }
`;

const Flex = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 10px;
`;

const Half = styled.div`
    width: 100%;

    @media (min-width: 980px) {
        width: 49.5%;
    }
`;

const DeleteButton = styled(Button)`
    background-color: #dc393d;
    border-color: #dc393d;
    display: flex;
    align-items: center;
    margin-top: 5px;
    margin-left: auto;
    font-size: 16px;

    &:hover {
        background-color: #b02a2e;
        border-color: #b02a2e;
    }

    @media (min-width: 980px) {
        margin-top: 0px;
    }
`;

const DeleteIcon = styled(FaTimes)`
    color: #ffffff;
    margin-right: 5px;
    font-size: 14px;
`;

const TrajectIcon = styled(FaTasks)`  
  margin: 0.05em 0.5em 0px 0px;
`;

const StethoscopeIcon = styled(FaStethoscope)`
  color: #51c0cf;
  margin: 0.05em 0.4em 0px 0.4em;
`;

const BriefcaseMed = styled(FaBriefcaseMedical)`
  color: #51c0cf;
  margin: 0.05em 0.4em 0.4em 0.4em;
`;

const Separator = styled.div`
  border-bottom: cadetblue 1px dotted;
  margin: 20px 0px 20px 25px; 
`;

const TrajectDetails = (props) => {
    const { t, i18n } = useTranslation();
    const store = useContext(registrationStore);
    const user = JSON.parse(store.user);
    const [viewTraject, setViewTraject] = useState(props.viewTraject);
    const trajStore = useContext(trajectStore);
    const [redirect, setRedirect] = useState(false);

    const isAiEnabled = isAppInsigthsEnabled();
    const appInsights = useAppInsightsContext();
    const trackProps = {
        user: {
            type: getInsightUserType(user.therapist),
            public_id: user.public_id 
        }
    }

    const toggleTraject = () => {
        setViewTraject(!viewTraject);
    };

    const AssignmentInfo = (category) => (
        <TaskContainer>
            <Flex>
                {props.traject.assignments
                    .filter((e) => e.category === category.category)
                    .map((assignment, index) => {
                        return (
                            <AssignmentCard
                                key={assignment.id}
                                number={index + 1}
                                assignment={assignment}
                                update={props.update}
                            />
                        );
                    })}
            </Flex>
            {category.category !== "research_clinical_observation" ? (
                <Button small 
                    onClick={() => {
                        trackEvent(isAiEnabled, appInsights, eventConstants.PRESS.CLIENTS.CARE_PATHS.ADD_TASK, trackProps);
                        openOverlay(category)
                    }}>
                    {t("trajectDetails.addAssignment")}
                </Button>
            ) : (
                <div>
                    <Button small onClick={() => setRedirect(true)}>
                        {t("trajectDetails.goToPatientDetails")}
                    </Button>
                    {redirect && (
                        <Redirect
                            to={{ pathname: "/t/therapy/PatientDetail/" + props.patient_id, search: "?edit=true" }}
                        />
                    )}
                </div>
            )}
        </TaskContainer>
    );

    const openOverlay = (category) => {
        //Set current category & open overlay
        props.openAssignmentOverlay();
        trajStore.setChosenCategory(category.category);
        trajStore.setChosenTrajectId(props.traject.id);
    };

    const hasContent = (category) => props.traject.assignments.filter((e) => e.category === category).length > 0;

    return (
        <AssignmentLargeCard>
            <CollapsibleTitle onClick={toggleTraject}>
                <TrajectIcon></TrajectIcon>{t("trajectDetails.traject") + " " + props.traject.title}{" "}
                {viewTraject ? <ChevronDown /> : <ChevronLeft />}
            </CollapsibleTitle>
            {viewTraject ? (
                <div>
                    <Separator></Separator>
                    <Collapsible title={t("ICF.Onderzoekstraject")} bold={true} open={true} icon={<StethoscopeIcon></StethoscopeIcon>}>
                        <Collapsible
                            title={t("ICF.KlinischeObservatie")}
                            color={"#7E7E7E"}
                            open={hasContent("research_clinical_observation")}
                        >
                            <AssignmentInfo category={"research_clinical_observation"} />
                        </Collapsible>
                        <Collapsible title={t("ICF.vragenlijsten")} color={"#7E7E7E"} open={hasContent("research_questions")}>
                            <AssignmentInfo category={"research_questions"} />
                        </Collapsible>
                        <Collapsible title={t("ICF.testen")} color={"#7E7E7E"} open={hasContent("research_tests")}>
                            <AssignmentInfo category={"research_tests"} />
                        </Collapsible>
                    </Collapsible>
                    <br />
                    <Collapsible title={t("ICF.behandeltraject")} bold={true} open={true} icon={<BriefcaseMed></BriefcaseMed>}>
                        <Collapsible
                            title={t("ICF.informatieEducatie")}
                            color={"#7E7E7E"}
                            open={hasContent("treatment_info_education")}
                        >
                            <AssignmentInfo category={"treatment_info_education"} />
                        </Collapsible>
                        <Collapsible title={t("ICF.bewustwording")} color={"#7E7E7E"} open={hasContent("treatment_awareness")}>
                            <AssignmentInfo category={"treatment_awareness"} />
                        </Collapsible>
                        <Collapsible title={t("ICF.training")} color={"#7E7E7E"} open={hasContent("treatment_training")}>
                            <AssignmentInfo category={"treatment_training"} />
                        </Collapsible>
                        <Collapsible
                            title={t("ICF.generalisatie")}
                            color={"#7E7E7E"}
                            open={hasContent("treatment_generalisation")}
                        >
                            <AssignmentInfo category={"treatment_generalisation"} />
                        </Collapsible>
                        <Collapsible
                            title={t("ICF.automatisatie")}
                            color={"#7E7E7E"}
                            open={hasContent("treatment_automatisation")}
                        >
                            <AssignmentInfo category={"treatment_automatisation"} />
                        </Collapsible>
                    </Collapsible>
                    <Separator></Separator>
                    <DeleteButton onClick={(id) => props.deleteTraject(props.traject.id)}>
                        <DeleteIcon />
                        {t("trajectDetails.deleteTraject")}
                    </DeleteButton>
                </div>
            ) : null}
        </AssignmentLargeCard>
    );
};

export default TrajectDetails;
