import React, { useContext, useEffect, useState } from "react";
import PatientOverlay from "../../components/overlays/patientOverlay";
import Notification from "../../components/notification";
import Global from "../../components/styled/global";
import { PageTitle } from "../../components/styled/typography";
import PageWrapper from "../../components/styled/pageWrapper";
import registrationStore from "../../components/registrationStore";
import { observer } from "mobx-react-lite";
import { api } from "../../api";
import { PageAnimation } from "../../components/styled/animation";
import { templateElement } from "@babel/types";
import HelpdeskForm from "../../components/helpdeskForm";
import { P } from "../../components/styled/typography";
import { useTranslation } from "react-i18next";

const HelpdeskP = observer(() => {
    const { t, i18n } = useTranslation();
    const [messages, setMessages] = useState([]);
    const store = useContext(registrationStore);
    const user = JSON.parse(store.user);

    useEffect(() => {
        api.get("/user/" + user.public_id + "/inbox").then((response) => {
            setMessages(response.data.inbox);
        });
    }, []);

    const update = () => {
        api.get("/user/" + user.public_id + "/inbox").then((response) => {
            setMessages(response.data.inbox);
        });
    };

    return (
        <Global>
            <PageWrapper>
                <PatientOverlay />
                <PageAnimation>
                    <PageTitle black>{t("accountMenuDropdown.helpdesk")}</PageTitle>
                    <P>{t("helpdesk.needHelp")}</P>
                    <HelpdeskForm />
                    <P>{t("helpdesk.urgent")}</P>
                </PageAnimation>
            </PageWrapper>
        </Global>
    );
});

export default HelpdeskP;
