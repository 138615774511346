import React, {useState} from 'react';
import {PrimaryButton} from '@eu.jstack/react-button';
import styled, {css} from 'styled-components';
import PropTypes from 'prop-types';
import Icon from './icon';
import {
    faPhoneAlt,
    faShareSquare,
    faMicrophoneSlash,
    faMousePointer,
    faMicrophone, faVideoSlash, faVideo
} from '@fortawesome/free-solid-svg-icons';
import {compose, position} from 'styled-system';
import {faSlideshare} from "@fortawesome/free-brands-svg-icons";

const ButtonContainer = styled(PrimaryButton)`
    position: absolute;
    left: ${props => props.left};
    border-radius: 50%;
    width: 60px;
    height: 60px;
    transition: all ease-out 0.3s;
    opacity: 0;
    transform: translateY(150%);
    z-index: 2;
    ${compose(position)};    
    
    ${props => props.show && css`
        opacity: 1;
        transform: translateY(0%);
    `};
  
    :hover {
      transition: all linear 0.1s;
      transition-delay: 0s;
      margin-bottom: 10px;
    }
`;

const HangupCallButton = ({show, clickCallbacks, muteAudio, muteVideo, activeMouseShare, activeScreenShare}) => {
    return (
        <>
            {/* <ButtonContainer style={{'transition-delay': '0.25s'}} onClick={clickCallbacks[0]} left={'calc(50% - 170px)'} show={show} bottom={5} px={0} py={0} bg={activeMouseShare ? 'red.2' : '#469CA8'}>
                <Icon fontSize={4} color='white' icon={faMousePointer} />
            </ButtonContainer> */}

            <ButtonContainer style={{'transition-delay': '0.20s'}} onClick={clickCallbacks[0]} left={'calc(50% - 100px)'} show={show} bottom={5} px={0} py={0} bg={activeScreenShare ? 'red.2' : '#469CA8'}>
                <Icon fontSize={4} color='white' icon={faSlideshare}/>
            </ButtonContainer>

            <ButtonContainer style={{'transition-delay': '0s'}} onClick={clickCallbacks[1]} show={show} bottom={5} px={0} py={0} bg='red.2'>
                <Icon fontSize={4} color='white' icon={faPhoneAlt} transform={{rotate: 135}}/>
            </ButtonContainer>

            <ButtonContainer style={{'transition-delay': '0.20s'}} onClick={clickCallbacks[2]} right={'calc(50% - 100px)'} show={show} bottom={5} px={0} py={0} bg={muteAudio ? 'red.2' : '#469CA8'}>
                <Icon fontSize={4} color='white' icon={muteAudio ? faMicrophoneSlash : faMicrophone} />
            </ButtonContainer>

            <ButtonContainer style={{'transition-delay': '0.25s'}} onClick={clickCallbacks[3]} right={'calc(50% - 170px)'} show={show} bottom={5} px={0} py={0} bg={muteVideo ? 'red.2' : '#469CA8'}>
                <Icon fontSize={4} color='white' icon={muteVideo ? faVideoSlash : faVideo} />
            </ButtonContainer>

            {/* <ButtonContainer onClick={clickCallbacks[1]} show={show} bottom={5} px={0} py={0} bg='red.2'>
                <Icon fontSize={4} color='white'  left={'calc(50% - 20px)'} icon={faPhoneAlt} transform={{rotate: 135}}/>
            </ButtonContainer> */}
        </>
    )
};

HangupCallButton.propTypes = {
    show: PropTypes.bool,
    onClick: PropTypes.func
};

export default HangupCallButton;
