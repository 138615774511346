import React, { useContext, useState, useEffect } from "react";
import PatientOverlay from "../../components/overlays/patientOverlay";
import Global from "../../components/styled/global";
import { Li, Ul } from "../../components/styled/list";
import { PageTitle, P } from "../../components/styled/typography";
import styled from "styled-components";
import { H3 } from "../../components/styled/typography";
import Button from "../../components/styled/button";
import PatientIconMale from "../../assets/images/patient-icon.png";
import PatientIconFemale from "../../assets/images/patient-icon-woman.png";
import { PageAnimation } from "../../components/styled/animation";
import { observer } from "mobx-react";
import registrationStore from "../../components/registrationStore";
import AccountNavigation from "../../components/accountNavigation";
import PageWrapper from "../../components/styled/pageWrapper";
import { useTranslation } from "react-i18next";
import TwoFA from "../../components/2FAPhone";
import CheckoutStep from "../../components/checkoutStep";
import PatientPlans from "../../components/checkoutComponents/PatientPlans";
import Card from "../../components/styled/card";
import { api } from "../../api";
import qs from 'qs';
import LoadingSpinner from "../../components/loadspinner";
import Flash from "../../components/styled/flashMessage";

const Plan = styled.div`
    border: 1px solid #51c0cf;
    border-radius: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    margin-top: 20px;

    @media (min-width: 980px) {
        width: 375px;
    }
`;

const TwoFACard = styled(Card)`
    margin-top: 20px;
    width: 100%;

    @media (min-width: 1200px) {
        width: 50%;
    }
`;
const Title = styled(H3)`
    margin-top: 25px;
`;

const A = styled.a`
    color: #51c0cf;
`;

const PlanImage = styled.img`
    width: 100px;
    margin-top: 40px;
`;

const Price = styled(H3)`
    font-weight: 400;
    margin-top: 20px;
    margin-bottom: 0px;
    @media (min-width: 980px) {
        font-size: 40px;
    }
`;

const SmallYear = styled(P)`
    margin: 0px;
    margin-top: 15px;
    color: #51c0cf;
`;

const SubmitButton = styled(Button)`
    margin-top: 10px;
    margin-bottom: 40px;
    padding-right: 30px;
    padding-left: 30px;
    font-size: 16px;
`;

const CurrentPlanText = styled(P)`
    color: #545454;
    font-weight: 700;
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 0px;
    padding-top: 10px;
`;


const CustomPlan = styled.div`
    background-color: #ffffff;
    border-radius: 10px;
    display: inline-block;
    border: 1px solid #51c0cf;
`;

const PText = styled(P)`
    color: #545454;
    margin: 5px;
    padding: 8px;
`;

const Flex = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
`;

const PlanText = styled(P)`
    color: #545454;
    font-weight: 700;
    font-size: 18px;
    margin: 5px;
`;

const NewPlan = styled.div`
    margin-top: 25px;
`;

const MijnPlan = observer((props) => {
    const { t, i18n } = useTranslation();
    const store = useContext(registrationStore);
    const user = JSON.parse(store.user);
    const [checkoutStep, setCheckoutStep] = useState(1);
    const [successMessage, setSuccessMessage] = useState(false);
    const [loading, setLoading] = useState(false);
    const [userPlan, setUserPlan] = useState();

    const planStartDate = new Date(user.plan_start_date);
    const expireDate = new Date(
        planStartDate.setMonth(
            planStartDate.getMonth() +
                (user.month_reduction ? user.plan_period - user.month_reduction : user.plan_period)
        )
    );

    const afterPayment = qs.parse(props.location.search, { ignoreQueryPrefix: true }).afterPayment;

    const verlengPlan = () => {
        //TEMP override!!! TODO: Change too normal Checkout as with Therapuet plans
        //  store.setPlan("Patiënt");
        //  store.setPlanId(userPlan.id);
        //  setCheckoutStep(checkoutStep + 1);
        api.post("/payments", {
            redirect_url: window.location.href,
            plan_id: userPlan.id,
            user_id: user.public_id,
            discount: 1
        }).then(function (response) {
            window.location.reload()
            store.setUser(JSON.stringify(response.data.user));
        });
    }

    useEffect(() => {
        if (afterPayment) {
            setSuccessMessage(true);
            setCheckoutStep(1);
        }
        setLoading(true);
        api.get("/plans", { params: { onlyPublic: true, typeOfUser: "Patiënt" } }).then((res) => {
            const freePlan = res.data.plans.find(x => x.price === 0);
            setUserPlan(freePlan);
        });
        api.get("/user_plans/" + user.public_id).then((res) => {
            if (res.data.user_plans.length <= 1) {
                setCheckoutStep(1);
            }
            let plans = [];
            for (var plan of res.data.user_plans) {
                const planObj = plan;
                const planStartDate = new Date(plan.dateActive);
                const expireDate = new Date(
                    planStartDate.setMonth(
                        planStartDate.getMonth() +
                            (plan.month_reduction ? plan.plan_period - plan.month_reduction : plan.plan_period)
                    )
                );
                planObj["expireDate"] = expireDate;
                plans.push(planObj);
            }
            setLoading(false);
        });
    }, []);

    return (
        <Global>
            <PageWrapper>
                <PatientOverlay />
                <PageAnimation>
                    <PageTitle black>{t("sidebarPatient.acc")}</PageTitle>
                    <AccountNavigation therapist={user.therapist} />
                    {loading ? <LoadingSpinner /> :
                    !user.expired ? (
                        <>
                            {successMessage && <Flash green>{t("mijnPlan.successMessage")}</Flash>}
                            <Flex>
                                {<CurrentPlanText>{t("mijnPlan.currentPlanText")}</CurrentPlanText>}
                                <CustomPlan>
                                    <PText>
                                        {t("mijnPlan.customPlanMessage")}
                                        <A href="mailto:info@telephon.digital"> info@telephon.digital</A>
                                    </PText>
                                </CustomPlan>
                            </Flex>
                            <Plan>
                                <PlanImage src={user.sex === "Man" ? PatientIconMale : PatientIconFemale} />
                                <SmallYear>
                                    {t('mijnPlan.patientFreePlan')}
                                </SmallYear>
                                <Ul>
                                    <Li small>{t("registerForm3.ex")}</Li>
                                    <Li small>{t("registerForm3.res")}</Li>
                                    <Li small>{t("registerForm3.doc")}</Li>
                                    <Li small>{t("registerForm3.com")}</Li>
                                </Ul>
                            </Plan>
                        </>
                    ) : (
                        <>
                            <PlanText>{t("mijnPlan.previousPlanExpiredText")}</PlanText>
                            <Button onClick={verlengPlan} >{t("mijnPlan.patientFreeExtension")}</Button>
                        </>
                    )}
                    {checkoutStep === 2 && (
                        <NewPlan>
                            <CheckoutStep />
                        </NewPlan>
                    )}
                </PageAnimation>
            </PageWrapper>
        </Global>
    );
});

export default MijnPlan;
