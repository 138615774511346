import React, {useEffect, useState} from 'react';
import TwoFAPhone from "../components/2FAPhone"
import { observer } from "mobx-react"
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import Header from '../components/header'
import { H2, P } from '../components/styled/typography'
import Card from '../components/styled/card'
import Global from '../components/styled/global'
import {api} from "../api"
import { useTranslation } from "react-i18next"
import qs from "qs"
import { FaArrowLeft } from 'react-icons/fa';

const LoginCard = styled(Card)`
    width: 90%;
    margin: 5vh auto;
    padding: 40px 30px;

    @media (min-width: 768px) {
      width: 70%;
      margin: 10vh auto;
      padding: 40px 40px;
    }
`

const Title = styled(H2)`
    margin: 20px auto 0px auto;
    padding-bottom: 20px;
    @media (min-width: 768px){
      padding-bottom: 40px;
    }
`

const NoAccountCreated = styled(P)`

    @media (min-width: 768px) {
      padding: 0px 0px 0px 60px;
    } 
`

const CreateAccount = styled(Link)`
    color: #51C0CF;
`

const Icon = styled(FaArrowLeft)`
    vertical-align: middle;
    margin-right: 5px;
`

const Step = styled(P)`
    font-weight: bold;
    text-align: right;
    margin-top: 0px;

    @media(min-width:980px){
      padding: 30px 60px 20px 0px;
    }
`


const Flex = styled.div`
    display: flex;
    justify-content: space-between;
`


const Back = styled(Link)`
    padding: 20px 0px 20px 10px;
    text-decoration: none;
    color: #AFAFAF;

    @media (min-width: 980px){
      padding: 20px 0px 20px 60px;
    }
`


const Login2 = observer((props) => {
  const { t, i18n } = useTranslation()
  const language = qs.parse(props.location.search, {ignoreQueryPrefix: true}).language

  useEffect(()=>{
    if (language){
      i18n.changeLanguage(language)
    }
  },[])

  return (
    <div>
    <Header />
    <LoginCard>
      <Flex>
          <Back to='/login'><Icon />{t('checkoutStep.back')}</Back>
        </Flex>
      <Title center black>{t('login2FA.title')}</Title>
      <TwoFAPhone />
    </LoginCard>
    </div>
  )
  }
)

export default Login2;
