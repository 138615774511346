import React, {useState} from "react"
import { useTranslation } from "react-i18next"
import {api} from "../api"
import styled from 'styled-components'

import Button from './styled/button'

const Input = styled.input`
    background: #FFFFFF;
    border: 1px solid #51C0CF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(197, 197, 197, 0.25);
    border-radius: 10px;
    padding: 10px 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    outline: none;
    margin-right: 0px;
    min-width: 150px;
`

const Flex = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

const DiscountBox = (props) => {
    const { t, i18n } = useTranslation()
    const [code, setCode] = useState()

    const handleChange = (e) => {
        setCode(e.target.value)
    }

    const handleSubmit = () => {
        api.put("/discount/" + code, {"plan_id": props.plan_id}).then(res => {
            if (res.data.succes){
                props.succes(res.data.discount, res.data.month_reduction)
            } else {
                props.failure()
            }
        })
    }
    return (
        <Flex>
            <Input type='text' onChange={handleChange} />
            <Button type='button' onClick={handleSubmit}>{t('discountBox.submitCode')}</Button>
        </Flex>
    )
}

export default DiscountBox