import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import PatientOverlay from "../../components/overlays/patientOverlay";
import Global from "../../components/styled/global";
import Button from "../../components/styled/button";
import { PageTitle, P } from "../../components/styled/typography";
import PageWrapper from "../../components/styled/pageWrapper";
import Article from "../../components/article";
import Author from "../../components/author";
import { observer } from "mobx-react";
import { api } from "../../api";
import LoadSpinner from "../../components/loadspinner";
import { PageAnimation } from "../../components/styled/animation";
import registrationStore from "../../components/registrationStore";
import resultStore from "../../components/resultStore";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import qs from "qs";

const Flex = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    @media (min-width: 980px) {
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
    }
`;

const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const DoneButton = styled(Button)`
    margin-top: 20px;
`;

const ButtonDiv = styled.div``;
const Description = styled(P)`
    margin-top: 0px;
    color: #545454;
`;

const MateriaalDetail = observer((props) => {
    const { t, i18n } = useTranslation();
    const store = useContext(registrationStore);
    const RStore = useContext(resultStore);
    const user = JSON.parse(store.user);
    const startTime = Date.now();
    const [document, setDocument] = useState({});
    const [loading, setLoading] = useState(true);
    const [redirect, setRedirect] = useState(false);
    const [isExercise, setIsExercise] = useState();
    const [exercise, setExercise] = useState();
    const { id } = props.match.params;
    const task_id = qs.parse(props.location.search, { ignoreQueryPrefix: true }).task_id;

    useEffect(() => {
        api.get("/document/" + id).then((response) => {
            setDocument(response.data.document);
            if (task_id) {
                api.get("/task/" + task_id).then((res) => {
                    setExercise(res.data.task);
                    setLoading(false);
                });
            } else {
                setLoading(false);
            }
        });

        RStore.clearRecording();
    }, []);

    useEffect(() => {
        var d = qs.parse(props.location.search, { ignoreQueryPrefix: true }).exercise;
        setIsExercise(d);
    }, [props.location.search]);

    const HandleSubmit = () => {
        setRedirect(true);
    };
    return (
        <Global>
            <PageWrapper>
                <PatientOverlay />
                <PageAnimation>
                    <PageTitle black>{t("sidebarPatient.material")}</PageTitle>

                    {loading ? (
                        <LoadSpinner />
                    ) : (
                        <Flex>
                            <FlexColumn>
                                {isExercise && <Description>{exercise.description}</Description>}
                                <Article title={document.title} content={document.content} />
                                <ButtonDiv>
                                    {isExercise && (
                                        <DoneButton onClick={HandleSubmit}>{t("document.readingready")}</DoneButton>
                                    )}
                                </ButtonDiv>
                            </FlexColumn>
                            <Author public_id={document.therapist_id} />
                        </Flex>
                    )}
                    {redirect && (
                        <Redirect
                            to={{
                                pathname: "/p/Assignments/results",
                                state: {
                                    id: task_id,
                                    startTime: startTime,
                                    type: "resultform",
                                },
                            }}
                        />
                    )}
                </PageAnimation>
            </PageWrapper>
        </Global>
    );
});

export default MateriaalDetail;
