let theme = {
    name: 'Telephon',
    colors: {
        blue: ['#e9f4f6', '#daecf1', '#b5dae3', '#6bb5c7', '#388294', '#1c414a'],
        grey: ['#F2F2F2', '#bdb9bb', '#918b8d', '#655d60', '#4f4649', '#23181c', '#0d0106'],
        green: ['#C6F4E4', '#8AEFCC', '#1FA073'],
        red: ['#F4B5BD', '#C76774', '#AE5460'],
        orange: ['#F1E1C1', '#F7BA39', '#AE8124'],
        white: '#ffffff'
    },
    breakpoints: ['480px', '839px', '1240px'],
    space: [0, 4, 8, 12, 16, 24, 32, 48, 64, 96, 128, 192, 256],
    fontSizes: [12, 14, 16, 18, 20, 24, 30, 36, 48, 60, 72]
};

const extendedTheme = {
    ...theme,
    mediaQueries: {
        small: `@media screen and (min-width: ${theme.breakpoints[0]})`,
        medium: `@media screen and (min-width: ${theme.breakpoints[1]})`,
        large: `@media screen and (min-width: ${theme.breakpoints[2]})`
    },
    colors: {
        ...theme.colors,
        primary: theme.colors.blue[3],
        primaryLight: [theme.colors.blue[3], theme.colors.blue[2], theme.colors.blue[1], theme.colors.blue[0]],
        primaryDark: [theme.colors.blue[4], theme.colors.blue[5]],
        grey: theme.colors.grey[3],
        greyLight: [theme.colors.grey[2], theme.colors.grey[1], theme.colors.grey[0]],
        greyDark: [theme.colors.grey[4], theme.colors.grey[5], theme.colors.grey[6]],
        success: theme.colors.green[1],
        successLight: [theme.colors.green[0]],
        successDark: [theme.colors.green[2]],
        error: theme.colors.red[2],
        textColor: theme.colors.grey[5],
        titleColor: theme.colors.grey[4]
    },
    components: {
        button: {
            'border-radius': '0px'
        },
        input: {
            'border': 'none !important',
            'background-color': theme.colors.blue[1]
        }
    }
};

export default extendedTheme;
