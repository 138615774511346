import {createContext} from 'react';
import {action, observable} from 'mobx';
import callService from '../services/callService';
import api from '../api';
import communicationService from '../services/communicationService';
import userService from '../services/userService';



class CommunicationStore {
    @observable isActiveCallBannerShown = false;
    @observable isInCall = false;
    @observable activeCall = null;
    @observable isCallFinished = false;
    @observable initialized = false;
    @observable connected = true;
    @observable user = null;
    @observable fftSize = 2048;
    @observable sampleRate = 44100;
    @observable filterCutoff = 1000;
    @observable settings = null;
    @observable settingsChanged = false;
    @observable mousePosition = null;
    @observable mouseChanged = false;
    @observable jitterDisplay = false;
    @observable shimmerDisplay = false;
    @observable fundamentalDisplay = false;
    @observable intensityDisplay = false;
    @observable hnrDisplay = false;
    @observable recorderDisplay = false;
    @observable spectrogramDisplay = false;
    @observable spectrogramDim = false;
    @observable calibrationDisplay = false;

    @action showActiveCallBanner() {
        this.isActiveCallBannerShown = true;
    }

    @action hideActiveCallBanner() {
        this.isActiveCallBannerShown = false;
    }

    @action setIsInCall(bool) {
        this.isInCall = bool;
    }

    @action setActiveCall(call) {
        this.activeCall = call;
    }

    @action finishActiveCall() {
        callService.finishCall(this.activeCall.id);
        communicationService.destroyVideoConnection(this.user.public_id);
        this.connected = false;
        this.setActiveCall(null);
    }

    @action initialize(userId) {
        api.createAPI();
        communicationService.initializeWebsocketConnection();

        Promise.all([
            communicationService.initializeVideoCallConnection(userId),
            // communicationService.initializeDataStreamConnection(userId),
            this.fetchAndSetUser(userId)
        ]).then(() => this.initialized = true);
    }

    // @action initializeData(userId) {
    //     communicationService.initializeDataStreamConnection(userId);
    // }

    @action setFilterCutoff(value) {
        this.filterCutoff = value;
    }

    @action setSampleRate(value) {
        this.sampleRate = value;
    }

    @action setFftSize(value) {
        this.fftSize = value;
    }



    @action setCalibrationDisplay(bool) {
        this.calibrationDisplay = bool;
    }

    @action setIsCallFinished(bool) {
        this.isCallFinished = bool;
    }

    @action setJitterDisplay(bool) {
        this.jitterDisplay = bool;
    }

    @action setSpectrogramDisplay(bool) {
        this.spectrogramDisplay = bool;
    }
    @action setSpectrogramDim(bool) {
        this.spectrogramDim = bool;
    }
     @action setShimmerDisplay(bool) {
        this.shimmerDisplay = bool;
    }
     @action setFundamentalDisplay(bool) {
        this.fundamentalDisplay = bool;
    }
     @action setIntensityDisplay(bool) {
        this.intensityDisplay = bool;
    }
     @action setHnrDisplay(bool) {
        this.hnrDisplay = bool;
    }
    @action setRecorderDisplay(bool) {
        this.recorderDisplay = bool;
    }

    @action setSettings(s) {
        this.settings = s;
        this.setSettingsChanged(true);
    }

    @action setSettingsChanged(bool) {
        this.settingsChanged = bool;
    }

    @action setMousePosition(pos) {
        this.mousePosition = pos;
        this.setMouseChanged(true);
    }

    @action setMouseChanged(bool) {
        this.mouseChanged = bool;
    }

    fetchAndSetUser(userId) {
        return userService.getUser(userId).then(({data}) => this.user = data);
    }
}

export const CommunicationStoreInstance = new CommunicationStore();

export default createContext(CommunicationStoreInstance);
