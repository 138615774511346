import React,{useContext, useState} from 'react'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import styled from 'styled-components'
import { Redirect } from "react-router-dom"
import Button from '../styled/button'
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {api} from "../../api"
import {observer} from "mobx-react-lite"
import { useTranslation } from "react-i18next"
import TagSelect from "../helperComponents/tagSelect";
import registrationStore from "../registrationStore";
import { Disciplines, Subdisciplines} from "../resources/disciplines";
import { Languages } from '../resources/languages';
import { Switch } from "../styled/switch";

const DocumentForm = styled(Form)`
`

const FormGroup = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
`

const Input = styled(Field)`
    background: #FFFFFF;
    border: 1px solid #51C0CF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(197, 197, 197, 0.25);
    border-radius: 10px;
    padding: 10px 10px;
    margin-top: 5px;
    outline: none;
    font-size: 16px;
`

const Label = styled.label`
    font-size: 16px;
    font-weight: 600;
    color: #545454;
`

const SubmitButton = styled(Button)`
    margin-top: 20px;
    padding-right: 45px;
    padding-left: 45px;
    font-size: 18px;
`
const FlexLeft = styled.div`
    margin-top: 10px;
    display: flex;
`

const Error = styled.p`
    font-size: 16px;
    color: #AFAFAF;
    font-style: italic;
`

const Center = styled.div`
    text-align: center;
`

const documentForm = observer((props) => {
    const { t, i18n } = useTranslation()
    const store = useContext(registrationStore);
    const user = JSON.parse(store.user);
    const [redirect, setRedirect] = useState(false)
    const [dataContent, setDataContent] = useState("")
    const [tags, setTags] = useState([]);

    return (<div>
        <Formik initialValues={{ title : "", discipline: user?.speciality, description : "", content : "", published: false}} 
                onSubmit={(values, actions)=> {
                    api.post('/document', {
                        "therapist_id": props.user.public_id,
                        "published" : values.published,
                        "title" : values.title,
                        "discipline" : values.discipline,
                        "subdiscipline": values.subdiscipline,
                        "language": values.language,
                        "content" : dataContent,
                        "therapist_name" : props.user.firstName + " " + props.user.lastName,
                        "description": values.description,
                        "tags": tags
                    })
                    actions.setSubmitting(false)
                    setRedirect(true)
                }}
                render={({errors,touched, values}, isSubmitting) => (
                    <DocumentForm>
                        <FormGroup>
                            <Label>{t('documentEditForm.tags')}</Label>
                            <TagSelect setTags={setTags} />
                        </FormGroup>
                        <FormGroup>
                            <Label>{t('documentEditForm.disciplin')}</Label>
                            <Input component="select" name="discipline">
                                <option value="">...</option>
                                <Disciplines default={user.speciality} />
                            </Input>
                            { touched.discipline && errors.discipline && <Error>{errors.discipline}</Error> }
                        </FormGroup>
                        <FormGroup>
                            <Label>{t('documentEditForm.subdisciplin')}</Label>
                            <Input component="select" name="subdiscipline" >
                                <option value={null}>{t("documentForm.allSub")}</option>
                                <Subdisciplines discipline={values.discipline} />
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label>{t('documentEditForm.language')}</Label>
                            <Input component="select" name="language">
                                <option value="">...</option>
                                <Languages default={i18n.language} />
                            </Input>
                            { touched.language && errors.language && <Error>{errors.language}</Error> }
                        </FormGroup>
                        <FormGroup>
                            <Label>{t('documentEditForm.title')}</Label>
                            <Input type="text" name="title" placeholder={t('documentForm.titleInstructions')}/>
                            { touched.title && errors.title && <Error>{errors.title}</Error> }
                        </FormGroup>
                        <FormGroup>
                            <Label>{t('documentEditForm.description')}</Label>
                            <Input type="text" name="description" placeholder={t("documentForm.descriptionInstructions")}/>
                            { touched.description && errors.description && <Error>{errors.description}</Error> }
                        </FormGroup>
                        <FormGroup>
                            <Label>{t('documentEditForm.content')}</Label>
                            <CKEditor
                                        editor={ ClassicEditor }
                                        data="<p></p><p></p><p></p><p></p><p></p><p></p>"
                                        onChange={ ( event, editor ) => {
                                            const data = editor.getData();
                                            setDataContent(data)
                                        } }
                                        config={{
                                            cloudServices: {tokenUrl : process.env.REACT_APP_COBRAND_API + "/editor_token/" + props.user.public_id, uploadUrl : "https://55482.cke-cs.com/easyimage/upload/"},
                                        }}
                                    />
                        </FormGroup>
                        <Switch name="published" label={t("fileForm.publish")} id="published"/>
                        { touched.published && errors.published && <Error>{errors.published}</Error> }
                        <Center>
                            <SubmitButton type='submit' disabled={isSubmitting}>{t('documentForm.configure')}</SubmitButton>
                        </Center>
                        {redirect && <Redirect to="/t/CreateMaterial"/>}
                    </DocumentForm>
                )}
                validationSchema={Yup.object().shape({
                    title: Yup.string().required(t('documentEditForm.titlePlaceholder')),
                    description: Yup.string().required(t('documentEditForm.descriptionPlaceholder')),
                    discipline: Yup.string().required(),
                    language: Yup.string().required()
                })}
        
        />
        </div>)
})

export default documentForm