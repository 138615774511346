import React, { useState } from "react";
import styled from "styled-components";
import _ from "lodash/array";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import Button from "../styled/button";

const Page = styled.div`
    display: flex;
    flex-direction: column;
`;

const Navigation = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 15px 5px;
`;

const IconArrowRight = styled(FaArrowRight)`
    color: #51c0cf;
    margin: 0px 5px;
    cursor: pointer;
`;

const IconArrowLeft = styled(FaArrowLeft)`
    color: #51c0cf;
    margin: 0px 5px;
    cursor: pointer;
`;

const ButtonGroup = styled.div`
    &::after {
        content: "";
        clear: both;
        display: table;
    }

    &::not(:last-child) {
        border-right: none; /* Prevent double borders */
    }

    &::hover {
        background-color: #3e8e41;
    }
`;

const PageButton = styled(Button)`
    margin: 5px 5px;
    cursor: pointer;

    &.active {
        background-color: #caecf0;
    }
`;

function Pagination({ pageSize, children }) {
    const [currentPage, setCurrentPage] = useState(0);

    const totalChildren = React.Children.count(children);
    const totalPages = Math.ceil(totalChildren / pageSize);

    const childrenArray = _.chunk(React.Children.toArray(children), pageSize);

    const changePage = (newPage) => {
        if (childrenArray.length > newPage && newPage >= 0) {
            setCurrentPage(parseInt(newPage));
        }
    };

    const generateButtons = (N, onClick) =>
        [...Array(N).keys()].map((i) => (
            <PageButton
                key={`Button_${i}`}
                value={i}
                onClick={(e) => onClick(e.target.value)}
                className={i === currentPage ? "active" : "default"}
                type="button"
            >
                {i + 1}
            </PageButton>
        ));

    return (
        <>
            {childrenArray.length > 0 && (
                <>
                    <Page>{childrenArray[currentPage]}</Page>
                    <Navigation>
                        <IconArrowLeft
                            aria-label="prev-page"
                            onClick={() => {
                                changePage(currentPage - 1);
                            }}
                        />
                        <ButtonGroup defaultValue={0} size={totalPages}>
                            {generateButtons(totalPages, changePage)}
                        </ButtonGroup>
                        <IconArrowRight
                            aria-label="next-page"
                            onClick={() => {
                                changePage(currentPage + 1);
                            }}
                        />
                    </Navigation>
                </>
            )}
        </>
    );
}

export default Pagination;
