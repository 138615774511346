import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Card from "../styled/card";
import RegistrationStore from "../registrationStore";
import { H4, P } from "../styled/typography";
import registrationStore from "../registrationStore";

const countryCodes = require("../../country_codes.json");

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 15px;
`;

const Question = styled(H4)`
    font-weight: 500;
    margin: 0px;
`;

const FormGroup = styled.div`
    display: flex;
    flex-direction: column;
`;

const PhoneNumberFields = styled.div`
    display: flex;
    flex-direction: column;

    @media (min-width: 980px) {
        flex-direction: row;
    }
`;
const Input = styled.input`
    background: #ffffff;
    border: 1px solid #51c0cf;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(197, 197, 197, 0.25);
    border-radius: 10px;
    padding: 10px 10px;
    margin-top: 5px;
    outline: none;
    font-size: 16px;
`;

const InputPhone = styled(Input)`
    margin-left: 0px;
    flex-grow: 2;
    font-size: 16px;

    @media (min-width: 980px) {
        margin-left: 10px;
    }

    &.valid {
        border-color: #45ae7c;
    }

    &.invalid {
        border-color: #f02929;
    }
`;

const PhonePrefix = styled(Input)`
    width: 30%;

    @media (min-width: 980px) {
        width: 20%;
    }

    &.valid {
        border-color: #45ae7c;
    }

    &.invalid {
        border-color: #f02929;
    }
`;

const Label = styled.label`
    font-size: 18px;
    font-weight: 600;
    color: #545454;
`;

const Helper = styled(P)`
    margin: 0px;
    font-style: italic;
    font-size: 14px;
    margin: 5px 0px;
    margin-bottom: 15px;
`;

const Datalist = styled.datalist`
    border: 1px solid #51c0cf;
`;

const PhoneNumber = ({ setRegisterStepIsValid, setRegisterStepIsInvalid, nextStep }) => {
    const { t, i18n } = useTranslation();
    const store = useContext(RegistrationStore);
    const [phonePrefix, setPhonePrefix] = useState();
    const [phoneNumber, setPhoneNumber] = useState();
    const [phonePrefixIsValid, setPhonePrefixIsValid] = useState(false);
    const [phoneNumberIsValid, setPhoneNumberIsValid] = useState(false);

    useEffect(() => {
        store.setPhoneNumber(phonePrefix + phoneNumber);
    }, [phonePrefix, phoneNumber]);

    useEffect(() => {
        if (phonePrefixIsValid && phoneNumberIsValid) {
            setRegisterStepIsValid();
            console.log("valid");
        } else {
            setRegisterStepIsInvalid();
        }
    }, [phonePrefixIsValid, phoneNumberIsValid]);

    let codes = [];
    for (const key in countryCodes) {
        codes.push(countryCodes[key]);
    }

    const validatePhoneNumber = (phoneNumber) => {
        const re = /^\d+$/;
        if (phoneNumber.length > 0 && re.test(phoneNumber)) {
            return true;
        } else {
            return false;
        }
    };

    const validatePrefix = (phonePrefix) => {
        const re = /\+\d+/;
        if (re.test(phonePrefix) && codes.includes(phonePrefix)) {
            return true;
        } else {
            return false;
        }
    };

    const handlePhoneNumberChange = (e) => {
        setPhoneNumber(e.target.value);
        const phoneField = document.getElementById("phone");

        if (validatePhoneNumber(e.target.value)) {
            phoneField.classList.remove("invalid");
            phoneField.classList.add("valid");
            setPhoneNumberIsValid(true);
        } else {
            phoneField.classList.remove("valid");
            phoneField.classList.add("invalid");
            setPhoneNumberIsValid(false);
        }
    };

    const handlePrefixChange = (e) => {
        setPhonePrefix(e.target.value);
        const prefixField = document.getElementById("prefix");

        if (validatePrefix(e.target.value)) {
            prefixField.classList.remove("invalid");
            prefixField.classList.add("valid");
            setPhonePrefixIsValid(true);
        } else {
            prefixField.classList.remove("valid");
            prefixField.classList.add("invalid");
            setPhonePrefixIsValid(false);
        }
    };

    return (
        <Wrapper>
            <FormGroup>
                <Question black>{t("gegevensForm.tel")}</Question>
                <Helper>{t("registerForm2.followingNumbers")}</Helper>
                <PhoneNumberFields>
                    <PhonePrefix
                        id="prefix"
                        list="country_codes"
                        type="text"
                        name="TelephonePrefix"
                        placeholder="+.."
                        onChange={(e) => handlePrefixChange(e)}
                    />
                    <Datalist id="country_codes" name="TelephonePrefix">
                        {codes.map((element) => (
                            <option value={element}>{element}</option>
                        ))}
                    </Datalist>
                    <InputPhone
                        id="phone"
                        type="text"
                        name="TelephoneNumber"
                        placeholder={t("phoneRegister.placeholder")}
                        onKeyPress={(e) => nextStep(e)}
                        onChange={(e) => handlePhoneNumberChange(e)}
                    />
                </PhoneNumberFields>
            </FormGroup>
        </Wrapper>
    );
};

export default PhoneNumber;
