import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Line } from "react-chartjs-2";
import 'chartjs-plugin-zoom';
import Card from "../../components/styled/card";
import { useTranslation } from "react-i18next";
import Button from "../styled/button";

const GraphCard = styled(Card)`
    width: 100%;
    box-sizing: border-box;
    padding-right: 40px;

    @media (min-width: 980px) {
        width: 70%;
    }
`;

const Select = styled.select`
    width: 20%;
    background: #FFFFFF;
    border: 1px solid #51C0CF;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(197, 197, 197, 0.25);
    border-radius: 10px;
    padding: 10px 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    outline: none;
    font-size: 16px;
`

const RightSelect = styled(Select)`
    margin-left: auto;
`

const Flex = styled.div`
    display: flex;
    flex-direction: column;
`

const ButtonGroup = styled.div`
    display: flex;
    flex-direction: row;
`

const TaskButton = styled(Button)`
    margin: 5px 5px;
    cursor: pointer;

    &.active {
        background-color: #469CA8;
        border-color: #caecf0;
    }
`

const LabelColors = [
    "#F23539",
    "#F0C239",
    "#24AE5F"
]
const BackgroundColor = "#51C0CF"
const AxisColor = "#545454"

const Labels = {
    date: "",
    duration: "ms",
    recording: "",
    result: "",
}

const ResultsGraph = (props) => {
    const { t, i18n } = useTranslation();
    const [results, setResults] = useState(props.myresults);
    const [xLabel, setXLabel] = useState("date");
    const [yLabel, setYLabel] = useState("result");
    const [dataSet, setDataSet] = useState([]);
    const [labelsArray, setLabelsArray] = useState([]);
    const [currentTask, setCurrentTask] = useState(0);

    const resultProps = [
        <option key="0" value="date">{"Date"}</option>,
        <option key="1" value="duration">{"Duration"}</option>,
        <option key="2" value="result">{"Result"}</option>
    ];

    const graphOptions = {
        title: {
            display: false
        },
        legend: {
            display: true,
            position: "bottom",
        },
        hover: {
            mode: "index",
            intersect: false
        },
        tooltips: {
          enabled: true,
          mode: "index",
          intersect: false,
        },
        defaultFontFamily: "Raleway, sans-serif",
        scales: {
            yAxes: [
                {
                    ticks: {
                      beginAtZero: true,
                      callback: function(value, index, values) {
                        return value + Labels[yLabel];
                      }
                    },
                    gridLines: {
                        drawOnChartArea: false
                    },
                }
            ],
            xAxes: [
                {
                    type: 'time',
                    distribution: 'series',
                    time: {
                        unit: 'day',
                        tooltipFormat: 'll'
                    },
                    gridLines: {
                        drawOnChartArea: false
                    },
                    ticks: {
                        min: labelsArray[0],
                        max: labelsArray[labelsArray.length - 1]
                    }
                }
            ],
        },
        plugins: {
            zoom: {
                zoom: {
                    enabled: true,
                    mode: 'x',
                    speed: 0.05
                },
                pan: {
                    enabled: true,
                    mode: 'x',
                }
            }
        }
    };

    const [options, setOptions] = useState(graphOptions);

    useEffect(() => {
        setResults(props.myresults);
    }, [props.myresults]);

    useEffect(() => {
        updateChart();
    }, [results, xLabel, yLabel, currentTask]);

    const updateChart = () => {
        var data = [];
        var labels = [];
        let pointBackgroundColors = [];

        if (typeof results === 'undefined')
            return false;

        results.taken[currentTask].results.forEach((result) => {
            data.push(result[yLabel]);
            
            let colorIndex = (result[yLabel] - 1 < LabelColors.length) ? result[yLabel] - 1 : 0;
            pointBackgroundColors.push(LabelColors[colorIndex]);

            var label = result[xLabel] + Labels[xLabel];
            if (xLabel === "date"){
                const elementDate = new Date(result[xLabel]);
                label = elementDate.toLocaleString('en');
            }
            labels.push(label);
        });

        const dataObject = {
            label: results.taken[currentTask].task_title,
            data: data,
            showLine: true,
            pointRadius: 5,
            pointHoverRadius: 10,
            pointBackgroundColor: pointBackgroundColors,
            backgroundColor: AxisColor,
            borderColor: BackgroundColor,
            hidden: false,
            fill: false
        };

        setDataSet([dataObject]);
        setLabelsArray(labels);
        
        var tempOptions = graphOptions;
        tempOptions.scales.xAxes[0].type = xLabel === "date" ? 'time' : 'category';
        tempOptions.scales.xAxes[0].ticks.min = Math.min(...labels);
        tempOptions.scales.xAxes[0].ticks.max = Math.max(...labels);
        setOptions(tempOptions);
    }

    const data = {
        labels: labelsArray,
        datasets: dataSet,
    };

    if (typeof results === 'undefined') {
        return false;
    }else {
        return (
            <GraphCard>
                <Flex>
                    <Select value={yLabel} name="yAxis" onChange={(e) => setYLabel(e.target.value)}>
                        {resultProps.map((x, index) => {
                            return index !== 0 ? x : null;
                        })}
                    </Select>
                    <ButtonGroup>
                        {(typeof results === 'undefined' ? '' : results.taken.map((taak, id) => {
                            return (
                                <TaskButton
                                    key={id}
                                    value={id}
                                    onClick={(e) => setCurrentTask(parseInt(e.target.value))}
                                    className={id === currentTask ? 'active' : 'default'}
                                    type="button"
                                >
                                    {t("resultGraph.taak")} {taak.task_title}
                                </TaskButton>
                            )
                        }))}
                    </ButtonGroup>

                    <Line data={data} options={options}/>
                    <RightSelect value={xLabel} name="xAxis" onChange={(e) => setXLabel(e.target.value)}>
                        {resultProps}
                    </RightSelect>
                </Flex>
            </GraphCard>
        );
    }
};

export default ResultsGraph;
