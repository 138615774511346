import React, { useContext } from "react";
import TherapistOverlay from "../../components/overlays/therapistOverlay";
import Global from "../../components/styled/global";
import PageWrapper from "../../components/styled/pageWrapper";
import registrationStore from "../../components/registrationStore";
import { observer } from "mobx-react-lite";
import { PageAnimation } from "../../components/styled/animation";
import GeneralInbox from "../../components/generalInbox";

const Inbox = observer(() => {
    const store = useContext(registrationStore);
    const user = JSON.parse(store.user);

    return (
        <Global>
            <PageWrapper>
                <TherapistOverlay />
                <PageAnimation>
                    <GeneralInbox />
                </PageAnimation>
            </PageWrapper>
        </Global>
    );
});

export default Inbox;
