import {autoCorrelate} from './pitchHelper';

//var extractPeaks = require('webaudio-peaks');

// Gekopieerd uit volgende repository: https://github.com/cwilso/PitchDetect
export const getJitterValues = (buf, sampleRate) => {
    // Implements the ACF2+ algorithm
    let SIZE = buf.length;
    let rms = 0;
  
    for (let i = 0; i < SIZE; i++) {
      const val = buf[i];
      rms += val * val;
    }
    rms = Math.sqrt(rms / SIZE);
    if (rms < 0.01){ // not enough signal
        return 0;
    }

    const averagedBuffer = new Array(SIZE-2).fill(0);

    for (let i = 0; i < averagedBuffer.length; i++){
        averagedBuffer[i] = (buf[i] + buf[i+1] + buf[i+2])/3;
    }

    //find current pitch
    const currentPitch = autoCorrelate(buf,sampleRate);

    //samplejump betwween peaks
    const sampleJump = Math.round((sampleRate/currentPitch)/4);

    //get theoretical peak count    
    const availablePeakCount = Math.floor(averagedBuffer.length/(sampleRate/currentPitch))

    //peak sample no array
    const peakLocations = new Array(availablePeakCount).fill(-1);
    //peak sample height array
    const peakHeights = new Array(availablePeakCount).fill(-1);


    for (var i = 0; i < availablePeakCount; i++) {  
        if (i == 0) { 
            //saving buffer space by starting from 0
            for (var o = 0; o < (sampleJump*4);o++) {
                if (averagedBuffer[o] > peakHeights[i]) {
                   peakLocations[i] = o;
                   peakHeights[i] = averagedBuffer[o];
                }
            }
        }
        if (i > 0) {
            for (var p = peakLocations[i-1] + sampleJump*3; p < (peakLocations[i-1]+sampleJump*5);p++) {
                if (averagedBuffer[p] > peakHeights[i]) {
                   peakLocations[i] = p;
                   peakHeights[i] = averagedBuffer[p];
                }
            }
        }
    }

    const distanceBetweenPeaks = new Array(availablePeakCount-1).fill(-1);

    for (var i = 0; i < distanceBetweenPeaks.length; i++) {
        distanceBetweenPeaks[i] = peakLocations[i+1] - peakLocations[i];
    }

    //if there isnt enough peaks we return zero
    if (distanceBetweenPeaks.length <= 0) {
        return 0;
    }

    //setum peak distance differences array
    const peakDistanceDifferences = new Array(distanceBetweenPeaks.length-1).fill(-1);
    
    // populate peakDistanceDifferences
    for (var i = 0; i < peakDistanceDifferences.length; i++){
        peakDistanceDifferences[i] = Math.abs(distanceBetweenPeaks[i+1] - distanceBetweenPeaks[i]);
    }

    var peakDistanceDiffSum = 0;

    for (var i = 0; i < peakDistanceDifferences.length; i++){
        peakDistanceDiffSum = peakDistanceDiffSum + peakDistanceDifferences[i];
    }

    var peaKDistanceDiffAvg = peakDistanceDiffSum / peakDistanceDifferences.length;

    let distanceBetweenPeaksSum = 0;
    for (var i = 0; i < distanceBetweenPeaks.length; i++) {
        distanceBetweenPeaksSum = distanceBetweenPeaksSum + distanceBetweenPeaks[i];
    }

    let avgPeriod = distanceBetweenPeaksSum / distanceBetweenPeaks.length;

    let result = peaKDistanceDiffAvg / avgPeriod * 100

    return result;
}
