import React, { useContext } from "react";
import styled from "styled-components";
import { P } from "../components/styled/typography";
import Button from "../components/styled/button";
import { FaEdit } from "react-icons/fa";
import { Link } from "react-router-dom";
import Card from "../components/styled/card";
import { useTranslation } from "react-i18next";
import { isAppInsigthsEnabled } from "../insights";
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { eventConstants, getInsightUserType } from "../insights-constants";
import { trackEvent } from "../insights-track";
import RegistrationStore from "../components/registrationStore";

const TaskCard = styled(Card)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 15px;
    margin: 10px 0px;

    @media (min-width: 980px) {
        width: 49.5%;
    }
`;

const Bold = styled(P)`
    font-weight: bold;
    margin: 0px;
`;
const StartButton = styled(Button)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 15px;
    font-size: 16px;
`;

const EditIcon = styled(FaEdit)`
    color: #ffffff;
    margin-right: 5px;
    font-size: 16px;
`;

const A = styled(Link)`
    text-decoration: none;
`;

function TodayTask(props) {
    const { t, i18n } = useTranslation();
    const store = useContext(RegistrationStore);
    const user = JSON.parse(store.user)

    const isAiEnabled = isAppInsigthsEnabled();
    const appInsights = useAppInsightsContext();
    const trackProps = {
        user: {
            type: getInsightUserType(props.patient?.therapist),
            public_id: user?.public_id
        }
    }

    const determineType = () => {
        if (props.data.type === "Document") {
            return "consultMaterial/Detail/" + props.data.task_id;
        }
        if (props.data.type === "stappenplan" || props.data.type === "dagboek" || props.data.type === "stappenplanValidatie") {
            return "Assignments/Detail/" + props.data.id;
        }
        return "consultMaterial/mediaDetail/" + props.data.task_id;
    };

    return (
        <TaskCard>
            <Bold>
                {t("resultNavigation.assignment")} {props.title} - {props.data.title}
            </Bold>
            <A
                to={{
                    pathname: determineType(),
                    search: "?exercise=true&task_id=" + props.data.id,
                }}
                onClick={() => trackEvent(isAiEnabled, appInsights, eventConstants.PRESS.ASSIGNMENTS.START_ASSIGNMENT, trackProps)}
            >
                <StartButton>
                    <EditIcon />
                    {t("metronoom.start")}
                </StartButton>
            </A>
        </TaskCard>
    );
}

export default TodayTask;
