import React, {useState} from "react";
import styled from "styled-components";
import Card from "../styled/card";
import { P } from "../styled/typography";
import { useTranslation } from "react-i18next";
import {FaCalendar, FaInfoCircle} from "react-icons/fa";

const NavItem = styled(Card)`
    width: 50%;
    margin: 5px 5px 5px 0px;
    cursor: pointer;

    @media (min-width: 980px) {
        width: 24%;
    }
`;

const Text = styled(P)`
    margin: 0px;
    font-weight: bold;
    &.isactive {
        color: #51c0cf;
    }
`;

const DatesText = styled(P)`
    margin: 10px 0px 0px 0px;
    font-size: 0.8em;
    font-style: italic;
    letter-spacing: 0.02em;
    cursor: default;
    vertical-align: middle;
`;

const CalendarIcon = styled(FaCalendar)`
  font-size: 0.7em;
  margin: 0px 5px 0px 0px;
`;

function TaskDatesList(props) {
    const { t, i18n } = useTranslation();
    const taskObjects = props.tasks.map((task, i) => ({id: i, task: task}))
    const dates_firsttask = taskObjects[0].task.task_dates.map((myDate, i) => ({id: i, date: myDate.date}));
    const firstDate = new Date(dates_firsttask[0].date).toLocaleDateString(i18n.language);
    const dates_lasttask = taskObjects[taskObjects.length-1].task.task_dates.map((myDate, i) => ({id: i, date: myDate.date}));
    const lastDate =  new Date(dates_lasttask[dates_lasttask.length-1].date).toLocaleDateString(i18n.language);

    return (
        <DatesText>
            <CalendarIcon></CalendarIcon>{firstDate} &raquo; {lastDate}
        </DatesText>
    )
}

function ResultNavigation(props) {
    const { t, i18n } = useTranslation();
    const [dates, setDates] = useState();

    return (
        <NavItem value={props.result.assignment_id} onClick={props.onClick}>
            <Text value={props.result.assignment_id}>
                {t("resultNavigation.assignment")} {props.result.assignment_title}
            </Text>
            <TaskDatesList tasks={props.result.taken} onClick={false}/>
        </NavItem>
    );
}

export default ResultNavigation;
