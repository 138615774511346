import {color, compose, space} from 'styled-system'
import React, {useEffect, useContext, useState} from 'react';
import Card from '@eu.jstack/react-card';
import {observer} from 'mobx-react-lite';
import styled, {ThemeProvider} from 'styled-components';
import extendedTheme from '../../theme';
import Icon from '../icon';
import {faPhoneAlt} from '@fortawesome/free-solid-svg-icons';
import Text from '../text';
import {withRouter} from 'react-router-dom';
import {PrimaryButton, TertiaryButton} from '@eu.jstack/react-button';
import callService from '../../services/callService';
import api from '../../api';
import CommunicationStore from '../../stores/communication.store';
import { useTranslation } from "react-i18next";

const Highlight = styled.div`
    height: 100px;
    width: 5px;
    ${compose(color)};
`;

const CardContainer = styled.div`
    display: flex;
    align-items: center;
    ${compose(space)}
`;

const CallIconContainer = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    ${compose(space)}
`;

const CallIconCircle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    ${compose(color)}
`;

const DescriptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    ${compose(space)}
`;

const ActionContainer = styled.div`
    display: flex;
    flex-direction: row;
    ${compose(space)}
`;

const ActiveCallNotification = observer(({history, location}) => {
    const communicationStore = useContext(CommunicationStore);
    const [activeCall, setActiveCall] = useState(null);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        api.createAPI();

        callService.getActiveCalls()
            .then(({data}) => {
                if (data.length > 0) {
                    communicationStore.showActiveCallBanner();
                    setActiveCall(data[0]); // for now only select the first active call
                } else {
                    communicationStore.hideActiveCallBanner();
                    setActiveCall(null);
                }
            });
    }, [communicationStore.isActiveCallBannerShown, communicationStore.isInCall, communicationStore.isCallFinished]);

    if (!communicationStore.isActiveCallBannerShown || location.pathname === '/video-chat') {
        return null;
    }

    const getCallerName = () => {
        if (!activeCall) {
            return;
        }

        // caller is initiator
        const caller = activeCall.attendees.find(attendee => attendee.public_id === activeCall.initiator);
        return `${caller.firstName} ${caller.lastName}`
    };

    const acceptCall = () => {
        communicationStore.hideActiveCallBanner();
        communicationStore.setIsInCall(true);
        history.push('/video-chat', {
            receiverId: activeCall.attendees.find(attendee => attendee.public_id !== activeCall.initiator).public_id,
            initiatorId: activeCall.initiator,
            role: 'receiver'
        });
    };

    const refuseCall = () => {
        communicationStore.hideActiveCallBanner();
        callService.finishCall(activeCall.id);
    };

    return (
        <ThemeProvider theme={extendedTheme}>
            <Card position='fixed' bottom='30px' right='30px' height='100px' p={0}>
                <Highlight bg='primary'/>

                <CardContainer p={5}>
                    <CallIconContainer>
                        <CallIconCircle bg='primaryLight.3'>
                            <Icon fontSize={3} color='primary' icon={faPhoneAlt}/>
                        </CallIconCircle>
                    </CallIconContainer>

                    <DescriptionContainer ml={5}>
                        <Text fontWeight='600'>{t('videochat.incoming')}</Text>
                        <Text color='greyLight.0'>{t('videochat.from')} {getCallerName()}</Text>
                    </DescriptionContainer>

                    <ActionContainer ml={8}>
                        <PrimaryButton onClick={() => acceptCall()} px={3}>{t('videochat.accept')}</PrimaryButton>
                        <TertiaryButton onClick={() => refuseCall()} ml={3}>{t('videochat.deny')}</TertiaryButton>
                    </ActionContainer>
                </CardContainer>
            </Card>
        </ThemeProvider>
    )
});

export default withRouter(ActiveCallNotification);
