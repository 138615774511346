import React, { useState, useEffect, useRef } from "react";
import { DateUtils } from "react-day-picker";
import { getDaysArray } from "../helperComponents/dateHelper";
import styled from "styled-components";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import Calendar from "./calendar";
import Pagination from "../helperComponents/pagination";
import "react-day-picker/lib/style.css";
import DateRangePicker from "./dateRangePicker";
import { P, SmallTitle } from "../styled/typography";

const FormGroup = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
`;

const Label = styled.label`
    font-size: 16px;
    font-weight: 600;
    color: #545454;
`;

const Flex = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    @media (min-width: 980px) {
        flex-direction: row;
        justify-content: flex-start;
    }
`;

const LeftDiv = styled.div`
    width: fit-content;
    align-self: flex-start;
    display: flex;
    flex-direction: column;
`;

const RightDiv = styled.div`
    padding-left: 0px;
    margin-left: 0px;
    border-left: none;
    border-top: 1px solid lightgrey;
    margin-top: 20px;
    padding-top: 20px;
    @media (min-width: 980px) {
        padding-left: 20px;
        margin-left: 10px;
        border-top: none;
        border-left: 1px solid lightgrey;
        margin-top: 0px;
        padding-top: 0px;
    }
`;

const Input = styled.input`
    background: #ffffff;
    border: 1px solid #51c0cf;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(197, 197, 197, 0.25);
    border-radius: 10px;
    padding: 10px 10px;
    margin-top: 5px;
    outline: none;
    font-size: 16px;
`;

const SubTitle = styled(SmallTitle)`
    @media (min-width: 980px) {
        font-size: 18px;
        margin-top: 10px;
    }
`;

const CalendarDiv = styled.div`
    margin-left: -20px;
`;

const FlexLabel = styled.label`
    display: flex;
    flex-direction: column;
    margin-top: 10px;
`;

const Sheduler = observer((props) => {
    const now = new Date();
    const { t, i18n } = useTranslation();
    const [useRegularShedule, setUseRegularShedule] = useState(false);
    const [dates, setDates] = useState([]);
    const [dateRange, setDateRange] = useState({ start: null, end: null });
    const [min, setMin] = useState(1);
    const calendarRef = useRef(null);

    useEffect(() => {
        if (useRegularShedule) {
            if (getAllDates().length > 0) sendShedule();
        }
        else if (dates.length > 0) sendShedule();
    }, [dates, dateRange]);

    useEffect(() => {
        resetShedule();
    }, [props.resetShedule]);
    
    useEffect(() => {
        props.handlePermitAddTask(getNumberDates());
    }, [dates]);

    const getNumberDates = () => useRegularShedule ? getAllDates().reduce((a, b) => a + b.frequency, 0) : dates.reduce((a, b) => a + b.frequency, 0);
        
    const resetShedule = () => {
        setUseRegularShedule(false);
        setDates([]);
        setDateRange({ start: null, end: null });
        setMin(1);
        if (calendarRef.current != null) calendarRef.current.clearDays();
    }

    const handleFrequencyChange = (day, event) => {
        var prevDates = [...dates];
        const index = prevDates.findIndex((selectedDay) => DateUtils.isSameDay(selectedDay.date, day));
        prevDates[index].frequency = parseInt(event.target.value);
        setDates(prevDates);
    };

    const changeShedule = () => {
        if (calendarRef.current != null) {
            calendarRef.current.clearDays();
        }
        if (!useRegularShedule) {
            setDates(getWeekdays());
            setMin(0);
        } else {
            setDates([]);
            setMin(1);
        }
        setUseRegularShedule(!useRegularShedule);
    };

    const getWeekdays = () => {
        var week = [];
        var now = new Date();
        for (var i = 0; i < 7; i++) {
            week.push({
                date: new Date(now),
                frequency: 0,
            });
            now.setDate(now.getDate() + 1);
        }
        //Sort monday to sunday
        return week.sort((a, b) => (a.date.getDay() || 7 - 1) - (b.date.getDay() || 7 - 1));
    };

    const getDate = (dateTime) => {
        if (useRegularShedule) {
            return dateTime.toLocaleDateString(i18n.language, { weekday: "long" });
        }
        return dateTime.toLocaleDateString(i18n.language);
    };

    const getAllDates = () => {
        var start = new Date(dateRange.start);
        var end = new Date(dateRange.end);
        var days = getDaysArray(start, end);
        var selectedDays = [];
        days.forEach(day => {
            var filteredDates = dates.filter(x => x.date.getDay() === day.getDay() && x.frequency > 0);
            if(filteredDates.length > 0) selectedDays.push({'date': day, 'frequency': filteredDates[0].frequency});
        });
        return selectedDays;
    };

    const sendShedule = () => {
        var shedule = {};
        if (useRegularShedule) {
            var allDates = getAllDates();

            if (allDates.length > 0) {
                shedule.total = allDates.reduce((a, b) => a + b.frequency, 0);
                shedule.dates = allDates;
            }
        } else {
            shedule.total = dates.reduce((a, b) => a + b.frequency, 0);
            shedule.dates = dates;
        }
        props.callback(shedule);
    };
    
    const setShedule = (days) => {
        var tempDates = [];
        for (const day in days) {
            const selectedIndex = dates.findIndex((selectedDay) => DateUtils.isSameDay(selectedDay.date, days[day]));
            if (selectedIndex >= 0) {
                tempDates.push({ date: days[day], frequency: dates[selectedIndex].frequency });
            } else {
                tempDates.push({ date: days[day], frequency: 1 });
            }
        }
        setDates(tempDates.sort((a, b) => Date.parse(a.date) - Date.parse(b.date)));        
    };

    return (
        <FormGroup>
            <SubTitle black>{t("sheduler.selectShedule")}</SubTitle>
            <Flex>
                <LeftDiv>
                    <label htmlFor="changeShedule">
                        {t("sheduler.useRegularShedule")}
                        <Input
                            type="checkbox"
                            id="changeShedule"
                            name="changeShedule"
                            checked={useRegularShedule}
                            onChange={changeShedule}
                        />
                    </label>
                    <label>
                        {t("sheduler.totalNumberOfTasks")} :{" "}
                        {getNumberDates()}
                    </label>
                    {useRegularShedule ? (
                        <>
                            <label>{t("sheduler.selectDateRange")}</label>
                            <DateRangePicker callback={setDateRange} />
                        </>
                    ) : (
                        <CalendarDiv aria-label="Calendar">
                            <Calendar
                                month={now}
                                disabledDays={{ before: now }}
                                callback={setShedule}
                                ref={calendarRef}
                            />
                        </CalendarDiv>
                    )}
                </LeftDiv>

                <RightDiv>
                    <div>
                        {dates.length > 0 && (
                            <>
                                {t("sheduler.setNumberOfTasksPerDay")}
                                <Pagination pageSize={3}>
                                    {dates.map((value, index) => {
                                        return (
                                            <div key={index}>
                                                <FlexLabel>
                                                    {getDate(value.date)} :
                                                    <Input
                                                        type="number"
                                                        aria-label={`changeFrequency${index}`}
                                                        name="changeFrequency"
                                                        placeholder={value.frequency}
                                                        min={min}
                                                        onChange={(e) => handleFrequencyChange(value.date, e)}
                                                    />
                                                </FlexLabel>
                                            </div>
                                        );
                                    })}
                                </Pagination>
                            </>
                        )}
                    </div>
                </RightDiv>
            </Flex>
        </FormGroup>
    );
});

export default Sheduler;
