import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import PatientOverlay from "../../components/overlays/patientOverlay";
import Global from "../../components/styled/global";
import { PageTitle, H4, SmallTitle } from "../../components/styled/typography";
import PageWrapper from "../../components/styled/pageWrapper";
import { api } from "../../api";
import ResultsGraph from "../../components/resultsGraph/resultsGraph";
import ResultNavigation from "../../components/resultsGraph/resultNavigation";
import LoadSpinner from "../../components/loadspinner";
import { PageAnimation } from "../../components/styled/animation";
import { observer } from "mobx-react";
import registrationStore from "../../components/registrationStore";
import { useTranslation } from "react-i18next";

const Flex = styled.div`
    display: flex;
`;

const UnAvailable = styled.div`
    display: none;

    @media (min-width: 980px) {
        display: block;
    }
`;

const NotAvailableText = styled.div`
    display: block;
    @media (min-width: 980px) {
        display: none;
    }
`;

const ResultatenDetail = observer(() => {
    const { t, i18n } = useTranslation();
    const store = useContext(registrationStore);
    const user = JSON.parse(store.user);
    const [Loading, setLoading] = useState(true);
    const [GraphLoading, setGraphLoading] = useState(true);
    const [thisAssignment, setThisAssignment] = useState([]);
    const [results, setResults] = useState([]);
    const [activeGraph, setActiveGraph] = useState();

    useEffect(() => {
        api.get("user/" + user.public_id + "/completedtasks").then((response) => {
            setThisAssignment(response.data.output);
            setLoading(false);
        });
    }, []);
    const handleClick = (e) => {
        const index = e.target.getAttribute("value");
        const assignment = thisAssignment.findIndex((p) => p.assignment_id == index);
        var elems = document.querySelectorAll(".isactive");
        [].forEach.call(elems, function (el) {
            el.classList.remove("isactive");
        });
        e.target.className += " isactive";
        setResults(thisAssignment[assignment]);
        setGraphLoading(false);
    };
    return (
        <Global>
            <PageWrapper>
                <PatientOverlay />
                <PageAnimation>
                    <PageTitle black>{t("patientCard.results")}</PageTitle>
                    <UnAvailable>
                        <SmallTitle black>{t("resultatenDetail.select")}</SmallTitle>
                        <Flex>
                            {Loading ? (
                                <LoadSpinner />
                            ) : thisAssignment.length > 0 ? (
                                thisAssignment.map((result) => (
                                    <ResultNavigation result={result} onClick={handleClick} />
                                ))
                            ) : (
                                <div style={{ "margin-top": "20px" }}>{t("resultatenDetail.noAss")}</div>
                            )}
                        </Flex>
                        {Loading ? (
                            <LoadSpinner />
                        ) : GraphLoading ? (
                            ""
                        ) : (
                            <ResultsGraph myresults={results} activegraph={activeGraph} />
                        )}
                    </UnAvailable>
                    <NotAvailableText>
                        <div style={{ "margin-top": "20px" }}>{t("resultatenDetail.phone")}</div>
                    </NotAvailableText>
                </PageAnimation>
            </PageWrapper>
        </Global>
    );
});

export default ResultatenDetail;
