import styled from 'styled-components'


const Container = styled.div`
    padding-right: 25px;
    padding-left: 25px;
    margin-right: auto;
    margin-left: auto;
    display: ${props => props.flex ? "flex" : "block"};
    align-items: ${props => props.alignCenter ? "center" : "flex-start"};
    justify-content: ${props => props.justifyEnd ? "flex-end" : "flex-start"};

    @media (min-width: 768px) {
        width: 700px;
    }
    @media (min-width: 992px) {
        width: 970px;
    }
    @media (min-width: 1200px) {
        width: 1170px;
    }
`

export default Container

